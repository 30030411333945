'use client'
import { memo, useCallback, useEffect, useMemo, useState, useRef } from 'react'
import { useAppStore } from '@/stores/app'
import { useUserStore } from '@/stores/user'
import { useRouter } from 'next/navigation'
import { cn, decimalFormat } from '@/lib/utils'
import styles from './index.module.scss'
import { useTranslation } from '@/lib/locales/client'
import { useShallow } from 'zustand/react/shallow'
import { CSSTransition } from 'react-transition-group'
import { Modal } from 'antd'
import { px2Scalepx } from '@/hooks/useRem'
import { DefaultList } from '@/app/[lng]/(promotion)/_components/defaultPromo.js'

const PromoNotice = () => {
  const router = useRouter()
  const { t } = useTranslation()
  const isBigScreen = useAppStore((state) => state.isBigScreen)
  const [showNotice, setShowNotice] = useState(false)
  const [showBox, setShowBox] = useState(false)
  const [showRemind, setShowRemind] = useState(false)
  const [isChecked, setIsChecked] = useState(false)
  const prevPromoType = useRef(null) // 用 useRef 保存之前的值
  const promoTimer = useRef(null)

  const { isLogin, userInfo, depositActivityInfo, wallets, kycAuditStatus, signDetail, currency, jpyToUsd } = useUserStore(
    useShallow((state) => ({
      isLogin: state.isLogin,
      userInfo: state.userInfo,
      depositActivityInfo: state.depositActivityInfo,
      wallets: state.wallets,
      kycAuditStatus: state.kycAuditStatus,
      signDetail: state.signDetail,
      currency: state.currency,
      jpyToUsd: state.jpyToUsd,
    }))
  )

  const isLowBlance = useMemo(() => {
    if (
      (currency.currency === "JPY" && currency.amount < 150) ||
      (currency.currency !== "JPY" && currency.amount < 1)
    ) {
      return true
    }
    return false
  }, [currency])

  const promoType = useMemo(() => {
    //等待数据加载完成后再判断
    if (
      !depositActivityInfo ||
      wallets.length === 0 ||
      kycAuditStatus === -1 ||
      !userInfo.registerDate ||
      signDetail === undefined
    ) {
      return ""
    }
    let isNeW = +new Date(userInfo.registerDate) - 1 > 1725580799999
    let depositTime = depositActivityInfo?.extraResult?.depositTime || 3
    if (kycAuditStatus === 0 && isNeW) {
      return "kyc1"
    } else if (kycAuditStatus < 2 && isNeW) {
      return "kyc2"
    } else if (depositTime == 0) {
      return "deposit1"
    } else if (isLowBlance && depositTime == 1) {
      return "deposit2"
    } else if (isLowBlance && depositTime == 2) {
      return "deposit3"
    } else if (
      signDetail !== null &&
      !signDetail.todaySignCount &&
      !signDetail.signFlag &&
      kycAuditStatus > 0
    ) {
      return "sign"
    }
    return ""
  }, [depositActivityInfo, wallets, kycAuditStatus, userInfo, signDetail])

  const signData = useMemo(() => {
    if (signDetail && signDetail.conValObj) {
      return signDetail.conValObj.daySignArrConfig[signDetail.nextSignCount - 1]
    } else {
      return {}
    }
  }, [signDetail])

  const promoData:any = useMemo(() => {
    if (promoType === "sign") {
      return {
        awardMoney: signData.prizeType === 1 ? signData.prizeAmount : null,
        awardCurrency: "USD",
        awardSpin: signData.prizeType === 6 ? signData.prizeAmount : null,
        defaultUrl: "/pachinko"
      }
    } else {
      return DefaultList.filter((el) => el.rewardType === promoType)[0] || {}
    }
  }, [promoType, signData])

  const closeModal = () => {
    setShowNotice(false)
    setShowBox(false)
    setTimeout(() => {
      setShowRemind(false)
    }, 300)
  }

  const goPath = () => {
    closeModal()
    router.push(promoData.defaultUrl)
  }

  const closeRemind = () => {
    closeModal()
    if (isChecked) {
      localStorage.setItem("lastShowPromosDialog", String(new Date().getDate()))
    } else {
      localStorage.removeItem("lastShowPromosDialog")
    }
  }

  useEffect(() => {
    promoTimer.current && clearTimeout(promoTimer.current)
    if (promoType && localStorage.getItem("lastShowPromosDialog") !== new Date().getDate().toString()) {
      //首次加载延迟两秒出来，完成任务后延迟10秒显示下一个
      promoTimer.current = setTimeout(
        () => {
          setShowBox(true)
        },
        prevPromoType.current ? 10000 : 2000
      )
    }
    prevPromoType.current = promoType
  }, [promoType])

  return (
    <div className={cn(styles["promoNotice"], styles[isBigScreen ? 'pc' : ''])}>
      <CSSTransition
        in={showBox && !showNotice}
        timeout={500}
        className={`pull-up promo-notice-box`}
        unmountOnExit
      >
        <div >
          <div>
            <img
              className="gift"
              src="/static/images/promo/gift.svg"
            />
            <p>{ t("promo.newTask") }</p>
          </div>
          <a onClick={() => setShowNotice(true)}>{ t("promo.view") }</a>
        </div>
      </CSSTransition>

      <Modal
        open={showNotice}
        centered
        footer={null}
        keyboard={false}
        width={px2Scalepx(375)}
        zIndex={2001}
        className={cn(styles[isBigScreen ? 'pc' : ''], styles["promo-notice-dialog"])}
        key="notice"
        wrapClassName="promo-notice-modal-wrap"
        maskClosable={false}
        closable={false}
        mask={true}
      >
        {!showRemind ? 
        (<div
            className="promo-dialog-content"
          >
            <div className="heard">
              <img
                className="gift"
                src="/static/images/promo/gift.svg"
              />
              <p>{ t("promo.newTask") }</p>
              <img
                className="close_btn"
                src="/static/images/home/memberDetail/close.svg"
                onClick={() => isBigScreen ? closeModal() : setShowRemind(true)}
              />
            </div>
            <div className="content">
              <p className="content-title">{ t("promo.completeTask") }</p>
              <div className="money-warp">
                {
                  promoData.awardMoney && 
                  (
                    <>
                      <span>{ t("promo.bonus") }</span>
                      <i>{ decimalFormat(promoData.awardMoney, promoData.awardCurrency) }</i>
                      <img
                        className="currency"
                        src={`/static/images/header/wallets/currencies/${promoData.awardCurrency}.png`}
                      />
                    </>
                  )
                }
                {
                  promoData.percentage && 
                  (
                    <>
                      <span>{ t("promo.bonus") }</span>
                      <i>{ promoData.percentage }</i>
                      <span>+ { t("promo.freeSpin") }</span>
                      <i>{ promoData.extra }</i>
                    </>
                  )
                }
                {
                  promoData.awardMoney && promoData.awardSpin && 
                  (<span>+</span>)
                }
                {
                  promoData.awardSpin && 
                  (
                    <>
                      <span>{ t("promo.freeSpin") }</span>
                      <i>{ promoData.awardSpin }</i>
                    </>
                  )
                }
              </div>
              <div className="progress-icon">
                <span>1</span>
                <div></div>
                <span>2</span>
                <div></div>
                <span>3</span>
              </div>
              <div className="progress-text">
                <div>{ t(`promo.taskInfo.${promoType}`) }</div>
                {
                  promoType.indexOf('kyc') > -1 ? (
                    <div>{ t("promo.approved") }</div>
                  ) : promoType.indexOf('deposit') > -1 ? (
                    <div>{ t("promo.creditedSuc") }</div>
                  ) : promoType.indexOf('bet') > -1 ? (
                    <div>
                      <p>{ t("promo.bet") } $15</p>
                      <span>≈ ¥ { decimalFormat(15 / jpyToUsd, "JPY") }</span>
                    </div>
                  ) : promoType.indexOf('sign') > -1 && signData.requireBetAmount ? (
                    <div>
                      <p>{ t("promo.bet") } ${ signData.requireBetAmount }</p>
                      <span>≈ ¥ { decimalFormat(parseInt(signData.requireBetAmount) / jpyToUsd, "JPY") }</span>
                    </div>
                  ) : ''
                }
                <div>{ t("promo.claimReward") }</div>
              </div>
              <a
                className="claim-task"
                onClick={goPath}
              >
                { t("promo.claimTask") }
              </a>
            </div>
        </div>
        ):
        (<div className="promo-dialog-remind">
            <div className="promo-remind-text">
              <p>{ t("promo.closeRemind") }</p>
              <div onClick={() => setIsChecked(!isChecked)}>
                <img src={`/static/images/promo/check${isChecked ? 'ed' : ''}.svg`} />
                <span>{ t("promo.notShowToday") }</span>
              </div>
              <i></i>
              <img
                className="close"
                onClick={closeRemind}
                src="/static/images/promo/close.svg"
              />
            </div>
            <div className="promo-icon">
              <img src="/static/images/tabbar/promo-a.svg" />
              <p>{ t("common.tabber.promo") }</p>
              <span>99</span>
            </div>
          </div>
        )}
      </Modal>
    </div>
  )
}

export default memo(PromoNotice)
