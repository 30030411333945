import { FC } from 'react'
import { useParams, useRouter } from 'next/navigation'
import { styled } from 'styled-components'
import { cn } from '@/lib/utils'

type IProps = {
  html: any
  className: string
}

const RenderHTML: FC<IProps> = (props) => {
  const router = useRouter()
  const { lng } = useParams()

  function handleClick(e) {
    e.preventDefault()
    if (e.target.nodeName.toUpperCase() === 'A') {
      const url = e.target.getAttribute('href')
      if (isUrl(url)) {
        window.open(url, '_blank')
      } else {
        const str = url.startsWith('/') ? url : `/${lng}/${url}`
        router.push(`/${lng}${str}`)
      }
    }
  }

  function isUrl(url) {
    const str = url.toLowerCase()
    return str.startsWith('http') || str.startsWith('https')
  }

  return (
    <>
      <StyledRenderHTML
        className={cn(props.className, 'rebder-html scrollbar')}
        dangerouslySetInnerHTML={{ __html: props.html }}
        onClick={handleClick}
      ></StyledRenderHTML>
    </>
  )
}

export default RenderHTML

const StyledRenderHTML = styled.div`
  .rebder-html {
    :deep {
      &::-webkit-scrollbar {
        width: 3px;
        height: 3px;
        background-color: #d9d9d9;
      }
      &::-webkit-scrollbar-track {
        background: #0000003f;
        border-radius: 2px;
      }
      &::-webkit-scrollbar-thumb {
        background: #cc004a;
        border-radius: 10px;
      }
      line-height: 1.4;
      p {
        line-height: 1.4;
      }
      * {
        max-width: 100%;
        height: auto;
      }
      @media (max-width: 999px) {
        table {
          width: calc(100vw - 80px) !important;
          margin-top: 12px;
        }
        img {
          max-width: 100%;
          height: auto;
        }
      }

      td,
      th {
        border: 1px solid #1e1e1e;
        padding: 6px;
      }
    }
  }
`
