'use client'
import { getActLotPage } from '@/api/activity'
import NoData from '@/components/layout/common/noData'
import { useTranslation } from '@/lib/locales/client'
import { Pagination, Skeleton } from 'antd'
import { memo, useEffect, useState } from 'react'
import styles from './index.module.scss'

const TicketList = (props: any) => {
  const { actCode = '', ticketNum = '', pageSize = 20 } = props
  const [totalSize, setTotalSize] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [isLoading, setIsLoading] = useState(true)
  const [pageList, setPageList] = useState([])
  const { t } = useTranslation()
  const getPage = () => {
    setIsLoading(true)
    const params: any = {
      size: props.pageSize,
      current: currentPage,
      queries: {
        actCode: props.actCode
      },
      orders: [
        {
          column: '',
          asc: true
        }
      ]
    }
    if (props.ticketNum) {
      params.queries.ticketNum = props.ticketNum
    }
    getActLotPage(params)
      .then((res) => {
        if (res.code === 200 && res.success) {
          setPageList(res.data?.records || [])
          setTotalSize(Number(res.data?.total))
          if (!props.ticketNum) {
            props.setTotalSize && props.setTotalSize(Number(res.data?.total))
          }
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    getPage()
  }, [currentPage])

  useEffect(() => {
    setCurrentPage(1)
    setTotalSize(0)
    if (actCode) {
      if (actCode > 20241008) {
        getPage()
      } else {
        setPageList([])
        setIsLoading(false)
      }
    } else {
      setPageList([])
      setIsLoading(false)
    }
  }, [actCode, ticketNum])

  return (
    <div className={styles['tickets-list-warp']}>
      <Skeleton loading={isLoading} title={false} paragraph={{ rows: 6 }} active>
        {pageList.length <= 0 ? (
          <NoData>
            <span>{t('activity.ticket.noData')}</span>
          </NoData>
        ) : (
          <div className="tickets-list">
            {pageList.map((item, index) => (
              <div key={index} className="tickets-item">
                {item.ticketNum}
              </div>
            ))}
          </div>
        )}
      </Skeleton>
      <Pagination
        current={currentPage}
        showSizeChanger={false}
        showLessItems
        total={totalSize}
        pageSize={pageSize}
        onChange={(page, pageSize) => {
          setCurrentPage(page)
        }}
        hideOnSinglePage={true}
        showQuickJumper={false}
      />
    </div>
  )
}

export default memo(TicketList)
