import { memo, useMemo } from 'react'
import styles from './index.module.scss'
import { useTranslation } from '@/lib/locales/client'
import { numberToKUnit } from '@/lib/filter'
import { useParams } from 'next/navigation'
import Link from 'next/link'

const VipProgress = (props: any) => {
  const { vipInfo ,isShowPC,onPCPop} = props
  const { t } = useTranslation()
  const { lng } = useParams()
  const scheduleValue = useMemo(() => {
    if (vipInfo.currentLevel === vipInfo.nextLevel || vipInfo.sumBetAmount > vipInfo.nextLevelAmount) {
      return '100%'
    }
    return ((vipInfo.sumBetAmount / (vipInfo.nextLevelAmount || 1)) * 100).toFixed(0) + '%'
  }, [vipInfo])

  const dynamicStyle: any = {
    '--dynamic-width': scheduleValue
  }

  return (
    <Link className={styles['vip-progress']} href={`/${lng}/account/Loyalty-club`} onClick={()=>isShowPC && onPCPop(false)}>
      <div className="title-box">
        <span className="title">{t('user.vipProgress')}</span>
        <div className="amount">
          <span>{numberToKUnit(vipInfo.sumBetAmount) || 0.0}</span>/
          {+vipInfo.nextLevel === 130
            ? numberToKUnit(10101386850, 2)
            : numberToKUnit(vipInfo.nextLevelAmount, 2)}
        </div>
      </div>
      <div className="progress-box">
        <div className="progress">
          <div className="schedule" style={dynamicStyle}>
            <span className="value">{scheduleValue}</span>
          </div>
        </div>
      </div>
      <div className="grade">
        <span className="current">VIP {vipInfo.currentLevel}</span>
        <span className="prev">VIP {vipInfo.nextLevel}</span>
      </div>
    </Link>
  )
}
export default memo(VipProgress)
