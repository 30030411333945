'use client'
import { useState, useMemo, memo, FC, useEffect } from 'react'
import { useTranslation } from '@/lib/locales/client'
import { useAppStore } from '@/stores/app'
import { useUserStore } from '@/stores/user'
import { formatAmount } from '@/lib/filter'
import { getExchangeRate } from '@/api/wallet'
import VerifyBaseDialog from '@/components/common/VerifyBaseDialog'
import { getCurrencySymbol } from '@/lib/utils'
import { useGameStore } from '@/stores/game'
import { useShallow } from 'zustand/react/shallow'
import styles from '../_common_css/tipsDialog.module.scss'

const TipsDiialog: FC = (props) => {
  const { t } = useTranslation()
  const [rate, setRate] = useState(1)
  const { gameTipFun, gameTipShow, setGameTipShow } = useGameStore(
    useShallow((state) => ({
      gameTipFun: state.gameTipFun,
      gameTipShow: state.gameTipShow,
      setGameTipShow: state.setGameTipShow
    }))
  )
  const { moneyTypeSelectIndex, bonusCouponItem, currency, gameCurrency } = useUserStore(
    useShallow((state) => ({
      moneyTypeSelectIndex: state.moneyTypeSelectIndex,
      bonusCouponItem: state.bonusCouponItem,
      currency: state.currency,
      gameCurrency: state.gameCurrency
    }))
  )
  const [loading, setLoading] = useState(false)

  const currencyData = useMemo(() => {
    const isBonusCoupon = moneyTypeSelectIndex === 1 && bonusCouponItem
    return isBonusCoupon ? bonusCouponItem : currency
  }, [])

  const changeAmount = useMemo(() => {
    return formatAmount(currencyData.amount * rate) || '0.00'
  }, [rate])

  useEffect(() => {
    if (gameTipShow) {
      queryExchangeRate()
    }
  }, [gameTipShow])

  const close = () => {
    setGameTipShow(false)
  }

  const queryExchangeRate = () => {
    const params = {
      amount: 1,
      isFindByCache: 0,
      isSave: false,
      targetCurrency: gameCurrency,
      sourceAmount: 1,
      sourceCurrency: currencyData.currency,
      tradeType: '02',
      used: 1
    }
    setLoading(true)
    getExchangeRate(params).then((res: any) => {
      setLoading(false)
      if (res.success && res.data.data && res.data.data.length > 0) {
        setRate(res.data.data[0].realRate)
      }
    })
  }

  const handleConfirm = () => {
    close()
    gameTipFun()
  }

  return (
    <VerifyBaseDialog
      visible={gameTipShow}
      zIndex={6100}
      width={343}
      closeFn={close}
      cusClass={styles['gametips']}
    >
      <div className="container">
        <p className="title">{t('home.game.tips1')}</p>
        <p className="text">
          {t('home.game.tips2')}
          <span className="red">
            {getCurrencySymbol(currencyData.currency)}
            {formatAmount(currencyData.amount)}
          </span>

          {currencyData.currency !== 'JPY' && currencyData.currency !== 'USD' && (
            <span className="red">{currencyData.currency}</span>
          )}

          {t('home.game.tips3')}
          <span className="red">
            {getCurrencySymbol(gameCurrency)}
            {loading ? '--' : changeAmount}
          </span>
          {t('home.game.tips4')}
        </p>
        <ul>
          <li>{t('home.game.tips6')}</li>
          <li>{t('home.game.tips7')}</li>
        </ul>
        <div className="tip-wrap">{t('home.game.tips7')}</div>

        <div className="btn" onClick={handleConfirm}>
          {t('home.game.tips8')}
        </div>
      </div>
    </VerifyBaseDialog>
  )
}

export default memo(TipsDiialog)
