// import { watch } from "vue"
// import { _this } from "@/plugins/globalProperties"
import { useUserStore } from '@/stores/user'
import { useAppStore } from '@/stores/app'
import {
  checkReportStatus,
  updateReportStatus,
  successfulDepositQuery,
  successfulDepositQueryUpdate
} from '@/api/base'
import { depositAutoTransInGame, transferAsyncTransferIn } from '@/api/game'
// import { appsFlyerEvent } from '@/lib/utils'

/**
 * @params name: 时间件名
 * @param data
 */
export const track = (name: string, data?: any) => {
  const appStore = useAppStore.getState()
  try {
    // FB  上传首存和注册
    const fbqName = {
      FirstDeposit: 'Purchase',
      RegisterSuccess: 'CompleteRegistration'
    }
    if (window.fbq && fbqName[name]) {
      window.fbq('track', fbqName[name], data)
    }

    // 谷歌 上传首存和存款和注册和登录
    const layerName = {
      FirstDeposit: 'FTD.success',
      DepositSuccess: 'deposit.success',
      RegisterSuccess: 'register.success',
      LoginSuccess: 'login.success'
    }
    if (window.dataLayer && data && layerName[name]) {
      const { loginName, value, currency } = data
      const params = <any>{
        UsernameID: loginName,
        SocialChannelID: '',
        event: layerName[name]
      }
      if (value) {
        params.AmountID = value
        params.CurrencyID = currency
      }
      window.dataLayer.push(params)
    }

    //APP埋点
    if (!appStore.isBigScreen) {
      window.Android?.adjustEvent(name, JSON.stringify(data || {}) || '')
      window.apkClient?.appsFlyerEvent(JSON.stringify({ event: name, data }))
      window.jsBridge?.postMessage(name, JSON.stringify(data || {}) || '') // 安卓APP马甲包返回
    }
  } catch (error) {
    console.log(error, '埋点报错')
  }
}

/**
 *  Meta Pixel 埋点hook
 * @returns
 */

export const trackFristDeposit = () => {
  const appStore = useAppStore.getState()
  const useStore = useUserStore.getState()
  const { id, loginName } = useStore.userInfo
  const merchantCode = appStore.commonConfig.merchantCode
  if (id && !localStorage.getItem('trackFirstDeposit_' + id)) {
    checkReportStatus({ id, merchantCode }).then((res: any) => {
      if (res && res.success && res.data?.reportStatus === 1) {
        const value = res.data.firstDepositAmount
        const currency = res.data.firstDepositCurrency.toUpperCase() === 'USDT' ? 'USD' : 'JPY'
        updateReportStatus({
          playerId: id,
          merchantCode,
          reportStatus: 2
        }).then((res1: any) => {
          if (res1 && res1.success) {
            track('FirstDeposit', { value, currency, loginName })
            localStorage.setItem('trackFirstDeposit_' + id, 'true')
          }
        })
      } else if (res && res.success && res.data?.reportStatus === 2) {
        localStorage.setItem('trackFirstDeposit_' + id, 'true')
      }
    })
  }
}

export const trackDeposit = () => {
  const appStore = useAppStore.getState()
  const useStore = useUserStore.getState()
  const { id, loginName } = useStore.userInfo
  const merchantCode = appStore.commonConfig.merchantCode
  successfulDepositQuery({
    playerId: id,
    merchantCode
  }).then((res: any) => {
    if (res && res.data?.length > 0) {
      res.data.forEach((el: any) => {
        track('DepositSuccess', {
          value: el.amount,
          currency: el.currency,
          loginName
        })
      })
      successfulDepositQueryUpdate({
        billDate: res.data[0].billDate,
        playerId: id,
        merchantCode
      })
    }
  })
}

export const deposiTransInGame = (currency: any) => {
  depositAutoTransInGame({}).then((res: any) => {
    if (res.data?.supportAutoTransIn === 1) {
      transferAsyncTransferIn({
        currency,
        fiatCurrency: 'USD',
        providerCode: 'KAMIKAZE',
        providerCurrency: 'USD'
      })
    }
  })
}
