'use client'
import { useState, useEffect, FC, useMemo } from 'react'
import { useTranslation } from '@/lib/locales/client'
import { cn, getCurrencySymbol } from '@/lib/utils'
import { formatAmount } from '@/lib/filter'
import React from 'react'
import { useAppStore } from '@/stores/app'

type IProps = {
  depositInfo?: any
  fiatCurrency?: string
  virtualCurrency?: string
}

const DepositText: FC<IProps> = (props) => {
  const { depositInfo, fiatCurrency, virtualCurrency } = props
  const { t } = useTranslation()
  const language = useAppStore((state) => state.language)
  
  const max = useMemo(() => {
    const info = depositInfo.channelInfo[virtualCurrency]
    if (virtualCurrency && info) {
      return Number(info.payTypeList[0].maxAmount)
    } else if (fiatCurrency && depositInfo.channelInfo) {
      return Number(depositInfo.channelInfo[fiatCurrency].maxAmount)
    }
    return 999999999
  }, [virtualCurrency])

  const min = useMemo(() => {
    const info = depositInfo.channelInfo[virtualCurrency]
    if (virtualCurrency && info) {
      return Number(info.payTypeList[0].minAmount)
    } else if (fiatCurrency && depositInfo.channelInfo) {
      return Number(depositInfo.channelInfo[fiatCurrency].minAmount)
    }
    return 0
  }, [virtualCurrency])
  return (
    <div className="tips-box">
      <img src={'/static/images/deposit/tips.png'} alt="" />
      <div className="deposit_text">
        {depositInfo.channelCode === 'vegaWallet' &&
          (language === 'en-US' ? (
            <>
              <p>🔂 Vega wallet allows you to make deposits from your e-wallet.</p>
              <p>⏰ Weekday, Saturday, Sunday, Holiday</p>
              <p>⏰ 24/7 (Japan time)</p>
              <p>
                💰 Minimum deposit amount: {getCurrencySymbol(fiatCurrency)}
                {formatAmount(min, 0)}
              </p>
              <p>
                💰 Maximum deposit amount: {getCurrencySymbol(fiatCurrency)}
                {formatAmount(max, 0)}
              </p>
              <p>&nbsp;</p>
              <p>
                *Vega Wallet enables you to fund your wallet and withdraw your winnings using Bank Transfers.
                It's very easy to use, completely safe and secure.&nbsp;Register your
                <a href="https://vega-wallet.com/" target="_blank" rel="noopener">
                  Vega Wallet&nbsp;
                </a>
              </p>
            </>
          ) : (
            <>
              <p>🔂 Vega walletを利用することで、e-walletでのご入金が可能です。</p>
              <p>⏰ 入金対応可能時間帯：平日・土曜・日曜・祝日</p>
              <p>⏰ 24時間即時 (日本時間)</p>
              <p>
                💰 最低入金額：{getCurrencySymbol(fiatCurrency)}
                {formatAmount(min, 0)}
              </p>
              <p>
                💰 最大入金額：{getCurrencySymbol(fiatCurrency)}
                {formatAmount(max, 0)}
              </p>
              <p>&nbsp;</p>
              <p>
                *ベガ・ウォレットは、銀行振込を利用して、ウォレットへの入金や勝利金の出金ができます。使い方はとても簡単で、安心・安全な決済方法です。ベガウォレットの登録は+
                <a href="https://vega-wallet.com/" target="_blank" rel="noopener">
                  こちら
                </a>
                +
              </p>
            </>
          ))}
        {(depositInfo.channelCode === 'deskdzjpypay' || depositInfo.channelCode === 'banktobank') &&
          (language === 'en-US' ? (
            <>
              <div>
                <span style={{ color: 'rgb(224, 62, 45)' }}>
                  If the name you enter does not match the name of the bank account holder, you will not be
                  able to make a deposit. Please check the name printed on your bank cash card before entering
                  it.
                </span>
              </div>
              <p>KYC required</p>
              <p>Bank Transfer allows you to make deposits from your online bank or ATM.</p>
              <p>⏰Weekday, Saturday, Sunday, Holiday</p>
              <p>⏰24/7 (Japan time)</p>
            </>
          ) : (
            <>
              <div>
                <span style={{ color: 'rgb(224, 62, 45)' }}>
                  入力した名前が銀行口座名義人と一致しない場合、ご入金することができません。銀行キャッシュカードに印刷されている名義人名をご確認の上ご入力ください。
                </span>
              </div>
              <p>本人確認の手続きが必要です。</p>
              <p>銀行振込を利用することで、お客様のオンラインバンクやATMからご入金が可能です。</p>
              <p>⏰ 銀行振込対応可能時間帯：平日・土曜・日曜・祝日</p>
              <p>⏰ 24時間即時 (日本時間)</p>
            </>
          ))}
        {depositInfo.channelCode === 'coinPayment' &&
          (language === 'en-US' ? (
            <>
              <p>
                ₿ CoinPayment allows you to make deposits in virtual currencies such as Bitcoin and Ethereum.
              </p>
              <p>⏰ Weekday, Saturday, Sunday, Holiday</p>
              <p>⏰ 24/7 (Japan time)</p>
              <p>
                💰Minimum deposit amount:
                <br />
                {min} {virtualCurrency}
              </p>
              <p>
                💰Maximum deposit amount:
                <br />
                {max} {virtualCurrency}
              </p>
            </>
          ) : (
            <>
              <p>
                ₿ CoinPaymentを利用することで、ビットコインやイーサリアムなどの仮想通貨でのご入金が可能です。
              </p>
              <p>⏰ 入金対応可能時間帯：平日・土曜・日曜・祝日</p>
              <p>⏰ 24時間即時 (日本時間)</p>
              <p>
                💰 最低入金額：
                <br />
                {min} {virtualCurrency}
              </p>
              <p>
                💰 最大入金額：
                <br />
                {max} {virtualCurrency}
              </p>
            </>
          ))}
        {depositInfo.channelCode === 'tigerpay' &&
          (language === 'en-US' ? (
            <>
              <p>🔂 Tiger Pay allows you to make deposits from your e-wallet.</p>
              <p>⏰ Weekday, Saturday, Sunday, Holiday</p>
              <p>⏰ 24/7 (Japan time)</p>
              <p>
                💰 Minimum deposit amount: {getCurrencySymbol(fiatCurrency)}
                {formatAmount(min, 0)}
              </p>
              <p>
                💰 Maximum deposit amount: {getCurrencySymbol(fiatCurrency)}
                {formatAmount(max, 0)}
              </p>
              <p>&nbsp;</p>
              <p>
                *Tiger Pay enables you to fund your wallet and withdraw your winnings using Bank Transfers.
                It's very easy to use, completely safe and secure. Register your
                <a href="https://secure.tiger-pay.com/dashboard/register" target="_blank">
                  Tiger Pay
                </a>
              </p>
            </>
          ) : (
            <>
              <p>🔂 Tiger Payを利用することで、e-walletでのご入金が可能です。</p>
              <p>⏰ 入金対応可能時間帯：平日・土曜・日曜・祝日</p>
              <p>⏰ 24時間即時 (日本時間)</p>
              <p>
                💰 最低入金額：{getCurrencySymbol(fiatCurrency)}
                {formatAmount(min, 0)}
              </p>
              <p>
                💰 最大入金額：{getCurrencySymbol(fiatCurrency)}
                {formatAmount(max, 0)}
              </p>
              <p>&nbsp;</p>
              <p>*</p>
              <p>
                タイガーペイは銀行振込を利用してウォレットへの入金や勝利金の出金ができます。使い方はとても簡単。安心・安全な決済方法です。
                タイガーペイの登録は
                <a target="_blank" href="https://secure.tiger-pay.com/dashboard/register">
                  +こちら+
                </a>
              </p>
            </>
          ))}
        {depositInfo.channelCode === 'naticWallet' &&
          (language === 'en-US' ? (
            <>
              <p>
                Register for Natic Wallet:
                <a href="https://naticwallet.com/register" target="_blank">
                  https://naticwallet.com/register
                </a>
              </p>
              <p>
                Natic Wallet Website:
                <a href="https://naticwallet.com" target="_blank">
                  https://naticwallet.com
                </a>
              </p>
              <p>
                Natic Wallet Guide:
                <a href="https://www.naticwallet.com/introduction-web" target="_blank">
                  https://www.naticwallet.com/introduction-web
                </a>
              </p>
              <p>&nbsp;</p>
              <p>
                [Features]
                <br />
                1.Purchase points 24/7. Wallet funds reflect in
                <br />
                1-5 minutes.
                <br />
                2.Send points to a member store through the checkout screen. Points are delivered once
                processing is complete.
                <br />
                3.Complete the member store's checkout to get points. They will be transferred to your Natic
                Wallet instantly upon transaction completion.
                <br />
                4.We will convert Natic Wallet points into Japanese yen and transfer them to your bank
                account.
              </p>
            </>
          ) : (
            <>
              <p>
                Natic Wallet登録:
                <a href="https://naticwallet.com/register" target="_blank">
                  https://naticwallet.com/register
                </a>
              </p>
              <p>
                Natic Wallet公式サイト:
                <a href="https://naticwallet.com" target="_blank">
                  https://naticwallet.com
                </a>
              </p>
              <p>
                Natic Walletガイド:
                <a href="https://www.naticwallet.com/introduction-web" target="_blank">
                  https://www.naticwallet.com/introduction-web
                </a>
              </p>
              <p>&nbsp;</p>
              <p>
                [機能特性]
                <br />
                1.ポイントは24時間いつでも購入可能です。ウォレットの資金は15〜20分以内に反映されます。
                <br />
                2.チェックアウト画面を通じてメンバーストアにポイントを送信します。処理が完了次第、ポイントが配信されます。
                <br />
                3.メンバーストアのチェックアウトを完了すると、ポイントが入手できます。取引完了後、すぐにNaticウォレットに転送されます。
                <br />
                4.Naticウォレットのポイントを日本円に変換し、銀行口座に振り込みます。
              </p>
            </>
          ))}
        {depositInfo.channelCode === 'payz' &&
          (language === 'en-US' ? (
            <>
              <p>
                🔂Payz allows you to make deposits from your <br /> e-wallet.
              </p>
              <p>⏰ Weekday, Saturday, Sunday, Holiday</p>
              <p>⏰ 24/7 (Japan time)</p>
              <p>
                💰 Minimum deposit amount: {getCurrencySymbol(fiatCurrency)}
                {formatAmount(min, 0)}{' '}
              </p>
              <p>
                💰 Maximum deposit amount: {getCurrencySymbol(fiatCurrency)}
                {formatAmount(max, 0)}{' '}
              </p>
              <p>&nbsp;</p>
              <p>
                *Payz enables you to fund your wallet and withdraw your winnings using Crypto. It's very easy
                to use, completely safe and secure. Register your
                <a href="https://payz.com/" target="_blank">
                  Payz
                </a>
              </p>
            </>
          ) : (
            <>
              <p>🔂 Payzを利用することで、e-walletでのご入金が可能です。</p>
              <p>⏰ 入金対応可能時間帯：平日・土曜・日曜・祝日</p>
              <p>⏰ 24時間即時 (日本時間)</p>
              <p>
                💰 最低入金額： {getCurrencySymbol(fiatCurrency)}
                {formatAmount(min, 0)}{' '}
              </p>
              <p>
                💰 最大入金額： {getCurrencySymbol(fiatCurrency)}
                {formatAmount(max, 0)}{' '}
              </p>
              <p>&nbsp;</p>
              <p>
                *Payzは、仮想通貨を利用して、ウォレットへの入金や勝利金の出金ができます。使い方はとても簡単で、安心・安全な決済方法です。Payzの登録は+
                <a href="https://payz.com/" target="_blank">
                  こちら
                </a>
                +
              </p>
            </>
          ))}
        {depositInfo.channelCode === 'naticPay' &&
          depositInfo.payType !== 'creditP93' &&
          (language === 'en-US' ? (
            <>
              <p>🔂STICPAY allows you to make deposits from your e-wallet.</p>
              <p>⏰ Weekday, Saturday, Sunday, Holiday</p>
              <p>⏰ 24/7 (Japan time)</p>
              <p>
                💰 Minimum deposit amount: {getCurrencySymbol(fiatCurrency)}
                {formatAmount(min, 0)}{' '}
              </p>
              <p>
                💰 Maximum deposit amount: {getCurrencySymbol(fiatCurrency)}
                {formatAmount(max, 0)}{' '}
              </p>
              <p>&nbsp;</p>
              <p>
                *STICPAY enables you to fund your wallet and withdraw your winnings using Crypto. It's very
                easy to use, completely safe and secure. Register your
                <a href="https://www.sticpay.com/" target="_blank">
                  STICPAY
                </a>
              </p>
            </>
          ) : (
            <>
              <p>🔂 STICPAYを利用することで、e-walletでのご入金が可能です</p>
              <p>⏰ 入金対応可能時間帯：平日・土曜・日曜・祝日</p>
              <p>⏰ 24時間即時 (日本時間)</p>
              <p>
                💰 最低入金額： {getCurrencySymbol(fiatCurrency)}
                {formatAmount(min, 0)}{' '}
              </p>
              <p>
                💰 最大入金額： {getCurrencySymbol(fiatCurrency)}
                {formatAmount(max, 0)}{' '}
              </p>
              <p>&nbsp;</p>
              <p>
                *STICPAYは、仮想通貨を利用して、ウォレットへの入金や勝利金の出金ができます。使い方はとても簡単で、安心・安全な決済方法です。STICPAYの登録は+
                <a href="https://www.sticpay.com/ja-JP" target="_blank">
                  こちら
                </a>
                +
              </p>
            </>
          ))}
        {depositInfo.channelCode === 'creditP93' &&
          (language === 'en-US' ? (
            <>
              <p>🔂You can deposit by Credit Card.</p>
              <p>⏰ Weekday, Saturday, Sunday, Holiday</p>
              <p>⏰ 24/7 (Japan time)</p>
              <p>
                💰 Minimum deposit amount: {getCurrencySymbol(fiatCurrency)}
                {formatAmount(min, 0)}{' '}
              </p>
              <p>
                💰 Maximum deposit amount: {getCurrencySymbol(fiatCurrency)}
                {formatAmount(max, 0)}{' '}
              </p>
              <p>&nbsp;</p>
              <p>
                *You can deposit funds into your wallet using a credit card. It's very simple to use and
                offers a safe and secure payment method.
              </p>
            </>
          ) : (
            <>
              <p>🔂 クレジットカードでのご入金が可能です。</p>
              <p>⏰ 入金対応可能時間帯：平日・土曜・日曜・祝日</p>
              <p>⏰ 24時間即時 (日本時間)</p>
              <p>
                💰 最低入金額： {getCurrencySymbol(fiatCurrency)}
                {formatAmount(min, 0)}{' '}
              </p>
              <p>
                💰 最大入金額： {getCurrencySymbol(fiatCurrency)}
                {formatAmount(max, 0)}{' '}
              </p>
              <p>&nbsp;</p>
              <p>
                *クレジットカードを利用して、ウォレットへの入金ができます。使い方はとても簡単で、安心・安全な決済方法です。
              </p>
            </>
          ))}
        {depositInfo.channelCode === 'jetonPaypay' &&
          (language === 'en-US' ? (
            <>
              <p>
                Bank Transfer EX is a new payment method where you input the deposit amount and select a
                transfer destination from the options provided. It allows direct settlement of deposit
                transactions, saving both time and fees.
              </p>
              <p>
                Please note that your bank account information may be visible to the transaction recipient.
                Additionally, separate transfer fees may apply.
              </p>
            </>
          ) : (
            <>
              <p>
                銀行振込EXは、入金額を入力し、表示された選択肢の中から振込先を選択する新しい支払い方法です。
                入金取引を直接決済できるため、時間と手数料を節約できます。
              </p>
              <p>
                ただし、お客様の銀行口座情報が取引先に表示される場合があることにご注意ください。
                また、別途振込み手数料がかかる場合がございます。
              </p>
            </>
          ))}
        {/* {depositInfo.channelCode === 'coinPayment' && (language === 'en-US' ? <></> : <></>)} */}
      </div>
    </div>
  )
}

export default DepositText
