export const verification = {
  title: 'アカウント認証',
  btn: 'アカウント情報',
  kyc: 'KYC書類を提出する際、個人ページの情報とお客様の身分証明書情報が一致しない場合、口座確認に遅れが生じます。',
  photoVerify2: 'アップロードする画像は 10MB 未満にする必要があります',
  kyecLevel: 'レベル',
  successOperation: '操作は成功しました。レビューをお待ちください',
  operationSuccess: '操作成功',
  table: {
    date: '日時',
    fileName: 'ファイル名',
    size: 'サイズ'
  },
  status: {
    unVefiry: '確認待ち', //待认证
    verifying: '未認証', //认证中
    Approved: '認証済み', //认证成功
    Rejected: '認証却下' //认证失败
  },
  statusBtn: {
    Authenticated: '確認待ち', //待认证
    verifying: '保留中'
  },
  uploadModel: {
    unUploadPhoto: '写真がアップロードされていません',
    takeNeed: '撮影条件',
    takeTips: 'サンプル画像に従って写真を撮影し、顔の特徴が写真内で明確であることを確認してください。',
    takeBtn: '写真を撮る/アップロードする'
  },
  moduleOne: {
    title001: 'アイデンティティ情報',
    title002: '電話番号 & E-Mail',
    subTitle001:
      '名前はクレジット カードおよび請求書と一致している必要があります。認証コードを受信できる携帯電話番号と電子メール アドレス。',
    text001: 'メールアドレス認証:',
    text002: '認証済み',
    text003: 'メールアドレスが承認されました。',
    text004: 'お客様のメールアドレスを確認する必要があります。',
    text005: 'これは、お客様のメールアドレスが有効で、電子メールを受信してやり取りできることを確認します。',
    pending: '保留中',
    pendingDes: 'メールアドレスが承認されなかった',
    Verified: '確認する',
    Approved: '認証済み'
  },
  moduleTwo: {
    modelTitle: 'レベル 2 検証',
    modelId: '身分証',
    modelHoldID: '身分証と一緒に映った自撮り（身分証の文字が鮮明に映った状態）',
    modelWaitHold: 'アップデート待ち',
    modelUpload: 'ファイルを追加',
    modelSave: '保存',
    title001:
      '写真付き身分証明書 1点（マイナンバーカード / 運転免許証 / パスポート / 写真付き住民基本台帳カード）',
    title002: '身分証と一緒に映った自撮り（身分証の文字が鮮明に映った状態）',
    subTitle001: '有効期限内の書類のみ有効',
    text001: '本人確認書類:',
    text002: '未承認',
    text003: '本人確認書類をアップロードしてください。',
    text004: 'ファイルを選択',
    text005: 'ファイルを追加',
    text006: '保存',
    text007: ' 本人確認書類をアップロードしてください。',
    fail: '却下',
    failTitle: '本人認証に失敗いたしました。',
    failDes: 'ご不明の場合はカスタマーサポートまでご連絡ください。',
    success: '認証済み',
    successDes: '本人確認書類が承認されました。',
    uploadIDTitle: '身分証',
    uploadIDDes: 'アップデート待ち',
    uploadBillTitle: '住所確認書類',
    uploadBillDes: 'アップデート待ち',
    onlyUploadOne: '下記の書類2点をアップロードしてください',
    uploadSuccess: 'ファイルのアップロードが完了しました。'
  },
  moduleThree: {
    modelBills: '住所確認書類',
    modelTitle: 'レベル 3 認証',
    title001:
      '住民票 / 水道・電気・ガス代・電信通話の請求書でご住所、発行日がわかるもの / 郵送されたクレジットカードご利用明細書',
    subTitle001: '3か月以内に発行された書類のみ有効',
    text001: '本人確認には、以下の書類2点が必要になります。',
    text002: '下記の書類をアップロードしてください。',
    text003:
      '写真付き身分証明書 1点（マイナンバーカード / 運転免許証 / パスポート / 写真付き住民基本台帳カード）',
    text004: '発行日から3ヶ月以内の住所確認書類 1点',
    text005:
      '（住民票 / 水道・電気・ガス代・電信通話の請求書でご住所、発行日がわかるもの / 郵送されたクレジットカードご利用明細書）',
    text006: 'ファイル形式：',
    text007: '.gif, .jpeg, .jpg, .png, .tif, .tiff',
    text008: '(最大ファイルサイズ 5 MB)',
    text009: 'また、出金の際にはご入金方法により、別の確認書類が必要になる場合がございます。',
    text010: '- クレジットカード画像',
    text011: '- 各ウォレット口座のスクリーンショット など'
  },
  moduleFour: {
    modelTitle: 'レベル 4 認証',
    title001: '資金源/収入証明書',
    subTitle001:
      '収入証明書をアップロードしてください。すべての書類は、平面に置き、その四隅を枠内に収め、すべての情報がはっきり見えるようにしなければなりません。',
    text001: '日時',
    text002: 'ファイル名',
    text003: 'サイズ'
  },
  // verification: {
  mainTips: {
    title: ' どうして、本人確認が必要ですか。',
    content: `"クイーンカジノでは、お客様により安心してご利用いただくために本人確認書類の提出をお願いしております。
        これはクイーンカジノが保有するライセンスに基づく義務であり、厳しい監査・監視のもとでライセンスを維持するため必要となるものです。
        また、マネーロンダリング対策、お客様の資金保護のためにも必要となる大変重要な手続きとなりますのでご協力いただけますようお願い申し上げます。
        ご提出いただきました本人確認書類は厳重に管理いたしますので、ご安心くださいませ。"`
  }
  // }
} as const
