'use client'
import { useAppStore } from '@/stores/app'
import { useRouter, useParams } from 'next/navigation'
import { cn, decimalFormat } from '@/lib/utils'
import styles from './index.module.scss'
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import dayjs from 'dayjs'
import { useUserStore } from '@/stores/user'
import React from 'react'
import { getRewardType } from './utils'
import { DefaultList } from '../defaultPromo.js'
import { applyFreeSpin, giveUPBonus, transferOutAll, releaseBonus } from '@/api/activity'
import { getGameList } from '@/api/game'
import { openGame } from '@/app/[lng]/(game)/_components/startGame'
import RewardSwiper from '../rewardSwiper'
import RewardCard from '../rewardCard'
import CancelBonus from '../cancelBonus'
import BonusReceive from '../bonusReceive'
import { useTranslation } from '@/lib/locales/client'
import { useShallow } from 'zustand/react/shallow'
const RewardCoupons = (props: any) => {
  const { cardType } = props
  const { freeSpinList, bonusAndTask, getBonusTaskList, SET_MONEY_TYPE_SELECT_INDEX, SET_BONUS_COUPON_ITEM } =
    useUserStore(
      useShallow((state) => ({
        freeSpinList: state.freeSpinList,
        bonusAndTask: state.bonusAndTask,
        getBonusTaskList: state.getBonusTaskList,
        SET_MONEY_TYPE_SELECT_INDEX: state.SET_MONEY_TYPE_SELECT_INDEX,
        SET_BONUS_COUPON_ITEM: state.SET_BONUS_COUPON_ITEM
      }))
    )
  const { setIsShowNotification, SET_GLOBAL_MSG } = useAppStore(
    useShallow((state) => ({
      setIsShowNotification: state.setIsShowNotification,
      SET_GLOBAL_MSG: state.SET_GLOBAL_MSG
    }))
  )
  const router = useRouter()
  const { lng } = useParams()
  const { t } = useTranslation()
  const [spinning, setSpinning] = useState(false)
  const [receiveDialog, setReceiveDialog] = useState(false)
  const [dialogData, setDialogData] = useState({})
  const [freeSpinData, setFreeSpinData] = useState([])
  const [cancelId, setCancelId] = useState('')
  const prevFreeSpinListRef: any = useRef()
  prevFreeSpinListRef.current = freeSpinList
  const isProgress = useMemo(() => {
    return ['bonus', 'quest', 'extra'].includes(cardType)
  }, [cardType])

  const couponsList = useMemo(() => {
    let list = []
    if (cardType === 'bonus') {
      list = bonusAndTask.filter((item) => [3, 5, 10].includes(item.bonusStatus) && item.bonusType === 4)
    } else if (cardType === 'quest') {
      list = bonusAndTask.filter((item) => [3, 5, 10].includes(item.bonusStatus) && item.bonusType === 5)
    } else if (cardType === 'extra') {
      list = bonusAndTask.filter((item) => [3, 5, 10].includes(item.bonusStatus) && item.bonusType === 0)
    } else if (cardType === 'available') {
      list = [...bonusAndTask.filter((item) => item.status === 2), ...freeSpinData]
    } else if (cardType === 'freeSpin') {
      list = freeSpinData
    }

    list.map((el) => {
      if (cardType === 'freeSpin' || el.roundNum) {
        el.rewardName = el.prizeName
        el.rewardType = 'freespin'
      } else {
        const rewardType = getRewardType(el)
        el.awardMoney =
          cardType === 'available' ? Number(el.fetchAmount) || Number(el.bonusAmount) : Number(el.amount)
        const item = DefaultList.find((ff) => ff.rewardType === rewardType)
        el.rewardType = rewardType
        el.awardCurrency = el.fetchCurrency || el.bonusCurrency
        if (item) {
          el.fixedDetailsJp = item.fixedDetailsJp
          el.fixedDetailsEn = item.fixedDetailsEn
        }
      }
    })
    return list
  }, [cardType, freeSpinData, bonusAndTask])

  const maxString = (num: number) => {
    const str = num.toString()
    if (str.length > 10) {
      return str.slice(0, 10) + '...'
    }
    return str
  }

  const _releaseBonus = (item) => {
    const params = { bonusId: item.requestId }
    transferOutAll(params).finally(() => {
      releaseBonus(params).then((res) => {
        if (res && res.success) {
          SET_GLOBAL_MSG({ flag: true, type: 'success', content: t('bonus.give') })
          getBonusTaskList()
        }
      })
    })
  }

  const handleCancel = () => {
    const params = { bonusId: cancelId }
    giveUPBonus(params).then((res) => {
      if (res && res.success) {
        setCancelId('')
        getBonusTaskList()
        SET_GLOBAL_MSG({ flag: true, type: 'success', content: t('bonus.up') })
      }
    })
  }

  const play = (item) => {
    //前置优惠券选中它
    if (item.bonusType === 4) {
      SET_MONEY_TYPE_SELECT_INDEX(1)
      SET_BONUS_COUPON_ITEM(item)
    }
    router.push(`/${lng}/pachinko`)
  }

  const clickReceive = (item) => {
    setDialogData(item)
    setReceiveDialog(true)
  }

  const handleReceive = () => {
    getBonusTaskList()
    setReceiveDialog(false)
  }

  const getFreeGame = () => {
    const gameIds = freeSpinList.map((item) => item.gameId)
    if (gameIds.length > 0 && ['available', 'freeSpin'].includes(cardType)) {
      setSpinning(true)
      getGameList({
        gameIds,
        pageSize: 999,
        hideLoading: true
      })
        .then(({ success, data }: any) => {
          if (success && data?.data.length > 0) {
            const arr = data?.data
            const list = []
            arr.forEach((item: any) => {
              freeSpinList.forEach((val) => {
                if (item.gameId === val.gameId && item.platformCode === val.providerCode) {
                  list.push({ ...val, ...item })
                }
              })
            })
            setFreeSpinData(
              list.sort((a, b) => new Date(b.useExpiredDate).getTime() - new Date(a.useExpiredDate).getTime())
            )
          } else {
            setFreeSpinData([])
          }
        })
        .finally(() => setSpinning(false))
    } else {
      setFreeSpinData([])
    }
  }

  const playGame = (val: any) => {
    if (val.bizCheckStatus === 4) {
      const params = {
        activityCode: val.activityCode,
        id: val.id
      }

      applyFreeSpin(params)
        .then((res: any) => {
          if (res && res.data) {
            setIsShowNotification(false)
            openGame(0, val, val.providerCurrency)
          }
        })
        .finally(() => {
          getBonusTaskList()
        })
    } else {
      setIsShowNotification(false)
      openGame(0, val, val.providerCurrency)
    }
  }
  useEffect(() => {
    freeSpinList.length ? getFreeGame() : setFreeSpinData([])
  }, [freeSpinList.length])

  useEffect(() => {
    if (cardType === 'freeSpin') {
      getFreeGame()
    }
  }, [cardType])
  const btns = useCallback((item: any) => {
    return (
      <>
        {cardType === 'freeSpin' || item?.roundNum ? (
          <a onClick={() => playGame(item)}>{t('promo.play')}</a>
        ) : cardType === 'available' ? (
          <a onClick={() => clickReceive(item)}>{t('promo.claim')}</a>
        ) : item.bonusStatus === 10 ? (
          <a onClick={() => _releaseBonus(item)}>{t('promo.claim')}</a>
        ) : (
          <React.Fragment>
            <a onClick={() => setCancelId(item?.requestId)}>{t('promo.cancel')}</a>
            <a onClick={() => play(item)}>{t('promo.play')}</a>
          </React.Fragment>
        )}
      </>
    )
  }, [])

  return (
    <div>
      <RewardSwiper
        swipData={couponsList}
        spinning={spinning}
        cardType={cardType}
        render={(item: any, key: string) => {
          return (
            <>
              {isProgress && (
                <div className={styles['progress_warp']}>
                  <div className="progress-line-warp">
                    <div className="progress-line">
                      <div
                        style={{
                          width:
                            (Number(item?.betAmount) / (Number(item?.requiredBetAmount) || 1)) * 100 + '%'
                        }}
                      ></div>
                    </div>
                    <a>
                      {Math.min(
                        (Number(item?.betAmount) / (Number(item?.requiredBetAmount) || 1)) * 100,
                        100
                      ).toFixed(0)}
                      %
                    </a>
                  </div>
                  <div className="progress-text">
                    <p>
                      <span>{t('promo.expirationDate')}</span>
                      {dayjs(item.usedExpiredDate).format('YYYY-MM-DD HH:mm:ss')}
                    </p>
                    <p>
                      {maxString(decimalFormat(item.betAmount, item.awardCurrency))} /
                      {maxString(decimalFormat(item.requiredBetAmount, item.awardCurrency))}
                    </p>
                  </div>
                </div>
              )}
              <RewardCard
                cardInfo={item}
                cardType={cardType}
                useBtns={true}
                btns={btns(item)}
                key={key}
              ></RewardCard>
            </>
          )
        }}
      ></RewardSwiper>
      {!!cancelId && <CancelBonus close={() => setCancelId('')} cancelBonus={handleCancel} />}

      {receiveDialog && (
        <BonusReceive
          dialogData={dialogData}
          close={() => setReceiveDialog(false)}
          receiveBonus={handleReceive}
        />
      )}
    </div>
  )
}
export default memo(RewardCoupons)
