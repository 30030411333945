'use client'
import { memo, useRef, useState, useEffect, useLayoutEffect } from 'react'
import { useAppStore } from '@/stores/app'
import { useUserStore } from '@/stores/user'
import { useAuthStore } from '@/stores/authControl'
import { useTranslation } from '@/lib/locales/client'
import { cn } from '@/lib/utils'
import { Form, Input, Button } from 'antd'
import { useClickAway } from 'ahooks'
import Validtor from '@/hooks/validtor'
import useCountDown from '@/hooks/useCountDown'
import { useRouter, usePathname, useParams } from 'next/navigation'
import { track } from '@/hooks/useTrack'
import { Decrypt, Encrypt } from '@/hooks/encrypt'
import { useCookies } from 'react-cookie'
// api *
import { logApi } from '@/api/auth'
import { sendCodeByPhone } from '@/api/account'
import { useShallow } from 'zustand/react/shallow'
import styles from './_common_css/login.module.scss'
// com
import SliderCaptcha from '@/components/common/sliderCaptcha'
import LockedDialog from './lockedDialog'
import CountrySelect from '@/components/base/countrySelect'
import { openGame } from '@/app/[lng]/(game)/_components/startGame'

/* init */
const P_B_KEY = 'J1PTY42LFGZAYBSS'
const P_REM_STR = 'Q_C_I_W'
const P_REM_STR2 = 'Q_C_I_W_N'

// login菜单切换
const loginMenuList = [
  { label: 'user.authDialog.logPW', type: 'PWLogin' },
  { label: 'user.authDialog.regPhone', type: 'CodeLogin' }
]

const Login = () => {
  const { t } = useTranslation()
  const router = useRouter()
  const pathName = usePathname()
  const rawPath = pathName.replace(/^\/(en|ja)($|\/)/g, '/')
  const { isBigScreen, commonConfig, limitLogin, countryCurInfo, updateLimitLoginDialog, setForgetDialog } =
    useAppStore(
      useShallow((state) => ({
        isBigScreen: state.isBigScreen,
        commonConfig: state.commonConfig,
        limitLogin: state.limitLogin,
        countryCurInfo: state.countryCurInfo,
        updateLimitLoginDialog: state.updateLimitLoginDialog,
        setForgetDialog: state.setForgetDialog
      }))
    )
  const { logRegVisible, closeModal } = useAuthStore(
    useShallow((state) => ({
      logRegVisible: state.logRegVisible,
      closeModal: state.closeModal
    }))
  )
  const { setWebToken, updateUserInfo, setSeonSession, fingerPrintID } = useUserStore(
    useShallow((state) => ({
      setWebToken: state.setWebToken,
      updateUserInfo: state.updateUserInfo,
      setSeonSession: state.setSeonSession,
      fingerPrintID: state.fingerPrintID
    }))
  )
  const [loginModalType, setLoginModalType] = useState('PWLogin')
  const { lng } = useParams()
  const [form] = Form.useForm()
  const { textRef, start, flag } = useCountDown(60, t('user.authDialog.send'), '')
  const [remberLog, setRemberLog] = useState(true)
  const [showSliderCaptchaPopup, setShowSliderCaptchaPopup] = useState(false)
  const [lockVisibility, setLockVisibility] = useState(false)
  const selectAreaRef = useRef(null) // 选择国家搜索
  const [isOpenCountry, setIsOpenCountry] = useState(false)
  const [cookies, setCookies] = useCookies(['authLog'])

  // 监听地区选择器 开关
  useEffect(() => {
    isOpenCountry ? selectAreaRef.current?.focus() : selectAreaRef.current?.blur()
  }, [isOpenCountry])

  // 点地区选择器面板以外的事件
  useClickAway(
    () => setIsOpenCountry(false), // 当选中地区选择面板以外时，关闭该面板,
    () => document.getElementById('country-select')
  )

  const focusHandle = () => {
    // 获取焦点时 判断地区选择是否有打开
    isOpenCountry ? selectAreaRef.current?.focus() : selectAreaRef.current?.blur()
  }

  useLayoutEffect(() => {
    if (logRegVisible && loginModalType === 'PWLogin') {
      try {
        let _ulockPW = localStorage.getItem(P_REM_STR)
        let _ulockUN = localStorage.getItem(P_REM_STR2)
        _ulockPW = JSON.parse(_ulockPW)
        _ulockUN = JSON.parse(_ulockUN)
        /*  */
        _ulockPW = decrypt(_ulockPW) || ''
        _ulockUN = self.atob(_ulockUN) || ''
        if (_ulockPW) {
          form.setFieldsValue({ userName: _ulockUN, password: _ulockPW })
        }
      } catch (e) {
        console.log(e)
      }
    }
  }, [logRegVisible])

  function chooseType(item) {
    setLoginModalType(item.type)
    onReset()
  }

  // 客服，待处理
  // const contactCS = () => {
  //   window.Intercom('show')
  // }

  // 记住密码
  function handleRember(params) {
    const nameRem = self.btoa(params.loginName)
    const pawRem = encryptParams(params.password)
    try {
      if (remberLog) {
        localStorage.setItem(P_REM_STR, JSON.stringify(pawRem))
        localStorage.setItem(P_REM_STR2, JSON.stringify(nameRem))
      } else {
        localStorage.removeItem(P_REM_STR)
        localStorage.removeItem(P_REM_STR2)
      }
    } catch (err) {
      console.log(err)
    }
  }

  /* 忘记密码 */
  function handForgot() {
    // pc走弹窗  H5 走url 路径
    if (isBigScreen) {
      closeModal()
      setForgetDialog(true)
    } else {
      closeModal()
      router.push(`/${lng}/reset-password`)
    }
  }

  /* ---------------验证相关-start----------- */
  async function captchaCallback(params) {
    await accountLogin(params)
  }

  // 发送验证号
  function sendPhoneVerify(evt) {
    const checkPhoneNum = form.getFieldError('namePhoneEmail') // 手机号验证成功才会继续
    evt.stopPropagation()
    if (!flag || !form.getFieldValue('namePhoneEmail') || checkPhoneNum.length) return
    sendCodeByPhone({
      purpose: 2,
      phonePrefix: '00' + countryCurInfo.code,
      phone: form.getFieldValue('namePhoneEmail')
    }).then((res: any) => {
      if (res.success) start() // 开始60秒倒计时
    })
  }

  /* 记住密码-加解密 */
  function encryptParams(data) {
    return Encrypt(data, P_B_KEY)
  }
  function decrypt(data) {
    try {
      return Decrypt(data, P_B_KEY)
    } catch (error) {
      return ''
    }
  }

  // 账密登录
  async function accountLogin(verifyData = {}) {
    const { userName, password } = form.getFieldsValue(['userName', 'password'])
    // verifyType 0 正常登录  1 邮箱或手机二次验证
    // if (Number(verifyType === 0)) {
    //   setValidData({ verifyType: '' })
    // }
    // 判断类型是用户名还是邮箱, 2邮箱, 1账号
    const sendType = Validtor.email(form.getFieldValue('userName')) ? 2 : 1

    // 请求参数封装
    let params = {
      ...verifyData,
      loginName: sendType === 1 ? userName?.toLowerCase() : userName,
      password,
      loginType: sendType,
      grantType: 'web_password'
    }

    // 加验证码登录, 二次手机邮箱验证登录不需要传password
    // if (verifyType === 1 && validData.verifyType) {
    //   // params.authenticationCode = verifyNum
    //   params.grantType = validData.verifyType === 'email' ? 'email2fa' : 'phone2fa'
    //   if (validData.verifyType === 'phone') {
    //     params.phone = params.loginName
    //   }
    // } else {
    //   params.password = password
    // }
    params.password = password

    // console.log('111222?', params)
    // 请求登录接口
    const res: any = await logApi({
      merchantCode: commonConfig.merchantCode,
      ...params
    })
    // console.log('登录数据?', res)
    if (res?.success) {
      // 设置用户信息
      setWebToken(res.data)
      await updateUserInfo(res.data.loginName, 1)
      // setCookies('authLog', '一串密钥', { path: '/' })
      // console.log('更新', cookies.authLog)
      closeModal()
      setSeonSession()
      track('LoginSuccess', { loginName: res.data.loginName })
      if (location.href.includes('/sports-bet')) {
        openGame(0, {
          platformCode: 'HUB88',
          gameId: window.location.href.includes('uat') ? 'btsg_sportsbetting' : 'btsg_sportbetting'
        })
      }

      // 待处理 体育页面如果重新路由，暂时不重载，待观察
      // const { pageReload } = inject('RELOAD')
      if (location.href.includes('/fb')) {
        router.refresh()
        // if (location.href.includes('/fb')) {
        // pageReload()
      }
      /* 缓存 */
      loginModalType === 'PWLogin' && handleRember(params)
      if (rawPath === '/login' || rawPath === '/register') {
        router.push(`/${lng}/`)
      } else {
        router.push(pathName) // 跳转当前页面
      }
    } else {
      switch (res.code) {
        // case 100807: // 弹出2FA
        //   verifyVisibility.value = true
        //   break
        // case 101808:
        //   validData.value = {
        //     verifyType: 'email', // email || phone 类型
        //     email: res.data // email || phone
        //   }
        //   showVerifyCodePopup.value = true
        //   break
        // case 101809:
        //   validData.value = {
        //     verifyType: 'phone', // email || phone 类型
        //     phonePrefix: '',
        //     number: res.data // phone
        //   }
        //   showVerifyCodePopup.value = true
        //   break

        case 100701: // 再次登录时需要弹出滑块验证
          // this.$refs.sliderCaptcha && this.$refs.sliderCaptcha.refresh()
          setShowSliderCaptchaPopup(true)
          break
        case 100700: // 已被锁定账号
          setLockVisibility(true)
          break
      }
    }
  }

  // 手机登录
  async function phoneLogin() {
    const { namePhoneEmail, codeNum } = form.getFieldsValue(['namePhoneEmail', 'codeNum'])
    try {
      const phoneParams = {
        phone: namePhoneEmail,
        grantType: 'phone2fa',
        authenticationCode: codeNum,
        phonePrefix: '00' + countryCurInfo.code
      }
      const result: any = await logApi({
        merchantCode: commonConfig.merchantCode,
        ...phoneParams
      })
      if (result && result.success) {
        console.log('返回请求token', result)
        setWebToken(result.data)
        await updateUserInfo(result.data.loginName, 1)

        closeModal()
        track('LoginSuccess', { loginName: result.data.loginName })
      } else {
        switch (result.code) {
          case 100700: // 已锁定账号，稍后再试
            setLockVisibility(true)
            break
        }
      }
    } catch (err) {
      console.log('phone login err')
    }
  }

  // login
  const onFinish = async (values: any) => {
    // console.log('Received values of form: ', values)
    if (limitLogin) {
      updateLimitLoginDialog(true)
      return
    }
    if (loginModalType === 'PWLogin') {
      await accountLogin()
    } else {
      await phoneLogin()
    }
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }

  const onReset = () => {
    form.resetFields()
  }

  return (
    <>
      <div className={cn(styles['user-login-modal'], !isBigScreen && styles['h5'])}>
        <div className="menu-list">
          {loginMenuList &&
            loginMenuList.map((item, index) => (
              <span
                key={index}
                className={cn(loginModalType == item.type && 'active-menu')}
                onClick={() => chooseType(item)}
              >
                {t(item.label)}
              </span>
            ))}
        </div>
        <Form
          form={form}
          validateTrigger="onBlur"
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          {loginModalType === 'PWLogin' ? (
            <>
              <Form.Item
                name="userName"
                className="!mb-[30px]"
                required={true}
                validateTrigger={['onBlur', 'onChange']}
                rules={[
                  {
                    validator: async (_rule, names) => {
                      if (!names) {
                        return Promise.reject(new Error(t('common.validtor.usenameEpty')))
                      }
                      return Promise.resolve()
                    }
                  }
                ]}
              >
                <Input
                  placeholder={t('user.authDialog.userNamePlac')}
                  allowClear={{
                    clearIcon: (
                      <img
                        src="/static/images/common/input_clear_x.svg"
                        style={{ marginLeft: '10px', width: '18px' }}
                      />
                    )
                  }}
                  onChange={(e) =>
                    form.setFieldsValue({ userName: e.target.value.replace(/[\u4E00-\u9FA5]/g, '') })
                  }
                />
              </Form.Item>
              {/* 密码 */}
              <Form.Item
                name="password"
                className="!mb-[20px]"
                required={true}
                validateTrigger={['onBlur', 'onChange']}
                rules={[
                  {
                    validator: async (_rule, names) => {
                      if (!names) {
                        return Promise.reject(new Error(t('common.validtor.passwordEpty')))
                      }
                      if (!Validtor.password(names)) {
                        return Promise.reject(new Error(t('common.validtor.passwordError'))) // 6-32
                      }
                      return Promise.resolve()
                    }
                  }
                ]}
              >
                <Input.Password
                  placeholder={t('user.authDialog.authPassword')}
                  allowClear={{
                    clearIcon: (
                      <img
                        src="/static/images/common/input_clear_x.svg"
                        style={{ marginLeft: '10px', width: '18px' }}
                      />
                    )
                  }}
                  iconRender={(visible) =>
                    visible ? (
                      <img
                        src="/static/images/user/login/icon-show.svg"
                        style={{ marginLeft: '10px', width: '18px' }}
                      />
                    ) : (
                      <img
                        src="/static/images/user/login/icon-hidden.svg"
                        style={{ marginLeft: '10px', width: '18px' }}
                      />
                    )
                  }
                />
              </Form.Item>
              {/* remember password */}
              <Form.Item name="remember" valuePropName="checked" noStyle>
                <div className={'rember-account'}>
                  <div className={'rember-check'} onClick={() => setRemberLog(!remberLog)}>
                    <div className={cn('check-img', remberLog && 'active')}></div>
                    <span className="text-[13px] font-normal not-italic text-black/65">
                      {t('user.authDialog.rememberAcc')}
                    </span>
                  </div>
                  {/* forget */}
                  <span className="text-[13px] font-normal not-italic text-black/65" onClick={handForgot}>
                    {t('user.authDialog.forgotPS')}
                  </span>
                </div>
              </Form.Item>
            </>
          ) : (
            <>
              {/* verify code */}
              <Form.Item
                name="namePhoneEmail"
                className="!mb-[30px]"
                required={true}
                validateTrigger={['onBlur', 'onChange']}
                rules={[
                  {
                    validator: async (_rule, names) => {
                      if (!names) {
                        return Promise.reject(new Error(t('common.validtor.phoneEpty')))
                      } else {
                        return Promise.resolve()
                      }
                    }
                  }
                ]}
              >
                <Input
                  placeholder={t('user.authDialog.codePlace')}
                  type="number"
                  onFocus={focusHandle}
                  prefix={
                    <CountrySelect
                      domId={'country-select'}
                      ref={selectAreaRef}
                      isOpenCountry={isOpenCountry}
                      setIsOpenCountry={setIsOpenCountry}
                    />
                  }
                />
              </Form.Item>
              <Form.Item
                name="codeNum"
                className="mb-[16px]"
                required={true}
                validateTrigger={['onBlur', 'onChange']}
                rules={[
                  {
                    validator: async (_rule, names) => {
                      if (!names || names.length > 6) {
                        return Promise.reject(new Error(t('common.validtor.codeError')))
                      }
                      return Promise.resolve()
                    }
                  }
                ]}
              >
                <>
                  <Input
                    placeholder={t('user.authDialog.authCodePlace')}
                    onChange={(e) => form.setFieldsValue({ codeNum: e.target.value.replace(/\D/gi, '') })}
                    suffix={
                      <div
                        className="mr-[4px] cursor-pointer text-sm font-semibold text-[#3F75FF]"
                        onClick={sendPhoneVerify}
                      >
                        {textRef}
                      </div>
                    }
                  />
                </>
              </Form.Item>
              <Form.Item name="sendTips" noStyle>
                {/* 未收到验证码-联系客服*/}
                <div
                  className="relative h-[16px]"
                  onClick={() => typeof window !== undefined && window?.Intercom('show')}
                >
                  <p
                    className={cn(
                      'absolute -top-[20px] right-0 mb-[8px] cursor-pointer text-right text-xs font-normal text-black/65 pc:mt-0'
                    )}
                  >
                    {t('user.authDialog.sendTips')}
                  </p>
                </div>

                {/* )} */}
              </Form.Item>
            </>
          )}
          {/* 按钮提交 */}
          <Form.Item name="loginSumbit">
            <Button
              block
              className="!h-[46px] w-full cursor-pointer self-stretch !rounded-none border-none px-[40px] py-[10px]"
              style={{
                background: '#FC005B',
                boxShadow: ' 0px 2px 0px 0px rgba(0, 0, 0, 0.15)'
              }}
              type="primary"
              htmlType="submit"
            >
              <span className="text-lg font-bold text-white">{t('user.login')}</span>
            </Button>
          </Form.Item>
        </Form>
        {/* 滑动验证模块 login 不用带qc前缀 */}
        {showSliderCaptchaPopup && (
          <SliderCaptcha
            use={2}
            fullTxt={t('user.login')}
            loginName={form.getFieldValue('userName')}
            callback={captchaCallback}
            close={() => setShowSliderCaptchaPopup(false)}
          />
        )}

        {/* 账号锁定弹窗 */}
        <LockedDialog visible={lockVisibility} />

        {/* 2FA丢失弹窗提示 */}
        {/* <Lost2faPopup v-model="lostVisibility" /> */}

        {/* 2FA验证 */}
        {/* <Verify2fa
      v-model="verifyVisibility"
      @toLogin="continueLogin"
    /> */}

        {/* 邮箱验证 */}
        {/* <ValidDataPopup
            v-if="showVerifyCodePopup"
            isUseToLogin
            :purpose="2"
            :loginName="formState.username"
            :validData="validData"
            @verify="finishVerify"
          /> */}
      </div>
    </>
  )
}

export default memo(Login)
