'use client'
import { useState, useEffect, useMemo, memo } from 'react'
import { cn, onHandleAlt } from '@/lib/utils'
import { Flex, Collapse } from 'antd'
import type { CollapseProps } from 'antd'
import { useDeepCompareEffect } from 'ahooks'
import meta from '@/app/[lng]/meta'
import { useTranslation } from '@/lib/locales/client'
import { Lng } from '@/lib/locales/i18n'
import { usePathname, useParams } from 'next/navigation'
import { FollowSocialMedia } from '@/constants/followUs'
import { cmsTemplateApi } from '@/api/base'
import { useAuthStore } from '@/stores/authControl'
import { useAppStore } from '@/stores/app'
import { useUserStore } from '@/stores/user'
import { useRedirect } from '@/lib/useRedireact'
import { numThFilter } from '@/lib/filter'
import Link from 'next/link'
import { PlusOutlined, MinusOutlined } from '@ant-design/icons'
import styles from '../_common_css/footer.module.scss'
import { useShallow } from 'zustand/react/shallow'
/**com */
import QueryLang from '@/components/base/queryLang'

const FooterBase = () => {
  const { t } = useTranslation()
  const globalPublickKey = useAppStore((state) => state.globalPublickKey)
  const openLogin = useAuthStore((state) => state.openLogin)
  const isLogin = useUserStore((state) => state.isLogin)
  const { currencyRateFooter, currency, jpyToUsd } = useUserStore(
    useShallow((state) => ({
      currencyRateFooter: state.currencyRateFooter,
      currency: state.currency,
      jpyToUsd: state.jpyToUsd
    }))
  )
  const { lng }: { lng: Lng } = useParams()
  const jumpURL = useRedirect()
  const pathName = usePathname()
  const rawPath = pathName.replace(/^\/(en|ja)($|\/)/g, '/')
  const [footerpaymentData, setFooterpaymentData] = useState([])
  const [footerpaytypeData, setFooterpaytypeData] = useState([])
  const [footerfiatData, setFooterfiatData] = useState([])
  const [currencyRateFmt, setCurrencyRateFmt] = useState('')
  const [footergamesupplierData, setFootergamesupplierData] = useState([])
  const [ambassadorImg, setAmbassadorImg] = useState('')
  const [jumpLink, setJumpLink] = useState('')
  const [ambassadorObj, setAmbassadorObj] = useState(null)

  const curYear = useMemo(() => new Date().getFullYear(), [])
  const dataList: any = useMemo(() => t('common.footer.H5Links', { returnObjects: true, lng: lng }), [lng])
  const hideFooter = useMemo(() => {
    if (Object.keys(meta).length) return meta[rawPath]?.hideFooter
  }, [pathName])

  const getCMSData = async () => {
    const params = {
      moduleCodeList: [
        'footerAmbassador',
        'footerpayment',
        'footerpaytype',
        'footerfiat',
        'footergamesupplier'
      ]
    }
    const cmsData: Resp = await cmsTemplateApi(params)
    if (cmsData?.success) {
      // 1st image official partner
      const getAmbassador = cmsData.data.find((item) => item.moduleCode === 'footerAmbassador') || {}
      setAmbassadorImg(getAmbassador?.mediaLibrary[0]?.fullFilePath || '')
      setJumpLink(getAmbassador?.templateAction || '')
      setAmbassadorObj(getAmbassador)
      setFooterpaymentData(cmsData.data.filter((item: any) => item.moduleCode === 'footerpayment'))
      setFooterpaytypeData(cmsData.data.filter((item: any) => item.moduleCode === 'footerpaytype'))
      setFooterfiatData(cmsData.data.filter((item: any) => item.moduleCode === 'footerfiat'))
      setFootergamesupplierData(cmsData.data.filter((item: any) => item.moduleCode === 'footergamesupplier'))
    }
  }

  const Licenses = [
    {
      icon: 'gamcare',
      path: 'https://www.gamcare.org.uk/'
    },
    {
      icon: 'ga',
      path: 'https://www.gamblersanonymous.org.uk/'
    },
    {
      icon: '18'
    },
    {
      icon: 'gpwa',
      path: 'https://www.gpwa.org/affiliate-program/og-affiliate'
    }
  ]

  // 注意这个依赖是初始才监听，如果货币切换要求实时，要加其它监听值
  useDeepCompareEffect(() => {
    // currency: 当前币种为法币时，展示法币符号；为虚拟币时，对应展示USD
    const { targetAmount, sourceCurrency } = currencyRateFooter || {}
    // console.log('currency', currencyRateFooter, currency)
    if (Object.keys(currency).length) {
      if (currency?.currency === 'JPY') {
        setCurrencyRateFmt(`1 JPY = ${numThFilter(jpyToUsd, 5)} USD`)
      } else if (currency?.currency === 'USD') {
        setCurrencyRateFmt(`1 USD = ${numThFilter(1 / jpyToUsd, 2)} JPY`)
      } else {
        const num = currency?.currency === 'SHIB' ? 5 : 2
        setCurrencyRateFmt(`1 ${sourceCurrency} = ${numThFilter(targetAmount / jpyToUsd || 0, num)} JPY`)
      }
    }
  }, [currency, currencyRateFooter])

  useEffect(() => {
    let script = document.createElement('script')
    script.src = 'https://67775a69-38c7-41dd-81de-2d3e0690fbc0.seals.dlagglobal.com/dlag-seal.js'
    script.async = true
    document.getElementsByTagName('body')[0].appendChild(script)
  }, [])

  useEffect(() => {
    if (globalPublickKey) getCMSData()
  }, [globalPublickKey])

  // links collapse
  const items: CollapseProps['items'] = dataList.map((item, index) => {
    return {
      key: index,
      label: item.title,
      className: 'p-[16px] text-base font-medium !rounded-none',
      style: { borderBottom: '1px solid #e7e7e7', padding: 0 },
      children: item.content.map((unit, idx) => (
        <p
          key={idx}
          className="p-[8px] last:border-none"
          style={
            idx !== item.content.length - 1 ? { borderBottom: '1px solid #e7e7e7' } : { borderBottom: 'none' }
          }
        >
          <Link
            // onClick={(event) => {
            //   event.stopPropagation()
            //   jumpURL(unit.link)
            // }}
            // href={unit.link}
            href={unit.title === 'Affiliate' ? unit.link : `/${lng}${unit.link}`}
            className="cursor-pointer text-xs font-bold"
            style={{
              color: '#7f7f7f'
            }}
            // href={getJaUrl(unit.link)}
          >
            {t(unit.title)}
          </Link>
        </p>
      ))
    }
  })

  return (
    <>
      {!hideFooter && (
        <footer className={styles['ac-footer']}>
          <article className={'m-auto block w-full bg-white pb-0'}>
            {/* FooterLinks  */}
            <div>
              <Collapse
                accordion
                ghost
                items={items}
                className="mb-0 bg-white"
                expandIconPosition={'end'}
                expandIcon={({ isActive }) =>
                  isActive ? <MinusOutlined className="text-base" /> : <PlusOutlined className="text-base" />
                }
              />
            </div>
            {ambassadorImg && (
              <div className="ambass-box">
                <img
                  src={ambassadorImg}
                  className={'ambass-img'}
                  onClick={() => jumpURL(jumpLink)}
                  alt={onHandleAlt(ambassadorObj)}
                />
              </div>
            )}
            {/* footer-social */}
            <Flex gap={8} wrap className={cn('mb-[16px] w-auto flex-[1_1_50%] px-[16px]')}>
              <div onClick={() => jumpURL('https://blog.queen-casino.com/')} className={'social-btn'}>
                <img className={'img1'} src="/static/images/footer/blog.svg" alt="" />
                {t('common.footer.blog')}
              </div>
              <div onClick={() => jumpURL('/videoList')} className={'social-btn'}>
                <img className={'img1'} src="/static/images/footer/msg.svg" alt="" />
                {t('common.footer.streams')}
              </div>
              <div onClick={() => jumpURL('https://queen-casino.info/')} className={'social-btn'}>
                <img className={'img2'} src="/static/images/footer/news.svg" alt="" />
                {t('common.footer.news')}
              </div>
              <div onClick={() => jumpURL('https://www.queen-casino.com/media/')} className={'social-btn'}>
                <img className={'img2'} src="/static/images/footer/icon_3.svg" alt="" />
                {t('common.footer.media')}
              </div>
            </Flex>
            {/* FootPayment */}
            <div className={'px-[16px]'}>
              <Flex gap={6} wrap justify="center" className="m-0 mb-[10px]">
                {footerpaymentData.map((item, index) => (
                  <img
                    key={index}
                    className="h-[28px] w-[60px] grayscale last:mr-0"
                    src={item.mediaLibrary[0]?.fullFilePath}
                    onClick={() => (isLogin ? jumpURL(item.templateAction) : openLogin())}
                  />
                ))}
              </Flex>
              <Flex gap={6} wrap justify="center" className="mb-[10px]">
                {footerpaytypeData.map((item, index) => (
                  <img
                    key={index}
                    className="h-[28px] w-[60px] object-cover grayscale last:mr-0"
                    src={item.mediaLibrary[0]?.fullFilePath}
                    onClick={() => (isLogin ? jumpURL(item.templateAction) : openLogin())}
                  />
                ))}
              </Flex>
              <Flex gap={6} wrap justify="center" className="mb-[10px]">
                {footergamesupplierData.map((item, index) => (
                  <img
                    key={index}
                    className="h-[28px] w-[60px] grayscale last:mr-0"
                    src={item.mediaLibrary[0]?.fullFilePath}
                    onClick={() => jumpURL(item.templateAction)}
                  />
                ))}
              </Flex>
              <Flex gap={6} wrap justify="space-between" className="mb-[10px]">
                {footerfiatData.map((item, index) => (
                  <img
                    className="m-0 h-[18px] w-[18px] grayscale"
                    key={index}
                    src={item.mediaLibrary[0]?.fullFilePath}
                    onClick={() => (isLogin ? jumpURL(item.templateAction) : openLogin())}
                  />
                ))}
              </Flex>
            </div>

            {/* Divider */}
            <div className="mb-[24px] h-[1px] w-full bg-gray-200"></div>
            <Flex vertical className="mb-[16px]">
              <Flex gap={30} justify="center">
                {FollowSocialMedia.map((item, key) => (
                  <a
                    target="_blank"
                    className="flex-col qc-flex-center"
                    key={key}
                    href={item.path}
                    title={item.name}
                  >
                    {item.name && (
                      <img src={`/static/images/footer/${item.name?.toLowerCase()}.svg`} alt={item.name} />
                    )}
                    {/* <span>{item.name}</span> */}
                  </a>
                ))}
              </Flex>
            </Flex>
            <Flex gap={16} justify="center" align="center">
              <div
                id="dlag-67775a69-38c7-41dd-81de-2d3e0690fbc0"
                data-dlag-seal-id="67775a69-38c7-41dd-81de-2d3e0690fbc0"
                data-dlag-image-size="128"
                data-dlag-image-type="basic-small"
                className="h-[32px] w-[32px]"
              />
              {Licenses.map((item, key) => (
                <a key={key} href={item.path} target="_blank" rel="nofollow">
                  <img src={`/static/images/footer/${item.icon?.toLowerCase()}-logo.svg`} alt={item.icon} />
                </a>
              ))}
              {/* language change */}
              <QueryLang />
            </Flex>
            {/* currency amount  */}
            <Flex justify="center" className="mt-[16px]">
              {currencyRateFooter.sourceCurrency && (
                <div className="whitespace-nowrap border-black/10 px-[10px] py-[4px] text-center text-[14px] font-semibold leading-[21px] text-black/45 qc-border-1">
                  {currencyRateFmt}
                </div>
              )}
            </Flex>
          </article>
          {/* bottom */}
          <Flex
            justify="space-between"
            align="center"
            vertical
            className={cn(
              'm-auto w-full bg-black/5 bg-white px-[16px] pt-[16px] text-xs font-normal text-[#888]'
            )}
          >
            <div className="text-center">
              <p className="mb-[10px]">{t('common.footer.copyrights', { 0: curYear })}</p>
              <div
                className={cn('footer-link', 'mb-[16px]')}
                dangerouslySetInnerHTML={{ __html: t('common.footerHtml.text') }}
              ></div>
              <div
                className={cn('footer-link', 'support mb-[16px]')}
                dangerouslySetInnerHTML={{ __html: t('common.footerHtml.support') }}
              ></div>
            </div>
          </Flex>
        </footer>
      )}
    </>
  )
}

export default memo(FooterBase)
