import { FC, memo } from 'react'
import { cn } from '@/lib/utils'
import { resetPasswordApi } from '@/api/account'
import { useTranslation } from '@/lib/locales/client'
import { useAppStore } from '@/stores/app'
import { useAuthStore } from '@/stores/authControl'
import { useParams, useRouter } from 'next/navigation'
import { Form, Button, Input } from 'antd'
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'
import Validtor from '@/hooks/validtor'
import { useShallow } from 'zustand/react/shallow'

type IProps = {
  captchaId: string
  mobile?: string
  email?: string
  type: string
  success: any
}

const ResetPwd: FC<IProps> = (props) => {
  const { type = 'phone' } = props
  const { t } = useTranslation()
  const router = useRouter()
  const { lng } = useParams()
  const { isBigScreen, setForgetDialog, countryCurInfo } = useAppStore(
    useShallow((state) => ({
      isBigScreen: state.isBigScreen,
      setForgetDialog: state.setForgetDialog,
      countryCurInfo: state.countryCurInfo
    }))
  )
  const SET_RESET_SUCCESS = useAuthStore((state) => state.SET_RESET_SUCCESS)
  const [form] = Form.useForm()
  const isFinishFill = Form.useWatch((values) => values.pwd && values.rePwd, form)

  // submit
  const onFinish = async (values: any) => {
    let params = {}
    if (type === 'phone') {
      params = {
        captchaId: props.captchaId,
        email: '',
        mobile: props.mobile,
        modifyType: 6,
        newPassword: values.pwd,
        phonePrefix: '00' + countryCurInfo.code
      }
    } else {
      params = {
        captchaId: props.captchaId,
        email: props.email,
        loginName: '',
        modifyType: 6,
        newPassword: values.rePwd,
        phonePrefix: ''
      }
    }
    resetPasswordApi(params).then((res: any) => {
      // console.log('重置密码?', res)
      if (res?.success) {
        SET_RESET_SUCCESS(true)
        // const timer = setTimeout(() => {
        props.success(true)
        if (isBigScreen) {
          setForgetDialog(false)
        } else {
          router.push(`/${lng}`)
        }
        onReset()
        // clearTimeout(timer)
        // }, 1000)
      }
    })
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }

  const onReset = () => {
    form.resetFields()
  }

  return (
    <Form
      form={form}
      validateTrigger="onBlur"
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item
        name="pwd"
        required={true}
        validateTrigger={['onChange']}
        rules={[
          {
            validator: async (_rule: any, names: string) => {
              if (!Validtor.password(names)) {
                return Promise.reject(t('common.validtor.passwordError'))
              } else {
                if (!form.getFieldValue('rePwd')) return
                return Promise.resolve()
              }
            }
          }
        ]}
      >
        <Input.Password
          placeholder={t('misc.password.inputPwd')}
          allowClear={{
            clearIcon: (
              <img
                src="/static/images/common/input_clear_x.svg"
                style={{ marginLeft: '10px', width: '18px' }}
              />
            )
          }}
          iconRender={(visible) =>
            visible ? (
              <EyeTwoTone style={{ marginLeft: '10px' }} />
            ) : (
              <EyeInvisibleOutlined style={{ marginLeft: '10px' }} />
            )
          }
        />
      </Form.Item>

      <Form.Item name="sendTips" noStyle>
        <div
          className="relative h-[16px]"
          onClick={() => typeof window !== undefined && window?.Intercom('show')}
        >
          <p
            className={cn(
              'absolute -top-[20px] left-0 right-0 mb-[10px] px-[4px] text-xs font-normal text-black/65'
            )}
          >
            {t('user.regSuc.pwTips')}
          </p>
        </div>
      </Form.Item>

      <Form.Item
        name="rePwd"
        required={true}
        className="mb-[26px]"
        validateTrigger={['onChange']}
        rules={[
          {
            validator: async (_rule: any, names: string) => {
              if (!Validtor.password(form.getFieldValue('pwd'))) {
                return Promise.reject(t('common.validtor.passwordError'))
              } else if (form.getFieldValue('pwd') !== names) {
                return Promise.reject(t('common.validtor.pwdSame'))
              } else {
                if (!form.getFieldValue('pwd')) return
                return Promise.resolve()
              }
            }
          }
        ]}
      >
        <Input.Password
          placeholder={t('misc.password.inputComPwd')}
          allowClear={{
            clearIcon: (
              <img
                src="/static/images/common/input_clear_x.svg"
                style={{ marginLeft: '10px', width: '18px' }}
              />
            )
          }}
          iconRender={(visible) =>
            visible ? (
              <EyeTwoTone twoToneColor="#16c0d5" style={{ marginLeft: '10px' }} />
            ) : (
              <EyeInvisibleOutlined style={{ marginLeft: '10px' }} />
            )
          }
        />
      </Form.Item>
      {/* submit */}
      <Form.Item name="loginSumbit">
        <Button
          block
          type="primary"
          className={cn(
            !isFinishFill && 'disabled',
            'rounded-0 !h-[46px] w-full cursor-pointer self-stretch border-none px-[40px] py-[10px] !text-lg font-bold text-white qc-flex-center'
          )}
          disabled={!isFinishFill}
          style={{
            background: '#FC005B',
            boxShadow: ' 0px 2px 0px 0px rgba(0, 0, 0, 0.15)'
          }}
          htmlType="submit"
        >
          {t('bets.confirm')}
        </Button>
      </Form.Item>

      <Form.Item>
        <p
          className="text-center text-[#000000a5]"
          onClick={() => typeof window !== undefined && window.Intercom('show')}
        >
          {t('user.authDialog.binded')}
          <a>{t('user.authDialog.contact')}</a>
        </p>
      </Form.Item>
    </Form>
  )
}

export default memo(ResetPwd)
