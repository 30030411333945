'use client'
import { useMemo, useState, useEffect, useRef, forwardRef, useImperativeHandle, memo } from 'react'
import { useAppStore } from '@/stores/app'
import { Empty, Input } from 'antd'
import type { InputRef } from 'antd'
import { cn } from '@/lib/utils'
import { useTranslation } from '@/lib/locales/client'
import styles from './_common_css/countrySelect.module.scss'
import { useShallow } from 'zustand/react/shallow'

type IProps = {
  isLightModel?: boolean
  // canChooseCountry?: boolean
  down?: string
  search?: string
  searchLight?: string
  countryCallBack?: any
  isOpenCountry: boolean
  setIsOpenCountry: (flag: boolean) => void
  domId?: string
}

// porps: {isLightModel, canChooseCountry}
const CountrySelect = forwardRef<InputRef, IProps>((props, ref) => {
  const { isOpenCountry = false, isLightModel = false, setIsOpenCountry } = props
  const selectRef = useRef(null)
  const { t } = useTranslation()
  const { countryCurInfo, commonConfig, SET_COUNTRY_INFO } = useAppStore(
    useShallow((state) => ({
      countryCurInfo: state.countryCurInfo,
      commonConfig: state.commonConfig,
      SET_COUNTRY_INFO: state.SET_COUNTRY_INFO
    }))
  )
  const [countryList, setCountryList] = useState([])
  const [searchStr, setSearchStr] = useState('')

  const onSearchCode = (evt) => {
    // console.log('地区搜索', evt.target.value, commonConfig)
    const { mobileInternationalCodes = [] } = commonConfig
    // 输入框 搜索国家代码结果
    const mobileCodeList = mobileInternationalCodes.filter((e) => {
      const _str = `${e.code}${e.alpha2}${e.country?.toUpperCase()}`
      return _str.includes(evt.target.value.trim().toUpperCase())
    })
    setCountryList(mobileCodeList)
    setSearchStr(evt.target.value)
  }

  // 使用 `useImperativeHandle` 来暴露给父组件的操作
  useImperativeHandle(ref, (): any => ({
    focus: () => {
      // console.log('子组件聚焦?', selectRef)
      if (selectRef.current) {
        selectRef.current?.focus()
      }
    },
    blur: () => {
      // console.log('子 失焦?', selectRef)
      if (selectRef.current) {
        selectRef.current?.blur()
      }
    }
  }))

  // 初始化面板 显示默认地区code
  useEffect(() => {
    setCountryList(commonConfig.mobileInternationalCodes)
  }, [])

  const flagLower = (str) => {
    return !!str && str.toLowerCase()
  }

  const chooseCountry = (item) => {
    SET_COUNTRY_INFO(item)
    setIsOpenCountry(false)
    /* callback */
    // 待观察
    props.countryCallBack && props.countryCallBack(item)
    // this.$emit("country-callBack", item)
  }

  /* function */
  const flagIcon = useMemo(() => {
    return countryCurInfo.alpha2?.toLowerCase()
  }, [countryCurInfo])

  return (
    <>
      <div
        className={cn(styles['country-select-modal'], isLightModel && styles['light-model'])}
        id={props.domId}
      >
        {/* cur-box */}
        <div className={'cur-country-modal'} onClick={() => setIsOpenCountry(!isOpenCountry)}>
          <p className={cn('country_icon', flagIcon)}></p>
          <span className={'code'}>+{countryCurInfo.code}</span>
          <div className={cn('arrow', isOpenCountry && 'arrow-down')}></div>
        </div>
        {/* drop-down */}
        {isOpenCountry && (
          <div className={'country-drop-dwon'}>
            <div className={'country-data'}>
              <div className={'search-modal'}>
                <Input
                  className={'ant-input-affix-wrapper-disabled'}
                  ref={selectRef}
                  value={searchStr}
                  onInput={onSearchCode}
                  placeholder={t('common.account.search')}
                  allowClear={{
                    clearIcon: (
                      <img
                        src="/static/images/common/input_clear_x.svg"
                        style={{ marginLeft: '10px', width: '18px' }}
                      />
                    )
                  }}
                  onClear={() => setSearchStr('')}
                  style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)', color: 'rgba(0, 0, 0, 0.85)' }}
                  prefix={
                    <div className={cn('fix-img', 'search', isLightModel && 'light', 'mr-[10px]')}></div>
                  }
                ></Input>
              </div>
              {/* list  ref={scrollData}*/}
              <div className={'data-list-wrapper'}>
                {countryList.length ? (
                  countryList.map((item) => (
                    <div className={'country-item'} key={item.alpha2} onClick={() => chooseCountry(item)}>
                      <div className={'item-left'}>
                        <div className={cn('country_icon', flagLower(item.alpha2))}></div>
                        <span className={'county-name'}>{item.country}</span>
                      </div>
                      <span className={'country-name'}>+{item.code}</span>
                    </div>
                  ))
                ) : (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Data Not Found" />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
})

export default memo(CountrySelect)
