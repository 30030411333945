'use client'
// import { useState } from 'react'
import { useUserStore } from '@/stores/user'
import { useAuthStore } from '@/stores/authControl'
import { openGame } from '@/app/[lng]/(game)/_components/startGame'
// import { useAppStore } from '@/stores/app'
import { useParams, useRouter } from 'next/navigation'

// --- 待处理 当前考虑传入appstore的参数来避免
export function jumpURL(link: string, type = '') {
  // export const jumpURL = (link: string, type = '') => {
  // const changeLanguage = useAppStore((state) => state.changeLanguage)  // 已由多语言插件进行切换
  // const [url, setURL] = useState(link)
  // const [status, setStatus] = useState(type)

  const isLogin = useUserStore((state) => state.isLogin)
  const openLogin = useAuthStore((state) => state.openLogin)
  const router = useRouter()
  const { lng } = useParams()

  if (!link) return
  if (link === '/language') {
    router.push(`/${lng}`)
    // changeLanguage('ja-JP')
    return
  }
  if (link === '/static/responsibleGaming') {
    router.push(`/${lng}/responsible`)
    return
  }
  if (link.indexOf('/islotentrance') > -1) {
    if (!isLogin) {
      openLogin()
    } else {
      openGame(0, {
        platformCode: 'ISLOT',
        gameId: 'islot_lobby'
      })
    }
    return
  }

  /* 去空格 */
  const _url = link.replace(/(^\s*)/g, '')
  if (_url.startsWith('http') || status === 'new') {
    window.open(_url)
    return
  } else {
    router.push(`/${lng}${_url}`)
  }
}
