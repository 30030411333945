'use client'
import { useMemo, memo } from 'react'
import { useTranslation } from '@/lib/locales/client'
import { usePathname, useParams } from 'next/navigation'
import { cn } from '@/lib/utils'
import meta from '@/app/[lng]/meta'
import { useAppStore } from '@/stores/app'
import { useUserStore } from '@/stores/user'
import { useAuthStore } from '@/stores/authControl'
import { track } from '@/hooks/useTrack'
import Link from 'next/link'
import styles from '../_common_css/tabbar.module.scss'
import { useShallow } from 'zustand/react/shallow'

const Tabbar = () => {
  const { t } = useTranslation()
  const { isShowSideNav, setIsShowSideNav, queryCommonConfig } = useAppStore(
    useShallow((state) => ({
      isShowSideNav: state.isShowSideNav,
      setIsShowSideNav: state.setIsShowSideNav,
      queryCommonConfig: state.queryCommonConfig
    }))
  )
  const { availableBonusTask, isLogin, availableMission } = useUserStore(
    useShallow((state) => ({
      availableBonusTask: state.availableBonusTask,
      isLogin: state.isLogin,
      availableMission: state.availableMission
    }))
  )
  const openLogin = useAuthStore((state) => state.openLogin)
  const pathName = usePathname()
  const rawPath = pathName.replace(/^\/(en|ja)($|\/)/g, '/')
  const { lng } = useParams()

  // 默认是否隐藏
  const hideTabbar = useMemo(() => {
    if (Object.keys(meta).length) return meta[rawPath]?.hideTabbar
  }, [pathName])

  const tabList = [
    {
      label: 'common.tabber.menu',
      icon: 'menu',
      href: '#'
    },
    {
      label: 'common.tabber.game',
      icon: 'game',
      path: '/game',
      href: `/${lng}/pachinko`
    },
    {
      label: 'common.tabber.wallet',
      icon: 'wallet',
      path: '/account/deposit',
      href: isLogin ? `/${lng}/account/deposit` : `#`
    },
    {
      label: 'common.tabber.promo',
      icon: 'promo',
      path: '/promo',
      href: isLogin ? `/${lng}/reward` : `/${lng}/promo`
    },
    {
      label: 'common.tabber.account',
      icon: 'account',
      path: '/account',
      href: `/${lng}/account`
    }
  ]

  // tarbar 激活时 图标和文字样式切换，games和promo目录下，有子页面时，需要一起做匹配
  const isActive = (item) => {
    if (rawPath === item.path) {
      return '-a'
    } else if (
      item.path === '/game' &&
      ['/favorite', '/pachinko', '/slots', '/fish', '/live-casino', '/poker', '/favoritegames'].indexOf(
        rawPath
      ) > -1
    ) {
      return '-a'
    } else if (item.path === '/promo' && ['/reward', '/promo', '/voucher'].indexOf(rawPath) > -1) {
      return '-a'
    } else {
      return ''
    }
  }

  // 跳转时附加行为
  const onTapBar = (evt, item) => {
    evt.stopPropagation()
    if (item?.icon === 'menu') {
      setIsShowSideNav(!isShowSideNav)
    }
    if (item?.icon === 'wallet' && !isLogin) {
      openLogin()
    }
    if (item?.icon === 'wallet' && isLogin) {
      track('RechargeClick')
      queryCommonConfig()
    }
  }

  return (
    <>
      {!hideTabbar && (
        <footer className={styles['footer-fix-menu']}>
          <ul>
            {tabList.map((item, key) => (
              <li onClick={(e) => onTapBar(e, item)} key={key} className={cn(isActive(item) && 'active')}>
                <Link
                  className={cn(item.icon === 'wallet' && 'wallet', 'text-[#4c4a48] active:text-[#4c4a48]')}
                  href={item.href}
                >
                  {item.icon === 'wallet' ? (
                    <img src="/static/images/tabbar/wallet.png" />
                  ) : (
                    // <img src={`/static/images/tabbar/${item.icon + '-a'}.svg`} />
                    <img src={`/static/images/tabbar/${item.icon + isActive(item)}.png`} />
                  )}
                  {t(item.label)}
                  {Number(availableBonusTask + availableMission) > 0 && item.icon === 'promo' && isLogin && (
                    <span>
                      {Number(availableBonusTask + availableMission) > 99
                        ? 99
                        : Number(availableBonusTask + availableMission)}
                    </span>
                  )}
                </Link>
              </li>
            ))}
          </ul>
        </footer>
      )}
    </>
  )
}

export default memo(Tabbar)
