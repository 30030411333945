'use client'
import { memo, useState } from 'react'
import { useUserStore } from '@/stores/user'
import { useTranslation } from '@/lib/locales/client'
import { Flex } from 'antd'
import dynamic from 'next/dynamic'
/* com */
import Login from '@/app/[lng]/(auth)/_components/login'
import Register from '@/app/[lng]/(auth)/_components/register'
import { useShallow } from 'zustand/react/shallow'
const SupportThird = dynamic(() => import('@/app/[lng]/(auth)/_components/supportThird'), { ssr: false })

// 未登录状态出现 在首页右侧
const RegisterRightSide = () => {
  const { t } = useTranslation()
  const { _hasHydrated, isLogin } = useUserStore(
    useShallow((state) => ({
      _hasHydrated: state._hasHydrated,
      isLogin: state.isLogin
    }))
  )
  const [logORreg, setLogORreg] = useState('register')

  // 未水合前 先等待
  if (!_hasHydrated) {
    return <div>Loading...</div>
  }

  // 未登录时不展示
  if (isLogin) return <></>

  return (
    <div className="pc:absolute pc:left-[1020px] pc:top-[20px] pc:h-[610px] pc:w-[300px] pc:bg-white">
      {/* 由于放外部的样式 保持移动端的一致，但移动UI现在已更新，所以这份单独兼容 */}
      <div className="py-[16px] text-center">
        <span className="text-xl font-semibold text-black/85">
          {logORreg === 'login' ? t('user.login') : t('user.authDialog.dReg')}
        </span>
      </div>
      {/* content */}
      <div className="px-[16px]">
        {logORreg === 'login' ? (
          <>
            <Login />
            {/* switch to register */}
            <Flex justify="space-around" className="-mt-[16px]">
              <p className="text-sm font-normal leading-[22px] text-black/65">
                {t('user.authDialog.toRegist1')}
                <span className="ml-1 cursor-pointer text-[#3F75FF]" onClick={() => setLogORreg('register')}>
                  {t('user.authDialog.toRegist2')}
                </span>
              </p>
            </Flex>
          </>
        ) : (
          <>
            <Register />
            {/* switch to login */}
            <Flex justify="space-around" className="-mt-[12px]">
              <p className="text-sm font-normal leading-[22px] text-black/65">
                {t('user.authDialog.toRegist3')}
                <span className="ml-1 cursor-pointer text-[#3F75FF]" onClick={() => setLogORreg('login')}>
                  {t('user.login')}
                </span>
              </p>
            </Flex>
          </>
        )}
        {/* third login */}
        <div className="absolute bottom-[20px] left-0 mt-[6px] w-full">
          <SupportThird fitHomeUnlogin />
        </div>
      </div>
    </div>
  )
}

export default memo(RegisterRightSide)
