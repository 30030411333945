import { useState, FC, memo } from 'react'
import { useTranslation } from '@/lib/locales/client'
import { cn } from '@/lib/utils'
import { useParams, useRouter } from 'next/navigation'
import { Tabs } from 'antd'
import styles from './_common_css/casino.module.scss'
import HomeGameListMobile from '@/app/[lng]/(home)/_components/homeGameListMobile'

type IProps = {
  data: any[]
  title: string
  className?: string
}

const CasinoBox: FC<IProps> = (props) => {
  const { t } = useTranslation()
  const router = useRouter()
  const { lng } = useParams()
  const [activeKey, setActiveKey] = useState('0')

  const goPath = () => {
    router.push(`/${lng}${props.data[activeKey]?.templateAction}` || '/')
  }

  return (
    <>
      <section className={cn(styles['casino-tab'], props.className)}>
        {props.title && <h1>{props.data && <span onClick={goPath}>{t('common.homeIcon.more')}</span>}</h1>}

        <Tabs
          className={'casino-tabs-box'}
          activeKey={activeKey}
          onChange={(key) => setActiveKey(key)}
          items={props.data.map((item, index) => {
            return {
              key: String(index),
              label: item.title,
              children: <HomeGameListMobile isHideTitle gameListData={item} />
            }
          })}
        />
      </section>
    </>
  )
}

export default memo(CasinoBox)
