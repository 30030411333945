'use client'
import { useAppStore } from '@/stores/app'
import { useAuthStore } from '@/stores/authControl'
import { useUserStore } from '@/stores/user'
import { useParams, useRouter } from 'next/navigation'
import { memo, useMemo, useState } from 'react'
import { useShallow } from 'zustand/react/shallow'
import styles from './index.module.scss'
import { cn } from '@/lib/utils'
import { useTranslation } from '@/lib/locales/client'
import { px2Scalepx } from '@/hooks/useRem'
import BonusTime from '@/app/[lng]/(home)/(pages)/guess-game/betting/bonusTime'
import VerifyBaseDialog from '@/components/common/VerifyBaseDialog'
import TicketsHistoryDialog from '../TicketsHistoryDialog'

const LotteryBox = (props: any) => {
  const {
    boxType = 'topic', //home 首页和侧边栏，topic 主题页
    showHeard = false
  } = props
  const router = useRouter()
  const { lng } = useParams()
  const { t } = useTranslation()
  const { lotteryTicketConfig, isBigScreen, setIsShowSideNav } = useAppStore(
    useShallow((state) => ({
      lotteryTicketConfig: state.lotteryTicketConfig,
      isBigScreen: state.isBigScreen,
      setIsShowSideNav: state.setIsShowSideNav
    }))
  )
  const { isLogin } = useUserStore(useShallow((state) => ({ isLogin: state.isLogin })))
  const { openLogin } = useAuthStore.getState()
  const [dialogVisible, setDialogVisisble] = useState(false)
  const [showPc, setShowPc] = useState(true)
  const [showDetail, setShowDetail] = useState(false)
  const wagered = useMemo(() => {
    return lotteryTicketConfig.amount ? lotteryTicketConfig.amount % lotteryTicketConfig.sourceAmount : 0
  }, [lotteryTicketConfig])

  const imgUrl = useMemo(() => {
    let type = ''
    if (isBigScreen && boxType === 'home') {
      type = 'mainUrlPc'
    } else if (!isBigScreen && boxType === 'home') {
      type = 'mainUrlH5'
    } else if (isBigScreen && boxType !== 'home') {
      type = 'backgroundUrl'
    } else if (!isBigScreen && boxType !== 'home') {
      type = 'backgroundUrlH5'
    }
    return lotteryTicketConfig[type]
  }, [lotteryTicketConfig])

  const openUrl = () => {
    if (lotteryTicketConfig.drawUrl) {
      window.open(lotteryTicketConfig.drawUrl)
    }
  }
  const goPromo = () => {
    setIsShowSideNav(false)
    router.push(`/${lng}/promo?detail=226388835520233473`)
  }
  const goPage = () => {
    setIsShowSideNav(false)
    if (isLogin) {
      router.push(`/${lng}/promo/lottery-tickets`)
    } else {
      openLogin()
    }
  }

  return (
    <>
      {showPc && (
        <div
          className={cn(
            styles['lottery-warp'],
            styles[isBigScreen ? 'pc' : 'h5'],
            styles[boxType],
            isLogin ? 'noLogin' : ''
          )}
        >
          {showHeard && (
            <div className="home-com-heard lottery-heard">
              <h3 style={{ display: 'flex', alignItems: 'center' }}>
                {!isBigScreen && (
                  <img style={{ width: '20px' }} src="/static/images/newYear/title-draw.png" alt="" />
                )}

                {isBigScreen ? '' : t('activity.lottery.luckyDraw')}
              </h3>
              <div className={cn('btn-warp', !isBigScreen ? 'btn_warp_h5' : '')}>
                {isLogin && !isBigScreen && (
                  <a className="fw_a_btn1" onClick={() => setDialogVisisble(true)}>
                    {t('activity.lottery.history')}
                  </a>
                )}

                <a className="fw_a_btn2" onClick={() => goPage()}>
                  {t('activity.lottery.more')}
                </a>

                {isBigScreen && <i onClick={() => setShowPc(false)}></i>}
              </div>
            </div>
          )}

          <div className={cn('lottery-banner', !isLogin ? 'nologin' : '')} onClick={() => goPage()}>
            <img src={imgUrl} />
            {!(isBigScreen && boxType === 'home') && (
              <a
                className="btn"
                onClick={(e) => {
                  e.stopPropagation()
                  goPromo()
                }}
              >
                {t('activity.lottery.detail')}
              </a>
            )}

            {boxType !== 'topic' && lotteryTicketConfig.actEndDate && !isBigScreen && (
              <>
              <div className='finish'>{t("activity.tournament.finishIn")}</div>
              <BonusTime value={lotteryTicketConfig.actEndDate}></BonusTime>
              </>
            )}
          </div>
          {isLogin && lotteryTicketConfig.sourceAmount && (
            <div className="lottery-info-warp">
              <div className="lottery-info">
                <div className="lottery-progress">
                  {(boxType === 'topic' || isBigScreen) && (
                    <>
                      <p className="name">{t('activity.lottery.yourWagered')}</p>
                      <div className="money">${wagered}</div>
                    </>
                  )}

                  <div className="progress-warp">
                    <div className="bar">
                      <i
                        style={{
                          width: (wagered / lotteryTicketConfig.sourceAmount) * 100 + '%'
                        }}
                      ></i>
                    </div>
                    <div className="icon">+{lotteryTicketConfig.sourceNum || 1}</div>
                  </div>
                  <div className="text-warp">
                    <p className="text">
                      {t('activity.lottery.totalWagered')}
                      <span>
                        {boxType !== 'topic' && isLogin && <>${wagered} /</>}$
                        {lotteryTicketConfig.sourceAmount}
                      </span>
                    </p>
                    <p className="text">
                      {t('activity.lottery.totalTicket')}
                      <span>  {lotteryTicketConfig.usedNum || 0}</span>
                    </p>
                  </div>
                </div>
                {boxType === 'topic' && (
                  <div className="lottery-time">
                    {lotteryTicketConfig.actEndDate && (
                      <BonusTime value={lotteryTicketConfig.actEndDate}></BonusTime>
                    )}

                    <div
                      className="text text-multi-ellipsis-l3"
                      dangerouslySetInnerHTML={{ __html: lotteryTicketConfig.description }}
                    ></div>
                  </div>
                )}
              </div>
              {boxType === 'topic' && (
                <a className="go-live" onClick={() => openUrl()}>
                  {t('activity.lottery.eventChannel')}
                </a>
              )}
            </div>
          )}
          {showDetail && (
            <VerifyBaseDialog
              visible={showDetail}
              zIndex={6002}
              width={px2Scalepx(isBigScreen ? 420 : 343)}
              closeFn={() => setShowDetail(false)}
            >
              <p className={styles['lottery-detail-text']}>{t('activity.lottery.popDetail')}</p>
            </VerifyBaseDialog>
          )}
          {dialogVisible && <TicketsHistoryDialog closeDialog={() => setDialogVisisble(false)} />}
        </div>
      )}
    </>
  )
}

export default memo(LotteryBox)
