export const personal = {
  // export const PERSONAL = {
  title: 'Personal',
  text001: 'To change the registered email address please contact our Customer Service at',
  text002: 'support@queen-casino.com',
  firstName: 'FIRST NAME',
  firstNamePlc: '太郎',
  lastName: 'LAST NAME',
  lastNamePlc: '山田',
  firstNameJapanese: 'NATIVE FIRST NAME',
  nativeFirstNamPlc: 'タロウ',
  lastNameJapanese: 'NATIVE LAST NAME',
  lastNameJapanesePlc: 'ヤマダ',
  emailAddress: 'EMAIL ADDRESS',
  birthDate: 'DATE OF BIRTH',
  country: 'COUNTRY',
  prefecture: 'PREFECTURE',
  prefecturePla: 'Prefecture',
  city: 'CITY/TOWN',
  cityPla: 'City/Town',
  address: 'ADDRESS',
  addressPla: 'Address',
  postalCode: 'ZIP CODE',
  postalCodePla: 'Zip Code',
  occupation: 'CAREER',
  phone: 'MOBILE NUMBER',
  phonePla: 'Mobile Number',
  btn001: "EDIT",
  btn002: "UPDATE",
  btn003: "CANCEL",
  btn004: "Submit",
  modifySuccess: 'Your data has been successfully saved',
  tips: 'Please fill in the correct personal information and residential address, otherwise it will lead to failure of identity verification',
  tips2: `Please enter the representation of the last name and first name in full-width katakana as used for "Account Holder Name".`
} as const
