'use client'
import dayjs from 'dayjs'
import { useEffect, useMemo, useState } from 'react'
import styles from './index.module.scss'
import { useTranslation } from '@/lib/locales/client'
import { cn } from '@/lib/utils'
const BonusTime = (props: any) => {
  const { value, finsh } = props
  const { t } = useTranslation()
  const [day, setDay] = useState(0)
  const [hours, setHours] = useState(0)
  const [mins, setMins] = useState(0)
  const [seconds, setSeconds] = useState(0)
  const [timer, setTimer] = useState<any>()

  const dayStr = useMemo(() => {
    return day > 9 ? day : `0${day}`
  }, [day])
  const hoursStr = useMemo(() => {
    return hours > 9 ? hours : `0${hours}`
  }, [hours])
  const minsStr = useMemo(() => {
    return mins > 9 ? mins : `0${mins}`
  }, [mins])
  const secondsStr = useMemo(() => {
    return seconds > 9 ? seconds : `0${seconds}`
  }, [seconds])

  const getDateValue = () => {
    const nowDate = dayjs()
    const endDate = dayjs(value)
    const dayValue = endDate.diff(nowDate, 'day')
    const hoursValue = endDate.diff(nowDate, 'hour') % 24
    const minsValue = endDate.diff(nowDate, 'minute') % 60
    const secondsValue = endDate.diff(nowDate, 'second') % 60
    setDay(dayValue)
    setHours(hoursValue)
    setMins(minsValue)
    setSeconds(secondsValue)

    if (nowDate >= endDate) {
      setDay(0)
      setHours(0)
      setMins(0)
      setSeconds(0)
    }
  }

  useEffect(() => {
    getDateValue()
    const _timer = setInterval(() => {
      getDateValue()
      if (day === 0 && hours === 0 && mins === 0 && seconds === 0) {
        clearInterval(timer)
        finsh && finsh()
      }
    }, 1000)
    setTimer(_timer)
    return () => clearInterval(timer)
  }, [])

  return (
    <div className={cn(styles['bonus-time'],"bonus-time")}>
      <div className="time-wrap">
        <span>{day}</span>
        {t('bonus.day')}
      </div>
      <div className="time-wrap">
        <span>{hoursStr}</span>
        {t('bonus.hour')}
      </div>
      <div className="time-wrap">
        <span>{minsStr}</span>
        {t('bonus.minute')}
      </div>
      <div className="time-wrap">
        <span>{secondsStr}</span>
        {t('bonus.second')}
      </div>
    </div>
  )
}
export default BonusTime
