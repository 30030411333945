import { useState, useEffect, useMemo, useRef, FC, memo } from 'react'
import { useSetState } from 'ahooks'
import { cn } from '@/lib/utils'
import { useTranslation } from '@/lib/locales/client'
import { useAppStore } from '@/stores/app'
import { CSSTransition } from 'react-transition-group'
import styles from '@/app/[lng]/(game)/_common_css/slotsGameProvider.module.scss'
import { Checkbox } from 'antd'
import { getGameProvider } from '@/api/game'
import { useClickAway } from 'ahooks'
import { useGameStore } from '@/stores/game'
import { useShallow } from 'zustand/react/shallow'
import { useUpdateEffect } from 'ahooks'

type IProps = {
  gameType?: string
  finish: any
  className?: string
}

const SlotsGameProvider: FC<IProps> = (props) => {
  const { gameType = '' } = props // 解构用于判断 默认字符串状态
  const { t } = useTranslation()
  const language = useAppStore((state) => state.language)
  const { SET_GAME_LEN } = useGameStore(
    useShallow((state) => ({
      SET_GAME_LEN: state.SET_GAME_LEN
    }))
  )

  let allValue = []
  const initText = useMemo(() => (gameType === '' ? t('home.game.provider') : t('home.game.select')), [])
  const [tabText, setTabText] = useState(initText)
  const [plainOptions, setPlainOptions] = useState([])
  const [isShowOptions, setIsShowOptions] = useState(false)
  const [checkedList, setCheckedList] = useState([])
  const [allGame, setAllGame] = useState(0)

  const menuRef = useRef<any>(null)
  // 关联弹出选择框
  useClickAway(() => {
    if (isShowOptions) {
      setIsShowOptions(false)
      props.finish({ checkedList })
    }
  }, menuRef)

  useUpdateEffect(() => {
    setTabText(checkedList.length > 0 ? checkedList.toString() : initText)
  }, [checkedList])

  useEffect(() => {
    setCheckedList([])
    queryGameLobbyCode()
  }, [gameType])

  const queryGameLobbyCode = () => {
    let gameTypes
    if (gameType) {
      gameTypes = [gameType]
      if (gameType === 'pachinko') {
        gameTypes.push('pachislot')
      }
    }
    return getGameProvider({
      language: language,
      gameTypes,
      visibleType: 1
    }).then((res: any) => {
      if (res.success) {
        setPlainOptions(res.data)
        let len = 0
        res.data.forEach((el) => {
          allValue.push(el.providerName)
          len += el.quantity
        })
        setAllGame(len)
        if (gameType === '') {
          SET_GAME_LEN(len)
        }
      }
    })
  }

  function handleSearch() {
    if (checkedList.length === 0) return
    setIsShowOptions(false)
    props.finish({ checkedList })
  }

  return (
    <div className={cn(styles['slot-provider'], props.className)} ref={menuRef}>
      <div
        className={cn('header-tab', isShowOptions && 'open', checkedList.length === 0 && 'nochose')}
        onClick={() => setIsShowOptions(!isShowOptions)}
      >
        {tabText}
      </div>
      <CSSTransition timeout={200} in={isShowOptions} unmountOnExit classNames={'fade'}>
        <div className={'list-box'}>
          <div className={'list-item'}>
            <Checkbox
              className={'checkbox-list-item-top'}
              checked={plainOptions.length === checkedList.length}
              onChange={(e) => {
                setCheckedList(e.target.checked ? plainOptions.map((item) => item.providerName) : [])
              }}
            >
              <span>{t('common.account.all') + ' - ' + allGame}</span>
              <div className={'delete-icon'}>
                <span
                  className={'cancel'}
                  onClick={(event) => {
                    event.preventDefault()
                    setCheckedList([])
                  }}
                >
                  {t('home.game.cancel')}
                </span>
                <span
                  className={cn('search', checkedList.length === 0 && 'disable')}
                  onClick={(event) => {
                    event.preventDefault()
                    handleSearch()
                  }}
                >
                  {t('home.game.search')}
                </span>
              </div>
            </Checkbox>
            <Checkbox.Group
              value={checkedList}
              onChange={(list) => {
                setCheckedList(list)
              }}
            >
              {plainOptions.map((item) => (
                <Checkbox key={item.providerName} value={item.providerName}>
                  <span>{item.providerName}</span>
                  <span className={'right-text'}>{item.quantity || 0}</span>
                </Checkbox>
              ))}
            </Checkbox.Group>
          </div>
        </div>
      </CSSTransition>
    </div>
  )
}

export default memo(SlotsGameProvider)
