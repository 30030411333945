'use client'
import { useState, useMemo, memo, FC, useEffect } from 'react'
import { useTranslation } from '@/lib/locales/client'
import { useAppStore } from '@/stores/app'
import { useUserStore } from '@/stores/user'
import { formatAmount } from '@/lib/filter'
import { getExchangeRate } from '@/api/wallet'
import VerifyBaseDialog from '@/components/common/VerifyBaseDialog'
import { getCurrencySymbol, getBonusName, formatBonusAmount } from '@/lib/utils'
import { useGameStore } from '@/stores/game'
import { useShallow } from 'zustand/react/shallow'
import styles from './_common_css/homeBonusDialog.module.scss'
import BonusReceive from '@/app/[lng]/(promotion)/_components/bonusReceive'

declare global {
  interface Window {
    hideHomeBonusDialog: boolean
  }
}

const HomeBonusDialog: FC = (props) => {
  const { t } = useTranslation()
  const [alreadyshow, setAlreadyshow] = useState(false) // 只刷新刷新后 只显示一次
  const { bonusAndTask, getBonusTaskList } = useUserStore(
    useShallow((state) => ({
      bonusAndTask: state.bonusAndTask,
      getBonusTaskList: state.getBonusTaskList
    }))
  )
  const { isShowHomeBonusDialog, isShowNoticeSysDialog, introduceDialog, language, setShowHomeBonusDialog } =
    useAppStore(
      useShallow((state) => ({
        isShowHomeBonusDialog: state.isShowHomeBonusDialog,
        isShowNoticeSysDialog: state.isShowNoticeSysDialog,
        introduceDialog: state.introduceDialog,
        language: state.language,
        setShowHomeBonusDialog: state.setShowHomeBonusDialog
      }))
    )
  const [receiveDialog, setReceiveDialog] = useState(false)

  const sourceData = useMemo(() => {
    return bonusAndTask.filter((item) => item.status === 2 && item.configCode !== 'b6571a8d7b')[0]
  }, [bonusAndTask])

  const isShowDialog = useMemo(() => {
    return isShowHomeBonusDialog && !isShowNoticeSysDialog && !introduceDialog
  }, [isShowHomeBonusDialog, isShowNoticeSysDialog, introduceDialog])

  useEffect(() => {
    if (sourceData && !alreadyshow) {
      setShowHomeBonusDialog(true)
      setAlreadyshow(true)
    }
  }, [sourceData])

  const handleReceive = () => {
    getBonusTaskList()
    setReceiveDialog(false)
    setShowHomeBonusDialog(false)
  }

  return (
    sourceData && (
      <>
        <VerifyBaseDialog
          visible={isShowDialog}
          zIndex={6100}
          width={343}
          closeFn={() => {
            setShowHomeBonusDialog(false)
          }}
          cusClass={styles['home_bonus_dialog']}
        >
          <div className="warp">
            <p className="title">{getBonusName(sourceData, language)}</p>
            {sourceData.freeSpinTimes ? (
              <p className="txt">
                {t('bonus.fg')}
                <span> {formatBonusAmount({ data: sourceData })} </span>
                {t('bonus.and')}
                <span> {sourceData.freeSpinTimes} </span>
                {t('bonus.fee')}
              </p>
            ) : (
              <p className="txt">
                {t('bonus.fg')}
                <span> {formatBonusAmount({ data: sourceData })} </span>
              </p>
            )}
            <div
              className="btn"
              onClick={() => {
                setReceiveDialog(true)
                setShowHomeBonusDialog(false)
              }}
            >
              {t('bonus.actionClaim')}
            </div>
          </div>
        </VerifyBaseDialog>
        {receiveDialog && (
          <BonusReceive
            dialogData={sourceData}
            close={() => {
              setReceiveDialog(false)
            }}
            receiveBonus={handleReceive}
          />
        )}
      </>
    )
  )
}

export default memo(HomeBonusDialog)
