'use client'
import { useState, useEffect, FC, useMemo } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { Form, Button, Input } from 'antd'
import { useUserStore } from '@/stores/user'
import { useAppStore } from '@/stores/app'
import { useWalletStore } from '@/stores/wallet'
import { useTranslation } from '@/lib/locales/client'
import { createQrCodePayment, getExchangeRate, getPaymentVega } from '@/api/wallet'
import { cn, getCurrencySymbol, conversion, decimalFormat, depositSwitch } from '@/lib/utils'
import { formatAmount } from '@/lib/filter'
import { Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import DepositData from './depositData'
import SelectCurrency from './selectCurrency'
import BankDepositTips from './bankDepositTips'
import BankToBank from './bankToBank'
import DepositText from './depositText'
import SearchSelect from '@/components/base/searchSelect'
import { px2Scalepx } from '@/hooks/useRem'

declare global {
  interface Window {
    newWindow: any
  }
}

type IProps = {
  depositInfo?: any
  isFastDeposit?: boolean
  iframeData?: any
  setThirdPartyUrl?: (obj: any) => void
}

const HibanqDeposit: FC<IProps> = (props) => {
  const { depositInfo } = props
  const { t } = useTranslation()
  const { storeCurrency, userInfo } = useUserStore(
    useShallow((state) => ({
      storeCurrency: state.currency?.currency,
      userInfo: state.userInfo
    }))
  )
  const { isBigScreen, SET_GLOBAL_MSG, language } = useAppStore(
    useShallow((state) => ({
      isBigScreen: state.isBigScreen,
      language: state.language,
      SET_GLOBAL_MSG: state.SET_GLOBAL_MSG
    }))
  )
  const { bankToBankInfo, updateBankToBankInfo } = useWalletStore(
    useShallow((state) => ({
      bankToBankInfo: state.bankToBankInfo,
      updateBankToBankInfo: state.updateBankToBankInfo
    }))
  )
  const [form] = Form.useForm()

  const [depositCurrencyType, setDepositCurrencyType] = useState('')
  const [currencylTypeList, setCurrencylTypeList] = useState([])
  const [rate, setRate] = useState({ realRate: 0 })
  const [quickAmountList, setQuickAmountList] = useState([])
  const [paypayBank, setPaypayBank] = useState(null)
  const [isShowBankDepositTips, setIsShowBankDepositTips] = useState(false)
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true)
  const [iframeUrl, setIframeUrl] = useState('')
  const [paypayBankList, setPaypayBankList] = useState([])

  const amount = Form.useWatch('amount', form)
  const userID = Form.useWatch('userID', form)
  const passWord = Form.useWatch('passWord', form)


  const getPaypayBankList = () => {
    getPaymentVega({
      channelCodes: 'bankpay',
      currency: 'JPY'
    }).then((res) => {
      if (res.success && res.data) {
        const paypayData = res.data.filter((el) => el.payType === 139 && el.bankArray?.length > 0)[0]
        if (paypayData) {
          const paypayBank = paypayData.bankArray.filter((el) => el.fixedAmountList?.length > 0)
          setPaypayBankList(paypayBank)
        }
      }
    })
  }

  useEffect(() => {
    form
      .validateFields({ validateOnly: true }) // 校验所有字段
      .then(() => {
        setIsSubmitDisabled(false) // 校验通过，可以提交
      })
      .catch(() => {
        setIsSubmitDisabled(true) // 校验不通过，禁用提交按钮
      })
  }, [amount, userID, passWord])

  useEffect(() => {
    depositSwitch('show')
    const val = depositInfo.channelInfo
    if (val) {
      const list = []
      if (val['JPY']) {
        list.push('JPY')
      }
      if (val['USD']) {
        list.push('USD')
      }
      setCurrencylTypeList(list)
      let cur = storeCurrency
      //虚拟币默认选中美元
      cur = cur !== 'JPY' && cur !== 'USD' ? 'USD' : cur
      setDepositCurrencyType(list.indexOf(cur) > -1 ? cur : list[0])
      if (depositInfo.payType == '139' && !paypayBankList.length) {
        getPaypayBankList()
      }
    }
  }, [depositInfo])

  useEffect(() => {
    if (depositCurrencyType === '') return
    if (depositInfo.payType == '139' && paypayBankList) {
      if (paypayBank) {
        const fixedAmountList = paypayBankList.filter((el) => paypayBank === el.bankCode)[0]?.fixedAmountList
        if (fixedAmountList.length > 6) {
          setQuickAmountList(conversion(fixedAmountList))
          form.setFieldsValue({ amount: conversion(fixedAmountList)[0][0] })
        } else {
          setQuickAmountList(fixedAmountList)
          form.setFieldsValue({ amount: fixedAmountList[0] })
        }
      }
    } else {
      let max = depositInfo.channelInfo[depositCurrencyType].maxAmount || 999999999
      let amountList
      max = parseInt(max).toString()
      if (depositInfo.channelCode === 'banktobank' || depositInfo.channelCode === 'gate2way') {
        amountList = ['30000', '50000', '100000', '500000', '1000000', '5000000']
      } else if (depositInfo.channelCode === 'payz' || depositInfo.channelCode === 'vegaWallet') {
        amountList =
          depositCurrencyType === 'USD'
            ? ['50', '100', '1000', '3000', '5000', '10000']
            : ['10000', '50000', '300000', '500000', '1000000', '1500000']
      } else {
        amountList =
          depositCurrencyType === 'USD'
            ? ['100', '1000', '10000', '30000', '50000', '100000']
            : ['10000', '100000', '500000', '1000000', '1500000', '2000000']
      }
      amountList = amountList.filter((el) => parseInt(el) < parseInt(max))
      amountList.length === 6 ? (amountList[5] = max) : amountList.push(max)
      setQuickAmountList(amountList)
    }
    queryExchangeRate()
  }, [depositCurrencyType, paypayBank])

  const formatQuickAmount = (num) => {
    let max = depositInfo.channelInfo[depositCurrencyType]?.maxAmount || 999999999
    max = parseInt(max).toString()
    if (num === max) return language === 'ja-JP' ? '最大' : 'MAX'
    if (depositCurrencyType === 'USD' || language !== 'ja-JP') {
      return formatAmount(num, 0, true)
    } else {
      return num >= 10000 ? num / 10000 + '万円' : num + '円'
    }
  }

  const queryExchangeRate = () => {
    if (depositInfo.channelCode === 'vegaWallet' && depositCurrencyType === 'JPY') {
      const params = {
        amount: 1,
        isFindByCache: 0,
        isSave: false,
        sourceAmount: 1,
        sourceCurrency: depositCurrencyType,
        targetCurrency: 'USD',
        tradeType: '01',
        used: 1
      }
      getExchangeRate(params).then((res) => {
        if (res.success && res.data.data && res.data.data.length > 0) {
          setRate(res.data.data[0])
        }
      })
    }
  }
  const onSubmit = () => {
    form.validateFields().then(() => {
      createDeposit()
    })
  }

  const setCurrency = (item) => {
    setDepositCurrencyType(item)
    form.validateFields()
  }

  const createDeposit = async () => {
    if (depositInfo.identityCertifiedFlag && (!userInfo.firstNameJapanese || !userInfo.lastNameJapanese)) {
      SET_GLOBAL_MSG({ flag: true, type: 'error', content: t('deposit.depositerErr') })
      return
    }
    const depositMethodMap = {
      deskdzjpypay: 0,
      jetonPaypay: 0,
      naticWallet: 20,
      vegaWallet: 21,
      payz: 25
    }
    let params: any = {
      payType: depositInfo.payType,
      currency: depositCurrencyType,
      amount: Number(amount),
      targetCurrency: depositCurrencyType,
      depositMethod: depositMethodMap[depositInfo.channelCode] || 0,
      depositor: depositInfo.identityCertifiedFlag
        ? userInfo.firstNameJapanese + '#' + userInfo.lastNameJapanese
        : null
    }
    if (depositInfo.payType === 'creditP93') {
      params.payType = depositInfo.channelInfo[depositCurrencyType].payType
      params.extSystemType = 'P93'
    }
    if (depositInfo.payType == '139') {
      params.merchantBankCode = paypayBankList.filter((el) => paypayBank === el.bankCode)[0]?.merchantBankCode
      params.channelCode = depositInfo.channelCode
    }
    if (depositInfo.openNewWindow && !props.isFastDeposit) {
      window.newWindow = window.open('/loading/index.html')
    }
    if (depositInfo.channelCode === 'naticWallet' || depositInfo.channelCode === 'vegaWallet') {
      params = {
        ...params,
        walletLoginName: userID,
        walletPassword: passWord
      }
    }
    createQrCodePayment(params)
      .then((res) => {
        if (res.success) {
          if (depositInfo.channelCode === 'banktobank') {
            return updateBankToBankInfo(res.data)
          }
          if (depositInfo.openNewWindow && res.data.url) {
            if (props.isFastDeposit) {
              setIframeUrl(res.data.url)
            } else {
              window.newWindow.location = res.data.url
            }
          } else {
            window.newWindow?.close()
            SET_GLOBAL_MSG({ flag: true, type: 'success', content: t('deposit.depositer') })
          }
        } else {
          window.newWindow?.close()
        }
      })
      .catch(() => {
        window.newWindow?.close()
      })
  }

  const isShowBanktoBank = useMemo(() => {
    let flag
    if (bankToBankInfo?.willExpiraTime > new Date().getTime()) {
      flag = true
    }
    return flag && depositInfo.payType === '45'
  }, [bankToBankInfo])

  return (
    <div>
      {iframeUrl !== '' && props.isFastDeposit ? (
        <div className="third-party">
          <p className="title">{t('deposit.tips3')}</p>
          <iframe id="fast-deposit-iframe" src={iframeUrl} />
        </div>
      ) : (
        <div className='wallet-container grey'>
          {!isBigScreen && !isShowBanktoBank && (
            <div
              className="close"
              onClick={() => {
                depositSwitch('hide')
              }}
            >
              <img src={'/static/images/service/del.png'} />
            </div>
          )}
          {(isBigScreen || !isShowBanktoBank) && (
            <div className="top-icon">
              <div className="left_box">
                {depositInfo?.channelLogoPath && <img src={depositInfo?.channelLogoPath} className="logo" />}
                <DepositData item={depositInfo} currencyData={depositCurrencyType} />
              </div>
            </div>
          )}
          {!isShowBanktoBank ? (
            <>
              {currencylTypeList.length > 1 && (
                <SelectCurrency
                  currencyData={depositCurrencyType}
                  currencyList={currencylTypeList}
                  finish={setCurrency}
                />
              )}
              <Form form={form} layout="vertical">
                {depositInfo.payType == '139' && paypayBankList.length > 0 && (
                  <Form.Item label={t('deposit.seachBank')} required={true}>
                    <SearchSelect
                      value={paypayBank}
                      onChange={(val) => setPaypayBank(val)}
                      options={paypayBankList.map((el) => {
                        return { label: el.bankName, value: el.bankCode }
                      })}
                      placeholder={t('withdraw.bankNameText')}
                    />
                  </Form.Item>
                )}
                {depositInfo.quickAmount && Array.isArray(quickAmountList[0]) && (
                  <Form.Item label={t('deposit.amountText')} required={true}>
                    <Swiper
                      className="amount-box-swiper"
                      modules={[Pagination]}
                      pagination={{ clickable: true, el: '.amount-box-pagination' }}
                      spaceBetween={px2Scalepx(12)}
                    >
                      {quickAmountList.map((list, key) => (
                        <SwiperSlide key={key} className="amount-box amount-box-silde">
                          {list.map((item, key) => (
                            <span
                              key={item}
                              className={cn('amount-box-item', amount === item && 'active')}
                              onClick={() => {
                                form.setFieldsValue({ amount: item })
                              }}
                            >
                              {formatQuickAmount(item)}
                            </span>
                          ))}
                        </SwiperSlide>
                      ))}
                      <div className="amount-box-pagination"></div>
                    </Swiper>
                  </Form.Item>
                )}
                {depositInfo.quickAmount &&
                  !Array.isArray(quickAmountList[0]) &&
                  quickAmountList.length > 0 && (
                    <Form.Item label={t('deposit.amountText')} required={true}>
                      <div className="amount-box">
                        {quickAmountList.map((item, key) => (
                          <span
                            key={item}
                            className={cn('amount-box-item', amount === item && 'active')}
                            onClick={() => {
                              form.setFieldsValue({ amount: item })
                            }}
                          >
                            {formatQuickAmount(item)}
                          </span>
                        ))}
                      </div>
                    </Form.Item>
                  )}
                {
                  <Form.Item
                    name="amount"
                    className={depositInfo.payType == '139' ? 'hideinput' : null}
                    label={depositInfo.quickAmount ? '' : t('deposit.amountText')}
                    required={true}
                    validateTrigger={['onBlur', 'onChange']}
                    rules={[
                      {
                        validator: async (_rule, value) => {
                          if (!value) return Promise.reject(t('deposit.wrong'))
                          if(value && depositInfo.payType == '139') return Promise.resolve()
                          const min = Number(depositInfo.channelInfo[depositCurrencyType].minAmount) || 1
                          const max =
                            Number(depositInfo.channelInfo[depositCurrencyType].maxAmount) || 999999999
                          if (!value || value < min || value > max || isNaN(Number(value))) {
                            return Promise.reject(
                              t('deposit.notEnough', {
                                currency: getCurrencySymbol(depositCurrencyType),
                                min: formatAmount(min, 0, true),
                                max: formatAmount(max, 0, true)
                              })
                            )
                          } else {
                            return Promise.resolve()
                          }
                        }
                      }
                    ]}
                  >
                    <Input
                      type='number'
                      placeholder={t('deposit.amount')}
                      prefix={<div>{getCurrencySymbol(depositCurrencyType)}</div>}
                    />
                  </Form.Item>
                }
                {(depositInfo.channelCode === 'vegaWallet' || depositInfo.channelCode === 'naticWallet') && (
                  <div className="flex justify-between">
                    <Form.Item
                      name="userID"
                      className="inline"
                      required={true}
                      validateTrigger={['onBlur', 'onChange']}
                      rules={[
                        {
                          validator: async (_rule, value) => {
                            if (!value) return Promise.reject(t('deposit.wrong'))
                            if (
                              (!value.startsWith('U') || value.length < 5) &&
                              depositInfo.channelCode === 'vegaWallet'
                            ) {
                              return Promise.reject(t('deposit.wrong'))
                            } else if (
                              (!value.startsWith('F') || value.length !== 8) &&
                              depositInfo.channelCode === 'naticWallet'
                            ) {
                              return Promise.reject(t('deposit.wrong'))
                            } else {
                              return Promise.resolve()
                            }
                          }
                        }
                      ]}
                    >
                      <Input
                        placeholder={t('deposit.userID')}
                        maxLength={8}
                        className="small-pl"
                        prefix={
                          depositInfo.channelCode === 'vegaWallet' && (
                            <img src={'/static/images/deposit/list/vegaicon.png'} className="vagaicon_img" />
                          )
                        }
                      />
                    </Form.Item>
                    <Form.Item
                      name="passWord"
                      className="inline"
                      required={true}
                      validateTrigger={['onBlur', 'onChange']}
                      rules={[
                        {
                          validator: async (_rule, value) => {
                            if (value && value.trim().length > 0) {
                              return Promise.resolve()
                            } else {
                              return Promise.reject(t('deposit.wrong'))
                            }
                          }
                        }
                      ]}
                    >
                      <Input.Password
                        autoComplete={'new-password'}
                        placeholder={t('deposit.password')}
                        maxLength={60}
                        className="small-pl"
                        prefix={
                          depositInfo.channelCode === 'vegaWallet' && (
                            <img src={'/static/images/deposit/list/vegaicon.png'} className="vagaicon_img" />
                          )
                        }
                      />
                    </Form.Item>
                  </div>
                )}
                {depositInfo.channelCode === 'vegaWallet' && (
                  <div className="vegaLink">
                    <a target="_blank" href="https://vega-wallet.com/whitelist/">
                      {t('deposit.vegaLink')}
                    </a>
                  </div>
                )}
                {depositInfo.identityCertifiedFlag === 1 && (
                  <Form.Item label={t('deposit.userName')} required={true}>
                    <Input
                      disabled
                      value={userInfo.lastNameJapanese + ' ' + userInfo.firstNameJapanese}
                    />
                  </Form.Item>
                )}
                <div className="totalAmount">
                  <p className="amount-info">
                    <span>{t('deposit.totalAmount')}</span>
                    {depositCurrencyType === 'JPY' &&
                      depositInfo.channelCode === 'vegaWallet' &&
                      amount > 0 &&
                      rate.realRate && <br />}
                    <span>
                      &nbsp;≈ {getCurrencySymbol(depositCurrencyType)}
                      {decimalFormat(amount, depositCurrencyType)}
                    </span>
                    {depositCurrencyType === 'JPY' &&
                      depositInfo.channelCode === 'vegaWallet' && amount &&
                      rate.realRate && <span>≈ ${decimalFormat(amount * rate.realRate, 'USD')}</span>}
                  </p>
                </div>
                <Button disabled={isSubmitDisabled} type="primary" onClick={onSubmit}>
                  {t('deposit.confirm')}
                </Button>
              </Form>

              {(depositInfo.channelCode === 'deskdzjpypay' || depositInfo.channelCode === 'banktobank') && (
                <a
                  className="bank-tips"
                  onClick={() => {
                    setIsShowBankDepositTips(true)
                  }}
                >
                  {t('deposit.bankTips')}
                </a>
              )}
            </>
          ) : (
            <BankToBank />
          )}
          {isShowBankDepositTips && (
            <BankDepositTips
              hideDialog={() => {
                setIsShowBankDepositTips(false)
              }}
            />
          )}
          {!props.isFastDeposit && !isShowBanktoBank && (
            <DepositText depositInfo={depositInfo} fiatCurrency={depositCurrencyType} />
          )}
        </div>
      )}
    </div>
  )
}

export default HibanqDeposit
