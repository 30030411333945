'use client'
import { useEffect, memo, useState } from 'react'
import { cn } from '@/lib/utils'
import { useTranslation } from '@/lib/locales/client'
import { useAppStore } from '@/stores/app'
import { useUserStore } from '@/stores/user'
import { Tabs } from 'antd'
// import { useDeepCompareEffect } from 'ahooks'
import { useShallow } from 'zustand/react/shallow'
import styles from './index.module.scss'
// import { Progress } from 'antd'
import { usePathname } from 'next/navigation'
import dynamic from 'next/dynamic'
import Reward from '@/app/[lng]/(promotion)/reward/_components/client'
// import RewardPage from '@/app/[lng]/(promotion)/reward/page'
import { CSSTransition } from 'react-transition-group'

const NotificPanel = () => {
  const { isShowNotification, setIsShowNotification, isBigScreen } = useAppStore(
    useShallow((state) => ({
      isShowNotification: state.isShowNotification,
      setIsShowNotification: state.setIsShowNotification,
      isBigScreen: state.isBigScreen
    }))
  )
  const { getBonusTaskList, updateUserInfo, getDepositActivity, bonusAndTask, isLogin } = useUserStore(
    useShallow((state) => ({
      getBonusTaskList: state.getBonusTaskList,
      updateUserInfo: state.updateUserInfo,
      getDepositActivity: state.getDepositActivity,
      bonusAndTask: state.bonusAndTask,
      isLogin: state.isLogin
    }))
  )
  const pathName = usePathname()
  const rawPath = pathName.replace(/^\/(en|ja)($|\/)/g, '/')
  // const [percent, setPercent] = useState(-1)
  // const [newTask, setNewTask] = useState(false)
  const Voucher = dynamic(() => import('@/app/[lng]/(promotion)/voucher/_components/client'), { ssr: false })

  const { t } = useTranslation()
  const items = [
    { label: t('promo.reward'), key: '0', children: <Reward /> },
    { label: t('common.account.voucher'), key: '1', children: <Voucher /> }
    // { label: t('common.headerNav.promotions'), key: '0', children: <Promo /> },
    // { label: t('promo.reward'), key: '1', children: <Reward /> },
    // { label: t('common.account.voucher'), key: '2', children: <Voucher /> }
  ]
  // const active = pathList.indexOf(pathname.substring(3)).toString()
  // const handleTab = (mod) => {
  //   if(mod === 1) {
  //     return moduleList[0]
  //   } else if(mod === 2) {
  //     return moduleList[1]
  //   } else {
  //     return moduleList[2]
  //   }
  // }
  // const switchNotifica = () => {
  //   if (!isShowNotification) {
  //     getBonusTaskList()
  //     updateUserInfo()
  //     getDepositActivity()
  //   }
  //   setIsShowNotification(!isShowNotification)
  //   setNewTask(false)
  // }

  // useDeepCompareEffect(() => {
  //   setNewTask(bonusAndTask.length > 0)
  //   const progressList = bonusAndTask.filter((item) => [3, 5, 10].includes(item.bonusStatus))
  //   if (progressList.length > 0) {
  //     let maxItem = Number(progressList[0].betAmount) / Number(progressList[0].requiredBetAmount)
  //     progressList.forEach((item) => {
  //       const x = Number(item.betAmount) / Number(item.requiredBetAmount)
  //       maxItem = maxItem > x ? maxItem : x
  //     })
  //     setPercent(Number((maxItem * 100).toFixed(2)))
  //   }
  // }, [bonusAndTask])

  return (
    <>
      {isBigScreen && isLogin && !['/load'].includes(rawPath) && isShowNotification && (
        <CSSTransition timeout={300} unmountOnExit in={isShowNotification} classNames={'fade'}>
          <div className={styles['notificPage_warp']}>
            <div className="mask" onClick={() => setIsShowNotification(false)}>
            </div>
            <div className="notificPage_content">
              {/* <RewardPage /> */}
              <Tabs className={cn(styles['common-tab'], 'w-[375px]')} defaultActiveKey="0" items={items} />
            </div>
          </div>
        </CSSTransition>
        // <div
        //   onClick={switchNotifica}
        //   className={cn(
        //     styles['notification_icon'],
        //     isBigScreen ? styles['pc'] : '',
        //     isShowNotification ? styles['open'] : ''
        //   )}
        // >
        //   {(percent === -1 || (isShowNotification && isBigScreen) || newTask) && (
        //     <div
        //       className={cn(
        //         'icon',
        //         newTask && isShowNotification ? 'newTask' : '',
        //         isShowNotification && isBigScreen ? 'close_icon' : ''
        //       )}
        //     ></div>
        //   )}

        //    <Progress
        //     type="circle"
        //     strokeColor={{
        //       '0%': '#F1D975',
        //       '100%': '#F11362'
        //     }}
        //     trailColor="#00000026"
        //     percent={percent === -1 ? 0 : percent}
        //     strokeLinecap="round"
        //     showInfo={percent >= 0 && (!isShowNotification || !isBigScreen) && !newTask}
        //     strokeWidth={10}
        //   ></Progress>
        // </div>
      )}
    </>
  )
}

export default memo(NotificPanel)
