'use client'
import { useState, useEffect, FC, useMemo } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { Spin } from 'antd'
import { useUserStore } from '@/stores/user'
import { useAppStore } from '@/stores/app'
import { useTranslation } from '@/lib/locales/client'
import { createVirtualDeposit } from '@/api/wallet'
import { cn, depositSwitch } from '@/lib/utils'
import DepositText from './depositText'
import DepositData from './depositData'
import SearchSelect from '@/components/base/searchSelect'
import QRCode from 'react-qr-code'
import { useUpdateEffect } from 'ahooks'

type IProps = {
  depositInfo?: any
  isFastDeposit?: boolean
  buyVirtualCurrency?: string
}

const VirtualDeposit: FC<IProps> = (props) => {
  const { depositInfo, isFastDeposit, buyVirtualCurrency } = props
  const { t } = useTranslation()
  const storeCurrency = useUserStore((state) => state.currency?.currency)
  const { isBigScreen, SET_GLOBAL_MSG } = useAppStore(
    useShallow((state) => ({
      isBigScreen: state.isBigScreen,
      SET_GLOBAL_MSG: state.SET_GLOBAL_MSG
    }))
  )
  const [currency, setCurrency] = useState('')
  const [sourceData, setSourceData] = useState([])
  const [currentProtocolIndex, setCurrentProtocolIndex] = useState(0)
  const [addressValue, setAddressValue] = useState('')
  const [tag, setTag] = useState('')
  const [spinning, setSpinning] = useState(true)
  const [protocolList, setProtocolList] = useState([])

  const chooseProtocol = (index) => {
    setCurrentProtocolIndex(index)
  }

  const createDeposit = () => {
    setSpinning(true)
    setAddressValue('')
    const params = {
      currency: currency,
      protocol: protocolList[currentProtocolIndex],
      tranAmount: 0
    }
    createVirtualDeposit(params).then((res) => {
      if (res.success) {
        setAddressValue(res.data.address)
        setTag(res.data.tag)
        setSpinning(false)
      }
    })
  }

  const handleCopy = (v) => {
    navigator.clipboard.writeText(v)
    SET_GLOBAL_MSG({ flag: true, type: 'success', content: t('deposit.copy') })
  }

  useEffect(() => {
    const channelInfo = depositInfo.channelInfo
    let cur
    if (channelInfo) {
      if (buyVirtualCurrency && channelInfo[buyVirtualCurrency]) {
        cur = buyVirtualCurrency
      } else {
        //如果当前钱包币种是支持的虚拟币，则默认选中
        cur =
          storeCurrency !== 'JPY' && storeCurrency !== 'USD' && channelInfo[storeCurrency]
            ? storeCurrency
            : 'USDT'
      }
      const arr = []
      for (let i in channelInfo) {
        arr.push({ label: i, value: i, sort: channelInfo[i].sort })
      }
      const data = arr.sort((a, b) => a.sort - b.sort)
      setSourceData(data)

      if (!channelInfo[cur]) {
        cur = data[0].label
      }
      setCurrency(cur)
    }
  }, [depositInfo.channelInfo])

  useEffect(() => {
    if (currency !== '') {
      setProtocolList(depositInfo.channelInfo[currency].protocols)
      setCurrentProtocolIndex(0)
    }
  }, [currency])

  useUpdateEffect(() => {
    createDeposit()
  }, [protocolList, currentProtocolIndex])

  useEffect(() => {
    depositSwitch('show')
  }, [depositInfo])

  return (
    <div>
      <div className='wallet-container grey'>
        {!isBigScreen && (
          <div
            className="close"
            onClick={() => {
              depositSwitch('hide')
            }}
          >
            <img src={'/static/images/service/del.png'} />
          </div>
        )}
        <div className="top-icon">
          <div className="left_box">
            {depositInfo?.channelLogoPath && <img src={depositInfo?.channelLogoPath} className="logo" />}
            <DepositData item={depositInfo} />
          </div>
        </div>
        <div className="virtual-box">
          <SearchSelect
            styleType="full"
            value={currency}
            onChange={(val) => setCurrency(val)}
            options={sourceData}
          />
          <div className="amount-box">
            {protocolList.map((item, index) => (
              <span
                key={item}
                className={index === currentProtocolIndex ? 'active' : ''}
                onClick={() => {
                  chooseProtocol(index)
                }}
              >
                {item}
              </span>
            ))}
          </div>
          <div className="code-box">
            <Spin spinning={spinning} size="large">
              <QRCode value={addressValue} size={130}></QRCode>
            </Spin>
            {addressValue && (
              <>
                {tag && (
                  <div className="address-box all">
                    <span>{t('deposit.tag')}</span>
                    <span>{tag}</span>
                    <img
                      onClick={() => {
                        handleCopy(tag)
                      }}
                      src={'/static/images/deposit/ic-copy-black.svg'}
                    />
                  </div>
                )}

                <div className="address-box">
                  <span>{addressValue}</span>
                  <img
                    onClick={() => {
                      handleCopy(addressValue)
                    }}
                    src={'/static/images/deposit/ic-copy-black.svg'}
                  />
                </div>
                <p className="note">
                  <img src={'/static/images/deposit/exclamation-red.png'} />
                  <span
                    dangerouslySetInnerHTML={{
                      __html:
                        currency?.toLowerCase() === 'xrp'
                          ? t('deposit.xrp')
                          : t('deposit.tips', {
                              currency: currency,
                              protocol: protocolList[currentProtocolIndex]
                            })
                    }}
                  ></span>
                </p>
              </>
            )}
          </div>
        </div>
        {!isFastDeposit && (
          <DepositText depositInfo={depositInfo} virtualCurrency={currency} />
        )}
      </div>
    </div>
  )
}

export default VirtualDeposit
