'use client'
import { useAppStore } from '@/stores/app'
import { cn } from '@/lib/utils'
import styles from './index.module.scss'
import { memo, useEffect, useMemo, useRef, useState } from 'react'
import { useUserStore } from '@/stores/user'
import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { signRewardFetch } from '@/api/activity'
import { message } from 'antd'
import RewardSwiper from '../rewardSwiper'
import RewardCard from '../rewardCard'
import BonusReceive from '../bonusReceive'
import { useTranslation } from '@/lib/locales/client'
import { useShallow } from 'zustand/react/shallow'
import ReactDOMServer from 'react-dom/server'

const RewardQuestBonus = () => {
  const { language } = useAppStore()
  const { getSignDetail, getBonusTaskList, signDetail, kycAuditStatus } = useUserStore(
    useShallow((state) => ({
      getSignDetail: state.getSignDetail,
      getBonusTaskList: state.getBonusTaskList,
      signDetail: state.signDetail,
      kycAuditStatus: state.kycAuditStatus
    }))
  )
  const { t } = useTranslation()
  const swiperRef = useRef(null)
  const [spinning, setSpinning] = useState(true)
  const [rewardList, setRewardList] = useState([])
  const [receiveDialog, setReceiveDialog] = useState(false)
  const [dialogData, setDialogData] = useState({})
  const onSwiper = (swiper: any) => {
    swiperRef.current = swiper
  }
  const getDelHtml = (data: any) => {
    return (
      <>
        <h3 className="sign-title">{t('promo.sign.title')}</h3>
        {data.map((el,index) => (
          <div className="sign-item"  key={index}>
            <div>
              <span>{el.day}</span>
              <span>{t('promo.time')}</span>
            </div>
            {el.prizeType === 1 ? (
              <p>
                {t('promo.bonus')} <span> {el.prizeAmount}</span>
              </p>
            ) : (
              <p>
                {t('promo.spin')}{' '}
                <span>
                  {' '}
                  {el.prizeAmount} {t('promo.time')}
                </span>
              </p>
            )}
          </div>
        ))}
        <h4 className="rule-title">{t('promo.sign.ruleTitle')}</h4>
        <p className="rule-text">{t('promo.sign.rule')}</p>
      </>
    )
  }
  const receiveBonus = (data: any) => {
    const { hasSign, fetchFlag, signId, prizeCode } = data
    const param: any = { activityCode: 'bFZwyXBQna' }
    if (signId && hasSign && !fetchFlag) {
      param.signId = signId
    } else if (prizeCode) {
      return
    }
    signRewardFetch(param).then(({ success, data }: any) => {
      if (success && data.length) {
        const bonusData = data.find((item: any) => item.prizeType === 1)
        const freeData = data.find((item: any) => item.prizeType === 6)
        let newData = {}
        if (bonusData && freeData) {
          newData = { ...bonusData, signFreeTimes: freeData.prizeAmount }
        } else {
          newData = data[0]
        }
        setDialogData({ ...newData, rewardName: language === 'ja-JP' ? 'ログイン報酬' : 'Daily Reward' })
        setReceiveDialog(true)
      }
      setTimeout(() => {
        getSignDetail()
      }, 500);
    })
  }
  const handleReceive = () => {
    getBonusTaskList()
    setReceiveDialog(false)
  }

  useEffect(() => {
    getSignDetail()
  }, [])

  useEffect(() => {
    if (!signDetail) {
      return
    }
    setRewardList([
      {
        rewardName: signDetail.activityDescribes?.find((item: any) => item.lang === language)?.title,
        available: signDetail.fetchFlag,
        rewardType: 'sign',
        signList: signDetail.conValObj.daySignArrConfig,
        //使用ReactDOMServer.renderToStaticMarkup 把jsx转换成dangerouslySetInnerHTML 识别的文本
        fixedDetails: ReactDOMServer.renderToStaticMarkup(getDelHtml(signDetail.conValObj.daySignArrConfig)),
        endTime:
          signDetail.todaySignCount && !signDetail.fetchFlag
            ? Number(new Date(new Date().setHours(0, 0, 0, 0) + 24 * 60 * 60 * 1000 - 1)) - 1
            : null
      }
    ])

    if (kycAuditStatus < 0.5) {
      message.open({
        type: 'warning',
        content: t('promo.sign.needKyc')
      })
    }
    if (signDetail.totalSignCount > 2) {
      swiperRef.current?.slideTo(signDetail.totalSignCount - 2, 300, false)
    }

    setSpinning(false)
  }, [signDetail])

  const signListData = useMemo(() => {
    return rewardList[0]?.signList || []
  }, [rewardList])

  const getImgUrl = (signItem: any) => {
    return `/static/images/promo/${signItem.prizeType === 1 ? 'bouns' : 'free'}.png`
  }
  // Quest Bonusn 轮播滑块内容signList
  const signList = useMemo(() => {
    return (
      <>
        {signListData.length > 0 && (
          <Swiper className={styles['sign-warp']} slidesPerView={3} spaceBetween={8} onSwiper={onSwiper}>
            {signListData.map((signItem: any, index: number) => (
              <SwiperSlide key={signItem.day + index} className="sign-item-box">
                <div
                  className={cn(
                    'sign-item',
                    signItem.hasSign && !signItem.fetchFlag ? 'available' : '',
                    signItem.fetchFlag ? 'claimed' : ''
                  )}
                  onClick={() => receiveBonus(signItem)}
                >
                  <img src={getImgUrl(signItem)} />
                  <p>
                    {signItem.prizeType === 1 ? t('promo.bonus') : t('promo.spin')} + {signItem.prizeAmount}
                  </p>
                  {signItem.fetchFlag != 0 && <i></i>}
                </div>
                <p>
                  {signItem.day} {t('promo.time')}
                </p>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </>
    )
  }, [signListData])

  return (
    <>
      <div className={styles['reward-quest-bonus']}>
        <RewardSwiper
          swipData={rewardList}
          spinning={spinning}
          render={(item: any, key: string) => (
            <RewardCard
              cardInfo={item}
              cardType="sign"
              key={key}
              claim={receiveBonus}
              signList={signList}
            ></RewardCard>
          )}
        ></RewardSwiper>
      </div>
      {receiveDialog && (
        <BonusReceive
          dialogData={dialogData}
          close={() => setReceiveDialog(false)}
          receiveBonus={handleReceive}
        />
      )}
    </>
  )
}

export default memo(RewardQuestBonus)
