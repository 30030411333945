'use client'
import { useEffect, useRef, useState, FC, memo } from 'react'
import { useSetState } from 'ahooks'
import { Modal } from 'antd'
import { useAppStore } from '@/stores/app'
import { ReloadOutlined } from '@ant-design/icons'
import { cn } from '@/lib/utils'
import { useTranslation } from '@/lib/locales/client'
import { queryValidateSlider, generateSlider } from '@/api/base'
import { Base64 } from 'js-base64'
import styles from './index.module.scss'

// 1注册 2登录 3忘记密码
type IProps = {
  use: number // 1注册 2登录 3忘记密码
  txt?: string
  fullTxt?: string //   // 显示以fullTxt为主
  loginName?: string
  // useType?: any
  callback?: (options?: any) => void
  close?: () => void
}

interface IslideImg {
  cutoutImage: string
  shadeImage: string
  type: any
  operationImage: string
  x: number
  y: number
  captchaId: string
  validateResult: any
  ticket: any
}

const SliderCaptcha: FC<IProps> = (props) => {
  const { use = 2 } = props
  const { t } = useTranslation()
  const isBigScreen = useAppStore((state) => state.isBigScreen)
  const [sliderCaptchaData, setSliderCaptchaData] = useSetState<IslideImg>({
    cutoutImage: '',
    shadeImage: '',
    type: null,
    operationImage: '',
    x: 0,
    y: 0,
    captchaId: '',
    validateResult: null,
    ticket: null
  })
  const moveObj = useRef<any>({
    x: 0,
    downCoordinate: {
      x: 0,
      y: 0
    },
    dom: null
  })
  // 滑块验证
  const captcha = useRef<{ x: number; y: number }>({
    x: 0,
    y: 0
  })

  const [leftMax, setLeftMax] = useState(0) // 最大左移距离
  // const [coefficient, setCoefficient] = useState(null) // 放大系数
  // const [isdown, setIsdown] = useState(false)
  // const [showPopup, setShowPopup] = useState(true)
  const coefficient = useRef(null) // 滑块验证码状态 init 初始 ing 滑动中 success 成功 fail 失败
  const sliderStatus = useRef('init') // 滑块验证码状态 init 初始 ing 滑动中 success 成功 fail 失败
  const sliderCaptcha = useRef(null)
  const sliderBlock = useRef<any>(null)
  const sliderBg = useRef(null)
  const blockBg = useRef(null)

  const init = async () => {
    try {
      await getSliderCaptcha()
      sliderStatus.current = 'init'
    } catch (err) {
      props.close()
    }
  }

  useEffect(() => {
    // await loadDataThen(() => appStore.commonConfig && appStore.commonConfig.reCaptchaStatus)
    init() // isGoogleVerify拿到的是正确的数据
  }, [])

  // pc 鼠标按下
  const touchStart = (e) => {
    if (sliderStatus.current === 'success') {
      return
    }
    moveObj.current = {
      x: 0,
      downCoordinate: { x: e.pageX, y: e.pageY },
      dom: e.target
      // sliderBlock: sliderBlock.current
    }
    document.addEventListener('mousemove', touchMove)
    document.addEventListener('mouseup', touchEnd)
    // setIsdown(true)
  }

  const touchMove = (moveEV) => {
    // if (!isdown) return
    if (sliderStatus.current === 'success') {
      return
    }
    // 开始拖动滑动
    moveObj.current.x = moveEV.pageX - moveObj.current.downCoordinate.x
    if (moveObj.current.x >= leftMax || moveObj.current.x <= 0) return false
    // 滑块取值
    moveObj.current.dom.style.left = moveObj.current.x + 'px'
    // 滑轨拖动背景跟上
    blockBg.current.style.width = moveObj.current.x + 32 + 'px'
    sliderBlock.current.style.left = moveObj.current.x + 'px'
    // 验证拼图
    captcha.current.x = moveObj.current.x * coefficient.current
    captcha.current.y = sliderCaptchaData.y
    sliderStatus.current = 'ing'
  }

  const touchEnd = () => {
    // setIsdown(false)
    if (sliderStatus.current === 'success') {
      return
    }
    validateSlider()
  }

  // useEffect(() => {
  //   // setMoveObj1(moveObj.current)
  //   console.log('读取 captcha', captcha.current)
  // }, [captcha.current])

  // --- h5 相关
  const touchStarth5 = (e) => {
    if (sliderStatus.current === 'success') {
      return
    }
    moveObj.current = {
      x: 0,
      downCoordinate: { x: e.touches[0].pageX, y: e.touches[0].pageY },
      dom: e.target
      // sliderBlock: sliderBlock.current
    }
    document.addEventListener('mousemove', touchMove)
    document.addEventListener('mouseup', touchEnd)
  }
  const touchMoveh5 = (moveEV) => {
    if (sliderStatus.current === 'success') {
      return
    }
    moveObj.current.x = moveEV.touches[0].pageX - moveObj.current.downCoordinate.x
    if (moveObj.current.x >= leftMax || moveObj.current.x <= 0) return false
    // 滑块拖动
    moveObj.current.dom.style.left = moveObj.current.x + 'px'
    // 滑轨拖动背景跟上
    blockBg.current.style.width = moveObj.current.x + 32 + 'px'
    sliderBlock.current.style.left = moveObj.current.x + 'px'
    // 验证拼图
    captcha.current.x = moveObj.current.x * coefficient.current
    captcha.current.y = sliderCaptchaData.y
    sliderStatus.current = 'ing'
  }

  const touchEndh5 = async () => {
    if (sliderStatus.current === 'success') {
      return
    }
    validateSlider()
  }

  // 验证滑块，captcha是匹配拼图的x,y轴 用于提交
  const validateSlider = async () => {
    try {
      const params = {
        use: use, // 1:注册; 2:登录; 3:忘记密码; 9:其他,
        captchaId: sliderCaptchaData.captchaId,
        captcha: Base64.encode(JSON.stringify(captcha.current)),
        noDialog: true,
        loginName: props.loginName
      }
      let res = await queryValidateSlider(params)
      if (Number(res.data.validateResult) === 0) {
        throw new Error()
      } else {
        sliderStatus.current = 'success'
        // 待观察，现在可能在滑动正确时还会继续弹出验证
        setTimeout(() => {
          props.callback({
            captchaType: 2,
            captchaId: params.captchaId,
            // captchaId: sliderCaptchaData.captchaId,
            captcha: params.captcha
            // captcha: Base64.encode(JSON.stringify(captcha))
          })
          props.close()
          // 回调关闭事件
        }, 500)
      }
    } catch (error) {
      // setMoveObj({ x: 0 })
      moveObj.current.x = 0
      sliderStatus.current = 'fail'
      reset()
      getSliderCaptcha()
    } finally {
      document.removeEventListener('mousemove', touchMove)
      document.removeEventListener('mouseup', touchEnd)
    }
  }
  // 重置各个状态
  const reset = () => {
    sliderBlock.current.style.left = 0
    moveObj.current.dom.style.left = 0
    moveObj.current = {
      x: 0,
      downCoordinate: {
        x: 0,
        y: 0
      },
      dom: null
      // sliderBlock: sliderBlock.current
    }
    captcha.current = {
      x: 0,
      y: 0
    }
    blockBg.current.style.width = 32 + 'px'
  }

  // 获取滑块验证码
  const getSliderCaptcha = async () => {
    // 用途[1:注册; 2:登录; 3:忘记密码; 9:其他][生成图形验证码时必填]
    const res = await generateSlider({ use: use, loginName: props.loginName })
    const result = res as any // 有时间处理完返回值 再一起改
    if (result && result.success) {
      setSliderCaptchaData(result.data)
      captcha.current.y = result.data.y
      return { body: result.data }
    } else {
      if (result?.msg) return Promise.reject(result?.msg)
    }
    return Promise.reject('login fail')
  }

  const refresh = () => {
    sliderBlock.current.style.left = 0
    if (moveObj.current.x) {
      moveObj.current.x = 0
    }
    if (moveObj.current.dom) {
      moveObj.current.dom.style.left = 0
      moveObj.current.dom.style.right = 'auto'
    }
    init()
  }

  const filterTxt = (val) => {
    const { t } = useTranslation()
    return t('user.sliderCaptcha.filterTxt')
  }
  const imgLoad = () => {
    // 图片可能缩放 做缩放时的处理
    // 放大系数
    // console.log('是否放大?', coefficient.current, sliderCaptchaData)
    // if (coefficient) {
    if (!coefficient.current) {
      // setCoefficient(sliderBg.current.naturalWidth / sliderBg.current.clientWidth)
      coefficient.current = sliderBg.current.naturalWidth / sliderBg.current.clientWidth
      // console.log('自然距离', sliderBg.current.naturalWidth, sliderBg.current.clientWidth)
      // console.log('滑头缩放', sliderBlock.current.style)
      sliderBlock.current.style.width = sliderBlock.current.clientWidth / coefficient.current + 'px'
      sliderBlock.current.style.top = sliderCaptchaData.y / coefficient.current + 'px'
      setLeftMax(sliderBg.current.clientWidth - sliderBlock.current.clientWidth)
    } else {
      sliderBlock.current.style.top = sliderCaptchaData.y / coefficient.current + 'px'
    }
    // 解决浏览器自带左右滑动设置冲突问题
    sliderCaptcha.current.addEventListener(
      'touchmove',
      (event) => {
        event.preventDefault()
      },
      false
    )
  }

  useEffect(() => {
    return () => {
      document.removeEventListener('mousemove', touchMove)
      document.removeEventListener('mouseup', touchEnd)
    }
  }, [])

  return (
    <>
      <Modal
        className={styles.sliderCaptcha}
        open={true}
        width={340}
        footer={null}
        centered
        closable={false}
        zIndex={2001}
        onCancel={props.close}
      >
        {/* 图形验证   */}
        <div ref={sliderCaptcha} className={cn('img', sliderStatus)}>
          <div className={'img-wrap'}>
            {/* 图片拼图划块 */}
            {sliderCaptchaData.cutoutImage && (
              <img
                ref={sliderBlock}
                className={'slider'}
                // style={{
                //   left: moveObj.current.x >= leftMax || moveObj.current.x <= 0 ? 0 : moveObj.current.x + 'px',
                //   top: sliderCaptchaData.y + 'px'
                // }}
                src={`data:image/jpeg;base64,${sliderCaptchaData.cutoutImage}`}
              />
            )}

            {sliderCaptchaData.shadeImage && (
              <img
                ref={sliderBg}
                className={'slider-bg'}
                src={`data:image/jpeg;base64,${sliderCaptchaData.shadeImage}`}
                onLoad={imgLoad}
              />
            )}

            {/* 成功  */}
            {sliderStatus.current === 'success' && (
              <div className={'status-box'}>
                <img src={'/static/images/user/captcha/success.png'} className={'status-box-icon'} />
                <div className={'status-box-txt'}>{t('user.sliderCaptcha.verifySuccess')}</div>
              </div>
            )}

            {/* 失败 */}
            {sliderStatus.current === 'fail' && (
              <div className={'status-box'}>
                <img src={'/static/images/user/captcha/fail.png'} className={'status-box-icon'} />
                <div className={'status-box-txt'}>{t('user.sliderCaptcha.fail')}</div>
              </div>
            )}
            <ReloadOutlined onClick={refresh} className={cn('refresh', 'color-[#b0bbcc] text-[22px]')} />
          </div>

          <div className={'block-container'}>
            <div className={'block-box'}>
              {isBigScreen ? (
                <div className={'block'} onMouseDown={touchStart} />
              ) : (
                <div
                  className={'block'}
                  onTouchStart={touchStarth5}
                  onTouchMove={touchMoveh5}
                  onTouchEnd={touchEndh5}
                />
              )}

              <div className={'txt'}>
                {/* props.fullTxt */}
                {filterTxt(sliderStatus)}
              </div>
              <div ref={blockBg} className={'inner'} />
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default memo(SliderCaptcha)
