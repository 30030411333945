/*
 * @description: 格式化数字-千分位
 * @param {len}: 0 表示取整，其余按位取小数点后几位
 */
import dayjs from 'dayjs'
import { useAppStore } from '@/stores/app'
// import { useParams } from 'next/navigation'
// import { Lng } from './locales/i18n'

export const numThFilter = (num: any, len = 0) => {
  if (isNaN(num)) return num
  if (num == null || !num) num = 0
  num = num.toString()
  if (num.indexOf('.') > -1) {
    num = num.substring(0, num.indexOf('.') + len + 1)
  }
  num = num.replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,')
  /* 补零处理 */
  if (num.indexOf('.') > -1) {
    const decimal = num.substring(num.indexOf('.') + 1)
    if (decimal.length < len) {
      num = num.padEnd(num.length + len - decimal.length, '0')
    }
  } else {
    num = num + '.'
    num = num.padEnd(num.length + len, '0')
  }
  /* 0-取整处理 */
  len <= 0 && (num = num.replace('.', ''))
  /* 小数位千分位去除 */
  if (num.indexOf('.') > -1) {
    const _end = num.split('.')[1].replace(',', '')
    const _star = num.split('.')[0]
    num = `${_star}.${_end}`
  }
  return num
}

/*
@description: 格式化数字-转换为k单位
@param {len}: 表示取小数点后几位-不做四舍五入处理
*/
export const numberToKUnit = (num: any, len = 2) => {
  if (Number(num) < 1000) {
    const strAmount = String(num).includes('.') ? String(num) : `${num}.00`
    const left = strAmount.split('.')[0]
    const right = strAmount.split('.')[1].padEnd(2, '0').substring(0, len)
    if (len === 0) return left
    return left + '.' + right
  } else {
    const res = (Number(num) / 1000).toString()
    if (String(res).includes('.')) {
      const left = res.split('.')[0]
      const right = res.split('.')[1].padEnd(2, '0').substring(0, len)
      if (len === 0) return left + 'K'
      return left + '.' + right + 'K'
    } else {
      if (len === 0) return res + 'K'
      return res + '.00' + 'K'
    }
  }
}

export const formatAmount = (amount: any, len = 2, isThousandths = true) => {
  if (amount <= 0 || !amount) return `0${len > 0 ? '.' : ''}` + ''.padEnd(len, '0')
  const str = String(Number(amount))
  const arr = str.split('.')
  const left = isThousandths ? arr[0].replace(/\B(?=(\d{3})+\b)/g, ',') : arr[0]
  let right = arr[1]
  if (right) {
    if (right.length >= len) {
      right = right.substring(0, len)
    } else {
      right = right.padEnd(len, '0')
    }
    return `${left}.${right}`
  }
  return len === 0 ? left : `${left}${len > 0 ? '.' : ''}` + ''.padEnd(len, '0')
}

export const nFormatter = (num: number, digits: number) => {
  const si = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'B' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' }
  ]
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
  let i
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break
    }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol
}

export const getBenefitsName = (name: any, lang: string) => {
  // const { lng }: { lng: Lng } = useParams()
  // const { language } = useAppStore()
  if (name) {
    const benefitName = JSON.parse(name)
    for (const key in benefitName) {
      if (key.startsWith(lang)) {
        return benefitName[key] || ''
      }
    }
  }
}

export const filterRank = (rank: number) => {
  if (rank >= 0 && rank <= 20) {
    return 'Iron'
  }
  if (rank >= 21 && rank <= 40) {
    return 'Bronze'
  }
  if (rank >= 41 && rank <= 60) {
    return 'Silver'
  }
  if (rank >= 61 && rank <= 80) {
    return 'Gold'
  }
  if (rank >= 81 && rank <= 100) {
    return 'Platinum'
  }
  if (rank >= 101 && rank <= 120) {
    return 'Emerald'
  }
  if (rank >= 121 && rank <= 129) {
    return 'Diamond'
  }
  if (rank === 130) {
    return 'Legend'
  }
}

const getNextMonday = (now: any) => {
  const dayOfWeek = now.getDay() // 0 表示星期日，1 表示星期一，以此类推
  const diff = dayOfWeek === 0 ? 1 : 8 - dayOfWeek // 如果今天是星期日，则需要1天，否则需要至少7天
  const nextMonday = new Date(now.getTime() + diff * 24 * 60 * 60 * 1000) // 将当前时间增加相应的天数
  return nextMonday
}

export const getRakebackTime = () => {
  //换成日本时区
  const language = useAppStore((state) => state.language)
  let nowTime: any = new Date(new Date().toLocaleString('sv', { timeZone: 'Asia/Tokyo' }))
  let endTime: any = new Date(new Date().toLocaleString('sv', { timeZone: 'Asia/Tokyo' }))
  //如果是星期一中午12点以前取当前星期一，否则取下个星期一
  if (!(nowTime.getDay() === 1 && nowTime.getHours() < 12)) {
    endTime = getNextMonday(endTime)
  }
  endTime.setHours(12, 0, 0)
  endTime = dayjs(endTime)
  nowTime = dayjs(nowTime)
  const days = endTime.diff(nowTime, 'day')
  let hours: any = endTime.diff(nowTime, 'hour') % 24
  let minutes: any = endTime.diff(nowTime, 'minute') % 60
  let seconds: any = endTime.diff(nowTime, 'second') % 60
  hours = hours < 0 ? '00' : hours <= 9 ? '0' + hours : hours
  minutes = minutes < 0 ? '00' : minutes <= 9 ? '0' + minutes : minutes
  seconds = seconds < 0 ? '00' : seconds <= 9 ? '0' + seconds : seconds

  if (language === 'ja-JP') {
    return `${days}日 ${hours}：${minutes}：${seconds}`
  } else {
    return `${days}days ${hours}：${minutes}：${seconds}`
  }
}

//弹窗的数字 去除两位小数后的0
export const popNumFormat = (num: any, cur = 'JPY') => {
  if (isNaN(num) || !num) return '0.00'
  num = parseFloat(num).toString()
  let integer = '' //整数部分
  let decimal = '' //小数部分
  if (num.split('.')) {
    integer = num.split('.')[0]
    if (num.split('.').length > 1) {
      decimal = num.split('.')[1]
    }
  }

  integer = integer.replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,')
  let numberlength = 2 //保留几位小数
  //如果是法币，保留两位，
  if (cur === 'JPY' || cur === 'USD') {
    numberlength = 2
  } else {
    numberlength = 8
  }
  if (decimal.length === 0) {
    decimal += '00'
  }
  if (decimal.length === 1) {
    decimal += '0'
  }
  if (decimal.length > numberlength) {
    decimal = decimal.slice(0, numberlength)
  }
  return integer + '.' + decimal
}

/*
@description: 格式化数字-转换为k单位
@param {len}: 表示取小数点后几位-不做四舍五入处理
*/
export const numberToKMUnit = (num: any) => {
  if (Number(num) < 1000) {
    const strAmount = String(num).includes('.') ? String(num) : `${num}.00`
    const left = strAmount.split('.')[0]
    const right = strAmount.split('.')[1].padEnd(2, '0').substring(0, 1)
    if (Number(right) === 0) return left
    return left + '.' + right
  } else if (Number(num) < 1000000) {
    const res = (Number(num) / 1000).toString()
    if (String(res).includes('.')) {
      const left = res.split('.')[0]
      const right = res.split('.')[1].padEnd(2, '0').substring(0, 1)
      if (Number(right) === 0) return left
      return left + '.' + right + 'K'
    } else {
      return res + 'K'
    }
  } else {
    const res = (Number(num) / 1000000).toString()
    if (String(res).includes('.')) {
      const left = res.split('.')[0]
      const right = res.split('.')[1].padEnd(2, '0').substring(0, 1)
      if (Number(right) === 0) return left
      return left + '.' + right + 'M'
    } else {
      return res + 'M'
    }
  }
}
