import {
  launch_game,
  launch_game_demo,
  getTransferRegister,
  transferAsyncTransferIn,
  launch_anonymous_game
} from '@/api/game'
import { useGameStore } from '@/stores/game'
import { getUrlParameter } from '@/lib/utils'
import { useAppStore } from '@/stores/app'
import { useUserStore } from '@/stores/user'
import { useAuthStore } from '@/stores/authControl'
import { gameFavorAdd, gameFavorDelete } from '@/api/game'
import { track } from '@/hooks/useTrack'
import i18next from 'i18next'

export const favoriteGameFun = (item: any, cb?: any) => {
  const { favoriteGameList, getFavoriteGameList } = useGameStore.getState()
  const isDelete = favoriteGameList.some(
    (e: any) => e.gameId === item.gameId && e.providerCode === item.platformCode
  )
  const fn = isDelete ? gameFavorDelete : gameFavorAdd
  fn({
    gameId: item.gameId,
    providerCode: item.platformCode
  }).then((res: any) => {
    getFavoriteGameList()
    if (cb && typeof cb === 'function') cb(isDelete)
  })
}

export const openConfigGame = (isTry: number, path: any) => {
  const { SET_ROUTER_PUSH } = useAppStore.getState()
  const { isLogin } = useUserStore.getState()
  const { openLogin } = useAuthStore.getState()
  const obj = getUrlParameter(path)
  if (path.includes("/fb")) {
    openGame(isLogin ? 0 : 1, {
      platformCode: 'FB',
      gameId: 'fb_lobby'
    })
  } else if (path.includes("/hippo-sports")) {
    openGame(isLogin ? 0 : 1, {
      platformCode: 'K8SPORTS',
      gameId: 'hp1_lobby',
      racing: obj.racingUrl
    })
  } else if (path.includes("/sports-bet")) {
    if (!isLogin) {
      openLogin()
      return
    } else {
      openGame(0, {
        platformCode: 'HUB88',
        gameId: window.location.href.includes('uat') ? 'btsg_sportsbetting' : 'btsg_sportbetting'
      })
    }
  } else if (obj.gameId && obj.platformCode) {
    openGame(isTry, obj, undefined)
  } else {
    SET_ROUTER_PUSH('/') // 如果gameUrl为空且路径为带游戏标识，则跳回首页
  }
}

// 根据入参路径，替换为当前访问路由+/islotgci islot游戏专用
function GetUrlRelativePath(url: any) {
  const arrUrl = url.split('://') // 解决厅房UAT下单/， 运营环境双//; eg：/v2//game；
  const start = arrUrl[1].indexOf('/')
  const relUrl = arrUrl[1].substring(start)
  return window.location.origin + '/islotgci' + relUrl
}

let openCount = 0 // 统计操作次数
export const openGame = (isTry: number, params: any, fixedCurrency?: string) => {
  const pathName = window.location.pathname.replace(/\/en|ja(\/)?/g, '')
  const t = i18next.getFixedT(null, null, 'common.game')
  // 防抖
  if (openCount > 0) return
  openCount++
  const time = setTimeout(() => {
    openCount = 0
    clearTimeout(time)
  }, 500)
  const {
    gameData,
    SET_GAME_DATA,
    platformCurrencys,
    setGameSearchDialogShow,
    setGameTipFun,
    setGameTipShow
  } = useGameStore.getState()
  const { openLogin } = useAuthStore.getState()
  const { isBigScreen, SET_GLOBAL_MSG, userIp } = useAppStore.getState()
  const { isLogin, moneyTypeSelectIndex, bonusCouponItem, currency, setGameCurrency, updateUserInfo } =
    useUserStore.getState()

  //是否转账钱包，转账钱包默认不支持多机台
  const isTransferwallet = ['JPT', 'JPT2', 'ISLOT', 'KAMIKAZE', 'IPACHIN'].indexOf(params.platformCode) > -1
  let openWindow: any
  if (!isLogin && isTry !== 1) {
    openLogin()
    return
  }
  if (gameData.length > 3) {
    SET_GLOBAL_MSG({ flag: true, type: 'warning', content: t('quitGameFirst') })
    return
  }

  if (isTry === 1) {
    if (params.platformCode !== 'FB' && params.platformCode !== 'K8SPORTS') {
      openWindow = window.open('/loading/index.html')
    }
    if (params.platformCode === 'K8SPORTS') {
      launch_anonymous_game({
        merchantCode: '1b0c73df8b',
        providerCode: 'K8SPORTS',
        providerCurrency: 'JPY',
        isDemo: 5,
        ipAddress: userIp
      }).then((res: any) => {
        if (res.success) {
          let gameUrl = res.data.entryHost
          if (params.racing) {
            const arr = gameUrl.split('?')
            gameUrl = `${arr[0]}racing${params.racing}?${arr[1]}`
          }
          SET_GAME_DATA([{ gameUrl }])
        }
      })
    } else {
      launch_game_demo({
        currency: 'USDT',
        depositUrl: `${window.location.origin}/account/deposit`,
        fiatCurrency: 'USD',
        gameId: params.gameId,
        providerCode: params.platformCode,
        providerCurrency: 'USD',
        siteUrl: window.location.origin,
        bonusId: ''
      }).then((res: any) => {
        if (res.success) {
          if (params.platformCode !== 'FB') {
            openWindow.location = res.data
          } else {
            SET_GAME_DATA([{ gameUrl: res.data }])
          }
        } else {
          openWindow?.close()
        }
      })
    }
    return
  }
  const supMultiMode = isTransferwallet || params.platformCode === 'PNG' ? 0 : 1

  if (!supMultiMode && (gameData.length > 0 || pathName === '/account/multimode')) {
    SET_GLOBAL_MSG({ flag: true, type: 'warning', content: t('notSuppot') })
    return
  }
  const sameGame = gameData.filter((el) => el.gameId === params.gameId)
  if (sameGame.length > 0) {
    SET_GLOBAL_MSG({ flag: true, type: 'warning', content: t('notSame') })
    return
  }
  // 只有开启优惠券且支持优惠券进入才使用优惠券
  const isBonusCoupon = moneyTypeSelectIndex === 1 && bonusCouponItem
  // 当前钱包币种
  let walletCurrency = isBonusCoupon ? bonusCouponItem.currency : currency.currency
  // 进游戏币种
  let gameCurrency = walletCurrency === 'JPY' ? 'JPY' : 'USD'
  // 汇率弹窗
  let needShowRate = false
  //如果游戏不支持 当前钱包币种线路 ，则对调法币线路（JPY或USD）并显示汇率弹窗，虚拟币默认转换USD，如果没有线路，也对调成JPY
  const gamePlatformCurrency = platformCurrencys.filter(
    (el: any) => el.providerCode === params.platformCode && el.providerCurrency === gameCurrency
  )
  if (gamePlatformCurrency.length === 0) {
    gameCurrency = walletCurrency === 'JPY' ? 'USD' : 'JPY'
    needShowRate = true
  } else if (walletCurrency !== 'JPY' && walletCurrency !== 'USD') {
    needShowRate = true
  }
  setGameCurrency(gameCurrency)

  if (fixedCurrency) {
    walletCurrency = fixedCurrency
    gameCurrency = fixedCurrency
    needShowRate = false
  }

  const filterParams = {
    currency: walletCurrency,
    depositUrl: `${window.location.origin}/account/deposit`,
    fiatCurrency: gameCurrency,
    gameId: params.gameId,
    providerCode: params.platformCode,
    providerCurrency: gameCurrency,
    siteUrl: window.location.origin,
    bonusId: isBonusCoupon ? bonusCouponItem.requestId : '' // 只有开启优惠券且支持优惠券进入才使用优惠券
  }

  if (!needShowRate) {
    goToGame()
  } else {
    setGameTipFun(goToGame)
    setGameTipShow(true)
  }

  function goToGame() {
    //iSlot JPT 转账钱包游戏进厅前注册账号
    if (isTransferwallet) {
      getTransferRegister(filterParams).then((res: any) => {
        if (res.success) {
          playGame()
        }
      })
    } else {
      playGame()
    }
  }

  function playGame() {
    transferAsyncTransferIn(filterParams).then((el: any) => {
      if (el.success) {
        launch_game(filterParams).then((res: any) => {
          if (res.success) {
            //如果是ｊｉｌｉ游戏，需要从域名转发一次再获取到真实域名
            if (params.platformCode.toUpperCase() === 'JILI') {
              fetch(res.data)
                .then((response) => response.json())
                .then((i) => gameHandle(i.Data))
            } else if (params.platformCode.toUpperCase() === 'JAPAROT') {
              openWindow.location.href = res.data
            } else if (params.platformCode.toUpperCase() === 'ISLOT') {
              window.location.href = GetUrlRelativePath(res.data)
            } else {
              gameHandle(res.data)
            }
          }
        })
      }
    })
  }

  // 第二步，游戏数据处理
  function gameHandle(gameUrl: string) {
    let name = 'slotGame'
    if (params.platformCode.toUpperCase() === 'AMIGO' && isBigScreen) {
      name = 'pachinko-game'
    }
    if (params.platformCode === "K8SPORTS" && params.racing) {
      const arr = gameUrl.split('?')
      gameUrl = `${arr[0]}racing${params.racing}?${arr[1]}`
    }
    const paths = `/${name}?platformCode=${params.platformCode}&gameId=${params.gameId}&supMultiMode=${supMultiMode}`
    pushPath(paths, gameUrl)
  }

  // 第三步，最终跳转&数据储存等
  function pushPath(path: any, url: string) {
    const gameObj = {
      gameUrl: url,
      ...params
    }
    const { SET_ROUTER_PUSH } = useAppStore.getState()
    if (!isTransferwallet && gameData.length > 0) {
      SET_GAME_DATA([...gameData, gameObj])
    } else {
      SET_GAME_DATA([gameObj])
    }
    setGameSearchDialogShow(false)
    track('EnterGame') // 埋点
    updateUserInfo() // 刷新金额
    if (
      (pathName !== '/account/multimode' && pathName !== '/fb' && pathName !=='/sports-bet' && pathName !=='/hippo-sports') ||
      (pathName === '/account/multimode' && !isBigScreen)
    ) {
      SET_ROUTER_PUSH(path)
    }
  }
  /******* 游戏进厅结束 ********/
}
