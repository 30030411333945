export const bets = {
  title: '決済ベット',
  titleno: '未確定ベット',
  id: 'ID検索',
  ticketID: 'ﾁｹｯﾄID',
  sport: 'ゲームの種類',
  sportlow: 'スポーツ',
  selection: '選択',
  selectionlow: '選択',
  status: '状況',
  statuslow: '状況',
  from: '開始日',
  to: '終了日',
  confirm: '送信',
  cancel: '削除',
  noData: '現在、決済ベットはございません。',
  voids: '無効',
  win: '勝ち',
  lose: '負け',
  refund: '払い戻し',
  halfWin: '半勝ち',
  halfLose: '半負け',
  single: 'シングル',
  combo: 'マルチプル',
  system: 'システム',
  title1: 'ﾁｹｯﾄID',
  title2: '日付',
  title3: 'ゲーム名',
  title4: 'オッズ',
  title5: 'ステーク',
  title6: 'ウォレットタイプ',
  title7: 'バランス',
  title8: 'タイプ',
  title9: '支払い',
  title10: '有効ベット金額',
  pend: 'タイ',
  reject: 'キャンセル',
  pa: '未決済',
  set: '決済ベット',
  bonus: 'ボーナス',
  canceled: 'キャンセル'
} as const
