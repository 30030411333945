'use client'
import { memo, useEffect, useMemo, useRef, useState } from 'react'
import { cn, getBonusName, getCurrencySymbol } from '@/lib/utils'
import styles from './index.module.scss'
import dayjs from 'dayjs'
// import { useParams } from 'next/navigation'
import { ExclamationCircleOutlined, SearchOutlined } from '@ant-design/icons'
import VerifyBaseDialog from '@/components/common/VerifyBaseDialog'
import { taskApply, applyBonus } from '@/api/activity'
import { getExchangeRate } from '@/api/wallet'
import { px2Scalepx } from '@/hooks/useRem'
import { popNumFormat } from '@/lib/filter'
import { useTranslation } from '@/lib/locales/client'
import { useAppStore } from '@/stores/app'
import { useShallow } from 'zustand/react/shallow'
import SearchSelect from '@/components/base/searchSelect'

const BonusReceive = (props: any) => {
  const { dialogData, receiveBonus, close } = props
  const { isBigScreen, commonConfig, language, SET_GLOBAL_MSG } = useAppStore(
    useShallow((state) => ({
      isBigScreen: state.isBigScreen,
      commonConfig: state.commonConfig,
      language: state.language,
      SET_GLOBAL_MSG: state.SET_GLOBAL_MSG
    }))
  )
  const { t } = useTranslation()
  const [currency, setCurrency] = useState(dialogData.fetchCurrency || 'USDT')
  const [rateList, setRateList] = useState([])
  useEffect(() => {
    queryExchangeRate()
  }, [])

  const virtualList = useMemo(() => {
    const arr =
      dialogData.isDepositBonus === 1
        ? dialogData.fetchCurrency?.split(';')
        : dialogData.fetchCurrencyScope?.split(';') || commonConfig.virtualCurrencies
    if (arr.indexOf('USD') === -1) {
      arr.push('USD')
    }
    if (arr.indexOf('JPY') === -1) {
      arr.push('JPY')
    }
    return arr
  }, [dialogData, commonConfig.virtualCurrencies])

  const bonusAmount = useMemo(() => {
    const tar = rateList.find((item) => item.sourceCurrency === currency)
    const amount = Number(dialogData.fetchAmount) || Number(dialogData.bonusAmount)
    return tar?.realRate && amount ? popNumFormat(amount / Number(tar.realRate), currency) : '----'
  }, [dialogData, rateList, currency])

  const virtualListOption = useMemo(() => {
    return dialogData.fetchCurrency
      ? [{ label: dialogData.fetchCurrency, value: dialogData.fetchCurrency }]
      : virtualList.map((item: any) => ({ label: item, value: item }))
  }, [dialogData, virtualList])

  const queryExchangeRate = () => {
    const params = {
      tradeType: '02', // 交易类型（01:存款 02:取款）
      sourceAmount: 1,
      sourceCurrency: virtualList.join(';'),
      targetCurrency: props.dialogData.fetchCurrency || 'USDT',
      isFindByCache: 0
    }
    getExchangeRate(params).then(({ success, data }: any) => {
      if (success) {
        setRateList(data.data || [])
      }
    })
  }

  const _receiveBonus = () => {
    let params = {},
      fn = null
    if (dialogData.prizeType === 6) {
      receiveBonus()
      return
    }
    if (dialogData.taskId) {
      params = {
        taskId: props.dialogData.taskId,
        applyCurrency: currency
      }
      fn = taskApply
    } else {
      params = {
        activityCode: 'bbp3xDV3PS',
        extraDataMap: {
          fetch_currency: currency,
          req_id: props.dialogData.requestId
        }
      }
      fn = applyBonus
    }
    fn &&
      fn(params).then(({ success }: any) => {
        success && SET_GLOBAL_MSG({ flag: true, type: 'success', content: t('bonus.locking') })
        receiveBonus()
      })
  }
  const getImg = (currency) => {
    try {
      return <img src={`/static/images/header/wallets/currencies/${currency}.png`} />
    } catch (error) {
      return ''
    }
  }

  return (
    <VerifyBaseDialog
      cusClass={styles['lee-bonusReceive-dialog']}
      visible={true}
      zIndex={5000}
      mask={false}
      width={px2Scalepx(343)}
      closeFn={close}
      type="bonus"
    >
      <p className="title">{dialogData.rewardName || getBonusName(dialogData, language)}</p>

      {dialogData.activityCode === 'bFZwyXBQna' && <p className="text-info">{t('promo.sign.popText')}</p>}
      {dialogData.prizeType !== 6 && dialogData.rewardType !== 'FREE_SPINS' && (
        <div className={cn('money-warp', dialogData.signFreeTimes || dialogData.awardSpin ? 'hasFree' : '')}>
          <div className="input-wrap">
            <p>{t('activity.recFriend.tableAmount')}</p>
            <SearchSelect
              value={currency}
              onChange={(value: string) => setCurrency(value)}
              disabled={dialogData.fetchCurrency}
              options={virtualListOption}
              loading={false}
              placeholder=""
              showSearch={false}
              prefixIcon={getImg(currency)}
            />
          </div>
          <div className="money">
            {getCurrencySymbol(currency)}
            {bonusAmount}
          </div>
        </div>
      )}
      {Boolean(dialogData.prizeType === 6 || dialogData.signFreeTimes || dialogData.awardSpin) && (
        <div className="free-warp">
          <span>{t('activity.ticket.freeSpins')}</span>
          <div className="money">
            {parseInt(dialogData.signFreeTimes || dialogData.awardSpin || dialogData.prizeAmount)}
            {t('promo.time')}
          </div>
        </div>
      )}

      <div className="btn" onClick={_receiveBonus}>
        {t('bonus.claimeActivity')}
      </div>

      {dialogData.expiredDate && (
        <div className="tips">
          <ExclamationCircleOutlined />
          <span className="sf">
            {
              new DOMParser().parseFromString(
                t('bonus.dg', { time: dayjs(dialogData.expiredDate).format('YYYY/MM/DD HH:mm:ss') }),
                'text/html'
              ).body.textContent
            }
          </span>
        </div>
      )}
    </VerifyBaseDialog>
  )
}
export default memo(BonusReceive)
