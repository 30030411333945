export const password = {
  text001: 'Passwords should contain at least 6 characters: letters, digits and special symbols.',
  text002:
    'Your password should not contain your username, name, email address or year of birth. You can increase the strength of your password by using a mixture of letters, digits and symbols. Please remember that passwords are case sensitive.',
  oldPwd: 'CURRENT PASSWORD',
  oldPwdPla: 'Current password',
  newPwd: 'NEW PASSWORD',
  newPwdPla: 'New password',
  newPwdConfirm: 'CONFIRM NEW PASSWORD',
  newPwdConfirmPla: 'Confirm new password',
  btn001: 'EDIT',
  btn002: 'UPDATE',
  btn003: 'CANCEL',
  errText001: 'Required field',
  errText002: 'Passwords do not match',
  modifySuccess: 'Password changed successfully, Please login again.',
  inputPwd: 'new password',
  inputComPwd: 'Confirm new password'
}
