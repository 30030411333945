'use client'
import { useMemo, useRef } from 'react'
import { useTranslation } from '@/lib/locales/client'
import { cn } from '@/lib/utils'
import { Flex } from 'antd'
import { useAuthStore } from '@/stores/authControl'
import { px2rem } from '@/hooks/useRem'
/* com */
import BaseDialog from '@/components/base/baseDialog'
import Login from '../_components/login'
import Register from '../_components/register'
import LeftModel from './leftModel'
import { useShallow } from 'zustand/react/shallow'

export default function AuthDesktopLayout() {
  const { t } = useTranslation()
  const { closeModal, logRegVisible, logRegType, openRegister, openLogin } = useAuthStore(
    useShallow((state) => ({
      closeModal: state.closeModal,
      logRegVisible: state.logRegVisible,
      logRegType: state.logRegType,
      openRegister: state.openRegister,
      openLogin: state.openLogin
    }))
  )
  const lineTimer = useRef(null)
  const handlClose = () => {
    closeModal()
    clearInterval(lineTimer.current)
    lineTimer.current = null
  }

  const dialogWidth = useMemo(() => {
    return px2rem(792, true)
  }, [])

  return (
    <>
      <BaseDialog visible={logRegVisible} width={dialogWidth}>
        <div className="flex select-none bg-[#fafafa] text-white" style={{ width: dialogWidth }}>
          <LeftModel />
          <div className={cn('relative mb-[16px] flex-1 px-[32px]', logRegType)}>
            {/* modal title */}
            <Flex align="flex-end" className="mb-[24px] h-[48px]">
              <p className="text-xl font-bold text-black/85">
                {logRegType === 'login' ? t('user.login') : t('user.authDialog.dReg')}
              </p>
            </Flex>
            {/* login staus toggle */}
            {logRegType === 'login' ? (
              <>
                <Login />
                <Flex justify="space-around" className="-mt-[12px]">
                  <p className="text-sm font-normal leading-[22px] text-black/65">
                    {t('user.authDialog.toRegist1')}
                    <span className="ml-1 cursor-pointer text-[#3F75FF]" onClick={() => openRegister(true)}>
                      {t('user.authDialog.toRegist2')}
                    </span>
                  </p>
                </Flex>
              </>
            ) : (
              <>
                <Register />
                <Flex justify="space-around" className="-mt-[12px]">
                  <p className="text-sm font-normal leading-[22px] text-black/65">
                    {t('user.authDialog.toRegist3')}
                    <span className="ml-1 cursor-pointer text-[#3F75FF]" onClick={() => openLogin()}>
                      {t('user.login')}
                    </span>
                  </p>
                </Flex>
              </>
            )}
          </div>
          {/* close modal */}
          <img
            src="/static/images/saveAccount/close-grey.png"
            onClick={handlClose}
            className="z-9 absolute right-[12px] top-[12px] w-[24px] cursor-pointer text-base"
          />
        </div>
      </BaseDialog>
    </>
  )
}
