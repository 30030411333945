import { useState, useEffect, memo, useRef } from 'react'
import { cn } from '@/lib/utils'
import { useTranslation } from '@/lib/locales/client'
import { useAppStore } from '@/stores/app'
import { useParams, useRouter } from 'next/navigation'
import useCountDown from '@/hooks/useCountDown'
import { Form, Button, Input } from 'antd'
import { validCodeByEmail } from '@/api/account'
import { forgetPasswordSendCodeByEmail } from '@/api/account'
import styles from '../_common_css/email.module.scss'
import { useShallow } from 'zustand/react/shallow'
// com
import ResetPwd from './resetPwd'
import Validtor from '@/hooks/validtor'
import SliderCaptcha from '@/components/common/sliderCaptcha'

// 与mobile模块接近相同，后面可优化
const EmailContainer = () => {
  const { t } = useTranslation()
  const router = useRouter()
  const { lng } = useParams()
  const { setForgetDialog, SET_GLOBAL_MSG } = useAppStore(
    useShallow((state) => ({
      setForgetDialog: state.setForgetDialog,
      SET_GLOBAL_MSG: state.SET_GLOBAL_MSG
    }))
  )
  const [form] = Form.useForm()
  const isFinishFill = Form.useWatch((values) => values.email && values.codeNum, form)
  const [step, setStep] = useState(1) // 1 forget, 2 reset
  const verifyCallback = useRef<{ captchaType?: number; captchaId?: string; captcha?: string }>({
    captchaType: 0,
    captchaId: '',
    captcha: ''
  })
  const [slideShowFlag, setSlideShowFlag] = useState(false)
  const { textRef, start, flag } = useCountDown(60, t('user.authDialog.send'), '')

  useEffect(() => {
    setStep(1)
  }, [])

  function contactHandle() {
    setForgetDialog(false)
    router.push(`/${lng}/contact`)
    window.Intercom('show')
  }

  function successHandle(bool: any) {
    if (bool) setStep(1)
    onReset()
  }

  async function captchaCallback(params) {
    verifyCallback.current = params
    startVerify()
  }

  function startVerify() {
    const checkEmail = form.getFieldError('email') // 邮件验证成功才会继续
    if (!flag || !form.getFieldValue('email') || checkEmail.length) return
    // 如果未验证滑块
    if (!verifyCallback.current.captchaType) {
      setSlideShowFlag(true)
      return
    }
    // 发送email
    forgetPasswordSendCodeByEmail({
      ...verifyCallback.current,
      use: 3,
      purpose: 5,
      email: form.getFieldValue('email'),
      loginType: 2
    })
      .then((res: any) => {
        if (res.success) {
          SET_GLOBAL_MSG({ flag: true, type: 'success', content: t('user.authDialog.sendSuc') })
          verifyCallback.current = { ...verifyCallback.current, captchaId: res.data.captchaId }
          start() // counting down
        } else {
          verifyCallback.current = {}
        }
      })
      .catch(() => {
        verifyCallback.current = {}
      })
  }

  // submit
  const onFinish = async (values: any) => {
    const params = {
      captchaId: verifyCallback.current.captchaId,
      code: values.codeNum,
      loginName: values.email
    }
    validCodeByEmail(params).then((res: any) => {
      if (res.success) {
        setStep(2)
      }
    })
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }

  const onReset = () => {
    form.resetFields()
  }

  return (
    <>
      <div className={styles['email-forget']}>
        <ul className="process-verify">
          <li className={cn('active', step > 1 && 'fish-icon')}>
            <i />
            <span>{t('common.account.accountVerify')}</span>
          </li>
          <li className={cn(step === 2 && 'active')}>
            <i>{step === 2 ? '' : 2}</i>
            <span>{t('user.resetPwd.title')}</span>
          </li>
        </ul>
        {/* step 1 */}
        {step === 1 ? (
          <Form
            form={form}
            validateTrigger="onBlur"
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              name="email"
              required={true}
              validateTrigger={['onChange']}
              className="mb-[24px]"
              rules={[
                {
                  validator: async (_rule: any, names: string) => {
                    if (!names) {
                      return Promise.reject(t('common.validtor.emailEpty'))
                    }
                    if (!Validtor.email(names)) {
                      return Promise.reject(t('common.validtor.emailError'))
                    }
                    return Promise.resolve()
                  }
                }
              ]}
            >
              <Input
                placeholder={t('user.authDialog.EmailPlace')}
                allowClear={{
                  clearIcon: (
                    <img
                      src="/static/images/common/input_clear_x.svg"
                      style={{ marginLeft: '10px', width: '18px' }}
                    />
                  )
                }}
                onChange={(e) =>
                  form.setFieldsValue({ email: e.target.value.replace(/[\u4E00-\u9FA5]/g, '') })
                }
              />
            </Form.Item>
            {/* didn't receive the code */}
            <>
              <Form.Item
                name="codeNum"
                required={true}
                validateTrigger={['onChange']}
                rules={[
                  {
                    validator: async (_rule: any, names: string) => {
                      if (!names || names.length > 6) {
                        return Promise.reject(new Error(t('common.validtor.codeError')))
                      }
                      return Promise.resolve()
                    }
                  }
                ]}
              >
                <Input
                  placeholder={t('user.authDialog.authCodePlace')}
                  onChange={(e) => form.setFieldsValue({ codeNum: e.target.value.replace(/\D/gi, '') })}
                  suffix={
                    <div
                      className="mr-[4px] cursor-pointer text-sm font-semibold text-[#3F75FF]"
                      onClick={(evt) => {
                        evt.stopPropagation()
                        startVerify()
                      }}
                    >
                      {textRef}
                    </div>
                  }
                />
              </Form.Item>
              <Form.Item name="sendTips" noStyle>
                {/* 未收到验证码-联系客服 */}
                <div
                  className="relative h-[16px]"
                  onClick={() => typeof window !== undefined && window?.Intercom('show')}
                >
                  <p
                    className={cn(
                      'absolute -top-[20px] right-0 mb-[6px] cursor-pointer text-right text-xs font-normal text-black/65'
                    )}
                  >
                    {t('user.authDialog.sendTips')}
                  </p>
                </div>
                {/* )} */}
              </Form.Item>
            </>
            {/* submit */}
            <Form.Item name="loginSumbit">
              <Button
                block
                className={cn(
                  !isFinishFill && 'disabled',
                  'rounded-0 !h-[46px] w-full cursor-pointer self-stretch border-none px-[40px] py-[10px] !text-lg font-bold text-white qc-flex-center'
                )}
                disabled={!isFinishFill}
                style={{
                  background: '#FC005B',
                  boxShadow: ' 0px 2px 0px 0px rgba(0, 0, 0, 0.15)'
                }}
                type="primary"
                htmlType="submit"
              >
                {t('bets.confirm')}
              </Button>
            </Form.Item>

            <Form.Item name="tips">
              <p className="text-center text-[#000000a5]">
                {t('user.authDialog.binded')}
                <a onClick={contactHandle}>{t('user.authDialog.contact')}</a>
              </p>
            </Form.Item>
          </Form>
        ) : (
          // step 2
          <ResetPwd
            captchaId={verifyCallback.current.captchaId}
            email={form.getFieldValue('email')}
            type={'email'}
            success={successHandle}
          />
        )}

        {/* 滑动验证模块 */}
        {slideShowFlag && (
          <SliderCaptcha
            use={3}
            txt={t('user.resetPwd.title')}
            loginName={'qc' + form.getFieldValue('userName')}
            callback={captchaCallback}
            close={() => setSlideShowFlag(false)}
          />
        )}
      </div>
    </>
  )
}

export default memo(EmailContainer)
