// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useUserStore } from '@/stores/user'
import { useAppStore } from '@/stores/app'
import { getUUID } from '@/lib/utils'
import { trackFristDeposit, deposiTransInGame, trackDeposit } from '@/hooks/useTrack'

class Ws {
  constructor(url?: string) {
    const socketProtocol = window.location.protocol === 'https:' ? 'wss://' : 'ws://'
    let socketUrl
    if (process.env.NODE_ENV === 'development') {
      socketUrl = 'ws://public-kratos-gateway-v1.k8s-fat.com/webSocket'
    } else if (window.location.href.indexOf('fat.com') > -1) {
      socketUrl = 'ws://public-kratos-gateway-v1.k8s-fat.com/webSocket'
    } else {
      socketUrl = socketProtocol + window.location.hostname + '/_api_/webSocket'
    }
    this.url = url || socketUrl // 链接url
    this.configTypes = this.getConfigTypes() // 获取需要处理的types以及处理其处理函数
    this.reconnectTimer = '' // 重连计时器
    this.heartInterval = 0
    this.subscribeQuery = [] // 储存订阅消息的数组
  }
  // 初始化连接ws
  init() {
    try {
      // 判断当前浏览器是否支持WebSocket
      if ('WebSocket' in window) {
        this.start()
        // 监听窗口关闭事件，当窗口关闭时，主动去关闭websocket连接，防止连接还没断开就关闭窗口，server端会抛异常。
        document.addEventListener(
          'beforeunload',
          () => {
            this.closeWebSocket()
          },
          false
        )
      } else {
        console.error('当前浏览器 Not support websocket')
      }
    } catch (error) {
      console.log(error)
    }
  }
  // 开始连接websocket
  start() {
    this.websocket = new WebSocket(this.url)

    // 连接发生错误的回调方法
    this.websocket.onerror = () => {
      console.error('WebSocket连接发生错误')
      clearInterval(this.heartInterval)
      this.reconnect()
    }

    // 连接关闭后的回调方法
    this.websocket.onclose = () => {
      clearInterval(this.heartInterval)
      this.reconnect() // 登录后，监听到连接关闭则自动重连
    }

    // 连接成功建立的回调方法
    this.websocket.onopen = () => {
      // console.log('WebSocket连接成功')
      this.send() // 发送指令初始化
      this.onmessage()
      // 开启心跳检测，以免一段时间后收不到消息自动失联
      this.heartInterval = setInterval(() => {
        this.keepalive()
      }, 10000)
    }
  }
  // 断线重连
  reconnect() {
    this.reconnectTime = 5 * 1000 // 断线重连间隔
    // console.log(this.reconnectTime + 'ms后断线重连')
    this.reconnectTimer && clearTimeout(this.reconnectTimer)
    this.reconnectTimer = setTimeout(() => {
      this.closeWebSocket() // 关闭原来的
      this.start()
      // console.log('断线重连中')
    }, this.reconnectTime)
  }
  // 接收到消息的回调方法
  onmessage() {
    this.websocket.onmessage = (event) => {
      try {
        const { payload, type } = JSON.parse(event.data)
        if (type === 'connection_ack') {
          // 初始化成功后再订阅消息
          this.subscribe() // 订阅所有消息
        } else if (type === 'next') {
          // 订阅后，如果返回的数据里面没有payload则五秒后进行断线重连，如果有payload则获取payload.data进行处理
          if (payload && payload.data) {
            const { content, type } = payload.data
            const needDeal = this.configTypes.find((el) => el.type === type)
            needDeal && needDeal.dealFun.call(this, content, payload.data) // 如果需要处理
          }
        }
      } catch (err) {
        // console.log(err)
      }
    }
  }
  // 发送消息
  send(params = {}) {
    const commonConfig = useAppStore.getState().commonConfig
    const {
      id = getUUID(),
      type = 'connection_init',
      payload = {
        merchantCode: commonConfig.merchantCode,
        loginName: useUserStore.getState().userInfo?.loginName || '',
        lang: useAppStore.getState().language,
        token: useUserStore.getState()?.webToken?.jti || ''
      }
    } = params

    const data = { id, type, payload }
    if (this.websocket && this.websocket.readyState === 1) {
      this.websocket.send(JSON.stringify(data))
    }
    return data
  }
  // 订阅消息=>传入query(不传，订阅所有消息)，返回订阅的id（注：禁止同一个消息多次订阅，会造成多次推送）
  subscribe(
    query = [
      'deposit',
      'withdraw',
      'wallet',
      'currencyRate',
      // 'sysMessage',
      'order',
      'orderAll',
      'orderRanking',
      'coupon',
      'activitySocketMqMsg',
      'activityAllUserSocketMqMsg',
      'publicToastPush'
    ]
  ) {
    // deposit-存款 withdraw-取款 wallet-额度变更  currencyRate-汇率 sysMessage-站内信 order-我的投注 orderAll-全部投注
    //orderRanking-大客榜单 coupon-优惠券 publicToastPush-公共toast推送 activitySocketMqMsg-会员当前排行榜信息 activityAllUserSocketMqMsg-所有会员当前排行榜信息

    const data = this.send({ type: 'subscribe', payload: { query } })
    this.subscribeQuery.push(data)
    return data
  }
  // 关闭订阅消息=>传入id(不传，关闭所有已订阅的消息)，关闭对应的订阅消息
  closeSubscribe(id) {
    if (id) {
      const haveId = this.subscribeQuery.some((el) => el.id === id)
      if (haveId) {
        this.subscribeQuery.splice(
          this.subscribeQuery.findIndex((el) => el.id === id),
          1
        )
        return this.send({ id, type: 'complete' })
      } else {
        throw new Error('关闭订阅消息失败，请传入已订阅的正确id')
      }
    } else {
      this.subscribeQuery.forEach((el) => this.closeSubscribe(el.id))
    }
  }
  // 关闭WebSocket连接
  closeWebSocket() {
    this.websocket && this.websocket.close()
  }
  // 获取需要处理的types以及处理其处理函数
  // deposit-存款 withdraw-取款  currencyRate-汇率 sysMessage-站内信 order-我的投注 orderAll-全部投注 orderRanking-大客榜单
  getConfigTypes() {
    return [
      // 前端需要针对这三个事件做特殊处理
      // {
      //   type: 'sysMessage',
      //   text: '站内信通知',
      //   dealFun(content, data) {
      //     store.commit('user/SET_UNREAD_MSG_COUNT', ++store.state.user.unreadMsgCount)
      //   }
      // },
      {
        type: 'deposit',
        text: '存款通知',
        dealFun(content, data) {
          setTimeout(() => {
            deposiTransInGame(content.currency)
          }, 8000)
          setTimeout(() => {
            useUserStore.getState().updateUserInfo()
            useUserStore.getState().getBonusTaskList()
          }, 1000)
          trackFristDeposit()
          trackDeposit()
          // console.log(content, data)
          console.log('存款通知存款通知存款通知')
        }
      },
      {
        type: 'withdraw',
        text: '取款通知',
        dealFun(content, data) {
          // console.log(content, data)
        }
      },
      {
        type: 'wallet',
        text: '额度变更',
        dealFun(content, data) {
          console.log('额度变更额度变更额度变更')
        }
      },
      {
        type: 'currencyRate',
        text: '汇率更新通知',
        dealFun(content, data) {
          // console.log(content, data)
          useUserStore.getState().SET_CURRENCY_RATE(content, 'ws')
        }
      },
      {
        type: 'order',
        text: '我的投注',
        dealFun(content, data) {
          // console.log(content, data)
          console.log('我的投注我的投注我的投注')
        }
      },
      {
        type: 'orderAll',
        text: '全部投注',
        dealFun(content, data) {
          // console.log(content, data)
        }
      },
      {
        type: 'orderRanking',
        text: '大客榜单',
        dealFun(content, data) {
          // console.log(content, data)
        }
      },
      {
        type: 'coupon',
        text: '优惠券',
        dealFun(content, data) {
          // console.log(content, data)
          console.log('优惠券优惠券优惠券优惠券优惠券')
        }
      },
      {
        type: 'publicToastPush',
        text: '公共toast推送',
        dealFun(content, data) {
          // console.log(content, data)
        }
      },
      {
        type: 'activityAllUserSocketMqMsg',
        text: '活动推送',
        dealFun(content, data) {
          // console.log(content, data)
        }
      },
      {
        type: 'activitySocketMqMsg',
        text: '活动推送对应客户',
        dealFun(content, data) {
          // console.log(content, data)
        }
      }
    ]
  }
  // 心跳包检测
  keepalive() {
    this.send({ type: '~heart-beats~' })
  }
}

export default Ws
