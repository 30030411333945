'use client'
import { useState, useEffect, FC } from 'react'
import { useAppStore } from '@/stores/app'
import { useUserStore } from '@/stores/user'
import { useWalletStore } from '@/stores/wallet'
import { useTranslation } from '@/lib/locales/client'
import { usePathname, useParams } from 'next/navigation'
import { queryPaymentList } from '@/api/wallet'
import { useShallow } from 'zustand/react/shallow'
import { defaultlChannel } from '../defaultlChannel'
import { Skeleton } from 'antd'
import { cn } from '@/lib/utils'
import DepositData from './depositData'
import Link from 'next/link'

type IProps = {
  isFastDeposit?: boolean
  buyVirtualCurrency?: string
  chooseType: (val: any) => void
}

const WalletTab: FC<IProps> = (props) => {
  const { lng } = useParams()
  const { t } = useTranslation()
  const { isBigScreen, language, changeFastDialog } = useAppStore(
    useShallow((state) => ({
      isBigScreen: state.isBigScreen,
      language: state.language,
      changeFastDialog: state.changeFastDialog
    }))
  )
  const { bankToBankInfo } = useWalletStore(
    useShallow((state) => ({
      bankToBankInfo: state.bankToBankInfo
    }))
  )
  const { SET_SHOW_KYC_DIALOG } = useUserStore.getState()
  const isCompleteDepositVerify = useUserStore((state) => state.isCompleteDepositVerify)
  const pathName = usePathname()
  const rawPath = pathName.replace(/^\/(en|ja)($|\/)/g, '/')

  const [spinning, setSpinning] = useState(true)
  const [currentType, setCurrentType] = useState('')
  const [compTab, setcompTab] = useState([])
  const [showBackBank, setShowBackBank] = useState(false)

  const walletDefaultlChannel = JSON.parse(JSON.stringify(defaultlChannel()))

  const chooseDepositType = (item) => {
    if (item.identityCertifiedFlag === 1 && !isCompleteDepositVerify) {
      SET_SHOW_KYC_DIALOG('L2')
      return
    }
    getDetailChannel(item)
  }

  const getDetailChannel = (val) => {
    setCurrentType(val.channelCode + val.payType)
    props.chooseType(Object.assign({}, val, { component: val.channelType }))
  }

  const initDeposit = async () => {
    queryPaymentList({}).then((res) => {
      if (res.success) {
        const depositInfoList = { virtual: {}, creditP93: {} }
        const depositAllData = []
        res.data.forEach((item, i) => {
          //如果是法币
          if (item.type === 1) {
            let list = item.payTypeList
            list.forEach((el) => {
              el.payType = el.payType.toString()
              if (el.payType === '129' && el.extSystemType === 'P93') return
              if (!depositInfoList[el.payType]) {
                depositInfoList[el.payType] = {}
              }
              if (
                props.isFastDeposit &&
                el.payType === '45' &&
                bankToBankInfo?.willExpiraTime > new Date().getTime() &&
                rawPath === '/account/deposit'
              ) {
                setShowBackBank(true)
              } else {
                depositInfoList[el.payType][item.currency] = el
              }
              //P93信用卡直连限额取并集，如果返回一个类型则展示改渠道
              if (['134', '135', '136', '137'].indexOf(el.payType) > -1) {
                const p93 = depositInfoList['creditP93'][i] || {}
                if (p93.maxAmount > el.maxAmount) {
                  el.maxAmount = p93.maxAmount
                }
                if (p93.minAmount < el.minAmount) {
                  el.maxAmount = p93.maxAmount
                }
                depositInfoList['creditP93'][item.currency] = el
              }
            })
          } else {
            depositInfoList['virtual'][item.currency] = res.data[i]
          }
        })
        walletDefaultlChannel.forEach((el) => {
          if (
            depositInfoList[el.payType] &&
            JSON.stringify(depositInfoList[el.payType]) !== '{}' &&
            el.channelType !== 'changeVirtual'
          ) {
            el.channelInfo = depositInfoList[el.payType]
            depositAllData.push(el)
          }
        })
        setSpinning(false)
        setcompTab(depositAllData.sort((a, b) => a.sort - b.sort))
      }
    })
  }

  useEffect(() => {
    if (!compTab.length) return
    const bankIndex = compTab.findIndex((item) => item.payType === '45')
    if (
      bankIndex > -1 &&
      bankToBankInfo?.willExpiraTime > new Date().getTime() &&
      rawPath === '/account/deposit'
    ) {
      chooseDepositType(compTab[bankIndex])
    } else if ((isBigScreen && compTab.length) || (props.isFastDeposit && props.buyVirtualCurrency)) {
      chooseDepositType(compTab[0])
    }
  }, [compTab])

  useEffect(() => {
    initDeposit()
  }, [])

  const imgUrl = (language === 'ja-JP' ? 'jp' : 'en') + '_' + (isBigScreen ? 'pc' : 'h5')

  return (
    <div className={cn('deposit-list', props.isFastDeposit ? 'fast' : '')}>
      <Skeleton loading={spinning} title={false} paragraph={{ rows: 4 }} active>
        {showBackBank && (
          <div className="back-bank">
            <p>{t('deposit.bankTransfer')}</p>
            <a onClick={() => changeFastDialog(true)}>{t('deposit.selecting')}</a>
          </div>
        )}

        {compTab.map((item) => (
          <div
            key={item.channelCode + item.payType}
            className={cn(
              'list-item',
              (item.channelCode === 'vegaWallet' || item.channelCode === 'coinPayment') && 'depositTeach',
              item.channelCode + item.payType === currentType && 'active'
            )}
            onClick={() => {
              chooseDepositType(item)
            }}
          >
            {item.channelMarkLogoPath && <img className="markLogo" src={item.channelMarkLogoPath} />}
            {item.channelLogoPath && <img className="icon-box" src={item.channelLogoPath} />}
            <DepositData item={item} />
            {item.channelCode === 'vegaWallet' && (
              <Link href={`/${lng}/vegaWalletTutorial`}>
                <img
                  className="walletTutorial"
                  src={`/static/images/vegaWalletTutorial/vega_${imgUrl}.png`}
                />
              </Link>
            )}
            {item.channelCode === 'coinPayment' && (
              <Link href={`/${lng}/landpage-virtual`}>
                <img
                  className="walletTutorial"
                  src={`/static/images/vegaWalletTutorial/coin_${imgUrl}.png`}
                />
              </Link>
            )}
          </div>
        ))}
      </Skeleton>
    </div>
  )
}
export default WalletTab
