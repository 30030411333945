'use client'
import { useAppStore } from '@/stores/app'
import { useRouter, useParams } from 'next/navigation'
import styles from '../index.module.scss'
import { numberToKUnit } from '@/lib/filter'
import { useEffect, useState } from 'react'
import { rewardStatistics } from '@/api/account'
import RewardCenter from '../../_components/rewardCenter'
import { useTranslation } from '@/lib/locales/client'
import { useUserStore } from '@/stores/user'
import { useAuthStore } from '@/stores/authControl'
import { useUpdateEffect } from 'ahooks'

export default function RewardPage() {
  const router = useRouter()
  const { lng } = useParams()
  const isBigScreen = useAppStore((state) => state.isBigScreen)
  const setIsShowNotification = useAppStore((state) => state.setIsShowNotification)
  const isLogin = useUserStore((state) => state.isLogin)
  const openLogin = useAuthStore((state) => state.openLogin)
  const { t } = useTranslation()
  const [rewardData, setRewardData] = useState({} as any)
  const goHis = () => {
    if (isBigScreen) {
      router.push(`/${lng}/account/bonus?type=3`)
      setIsShowNotification(false)
    } else {
      router.push(`/${lng}/account/bonusHistory`)
    }
  }
  const getRewardStatistics = () => {
    rewardStatistics({}).then((res: any) => {
      if (res.success) {
        setRewardData(res.data)
      }
    })
  }

  useUpdateEffect(() => {
    if (!isLogin) {
      openLogin()
    }
  }, [isLogin])

  useEffect(() => {
    getRewardStatistics()
  }, [])

  return (
    <div className={styles['reward-warp']}>
      <div className="reward-total">
        <div className="top" onClick={goHis}>
          <div>
            <h5>{t('promo.bonusClaimedTotal')}</h5>
            <p>$ {numberToKUnit(rewardData.claimedBonusTotal || 0, 2)}</p>
          </div>
          <img src="/static/images/promo/right.png" />
        </div>
        <div className="content">
          <div>
            <h5>{t('promo.bonusTotal')}</h5>
            <p>$ {numberToKUnit(rewardData.advanceBonusTotal || 0, 2)}</p>
          </div>
          <div>
            <h5>{t('promo.questTotal')}</h5>
            <p>$ {numberToKUnit(rewardData.questBonusTotal || 0, 2)}</p>
          </div>
          <div>
            <h5>{t('promo.extraTotal')}</h5>
            <p>$ {numberToKUnit(rewardData.extraBonusTotal || 0, 2)}</p>
          </div>
          <div>
            <h5>{t('promo.spinTotal')}</h5>
            <p>{rewardData.freeSpainTotal || 0}</p>
          </div>
        </div>
      </div>
      <RewardCenter />
    </div>
  )
}
