import { useRouter } from 'next/navigation'
import { useUserStore } from '@/stores/user'
import { useAuthStore } from '@/stores/authControl'
import { useParams } from 'next/navigation'
import { openGame } from '@/app/[lng]/(game)/_components/startGame'

export function useRedirect() {
  const router = useRouter()
  const { lng } = useParams()
  const isLogin = useUserStore((state) => state.isLogin)
  const openLogin = useAuthStore((state) => state.openLogin)

  // 通用权限控制跳转函数
  const redirect = (target, status = '') => {
    if (!target) return
    if (target === '/language') {
      router.push(`/${lng}`)
      // changeLanguage('ja-JP')
      return
    }
    if (target === '/static/responsibleGaming') {
      router.push(`/${lng}/responsible/`)
      return
    }
    if (target.indexOf('/islotentrance') > -1) {
      if (!isLogin) {
        openLogin()
      } else {
        openGame(0, {
          platformCode: 'ISLOT',
          gameId: 'islot_lobby'
        })
      }
      return
    }

    /* 去空格 */
    const _url = target.replace(/(^\s*)/g, '')
    if (_url.startsWith('http') || status === 'new') {
      window.open(_url)
      // window.open(`/${lng}${_url}`)
      return
    } else {
      router.push(`/${lng}${_url}`)
    }
  }

  return redirect
}
