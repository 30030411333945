'use client'
import { FC, useEffect, Fragment, memo } from 'react'
import { useUpdateEffect } from 'ahooks'
import { Modal } from 'antd'
import { cn } from '@/lib/utils'
import styles from './index.module.scss'

type IProps = {
  width?: string | number
  height?: string | number
  visible?: boolean
  cusClass?: string
  isFullScreen?: boolean
  needCenter?: boolean
  zIndex?: number
  type?: string
  closeFn?: (flag?: boolean) => void
  // handlClose: (type?: number) => void
  updateFullScreenClass?: (val?: string) => void
  maskClosable?: boolean // 是否遮罩可以关闭，默认true
  getContainer?: () => HTMLElement // 附加到你喜欢的位置 下.方
  [key: string]: any
}

const VerifyBaseDialog: FC<IProps> = ({ children, ...props }) => {
  const {
    type = 'common',
    needCenter = true,
    zIndex = 2000,
    isFullScreen = false,
    maskClosable = true
  } = props

  const imgTypeList = [
    { type: 'common', src: '/static/images/myAccount/verification/model_middle_icon.png', alt: '' },
    { type: 'alert', src: '/static/images/myAccount/verification/model_middle_icon_alert.png', alt: '' },
    { type: 'info', src: '/static/images/myAccount/verification/model_middle_icon_info.png', alt: '' },
    { type: 'checkin', src: '/static/images/myAccount/verification/model_middle_icon_checkin.png', alt: '' },
    { type: 'bonus', src: '/static/images/myAccount/verification/model_middle_icon_bonus.png', alt: '' },
    {
      type: 'registerSuccess',
      src: '/static/images/myAccount/verification/model_middle_register_success.png',
      alt: ''
    }
  ]

  useUpdateEffect(() => {
    if (!props.visible) {
      props.closeFn(false)
    }
  }, [props.visible])

  return (
    <Modal
      className={cn(styles['base-dialog'], props.cusClass)}
      closable={false}
      width={props.width}
      footer={null}
      height={props.height}
      open={props.visible}
      styles={{
        body: {
          padding: 0
        }
      }}
      forceRender
      onCancel={() => props.closeFn()}
      maskClosable={maskClosable}
      wrapClassName={isFullScreen ? styles['full-modal'] : styles['not-full']}
      centered={needCenter}
      getContainer={props.getContainer}
      zIndex={zIndex}
    >
      <div className={styles['verify_model']}>
        <div className="top_model">
          <img
            onClick={() => props.closeFn()}
            className="delete_x"
            src="/static/images/myAccount/verification/delete_x.png"
            alt=""
          />
          <img
            className="left_arrow"
            src="/static/images/myAccount/verification/model_left_arrow.png"
            alt=""
          />
          <div className="middle_div">
            {imgTypeList.map((item) => (
              <Fragment key={item.type}>
                {item.type === type && <img src={item.src} alt={item.alt} />}
              </Fragment>
            ))}
          </div>
        </div>
        <div className="content_main">
          {/* <div className="content_main relative top-[-50px] w-full rounded-bl-[5px] rounded-tr-[50px] bg-white"> */}
          <>{children}</>
        </div>
      </div>
    </Modal>
  )
}

export default memo(VerifyBaseDialog)
