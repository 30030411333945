'use client'
import { useState, useEffect, useMemo, useRef, useLayoutEffect, memo } from 'react'
import { useSetState } from 'ahooks'
import { cn } from '@/lib/utils'
import { useTranslation } from '@/lib/locales/client'
import { useAppStore } from '@/stores/app'
import { useUserStore } from '@/stores/user'
import { useAuthStore } from '@/stores/authControl'
import { EyeInvisibleOutlined, EyeTwoTone, InfoCircleTwoTone } from '@ant-design/icons'
import { Form, Button, Input } from 'antd'
import Validtor from '@/hooks/validtor'
import html2canvas from 'html2canvas'
import { formatUpdateApi } from '@/api/account'
import { getUserInfo, logApi, newRegEditCallback } from '@/api/auth'
import styles from './_common_css/registerSuccess.module.scss'
import { useShallow } from 'zustand/react/shallow'
import { track } from '@/hooks/useTrack'
/* com */
import VerifyBaseDialog from '@/components/common/VerifyBaseDialog'
// import BaseDialog from '@/components/base/baseDialog'
// import ACInput from '@/components/base/acInput'

const RegisterSuccess = () => {
  const { t } = useTranslation()
  const isBigScreen = useAppStore((state) => state.isBigScreen)
  const { thirdRmCode, webToken, setWebToken, updateUserInfo } = useUserStore(
    useShallow((state) => ({
      thirdRmCode: state.thirdRmCode,
      webToken: state.webToken,
      setWebToken: state.setWebToken,
      updateUserInfo: state.updateUserInfo
    }))
  )
  const { regSucInfo, SET_REG_SUC_INFO } = useAuthStore(
    useShallow((state) => ({
      regSucInfo: state.regSucInfo,
      SET_REG_SUC_INFO: state.SET_REG_SUC_INFO
    }))
  )
  const downLoadImg = useRef(null)
  const [form] = Form.useForm()
  const [userInfo, setUserInfo] = useSetState({
    userNamePwdStatus: 0,
    loginName: '',
    id: ''
  })
  const [isShowSave, setIsShowSave] = useState(false)
  const [showDownLoad, ShowDownLoad] = useState(false)
  const [isFromThird, setIsFromThird] = useState(false) // 来自三方注册的才提示用户保存图片
  const [recOnlyRead, setRecOnlyRead] = useState(false)

  const btnDisable = useMemo(() => {
    const { userName, password } = form.getFieldsValue(['userName', 'password'])
    let _pw = regSucInfo.showPwEdit ? Validtor.password(password) : true
    return Validtor.username(userName) && _pw
  }, [])
  /* 提取代理信息 */
  const agentCode = useMemo(() => localStorage.getItem('agent-info-local') || thirdRmCode || '', [])

  useEffect(() => {
    getUserInfoFn()
    // 原项目 放着 待处理 原项目用nextTick useLayoutEffect(() => {  /* 提取　QC　前缀 */
  }, [])

  // --- 待处理 原项目用nextTick
  useLayoutEffect(() => {
    /* 提取　QC　前缀 */
    let { loginName = '', originalPwd, showPwEdit } = regSucInfo || {}
    // setFormState({ username: loginName?.slice(2) })
    form.setFieldsValue({ userName: loginName?.slice(2) })
    if (showPwEdit) {
      // setFormState({ password: originalPwd })
      form.setFieldsValue({ password: originalPwd })
      setIsFromThird(true)
    }
  })

  /* 提取代理信息 */
  useLayoutEffect(() => {
    if (agentCode) {
      // setFormState({ rmCode: agentCode })
      form.setFieldsValue({ rmCode: agentCode })
      setRecOnlyRead(true)
    }
  }, [agentCode])

  // --- 待处理 原项目用nextTick 放在downIMG内
  useLayoutEffect(() => {
    getDom()
  }, [])

  // 注意，downLoadImg 模块没引用到的问题，先哥置
  async function getDom() {
    if (!downLoadImg.current) return
    let _dom = downLoadImg.current
    await html2canvas(_dom, {
      logging: false,
      allowTaint: true,
      scale: window.devicePixelRatio,
      scrollY: 0,
      scrollX: 0,
      useCORS: true,
      backgroundColor: '#ffffff'
    }).then(function (canvas) {
      let imgUrl = canvas.toDataURL('responsible/png')
      let tempLink = document.createElement('a') // 创建一个a标签
      tempLink.style.display = 'none'
      tempLink.href = imgUrl
      tempLink.setAttribute('download', 'QC') // 给a标签添加下载属性
      if (typeof tempLink.download === 'undefined') {
        tempLink.setAttribute('target', '_blank')
      }
      document.body.appendChild(tempLink) // 将a标签添加到body当中
      tempLink.click() // 启动下载
      document.body.removeChild(tempLink) // 下载完毕删除a标签
      window.URL.revokeObjectURL(imgUrl)
    })
    /* reset */
    ShowDownLoad(false)
    setIsShowSave(false)
    SET_REG_SUC_INFO({ flag: false })
  }

  function getUserInfoFn() {
    getUserInfo({ loginName: webToken.loginName, searchFreeSpinWallet: true }).then((res) => {
      setUserInfo(res.data)
    })
  }

  function onClose() {
    SET_REG_SUC_INFO({ flag: false })
    setIsShowSave(false)
  }

  function handlClose(type = 1) {
    // regSucInfo.flag = false
    SET_REG_SUC_INFO({ flag: false })
    onClose()
    localStorage.removeItem('userStore')
    setWebToken('')
    // if(isShowSave === 2) {

    // }
  }

  /* down-img */
  function downIMG() {
    ShowDownLoad(true)
  }

  function removeAllSpace(str) {
    if (str) {
      return str.replace(/\s+/g, '')
    }
    return ''
  }

  // 开始手机登录 注册新用户操作
  const onFinish = async (values: any) => {
    // console.log('Received values of form: ', values)
    if (!btnDisable) return
    // formRef.current.validate().then(() => {
    const _name = removeAllSpace(form.getFieldValue('userName'))
    const _pwd = removeAllSpace(form.getFieldValue('password'))
    // 提交
    return formatUpdateApi({
      // const res: any = await formatUpdateApi({
      modifyType: Number(userInfo.userNamePwdStatus === 0 ? 5 : 6),
      lastUpdatedBy: userInfo.loginName.toLocaleLowerCase() || '',
      modifyLoginName: 'qc' + _name.toLocaleLowerCase(),
      newPassword: _pwd,
      id: userInfo.id
    }).then((res) => {
      if (res && res.success) {
        let params = {
          grantType: 'refresh_token',
          refresh_token: webToken.refresh_token
        }
        logApi(params).then((res) => {
          setWebToken(res.data)
          updateUserInfo(res.data.loginName, 1)
          // regSucInfo.flag = false
          SET_REG_SUC_INFO({ flag: false })
          track('RegisterSuccess', { loginName: res.data.loginName })
        })
      }
    })
    // })
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }

  return (
    <>
      <VerifyBaseDialog
        visible
        zIndex={2000}
        width={343}
        closeFn={() => handlClose(2)}
        type={'registerSuccess'}
        isFullScreen={false}
        handlClose={() => handlClose()}
      >
        {!isShowSave ? (
          <div className={styles['reg-success-modal']}>
            {/* close */}
            <p className={'reg-suc-title'}>{t('user.regSuc.newWelcome')}</p>
            {/* form */}
            <Form
              form={form}
              layout="vertical"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              {/* 特殊处理 附加提示 */}
              <Form.Item className="nameTips" noStyle>
                <p className={'name-tips'}>
                  {/*  twoToneColor="#fff" */}
                  <InfoCircleTwoTone className="mr-[6px] h-[13px] w-[13px]" />
                  {/* <img src="/static/images/user/nm-tips.png" className="mr-[4px] h-[14px] w-[14px]" /> */}
                  {t('user.regSuc.editOnce')}
                </p>
              </Form.Item>

              <Form.Item
                name="userName"
                className="!mb-[16px]"
                required={true}
                validateTrigger={['onBlur', 'onChange']}
                rules={[
                  {
                    validator: async (_rule, names) => {
                      if (!names) {
                        return Promise.reject(t('common.validtor.usenameEpty'))
                      }
                      if (!Validtor.username(names)) {
                        return Promise.reject(t('common.validtor.nameErr'))
                      }
                      return Promise.resolve()
                    }
                  }
                ]}
              >
                <Input
                  placeholder={t('user.regSuc.newUNPlac')}
                  prefix={<div className={'qc-prefix'}>QC</div>}
                  onChange={(e) =>
                    form.setFieldsValue({ userName: e.target.value.replace(/[\u4E00-\u9FA5]/g, '') })
                  }
                  allowClear={{
                    clearIcon: (
                      <img
                        src="/static/images/common/input_clear_x.svg"
                        style={{ marginLeft: '10px', width: '18px' }}
                      />
                    )
                  }}
                />
              </Form.Item>

              <Form.Item className="commonTips" noStyle>
                <p className={cn('common-tips', '-mt-[10px] mb-[4px]')}>{t('user.regSuc.unTips')}</p>
              </Form.Item>

              {/* passowrd */}
              {/* 这里没明白原逻辑，showPwEdit在什么情况下会出现。先取消 */}
              {/* {regSucInfo.showPwEdit && ( */}
              <Form.Item
                name="password"
                className="!mb-[16px]"
                required={true}
                validateTrigger={['onBlur', 'onChange']}
                rules={[
                  {
                    validator: async (_rule, names) => {
                      if (!names) {
                        return Promise.reject(t('common.validtor.passwordEpty'))
                      }
                      if (!Validtor.password(names)) {
                        return Promise.reject(t('common.validtor.passwordError'))
                      }
                      return Promise.resolve()
                    }
                  }
                ]}
              >
                <Input.Password
                  placeholder={t('user.regSuc.nwPWPlac')}
                  allowClear={{
                    clearIcon: (
                      <img
                        src="/static/images/common/input_clear_x.svg"
                        style={{ marginLeft: '10px', width: '18px' }}
                      />
                    )
                  }}
                  iconRender={(visible) =>
                    visible ? (
                      <EyeTwoTone style={{ marginLeft: '10px' }} />
                    ) : (
                      <EyeInvisibleOutlined style={{ marginLeft: '10px' }} />
                    )
                  }
                />
              </Form.Item>

              <Form.Item className="pwdTips" noStyle>
                <p className="-mt-[10px] mb-[4px] text-left text-xs text-black/65">
                  {t('user.regSuc.pwTips')}
                </p>
              </Form.Item>
              {/* )} */}

              {/* rmCode */}
              {form.getFieldValue('rmCode') && (
                <Form.Item
                  name="rmCode"
                  className="!mb-[16px]"
                  validateTrigger={['onBlur']}
                  rules={[
                    { pattern: /[^\u4E00-\u9FA5]/g, message: t('common.validtor.nameErr') }, // noChinese
                    { type: 'string', max: 20, message: 'maximum 20 characters' }
                  ]}
                >
                  <Input
                    placeholder={t('user.authDialog.refCodePlace')}
                    // readOnly={recOnlyRead}
                    disabled={recOnlyRead}
                    allowClear={{
                      clearIcon: (
                        <img
                          src="/static/images/common/input_clear_x.svg"
                          style={{ marginLeft: '10px', width: '18px' }}
                        />
                      )
                    }}
                  />
                </Form.Item>
              )}

              {/* 按钮提交 */}
              <Form.Item name="loginSumbit">
                <Button
                  block
                  // className={cn(btnDisable && 'btn-active')}
                  className="!h-[46px] w-full cursor-pointer self-stretch !rounded-none border-none px-[40px] py-[10px] !text-lg font-bold text-white"
                  style={{
                    background: '#FC005B',
                    boxShadow: ' 0px 2px 0px 0px rgba(0, 0, 0, 0.15)'
                  }}
                  type="primary"
                  htmlType="submit"
                >
                  {t('user.confirm')}
                </Button>
              </Form.Item>

              {/* 原项目 提交按钮 */}
              {/* <div className={cn(btnDisable && 'btn-active', 'auth-btn-submit')} onClick={onSubmit}>
              {t('user.confirm')}
            </div> */}
            </Form>
          </div>
        ) : (
          <div className={styles['save-img-check']}>
            <div className={cn('save-img-content', 'flex-col qc-flex-center')}>
              <img src="/static/images/saveAccount/close-grey.png" className={'close'} onClick={onClose} />
              {/* down-load */}
              <p className={cn(!isBigScreen && 'h5', 'save-title')}>{t('user.saveAccount.save')}</p>
              <div onClick={downIMG} className={'save-btn'}>
                {t('user.confirm')}
              </div>
              {/* down-load */}
              {showDownLoad && (
                <div ref="downLoadImg" className={cn('down-load', 'flex-col qc-flex-center')}>
                  <img src="/static/images/saveAccount/new-suc-log.png" className={'suc-log'} />
                  <p className={'well-com'}>{t('user.regSuc.newWelcome')}</p>
                  <div className={'info-box'}>
                    <div className={'info-com'}>
                      <img src="/static/images/saveAccount/use.png" className={'user'} />
                      <span className={'m-8'}>{t('user.saveAccount.useName')}</span>
                      <span className={'red'}>QC</span>
                      <span className={'black'}>{form.getFieldValue('userName') || ''}</span>
                    </div>
                    <div className={'info-com'}>
                      <img src="/static/images/saveAccount/pwd.png" className={'user'} />
                      <span className={'m-8'}>{t('user.saveAccount.password')}</span>
                      <span className={'black'}>{form.getFieldValue('password') || ''}</span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </VerifyBaseDialog>
    </>
  )
}

export default memo(RegisterSuccess)
