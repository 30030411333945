'use client'
import { getActLotConfigList } from '@/api/activity'
import VerifyBaseDialog from '@/components/common/VerifyBaseDialog'
import { px2Scalepx } from '@/hooks/useRem'
import { useTranslation } from '@/lib/locales/client'
import { cn } from '@/lib/utils'
import { useAppStore } from '@/stores/app'
import { Select } from 'antd'
import { useParams, useRouter } from 'next/navigation'
import { memo, useEffect, useState } from 'react'
import { useShallow } from 'zustand/react/shallow'
import styles from './index.module.scss'
import TicketList from '../TicketList'

const TicketsHistoryDialog = (props: any) => {
  const { closeDialog, actList = [] } = props
  const [actCode, setActCode] = useState(null)
  const [list, setList] = useState([])
  const { language, isBigScreen } = useAppStore(
    useShallow((state) => ({ language: state.language, isBigScreen: state.isBigScreen }))
  )
  const router = useRouter()
  const { lng } = useParams()
  const { t } = useTranslation()
  const queryActLotConfigList = () => {
    getActLotConfigList({}).then((res) => {
      if (res.code === 200 && res.success) {
        const arr = []
        res.data.forEach((el) => {
          const tar = JSON.parse(el.actName)
          arr.push({
            value: el.actCode,
            label: tar[language]
          })
        })
        arr.shift()
        setList(arr)
        if (arr[0]?.value > 20241008) {
          setActCode(arr[0].value)
        }
      }
    })
  }

  const selectItem = (actCode) => {
    if (actCode <= 20241008) {
      //如果是不是集奖券2.0
      closeDialog && closeDialog()
      router.push(`/${lng}/promo/lucky-draw-win-a-car`)
    }
  }


  useEffect(() => {
    queryActLotConfigList()
  }, [])

  return (
    <VerifyBaseDialog
      cusClass={cn(styles['tickets-history-dialog'], isBigScreen ? 'pc' : 'h5')}
      visible={true}
      width={px2Scalepx(isBigScreen ? 420 : 343)}
      closeFn={() => closeDialog()}
    >
      <p className="title">{t('activity.lottery.history')}</p>
      <Select
        value={actCode}
        className="form-select"
        popupClassName="tickets-drop"
        options={list}
        onChange={(val) => {
          setActCode(val)
          selectItem(val)
        }}
        placeholder={t('activity.lottery.select')}
      ></Select>
      <TicketList actCode={actCode} />
    </VerifyBaseDialog>
  )
}

export default memo(TicketsHistoryDialog)
