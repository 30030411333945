import { useState, useEffect, useMemo, memo, useRef } from 'react'
import { cn } from '@/lib/utils'
import meta from '@/app/[lng]/meta'
import { useDeepCompareEffect } from 'ahooks'
import { useAppStore } from '@/stores/app'
import { useUserStore } from '@/stores/user'
import { useAuthStore } from '@/stores/authControl'
import { track } from '@/hooks/useTrack'
import { cmsTemplateApi } from '@/api/base'
import { Layout, Popover, Badge, Flex, Space, Dropdown } from 'antd'
import { RightOutlined, CaretDownOutlined } from '@ant-design/icons'
import type { MenuProps } from 'antd'
// import { webpHandle } from '@/hooks/aboutWebp'
import Link from 'next/link'
import { useTranslation } from '@/lib/locales/client'
import { Lng } from '@/lib/locales/i18n'
import { useRouter, usePathname, useParams } from 'next/navigation'
import styles from '../_common_css/common.module.scss'
import { useShallow } from 'zustand/react/shallow'
import dayjs from 'dayjs'
// *com
import HeaderAccountMoney from '../../common/accountMoney'
import AccountHome from '@/app/[lng]/account/_components/client'
import GameProvideSearch from '@/app/[lng]/(game)/_components/gameProvideSearch'
const { Header } = Layout

const PCHeader = () => {
  const { t } = useTranslation()
  const {
    globalPublickKey,
    unreadMsgCount,
    setIsShowNotification,
    isShowNotification,
    setIsShowAddToHome,
    changeFastDialog
  } = useAppStore(
    useShallow((state) => ({
      globalPublickKey: state.globalPublickKey,
      unreadMsgCount: state.unreadMsgCount,
      isShowNotification: state.isShowNotification,
      setIsShowNotification: state.setIsShowNotification,
      setIsShowAddToHome: state.setIsShowAddToHome,
      changeFastDialog: state.changeFastDialog
    }))
  )

  const { isLogin, depositActivityInfo, userInfo, availableBonusTask, availableMission } = useUserStore(
    useShallow((state) => ({
      isLogin: state.isLogin,
      depositActivityInfo: state.depositActivityInfo,
      userInfo: state.userInfo,
      availableBonusTask: state.availableBonusTask,
      availableMission: state.availableMission
    }))
  )
  const { openLogin, openRegister } = useAuthStore(
    useShallow((state) => ({
      openLogin: state.openLogin,
      openRegister: state.openRegister
    }))
  )
  const router = useRouter()
  const pathName = usePathname()
  const rawPath = pathName.replace(/^\/(en|ja)($|\/)/g, '/')
  const { lng }: { lng: Lng } = useParams()
  const [activityFlag, setActivityFlag] = useState(false)
  const [depositTime, setDepositTime] = useState(0)
  const [navList, setNavList] = useState([])
  const [IsshowSubMenu, setIsshowSubMenu] = useState(false)
  // const [tabIndex, setTabIndex] = useState(0)  // 待观察
  const [headerLogo, setHeaderLogo] = useState('')
  const [clicked, setClicked] = useState(false)
  const hideHeader = useMemo(() => {
    if (Object.keys(meta).length) return meta[rawPath]?.hideHeader
  }, [pathName])

  // const filterData = ['Classic', 'Premier', 'Expert']
  const filterData: any = useMemo(
    () => t('common.headerNav.sportList', { returnObjects: true, lng: lng }),
    [lng]
  )
  const getPromoMsgCount = useMemo(() => {
    const count = Number(availableBonusTask + availableMission)
    return count > 0 ? count : undefined
  }, [availableBonusTask, availableMission])

  // 读取活动相关状态
  useDeepCompareEffect(() => {
    if (Object.keys(depositActivityInfo).length) {
      setActivityFlag(depositActivityInfo?.flag || false)
      if (depositActivityInfo.extraResult) {
        setDepositTime(Number(depositActivityInfo?.extraResult?.depositTime) || 0)
      }
    }
  }, [depositActivityInfo])

  // get cms logo
  const onGetCMSData = async () => {
    const params = {
      moduleCodeList: ['headerBg']
    }
    const cmsData: Resp = await cmsTemplateApi(params)
    if (cmsData?.success) {
      const filterList = cmsData.data[0] || []
      if (Object.keys(filterList).length) {
        if (filterList.mediaLibrary && filterList.mediaLibrary.length) {
          const filterLogo = filterList.mediaLibrary.find((item) => item.resourceType === 1)
          Object.keys(filterLogo).length && setHeaderLogo(filterLogo.fullFilePath)
        }
      }
    }
  }

  // headerNav
  const dataFmt = (v) => {
    return dayjs(v).format('YYYY-MM-DD')
  }

  useEffect(() => {
    if (globalPublickKey) {
      onGetCMSData()
      queryNavigation()
    }
  }, [globalPublickKey])

  const queryNavigation = async () => {
    const params = {
      moduleCodeList: ['headerNavigation', 'webInfoConfig']
    }
    const cmsData: Resp = await cmsTemplateApi(params)
    if (cmsData?.success) {
      console.log('查询现有 菜单栏', cmsData)
      const dataList = cmsData.data.filter((item: any) => item.moduleCode === 'headerNavigation')
      console.log('读取导航栏数据', dataList)
      setNavList(dataList)
    }
  }

  // 点击个人中心头像 如果出现滚动且关闭弹出层
  useEffect(() => {
    if (clicked) {
      const handleScroll = () => {
        setClicked(false)
      }
      // 绑定滚动事件
      window.addEventListener('scroll', handleScroll, true)
      return () => {
        window.removeEventListener('scroll', handleScroll, true)
      }
    }
  }, [clicked])

  // const isLink = (path: string) => {
  //   const pathList = ['/account', '/sports-bet', 'showNotification']
  //   return !pathList.includes(path)
  // }

  const handNav = (nav: any) => {
    // console.log('路径', nav)
    const activeLink = nav.templateAction
    // 配置菜单菜单的导航 不做跳转, 直接展示二级菜单
    if (activeLink?.indexOf('load?sports') > -1 || activeLink === '/sports-bet' || activeLink === '/blog') {
      setIsshowSubMenu(true)
      return
    }
    // 全连接时直接跳转(如: http xxx)
    if (activeLink && activeLink.indexOf('http') > -1) {
      window.open(activeLink)
      return
    }
    router.push(`/${lng}${activeLink}`)
  }

  // 导航栏 移入Item时 有个弹出菜单
  const isShowNavMenu = (navItem) => {
    if (
      navItem.templateAction.indexOf('load?sports') > -1 ||
      navItem.templateAction === '/sports-bet' ||
      navItem.templateAction === '/blog'
    ) {
      setIsshowSubMenu(true)
    }
  }

  const change = (item: any, index: any) => {
    item === 'Classic' ? router.push(`/${lng}/fb`) : router.push(`/${lng}/sports-bet`)
  }

  const items: MenuProps['items'] = filterData.map((unit, index) => {
    return {
      key: index,
      label: (
        <div
          key={index}
          className={'cu-label'}
          onClick={(event) => {
            event.stopPropagation()
            change(unit, index)
          }}
        >
          {unit}
        </div>
      )
    }
  })

  return (
    <>
      {!hideHeader && (
        <Header
          className={cn(styles['header_box'], 'z-999 relative !h-[108px] w-full bg-white !px-0')}
          style={{
            background: 'linear-gradient(101deg, #FDF6EE 19.05%, #F9F1FC 86.36%)'
          }}
        >
          <div>
            {/* 上下两层菜单 */}
            <Flex vertical>
              {/* nav */}
              <Flex gap={12} justify="space-between" className="mx-auto h-[60px] w-[1000px]">
                {/* left */}
                <Flex>
                  <Link className="z-3 w-[214px] qc-flex-center" href={`/${lng}`}>
                    {headerLogo && (
                      <img src={headerLogo} className="mr-[16px] h-[40px] w-[118px] cursor-pointer" />
                    )}
                    {
                      <img
                        src="/static/images/header/sonepar_pc.png"
                        className="mr-[10px] h-[40px] w-[80px] cursor-pointer"
                      />
                    }
                  </Link>
                  <div>
                    <img src="/static/images/header/brand.png" className="w-[94px]" />
                  </div>

                  {/* 登录后 新人优惠 */}
                  {isLogin && activityFlag && depositTime < 3 && (
                    <Flex
                      justify="center"
                      align="center"
                      className="w-[220px] pc:cursor-pointer"
                      onClick={() => changeFastDialog(true)}
                    >
                      <Flex>
                        <Space className="text-2xl font-semibold text-[#FC005B]">+</Space>
                        <Space className="text-3xl font-semibold text-[#FC005B]">
                          {depositActivityInfo?.extraResult?.bonusRate || 0}
                        </Space>
                        <Space className="text-2xl font-normal text-[#FC005B]">%</Space>
                      </Flex>
                      <Flex vertical align="center" className="w-[90px]">
                        <Flex align="center" className="h-[20px] text-base font-semibold text-[#FC005B]">
                          REWARDS
                        </Flex>
                        <Flex align="center" className="h-[10px] text-xs font-normal text-[#5E434F]">
                          <span>End {dataFmt(depositActivityInfo?.activityEndDate)}</span>
                        </Flex>
                      </Flex>
                      <RightOutlined style={{ fontSize: '16px', color: '#5E434F' }} />
                    </Flex>
                  )}
                </Flex>

                {/* right */}
                <Flex gap={14} justify={'flex-end'}>
                  {/* download  */}
                  <Flex align="center">
                    <img
                      src="/static/images/header/pc_download.png"
                      alt=""
                      className="h-[32px] w-[32px] cursor-pointer text-[#9D8B93]"
                      onClick={() => setIsShowAddToHome(true)}
                    />
                  </Flex>
                  {/* 余额 & 货币切换 */}
                  {isLogin && rawPath !== '/account' && <Space>{<HeaderAccountMoney />}</Space>}
                  {/* 未登录状态 login按钮 */}
                  {!isLogin && (
                    <Flex align="center">
                      <div
                        className="h-[32px] w-[100px] border-loginBtn/60 px-[12px] qc-flex-center qc-border-1 pc:cursor-pointer"
                        onClick={() => openLogin()}
                      >
                        <span className="text-sm font-semibold not-italic text-[#5E434F99]">
                          {t('user.login')}
                        </span>
                      </div>
                    </Flex>
                  )}
                  {/* register | deposit */}
                  <Flex align="center">
                    {isLogin ? (
                      <Link
                        href={`/${lng}/account/deposit`}
                        onClick={() => track('RechargeClick')}
                        className="h-[32px] w-[100px] px-[14px] py-[12px] qc-flex-center"
                        style={{
                          background: 'linear-gradient(91deg, #FF4E8E -1.47%, #FC005B 99.11%)',
                          boxShadow: '0px 1px 0px 0px rgba(0, 0, 0, 0.15)'
                        }}
                      >
                        <span className="leading-100 text-sm font-semibold not-italic text-white">
                          {t('user.headUser.toDeposit')}
                        </span>
                      </Link>
                    ) : (
                      <div
                        onClick={() => openRegister(true)}
                        className="h-[32px] w-[100px] px-[14px] py-[12px] qc-flex-center pc:cursor-pointer"
                        style={{
                          background: 'linear-gradient(91deg, #FF4E8E -1.47%, #FC005B 99.11%)',
                          boxShadow: '0px 1px 0px 0px rgba(0, 0, 0, 0.15)'
                        }}
                      >
                        <span className="leading-100 text-sm font-semibold not-italic text-white">
                          {t('user.signup')}
                        </span>
                      </div>
                    )}
                  </Flex>
                  {/* 登录状态 集奖券 */}
                  {isLogin && (
                    <Flex align="center">
                      <div
                        onClick={() => setIsShowNotification(!isShowNotification)}
                        className="h-[32px] w-[32px] shrink-0 cursor-pointer rounded-full bg-[#FC005B]/[0.10] qc-flex-center"
                      >
                        <Badge count={getPromoMsgCount} offset={[4, -2]} size="small">
                          <img src="/static/images/header/ticket.png" className="h-[20px] w-[20px]" />
                        </Badge>
                      </div>
                    </Flex>
                  )}
                  {/* user center */}
                  {isLogin && (
                    <Flex align="center">
                      <Popover
                        trigger="click"
                        placement="bottomRight"
                        overlayClassName={'avatarPop'}
                        open={clicked}
                        onOpenChange={(open) => setClicked(open)}
                        getPopupContainer={() => document.querySelector('.pc-contrainer')}
                        zIndex={2001}
                        content={
                          // 改为弹出个人用户信息
                          <AccountHome device="mobile" isShowPC={clicked} onPCPop={setClicked} />
                        }
                      >
                        <div
                          className={cn(
                            'relative h-[32px] w-[32px] cursor-pointer rounded-full',
                            'user-avatar',
                            unreadMsgCount > 0 && 'active'
                          )}
                          style={{
                            backgroundImage: userInfo.avatar
                              ? `url(/static/images/myAccount/avatar/avata-${userInfo.avatar}.png)`
                              : `url(/static/images/myAccount/avatar/avata-0.png)`
                          }}
                          id="userAvatar"
                          onClick={() => {
                            setClicked(!clicked)
                          }}
                        ></div>
                      </Popover>
                    </Flex>
                  )}
                </Flex>
              </Flex>

              {/* menu 导航栏 */}
              <Flex
                gap={12}
                align={'center'}
                className="mx-auto h-[48px] w-full bg-white"
                style={{
                  borderTop: '1px solid rgba(0, 0, 0, 0.10)'
                }}
              >
                <nav
                  className="mx-auto flex w-[1000px] items-center"
                  style={{
                    background: 'linear-gradient(180deg, #f5f5f5 0%, #e9e9e9 100%)'
                  }}
                >
                  <ul className="nav-list">
                    {navList.map((item, index) => (
                      <Link
                        className={cn('nav-item', item.templateAction === rawPath && 'item-active')}
                        key={index}
                        href="#"
                        onClick={(event) => {
                          event.preventDefault()
                          handNav(item)
                        }}
                        onMouseOver={() => isShowNavMenu(item)}
                        onMouseOut={() => setIsshowSubMenu(false)}
                      >
                        {/* 特殊处理有弹窗的 */}
                        {item.templateAction?.indexOf('load?sports') > -1 ||
                        item.templateAction === '/sports-bet' ||
                        item.templateAction === '/blog' ? (
                          <>
                            <Dropdown
                              menu={{ items }}
                              overlayClassName="flex justify-center items-center flex-col gap-[8px] w-[128px] p-[8px]"
                              // menu={{ items }}
                              // dropdownRender={(menu) => (
                              //   <div style={contentStyle}>
                              //     {cloneElement(
                              //       menu as React.ReactElement<{
                              //         style: React.CSSProperties
                              //       }>,
                              //       { style: menuStyle }
                              //     )}
                              //     <Divider style={{ margin: 0 }} />
                              //     <Space style={{ padding: 8 }}>
                              //       <Button type="primary">Click me!</Button>
                              //     </Space>
                              //   </div>
                              // )}
                            >
                              {/* <a onClick={(e) => e.preventDefault()}> */}
                              <Space>
                                {/* Hover me */}
                                {item.title}
                                {/* <DownOutlined /> */}
                                <CaretDownOutlined className="absolute right-[6px] top-[2px] h-[8px] w-[8px]" />
                              </Space>
                              {/* </a> */}
                            </Dropdown>
                          </>
                        ) : (
                          item.title
                        )}
                      </Link>
                    ))}
                    <Popover
                      trigger="click"
                      placement="bottomRight"
                      overlayClassName={'pcTabGameSearchPop'}
                      getPopupContainer={() => document.querySelector('.pc-contrainer')}
                      zIndex={2001}
                      content={<GameProvideSearch pageType={'searchPage'} />}
                    >
                      <li className="cursor-pointer qc-flex-center">
                        <img className="h-[20px] w-[20px]" src="/static/images/game/search-icon.svg" />
                      </li>
                    </Popover>
                  </ul>
                </nav>
              </Flex>
            </Flex>
          </div>
        </Header>
      )}
    </>
  )
}

export default memo(PCHeader)
