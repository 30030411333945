import(/* webpackMode: "eager", webpackExports: ["default"] */ "/project/node_modules/@ant-design/nextjs-registry/es/AntdRegistry.js");
;
import(/* webpackMode: "eager" */ "/project/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/project/src/assets/styles/base/reset.css");
;
import(/* webpackMode: "eager" */ "/project/src/app/[lng]/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/project/src/components/layout/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/project/src/lib/appRegistry.tsx");
