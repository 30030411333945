import { useAppStore } from '@/stores/app'

export const defaultlChannel = () => {
  const language = useAppStore((state) => state.language)
  const isEn = language === 'en-US'
  return [
    {
      channelCode: 'coinPayment',
      channelName: isEn ? 'Cryptocurrency' : '仮想通貨',
      channelType: 'virtual',
      channelLogoPath: '/static/images/deposit/list/crypto.png',
      channelMarkLogoPath: `/static/images/deposit/list/up3${isEn ? '' : '-jp'}.png`,
      bestFastFlag: true,
      payType: 'virtual',
      sort: 1
    },
    {
      channelCode: 'deskdzjpypay',
      channelName: isEn ? 'Bank Transfer' : '銀行振込',
      identityCertifiedFlag: 1,
      channelType: 'hibanq',
      channelLogoPath: '/static/images/deposit/list/banktransfer.png',
      channelMarkLogoPath: `/static/images/deposit/list/popular${isEn ? '' : '-jp'}.png`,
      payType: '18',
      sort: 2,
      openNewWindow: true
    },
    {
      channelCode: 'banktobank',
      channelName: isEn ? 'Bank Transfer' : '銀行振込',
      identityCertifiedFlag: 1,
      channelType: 'hibanq',
      channelLogoPath: '/static/images/deposit/list/banktransfer.png',
      channelMarkLogoPath: `/static/images/deposit/list/popular${isEn ? '' : '-jp'}.png`,
      payType: '45',
      sort: 2,
      quickAmount: true
    },
    {
      channelCode: 'vegaWallet',
      channelName: 'Vega Wallet',
      channelType: 'hibanq',
      channelLogoPath: '/static/images/deposit/list/vegawallet.png',
      channelMarkLogoPath: `/static/images/deposit/list/popular${isEn ? '' : '-jp'}.png`,
      payType: '133',
      sort: 3,
      bestFastFlag: true,
      quickAmount: true
    },
    {
      channelCode: "jetonPaypay",
      channelName: isEn ? "Bank Transfer EX" : "銀行送金EX",
      channelType: "hibanq",
      channelLogoPath: "/static/images/deposit/list/paypay.png",
      payType: "139",
      sort: 4,
      quickAmount: true,
      openNewWindow: true,
      identityCertifiedFlag: 1
    },
    {
      channelCode: "gate2way",
      channelName: isEn ? "Bank Transfer（gate2way）" : "銀行振込（gate2way）",
      channelType: "hibanq",
      channelLogoPath: "/static/images/deposit/list/gate2.png",
      payType: "143",
      sort: 4.5,
      quickAmount: true,
      openNewWindow: true,
      identityCertifiedFlag: 1
    },
    {
      channelCode: 'payz',
      channelName: 'PayZ',
      channelType: 'hibanq',
      channelLogoPath: '/static/images/deposit/list/payz.png',
      payType: '130',
      sort: 4.8,
      quickAmount: true,
      identityCertifiedFlag: 1,
      openNewWindow: true
    },
    {
      channelCode: 'naticPay',
      channelLogoPath: '/static/images/deposit/list/sticpay.png',
      channelName: 'Sticpay',
      channelType: 'hibanq',
      payType: '129',
      sort: 5,
      identityCertifiedFlag: 1,
      openNewWindow: true,
      quickAmount: true
    },
    {
      channelCode: 'naticPay',
      channelLogoPath: '/static/images/deposit/list/creditcard.png',
      channelName: isEn ? 'Credit Card' : 'クレジットカード',
      channelType: 'hibanq',
      payType: 'creditP93',
      sort: 5,
      identityCertifiedFlag: 1,
      openNewWindow: true
    },
    {
      channelCode: 'naticPay',
      channelLogoPath: '/static/images/deposit/list/creditcard.png',
      channelName: isEn ? 'Credit Card' : 'クレジットカード',
      channelType: 'changeVirtual',
      payType: '999',
      sort: 5,
      openNewWindow: true
    },
    {
      channelCode: 'naticWallet',
      channelName: 'Natic Wallet',
      channelType: 'hibanq',
      channelLogoPath: '/static/images/deposit/list/naticwallet.png',
      payType: '125',
      sort: 6,
      bestFastFlag: true,
      quickAmount: true
    },
    {
      channelCode: 'tigerpay',
      channelLogoPath: '/static/images/deposit/list/tigerpay.png',
      channelName: 'Tiger Pay',
      channelType: 'hibanq',
      payType: '123',
      sort: 7,
      openNewWindow: true
    },
    {
      channelCode: 'naticPay',
      channelLogoPath: '/static/images/deposit/list/skrill.png',
      channelName: 'Skrill',
      channelType: 'changeVirtual',
      payType: '126',
      sort: 8,
      openNewWindow: true
    },
    {
      channelCode: 'naticPay',
      channelLogoPath: '/static/images/deposit/list/neteller.png',
      channelName: 'Neteller',
      channelType: 'changeVirtual',
      payType: '127',
      sort: 9,
      openNewWindow: true
    },
    {
      channelCode: 'naticPay',
      channelLogoPath: '/static/images/deposit/list/jeton.png',
      channelName: 'Jeton',
      channelType: 'changeVirtual',
      payType: '124',
      sort: 10,
      openNewWindow: true
    },
    {
      channelCode: 'naticPay',
      channelLogoPath: '/static/images/deposit/list/perfectmoney.png',
      channelName: 'PerfectMoney',
      channelType: 'changeVirtual',
      payType: '122',
      sort: 12,
      openNewWindow: true
    },
    {
      channelCode: 'onramper',
      channelLogoPath: '/static/images/deposit/list/onramper.png',
      channelMarkLogoPath: '/static/images/deposit/list/onrampermark.png',
      channelName: isEn ? 'Buy Crypto' : '仮想通貨購入',
      channelType: 'changeVirtual',
      payType: '60',
      sort: 13,
      openNewWindow: true
    }
    // {
    //   channelCode: "creditcard",
    //   channelName: isEn ? "Credit Card" : "クレジットカード",
    //   identityCertifiedFlag: 1,
    //   channelType: "hibanq",
    //   channelLogoPath: "/static/images/deposit/list/card.png",
    //   payType: "credit",
    //   creditList: {},
    //   sort: 14
    // }
  ]
}
