'use client'
import { useState, useEffect, useMemo, memo } from 'react'
import { cn, onHandleAlt } from '@/lib/utils'
import { useDeepCompareEffect } from 'ahooks'
import { Flex } from 'antd'
import Link from 'next/link'
import meta from '@/app/[lng]/meta'
import { useTranslation } from '@/lib/locales/client'
import { Lng } from '@/lib/locales/i18n'
import { usePathname, useParams } from 'next/navigation'
import { FollowSocialMedia, FollowBlog, Licenses } from '@/constants/followUs'
import siteLinks from '@/config/site_links'
import { cmsTemplateApi } from '@/api/base'
import { useAppStore } from '@/stores/app'
import { useUserStore } from '@/stores/user'
import { useAuthStore } from '@/stores/authControl'
import { useRedirect } from '@/lib/useRedireact'
import { numThFilter } from '@/lib/filter'
import styles from '../_common_css/common.module.scss'
import { useShallow } from 'zustand/react/shallow'
/**com */
import QueryLang from '@/components/base/queryLang'

const Footer = () => {
  const { t } = useTranslation()
  const globalPublickKey = useAppStore((state) => state.globalPublickKey)
  const openLogin = useAuthStore((state) => state.openLogin)
  const { isLogin, currencyRateFooter, currency, jpyToUsd } = useUserStore(
    useShallow((state) => ({
      isLogin: state.isLogin,
      currencyRateFooter: state.currencyRateFooter,
      currency: state.currency,
      jpyToUsd: state.jpyToUsd
    }))
  )
  const { lng }: { lng: Lng } = useParams()
  const jumpURL = useRedirect()
  const pathName = usePathname()
  const rawPath = pathName.replace(/^\/(en|ja)($|\/)/g, '/')
  const [footerpaymentData, setFooterpaymentData] = useState([])
  const [footerpaytypeData, setFooterpaytypeData] = useState([])
  const [footerfiatData, setFooterfiatData] = useState([])
  const [currencyRateFmt, setCurrencyRateFmt] = useState('')
  const [footergamesupplierData, setFootergamesupplierData] = useState([])
  const [ambassadorImg, setAmbassadorImg] = useState('')
  const [jumpLink, setJumpLink] = useState('')
  const [ambassadorObj, setAmbassadorObj] = useState(null)
  const linksPath = useMemo(() => Object.values(siteLinks), [])
  const curYear = useMemo(() => new Date().getFullYear(), [])
  const dataList: any = useMemo(() => t('common.footer.links', { returnObjects: true, lng: lng }), [lng])
  const onOpenChat = () => {
    window.Intercom('show')
  }

  // 特殊路由下不隐藏头部
  const exSomeURL =
    rawPath.startsWith('/account/buyCrypto') ||
    rawPath.startsWith('/account/deposit') ||
    rawPath.startsWith('/account/withdraw')

  const hideFooter = useMemo(() => {
    if (Object.keys(meta).length) {
      // 特殊处理充值 在pc底部不隐藏 h5隐藏
      if (exSomeURL && isLogin) {
        return !meta[rawPath]?.hideFooter
      } else {
        return meta[rawPath]?.hideFooter
      }
    }
  }, [pathName, isLogin])

  // const getJaUrl = (path: any) => {
  // console.log(language === 'ja-JP', path)
  //   if (lng === 'ja' && path && path.indexOf('/ja') === -1 && path.indexOf('http') === -1) {
  //     return '/ja' + path
  //   }
  //   return path
  // }

  const getCMSData = async () => {
    const params = {
      moduleCodeList: [
        'footerAmbassador',
        'footerpayment',
        'footerpaytype',
        'footerfiat',
        'footergamesupplier'
      ]
    }
    const cmsData: Resp = await cmsTemplateApi(params)
    if (cmsData?.success) {
      // 1st image official partner
      const getAmbassador = cmsData.data.find((item) => item.moduleCode === 'footerAmbassador') || {}
      setAmbassadorImg(getAmbassador?.mediaLibrary[0]?.fullFilePath || '')
      setJumpLink(getAmbassador?.templateAction || '')
      setAmbassadorObj(getAmbassador)
      setFooterpaymentData(cmsData.data.filter((item: any) => item.moduleCode === 'footerpayment'))
      setFooterpaytypeData(cmsData.data.filter((item: any) => item.moduleCode === 'footerpaytype'))
      setFooterfiatData(cmsData.data.filter((item: any) => item.moduleCode === 'footerfiat'))
      setFootergamesupplierData(cmsData.data.filter((item: any) => item.moduleCode === 'footergamesupplier'))
    }
  }

  // 注意这个依赖是初始才监听，如果货币切换要求实时，要加其它监听值
  useDeepCompareEffect(() => {
    // currency: 当前币种为法币时，展示法币符号；为虚拟币时，对应展示USD
    const { targetAmount, sourceCurrency } = currencyRateFooter || {}
    if (Object.keys(currency).length) {
      if (currency?.currency === 'JPY') {
        setCurrencyRateFmt(`1 JPY = ${numThFilter(jpyToUsd, 5)} USD`)
      } else if (currency?.currency === 'USD') {
        setCurrencyRateFmt(`1 USD = ${numThFilter(1 / jpyToUsd, 2)} JPY`)
      } else {
        const num = currency?.currency === 'SHIB' ? 5 : 2
        setCurrencyRateFmt(`1 ${sourceCurrency} = ${numThFilter(targetAmount / jpyToUsd || 0, num)} JPY`)
      }
    }
  }, [currency, currencyRateFooter])

  useEffect(() => {
    let script = document.createElement('script')
    script.src = 'https://67775a69-38c7-41dd-81de-2d3e0690fbc0.seals.dlagglobal.com/dlag-seal.js'
    script.async = true
    document.getElementsByTagName('body')[0].appendChild(script)
  }, [])

  useEffect(() => {
    if (globalPublickKey) getCMSData()
  }, [globalPublickKey])

  return (
    <>
      {!hideFooter && (
        <footer className="w-full bg-white">
          <Flex vertical className={cn(styles['qc-footer'], 'ac-footer', 'm-auto mt-[24px] w-[1000px]')}>
            <Flex gap={'middle'} justify={'space-between'} className="mb-[16px] h-[406px]">
              {/* left side */}
              <Flex vertical gap={22} className="mb-[20px] basis-[660px]">
                <Flex className="w-full last:mr-0">
                  {dataList.map((item, key) => (
                    <div
                      key={key}
                      className={'w-min-[22px] relative h-auto whitespace-nowrap'}
                      style={{
                        marginRight: lng === 'ja' ? '40px' : '50px'
                      }}
                    >
                      <h3 className="mb-[20px] text-base font-semibold leading-[16px] text-[#000000d8]">
                        {item.title}
                      </h3>
                      <ul>
                        {item.children.map((unit, index) => (
                          <li key={'cd' + index} className={cn('cursor-pointer leading-[28px]')}>
                            <Link href={unit.title === 'Affiliate' ? unit.link : `/${lng}${unit.link}`}>
                              <span
                                className={cn(
                                  rawPath === unit.link && '!text-[#fc005b]',
                                  'text-sm font-normal normal-case text-black/65 hover:text-[#fc005b]'
                                )}
                              >
                                {unit.title}
                              </span>

                              {linksPath[key][index] === '/contact' && (
                                <span
                                  className="absolute ml-[4px] mt-[4px] h-[22px] whitespace-nowrap rounded-full px-[4px] text-center text-[11px] leading-loose text-white"
                                  style={{
                                    background:
                                      'linear-gradient(90deg, rgba(241, 19, 98, 1) 0%, rgba(241, 217, 117, 1) 100%)'
                                  }}
                                  onClick={(event) => {
                                    event.stopPropagation()
                                    onOpenChat()
                                  }}
                                >
                                  {t('common.service')}
                                </span>
                              )}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </Flex>
                <article>
                  <h3 className="mb-[16px] text-base font-semibold leading-[16px] text-[#000000A6]">
                    {t('common.footer.followUS')}
                  </h3>
                  <div className="flex w-[176px] items-center justify-between">
                    {FollowSocialMedia.map((item, key) => (
                      <a
                        target="_blank"
                        key={key}
                        href={item.path}
                        title={item.name}
                        className="last-of-type:mr-[16px]"
                      >
                        {/* <a target="_blank" key={key} href={getJaUrl(item.path)} title={item.name}> */}
                        {item.name && (
                          <img
                            src={`/static/images/footer/${item.name?.toLowerCase()}_pc.svg`}
                            alt={item.name}
                            className="h-max-[32px] mr-[8px] w-[32px] opacity-65 hover:opacity-100 hover:transition-all hover:delay-300 hover:ease-in-out"
                          />
                        )}
                      </a>
                    ))}
                    {FollowBlog.map((item, key) => (
                      <div
                        key={key}
                        onClick={() => jumpURL(item.url)}
                        className={
                          'w-min-[88px] mr-[6px] flex h-[32px] cursor-pointer items-center justify-center border-black/10 px-[10px] py-[4px] qc-border-1 hover:bg-[#f5f5f5]'
                        }
                      >
                        <img
                          src={item.img}
                          alt=""
                          className="mr-[4px] h-[18px] w-auto text-[#595959] opacity-100"
                        />
                        <span className="w-[50px] text-center text-xs font-semibold text-black/65">
                          {t(item.text)}
                        </span>
                      </div>
                    ))}
                  </div>
                </article>

                <Flex className="items-center">
                  <QueryLang />
                  <Flex gap={16} className="ml-[16px] first:h-[32px] last:w-[70px]">
                    <div
                      id="dlag-67775a69-38c7-41dd-81de-2d3e0690fbc0"
                      data-dlag-seal-id="67775a69-38c7-41dd-81de-2d3e0690fbc0"
                      data-dlag-image-size="128"
                      data-dlag-image-type="basic-small"
                      className="h-[32px] w-[32px]"
                    />
                    {Licenses.map((item, key) => (
                      <a key={key} href={item.path} target="_blank" rel="nofollow">
                        <img
                          className="h-[32px] w-[32px] hover:opacity-70 hover:transition-all hover:delay-300 hover:ease-in-out"
                          src={`/static/images/footer/${item.icon?.toLowerCase()}-logo-pc.svg`}
                          alt={item.icon}
                        />
                      </a>
                    ))}
                  </Flex>
                </Flex>
              </Flex>
              {/* right side */}
              <Flex vertical gap={34} className="w-[345px]">
                {ambassadorImg && (
                  <div className="rounded-4">
                    <img
                      src={ambassadorImg}
                      className="h-[212px] cursor-pointer"
                      onClick={() => jumpURL(jumpLink)}
                      alt={onHandleAlt(ambassadorObj)}
                    />
                  </div>
                )}
                {/* payment */}
                <Flex vertical gap={8} className="px-[12px]">
                  <Flex justify="space-between">
                    {footerpaymentData.map((item, index) => (
                      <img
                        key={index}
                        className="rounded-4 h-[28px] w-[62px] grayscale hover:cursor-pointer hover:grayscale-0"
                        src={item.mediaLibrary[0]?.fullFilePath}
                        onClick={() => (isLogin ? jumpURL(item.templateAction) : openLogin())}
                      />
                    ))}
                  </Flex>
                  <Flex justify="space-between">
                    {footerpaytypeData.map((item, index) => (
                      <img
                        key={index}
                        className="rounded-4 h-[28px] w-[62px] grayscale hover:cursor-pointer hover:grayscale-0"
                        src={item.mediaLibrary[0]?.fullFilePath}
                        onClick={() => (isLogin ? jumpURL(item.templateAction) : openLogin())}
                      />
                    ))}
                  </Flex>
                  <Flex justify="space-between">
                    {footergamesupplierData.map((item, index) => (
                      <img
                        key={index}
                        className="rounded-4 h-[28px] w-[62px] grayscale hover:cursor-pointer hover:grayscale-0"
                        src={item.mediaLibrary[0]?.fullFilePath}
                        onClick={() => jumpURL(item.templateAction)}
                      />
                    ))}
                  </Flex>
                  <Flex justify="space-between">
                    {footerfiatData.map((item, index) => (
                      <img
                        key={index}
                        className="rounded-4 h-[20px] w-[20px] grayscale hover:cursor-pointer hover:grayscale-0"
                        src={item.mediaLibrary[0]?.fullFilePath}
                        onClick={() => (isLogin ? jumpURL(item.templateAction) : openLogin())}
                      />
                    ))}
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
            {/* Divider */}
            <div className="mb-[24px] h-[1px] w-full bg-gray-200"></div>
            {/* bottom */}
            <Flex vertical gap={16} className="h-[235px]">
              {/* rate */}
              <div className="flex items-center justify-center">
                {currencyRateFooter?.sourceCurrency && (
                  <div className="h-[37px] whitespace-nowrap border-black/10 p-[8px] text-center text-[14px] font-semibold leading-[21px] text-black/45 qc-border-1">
                    {currencyRateFmt}
                  </div>
                )}
              </div>
              <p className="flex items-center justify-center text-sm font-normal leading-[14px] text-black/45">
                {t('common.footer.copyrights', { 0: curYear })}
              </p>
              <div
                className={cn(
                  'footer-link',
                  'flex items-center justify-center text-xs font-normal leading-[14px] text-black/45'
                )}
                dangerouslySetInnerHTML={{ __html: t('common.footerHtml.text') }}
              ></div>
              <p
                className={cn(
                  'footer-link',
                  'flex items-center justify-center text-xs font-normal leading-[14px] text-black/45'
                )}
                dangerouslySetInnerHTML={{ __html: t('common.footerHtml.support') }}
              ></p>
            </Flex>
          </Flex>
        </footer>
      )}
    </>
  )
}

export default memo(Footer)
