'use client'
import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'
import { getUserInfo, logoutApi, getKycUserInfo, seonSession } from '@/api/auth'
import { signApplyAign, signDetail, sumFullLifecycle, taskQueryTaskTags } from '@/api/activity'
import {
  rakebackReceive,
  getBonusList,
  getFreeSpinList,
  getHomeTask,
  queryDetailLogined
} from '@/api/activity'
import { getExchangeRate } from '@/api/wallet'
import { useAppStore } from '@/stores/app'
import { useAuthStore } from '@/stores/authControl'
import { track } from '@/hooks/useTrack'
// import { getRakebackTime } from '@/utils/filter'
import { virtualArr } from '@/constants/followUs'
import dayjs from 'dayjs'
import { Md5 } from 'ts-md5'
// import { useRouter } from 'next/navigation'
import { useTranslation } from '@/lib/locales/client'
import { IUserStroe } from '../types/IStore'
// import { useRedirect } from '@/lib/useRedireact'
// import fpPro from '@web/sdk/lib/fingerPrintProKratos'

// 创建状态存储
export const useUserStore = create<IUserStroe>()(
  persist(
    immer((set, get) => ({
      // original
      userInfo: {},
      wallets: [],
      currency: {
        currency: 'JPY',
        amount: 0
      }, //当前币种
      freeSpinWallet: { currency: '' }, //免费旋转钱包
      homeDepositType: '',
      hideZero: false,
      currencyDisplay: '',
      currencyRate: [],
      currencyRateFooter: {}, // 币种汇率查询，底部使用，有独立业务逻辑；
      moneyTypeSelectIndex: 0, // 当前是启用优惠券还是使用普通钱包（0：普通钱包，1：优惠券）
      bonusCouponItem: { requestId: '' }, // 开启的优惠券item
      isLogin: false,
      webToken: '',
      safeLogInfo: {},
      fingerPrintID: '66733c8855507546c2eaad3e845159f5', // 先hard code，后期接入指纹再改
      bonusAndTask: [],
      doneBonusAndTask: [],
      freeSpinList: [],
      availableBonusTask: -1,
      vipInfo: {},
      pageVisible: true,
      kycInfo: {}, // kyc数据
      kycStatus: {
        level1: 2, //-1 拒绝、0 待审核、1 已完成、2 未开始
        level2: 2,
        level3: 2,
        level4: 2
      },
      thirdRmCode: '', //手动输入的的RmCode，第三方注册使用
      gameCurrency: '',
      activityCurrency: 1, //当前汇率兑换日元
      jpyToUsd: 1,
      rakebackInfo: {
        rakeBackStatus: 1,
        rakeBackValidStatus: 1,
        rakeAmountPendingTotal: '0',
        rakebackTimeStr: ''
      },
      depositActivityInfo: {
        activityCode: '',
        conKey: '',
        conVal: '',
        flag: false,
        productId: '',
        sysNowTime: new Date(),
        extraResult: {
          depositTime: null,
          activityBeginAndEndDate: [],
          bonusRate: '',
          freeSpinTimes: '',
          freeSpinCounts: 0,
          prizeAmount: ''
        },
        remark: '',
        activityDescribes: '',
        activityBeginDate: new Date(),
        activityEndDate: new Date()
      },
      kycAuditStatus: -1,
      signDetail: null,
      missionTagsList: [],
      availableMission: -1,
      isCompleteDepositVerify: false,
      showKycDialogType: '',
      statisticsInfo: {
        totalDefaultWinLossAmount: 0,
        totalDefaultValidBetAmount: 0,
        totalDefaultBetAmount: 0
      },
      _hasHydrated: false,
      // actions
      // 上传指纹前的埋点, 指纹等后面接, 等埋点加入后再恢复
      getFpPro: async (params) => {
        // await _this.$FP?.eventTracking({
        //   loginName: params.loginName,
        //   playerId: params.playerId,
        //   token: get().webToken
        // })
      },
      kycInfoAction: (data) => {
        set({ kycAuditStatus: 0 })
        get().SET_KYCINFO(data)
        //绑定过手机或者邮箱，就能领取KYC1奖励
        // console.log('userInfo:', get().userInfo, get().kycInfo)
        if (get().userInfo.mobileBindFlag === 1 || get().userInfo.emailBindFlag === 1) {
          set({ kycAuditStatus: 0.5 })
        }
        if (
          data.firstLevel.status === 1 &&
          get().userInfo.mobileBindFlag === 1 &&
          get().userInfo.emailBindFlag === 1
        ) {
          set({ kycStatus: { ...get().kycStatus, level1: 1 }, kycAuditStatus: 1 })
        }
        const resultF = data.secondLevel.some((item) => {
          if (item.type == 'F') {
            return true
          }
        })
        if (data.secondLevel.length > 0 && resultF) {
          set({ kycStatus: { ...get().kycStatus, level2: data.secondLevel[0].status } })
          if (data.secondLevel[0].status === 1) {
            set({ kycAuditStatus: 2 })
          }
        }
        if (data.thirdLevel.length > 0) {
          set({ kycStatus: { ...get().kycStatus, level3: data.thirdLevel[0].status } })
          if (data.thirdLevel[0].status === 1) {
            set({ kycAuditStatus: 3 })
          }
        }
        if (data.fourthLevel.length > 0) {
          set({ kycStatus: { ...get().kycStatus, level4: data.fourthLevel[0].status } })
          if (data.fourthLevel[0].status === 1) {
            set({ kycAuditStatus: 4 })
          }
        }
        set({
          isCompleteDepositVerify:
            !!get().userInfo.firstNameJapanese &&
            !!get().userInfo.lastNameJapanese &&
            !!get().userInfo.mobile &&
            !!get().userInfo.email &&
            get().kycAuditStatus >= 2
        })
      },
      /* 注册登录时调用-先请求最新token */
      updateUserInfo: async (loginName?: string, isforLogin = 0) => {
        // updateUserInfo: async (loginName, zhiwen = 0) => {
        if (get().webToken === '') return
        const res: any = await getUserInfo({ loginName, searchFreeSpinWallet: true })
        // console.log('get userinfo?', res)
        if (res.success && res.data) {
          if (isforLogin) {
            // 上传指纹前的埋点, 指纹等后面接，先关了_this引用
            //上报指纹
            // fpPro({
            //   loginName: res.data.loginName,
            //   playerId: res.data.id
            // })
          }
          set({ userInfo: res.data || {} })
          const myCurrency = get().freeSpinWallet.currency || ''
          const tar = res.data.freeSpinWalletList?.find((item: any) => item.currency === myCurrency)
          set({ freeSpinWallet: tar ? tar : res.data.freeSpinWalletList?.[0] || {} })
          if (Number(get().userInfo.userNamePwdStatus) === 0) {
            useAuthStore.getState().SET_REG_SUC_INFO({ flag: true })
          } else {
            if (isforLogin) {
              get().setSeonSession()
            }
            getKycUserInfo({ playerId: res.data.id }).then((res2) => {
              // console.log('用户登录时调用kyc数据 ', res2)
              if (res2.data && res2.data.firstLevel) {
                delete res2.data.loginName
                set({ userInfo: { ...res.data, ...res2.data.firstLevel } })
                get().kycInfoAction(res2.data)
              }
            })
            get().setUserLogin(true)
            //默认排序 + 有金额的排在前面
            const arr = ['JPY', 'USD', ...virtualArr]
            const hasBalance = []
            const noBalance = []
            // console.log('用户钱包数据', res.data)
            res.data.wallets.forEach((el) => {
              el.sort = arr.indexOf(el.currency)
              Number(el.amount) > 0 ? hasBalance.push(el) : noBalance.push(el)
            })
            set({
              wallets: [
                ...hasBalance.sort((a, b) => a.sort - b.sort),
                ...noBalance.sort((a, b) => a.sort - b.sort)
              ]
            })
            set({ currency: get().wallets.filter((tt) => tt.currency === get().currency.currency)[0] })
            // set({ currency: get().wallets.filter((tt) => tt.currency === get().currency.currency)[0] })
          }
        }
      },
      setThirdRmCode: (data) => set({ thirdRmCode: data }),
      setfingerPrintID: (data) => set({ fingerPrintID: data }),
      setUserLogin: (data) => set({ isLogin: data }),
      /* 鉴权接口需要使用 */
      setWebToken: (data) => set({ webToken: data }),
      SET_FREESPIN_WALLET: (data) => set({ freeSpinWallet: data }),
      SET_CURRENCY: (data) => set({ currency: data }),
      SET_CURRENCY_DISPLAY: (data) => set({ currencyDisplay: data }),
      SET_HIDE_ZERO: (data) => set({ hideZero: data }),
      SET_MONEY_TYPE_SELECT_INDEX: (data) => set({ moneyTypeSelectIndex: data }),
      SET_HOME_DEPOSIT_TYPE: (data) => set({ homeDepositType: data }),
      SET_ACTIVITY_CURRENCY: (data) => set({ activityCurrency: data }),
      SET_KYCINFO: (data) => set({ kycInfo: data }),
      SET_BONUS_COUPON_ITEM: (data) => {
        if (data) {
          data.currency = data.fetchCurrency || data.bonusCurrency
          set({ bonusCouponItem: data })
        }
      },
      SET_SHOW_KYC_DIALOG: (type) => {
        set({ showKycDialogType: type })
      },
      getCurrencyRate: () => {
        // 必须要能拿到virtualCurrencies数据才往下执行
        const { commonConfig } = useAppStore.getState()
        // console.log('配置参数', commonConfig)
        // const commonConfig = useAppStore((state) => state.commonConfig)
        if (commonConfig.virtualCurrencies && commonConfig.virtualCurrencies.length) {
          get().getExchangeRateFn(
            {
              tradeType: '02', // 交易类型（01:存款 02:取款）
              sourceAmount: 1,
              sourceCurrency: commonConfig.virtualCurrencies.join(';'),
              targetCurrency: get().currencyDisplay,
              amount: 1,
              isFindByCache: 0,
              used: 1
            },
            'cr'
          )
        }
      },
      getExchangeRateFn: async (params, source) => {
        if (!params) return
        getExchangeRate(params).then((res: any) => {
          // console.log('获取 货币比率', res)
          const { data, success } = res || {}
          if (success) {
            'cr' === source ? set({ currencyRate: data.data }) : get().SET_CURRENCY_RATE(data.data[0])
          }
        })
      },
      SET_CURRENCY_RATE: (data, source) => {
        if (!data) return
        const { currency } = get().currency || {}
        // console.log('当前汇率', data, source)
        if (source === 'ws') {
          if (!get().isLogin) return // WS退出登录后未断开仍一直推送；如未登录，不储存数据;
          // 如果来源是WS，需要过滤数据 sourceCurrency

          const obj =
            data.filter((item) => item.sourceCurrency === currency && item.targetCurrency === 'USDT')[0] || {}
          // WS推送的数据结构，使用realRate，rate以后将废弃；
          // {
          //   rate: 63160
          //   realRate: 63160
          //   sourceCurrency: "BTC"
          //   targetCurrency: "USD"
          // }
          // console.log('读取推送数据', obj)
          if (obj.realRate) {
            set({
              currencyRateFooter: { ...obj, ...{ targetAmount: obj.realRate } }
            })
          }
        } else {
          set({ currencyRateFooter: data || {} })
        }
      },
      getRakebackInfo: () => {
        // 洗码功能隐藏 (原项目)
        // _this.$api.rakebackRequest({}).then((res) => {
        //   if (res && res.success) {
        //     this.rakebackInfo = res.data
        //     if (window.timerRakeback) clearInterval(window.timerRakeback)
        //     if (
        //       this.rakebackInfo.rakeBackStatus === 0 &&
        //       this.userInfo.playerLevel < 101 &&
        //       Number(this.rakebackInfo.rakeAmountPendingTotal) >= 10
        //     ) {
        //       this.rakebackInfo.rakebackTimeStr = getRakebackTime()
        //       window.timerRakeback = setInterval(() => {
        //         this.rakebackInfo.rakebackTimeStr = getRakebackTime()
        //       }, 1000)
        //     }
        //   }
        // })
      },
      getRakebackBonus: () => {
        const { t } = useTranslation()
        const { SET_GLOBAL_MSG } = useAppStore.getState()
        if (get().rakebackInfo.rakeBackValidStatus === 0) {
          rakebackReceive({ rakeType: 1 }).then((res: any) => {
            if (res && res.success) {
              // this.getRakebackInfo()
              get().getBonusTaskList()
              // messageApi.success(t('vip.claimSuccess'))
              SET_GLOBAL_MSG({ flag: true, type: 'success', content: t('vip.claimSuccess') })
            }
          })
        }
      },
      // 未登录和已登录获取的活动码不同
      getDepositActivity: () => {
        let params = {}
        if (get().isLogin) {
          params = {
            activityCode: 'LfT3n08PhU', //固定值
            conKey: '8LeN3RGVmv' //固定值
          }
        } else {
          params = {
            activityCode: 'H3YquTRbLN', //固定值
            conKey: 'T9GyWVsQQK' //固定值
          }
        }
        // 首页推送任务(活动相关)
        getHomeTask(params).then((res: any) => {
          // console.log('数据封装', JSON.parse(res.data.conVal))
          if (res.success) {
            set({ depositActivityInfo: res.data })
          }
        })
      },
      setSeonSession: () => {
        const seonCdn = [
          'https://cdn.seondf.com/js/v6/agent.umd.js',
          'https://cdn.deviceinf.com/js/v6/agent.umd.js',
          'https://cdn.seonintelligence.com/js/v6/agent.umd.js'
        ]
        const jsTag = document.createElement('script')
        // console.log('脚本tag', jsTag) 取的seonCdn第1个
        jsTag.src = seonCdn[0] || ''
        document.body.appendChild(jsTag)
        jsTag.onload = async () => {
          const { commonConfig } = useAppStore.getState()
          // const commonConfig = useAppStore((state) => state.commonConfig)
          const sessionId = Md5.hashStr(get().webToken.access_token)
          if (typeof window !== undefined) {
            window.seon.init()
            // console.log('window seno', window)
            const seonConfig = { dnsResolverDomain: 'seondfresolver.com' }
            const session = await window.seon.getSession(seonConfig)
            // const session = (await global?.window) && window.seon.getSession(seonConfig)
            seonSession({
              merchantCode: commonConfig.merchantCode,
              session: session,
              sessionId: sessionId
            })
          }
        }
      },
      /* 登出 */
      userLogout: (jumpURL = '') => {
        console.log('登出', jumpURL)
        // const router = useRouter()
        const { SET_ROUTER_PUSH } = useAppStore.getState()
        // const useRouterDirect = useRedirect()
        logoutApi().then((res) => {
          console.log('用户登出', res)
          track('LogoutSuccess')
          get().clearUserInfo()
          // useRouterDirect(jumpURL || '/')

          SET_ROUTER_PUSH(jumpURL || '/')

          // _this.$router.push(jumpURL || '/')
          // 待处理
          // router.replace('/')
          // setTimeout(() => {
          //   location.reload()
          // }, 300)
        })
        // .catch((err) => {
        //   console.log('logout fail', err)
        // })
      },
      clearUserInfo: () => {
        set({
          userInfo: {},
          currency: { currency: 'JPY', amount: 0 },
          bonusCouponItem: {},
          freeSpinWallet: {},
          kycStatus: { level1: 2, level2: 2, level3: 2, level4: 2 } // 重置kyc状态
        })
        // set({ kycStatus: { level1: 2, level2: 2, level3: 2, level4: 2 } }) // 重置kyc状态
        get().setUserLogin(false)
        get().setWebToken('')
        localStorage.removeItem('userInfo')
      },
      /* 登录安全校验信息 */
      SET_SAFE_LOG_INFO: (data) => set({ safeLogInfo: data }),
      setGameCurrency: (data) => set({ gameCurrency: data }),
      getBonusTaskList: () => {
        // console.log('打开奖励前 查看用户是否登录', get().isLogin)
        if (!get().isLogin) return
        //bonusType 0: 优惠金 4：前置优惠卷 5：后置优惠卷
        getBonusList({ pageNo: 1, pageSize: 200, bonusTypeList: [0, 4, 5] }).then(async (res: any) => {
          console.log('BonusLis', res)
          if (res && res.success) {
            const freeSpin = await get().queryFreeSpin()
            const sourceData = res.data.data || []
            const dataArr = []
            const bonusdataArr = []
            const doneBonusAndTask = []
            let tar = ''
            sourceData.forEach((item) => {
              if (item.bonusStatus === 3 || item.bonusStatus === 5 || item.bonusStatus === 10) {
                dataArr.push(item)
                if (Number(item.amount) > 0 && item.bonusType !== 5) {
                  bonusdataArr.push(item)
                  if (item.requestId === get().bonusCouponItem?.requestId) {
                    tar = item
                  }
                }
              }
              if (item.status === 2) {
                freeSpin.forEach((val) => {
                  if (val.refBonusId === item.requestId) {
                    item.freeSpinTimes = val.roundNum
                  }
                })
                dataArr.push(item)
              } else {
                doneBonusAndTask.push(item)
              }
            })
            // console.log('读取 sourceData', sourceData, tar)
            set({ doneBonusAndTask: doneBonusAndTask, bonusAndTask: dataArr })
            if (tar) {
              get().SET_BONUS_COUPON_ITEM(tar)
            } else {
              if (bonusdataArr.length > 0) {
                get().SET_BONUS_COUPON_ITEM(bonusdataArr[0])
              } else {
                get().SET_BONUS_COUPON_ITEM({})
              }
            }
            set({ availableBonusTask: freeSpin.length + get().bonusAndTask.length })
          }
        })
      },
      queryFreeSpin: async () => {
        return new Promise((resolve) => {
          //0=待审核,1=已派发,2=已拒绝,3=已撤回,4=等待领取(手动领取类型)
          getFreeSpinList({
            pageSize: 200,
            bizCheckStatusList: [4],
            useExpiredBeginDate: dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss')
          }).then((res: any) => {
            if (res && res.success) {
              // console.log('读取免费旋转', res)
              const data = res.data || []
              set({ freeSpinList: data })
              resolve(get().freeSpinList)
            }
          })
        })
      },
      updatePageVisible: (data) => set({ pageVisible: data }),
      getVipInfo: () => {
        queryDetailLogined().then((res: any) => {
          if (res.success) {
            set({ vipInfo: res.data })
          }
        })
      },
      updateJpyToUsd: (data) => set({ jpyToUsd: data }),
      getSignDetail: () => {
        signDetail({ activityCode: 'bFZwyXBQna' }).then((res: any) => {
          if (res.data && res.data.flag === 1) {
            set({ signDetail: res.data })
            if (res.data.signFlag && get().kycAuditStatus > 0) {
              signApplyAign({ activityCode: 'bFZwyXBQna' })
            }
          }
        })
      },
      getMissionTagsList: () => {
        taskQueryTaskTags({}).then((res: any) => {
          // console.log('读取 availableMission 详情', res)
          if (res && res.success) {
            // missionTagsList = res.data || []
            set({ missionTagsList: res.data || [] })
            let num = 0
            // 改写原forEach
            // const updateMissiondata = get().missionTagsList.map((el: any) => {
            //   num += el.availablePrizeCount
            // })
            const updateMissiondata = [...get().missionTagsList]
            updateMissiondata.forEach((el: any) => {
              num += el.availablePrizeCount
            })
            set({ missionTagsList: updateMissiondata })
            set({ availableMission: num })
          }
        })
      },
      getSumFullLifecycle: () => {
        sumFullLifecycle({}).then((res: any) => {
          if (res.success) {
            set({ statisticsInfo: res.data })
          }
        })
      },
      setHasHydrated: (state) => {
        set({
          _hasHydrated: state
        })
      }
    })),
    {
      name: 'userStore', // name of the item in the storage (must be unique)
      // storage: localStorage,
      storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
      // 指定需要存储的字段
      partialize: (state) => ({
        userInfo: state.userInfo,
        isLogin: state.isLogin,
        webToken: state.webToken,
        fingerPrintID: state.fingerPrintID,
        currency: state.currency,
        homeDepositType: state.homeDepositType,
        hideZero: state.hideZero,
        currencyDisplay: state.currencyDisplay,
        moneyTypeSelectIndex: state.moneyTypeSelectIndex,
        bonusCouponItem: state.bonusCouponItem,
        freeSpinWallet: state.freeSpinWallet,
        jpyToUsd: state.jpyToUsd,
        currencyRate: state.currencyRate,
        currencyRateFooter: state.currencyRateFooter,
        kycAuditStatus: state.kycAuditStatus,
        kycStatus: state.kycStatus,
        _hasHydrated: state._hasHydrated
      }),
      // 合并默认值
      merge: (persistedState, currentState) => ({ ...currentState, ...(persistedState as IUserStroe) }),
      onRehydrateStorage: (state) => {
        return () => state.setHasHydrated(true)
      }
      // skipHydration: true // 跳过水合
    }
  )
)
