'use client'
import { useAppStore } from '@/stores/app'
import { cn } from '@/lib/utils'
import React, { useMemo, useState, useRef, memo, FC, useEffect } from 'react'
import { useTranslation } from '@/lib/locales/client'
import { useShallow } from 'zustand/react/shallow'
import './index.scss'
import { Modal } from 'antd'

type IProps = {
  hideDialog: () => void
}

const TeachingDialog: FC<IProps> = (props) => {
  const { isBigScreen, language } = useAppStore(
    useShallow((state) => ({
      isBigScreen: state.isBigScreen,
      language: state.language
    }))
  )
  const { t } = useTranslation()

  const [showCloseButton, setShowCloseButton] = useState(false)
  const [showCloseButton2, setShowCloseButton2] = useState(false)
  const [countDown, setCountDown] = useState(5)

  let timer: any
  const countDownTimer = () => {
    setShowCloseButton2(true)
    setShowCloseButton(false)
    timer = setInterval(() => {
      setCountDown((prevCount) => prevCount - 1)
    }, 1000)
  }

  const clearTimer = () => {
    setCountDown(5)
    clearInterval(timer)
    timer = null
  }

  useEffect(() => {
    if (countDown == 3) {
      setShowCloseButton(true)
    }
    if (countDown == 0) {
      setShowCloseButton2(false)
      clearTimer()
    }
  }, [countDown])

  useEffect(() => {
    countDownTimer()
    return () => {
      clearTimer()
    }
  }, [])

  return (
    <Modal
      className="bank-tips-dialog"
      open={true}
      centered={true}
      maskClosable={false}
      closable={false}
      footer={null}
    >
      <div className="verify_model">
        <div className="top_model">
          {showCloseButton && (
            <img
              onClick={() => {
                props.hideDialog()
              }}
              className="delete_x"
              src="/static/images/myAccount/verification/delete_x.png"
            />
          )}

          <img className="left_arrow" src="/static/images/myAccount/verification/model_left_arrow.png" />
          <div className="middle_div">
            <img src="/static/images/deposit/tips-icon.png" />
          </div>
        </div>
        <div className="content_main">
          <p dangerouslySetInnerHTML={{ __html: t('deposit.bankTipTitle') }}></p>
          <p className="title2" dangerouslySetInnerHTML={{ __html: t('deposit.bankTipMain') }}></p>
          <img src="/static/images/deposit/screenshot-en.png" />
          {showCloseButton2 && (
            <p className="title2">
              {t('deposit.confirmAgain')}
              <span>{countDown}s</span>
            </p>
          )}
          {showCloseButton && (
            <div
              className="del_btn"
              onClick={() => {
                props.hideDialog()
              }}
            >
              {t('deposit.iSee')}
            </div>
          )}
        </div>
      </div>
    </Modal>
  )
}
export default memo(TeachingDialog)
