import { post } from "./config/config"

//取款
export const withdrawCreate = (params: any) => post("/api/v1/withdraw/create", params)

//取消取款
export const cancelWithdraw = (params: any) => post("/api/v1/withdraw/cancel", params)

//获取商户存款渠道列表
export const getDepositChannel = (params: any) => {
  return post("/api/v1/payment/get_payment_channel", params)
}

//创建银行卡法币存款
export const createBankDeposit = (params: any) => post("/api/v1/deposit/create_online_banks", params)

//创建信用卡存款
export const createCreditDeposit = (params: any) => post("/api/v1/deposit/createCreditCardPayment", params)

//查询存款信息
export const queryOnlineBanks = (params: any) => post("/api/v1/deposit/query_online_banks", params)

//创建存款信息 新接口
export const createQrCodePayment = (params: any) => post("/api/v1/deposit/createQrCodePayment", params)

//获取法币买虚拟币
export const getPayTypeList = (params: any) => post("/api/v1/common/get_pay_type_list", params)

//資金支付方式渠道 ，傳值為 query ，查詢多個渠道用 ; 隔開
export const getPaymentVega = (params: any) =>
  post("/api/v1/deposit/getPaymentAllChannel", params, { hideLoading: true })

//获取存款渠道明细
export const getDepositCurrencyList = (params: any) =>
  post("/api/v1/common/get_deposit_currency_list_V2", params, { hideLoading: true })

//获取虚拟币存款渠道明细
export const createVirtualDeposit = (params: any, config: any = {}) =>
  post("/api/v1/deposit/create", params, { hideLoading: true, ...config })

//查询指定币种兑换汇率
export const getExchangeRate = (params: any, config: any = {}) =>
  post("/api/v1/wallet/cache_rate/query_list", params, { hideLoading: true, ...config })

//获取当前取款信息
export const queryWithdrawMethod = (params: any) => post("/api/v1/withdraw/queryWithdrawMethod", params, { hideLoading: true })

//获取存款限额
export const getDepositLimit = (params: any) => post("/api/v1/deposit/queryLimitList", params, { hideLoading: true })

//获取当前取款配置信息
export const getWithdrawConfig = (params: any) => post("/api/v1/withdraw/query_withdraw_config", params)

//根据存款渠道获取任务
export const getTaskByChannel = (params: any) => {
  return post("/api/phoenix/prom/task/deposit/getByChannel", params, { hiddeErr: true, hideLoading: true })
}

//获取存取款限额
export const getTransLimit = (params: any) => {
  return post("/api/v1/common/trans_limit_config", params, { hideLoading: true })
}

//预计任务获得奖金
export const getEstimateReward = (params: any) => {
  return post("/api/phoenix/prom/task/estimateReward", params, { hideLoading: true })
}


//根据币种获取用户银行卡列表
export const getBankcardConfig = (params: any) => {
  return post("/api/ext/v1/bank_card/list", params)
}

//新增用户银行卡
export const addBankCard = (params: any) => {
  return post("/api/ext/v1/bank_card/add", params)
}

//删除用户银行卡
export const deleteBankCard = (params: any) => {
  return post("/api/ext/v1/bank_card/delete", params)
}

//创建虚拟币钱包
export const createVirtualWallet = (params: any) => {
  return post("/api/v1/player/wallet_address/create", params)
}

//根据登录名查询玩家钱包地址信息
export const getVirtualCardList = (params: any) => {
  return post("/api/v1/player/wallet_address/query_by_login_name", params)
}

//删除玩家钱包地址接口
export const deleteVirtualCard = (params: any) => {
  return post("/api/v1/player/wallet_address/delete", params)
}

// 查询用户银行卡存款成功率
export const depositSuccessRate= (params: any) => {
  return post("/api/v1/deposit/querySuccessRate", params)
}

// 获取充值 新接口
export const queryPaymentList= (params: any) => {
  return post("/api/v1/paymentChanel/query_payment_list", params)
}

