export const stat = {
  // export const static = {
  about: {
    title: 'About QUEEN CASINO',
    subTitle: [
      `QUEEN CASINO is an exciting "Online Gaming Website" with all-inclusive elements and brings fun to you. Our aim is to become the world's most stylish, avant-garde and fun-filled "Online Gaming Website"!`
    ],
    content: [
      {
        dd: `
                <p>QUEEN CASINO has years of gaming industry experience and provide personal services by our professional staff. You can find the most compelling popular live baccarat, slot machines, blackjack, Caribbean poker, roulette, R&G Poker and etc. here. With our best reward program and most convenient payment plan, you will enjoy our games and services.</p>
                <p>Every registered QUEEN CASINO's will have a corresponding user account. Periodic security checks will be performed from time to time. The most advanced gaming software will be adopted in order to ensure the security and for 24 hours detection and monitor. Customers can have fun at our most secure online gaming website.</p>
                <p>There are a variety of deposits and withdrawals choices for customers selection. We link up with third-party financial regulatory authorities to ensure compliance with relevant laws and regulations. Customers can make a profit by enjoying our well-designed online games and services under a highly secured system.</p>
                <p>Customers advice and comments are very important to us. If you have any questions or suggestions, please feel free to contact us via e-mail <a href="mailto:support@queen-casino.com">support@queen-casino.com</a>.</p>
                <p></p>
            `
      }
    ],
    footer: `
        <p>If you have not yet become our member, please <a class="go-register">Register Now</a> and start the wonderful games that we have prepared for you. </p>
    `
  },
  security: {
    title: 'Security',
    subTitle:
      "After logging in, all information sent to and from QUEEN CASINO is encrypted using 256-bit Secure Socket Layer (SSL) technology. Your credit card details are encrypted and only sent over the Internet once to QUEEN CASINO. They are then stored encrypted in QUEEN CASINO's secure systems.",
    content: [
      {
        dt: 'Products',
        dd: `<p>To ensure integrity, our products work with a Random Number Generator (RNG) which, as the name suggests, randomly generates numbers. This guarantees that there is no manipulation whatsoever, by the company or by the customer. We do this out of respect to our customers and to comply with strong international standards.</p>`
      }
    ]
  },
  sportRules: {
    title: 'Sports Betting Rules',
    subTitle:
      "These rules are the terms and conditions of the bets QUEEN CASINO accept. All bets accepted by QUEEN CASINO will be settled under these rules. Included in the rules are the details if customer makes an error placing a bet or the error is in QUEEN CASINO website or system. It is customer's responsibility to know these rules before placing a bet to avoid misinformation.",
    content: [
      {
        dd: `
          <h3 style="color: #fc005b">General Rule（Premier version）</h3>
          <h3 class="fg">•\tGeneral</h3>
            <ul>
             <li>⚬\tGeneral settlement rules apply for all sports if not stated in sport specific rules otherwise: </li>
             <li>⚬\tIf the outcome of a market cannot be verified officially, we reserve the right to delay the settlement until official confirmation;</li>
             <li>⚬\tIf a match is interrupted but subsequently resumed (from the point in the match at which it was interrupted) and played to a finish within 48 hours of the original scheduled kick off time, all bets will stand on the fixture;</li>
             <li>⚬\tOtherwise if the match is resumed but not finished within 48 hours of the original scheduled kick off time, this will be treated as an abandonment and bets on the original fixture will be void, except for those bets the outcome of which had already been determined, prior to the initial interruption in fixture;</li>
             <li>⚬\tSpecifically for the 3x3, Streetball and Big3 match versions of the sport, settlement will be based on official competition rules. Where a match starts but is not completed, bets will be void unless the specific market outcome is already determined;</li>
             <li>⚬\tIn 2-Way markets Push rules apply unless otherwise stated below. Stakes on single bets are returned, and in multiples/parlays the selection is treated as a non-runner.</li>
             <li>⚬\tIf markets were offered when the outcome was already known, we reserve the right to void any betting.</li>
             <li>⚬\tIf it is not an obvious mistake in the name then bets will stand.</li>
             <li>⚬\tIn the case of any obviously incorrectly displayed or calculated prices, we reserve the right to void betting. This includes a deviation of more than 100% in the pay-out compared to the market average.</li>
             <li>⚬\tFor all matches that are played as part of a two-legged tie. Common for knockout tournaments where teams need to play each other twice to determine the winner. If in this case the aggregate score is equal at the end of regulation time in the 2nd leg. Overtime will be included to determine the outcome of 2nd leg match bets.</li>
             <li>⚬\tIf coverage of the match is stopped or removed and the match finishes regularly, all markets will be settled according to the final result. If the outcome of a market cannot be verified officially, we reserve the right to void them.</li>
             <li>⚬\tIn the case of an incorrect settlement of markets, we reserve the right to correct them anytime.</li>
             <li>⚬\tIf a match does not adhere to the generally accepted format (e.g. unusual period length, counting procedure, format of a match etc.); we reserve the right to void any market.</li>
             <li>⚬\tAll markets are considered for regular time only unless otherwise stated.</li>
             <li>⚬\tOutright markets are classified as "All In Run Or Not," meaning they will be settled as a loss if the selected participant does not participate in the event. In the event of multiple winners, Dead Heat rules will be applied.</li>
             <li>⚬\tWhen bets are placed on In-Play Asian Handicap, match Scoreline is treated as 0:0 at the time of the placement. This applies only for soccer and eSoccer.</li>
             <li>⚬\tVideo assistant referee (VAR) – If we have settled a bet and, due to a subsequent VAR decision, it becomes apparent that such settlement was incorrect, we reserve the right to reverse such settlement (provided that the VAR decision occurred prior to the conclusion of the match or other time frame relating to the bet). Bets which are placed between the occurrence of an incident which leads to a VAR review and the related VAR decision will be deemed void unless the VAR review (and subsequent decision) did not ultimately alter the decision made by the on-field officials or the VAR review (and subsequent decision) altered the decision made by the on-field officials but did not have any material influence on the bet(s) in question. All bets which were not influenced by the VAR review will stand.</li>
             <li>⚬\tAll player selections will be considered active if a player enters the field/court/rink etc. as an active player at any point of the match, regardless of how long he plays.</li>
          </ul>
          <h3>•\tSoccer</h3>
          <ul>
            <li>⚬\tAll markets (except halftime, first half markets, overtime and penalty shoot out) are considered for regular time only unless otherwise stated.</li>
            <li>⚬\tIf a match is interrupted and continued within 48h after initial kickoff, all open bets will be settled with the final result. Otherwise, all undecided bets are considered void.</li>
            <li>⚬\tRegular 90 Minutes: Markets are based on the result at the end of a scheduled 90 minutes play unless otherwise stated. This includes any added injury or stoppage time but does not include extra-time, time allocated for a penalty shootout or golden goal.</li>
            
          </ul>
          <h3>•\tSettlement and cancellation rules:</h3>
          <ul>
            <li>⚬\tIf the market remains open when the following action have already taken place: goals, red or yellow-red cards and penalties, we reserve the right to void betting.</li>
            <li>⚬\tIn case when match gets abandoned before the final whistle all undecided markets will be declared void.</li>
            <li>⚬\tIf a match is interrupted or postponed and is not continued within 48h after initial kick-off date betting will be void.</li>
            <li>⚬\tIf the team names or category are displayed incorrectly, we reserve the right to void betting. If it is not an obvious mistake in the name then bets will stand.</li>
            <li>⚬\tStatistical data or editorial texts published on the sportsbook are for information and entertainment purposes only, and not for decision making. There are no warranties for the correctness of such information or data. The sportsbook shall not acknowledge or accept any liability whatsoever regarding any decisions taken by an End User. At all times it is the End User’s responsibility to be aware about circumstances relating to an Event or Market.</li>
            <li>⚬\tIt is forbidden to use any automated systems (any kind of scanners, robots etc.) on the sportsbook. The sportsbook reserves the right to cancel any Bet which is made using automated systems of any kind.</li>
            <li>⚬\tIt is forbidden to use multiple or third party accounts for the purpose of use of services offered on the sportsbook. Bets made in contrary to the terms of this clause shall be void.</li>
            <li>⚬\tOffering of the Events and Markets available on the sportsbook may change from jurisdiction to jurisdiction, as offering of the sportsbook at all times is subject to applicable law.</li>
            <li>⚬\tSportsbook uses only reputable and legitimate data source(s) for determination of the outcome of a bet. Unless the data sources are not expressly disclosed in these betting rules, the data source(s) may be disclosed to an End User upon request.</li>
            <li>⚬\tIn case of any contradictions: Market Rules take precedence over Sport Rules. Sport Rules take precedence over General Rules. In case there are no specific Market Rules or Sport Rules, General Rules apply.</li>
            <li>⚬\tAn “X” included in a description of an odds or a market of these terms shall be understood as a number defined in the sportsbook offering.</li>
          </ul>
          <h3>•\tMatch Abandonments - Live:</h3>
          <ul>
            <li>⚬\tOfficial match abandoned before the completion of 90 minutes play will be void except for those tickets the outcome of which has already been determined at the time of abandonment.</li>
            <li>⚬\tFriendly match abandoned before the completion of 80 minutes play will be void except for those tickets the outcome of which has already been determined at the time of abandonment.</li>
          </ul>
          <h3>•\tBooking markets:</h3>
          <ul>
            <li>⚬\tYellow card counts as 1, red card counts as 2. Second yellows are ignored for settlement purposes (e.g. maximum card count per player is 3).</li>
            <li>⚬\tSettlement will be made according to all available evidence of cards shown during the regular 90 minutes play.</li>
            <li>⚬\tCards shown after the match are not considered.</li>
            <li>⚬\tCards for non-players (already substituted players, managers, players on bench) are not considered.</li>
          </ul>
          <h3>•\tCorner Markets:</h3>
          <ul>
            <li>⚬\tCorners awarded but not taken are not considered.</li>
          </ul>
          <h3>•\tNext Goalscorer:</h3>
          <ul>
            <li>⚬\tOwn goals will not be considered for Next Goalscorer settlement purposes and are ignored.</li>
            <li>⚬\tAll players who took part in the match are considered as runners. If for any reason an unlisted player scores a goal all bets on listed players stand. If a player is listed but does not take part in the match, all related bets made on the player will be considered as void.</li>
            <li>⚬\t Market will be settled based on the official data.</li>
          </ul>
          <h3>•\tInterval Markets:</h3>
          <ul>
            <li>⚬\tMarkets will be settled based on the goal time announced by TV. If this is not available, the time according to the match clock is considered.</li>
            <li>⚬\tGoal Markets are settled based on the time the ball crosses the line, and not the time the kick is made.</li>
            <li>⚬\tCorner interval markets are settled based on the time the corner kick is taken and not the time the corner is conceded or awarded.</li>
            <li>⚬\tBooking interval markets are settled based on the time the card is shown and not the time the infringement is made.</li>
            <li>⚬\tOffsides will be settled based on the time when the referee gives the decision. This rule will be applied on any video assistant referee (VAR) situation.</li>
            <li>⚬\tPenalty markets will be settled based on the time when the referee gives the decision. This rule will be applied on any video assistant referee (VAR) situation. Penalties awarded but not taken are not considered.</li>
            <li>⚬\tFor all 5 min/15 min interval markets, related to interval minutes 41-45/31-45, events (goals, corners) in added injury or stoppage time will be accounted for.</li>
            <li>⚬\tEvent must happen between 0:00 and 09:59 to be classed in the first 10 minutes (e.g. a time period 11 to 20 minutes is 10:00 to 19:59). The designated 10 minute period must be completed for the bet to stand (unless the outcome of the specific market is already determined).</li>
            <li>⚬\tWill A Goal Be Scored Between xx-xx Mins?.</li>
            <li>⚬\tAny goals in injury time count towards the bet. For example goal scored on 90+ minute added time counts towards the 76 - 90 minutes range.</li>
          </ul>
          <h3>•\tAsian Handicap:</h3>
          <ul>
            <li>⚬\tBasic Concept: Asian handicap betting aims to level the playing field between two teams by giving one team a hypothetical advantage or disadvantage before the game starts. This is done by applying a handicap to the final result of the match.</li>
            <li>⚬\tHandicap Values: Asian handicap bets typically involve quarter goals (0/+0.5. 0/-0.5), whole goals (+1, -1), and half goals. For example, a team might have a handicap of -0.5, -1.0, -1.5, etc., which means they need to win by a certain margin for the bet to win. Conversely, a team might have a handicap of +0.5, +1.0, +1.5, etc., which means they can lose by a certain margin or even draw for the bet to win.</li>
            <li>⚬\tOutcome Scenarios:</li>
            <li>⚬\tWin:If you bet on a team with a negative handicap and they win by more than the handicap value, your bet wins. Likewise, if you bet on a team with a positive handicap and they win, draw, or lose by less than the handicap value, your bet wins.</li>
            <li>⚬\tLoss: If you bet on a team with a negative handicap and they draw or lose, your bet loses. Similarly, if you bet on a team with a positive handicap and they lose by more than the handicap value, your bet loses.</li>
            <li>⚬\tPush: If the actual result matches the handicap exactly, your stake is refunded.</li>
            <li>⚬\tHalf won: This outcome occurs when you place a handicap bet, and half of your bet is deemed a winner. Possible with quarter handicaps.</li>
            <li>⚬\tHalf lost: Is the outcome where only half of your bet is lost. Possible with quarter handicaps.</li>
          </ul>
          <h3>•\tQuarter Handicap:</h3>
          <ul>
            <li>⚬\tIf you bet on the favorite (e.g., Team A -0.25):</li>
            <li>⚬\tIf Team A wins, you win the full amount of your bet.</li>
            <li>⚬\tIf the match ends in a draw, you'll get half of your stake back, and the other half will be considered a loss.</li>
            <li>⚬\tIf Team A loses, you lose the entire bet.</li>
            <li>⚬\tIf you bet on the underdog (e.g., Team B +0.25):</li>
            <li>⚬\tIf Team B wins or the match ends in a draw, you win the full amount of your bet.</li>
            <li>⚬\tHalf Handicap:.</li>
            <li>⚬\tIf you bet on the favorite (e.g., Team A -0.5):</li>
            <li>⚬\tTeam A must win for you to win the bet.</li>
            <li>⚬\tIf the match ends in a draw or Team A loses, you lose the bet.</li>
            <li>⚬\tIf you bet on the underdog (e.g., Team B +0.5):</li>
            <li>⚬\tTeam B must either win or draw for you to win the bet.</li>
            <li>⚬\tIf Team B loses, you lose the bet.</li>
          </ul>
          <h3>•\tWhole Handicap:</h3>
          <ul>
            <li>⚬\tSettlement for whole handicaps is simpler</li>
            <li>⚬\tIf you bet on the favorite (e.g., Team A -1):</li>
            <li>⚬\tTeam A must win by more than one goal for you to win the bet.</li>
            <li>⚬\tIf Team A wins by exactly one goal, it's a push (your stake is refunded).</li>
            <li>⚬\tIf Team A draws or loses, you lose the bet.</li>
            <li>⚬\tIf you bet on the underdog (e.g., Team B +1):</li>
            <li>⚬\tTeam B must either win or draw, or lose by less than one goal for you to win the bet.</li>
            <li>⚬\tIf Team B loses by exactly one goal, it's a push (your stake is refunded).</li>
            <li>⚬\tIf Team B loses by more than one goal, you lose the bet.</li>
            <li>⚬\tIf Team B loses by exactly one goal, it's a push (your stake is refunded).</li>
            <li>⚬\tBenefits: Asian handicap betting eliminates the possibility of a draw outcome, providing more betting options and potentially higher winning probabilities..</li>
            <li>⚬\tIf Asian Handicap bets are placed In-Play, goals that have been awarded prior to bet placement are discounted for settlement purposes. The scoreline is treated as 0-0 at the time of bet placement</li>
          </ul>
`
      },
      {
        dd: `
          <h3>
            <a href="/file/sportRules_common.pdf"  target="_blank" style="color: #fc005b">Full rules（Premier version）</a>
          </h3>`
      },
      {
        dd: `
          <h3 style="color: #fc005b">General Rule（Classic version）</h3>
          <ul>
            <li>•\tThese rules are intended to define the terms and conditions that apply to all bets accepted by us. It is your responsibility to make yourself aware of these rules and the terms upon which your bets are accepted. We reserve the right to amend these rules at any time and any such amendments will then be displayed on this website. Please note that any additional information detailed in the “Announcements” section forms part of the betting rules. In the event of any disparity, please note that any such additional information will supersede our betting rules.</li>
            <li>•\tWe consider these rules to be fair and should you require any advice regarding these rules please contact our customer service team. Our team is there to help you and it is in everybody's interest to ensure that queries and disputes are settled as quickly and amicably as possible. The customer service team endeavours to provide prompt and considerate attention to errors and disputes.</li>
            <li>•\tAll these rules serve only to establish the general principles that regulate the relationship between the member and the company. In the event of any disagreement it is not the intention of the company to enter into a dispute with the member, but rather to find a solution, whenever possible, for the mutual satisfaction of both parties.</li>
            <li>•\tAll bets placed with the company are subject to the following rules and regulations. Any rules listed within individual sports supersede these rules for that particular sport or market:</li>
            <li>•\tMinimum and maximum bet amounts on all sporting events will be determined by the company and are subject to change without prior written notice.</li>
            <li>•\tThe company relies entirely upon the information provided by the customer within their betting account. The company is not responsible for any form of misrepresentation by the customer.</li>
            <li>•\tWhen betting, members are solely responsible for their own account transactions. Please be sure to review your bets for any mistakes before submitting. Once a transaction is complete, it cannot be changed. The company does not take responsibility for missing or duplicate bets made by the client. Clients may review their transactions in the 'Statement Details' section of the site after each session to ensure all requested bets were accepted.</li>
            <li>•\tIn the case of any complaints, the company does not consider valid prints or receipts, which have not been recorded or stored in the company files.</li>
            <li>•\tThe company reserves the right to close or suspend a customer's account at anytime.</li>
            <li>•\tThe company reserves the right to suspend betting in a market at anytime.</li>
            <li>•\tThe company reserves the right to void any bets struck on an event where the outcome is known. Should a bet be placed at an incorrect price due to a delay in the 'Live' coverage of an event, the bets placed during this period of time will be voided, win or lose.</li>
            <li>•\tInformation relating to an event (e.g. dates, times, scores, statistics, news, red cards, neutral ground details, etc.) are for general information purposes only. The company shall not be liable for the accuracy in the information provided.</li>
            <li>•\tShould a game / event be abandoned, suspended or postponed and fail to resume after 36 hours from the official kick off / start time, the result as it stands will be deemed void and bets will be cancelled, unless stated otherwise in the individual sport’s rules. Certain markets that are unconditionally determined will be settled accordingly. The settlement procedures for these markets are stated in the individual sport’s rules. The company's decision to cancel bets in such an event is final and is regardless of any official decision by the event referee or relevant governing authority. For 'Parlays', the bet will still be considered valid, though the selection within the parlay will be considered void. The payout formula will be calculated as (1) for that particular selection.</li>
            <li>•\tShould a game / event be abandoned the result as it stands will be deemed void and bets will be cancelled, unless stated otherwise in the individual sport’s rules. The decision and definition of abandonment can be made at the company’s discretion.</li>
            <li>•\tShould there be any discrepancies with team names or any other information in other languages, the company will refer to all team names in English.</li>
            <li>he company is not liable, under any circumstances whatsoever, for any discrepancies, damages or loss caused by any of the following incidents:
              <ul>
                <li>⚬\tDisruption of service to the company's website(s), server(s) or network.</li>
                <li>⚬\tLoss of data or damaged data on the company's server(s).</li>
                <li>⚬\tMalicious attack on the company's website(s), server(s) or network by hackers.</li>
                <li>⚬\tSlow or irregular internet service while accessing the company's website.</li>  
              </ul>
            </li>
            <li>•\tIf there is any dispute over the interpretation of any rules or regulation, the company's interpretation will always take precedence.</li>
          </ul>`
      },
      {
        dd: `
          <h3>RESULTS AND SETTLEMENT</h3>
          <ul>
            <li>•\tThe result of an event will be determined on the day of its conclusion for betting purposes unless otherwise stated within the rules for each sport or event. The company does not recognize protests or overturned decisions for betting purposes. The company will only reset / correct the results due to human error, system error or mistakes made by the referring results source within 36 hours after results are processed.</li>
            <li>•\tMarkets are usually settled at the end of the event. However, in the interest of the member, some markets may be settled before the official result is declared. These are markets that are unconditionally determined and can include certain markets for events that are abandoned, suspended or postponed. The company reserves the right to reverse settlement in the event a market is settled in error.</li>  
            <li>•\tResults will be determined by the company using, where possible, official sources from each sport’s governing body.</li>
            <li>•\tFor the purpose of settling bets, the company shall take the latest back-up data on bets placed. The company will not entertain any complaints or disputes, unless the customer can produce a printed copy of the betting transaction(s). Otherwise, the company’s transaction record shall be deemed as final.</li>
          </ul>`
      },
      {
        dd: `
          <h3>IN-PLAY / IN-RUNNING RULES</h3>
          <ul>
            <li>•\tIn-Play is where it is possible to bet during an ongoing game or event. Bets will be accepted once the game or event begins and will cease once the game or event is taken off the odds display. A variety of different markets can appear during the duration of a game.</li>
            <li>•\tAll bets placed In-Play are subject to a system acceptance procedure. This may result in a delayed confirmation (or rejection) of each bet.</li>
            <li>•\tThe word “Confirming” will be shown on the bet receipt of any bet being processed by the system. This means that the bet has not been confirmed or rejected yet.</li>
            <li>•\tConfirming bets will be rejected should a significant event occur during the time of bet placement. These events may include, but are not limited to, a goal, red card, penalty or technical malfunction.</li>
            <li>•\tVideo Assistant Referee (VAR) decisions made after a significant event occurs, may result in confirmed bets being voided. Bets voided will be from the time of the significant event, until the time the final VAR decision was officially announced.</li>
            <li>•\tIn the case of In-Play, the company will enforce the following conditions in order to ensure that bets are placed at the correct time, at the correct price and under the correct circumstances, as determined by us:
              <ul>
                <li>⚬\tThe time in which a goal / point is scored is determined solely by the company. We will not entertain any references as to the time that a goal / point is scored as stated in official league / club websites, media websites or “live score” websites.</li>
                <li>⚬\tIf there are reasonable grounds to suspect that a bet (or bets) has been placed after an event has taken place in the game / event, we reserve the right to cancel such bet(s) without having to provide any reason or burden of proof.</li>
                <li>⚬\tIn the event that the company website cannot be accessed to update the score, odds or handicap-goal line, the company reserves the right to cancel all pending bets placed at the time of the incident.</li>
              </ul>
            </li>         
          </ul>`
      },
      {
        dd: `
          <h3>Reservation bet rules</h3>
          <ul>
            <li>•\tReservation betting is a non-instant betting method where the order is conditionally effective. The company reserves the right to provide and withdraw the reservation betting function at any time.</li>
            <li>•\tAll Reservation bets need to comply with the system acceptance procedures and comply with the company's regulations on general betting.</li>
            <li>•\tIf the “In Reservation” is showed on the bet slip, it means that the Reservation bet has been successful but not valid. When the odds of the option reach the Reservation odds, the system will automatically process the bet.</li>
            <li>•\tIf the Reservation bet meets the conditions enters the betting process, and the wallet balance is not enough to complete the bet, the Reservation bet will automatically become invalid and the Reservation bet will not be continued. In order to improve the success rate of the reservation, please ensure that the wallet balance is sufficient.</li>
            <li>•\tIf an Reservation options are settled or cancelled, all bets placed on the relevant Reservation order will be considered void.</li>
            <li>•\tAfter the football score (goal, corner kick or red/yellow card, etc.) changes, if the settlement of the bet in the reservation is related to the score, the bet in the reservation will be set as invalid. For example, when the goal score is 0-0, the reservation bets are goals over 2.5 and corners over 5.5. After the goal score becomes 1-0, the reservation bet goals over 2.5 will be set as invalid, and the reservation bet corners over 5.5 will be set as invalid. The order will continue to be in reservation as normal.</li>
            <li>•\tThe number of Reservation bets that exist simultaneously for a single user is set by the company and may be adjusted at any time.</li>
            <li>•\tYou can modify the reservation stake and odds for the bet that is in the reservation.</li>
            <li>•\tThe rules for accepting odds for Reservation order bets are: accept better odds.</li>  
          </ul>`
      },
      {
        dd: `
          <h3>ISSUES RELATING TO TIMES</h3>
          <ul>
            <li>•\tIf an event starts before its scheduled time, only bets placed before the event starts will be considered valid. All bets placed after the start of the game will be considered invalid or void, except for In-Play bets.</li>
            <li>•\tFor Soccer events where injury or stoppage time is played, this is deemed to be part of regular time.</li>
            <li>•\tUnless otherwise stated in the individual sport rules, overtime scoring does not count towards normal full time.</li>
          </ul>`
      },
      {
        dd: `
          <h3>CHANGE OF VENUE</h3>
          <ul>
            <li>•\tIf a scheduled venue is changed, all bets will be considered void if the original away team is now the home team.</li>
            <li>•\tWe reserve the right to void all bets if we think other change of venues scenarios could affect the outcome of the event, e.g. Tennis change of venue surface.</li>
            <li>•\tIn certain situations, we may not void the bets. This can include tournaments where both selections were originally considered to be playing at a neutral venue and the change of venue has no impact.</li>
            <li>•\tThe individual sport’s rules will override these rules.</li>
          </ul>`
      },
      {
        dd: `
          <h3>ERRORS</h3>
          <ul>
            <li>•\tWhile the company makes every effort to ensure that errors are not made, we reserve the right to void a bet if there is a clear error. This includes, but is not limited to:
              <ul>
                <li>⚬\tIncorrect prices, which are clearly different from those available in the general market.</li>
                <li>⚬\tIncorrect handicaps, Over / Unders, Totals,Lines, Spreads, etc.</li>
                <li>⚬\tIncorrect fixtures, dates and times.</li>
              </ul>
            </li>
            <li>•\tWe will endeavour to contact the customer when we void bets in this instance, if possible. The information will also be relayed in our Announcements section.</li>
          </ul>`
      },
      {
        dd: `
          <h3>IRREGULAR BETS</h3>
          <ul>
            <li>•\tAny member who the company suspects of cheating or damaging our platform will have their bets voided and their account suspended without warning or notification. Abnormal behavior includes the use of any device, automatic device, software, program, etc. to interfere with the operation of this website.</li>
            <li>•\tAccept Better Odds/Accept Any Odds/No odds changes are accepted</li>
            <li>•\tThe function of accepting or not accepting changes in odds can be selected through the “Betting Rules” set by the system.</li>
            <li>•\tThe term “Accept Better Odds” means all selections added to the betslip can be processed automatically, so long as the odds on offer show a higher return than originally indicated at time of selection. If the adjusted odds within the betslip show a lower return value than originally indicated, the bet will not be processed automatically.</li>
            <li>•\t“Accept Any Odds” means all bet slips added to this option, regardless of whether the adjusted bet slip line shows a higher or lower value than the original return, can be processed automatically.</li>
            <li>•\t“No odds changes are accepted” means all bet slips added to this option, as long as the bet slip line changes, the bet will not be processed automatically</li>
          </ul>`
      },
      {
        dd: `
          <h3>Enhanced Price Specials / Multiples (Price Boosts)</h3>
          <ul>
            <li>•\tThe company reserves the right to offer and withdraw all higher odds/multiples bets at any time.</li>
            <li>•\tLimits stake amount for all higher odds bets /multiples bets may be adjusted at any time.</li>
            <li>•\tAll Higher Odds/multiples bets rules are the same as the General Rules and those set out in the respective Sports Rules above. - Football Example - Manchester United to Win and Over / Under 2.5’ will comply with ‘1 X 2 & Goals Over / Under’ Football Rules.</li>
            <li>•\tThese soccer markets are offered higher Odds/multiples bets on the basis of regular time and do not include Extra Time or Penalty Shootout (unless otherwise stated)</li>
            <li>•\tFor multiples bets (Price Boosts), if an event is suspended when the result is unconditionally determined, then the bet will be settled accordingly as ‘Win’ or ‘Lose’. - Football Example: If you select Over 2.5 Goals and the score is 2-1 at the time of suspension, then the selection will be considered a ‘Win’.</li>
            <li>•\tFor multiples bets(Price Boosts), if an event is suspended when the result is undetermined, then the bet will be ‘Void’. - Football Example: If you select Correct Score 2-2 (at Full-Time) and the score is 1-1 at the time of suspension, then the bet will be ‘Void’.</li>
            <li>•\tFor multiples bets(Price Boosts), the odds will be readjusted should one or more selections be 'draw' or ‘Void’.</li>
          </ul>`
      },
      {
        dd: `
          <h3>
            <a href="/file/sportRules_en.pdf"  target="_blank" style="color: #fc005b">Full rules（Classic version）</a>
          </h3>
          <ul>
            <li>※ English version is prioritized on the site.</li>      
            <li>※ Last Updated：January 12, 2024</li>
          </ul>`
      }
    ]
  },
  termsAndConditions: {
    title: 'Terms and Conditions',
    content: [
      {
        dd: `
          <h3 style="margin-top:0px;">1. 	General</h3>
          <p>1.1 	These general terms and conditions defined in this text as "Terms and Conditions" regulate the usage of games and play provided through the brands Queen-Casino and ShinQueen an, which are managed by Zen Estratega SRL (the "Websites").
          <br/ ><br/ >1.2	Zen Estratega SRL, a company registered in Costa Rice on the 16th of February 2024, with address at 400, Calle La Ceiba, San Rafael, Escazú, Province of San Jose, Costa Rica and registered company number 3-102-897673. 
          <br/ ><br/ >1.3 	We call Zen Estratega SRL, "Our Company", "We", "Us", "The Company", and we shall refer to the player as "You" or "the player".
          <br/ ><br/ >1.4 	The current version of this agreement is valid until a new version is published on the 		Websites.
          <br/ ><br/ >1.5 	These Terms and Conditions also apply to all platforms and devices that enable game play of games provided by our company ‘Games’ are defined as Casino and other games available on the Websites.
          <br/ ><br/ >1.6 	"Games", "the Games" or "Game" refers to any of the gaming products offered at the 		Websites.
          <br/ ><br/ >1.7 	Our Company has the right to revise these Terms and Conditions, and they shall be effective from the time they become available on this website. If the Terms and Conditions change, both you and us shall abide to the changes. If you do not agree to the change(s) of these Terms and Conditions, you will not be unable to log in to your account and if there is a positive balance on your account, you will be able to withdraw it. For details, please contact us on support@queen-casino.com chat is available if necessary.
          <br/ ><br/ >1.8	You must fully read and understand these Terms and Conditions before registering. If 		you do not agree to any part of these Terms and Conditions, you shall not continue 		the registration or usage of our websites.
          <br/ ><br/ >1.9	These Terms and Conditions can be applied when confirming and registering necessary information in the registration process of our websites. By registering your account you agree to read, understand, and accept these Terms and Conditions. By using our websites, you agree to accept the current Terms and Conditions.
          <br/ ><br/ >1.10	You understand and agree to be bound by these Terms and Conditions which will be 		revised from time to time.
          <br/ ><br/ >1.11	Although these Terms and Conditions are published in multiple languages, they are 	all based on the same principle and are translated for the purpose of giving 	information to our players. Here, only those versions written in English shall establish a legal relationship between you and the Company. If there are differences between the English version and the non-English version of these Terms and Conditions, the English version shall prevail.
          </p>
          <h3>2. 	Your obligations</h3>
          <p>2.1	You may only open one account on each of the Websites provided by us. The Company has the right to terminate any duplicate accounts.
          <br/ ><br/ >2.2	You are over 18 years of age or comply with a higher minimum legal age, stipulated 		in the jurisdiction of your residence under the laws applicable to you. It is your sole 		responsibility to know the age at which gambling is legal in your country of residence.
          <br/ ><br/ >2.3	You are solely responsible for the account details such as username and password or any other means to access your account at the Websites. In the case there is suspicion that such information should have been compromised you must inform the Company and take proper action to prohibit or prevent any unauthorised access to any part of your account or funds.
          <br/ ><br/ >2.4	You may use the Games on your own behalf and not on the behalf of any other 			person or company.
          <br/ ><br/ >2.5 	You may participate in the Games strictly in your personal non-professional capacity 		for recreational and entertainment reasons only.
          <br/ ><br/ >2.6 	You must be residing in a jurisdiction where gambling is legal. You are not allowed to access the Website and/or register an account from a jurisdiction that prohibits online gambling and/or any other jurisdiction that the Tobique Gaming Commission deems online gambling illegal. Queen – Casino prohibits persons located in (including temporary visitors) in UK, USA, Ontario, New Brunswick, and FATF-sanctioned countries.
          <br/ ><br/ >2.7	You cannot transfer, sell, or pledge your balance or your Account to another person. 	This prohibition includes the transfer of any assets of value of any kind, including but 	not limited to ownership of accounts, winnings, deposits, bets, rights and/or claims in 	connection with these assets, legal, commercial, or otherwise. The prohibition on 	said transfers also includes however is not limited to the encumbrance, pledging, 	assigning, usufruct, trading, brokering, hypothecation and/or gifting in cooperation 	with a fiduciary or any other third party, company, natural or legal individual, 	foundation and/or association in any way shape or form.
          <br/ ><br/ >2.8 	You agree that all information that you give us, such as but not limited to, address and email, during the term of validity of this agreement is complete, true, correct, and that you will immediately notify the Company of any changes of such information or change such details.
          <br/ ><br/ >2.9 	You may only participate in any Game if you have enough funds on your account for 		such participation.
          <br/ ><br/ >2.10 	You may not use funds that are tainted or associated with any illegality or originate 		from any illegal activity or source.
          <br/ ><br/ >2.11 	You are solely responsible for reporting and accounting for any taxes or other fees applicable to you under the relevant laws for any winnings that you receive from the Websites. 
          <br/ ><br/ >2.12 	In relation to deposits and withdrawals of funds into and from your Account, you shall 		only use such credit cards and other financial instruments that are valid and lawfully 		belong to you.
          <br/ ><br/ >2.13 	You understand that by playing Games provided by us, you take the risk of losing 		money deposited into your account.
          <br/ ><br/ >2.14 	You shall not be involved in any fraudulent, collusive, fixing, or other unlawful activity 	in relation to your or third parties’ participation in any of the Games and shall not use any software-assisted methods or techniques or hardware devices for your 	participation in any of the Games. We hereby reserve the right to invalidate any 	betting in the event of such behaviour.
          <br/ ><br/ >2.15 	You may only use the software for your own personal, recreational use in accordance 		with rules and terms and conditions we have established and in accordance with all 		applicable laws, rules, and regulations.
          <br/ ><br/ >2.16 	You shall be courteous to other players using the Websites as well as to support 			personnel employed by the Company and avoid rude or obscene comments.
          </p>
          <h3>3. 	Your account</h3>
          <p>3.1 	For you to be able to deposit money and place bets, you must first register personally 		with us and open an account ("Account").
          <br/ ><br/ >3.2 	You are permitted to open only one account and register it personally. We reserve the 		right to close any multiple Accounts opened in the same name or in any way 			referable to the same person.
          <br/ ><br/ >3.3 	You must enter all mandatory information requested into your registration form all of 	which must be true and correct. It is your sole responsibility to ensure that the 		information you provide is true, complete, and correct. You are hereby notified that 	we carry out verification procedures and that your Account may be blocked for 	access or closed if you are found to supply false or misleading information. You are required to update and keep up to date the mandatory information provided in the registration form in the event such information changes.
          <br/ ><br/ >3.4 	If you notice that you have more than one registered Account, you must notify us 			immediately. Failure to do so may lead to your Account being blocked for access.
          <br/ ><br/ >3.5 	As part of the registration process, you will have to choose your username and 		password for your login into the Websites. It is your sole and exclusive responsibility to ensure that your login details are kept securely. You must not disclose your login 	details to anyone. We are not responsible for any abuse or misuse of your Account by third parties due to your disclosure, whether intentional or accidental, whether 	active or passive, of your login details to any third party.
          <br/ ><br/ >3.6 	You are not allowed to transfer funds from your Account to other players or to receive 		money from other players into your Account, or to transfer, sell and/or acquire, user 		accounts.
          <br/ ><br/ >3.7	The Company reserves the right to refuse or close an Account at its sole discretion, but any contractual obligations already made by the Company shall, without prejudice to any rights available at law to the Company, be honoured accordingly.
          <br/ ><br/ >3.8 	The Company prohibits player collusion and do not allow any kind of robots and programmed devices to participate in gameplay.
          <br/ ><br/ >3.9 	If you wish to close your account, you may do so at any time, by contacting customer 		support in written form. The effective closure of the Account will correspond to the 		termination of the Terms and Conditions. In case the reason behind the closure of the 		Account is related to concerns about possible gambling addiction the player shall 		indicate it.
          <br/ ><br/ >3.10 	In the case a player account is closed, related information about the account is saved 	for a minimum of six (6) years from the time the account is closed. Related information includes player name, address, phone, email and account transactional 	details. 
          </p>
          <h3>4. 	Deposits into your account</h3>
          <p>4.1	You may be charged assigned fees for processing deposits. If such fees occur, they’ll be visible during the deposit process. Deposits can be made with Credit Card, Bank Transfer.
          <br/ ><br/ >4.2	The Company shall assign minimum deposit amounts and maximum deposit 			amounts as specified on the Websites.
          <br/ ><br/ >4.3	Depositing funds into your account is made by using any of the methods specified on 		the Websites, as may be amended from time to time. Details in respect to the timings 		for withdrawals in respect to the method utilized are available on the relevant pages 		of the Websites, as may be amended from time to time.
          <br/ ><br/ >4.4 	Deposited amounts are available on the Account within a reasonable amount of time 	after the confirmation of the deposit on all available deposit options unless extra verification procedures are needed as stated in<br/ ><br/ >4.4. 
          <br/ ><br/ >4.5	The Company reserves the right to use additional procedures and means to verify 		the identity of the customer in order to validate the payment to your account (Know 		Your Customer).
          <br/ ><br/ >4.6	If the Company mistakenly credits Your Account with winnings that do not belong to you, whether due to a technical or human error or otherwise, the amount will remain property of the Company and the amount will be transferred from Your Account. If prior to the Company becoming aware of the error You have withdrawn funds that do not belong to you, without prejudice to other remedies and actions that may be available at law, the mistakenly paid amount will constitute a debt owed by You to the In the event of an incorrect crediting, you are obliged to notify the Company immediately by email.
          <br/ ><br/ >4.7	You shall only use valid credit cards or other financial means legally belonging to 			you.
          <br/ ><br/ >4.8	The minimum deposit amount for the Player varies depending on the selected deposit method. This specific amount will be displayed on the Company in the relevant sections during the deposit process. Ensure to check the stipulated minimum for each method before proceeding with your transaction.
          <br/ ><br/ >4.9	It is prohibited by law to deposit funds obtained from fraudulent means.
          <br/ ><br/ >4.10 	The Company accepts deposits in United States Dollar (USD), Japanese Yen (JPY), Cryptocurrencies (USDT/USDC/BTC/ETH/DOGE/LTC/TRX/DAI/XRP/ADA). Any funds withdrawn from the Websites will also be in these respective currencies. Ensure that your selected method of deposit or withdrawal supports these currencies to avoid any complications or additional conversions. Please note that any exchange premiums are paid by You.
          <br/ ><br/ >4.11	The Company will perform security checks on random occasions and at our sole discretion. You accept that we may demand additional information in the event of a security check and that we may suspend and/or terminate your Account, registration or withhold your payment until we receive the appropriate information that we have requested.
          <br/ ><br/ >4.12	The Company will only accept colour copies of identification documents.
          <br/ ><br/ >4.13 	Deposits made to your account should be commensurate with game play. Therefore, if in the Company’s discretion, there is suspicion of abuse (for instance, where a deposited amount has not been used for an appropriate level of gameplay and the user then makes a withdrawal request in relation to that deposited amount or where the Company suspects that you are abusing exchange rate fluctuations), we reserve the right in its absolute discretion to cancel the respective deposit(s) in part or in full, to retract any costs that may have resulted in conjunction therewith and to close the gaming account indefinitely. The Company further reserves the right to request and obtain satisfactory proof of deposit and additional copies of personal identification in such instances prior to processing any withdrawal request, the processing of such request to be entirely at the Company’ s discretion.
          <br/ ><br/ >4.14 	The Company reserves the right to terminate an account, ban a player and retain all funds where that player is reasonably suspected of engaging in fraudulent activities which 	include, but are not limited to, use of stolen or falsified credit cards or account numbers, forgery, and submission of data or documents which are forged, stolen or otherwise misappropriated.
          </p>
          <h3>5.	Withdrawals</h3>
          <p>5.1 	Available withdrawal options are to be clearly stated on the Websites and may differ 		depending on the market from which the player originates.
          <br/ ><br/ >5.2 	Withdrawals will be made to your bank account or other withdrawal methods available to choose from ‘The Website’ and/or chosen by the Company. The company shall credit you back using the same method as you have previously deposited with if the method allows this to be made.
          <br/ ><br/ >5.3 	Funds marked as "Bonus money" or stuck in an aborted game will not be possible to 		withdraw.
          <br/ ><br/ >5.4 	The verification team employed by the Company will carry out additional verification 		procedures for any single or cumulative deposits or withdrawals, and further reserves the right to carry out such verification procedures also in case of lower pay-outs or random security checks. Such verifications may for example include Copy of National ID card, Passport, Personal Account Number Card, Proof of residence and/or self-photos to confirm authenticity.
          <br/ ><br/ >5.5	All transactions shall be checked in order to prevent money laundering. If the player becomes aware of any suspicious activity relating to any of the Games of the Websites, the player must report this to the Company immediately. The Company may suspend, block, or close an Account and withhold funds if requested to do so in compliance with the Prevention of Money Laundering Regulations.
          <br/ ><br/ >5.6	Be advised that our products are consumed instantly during gameplay. Thus, we 		cannot provide refunds, returns of goods, or cancellation of your service when playing. If you play a game with real money, the money will be drawn from your 	player account instantly.
          <br/ ><br/ >5.7 	Players shall not treat the Company as a banking institution nor expect any interest.
          <br/ ><br/ >5.8	We may impose a fee on the processing of the withdrawal, which will be stated when 		you request a withdrawal.
          </p>
          <h3>6. 	Bonuses & Rewards Scheme Conditions</h3>
          <p>6.1 	The bonus and rewards conditions defined are valid until a new version is made 			available on the Websites.
          <br/ ><br/ >6.2 	The Company reserves the right to withhold and remove any bonus money awarded to you if it has not been used within 60 days from the date awarded. Individual campaigns may have a shorter expiration time and are, if so, stated to the player related to the time when receiving the campaign offer.
          <br/ ><br/ >6.3	If you are eligible for a bonus, wagering requirements may apply before you are eligible to make any cash-outs. The Company has the right to choose which games and how much a game contributes to the wagering requirements.
          <br/ ><br/ >6.4 	The welcome bonus is subject to 25x deposit + bonus wagering requirements. Funds derived from bonuses are not withdrawable until the associated wagering requirement is complete.
          <br/ ><br/ >6.5	Unless otherwise specified, bonus funds can be wagered on slot games only.
          <br/ ><br/ >6.6	Welcome bonuses at the Company can only be received once per household, IP address, and/or credit card number. We reserve the right to close your account and confiscate any existing funds if evidence of bonus abuse/fraud is found.
          <br/ ><br/ >6.7	Individually, bonus campaigns can have specific details and terms, in this case such 		information shall be made available to the user.
          <br/ ><br/ >6.8	Game rounds and game features triggered with bonus funds must be played through 		whilst a bonus is active. Players are not permitted to delay completion of game 			rounds or game features until after the wagering requirement for that bonus has been 		met, and/or they have deposited new funds. If such activity is identified, the player 		agrees that their bonus and/or any associated winnings may be voided at the 			discretion of Casino Management.
          <br/ ><br/ >6.9 	The Company reserves the right to close your account and not to issue refunds of any deposits, remittance of balances or winnings generated on the account, should we suspect bonus abuse has occurred. Abuse includes but is not limited to, cash stashing, bonus stashing or multi account usage.
          <br/ ><br/ >6.10	The wagering requirement may be deemed void if your account balance is or 			reaches 0.00 EUR/USD and all game rounds are closed and settled.
          <br/ ><br/ >6.11	Cashback bonuses differ and are not subject to wagering requirements. Such bonuses are credited directly to the player's real money/cash account balance.
          <br/ ><br/ >6.12. If your bonus is not automatically reflected, please contact customer support.
          </p>
          <h3>7. 	Paused and Inactive accounts</h3>
          <p>7.1	 A paused account is an account that has not been accessed for 12 months, that has 		a real money balance.
          <br/ ><br/ >7.2 	An inactive account is an account that has not been accessed for 30 months, that 		has a real money balance.
          <br/ ><br/ >7.3 	The Company will inform you by email that your account will become inactive one month prior. When an account is deactivated, The Company may impose a fee to your account, such fee shall be a monthly fee of $5. 
          </p>
          <h3>8. 	Responsible Gaming</h3>
          <p>8.1	You may at your discretion choose to limit your ability to access your Account (log in) for a definite or indefinite time in which your account will not be accessible. You may choose to self-exclude for a period of 7 days, 30 days, 90 days and permanent. No variations to these time frames will be available.
          <br/ ><br/ >8.2	All limitations mentioned above are administrated and initiated in the ‘Security’ section of the Websites. Alternatively this can be requested by contacting customer support on live chat or via email at support@queen-casino.com. 
          <br/ ><br/ >8.3 	A self-exclusion request would be valid for the Company only and does not include other sites operated by us.
          <br/ ><br/ >8.4 	We reserve the right at our sole discretion to block or close your Account on any platform owned by the Company if we consider that your continued use of our Services is detrimental to your health and wellbeing.
          <br/ ><br/ >8.5	We reserve the right at our sole discretion to block or close your Account on any platform owned by the Company if we consider that you have a gambling addiction and that your continued use of our Services is detrimental to your health and wellbeing.
          <br/ ><br/ >8.6	Upon closing your account you acknowledge the following:
          <br/ >•	You should not attempt or proceed to open any new accounts during your self-exclusion timeframe or forever if a permanent self-exclusion has been selected.
          <br/ >•	If you succeed in opening an account during a self-exclusion, we will endeavour to close any and all such accounts at our earliest detection.
          <br/ >•	The Company is only responsible for taking reasonable steps to prevent you from gambling on any of our products. It is also the players responsibility to refrain from breaching these agreed terms.
          </p>
          <h3>9. 	Privacy Policy</h3>
          <p>9.1	You acknowledge and accept that we collect and use your personal data. For you to 		access the processing of personal data in compliance with data protection 			regulations, you are requested to access our Privacy Policy.
          </p>
          <h3>10. 	Exceptional Circumstances & Aborted Games</h3>
          <p>10.1 	Unexpected technical problems or circumstances outside the control of the Company such as technical problems with third party providers, allows the Company to cancel bets and give refunds to players.
          <br/ ><br/ >10.2 	The Company has the right to limit, cancel and refuse bets in the case they are too large or if we see that the betting pattern of the player takes place in such a way that the system is being abused.
          <br/ ><br/ >10.3	If a refund is decided upon, the amount of the refund shall be returned to the player’s 		account, the player shall be informed, and the procedure finalized within 48 hours 		after the decision has been made.
          <br/ ><br/ >10.4 	In the case that a game is "stuck" in a state where it is not being finished, for example a connection loss while playing an active blackjack hand, the Company has the right to "clean up" such bets at a regular basis and refund the bet/wager to the player’s account. This is performed on a monthly basis. If the game has been aborted or miscarried on the server, the player shall be refunded.
          <br/ ><br/ >10.5 	If a bonus campaign has in any way been misconfigured, the Company has the right to alter player's balances and account details to correct the mistake.
          <br/ ><br/ >10.6 	If a game contains a bug or misconfiguration that causes incorrect behaviour or pay out, the Company has the right to remove the game and alter player's balances and account details to correct the mistake.
          <br/ ><br/ >10.7 	The Company is not liable for any downtime, server disruptions, lagging, or any 		technical or political disturbance to the game play. Refunds may be given solely at the discretion of the management.
          <br/ ><br/ >10.8	The Company shall accept no liability for any damages or losses which are deemed 	or alleged to, have arisen out of, or in connection with Websites or its content, including without limitation, delays or interruptions in operation or transmission, loss or corruption of data, communication or lines failure, any person’s misuse of the site or its content or any errors or omissions in content.
          <br/ ><br/ >10.9 	The Company may remove any game from the Websites at any time we see fit.
          </p>
          <h3>11. 	Complaints</h3>
          <p>11.1 	You may contact our customer service according to the instructions located on the Websites to give us any complaints regarding our services. Should you want to send us a complaint through email you may do so addressing the Company you may do so addressing support@queen-casino.com.
          <br/ ><br/ >11.2	Complaints are handled in the support department and escalated in the organization of the Company in the case that support personnel do not solve the case immediately. The player shall be informed about the state of the complaint to a reasonable level.
          <br/ ><br/ >11.3 	If, for some reason, you are not satisfied with the resolution of your complaint by 			Zen Estratega SRL., you may bring unresolved disputes to Gaming Services 			Provider N.V Tobique Gaming Commission.  
          </p>
          <h3>12. 	Limitation of Liability</h3>
          <p>12.1 	You enter the Websites and participate in the Games at your own risk. The Websites 		and the Games are provided without any warranty whatsoever, whether express or 		implied.
          <br/ ><br/ >12.2 	Without prejudice to the generality of the preceding provision, we, our directors, 			employees, partners, service providers: 
          <br/ >•	do not warrant that the software, Games and the Websites are fit for their purpose.
          <br/ >•	do not warrant that the software, Games and the Websites are free from errors.
          <br/ >•	do not warrant that the software, Games and the Websites will be accessible without interruptions.
          <br/ >•	shall not be liable for any loss, costs, expenses, or damages, whether direct, indirect, special, consequential, incidental or otherwise, arising in relation to your use of the Websites or your participation in the Games.
          <br/ ><br/ >12.3 	You understand and acknowledge that, if there is a malfunction in a Game or its 			interoperability, any bets made during such malfunctioning shall be void. Funds 			obtained from a malfunctioning Game shall be considered void, as well as any	 		subsequent game rounds with said funds, regardless of what Games are played 			using such funds.
          <br/ ><br/ >12.4 	You hereby agree to fully indemnify and hold harmless us, our directors, employees, 		partners, and service providers for any cost, expense, loss, damages, claims and 		liabilities howsoever caused that may arise in relation to your use of the Website or 		participation in the Games.
          <br/ ><br/ >12.5 	To the extent permitted by law, our maximum liability arising out of or in connection 	with your use of the Websites, regardless of the cause of actions (whether in contract, tort, breach of warranty or otherwise), will not exceed $1500. 
          </p>
          <h3>13. 	Breaches, Penalties and Termination</h3>
          <p>13.1 	If you breach any provision of these Terms and Conditions or we have a reasonable 		ground to suspect that you have breached them, we reserve the right to not open, to 		suspend, or to close your Account, or withhold payment of your winnings and apply 		such funds on account of any damages due by you.
          <br/ ><br/ >13.2 	If the Company suspects that you are engaged in illegal and/or fraudulent activities when using any of the Websites; or in breach of this Agreement; or that you are having 	problems with creditors or otherwise detrimental to our business, we may freeze or terminate your account or cancel any stakes at our absolute discretion.
          <br/ ><br/ >13.3 	You acknowledge that the Company shall be the final decision-maker of whether you have violated the Company's rules, terms or conditions in a manner that results in suspension or permanent barring from participation in our site(s).
          </p>
          <h3>14. 	Severability</h3>
          <p>14.1 	If any provision of these Terms and Conditions is held to be illegal or unenforceable, 		such provision shall be severed from these Terms and Conditions and all other 			provisions shall remain in force unaffected by such severance.
          </p>
          <h3>15. 	Assignment</h3>
          <p>15.1 	We reserve the right to assign or otherwise lawfully transfer this agreement. You shall 		not assign or otherwise transfer this agreement.
          </p>
          <h3>16. 	Entire Agreement</h3>
          <p>16.1 	These Terms and Conditions jointly with our Privacy Policy constitute the entire agreement between you and the Company with respect to the Websites and save in the case of fraud, supersede all prior or contemporaneous communications and proposals, whether electronic, oral or written, between you and us with respect to the Websites.
          </p>
          <h3>17. 	Applicable law and jurisdiction</h3>
          <p>17.1	These Terms and Conditions shall be governed by the Tobique Laws.
          <br/ ><br/ >17.2 	The parties agree that any dispute, controversy, or claim arising out of or in 			connection with these Terms and Conditions, or the breach, termination, or invalidity 		thereof, shall be submitted to the exclusive jurisdiction of the Tobique Laws.
          </p>
          <br/ ><br/ ><p>* English version is prioritized on the site.
          <br/ >* Last Updated： June 21, 2024</p>
        `
      }
    ]
  },
  generalTermsOfUse: {
    title: 'Campaign Term of Use',
    subTitle:
      'QUEEN CASINO offers many opportunities to receive bonus money after each deposit. There are various types of campaigns, including deposit bonus, such as first deposit bonus, second deposit bonus, and third deposit bonus.',
    content: [
      {
        dd: `
          <h3>No Deposit Bonus</h3>
          <ul>
            <li class="leter-first">QUEEN CASINO offers no deposit bonuses to customers who register on an irregular basis. (These include keyword bonuses and new registration bonuses, among others.) The withdrawal requirements and the maximum cashable amount for no deposit bonuses vary depending on the bonus granted. Please refer to the terms and conditions for each bonus. </li>
            <li class="leter-first">For example, the withdrawal requirements for the registration bonus of $20 are 25 times the bonus amount in betting turnover, and the maximum withdrawal amount is $100.</li>
          </ul>
        `
      },
      {
        dd: `
        <h3>Deposit bonus</h3>
        <ul>
          <li class="leter-first">For those who have obtained a deposit bonus, you can check the bonus amount in the "Bonus" wallet located next to the cash wallet. Once the withdrawal conditions of the bonus are fulfilled, the balance will be transferred to the cash wallet and become available for withdrawal. Deposit bonuses are generally offered in a manner similar to the initial deposit bonus, where a certain percentage (e.g., ●●%) of the deposited amount is provided as a deposit bonus for deposits exceeding a specified minimum. For example, in the case of a 50% deposit bonus, if you deposit 20 USD, you will receive a bonus of 10 USD.</li>
          <li class="leter-first">Details regarding deposit bonuses are provided on QUEEN CASINO's website or through campaign emails. Please note that the offers for deposit bonuses may change, and in such cases, new terms and conditions related to the bonus will be published. If specific terms are not mentioned, please refer to the general bonus terms and conditions.</li>
        </ul>
        `
      },
      {
        dd: `
        <h3>Withdrawal Conditions for AG JACKPOT</h3>
        <ul>
          <li class="leter-first">AG Jackpot bonuses can be withdrawal without betting multiplles, but if players use bonus bets,need to x1 turnover to withdaw.</li>
        </ul>
        `
      },
      {
        dd: `
        <h3>Quests (No Deposit Quests, Deposit Quests)</h3>
        <ul>
          <li class="leter-first">A quest is a program where you can receive cash rewards by clearing wagering conditions using funds from your cash wallet. Quests are typically valid for 30 days after initiation. Once a quest has begun, it continues until the wagering conditions are met or canceled, and combining multiple quests is not allowed. The wagering contribution rate for live casino bets in quests is 15%.</li>
          <li class="leter-first">While information about deposit bonuses is provided on QUEEN CASINO's website or through campaign emails, please note that the offers for deposit bonuses may change. In such cases, new terms and conditions related to the bonuses will be published. If specific terms are not provided, please refer to the general bonus terms and conditions.</li>
        </ul>
        `
      },
      {
        dd: `
          <h3>Free Spin (Free Spin Bonus)</h3>
          <ul>
            <li class="leter-first">1. Free spin amount is 30 yen or 0.2 USD and the equivalent in other currencies.</li>
            <li class="leter-first">2. When balance is 1,600 YEN or $10 or more in free spins wallet, it will automatically create a Bonus .</li>
            <li class="leter-first">3.Please receive the bonus from the promo page.</li>
            <li class="leter-first">4. Free spin bonus rollover is 20x.</li>
            <li class="leter-first">5. Converted limit of Free spins bonus is bonus x 5.</li>
          </ul>
        `
      },
      {
        dd: `
          <h3>Notes and prohibitions for bonus games</h3>
          <ul>
            <li class="leter-first">Bet together cash and bonus, each with different withdrawal conditions will cause a system error, so please refrain.</li>
            <li class="leter-first">The conditions for withdrawal of bonuses (first deposit bonus, reload bonus, etc.) automatically awarded by the system, requires a BET result of 30 times the bonus amount awarded. In addition, the maximum bet for one game is 10USD.
                * Please refer to the "BET COUNT Percentage by Game Type" described below for the bonus BET count rate.
            </li>
            <li class="leter-first">System betting using bonus money is prohibited.</li>
            <li class="leter-first">Bonuses that do not meet withdrawal requirements will expire on 14 days.</li>
            <li class="leter-first">If you apply for cash withdrawal with bonus money remaining, the bonus remaining at that time will be deleted automatically.</li>
            <li class="leter-first">If we suspect a misappropriate use of the campaign’s terms, QUEEN CASINO may, at its discretion, may revise the terms of use and reserve the right to suspend or refuse any payments or wins.</li>
            <li class="leter-first">All customers of QUEEN CASINO accept bonus money and accept the terms and the terms and conditions of each campaign.</li>
            <li class="leter-first">In the event of a violation of the General Terms and Conditions of the Campaign, QUEEN CASINO reserves the right to confiscate the winnings and freeze the account in all circumstances, whether deliberately or negligently.</li>
            <li class="leter-first">Sports betting and e-sports betting are eligible for wagering if the odds are 1.5 or higher in European odds. If you bet on odds less than 1.5, please note that it will not be reflected as part of the rollover wagering requirement.</li>
            <li class="leter-first">Sports calculate effective rollover based on the win/lose amount that produces game results.</li>
            <li class="leter-first">The bonus will be forfeited once the bonus balance is less than 1USD.</li>
            <li class="leter-first">Percentage Game type:
              <ul>
                <li class="leter-first-circle">100% Pokies/Video Pokies</li>
                <li class="leter-first-circle">100% Sports Bet</li>
                <li class="leter-first-circle">100% e-sports</li>
                <li class="leter-first-circle">100% Virtual Games</li>
                <li class="leter-first-circle">15% Live Casino</li>
                <li class="only">Bonus and quests: The rollover is based on the amount of the bet compared to the winnings, with the lower amount being rollover</li>
                <li class="leter-first-circle">0%  Pachinko/Pachislot</li>
                <li class="leter-first-circle">0%  iSLOT（Live Slot）</li>
                <li class="leter-first-circle">Bonus and quests: The rollover is based on the amount of the bet compared to the winnings, with the lower amount being rollover</li>
              </ul>
            </li>
            <li class="leter-first">QUEEN CASINO regularly communicates campaigns and special offers via the website, email and other electronic media, and describes certain terms of use during each campaign period. In order to receive the latest version of the campaign, please register your registered email address with a valid address.</li>
            <li class="leter-first">The minimum payout amount is USD50.00. For payouts under USD50.00 QUEEN CASINO has the right to charge a transaction fee of USD5.00.</li>
          </ul>
        `
      },
      {
        dd: `
          <p>* English version is prioritized on the site.</p>
          <p>* Last Updated： July 1, 2024</p>
        `
      }
    ]
  },
  moneyLaundering: {
    title: 'AML Policy of TOBIQUE Co N.V.',
    content: [
      {
        dd: `
          <p>Anti Money Laundring Policy</p>
          <p>(AMLI Anti-Money-Laundering policy of Queen Casino.</p>
          <p>Introduction: Queen Casino is operated by Zen Estratega SRL, registration number: 3-102-897673, registered address: 400, Calle La Ceiba, San Rafael, Escazú, Province of San Jose, Costa Rica. </p>
          <p>Objective of the AML Policy: We seek to offer the highest security to all of our users and customers on Queen Casino for that a three step account verification is done in order to insure the identity of our customers. The reason behind this is to prove that the details of the person registered are correct and the deposit methods used are not stolen or being used by someone else, which is to create the general framework for the fight against money laundering. We also take into accord that depending on the nationality and origin, the way of payment and for withdrawing different safety measurements must be taken.</p>
          <p>Queen Casino also puts reasonable measures in place to control and limit ML risk, including dedicating the appropriate means.</p>
          <p>Queen Casino is committed to high standards of anti-money laundering (AML) according to the EU guidelines, compliance and requires management & employees to enforce these standards in preventing the use of its services for money laundering purposes.</p>
          <p>The AML program of Queen Casino is designed to be compliant with :</p>
          <p>EU : “Directive 2015/849 of the European Parliament and of The Council of 20 May 2015 on the prevention of the use of the financial system for the purposes of money laundering”</p>
          <p>EU : “Regulation 2015/847 on information accompanying transfers of funds”</p>
          <p>EU : Various regulations imposing sanctions or restrictive measures against persons and embargo on certain goods and technology, including all dual-use goods</p>
          <p>BE : “Law of 18 September 2017 on the prevention of money laundering limitation of the use of cash</p>
        `
      },
      {
        dd: `
          <h2>Definition of money laundering:</h2>
          <ul>
            <li class="leter-first">The conversion or transfer of property, especially money, knowing that such property is derived from criminal activity or from taking part in such activity, for the purpose of concealing or disguising the illegal origin of the property or of helping any person who is involved in the commission of such an activity to evade the legal consequences of that person's or companies action. </li>
            <li class="leter-first">The concealment or disguise of the true nature, source, location, disposition, movement, rights with respect to, or ownership of, property, knowing that such property is derived from criminal activity or from an act of participation in such an activity.</li>
            <li class="leter-first">The acquisition, possession or use of property, knowing, at the time of receipt, that such property was derived from criminal activity or from assisting in such an activity</li>
            <li class="leter-first">Participation in, association to commit, attempts to commit and aiding, abetting, facilitating and counselling the commission of any of the actions referred to in points before.</li>
          </ul>
          <p class="leter-first">Money laundering shall be regarded as such even when the activities which generated the property to be laundered were carried out in the territory of another Member State or in that of a third country.</p>
        `
      },
      {
        dd: `
          <h2>Organization of the AML for Queen Casino</h2>
          <ul>
            <li class="leter-first">In accordance with the AML legislation, Queen Casino has appointed the “highest level” for the prevention of ML: The full management of Zen Estratega SRL. are in charge.</li>
            <li class="leter-first">Furthermore, an AMLCO (Anti Money Laundering Compliance Officer) is in charge of the enforcement of the AML policy and procedures within the System.</li>
            <li class="leter-first">The AMLCO is placed under the direct responsibility of the general Management.</li>
          </ul>
        `
      },
      {
        dd: `
          <h2>AML policy changes and implementation requirements</h2>
          <ul>
            <li class="leter-first">Each major change of Queen Casino AML policy is subject to be approval by the general management of Zen Estratega SRL. and the Anti money laundering compliance officer.</li>
          </ul>
        `
      },
      {
        dd: `
          <h2>Three step Verification</h2>
          <ul>
            <li>
              <h3 class="leter-first">Step one verification</h3>
              <ul>
                <li>Step one verification must be done by every user and customer to withdraw. Regarding of the choice of payment, the amount of payment, the amount of withdraw, the choice of withdraw and nationality of the user/customer step one verification must be done first. Step one verification is a document that must be filled out by the user/customer himself. Following information’s must be filled in: first name, second name, date of birth, country of usual residence, gender and full address.</li>
              </ul>
            </li>
            <li>
              <h3 class="leter-first">Step two verification</h3>
              <ul>
                <li>Step two verification must be done by every user which deposit over 2000$ (two thousand Dollar) or withdraws over 2000$ (two thousand Dollar) or sends another user over 1000$ (one thousand Dollar) Until step two verification is done the withdraw, tip or deposit will be hold. Step to verification will lead the user or customer to a subpage where he must send in his ID. The user/customer must make a picture of his ID. While a paperclip with a six-digit random generated number is next to his ID: Only an official ID may be used for ID verification, depending on the country the variety of accepted IDs may be different. There will also be an electronic check if the filled in Data from the step one verification is correct. The electronic check will check via two different databanks to insure the given information’s matches with the filled document and the name from the ID: If the electronic test fails or is not possible the user/customer is required to send in a conformation of his current resident. A certificate of registration by the government or a similar document is required.</li>
              </ul>
            </li>
            <li>
              <h3 class="leter-first">Step three verification</h3>
              <ul>
                <li>Step three verification must be done by every user which deposit over 5000$ (five thousand Dollar) or withdraws over 5000$ (five thousand Dollar) or sends another user over 3000$ (three thousand Dollar) Until step three verification is done the withdraw, tip or deposit will be hold. For step 3 a user/customer will be asked for a source of wealth.</li>
              </ul>
            </li>
          </ul>
        `
      },
      {
        dd: `
          <h2>Customer identification and verification (KYC)</h2>
          <ul>
            <li class="leter-first">The formal identification of customers on entry into commercial relations is a vital element, both for the regulations relating to money laundering and for the KYC policy.</li>
            <li class="leter-first">This identification relies on the following fundamental principles:</li>
            <li class="leter-first">A copy of your passport, ID card or driving license, each shown alongside a handwritten note mentioning six random generated numbers. Also, a second picture with the face of the user/customer is required. The user/customer may blur out every information, besides date of birth, nationality, gender, first name, second name and the picture. To secure their privacy.</li>
            <li class="leter-first">Please note that all four corners of the ID have to be visible in the same image and all details has to be clearly readable besides the named above. We might ask for all details if necessary.</li>
            <li class="leter-first">An employee may do additional checks if necessary, based on the situation.</li>
          </ul>
        `
      },
      {
        dd: `
          <h2>Proof of Address</h2>
          <ul>
            <li class="leter-first">Proof of address will be done via to different electronic checks, which use two different databases. If an electronic test fails, the user/customer has the option to make a manually proof.</li>
            <li class="leter-first">A recent utility bill sent to your registered address, issued within the last 3 months or an official document made by the government that proofs your state of residence.</li>
            <li class="leter-first">To make the approval process as speedy as possible, please make sure the document is sent with a clear resolution where all four corners of the document is visible, and all text is readable.</li>
            <li class="leter-first">For example: An electricity bill, water bill, bank statement or any governmental post addressed to you.</li>
            <li class="leter-first">An employee may do additional checks if necessary, based on the situation.</li>
          </ul>
        `
      },
      {
        dd: `
          <h2>Source of funds</h2>
          <ul>
            <li class="leter-first">If a player deposits over a five thousand euro there is a process of understandings the source of wealth (SOW)</li>
            <li class="leter-first">Examples of SOW are:
              <ul>
                <li class="leter-first-circle">Ownership of business</li>
                <li class="leter-first-circle">Employment</li>
                <li class="leter-first-circle">Inheritance</li>
                <li class="leter-first-circle">Investment</li>
                <li class="leter-first-circle">Family</li>
              </ul>
            </li>
            <li class="leter-first">It is critical that the origin and legitimacy of that wealth is clearly understood. If this is not possible an employee may ask for an additional document or prove.  </li>
            <li class="leter-first">The account will be frozen if the same user deposits either this amount in one go or multiple transactions which amount to this. An email will be sent to them manually to go through the above and an information on the website itself.</li>
            <li class="leter-first">Queen Casino also asks for a bank wire/credit card to further insure the Identity of the user/customer. It also gives additional information about the financial situation of the user/customer.</li>
          </ul>
        `
      },
      {
        dd: `
          <h2>Basic document for step one</h2>
          <ul>
            <li class="leter-first">The basic document will be accessible via the setting page on Queen Casino. Every user has to fill out the following information:
              <ul>
                <li class="leter-first-circle">First name</li>
                <li class="leter-first-circle">Second name</li>
                <li class="leter-first-circle">Nationality</li>
                <li class="leter-first-circle">Gender</li>
                <li class="leter-first-circle">Date of Birth</li>
              </ul>
            </li>
            <li class="leter-first">The document will be saved and created by an AI, an employee may do additional checks if necessary based on the situation.</li>
          </ul>
        `
      },
      {
        dd: `
          <h2>Risk management</h2>
          <ul>
            <li class="leter-first">In order to deal with the different risks and different states of wealth in different regions on the earth Queen Casino will categorize every nation in three different regions of risk.</li>
          </ul>
        `
      },
      {
        dd: `
          <h2>Region one: Low risk</h2>
          <ul>
            <li class="leter-first">For every nation from the region one the three-step verification is done as described earlier. </li>
          </ul>
        `
      },
      {
        dd: `
          <h2>Region two: Medium risk</h2>
          <ul>
            <li class="leter-first">For every nation from the region two the three-step verification will be done at lower deposit, withdraw and tip amounts. Step one will be done as usually. Step two will be done after depositing $1,000, withdrawing $1000 or tipping another user/customer $500. Step three will be done after depositing $2,500, withdrawing $2,500 or tipping another user/customer $1,000. Also, users from a low risk region that change crypto currency in any other currency will be treated like user/customers from a medium risk region. </li>
          </ul>
        `
      },
      {
        dd: `
          <h2>Region three: High risk</h2>
          <ul>
            <li class="leter-first">Regions of high risks will be banned. High risk regions will be regularly updated to keep up with the changing environment of a fast-changing world. </li>
          </ul>
        `
      },
      {
        dd: `
          <h2>Additional measurements</h2>
          <ul>
            <li class="leter-first">In addition, an AI which is overseen by the AML compliance officer will look for any unusual behaviour and report it right away to a employee of Queen Casino. </li>
            <li class="leter-first">According to a risk based few and general experience the human employees will recheck all checks which are done bevor by the AI or other employees and may redo or do additional checks according to the situation. </li>
            <li class="leter-first">In addition, a data Scientist supported by modern, electronic, analytic systems will look for unusual behaviour like: Depositing and withdrawing without longer Betting sessions. Attempts to use a different Bank account to for Deposit and Withdraw, nationality changes, currency changes, behaviour and activity changes as well as checks, if an account is used by it´s original owner. </li>
            <li class="leter-first">Also a User has to use the same method for Withdraw as he used for Deposit, for the amount of the initial Deposit to prevent any Money Laundering. </li>
          </ul>
        `
      },
      {
        dd: `
          <h2>Enterprise-wide risk assessment</h2>
          <ul>
            <li class="leter-first">As part of its risk-based approach, Queen Casino has conducted an AML “Enterprise-wide risk assessment” (EWRA) to identify and understand risks specific to <www.website.com> and its business lines. The AML risk policy is determined after identifying and documenting the risks inherent to its business lines such as the services the website offers. The Users to whom services are offered, transactions performed by these Users, delivery channels used by the bank, the geographic locations of the bank’s operations, customers and transactions and other qualitative and emerging risks. </li>
            <li class="leter-first">The identification of AML risk categories is based on Queen Casino understanding of regulatory requirements, regulatory expectations and industry guidance. Additional safety measures are taken to take care of the additional risks the world wide web brings with it. </li>
            <li class="leter-first">The EWRA is yearly reassessed. </li>
          </ul>
        `
      },
      {
        dd: `
          <h2>Ongoing transaction monitoring</h2>
          <ul>
            <li class="leter-first">AML-Compliance ensures that an “ongoing transaction monitoring” is conducted to detect transactions which are unusual or suspicious compared to the customer profile.
                This transaction monitoring is conducted on two levels:
                <ul>
                  <li>The first Line of Control:</li>
                  <li class="leter-first-circle">Queen Casino works solely with trusted Payment Service Providers whom all have effective AML policies in place as to prevent the large majority of suspicious deposits onto Queen Casino from taking place without proper execution of KYC procedures onto the potential customer.</li>
                  <li>The second Line of Control:</li>
                  <li class="leter-first-circle">Queen Casino makes its network aware so that any contact with the customer or player or authorized representative must give rise to the exercise of due diligence on transactions on the account concerned. In particular these include:</li>
                  <li>Requests for the execution of financial transactions on the account</li>
                  <li>Requests in relation to means of payment or services on the account</li>
                </ul>
            </li>
            <li class="leter-first">Also, the three-step verification with adjusted risk management should provide all necessary information’s about all costumers of Queen Casino at all time.</li>
            <li class="leter-first">Also, all transaction must be overseen by employees over watched by the AML compliance officer who is over watched by the general management.</li>
            <li class="leter-first">The specific transactions submitted to the customer support manager, possibly through their Compliance Manager must also be subject to due diligence.</li>
            <li class="leter-first">Determination of the unusual nature of one or more transactions essentially depends on a subjective assessment, in relation to the knowledge of the customer (KYC), their financial behaviour and the transaction counterparty.</li>
            <li class="leter-first">These checks will be done by an automated System, while an Employee crosschecks them for additional security.</li>
            <li class="leter-first">The transactions observed on customer accounts for which it is difficult to gain a proper understanding of the lawful activities and origin of funds must therefore rapidly be considered atypical (as they are not directly justifiable).</li>
            <li class="leter-first">Any Queen Casino staff member must inform the AML division of any atypical transactions which they observe and cannot attribute to a lawful activity or source of income known of the customer.
                <ul>
                  <li>The third Line of Control:</li>
                  <li class="leter-first-circle">As a last line of defence against AML Queen Casino will do manually checks on all suspicious and higher risk users in order to fully prevent money laundering.</li>
                  <li class="leter-first-circle">If fraud or Money Laundering is found the authorities will be informed.</li>
                </ul>
            </li>
          </ul>
        `
      },
      {
        dd: `
          <h2>Reporting of Suspicious transactions on Queen Casino</h2>
          <ul>
            <li class="leter-first">In its internal procedures, Queen Casino describes in precise terms, for the attention of its staff members, when it is necessary to report and how to proceed with such reporting.</li>
            <li class="leter-first">Reports of atypical transactions are analysed within the AML team in accordance with the precise methodology fully described in the internal procedures.</li>
            <li class="leter-first">Depending on the result of this examination and on the basis of the information gathered, the AML team.</li>
            <li class="leter-first">Will decide whether it is necessary or not to send a report to the FIU, in accordance with the legal obligations provided in the Law of 18 September 2017</li>
            <li class="leter-first">Will decide whether or not it is necessary to terminate the business relations with the customer</li>
          </ul>
        `
      },
      {
        dd: `
          <h2>Procedures</h2>
          <ul>
            <li class="leter-first">The AML rules, including minimum KYC standards will be translated into operational guidance or procedures that are available on the Intranet site of Queen Casino.</li>
          </ul>
        `
      },
      {
        dd: `
          <h2>Record keeping</h2>
          <ul>
            <li class="leter-first">Records of data obtained for the purpose of identification must be kept for at least ten years after the business relationship has ended.</li>
            <li class="leter-first">Records of all transaction data must be kept for at least ten years following the carrying-out of the transactions or the end of the business relationship.</li>
            <li class="leter-first">These data will be safely, encrypted stored offline and online.</li>
          </ul>
        `
      },
      {
        dd: `
          <h2>Training</h2>
          <ul>
            <li class="leter-first">Queen Casino human employees will make manual controls on a risk based approve for which they get special training. </li>
            <li class="leter-first">The training and awareness program is reflected by its usage.</li>
            <li class="leter-first">A mandatory AML training program in accordance with the latest regulatory evolutions, for all in touch with finances.</li>
            <li class="leter-first">Academic AML learning sessions for all new employees.</li>
            <li class="leter-first">The content of this training program has to be established in accordance with the kind of business the trainees are working for and the posts they hold. These sessions are given by an AML-specialist working in Zen Estratega SRL. AML team.</li>
          </ul>
        `
      },
      {
        dd: `
          <h2>Auditing</h2>
          <ul>
            <li class="leter-first">Internal audit regularly establishes missions and reports about AML activities.</li>
          </ul>
        `
      },
      {
        dd: `
          <h2>Data Security</h2>
          <ul>
            <li class="leter-first">All data given by any user/customer will be kept secure, will not be sold or given to anyone else. Only if forced by law, or to prevent money laundering data may be shared with the AML-authority of the affected state.</li>
            <li class="leter-first">Queen Casino will follow all guidelines and rules of the data protection directive (officially Directive 95/46/EC)</li>
          </ul>
        `
      },
      {
        dd: `
          <h2>Contact us</h2>
          <ul>
            <li class="leter-first">If you have any complains about our AML and KYC Policy or about the checks done on your Account and your Person, please contact us: <a href="mailto:support@queen-casino.com">support@queen-casino.com</a></li>
          </ul>
        `
      },
      {
        dd: `
          <p>* English version is prioritized on the site.</p>
          <p>Last Updated： September 6, 2023</p>
        `
      }
    ]
  },
  privacy: {
    title: 'Privacy Policy',
    subTitle: [
      `This Privacy Policy is intended to govern the handling of user information, including personal information, on all services operated by Queen Casino website and Zen Estratega SRL (including www.queen-casino.com, hereinafter referred to as "our site" or "the Company").`,
      `Before using our site, please make sure to read this Policy. By using or registering on our site, you are deemed to have agreed to this Policy. If you do not agree to this Policy, please refrain from using our site.`,
      `The Company is committed to protecting the privacy of all visitors to our site and strives to provide all services and activities through a secure online environment.`
    ],
    content: [
      {
        dd: `
          <h2>Information Collection</h2>
          <ul>
            <li class="leter-first">The information collected by the Company from users includes, but is not limited to, the following:
              <ul>
                <li class="leter-first-circle">Personal information provided by users through forms on our site (including information required for user registration, application to various services provided by the Company, or requests for posting materials or additional services). Note that when participating in competitions or promotions, making inquiries to customer support, or reporting website issues, users may be asked to submit personal information.</li>
                <li class="leter-first-circle">Records of responses when users contact our site.</li>
                <li class="leter-first-circle">Responses to surveys conducted by the Company (responses to surveys are not mandatory).</li>
                <li class="leter-first-circle">Details of bets, plays, payments, and transactions made on our site.</li>
                <li class="leter-first-circle">Data usage, location information, weblogs, and other communication data (including information for billing and other purposes), as well as access details to pages and resources on our site.</li>
                <li class="leter-first-circle">Information and conversations in chat or message boards on our site (if applicable).</li>
              </ul>
            </li>
          </ul>
        `
      },
      {
        dd: `
          <h2>Use of User Information</h2>
          <ul>
            <li class="leter-first">The Company collects and processes information provided by users and may share information with data processors contracted with the Company for the following purposes:
              <ul>
                <li class="leter-first-circle">Account setup, activation, operation, management, and provision of services requested by users.*1</li>
                <li class="leter-first-circle">Configuration of user personal profiles.*2</li>
                <li class="leter-first-circle">Providing promotional information to users (if you do not wish to receive information about promotions or events, please uncheck the relevant option in "My Account" under "Settings." Our marketing and promotional activities are conducted in accordance with relevant laws.)*3</li>
                <li class="leter-first-circle">Sharing information with third parties (such as financial institutions, credit agencies, age verification agencies, etc.) to verify the accuracy of information obtained from users.*4</li>
                <li class="leter-first-circle">Prevention of fraud, illegal activities, money laundering, compliance with our terms of use, reducing credit risk, and maintaining the integrity of sports/games. Accordingly, user personal information and betting/gaming history may be shared with third parties such as regulatory authorities, betting and sports organizations, financial institutions, age verification agencies, police, credit agencies, etc.*5</li>
                <li class="leter-first-circle">Complying with relevant licenses and regulatory authorities' laws.*6</li>
                <li class="leter-first-circle">Ensuring or enforcing our terms of use and other agreements, as well as protecting the rights, property, and safety of the Company, users, and others.*7</li>
                <li class="leter-first-circle">Credit card payment processing for our services (including affiliate programs) and online payments.*8</li>
                <li class="leter-first-circle">Collecting research, survey, analysis, and feedback from users.*9</li>
                <li class="leter-first-circle">When using the internet for information collection and processing, international transmission of information may occur. If third-party processors handle user personal information, they will take measures to ensure secure processing in accordance with this Policy.</li>
                <li class="leter-first-circle">Third-party agencies may be involved in processing user information for the purposes described in *1~9 above. However, except in cases of joint ventures or the sale of the whole or part of the Company, the Company will not disclose or trade user information with third parties. Without user consent, the Company will not share, sell, or disclose submitted information, unless required by law.</li>
              </ul>
            </li>
          </ul>
        `
      },
      {
        dd: `
          <h2>Transaction Management</h2>
          <ul>
            <li class="leter-first">All bets, plays, and account transaction histories are recorded in the Company's system. To provide the best service, transactions are regularly audited.</li>
          </ul>
        `
      },
      {
        dd: `
        <h2>Chat and Email</h2>
        <ul>
          <li class="leter-first">Conversations with customer support are recorded to improve internal services, enhance quality management, and provide quicker responses.</li>
        </ul>
        `
      },
      {
        dd: `
        <h2>Use of Cookies</h2>
        <ul>
          <li class="leter-first">"Cookies" are small files containing information temporarily stored on the user's device/browser when visiting a website or using an application. Cookies allow the server to collect information from the browser.</li>
          <li class="leter-first">The Company uses cookies on our site for the following purposes:</li>
          <li class="leter-first">Authenticating users accessing our site and applications to provide appropriate services.</li>
          <li class="leter-first">Allowing login permission on our site and confirming the retention of login status when navigating pages. Betting information is temporarily stored in cookies until the betting is completed, allowing users to make the most of our products and services. Additionally, certain settings (such as selected language, odds display method, etc.) are temporarily stored in cookies for a longer time to restore the same state when revisiting our site.</li>
          <li class="leter-first">Automatically collecting information related to bets and account transactions on our site.</li>
          <li class="leter-first">Analyzing user browsing patterns on our site and building customized browser information. Currently, we use the web analytics service "Google Analytics" provided by Google. Google Analytics uses cookies to analyze how users use our site. We also use analytical cookies from other third-party providers.</li>
          <li class="leter-first">Providing users with relevant and useful advertisements and information or measuring the effectiveness of our advertising campaigns.</li>
          <li class="leter-first">Creating activity reports on our site or providing other services related to activities and internet usage on our site. In cases required by law or where third-party processors handle information on behalf of the Company, information collected may be transferred to third parties.</li>
          <li class="leter-first">Our site and applications use "Flash cookies," "session cookies," and "persistent cookies." "Flash cookies" are small amounts of information stored and accessed by Adobe Flash, a browser plugin used on websites. "Session cookies" store information about user activity on web pages, allowing the server to resume activity from where the user last left our site. "Persistent cookies" are used to provide users with more enjoyable and faster experiences when revisiting our site.</li>
          <li class="leter-first">Users can block third-party cookies through privacy settings in their browsers, thereby rejecting tracking cookies (steps may vary depending on the browser). For information on managing or deleting cookies, refer to this website (<a href="www.aboutcookies.org" target="_blank">www.aboutcookies.org</a>). However, refusing cookie usage may limit the functionality and service accessibility on our site (e.g., storing selected bets in the betting slip requires cookie memory).</li>
        </ul>
        `
      },
      {
        dd: `
          <h2>Security and Privacy</h2>
          <ul>
            <li class="leter-first">The Company takes every effort to protect user information from unauthorized access, improper processing, loss, damage, or harm and implements security measures for this purpose. The Company will make every effort to protect the personal information provided by users (including registration details, debit/credit card information, etc.). Our security page encrypts data using Secure Sockets Layer (SSL). Please keep passwords given by the Company or set by users to access certain areas of our site secure and do not share them with others.</li>
            <li class="leter-first">Personal information that identifies users will always be encrypted when transmitted over the internet. SSL is a special protocol that automatically encrypts information sent by users on our site. Our server reads the information using a private key. In other words, user information such as debit/credit card information and personal information is converted into encrypted data that can only be deciphered using a private key or password. When users' browsers are properly configured, a warning message will be displayed when accessing secure pages on our site, indicating that our site is protecting user information.</li>
            <li class="leter-first">Unfortunately, transmitting information over the internet is not entirely secure. As mentioned in 6(b) above, while the Company takes every effort to protect user information, it cannot guarantee the security of information publicly disclosed by users on the internet. Users acknowledge the risks involved in using the internet and will not hold the Company responsible for any security breaches unless caused by negligence. To protect personal information, it is recommended that users avoid including confidential information (such as debit/credit card numbers) when sending emails to the Company.</li>
            <li class="leter-first">Our site may contain links to websites of subsidiaries, partner organizations, or advertising URLs. Each website may have its own privacy policies and terms of use. The Company assumes no responsibility for these policies. Users are advised to review them before submitting information to each website.</li>
          </ul>
        `
      },
      {
        dd: `
          <h2>Retention and Disposal</h2>
          <ul>
            <li class="leter-first">The Company has established a policy to retain payment information for six years. Other information will be retained for the necessary period for the purposes of use and processing as described in 2(a), unless required by law. The Company will take all reasonable measures to ensure the proper disposal of privacy information.</li>
          </ul>
        `
      },
      {
        dd: `
          <h2>Changes to the Policy</h2>
          <ul>
            <li class="leter-first">The Company may change this Policy at any time. The revised items will be effective when posted on the website.</li>
          </ul>
        `
      },
      {
        dd: `
          <h2>Accuracy and Access to Information</h2>
          <ul>
            <li class="leter-first">Necessary measures will be taken to verify that the information collected from users is accurate and authenticated.</li>
            <li class="leter-first">Users have the right to know the information the Company holds about them and are encouraged to contact the Company to update any outdated or inaccurate information.</li>
            <li class="leter-first">The Company reserves the right to charge a fee for providing the above information as administrative expenses.</li>
            <li class="leter-first">For comments, questions, or inquiries about the use of user information, users can contact us anytime through the "Chat with Us" feature located in the bottom right corner of our site or via email at <a href="mailto:support@queen-casino.com">support@queen-casino.com</a>.</li>
          </ul>
        `
      },
      {
        dd: `
          <p>* English version is prioritized on the site.</p>
          <p>* Last Updated： September 6, 2023</p>

        `
      }
    ]
  },
  responsibleGaming: {
    title: 'Responsible Gambling',
    subTitle: [
      `We want you to enjoy your gaming experience with us across all our channels. We promote the healthy use of our products and encourage our customers to bet with care.`,
      `Throughout this section we offer you guidelines and instructions on how to stay in control and maintain a balanced approach so that you continue to enjoy your experience. If at any point this stops, please be aware that we have a number of control tools that will help you combat your problem. There are also many confidential organizations where you can seek help and advice.`,
      `Customers who believe they have a gambling problem are urged to seek help. If you need to talk to someone about problem gambling, please click here for contact details of confidential organizations that can offer the right support.`
    ],
    content: [
      {
        dt: 'Staying In Control',
        dd: `
          <p>To stay in control please consider the following:</p>
          <p>* Gambling should be entertaining and not seen as a genuine way of making money;</p>
          <p>* Avoid chasing losses;</p>
          <p>* Only gamble what you can afford to lose;</p>
          <p>* Don't let gambling interfere with your daily responsibilities;</p>
          <p>* Know your limits and be clear about them before you start to gamble. When you reach these limits be it the time or money you spend gambling, stop;</p>
          <p>* If you need help, set a deposit limit to control the amount you can spend;</p>
          <p>* If you need to have a break from gambling, time out or self-exclude.</p>
        `
      },
      {
        dt: 'Problem Gambling',
        dd: `
          <p>Problem gambling is a compulsion to continue gambling despite the negative consequences it may have. If you are unsure whether or not your (or someone else’s) gambling has become a cause for concern please ask yourself the following:</p>
          <p>・Do you stay away from work, college or school to gamble?</p>
          <p>・Do you gamble to escape from a boring or unhappy life?</p>
          <p>・When gambling and you run out of money, do you feel lost and in despair, and need to gamble again as soon as possible?</p>
          <p>* Do you gamble until your last penny is gone, even the bus fare home or the cost of a cup of tea?</p>
          <p>* Have you recently tried to stop gambling but were unsuccessful?</p>
          <p>* Have you ever lied to cover up the amount of money or time you have gambled?</p>
          <p>* Have others ever criticized your gambling?</p>
          <p>* Have you lied, stolen or borrowed just to get money to gamble or to pay gambling debts?</p>
          <p>* Are you reluctant to spend "gambling money" on anything else?</p>
          <p>* Have you lost interest in your family, friends or interests?</p>
          <p>* After losing, do you feel you must try and win back your losses as soon as possible?</p>
          <p>* Do arguments, frustrations or disappointments make you want to gamble?</p>
          <p>* Do you feel depressed or even suicidal because of your gambling?</p>
          <p>The more you answer 'yes' to the above questions, the more likely your gambling has become a concern.</p>
          <h3>Self Exclusion</h3>
          <p>Should you feel the need to take a break from any gaming activities on our site, you have the option to self-exclude yourself at any time for up to 90 days from the Self Exclusion section when you access your My Account page. Once the self-exclusion period has been set, no transactions can be transacted with your account and access to your account will be restricted until the set period has passed. Should you wish to exclude yourself for more than 90 days, you can always send an email to <a href="mailto:support@queen-casino.com">support@queen-casino.com</a> to request for a longer period to restrict you from accessing your account registered with us.</p>
        `
      },
      {
        dt: 'Underage Gambling',
        dd: `
          <p>To prevent underage gambling, an automated Age and Identity verification check occurs at the point of successful registration. In the case that we are unable to ascertain a match to an 18 or over database, a physical copy of ID will be requested from the customer which must be provided within 72 hours.</p>
          <p>Our efforts must be complemented by user behavior so we ask that if young people have access to a computer with Internet access, you take care to keep your username, password and deposit details confidential and ensure to log out of your QUEEN CASINO account.</p>
          <p>There are also a number of internet filtering software which can be installed on your computer to protect minors. Such software provides the ability to control content displayed, block websites and set up passwords to prevent gambling websites being available to underage players. E.g. “Net Nanny” or “CyberPatrol”</p>
        `
      }
    ]
  },
  faq: {
    title: 'FAQ',
    subTitle: '',
    content: [
      {
        dt: 'General',
        dd: `
            <h3><strong>Q: How do I register as a new user?</strong></h3>
            <p>A: Registration is free. Click 'SIGN UP' in the upper right corner of the website and enter your information in the Customer Information page. After entering your information, click "SUBMIT" to complete your registration.<br>
            An authentication email will be sent to your registered email address. Click on the URL in the email to complete your registration and login to the website.</p>
            <h3><strong>Q: I haven't received an authentication email.</strong></h3>
            <p>A: Please refer to the official guide below for more information about email authentication.<br>
            <a href="https://queen-casino.info/ja/registration-verify-guide/" target="_blank">https://queen-casino.info/ja/registration-verify-guide/</a><br>
            <br>
            You can also authenticate by registering in the LINE app. Please click the link below to register.<br>
            <a href="https://uyu4ktjm.autosns.app/line" target="_blank">https://uyu4ktjm.autosns.app/line</a><br>
            <br>
            If you still do not receive the message after changing the spam filter settings of your carrier email, we recommend that you change your email address to another one.<br>
            You can change your email address any time by contacting us through our live chat system.</p>
            <h3><strong>Q: My deposit has been turned into a full bonus, is this normal?</strong></h3>
            <p>A: Both of our bonuses will be reflected together in your bonus balance when you deposit and win the bonus.<br>
            Once you have wagered the required multiple of the total deposit and bonus amount, winning and losing, the funds will be transferred to your cash account and you will be able to withdraw.<br>
            More information about the required multiples can be found on the promotion page.</p>
            <h3><strong>Q: Can I register more than one account at a time?</strong></h3>
            <p>A: Our apologies, but you can only register one account per person on our website.</p>
            <h3><strong>Q: Do you have the necessary licenses to operate?</strong></h3>
            <p>A: Yes. The website has passed a rigorous review by Gaming TOBIQUE and is licensed. The website is also regularly audited to ensure that it is reliable and secure.</p>
            <h3><strong>Q: What types of games do you offer?</strong></h3>
            <p>A: Our website offers sports betting, e-sports betting, live casino, slot games and virtual games.</p>
            <h3><strong>Q: What should I do if I have an iquiry?</strong></h3>
            <p>A: Please contact our 24/7 live chat service. Click on 'Live Chat' at the bottom right of the website and we will respond immediately.<br>
            You can also email us at <a href="mailto:support@queen-casino.com">support@queen-casino.com</a></p>
            `
      },
      {
        dt: 'ACCOUNT',
        dd: `
            <h3><strong>Q: I forgot my password. How can I reset it?</strong></h3>
            <p>A: Click on the "IF YOU FORGET" link on the login form and enter your registered email address. A link to set a new password will be sent to your registered email address, and you can reset your password from there.</p>
            <h3><strong>Q: I have a problem logging in. What should I do?</strong></h3>
            <p>A: Please try entering your correct email address and password again. If the problem still persists, please contact our customer support.</p>
            <h3><strong>Q: Can I update my registration information once I have registered?</strong></h3>
            <p>A: To update your registration information, please click on the "Account Icon" in the upper right corner of the website, select "My Account", and make changes in your account information. However, only your address and phone number can be updated.<br>
            If you need to change any other information, please contact our customer support.</p>
            <h3><strong>Q: Can I change my username?</strong></h3>
            <p>A: No, you cannot. We recommend that you register your own username when you register, as you cannot change your username after you have created your account.</p>
            <h3><strong>Q: Can I change my registered e-mail address?</strong></h3>
            <p>A: Yes, you can. You can change your e-mail address by contacting us through our live chat system.</p>
            <h3><strong>Q: Is it possible to change the currency display to yen?</strong></h3>
            <p>A: No, it cannot. The only currency available is USD (US Dollars) and the display cannot be changed.</p>
            <h3><strong>Q: Where can I check my balance?</strong></h3>
            <p>A: Your available balance is displayed in the upper right corner.</p>
            <h3><strong>Q: Where can I check the details of my deposits and withdrawals?</strong></h3>
            <p>A: To view the details of your deposits and withdrawals, click on "Statements" in "My Account"</p>
            <h3><strong>Q: Is my transaction/personal information secure?</strong></h3>
            <p>A: Yes, QUEEN CASINO encrypts all transactions, personal information and all other information using Secure Sockets Layer (SSL) 256-bit communication.<br>
            We use only the highest level of data encryption to ensure that your information is safe and secure.</p>
            <h3><strong>Q: Can I remove the self-regulation before the deadline?</strong></h3>
            <p>A: No, you cannot. The website will not be able to remove the self-imposed restrictions until the deadline you set by yourself has arrived.</p>
          `
      },
      {
        dt: 'Payments',
        dd: `
            <h3><strong>Q: What deposit methods do you offer?</strong></h3>
            <p>A: We offer the following deposit methods;</p>
            <ul>
              <li>
              <p><strong>Bank transfers</strong> can be settled safely and easily over the counter, at ATMs, or through mobile money transfer procedures at the financial institutions you normally use, just like regular domestic money transfers. There is only a transfer fee for deposits and withdrawals.</p>
              </li>
              <li>
              <p><strong>iWallet</strong> is a secure online money transfer service that allows you to make deposits and withdrawals to and from your QUEEN CASINO account. To make a deposit with iWallet, you must first register your account with iWallet and make sure that you have sufficient balance. To register an iWallet, please visit the <a href="https://iwl.hk/jp/index.php" rel="noopener" target="_blank">iWallet website.</a></p>
              </li>
              <li>
              <p><strong>ecoPayz</strong> is a globally trusted online payment service that allows you to make secure and easy payments at websites around the world. After entering the amount you wish to deposit and selecting Continue, you will be redirected to your ecoPayz account where you can login and confirm your deposit. For more information on how to register with ecoPayz, please visit the <a href="https://www.ecopayz.com/ja/" rel="noopener" target="_blank">ecoPayz website.</a></p>
              </li>
              <li>
              <p><strong>ONRAMP/WLPay</strong> allows you to easily deposit funds into your QUEEN CASINO account using a credit card. It accepts most major credit cards supported by VISA and MasterCard.</p>
              </li>
              <li>
              <p><strong>CoinPayment</strong> is a virtual currency payment service that offers secure deposits and withdrawals in BTC, LTC, ETH, DOGE, DAI, and USDC.</p>
              </li>
            </ul>
            <h3><strong>Q: How do I make a deposit?</strong></h3>
            <p>A: You can make a deposit from your mobile or PC as follows;</p>
            <p style="padding-left: 30px;">[How to make a deposit on your mobile phone]<br>
            <strong>1.</strong> Login to your account<br>
            <strong>2.</strong> Tap the "Account Icon" in the upper right corner of the website<br>
            <strong>3.</strong> Tap "Deposit"<br>
            <strong>4.</strong> Select a deposit method<br>
            <strong>5.</strong> Enter the amount you wish to deposit.<br>
            <strong>6.</strong> Tap "Make Deposit" to complete your deposit.<br>
            <br>
            <br>
            [How to make a deposit on a PC]<br>
            <strong>1.</strong> Login to your account<br>
            <strong>2.</strong> Click "Deposit" in the upper right corner of the website.<br>
            Follow steps 4-6 above to complete your deposit.<br>
            <br>
            ※ Please note that depending on the payment method you use, you may be redirected to the relevant payment website to log in to the payment institution.</p>
            <h3><strong>Q: Can I make deposits and withdrawals on weekends and holidays?</strong></h3>
            <p>A: Yes. All deposit and withdrawal methods are available 24/7</p>
            <h3><strong>Q: What is the minimum deposit amount?</strong></h3>
            <p>A: 10 USD for ecoPayz and iWallet, 30 USD for WlandPay, and 20 USD for Bank Transfer and CoinPayment.</p>
            <h3><strong>Q: Is there a fee for making a deposit?</strong></h3>
            <p>A: No. There are no fees charged when you deposit funds into your QUEEN CASINO account.<br>
            However, depending on the payment institution you use, you may be charged a fee when transferring funds.</p>
            <h3><strong>Q: Is it safe to use my credit card at QUEEN CASINO?</strong></h3>
            <p>A: We do our best to protect the personal information provided by our customers. The security page of our website is encrypted using Secure Sockets Layer (SSL) 256-bit communication.<br>
            Credit card information is encrypted and is not stored in the QUEEN CASINO system, but is sent directly to the credit card processing company provided by QUEEN CASINO through an encrypted channel.<br>
            It is stored in the form of a "token" to be used as a reference for future transactions.</p>
            <h3><strong>Q: My account balance was not updated after I made a deposit, what should I do?</strong></h3>
            <p>A: Please try to log out manually and then log back in. If it still does not update, please contact our customer support team so that we can check the status of your deposit.</p>
            <h3><strong>Q: How do I make a withdrawal?</strong></h3>
            <p>A: You can withdraw via bank transfer, OnRamp, ecoPayz, iWallet, and CoinPayment. Please note that you can only use the same method you used to deposit money into your QUEEN CASINO account, unless you are notified of a different way to withdraw money.</p>
            <h3><strong>Q: What is the minimum withdrawal amount?</strong></h3>
            <p>A: 10 USD for ON RAMP, ecoPayz and iWallet, and 50 USD for bank transfer and CoinPayment.</p>
            <h3><strong>Q: How do I make a withdrawal request?</strong></h3>
            <p>A: While you are logged in, please follow the steps below.</p>
            <p style="padding-left: 30px;"><strong>1.</strong> Click on the account icon on the top right corner of the website<br>
            <strong>2.</strong> Select the withdrawal method<br>
            <strong>3.</strong> Enter your institution's account information and the amount you wish to withdraw.<br>
            <br>
            ・For bank transfer, enter your bank account information.<br>
            ・For OnRamp, enter your registered email address.<br>
            ・For ecoPayz and iWallet, enter your account number.<br>
            ・For CoinPayment, enter your wallet address<br>
            <br>
            <strong>4.</strong> Click "Withdrawal" to complete your withdrawal request.</p>
            <h3><strong>Q: Will I be charged a fee for withdrawals?</strong></h3>
            <p>A: No, there are no fees when you withdraw funds from your QUEEN CASINO account.<br>
            However, depending on the payment institution you use, you may be charged a fee when transferring funds.</p>
            <h3><strong>Q: Why can't I submit a withdrawal request after clicking on "Withdrawal"?</strong></h3>
            <p>A: There is a currently active withdrawal request. Once the withdrawal is completed, the withdrawal function will be activated.</p>
            <h3><strong>Q: When will my withdrawal be reflected?</strong></h3>
            <p>A: Once you have submitted all the necessary documents, your withdrawal will be reflected within 1-2 hours for CoinPayment and within 12 hours for other withdrawal methods.</p>
        `
      },
      {
        dt: 'ACCOUNT VERIFICATION',
        dd: `
          <h3><strong>Q: Do I need to submit identity verification documents?</strong></h3>
          <p>A: Yes, we ask for your identification documents to ensure that we can process your application smoothly. To expedite the process, please make sure that your registration information is always up-to-date and matches your identification documents.<br>
          By submitting your identification documents, you help us provide a safer playing environment for you.</p>
          <h3><strong>Q: Why do I need to verify my identity?</strong></h3>
          <p>A: Apart from our obligation to fulfill our regulations, the process of account verification must be completed in order to enable the withdrawal feature on your account.<br>
          The account verification process, or Know Your Customer, is the process by which your financial institution, bank, or payment platform verifies your identity.</p>
          <h3><strong>Q: What kind of documents do I need to submit?</strong></h3>
          <p>A: The following documents are usually required to verify your identity.</p>
          <p style="padding-left: 30px;"><strong>1.</strong> Identification card<br>
          &nbsp;&nbsp;&nbsp;&nbsp;Driver's license (both sides)<br>
          &nbsp;&nbsp;&nbsp;&nbsp;Passport (page with photo and page with address)<br>
          &nbsp;&nbsp;&nbsp;&nbsp;My Number Card (front side only)<br>
          &nbsp;&nbsp;&nbsp;&nbsp;Basic Resident Registration Card with photo (both sides)<br>
          (Any one of the above)<br>
          <br>
          Required fields are as follows;</p>
          <p style="padding-left: 30px;"><strong>✓</strong> Face photo</p>
          <p style="padding-left: 30px;"><strong>✓</strong> Name</p>
          <p style="padding-left: 30px;"><strong>✓</strong> Address</p>
          <p style="padding-left: 30px;"><strong>✓</strong> Date of birth</p>
          <p style="padding-left: 30px;"><strong>✓</strong> Expiration date</p>
          <p style="padding-left: 30px;"><strong>✓</strong> My Number digits (in case of My Number Card)</p>
          <p style="padding-left: 30px;"><strong>2.</strong> Proof of address (one document verifying the address within 3 months from the date of issue)</p>
          <p style="padding-left: 30px;"><strong>✓</strong> Certificate of residence</p>
          <p style="padding-left: 30px;"><strong>✓</strong> Water/electricity/gas bill/telephone bill with your address and date of issue (if mailed)</p>
          <p style="padding-left: 30px;"><strong>✓</strong> Credit card statement (sent by mail)</p>
          <p style="padding-left: 30px;">(Items other than those listed above will not be considered)</p>
          <p style="padding-left: 30px;">※ Please take photos of the documents you submit with your camera, smartphone, or cellphone, and include all four corners so that the photo is not cut off. Please make sure that the required fields are clearly legible when using a "flash" to take pictures.<br>
          File extensions that can be uploaded are JPEG, BMP, and PNG.</p>
          <h3><strong>Q: How do I submit my personal identification documents?</strong></h3>
          <p>A: After logging in, please do the following;</p>
          <p style="padding-left: 30px;">After logging in, please do the following;<br>
          <strong>1.</strong> Tap the "Account Icon" in the upper right corner of the website.<br>
          <strong>2.</strong> Tap "Identity Verification".<br>
          <strong>3.</strong> Tap "Select File" and upload your identification documents.<br>
          <strong>4.</strong> Tap "Save" to complete.</p>
          <p style="padding-left: 30px;">How to operate on a PC<br>
          <strong>1.</strong> Click the "Account Icon" in the upper right corner of the website.<br>
          <strong>2.</strong> Select "My Account".<br>
          <strong>3.</strong> Click on "Account Verification".<br>
          <strong>4.</strong> Click on "Select File" and upload your personal identification documents.<br>
          <strong>5.</strong> Click "Save" to complete.</p>
          <h3><strong>Q: Do I need to submit any other documents after completing the identity verification?</strong></h3>
          <p>A: When you deposit funds to your QUEEN CASINO account using a new payment method or credit card, you may be asked to submit additional documents.<br>
          This is to verify the ownership of the payment method being used and to ensure smooth processing of the withdrawal.</p>
          <p style="padding-left: 30px;">If necessary, please submit the following documents</p>
          <p style="padding-left: 30px;">Credit card (both sides and whole (including all four corners)</p>
          <p style="padding-left: 30px;"><strong>✓</strong> Card Name</p>
          <p style="padding-left: 30px;"><strong>✓</strong> Expiration date</p>
          <p style="padding-left: 30px;"><strong>✓</strong> First 6 digits and last 4 digits of the card number must be hidden in the photo. The CVV security number on the back of the card must also be hidden.</p>
          <p style="padding-left: 30px;"><strong>✓</strong> Signature part</p>
          <p style="padding-left: 30px;"><strong>Payment Institution</strong></p>
          <p style="padding-left: 30px;"><strong>✓</strong> A screenshot of the payment institution account showing the owner's details.</p>
          <p style="padding-left: 30px;"><strong>✓</strong> A screenshot of the statement showing the recent deposits made to the QUEEN CASINO account.</p>
          <p style="padding-left: 30px;"><br>
          ※ The screenshot you submit must capture and display the entire screen of the monitor. Screenshots with cropped corners will not be accepted.</p>
          <p style="padding-left: 30px;">※ Additional submissions should be sent to Customer Support at <a href="mailto:support@queen-casino.com?Subject=ACCOUNT VERIFICATION" target="_top">support@queen-casino.com</a>. Attached files (extension: JPEG, BMP or PNG)</p>
        `
      },
      {
        dt: 'Sports Betting',
        dd: `
          <h3><strong>Q: How do I place a bet?</strong></h3>
          <p>A: Select the sport you wish to bet on. You will see a list of events taking place and you can click on them to see the detailed game odds (multiplier). Once you have selected the event you are interested in, you will be prompted to enter the available stake (wager).<br>
          Enter the amount you wish to bet and click "Place Bet".</p>
          <h3><strong>Q: How do I place a bet on a live event?</strong></h3>
          <p>A: Please login with your registered account. After logging in, click on "In Play" and select the event you wish to bet on. Next, you will see a "Bet Slip" on the right side of the website.<br>
          Enter the amount you wish to bet and click "Place Bet". When your bet is approved, you will see a green "Approved" message.</p>
          <h3><strong>Q: What is the maximum amount I can bet?</strong></h3>
          <p>A: Each event has a different maximum stake (wager). To see the maximum stake amount for each event, click on the "Max Bet" option you have selected under "Bet Slip".</p>
          <h3><strong>Q: How can I check if I have placed a bet?</strong></h3>
          <p>A: You can check your betting history by going to "My Account" and clicking on "Settlement Bets" or "Pending Bets". If you have not placed any bets yet, you can check your "Pending Bets".</p>
          <h3><strong>Q: I placed a bet by mistake. Can I cancel it?</strong></h3>
          <p>A: Unfortunately, once a bet is placed, it cannot be cancelled. Please make sure you have made the correct selection when placing your bet.</p>
          <h3><strong>Q: I would like to know my results, what should I do?</strong></h3>
          <p>A: If you would like to know your results, please click on the account icon on the top right corner of the website. Next, click on 'Settlement Bets' and you will see the results.<br>
          If you are in the middle of a match or waiting for a settlement, it will be shown in the 'Pending Bets' section.</p>
          <h3><strong>Q: How long does it take for my bet to be settled?</strong></h3>
          <p>A: The website aims to settle bets as quickly as possible. However, for some events, we need time to check the official results. If your bet has not been settled for a long period of time, please contact our customer support team.</p>
          <h3><strong>Q: Does soccer include extra time? What if the game goes into extra time in baseball?</strong></h3>
          <p>A: For soccer, unless otherwise stated, all bets are settled for the stipulated time only (including intermissions) and do not include extra innings or penalty kicks.<br>
          In the case of baseball, all bets include extra innings unless otherwise stated. As mentioned above, the regulations differ depending on the competition, so please check the details here.<br>
          As mentioned above, the regulations differ depending on the competition, so please check the details <a href="/static/sportRules">here</a>.</p>
          <h3><strong>Q: What are the [Play icon] and [Soccer court icon] displayed in the screen?</strong></h3>
          <p>A: The [Play icon] and [Soccer court icon] are functions that allow you to enjoy live streaming. （The [Play icon] and [Soccer court icon] are features that allow you to enjoy live streaming (you must have a deposit history to use them).<br>
          <br>
          In the [Soccer court icon], you can enjoy Live Center (illustrated match progress). (You do not need to have a deposit history to use this feature.)）</p>
            `
      },
      {
        dt: 'Bonus',
        dd: `
          <h3><strong>Q: How do I receive my registration bonus?</strong></h3>
          <p>A: Please contact our 24/7 live chat service. Click on 'Live Chat' at the bottom right of the website and we will respond immediately.<br>
          You can also email us at <a href="mailto:support@queen-casino.com">support@queen-casino.com</a>.</p>
          <h3><strong>Q: How do I receive my deposit bonus?</strong></h3>
          <p>A: You can earn the bonus by clicking on the "Earn Bonus" button on the Bonus page before you make a deposit request.<br>
          If you do not want to receive the bonus, you can simply make a deposit without clicking the "Get Bonus" button and you will not receive the bonus.</p>
          <h3><strong>Q: Why can't I get a new bonus?</strong></h3>
          <p>A: You will not be able to get a new bonus if you have a remaining bonus balance. Please make sure that your bonus balance is zero.</p>
          <h3><strong>Q: Where can I check my bonus achievements?</strong></h3>
          <p>A: Tap the icon in the upper right corner of the QUEEN CASINO top screen, then tap [Bonuses].<br>
          In the active bonuses, there is an item called "Rollover".<br>
          The amount on the left side is the amount you have actually bet, and the amount on the right side is the amount you need to withdraw or cash out your bets.<br>
          Each bonus has a different valid bet amount and game category, so please check the respective promotion page for details.</p>
          <h3><strong>Q: I accidentally received a deposit bonus. Can I cancel it?</strong></h3>
          <p>A: Please contact our customer support immediately after making a deposit and do not play any games. It cannot be withdrawn after you have started playing.</p>
          <h3><strong>Q: The remaining fraction of my bonus has disappeared.</strong></h3>
          <p>A: The bonus balance will be automatically deleted when the amount is less than 1USD.<br>
          The purpose of this setting is to save you the trouble of having to play a game just to get your balance down to zero when you have a small amount of bonus left.<br>
          Many other casinos have this feature as well.</p>
          <h3><strong>Q: Is there a different percentage that counts as a wager amount for each game?</strong></h3>
          <p>A: It depends on the game as shown below.</p>
          <p style="padding-left: 30px;"><strong>100%</strong> Slots / Video Slots</p>
          <p style="padding-left: 30px;"><strong>100%</strong> Sports betting</p>
          <p style="padding-left: 30px;"><strong>100%</strong> eSports</p>
          <p style="padding-left: 30px;"><strong>100%</strong> Virtual games</p>
          <p style="padding-left: 30px;"><strong>15%</strong> Oasis Poker</p>
          <p style="padding-left: 30px;"><strong>15%</strong> Pontoon</p>
          <p style="padding-left: 30px;"><strong>15%</strong> Punto Banco</p>
          <p style="padding-left: 30px;"><strong>15%</strong> Caribbean Poker</p>
          <p style="padding-left: 30px;"><strong>15%</strong> Video Poker (all types)</p>
          <p style="padding-left: 30px;"><strong>15%</strong> Blackjack (all types)</p>
          <p style="padding-left: 30px;"><strong>15%</strong> Roulette (all types)</p>
          <p style="padding-left: 30px;"><strong>15%</strong> Baccarat (all types)</p>
          <p style="padding-left: 30px;"><strong>15%</strong> Instant games</p>
          <h3><strong>Q: Is there an expiration date for the bonus?</strong></h3>
          <p>A: There is an expiration date for receiving and cashing in (rollover).<br>
          Each bonus has a different expiration date, so please check the promotion page for details.</p>
          <h3><strong>Q: Is there a specific withdrawal limit for bonuses?</strong></h3>
          <p>A: Some bonuses have a specific amount that can be withdrawn. This is stated as the maximum withdrawal amount, please check the campaign terms and conditions.</p>
          <h3><strong>Q: Is there a specific maximum bet for the bonus?</strong></h3>
          <p>A: Each bonus has a different betting limit. You can bet over the betting limit, but it will not count towards your withdrawal requirements.<br>
          However, if you use your bonus to play with a wager amount that is not reflected in the rollover, and your subsequent play is determined to be abusive or fraudulent, your account may be restricted, including pending withdrawal requests, until we investigate and know the outcome of the investigation.</p>
          <h3><strong>Q: Is there a minimum odds limit for using the bonus on sports and eSports bets?？</strong></h3>
          <p>A: Each bonus has a specific payout multiplier that counts towards the withdrawal requirement. Please refer to each promotion page and campaign terms and conditions for details.</p>
          </div>
        `
      }
    ],
    footer: ''
  }
} as const
