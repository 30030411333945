'use client'
import { useEffect, useRef, useState } from 'react'
import { useUpdateEffect } from 'ahooks'
import { useTranslation } from '@/lib/locales/client'
import { useAuthStore } from '@/stores/authControl'
import { useUserStore } from '@/stores/user'
import { useAppStore } from '@/stores/app'
import parsePhoneNumberFromString from 'libphonenumber-js'
import Validtor from '@/hooks/validtor'
import useCountDown from '@/hooks/useCountDown'
import { playerCreateAccount, sendCodeByPhone } from '@/api/account'
import { cn } from '@/lib/utils'
import { Form, Input, Button, AutoComplete } from 'antd'
import type { AutoCompleteProps } from 'antd'
import styles from './_common_css/register.module.scss'
// import { webpHandle } from '@/hooks/aboutWebp'
import { useShallow } from 'zustand/react/shallow'
import { track } from '@/hooks/useTrack'
/* com */
import CountrySelect from '@/components/base/countrySelect'
import SliderCaptcha from '@/components/common/sliderCaptcha'

// register menu
const regMenuList = [
  { label: 'user.authDialog.regEmail', type: 'emailReg' },
  { label: 'user.authDialog.regPhone', type: 'phoneReg' }
]
const complateEmailEndList = ['@gmail.com', '@outlook.com', '@yahoo.com', '@icloud.com']

export default function Register() {
  const { t } = useTranslation()
  const { isBigScreen, countryCurInfo, limitLogin, updateLimitLoginDialog, SET_GLOBAL_MSG } = useAppStore(
    useShallow((state) => ({
      isBigScreen: state.isBigScreen,
      countryCurInfo: state.countryCurInfo,
      limitLogin: state.limitLogin,
      updateLimitLoginDialog: state.updateLimitLoginDialog,
      SET_GLOBAL_MSG: state.SET_GLOBAL_MSG
    }))
  )
  const { setThirdRmCode, setWebToken, updateUserInfo } = useUserStore(
    useShallow((state) => ({
      setThirdRmCode: state.setThirdRmCode,
      setWebToken: state.setWebToken,
      updateUserInfo: state.updateUserInfo
    }))
  )
  const { closeModal, SET_REG_SUC_INFO } = useAuthStore(
    useShallow((state) => ({
      closeModal: state.closeModal,
      SET_REG_SUC_INFO: state.SET_REG_SUC_INFO
    }))
  )
  const [form] = Form.useForm()
  // watch some special item
  const userName = Form.useWatch('username', form)
  const rmCode = Form.useWatch('rmCode', form)
  const emailReg = Form.useWatch('emailReg', form)
  const selectAreaRef = useRef(null) // 选择国家搜索
  const { textRef, start, flag } = useCountDown(60, t('user.authDialog.send'), '')
  const [isOpenCountry, setIsOpenCountry] = useState(false)
  const [hasRmCode, setHasRmCode] = useState(false)
  const [registerMenuList, setRegisterMenuList] = useState('emailReg')
  const [slideShowFlag, setSlideShowFlag] = useState(false)
  const [checkedReg, setCheckedReg] = useState(true)
  const [nameRepeat, setNameRepeat] = useState(false)
  const [emailRepeat, setEmailRepeat] = useState(false)
  const [emailOptions, setEmailOptions] = useState<AutoCompleteProps['options']>([])

  useEffect(() => {
    const code = localStorage.getItem('agent-info-local')
    if (code) {
      // setFormState({ rmCode: code })
      // form.setFieldValue({rmCode: code})
      form.setFieldsValue({ rmCode: code })
      setHasRmCode(true)
    } else {
      setHasRmCode(false)
    }
  }, [])

  /* 注册发送验证码 */
  function sendPhoneVerify(evt) {
    const checkPhoneNum = form.getFieldError('phoneReg') // 手机号验证成功才会继续
    evt.stopPropagation()
    if (!flag || !form.getFieldValue('phoneReg') || checkPhoneNum.length) return
    sendCodeByPhone({
      purpose: 1,
      phonePrefix: '00' + countryCurInfo.code,
      phone: form.getFieldValue('phoneReg')
    }).then((res: any) => {
      SET_GLOBAL_MSG({ flag: true, type: 'success', content: t('user.authDialog.sendSuc') })
      if (res.success) start() // 开始60秒倒计时
    })
  }

  // term
  const handleClick = (e) => {
    if (e.target.className === 'le_fh') return
    setCheckedReg(!checkedReg)
  }

  //  邮箱添加自动补全后缀
  // const ThirdSupport = ['google', 'twitter', 'line']
  const supplyEmailSuffix = (value: string) => {
    setEmailOptions(() => {
      // 不存在，或中文 或 已有@
      if (!value || /[\u4E00-\u9FA5]/.test(value) || value.includes('@')) {
        return []
      } else {
        return complateEmailEndList.map((domain) => ({
          label: value + domain,
          value: value + domain
        }))
      }
    })
  }

  useUpdateEffect(() => {
    if (nameRepeat) {
      setNameRepeat(false)
    }
  }, [userName])

  //需求，输入框里有rmcode 第三方注册也要获取到
  useUpdateEffect(() => {
    if (rmCode) {
      setThirdRmCode(rmCode)
    }
  }, [rmCode])

  useUpdateEffect(() => {
    if (emailRepeat) {
      setEmailRepeat(false)
    }
  }, [emailReg])

  // go register
  const register = async (verifyData = {}) => {
    const {
      userName,
      password,
      rmCode = '',
      code,
      phoneReg,
      emailReg
    } = form.getFieldsValue(['userName', 'password', 'rmCode', 'code', 'phoneReg', 'emailReg'])
    if (limitLogin) {
      updateLimitLoginDialog(true)
      return
    }
    if (!checkedReg) {
      SET_GLOBAL_MSG({ flag: true, type: 'warning', content: t('user.termsChecked') })
      return
    }
    // 提交前再判断一次
    try {
      // 如果未验证滑块
      if (!Object.keys(verifyData).length) {
        setSlideShowFlag(true)
        return
      }
      const _status = registerMenuList === 'emailReg'
      const _address = _status ? emailReg : phoneReg
      // const affiliateId = route.query['affiliate_id'] || ''  // 待处理
      // const btag = window.sessionStorage.getItem('btag')
      // const stag = window.sessionStorage.getItem('stag')
      let params
      if (_status) {
        params = {
          ...verifyData,
          password: password?.toLowerCase(),
          loginName: 'qc' + userName?.toLowerCase(),
          referCode: rmCode?.toLowerCase(),
          email: _address,
          btag: '',
          affiliateId: ''
        }
      } else {
        // phone
        params = {
          ...verifyData,
          affiliateId: '',
          btag: '',
          mobileCode: code,
          mobileNo: phoneReg,
          phonePrefix: '00' + countryCurInfo.code,
          referCode: rmCode?.toLowerCase()
        }
      }
      const res: any = await playerCreateAccount(params)
      // console.log('注册 res', res)
      if (res.success) {
        if (_status) {
          setWebToken(res.data.tokenInfo)
          await updateUserInfo(res.data.loginName, 1)
          closeModal()
          track('RegisterSuccess', { loginName: res.data.loginName })
        } else {
          // phone
          setWebToken(res.data.tokenInfo)
          closeModal()
          SET_REG_SUC_INFO({ flag: true })
        }
      }
      window.sessionStorage.setItem('inviteCode', '') // 使用session缓存inviteCode，关闭页面再进入后清空
    } catch (error) {
      // console.log('err', error)
      // 登录名已存在
      if (Number(error.code == 100010)) {
        setNameRepeat(true)
        form.validateFields(['userName'])
      }

      if (Number(error.code === 100919)) {
        setEmailRepeat(true)
        form.validateFields(['emailReg'])
      }
    } finally {
      verifyData = {}
    }
  }

  const focusHandle = (evt) => {
    // 获取焦点时 判断地区选择是否有打开
    isOpenCountry ? selectAreaRef.current?.focus() : selectAreaRef.current?.blur()
  }

  function chooseType(item) {
    setRegisterMenuList(item.type)
    onReset()
  }

  const captchaCallback = async (params) => {
    setSlideShowFlag(false)
    await register(params)
  }

  // post register
  const onFinish = async (values: any) => {
    // console.log('Received values of form: ', values)
    return await register()
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }

  const onReset = () => {
    form.resetFields()
  }

  return (
    <>
      <article className={cn(styles['register-modal'], !isBigScreen && styles['h5'])} id="user-register">
        {/* 菜单 */}
        <div className="menu-list">
          {regMenuList &&
            regMenuList.map((item, index) => (
              <span
                key={index}
                className={cn(registerMenuList == item.type && 'active-menu')}
                onClick={() => chooseType(item)}
              >
                {t(item.label)}
              </span>
            ))}
        </div>
        <Form
          form={form}
          validateTrigger="onBlur"
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          {registerMenuList === 'emailReg' ? (
            <>
              <Form.Item
                name="emailReg"
                className="!mb-[24px]"
                required={true}
                validateTrigger={['onBlur', 'onChange']}
                rules={[
                  {
                    validator: async (_rule, names) => {
                      if (!names) {
                        return Promise.reject(t('common.validtor.emailEpty'))
                      }
                      if (!Validtor.email(names) || names.length < 4) {
                        return Promise.reject(t('common.validtor.emailError'))
                      }
                      if (emailRepeat) {
                        return Promise.reject(t('user.lostPop.emailRepeat'))
                      }
                      return Promise.resolve()
                    }
                  }
                ]}
              >
                <AutoComplete
                  onSearch={supplyEmailSuffix}
                  style={{ height: 42 }}
                  defaultActiveFirstOption
                  getPopupContainer={() => document.getElementById('user-register')}
                  popupClassName={'email-select'}
                  options={emailOptions}
                >
                  <Input
                    placeholder={t('user.authDialog.EmailPlace')}
                    allowClear={{
                      clearIcon: (
                        <img
                          src="/static/images/common/input_clear_x.svg"
                          style={{ marginLeft: '10px', width: '18px' }}
                        />
                      )
                    }}
                    onChange={(e) =>
                      form.setFieldsValue({ emailReg: e.target.value.replace(/[\u4E00-\u9FA5]/g, '') })
                    }
                  />
                </AutoComplete>
              </Form.Item>
              {/* userName */}
              <Form.Item
                name="userName"
                className="!mb-[24px]"
                required={true}
                validateTrigger={['onChange']}
                rules={[
                  {
                    validator: async (_rule, names) => {
                      if (!names) {
                        return Promise.reject(t('common.validtor.usenameEpty'))
                      }
                      if (!Validtor.username(names)) {
                        return Promise.reject(t('common.validtor.nameErr'))
                      }
                      if (nameRepeat) {
                        return Promise.reject(t('user.lostPop.nameRepeat'))
                      }
                      return Promise.resolve()
                    }
                  }
                ]}
              >
                <Input
                  placeholder={t('user.lostPop.userName')}
                  allowClear={{
                    clearIcon: (
                      <img
                        src="/static/images/common/input_clear_x.svg"
                        style={{ marginLeft: '10px', width: '18px' }}
                      />
                    )
                  }}
                  onChange={(e) =>
                    form.setFieldsValue({ userName: e.target.value.replace(/[\u4E00-\u9FA5]/g, '') })
                  }
                  prefix={
                    <div className="p-[10px] pl-0 text-base font-semibold leading-[20px] text-[#FC005B]">
                      QC
                    </div>
                  }
                />
              </Form.Item>
              {/* password */}
              <Form.Item
                name="password"
                className="!mb-[24px]"
                required={true}
                validateTrigger={['onBlur', 'onChange']}
                rules={[
                  {
                    validator: async (_rule, names) => {
                      if (!names) {
                        return Promise.reject(t('common.validtor.passwordEpty'))
                      }
                      if (!Validtor.password(names)) {
                        return Promise.reject(t('common.validtor.passwordError'))
                      }
                      return Promise.resolve()
                    }
                  }
                ]}
              >
                <Input.Password
                  placeholder={t('user.authDialog.authPassword')}
                  autoComplete="new-password"
                  allowClear={{
                    clearIcon: (
                      <img
                        src="/static/images/common/input_clear_x.svg"
                        style={{ marginLeft: '10px', width: '18px' }}
                      />
                    )
                  }}
                  iconRender={(visible) =>
                    visible ? (
                      <img
                        src="/static/images/user/login/icon-show.svg"
                        style={{ marginLeft: '10px', width: '18px' }}
                      />
                    ) : (
                      <img
                        src="/static/images/user/login/icon-hidden.svg"
                        style={{ marginLeft: '10px', width: '18px' }}
                      />
                    )
                  }
                />
              </Form.Item>
            </>
          ) : (
            <>
              {/* phone register */}
              <Form.Item
                name="phoneReg"
                className="!mb-[24px]"
                required={true}
                validateTrigger={['onChange']}
                rules={[
                  {
                    validator: async (_rule, value) => {
                      if (!value) {
                        return Promise.reject(t('common.validtor.phoneEpty'))
                      }
                      let _short = countryCurInfo.countryCode || 'JP'
                      let _phVerify = parsePhoneNumberFromString(value, _short)
                      if (_short === 'CN' && !Validtor.phoneRegular(value)) {
                        return Promise.reject(t('common.validtor.phoneVerify'))
                      }
                      if (!!_phVerify && !_phVerify.isValid()) {
                        return Promise.reject(t('common.validtor.phoneVerify'))
                      }
                      return Promise.resolve()
                    }
                  }
                ]}
              >
                <Input
                  placeholder={t('user.authDialog.phonePlace')}
                  type="number"
                  onFocus={focusHandle}
                  prefix={
                    <CountrySelect
                      domId={'country-select'}
                      ref={selectAreaRef}
                      isOpenCountry={isOpenCountry}
                      setIsOpenCountry={setIsOpenCountry}
                    />
                  }
                />
              </Form.Item>
              {/* sms code */}
              <Form.Item
                name="code"
                className="!mb-[6px]"
                required={true}
                validateTrigger={['onChange']}
                rules={[
                  {
                    validator: async (_rule, names) => {
                      if (!names || names.length < 6) {
                        return Promise.reject(t('common.validtor.codeError'))
                      }
                      return Promise.resolve()
                    }
                  }
                ]}
              >
                <Input
                  placeholder={t('user.authDialog.authCodePlace')}
                  onChange={(e) => form.setFieldsValue({ code: e.target.value.replace(/\D/gi, '') })}
                  suffix={
                    <div
                      className="mr-[4px] cursor-pointer text-sm font-semibold text-[#3F75FF]"
                      onClick={sendPhoneVerify}
                    >
                      {/* <div className={'right-send'} onClick={sendPhoneVerify}> */}
                      {textRef}
                    </div>
                  }
                />
              </Form.Item>
              {/* send code tips */}
              <Form.Item name="sendTips" noStyle>
                {/* 未收到验证码-联系客服 */}
                <p
                  onClick={() => typeof window !== undefined && window?.Intercom('show')}
                  className={cn(
                    '!relative mb-[6px] cursor-pointer text-right text-xs font-normal text-black/65'
                  )}
                >
                  {t('user.authDialog.sendTips')}
                </p>
                {/* )} */}
              </Form.Item>
            </>
          )}
          {/* refCode */}
          <Form.Item
            name="rmCode"
            className="!mb-[16px]"
            validateTrigger={['onBlur', 'onChange']}
            rules={[
              {
                validator: async (_rule, names) => {
                  if (names && names.length > 20) {
                    return Promise.reject('Maximum 20 code! ')
                  }
                  return Promise.resolve()
                }
              }
            ]}
          >
            <Input
              placeholder={t('user.authDialog.refCodePlace')}
              readOnly={hasRmCode}
              allowClear={{
                clearIcon: (
                  <img
                    src="/static/images/common/input_clear_x.svg"
                    style={{ marginLeft: '10px', width: '18px' }}
                  />
                )
              }}
            />
          </Form.Item>
          {/* terms */}
          <div className={cn('terms-option', 'mb-[16px]')} onClick={(e) => handleClick(e)}>
            {checkedReg ? <div className={cn('check-img', 'active')} /> : <div className={'check-img'} />}
            <div
              className={cn('text-xs font-normal not-italic text-black/65', 'terms-tips')}
              dangerouslySetInnerHTML={{ __html: t('user.authDialog.termsTips') }}
            />
          </div>

          {/* 按钮提交 */}
          <Form.Item name="loginSumbit">
            <Button
              block
              className="!h-[46px] w-full cursor-pointer self-stretch !rounded-none border-none px-[40px] py-[10px]"
              style={{
                background: '#FC005B',
                boxShadow: ' 0px 2px 0px 0px rgba(0, 0, 0, 0.15)'
              }}
              type="primary"
              htmlType="submit"
            >
              <span className="text-lg font-bold text-white">{t('user.authDialog.dReg')}</span>
            </Button>
          </Form.Item>
        </Form>
        {/* 滑动验证模块 */}
        {slideShowFlag && (
          <SliderCaptcha
            use={1}
            fullTxt={t('user.tips')}
            loginName={'qc' + form.getFieldValue('userName')}
            callback={captchaCallback} // 直接执行
            close={() => setSlideShowFlag(false)}
          />
        )}
      </article>
    </>
  )
}
