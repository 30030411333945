import React from 'react'
import { Select, Spin, Input } from 'antd'
import styles from './index.module.scss'
import { cn } from '@/lib/utils'
const { Option } = Select

const SearchSelect = (props) => {
  const {
    value,
    onChange,
    options,
    loading,
    placeholder,
    disabled,
    showSearch,
    prefixIcon,
    styleType = ''
  } = props
  const handleChange = (selectedValue) => {
    if (onChange) {
      onChange(selectedValue)
    }
  }

  const renderOptions = () => {
    return options && options.length > 0 ? (
      options.map((item) => (
        <Option key={item.value} value={item.value}>
          {item.label}
        </Option>
      ))
    ) : (
      <Option value="" disabled>
        No options available
      </Option>
    )
  }

  return (
    <div className={styles['currency-warp']}>
      {/* 左侧图标 */}
      <div className="icon-box">{prefixIcon ? prefixIcon : ''}</div>
      <Select
        className={cn('currency-select', styleType)}
        popupClassName={styles['currency-popup']}
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        loading={loading}
        disabled={disabled}
        showSearch={showSearch}
        optionFilterProp="children" // 使用 children 作为过滤的依据
        filterOption={(input, option: any) => option.children.toLowerCase().includes(input.toLowerCase())}
      >
        {loading ? (
          <Option value="">
            <Spin size="small" />
          </Option>
        ) : (
          renderOptions()
        )}
      </Select>
    </div>
  )
}

export default SearchSelect
