'use client'
import { useState, useEffect, useMemo } from 'react'
import { styled } from 'styled-components'
import { cn } from '@/lib/utils'
import { useTranslation } from '@/lib/locales/client'
import { useAppStore } from '@/stores/app'
import { useParams, useRouter } from 'next/navigation'
import { Modal } from 'antd'
import 'swiper/css'
import 'swiper/css/pagination'
import { CloseOutlined } from '@ant-design/icons'
import { Autoplay, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import RenderHtml from '@/components/base/renderHTML'
import { addDays, subDays } from 'date-fns'
import { getSysContent } from '@/api/notice'
import './_common_css/index.scss'
import styles from './_common_css/noticeDialog.module.scss'

const NoticeDialog = () => {
  const { t } = useTranslation()
  const router = useRouter()
  const { lng } = useParams()
  const { isBigScreen, isShowNoticeSysDialog, setIsShowNoticeSysDialog } = useAppStore()
  const [homeNoticeList, setHomeNoticeList] = useState([])
  const [checked, setChecked] = useState(false)

  const handleClose = () => {
    setIsShowNoticeSysDialog(false)
  } 
  const changeCheck = () => {
    setChecked(!checked)
    if (checked) {
      const today = new Date().getDate()
      localStorage.setItem('lastShowNoticeSysDialog', today.toString())
    }
  }

  function getTheSysContent() {
    let params = {
      pageSize: 10,
      pageNo: 1,
      batchId: -1, //-1 批量人工发送，-1系统自动发送
      isDeleted: 0,
      createdDateBegin: subDays(new Date(), 7).toISOString(),
      createdDateEnd: new Date().toISOString()
    }

    getSysContent(params).then((res: any) => {
      if (res.success) {
        const data = JSON.parse(localStorage.getItem('InitHomeMessageList'))
        if (data) {
          const oldMessageList = data
          const newList = res.data.data.filter((item) => item.isShow === 1)
          const diffList = newList.filter((item) => !oldMessageList.some((item2) => item2.id === item.id))
          setHomeNoticeList(diffList)
          localStorage.setItem('InitHomeMessageList', JSON.stringify(newList))
        } else {
          setHomeNoticeList(res.data.data.filter((item) => item.isShow === 1))
          localStorage.setItem('InitHomeMessageList', JSON.stringify(homeNoticeList))
        }
      }
    })
  }

  useEffect(() => {
    if (homeNoticeList.length > 0) {
      setIsShowNoticeSysDialog(true)
      console.log(homeNoticeList, 'homeNoticeList')
    }
  }, [homeNoticeList])

  const goMessagePage = (info) => {
    if (info) {
      sessionStorage.setItem('homeNoticeDetail', JSON.stringify(info))
      router.push(`/${lng}/account/notice?id=homeNoticeDetail`)
      // --- 待处理
      // router.push({
      //   path: "/account/notice",
      //   query: {
      //     id: "homeNoticeDetail"
      //   }
      // })
      setIsShowNoticeSysDialog(false)
    }
  }

  useEffect(() => {
    getTheSysContent()
  }, [])

  return (
    <>
      <Modal
        className={cn(styles[isBigScreen && 'pc'], styles['notice-dialog-globle'])}
        key={'notice'}
        open={isShowNoticeSysDialog}
        footer="null"
        maskClosable={false}
        closable={false}
        centered
      >
        <div className="notice-dialog-globle-content">
          <Swiper
            className="swiper notice-swiper"
            modules={[Autoplay, Pagination]}
            grabCursor
            slidesPerView={'auto'}
            spaceBetween={10}
            centeredSlides
            pagination={{
              clickable: true
            }}
            autoplay={{
              delay: 8000
            }}
          >
            {homeNoticeList.map((item, index) => (
              <SwiperSlide key={index} className="notice-swiper-slide">
                <div className="swiper-content">
                  <div className="notice-swiper-slide-text">
                    <div className="top-wrap">{t('withdraw.notify')}</div>
                    <div className="title">{item.subject}</div>
                    <RenderHtml html={item.content} className="text-content scrollbar-grey"></RenderHtml>
                    <div className="btns">
                      <div
                        className="detail-btn solitics_NoticeDetailsBtn"
                        onClick={() => goMessagePage(item)}
                      >
                        {t('notice.detailBtn')}
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div onClick={handleClose} className="notice-dialog-close solitics_NoticeCloseBtn">
          <CloseOutlined />
        </div>
      </Modal>
    </>
  )
}

export default NoticeDialog
