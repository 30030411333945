//用户钱包相关
import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

type IWalletStore = {
  currency: string
  bankToBankInfo: any
  _hasHydrated: boolean
  setHasHydrated: (hydState: boolean) => void
  updateCurrency: (state: string) => void
  updateBankToBankInfo: (obj: any) => void
}

// 创建状态存储
export const useWalletStore = create<IWalletStore>()(
  persist(
    immer((set, get) => ({
      currency: 'USD',
      bankToBankInfo: {},
      _hasHydrated: false,
      //actions
      updateCurrency: (state: string) => {
        set({ currency: state })
      },
      updateBankToBankInfo: (state: any) => {
        if (state.bankName) {
          state.canEndTime = new Date().getTime() + 5 * 60 * 1000
          state.willExpiraTime = new Date().getTime() + 15 * 60 * 1000
        }
        set({ bankToBankInfo: state })
      },
      setHasHydrated: (state) => {
        set({
          _hasHydrated: state
        })
      }
    })),
    {
      name: 'walletStore', // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
      merge: (persistedState, currentState) => ({ ...currentState, ...(persistedState as IWalletStore) }),
      onRehydrateStorage: (state) => {
        return () => state.setHasHydrated(true)
      }
    }
  )
)
