import { FC, useEffect, memo } from 'react'
import { Modal } from 'antd'
import { useTranslation } from '@/lib/locales/client'
import { px2Scalepx } from '@/hooks/useRem'
import { useAppStore } from '@/stores/app'

type IProps = {
  updateValue?: (val: boolean) => {}
  visible: boolean // false
}

const LockedDialog: FC<IProps> = (props) => {
  // const emits = defineEmits(["update:visible", "showLost2faPopup", "toLogin"])
  const { t } = useTranslation()
  const isBigScreen = useAppStore((state) => state.isBigScreen)
  useEffect(() => {
    // props.updateValue(props.visible)
  }, [props.visible])

  return (
    <Modal
      open={props.visible}
      centered
      footer={null}
      keyboard={false}
      width={px2Scalepx(360)}
      zIndex={2001}
      className="block rounded-[6px] bg-white"
    >
      <p className="mb-[20px] text-[16px] leading-[20px]">{t('user.lostPop.lockUserMsgs1')}</p>
      {/* content 没看到样式 */}
      <p className="content">{t('user.lostPop.lockUserMsgs2')}</p>

      <div
        className="mx-auto mb-0 mt-[16px] h-[42] w-[120px] cursor-pointer rounded-[4px] bg-[#0b80dc] text-center leading-[42px] text-white"
        onClick={() => props.updateValue(false)}
      >
        {t('user.confirm')}
      </div>
    </Modal>
  )
}

export default memo(LockedDialog)
