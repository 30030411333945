'use client'
import { useTranslation } from '@/lib/locales/client'
import { FollowSocialMedia } from '@/constants/followUs'

export default function NotFoundPage() {
  const { t } = useTranslation()

  return (
    <div className="qc-flex-center">
      <div className="z-9999 relative min-h-full w-full bg-[url('/static/images/system/bg.jpg')] bg-cover bg-no-repeat px-[25px] pb-[27px] pt-[8px] pc:flex pc:w-[1000px] pc:items-center pc:justify-center pc:bg-[url('/static/images/system/bg-pc.jpg')] pc:p-0 pc:text-black">
        <div className="mt-[10px] flex w-full flex-col justify-around pc:mt-[100px] pc:w-[1000px] pc:flex-row">
          <div className="flex flex-col items-center">
            <img
              className="mx-[24px] my-[15px] h-[56px] w-[138px]"
              src="/static/images/system/logo.png"
              alt=""
            />
            <img
              className="h-[50px] w-[80px] pc:h-[176px] pc:w-[233px]"
              src="/static/images/system/404.png"
              alt=""
            />
          </div>
          <div className="pc:-mt-[76px]">
            <h1 className="hidden text-center text-2xl font-bold text-[#cc004a] pc:mb-[50px] pc:text-[40px]">
              {t('system.404.tips404')}
            </h1>
            <div className="mt-[16px] text-left text-sm text-white pc:w-[460px]">
              <div>
                <p className="mb-[16px] font-bold pc:mb-[32px] pc:text-2xl">{t('system.404.tips')}</p>
                <p className="pc:text-xl">{t('system.404.tip1')}</p>
                <p className="pc:text-xl">{t('system.404.tip2')}</p>
                <p className="pc:text-xl">{t('system.404.tip3')}</p>
              </div>
              <div className="mx-0 mr-[22px] mt-[32px] pc:mb-[40px]">
                <p>{t('system.404.tipResult')}</p>
              </div>
            </div>
            <div className="pc:mb-[32px]">
              <p className="mb-[16px] text-center text-base text-white pc:mb-[32px]">{t('system.qcTeam')}</p>
              <div className="mx-auto flex w-[260px] items-center justify-between pc:w-[360px]">
                {FollowSocialMedia.map((item, key) => (
                  <a target="_blank" key={key} href={item.path} title={item.name}>
                    {item.name && (
                      <img
                        src={`/static/images/system/${item.name}.png`}
                        className="h-[30px] w-[30px] pc:h-[40px] pc:w-[40px]"
                        alt="item.name"
                      />
                    )}
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
