import { useAppStore } from '@/stores/app'
import styles from './_common_css/previewDialog.module.scss'
import { Modal } from 'antd'
import { useShallow } from 'zustand/react/shallow'

const PreviewDialog = () => {
  const { isBigScreen, previewDialogData, updatePreviewDialog } = useAppStore(
    useShallow((state) => ({
      isBigScreen: state.isBigScreen,
      previewDialogData: state.previewDialogData,
      updatePreviewDialog: state.updatePreviewDialog
    }))
  )

  const handleClose = () => {
    updatePreviewDialog({
      visible: false,
      gameData: {}
    })
  }

  return (
    <>
      <Modal
        className={styles['lee-bet-preview-dialog']}
        open
        width={isBigScreen ? '442px' : '92%'}
        centered
        footer={null}
        maskClosable={false}
        keyboard={false}
        onCancel={handleClose}
      >
        <img src="/static/images/activity/bettting/corner.png" className="corner corner1" />
        <img src="/static/images/activity/bettting/corner.png" className="corner corner2" />
        <img src="/static/images/activity/bettting/corner.png" className="corner corner3" />
        <img src="/static/images/activity/bettting/corner.png" className="corner corner4" />
        <div className="title">{previewDialogData.gameData.guessGameName}</div>
        {/* 待处理 */}
        <video
          className="guide-video"
          src={previewDialogData.gameData.trailerUrl}
          controls
          loop={false}
          autoPlay
          playsInline
          //  webkitPlaysinline
          //  x5PlaysInline
        />
      </Modal>
    </>
  )
}

export default PreviewDialog
