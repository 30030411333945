'use client'
import { useEffect, useState } from 'react'
import i18next from 'i18next'
import { initReactI18next, useTranslation as useTranslationOrg } from 'react-i18next'
import { useCookies } from 'react-cookie'
// import { useSetCookie, useGetCookie } from 'cookies-next';
// import { setCookie, getCookie } from 'cookies-next'
import resourcesToBackend from 'i18next-resources-to-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { Lng, resources, languages } from './i18n'
// import { useAppStore } from '@/stores/app'
import { getOptions, COOKIE_LANG, DEFAULT_NS } from './settings'
import { useParams } from 'next/navigation'

const runsOnServerSide = typeof window === 'undefined'

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(resourcesToBackend(resources))
  .init({
    ...getOptions(),
    lng: undefined, // let detect the language on client side
    detection: {
      order: ['path', 'htmlTag', 'cookie', 'navigator', 'localStorage']
    },
    // interpolation: {
    //   escapeValue: false, // 禁用字符转义
    // },
    preload: runsOnServerSide ? languages : []
  })

export function useTranslation() {
  const ns = DEFAULT_NS
  const { lng }: { lng: Lng } = useParams()
  const [cookies, setCookie] = useCookies([COOKIE_LANG])
  // console.log('cookies语言', cookies, lng)
  const ret = useTranslationOrg(ns)
  const { i18n } = ret
  if (runsOnServerSide && lng && i18n.resolvedLanguage !== lng) {
    i18n.changeLanguage(lng)
  } else {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [activeLng, setActiveLng] = useState(i18n.resolvedLanguage)
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if (activeLng === i18n.resolvedLanguage) return
      setActiveLng(i18n.resolvedLanguage)
    }, [activeLng, i18n.resolvedLanguage])
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if (!lng || i18n.resolvedLanguage === lng) return
      i18n.changeLanguage(lng)
    }, [lng, i18n])
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if (cookies.i18next === lng) return
      setCookie(COOKIE_LANG, lng, { path: '/' })
    }, [lng, cookies.i18next])
  }
  return ret
}
