'use client'
import React, { memo, useEffect, useState } from 'react'
import { queryGuessGameList, getActLotConfigList, getActLotPrize } from '@/api/activity'
import styles from './index.module.scss'
import RewardSwiper from '../rewardSwiper'
import RewardCard from '../rewardCard'
import { DefaultList } from '../defaultPromo'
import { useTranslation } from '@/lib/locales/client'
import { useAppStore } from '@/stores/app'
import { useShallow } from 'zustand/react/shallow'

const RewardSpecialBonus = () => {
  const [spinning, setSpinning] = useState(false)
  const [specialList, setSpecialList] = useState([])
  const { t } = useTranslation()
  const { lotteryTicketConfig } = useAppStore(
    useShallow((state) => ({ lotteryTicketConfig: state.lotteryTicketConfig }))
  )
  const getGuessGameList = () => {
    queryGuessGameList({}).then(({ success, data }) => {
      if (success && data.length > 0) {
        const list = data
        const el = list.reduce((prev: any, next: any) => {
          return prev.poolTotalAmount > next.poolTotalAmount ? prev : next
        }, list[0])
        const item = DefaultList.filter((ff) => ff.rewardType === 'guess')[0]
        setSpecialList((prevState) => [
          ...prevState,
          {
            ...item,
            awardMoney: el.poolTotalAmount,
            awardCurrency: el.poolCurrency,
            endTime: el.activityDateEnd,
            expireTime: el.activityDateEnd,
            guessGameName: el.guessGameName
          }
        ])
      }
    })
  }

  const getMyPrize = () => {
    const { actCode, actEndDate } = lotteryTicketConfig
    if (!actCode || actCode < 20241008) return
    getActLotPrize({ actCode, hideLoading: true })
      .then(({ success, data }) => {
        if (success && data?.bigPrizeLevel) {
          const el = data.bigPrizeLevel.sort((a, b) => a.targetBetAmount - b.targetBetAmount)[0]
          if (el) {
            const item = DefaultList.find((el) => el.rewardType === 'lucky_draw') || {}
            setSpecialList((prevState) => [
              ...prevState,
              {
                ...item,
                endTime: actEndDate,
                luckyDrawName: el.prizeName,
                defaultUrl: lotteryTicketConfig.drawUrl
              }
            ])
          }
        }
      })
      .finally(() => {
        setSpinning(false)
      })
  }
  useEffect(() => {
    if (lotteryTicketConfig.actCode) {
      getMyPrize()
    }
  }, [lotteryTicketConfig])

  useEffect(() => {
    getGuessGameList()
  }, [])

  return (
    <div className="reward-special">
      <RewardSwiper
        swipData={specialList}
        spinning={spinning}
        cardType="special"
        render={(item: any, key: string) => (
          <RewardCard cardInfo={item} cardType="special" key={key}>
            {item.rewardType == 'lucky_draw' && (
              <>
                <div className={styles['reward-lucky']}>
                  <div className="progress-warp">
                    <div className="bar">
                      <i
                        style={{
                          width:
                            ((lotteryTicketConfig.amount % lotteryTicketConfig.sourceAmount) /
                              lotteryTicketConfig.sourceAmount) *
                              100 +
                            '%'
                        }}
                      ></i>
                    </div>
                    <div className="icon">+{lotteryTicketConfig.sourceNum || 1}</div>
                  </div>
                  <div className="text-warp">
                    <p className="text">
                      <span>{t('activity.lottery.totalTicket')}</span>
                      {lotteryTicketConfig.totalNum}
                    </p>
                    <p className="text">
                      <span>{t('activity.lottery.totalWagered')}</span>${lotteryTicketConfig.amount} / $
                      {lotteryTicketConfig.sourceAmount}
                    </p>
                  </div>
                </div>
              </>
            )}
          </RewardCard>
        )}
      ></RewardSwiper>
    </div>
  )
}

export default memo(RewardSpecialBonus)
