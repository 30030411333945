import { getGameList, getPachinkoList, getAmigoInfo } from '@/api/game'
import { useAppStore } from '@/stores/app'
import { useGameStore } from '@/stores/game'
import { useState, useRef } from 'react'
import { useSetState, useUpdateEffect } from 'ahooks'
import { useShallow } from 'zustand/react/shallow'

export const useGameData = (pageType) => {
  const { isBigScreen, language } = useAppStore(
    useShallow((state) => ({
      isBigScreen: state.isBigScreen,
      language: state.language
    }))
  )
  const liveLimitList = useGameStore((state) => state.liveGameBetLimit)
  const favoriteGameList = useGameStore((state) => state.favoriteGameList)
  const [filterGameList, setFilterGameList] = useState([])
  const [totalCount, setTotalCount] = useState(0)
  const useSearchType: any = useRef({})
  const [isLoding, setIsLoding] = useState(false)
  const getGameData = (gameType = '', pageNo = 1) => {
    if (gameType) {
      if (gameType === 'favorite') {
        let ids = []
        ids = favoriteGameList.map((e) => {
          return e.gameId
        })
        useSearchType.current.gameIds = ids
      } else {
        useSearchType.current.gameTypes = [gameType]
      }
    }
    let gameListArr = []
    if (gameType !== 'pachinko') {
      const obj = {
        ...useSearchType.current,
        pageSize: isBigScreen ? (pageType === 'lobbyPage' ? 18 : 12) : 9,
        language: language,
        visibleType: 1,
        pageNo
      }
      if (
        obj.gameCategories?.[0] &&
        obj.gameCategories[0].split('-')[0] === 'limit' &&
        liveLimitList.length > 0
      ) {
        const limitType = obj.gameCategories[0].split('-')[1]
        const limitItem = [
          { min: 0.5, max: 20000 },
          { min: 1, max: 25000 },
          { min: 50, max: 10000 },
          { min: 100, max: 20000 },
          { min: 1000, max: 25000 },
          { min: 25000, max: 9999999999 }
        ][limitType]
        const hasOverlap = (arr1, arr2) => {
          const min = [Math.min(...arr1), Math.min(...arr2)]
          const max = [Math.max(...arr1), Math.max(...arr2)]
          return Math.max(...min) <= Math.min(...max)
        }
        const gameIds = liveLimitList
          .filter((el) => hasOverlap([el.minBetAmount, el.maxBetAmount], [limitItem.min, limitItem.max]))
          .map((el) => {
            return el.gameId
          })
        delete obj.gameCategories
        obj.gameIds = gameIds
      }
      getGameList(obj).then((res) => {
        if (res.success && res.data) {
          gameListArr = res.data.data
          if (res.data.data.length > 0) {
            const ids = res.data.data.filter((e) => e.platformCode === 'AMIGO').map((el) => el.gameId)
            if (ids.length > 0) {
              getAmingoDel(ids)
            } else {
              setFilterGameList(gameListArr)
              setIsLoding(false)
            }
          }
          if (pageNo === 1) {
            setTotalCount(res.data.totalRow)
          }
        } else {
          setFilterGameList([])
          setTotalCount(0)
          setIsLoding(false)
        }
      })
    } else {
      const { comboName, gameCategories, providerCodes } = useSearchType.current
      const obj = {
        pageNo,
        pageSize: isBigScreen ? 21 : 9,
        gameName: null,
        providerCodes: null,
        type: null
      }
      if (comboName) {
        obj.gameName = comboName
      } else if (providerCodes && providerCodes.length > 0) {
        obj.providerCodes = providerCodes
      } else {
        obj.type = gameCategories ? gameCategories[0] : 0
      }
      getPachinkoList(obj).then((res) => {
        if (res.data?.data?.length) {
          const pachinkoList = res.data.data
          const gameIds = pachinkoList.map((el) => {
            return el.gameId
          })
          if (pageNo === 1) {
            setTotalCount(res.data.totalRow)
          }
          getGameList({
            gameIds,
            pageSize: 30,
            gameTypes: ['pachinko', 'pachislot']
          }).then((r) => {
            if (r.success && r.data) {
              r.data.data.forEach((el) => {
                pachinkoList.forEach((i) => {
                  if (i.gameId === el.gameId) {
                    el.property = i
                  }
                })
              })
              setFilterGameList(r.data.data)
              setIsLoding(false)
            }
          })
        } else {
          setFilterGameList([])
          setTotalCount(0)
          setIsLoding(false)
        }
      })
    }
    //Amingo机台数据
    const getAmingoDel = (gameIdList) => {
      getAmigoInfo({ gameIdList }).then((res) => {
        setIsLoding(false)
        if (res.success && res.data) {
          for (let i = gameListArr.length - 1; i >= 0; i--) {
            const item = gameListArr[i]
            if (item.platformCode === 'AMIGO') {
              item.property = res.data[item.gameId]
              if (item.property?.state !== 1) {
                gameListArr.splice(i, 1)
              }
            }
          }
          setFilterGameList(gameListArr)
        }
      })
    }
  }
  return {
    filterGameList,
    totalCount,
    useSearchType,
    getGameData,
    isLoding,
    setIsLoding,
    setFilterGameList,
    setTotalCount
  }
}
