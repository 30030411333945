export const DefaultList = [
  //WELCOME
  {
    configName:
      '[{"lang": "en-US", "name": "Information complete(KYC Level 1)"},{"lang": "ja-JP", "name": "個人情報(レベル 1)"}]',
    configCode: "kyc1",
    rewardType: "kyc1",
    defaultUrl: "/account/personal",
    awardMoney: "20.00",
    awardCurrency: "USD",
    awardSpin: "20",
    fixedDetailsJp: `
        <p>
          個人情報：個人情報の入力を完了すると、マイアカウントのボーナス欄にボーナスが表示されますのでご獲得ください。
        </p>
    `,
    fixedDetailsEn: `
        <p>
          Information complete : Complete personal details, bonus will appear from My Account in the bonus section, please acquire it.
        </p>
    `
  },
  {
    configName:
      '[{"lang": "en-US", "name": "Identity verification(KYC Level 2)"},{"lang": "ja-JP", "name": "ご本人様確認(レベル 2)"}]',
    configCode: "kyc2",
    rewardType: "kyc2",
    defaultUrl: "/account/verification",
    awardMoney: "68.00",
    awardCurrency: "USD",
    awardSpin: "68",
    fixedDetailsJp: `
        <p>
          ご本人様確認：ご本人様確認が完了すると、マイアカウントのボーナス欄にボーナスが表示されますのでご獲得ください。
        </p>
        
    `,
    fixedDetailsEn: `
        <p>
          Identity verification : Complete Identity verification, bonus will appear from My Account in the bonus section,please acquire it.
        </p>
    `
  },
  {
    configName:
      '[{"lang": "en-US", "name": "Billing Address(KYC Level 3)"},{"lang": "ja-JP", "name": "ご請求先住所確認(レベル 3)"}]',
    configCode: "4183d52d76",
    rewardType: "kyc3",
    defaultUrl: "/account/verification",
    fixedDetailsJp: `
        <p>
          公共料金の請求書に記載されている住所確認：住所確認が完了すると、マイアカウントのボーナス欄にボーナスが表示されますのでご獲得ください。
          ※ボーナスの受け取り期限は30日間となります。
        </p>
    `,
    fixedDetailsEn: `
        <p>
          Billing Address : Complete Billing Address, bonus will appear from My Account in the bonus section,please acquire it.
          ※The deadline for receive the bonus is 30 days.
        </p>
    `
  },
  {
    configName: '[{"lang": "en-US", "name": "1st Deposit"},{"lang": "ja-JP", "name": "初回入金"}]',
    configCode: "depositTime1",
    rewardType: "deposit1",
    defaultUrl: "/account/deposit",
    percentage: "100%",
    extra: "10~60",
    fixedDetailsJp: `
        <p>
        ボーナス:<br/>
        入金額の100％、最大$800まで。<br/>
        （入金額＋ボーナス）×25倍のターンオーバーを達成する必要があります。<br/>
        </p>
    `,
    fixedDetailsEn: `
        <p>
        Bonus:<br/>
        100% of deposit amount and up to $800.<br/>
        Need to be completed by (Deposit + Bonus)x<br/>
        25 Valid Turnover
        </p>
    `
  },
  {
    configName: '[{"lang": "en-US", "name": "2nd Deposit"},{"lang": "ja-JP", "name": "セカンド入金"}]',
    configCode: "depositTime2",
    rewardType: "deposit2",
    defaultUrl: "/account/deposit",
    percentage: "50%",
    extra: "15~70",
    fixedDetailsJp: `
        <p>
        ボーナス:<br/>
        入金額の50％、最大$500まで。<br/>
        （入金額＋ボーナス）×15倍のターンオーバーを達成する必要があります。<br/>
        </p>
    `,
    fixedDetailsEn: `
        <p>
        Bonus:<br/>
        50% of deposit amount and up to $500.<br/>
        Need to be completed by (Deposit + Bonus)x<br/>
        15 Valid Turnover
        </p>
    `
  },
  {
    configName: '[{"lang": "en-US", "name": "3rd Deposit"},{"lang": "ja-JP", "name": "サード入金"}]',
    configCode: "depositTime3",
    rewardType: "deposit3",
    defaultUrl: "/account/deposit",
    percentage: "30%",
    extra: "15~70",
    fixedDetailsJp: `
        <p>
        ボーナス:<br/>
        入金額の30％、最大$500まで。<br/>
        （入金額＋ボーナス）×25倍のターンオーバーを達成する必要があります。
        </p>
    `,
    fixedDetailsEn: `
        <p>
        Bonus:<br/>
        30% of deposit amount and up to $500.<br/>
        Need to be completed by (Deposit + Bonus)x <br/>
        25 Valid Turnover        
        </p>
    `
  },
  {
    configName:
      '[{"lang": "en-US", "name": "New Registere betting bonus-Pachinko/Pachislot"},{"lang": "ja-JP", "name": "新規登録者ベットボーナス-パチンコ/パチスロ"}]',
    configCode: "dc7ad9266b",
    rewardType: "betpachinko",
    defaultUrl: "/pachinko",
    awardMoney: "15.00",
    awardSpin: "30",
    awardCurrency: "USD",
    betType: "pachinko;pachislot",
    fixedDetailsJp: `
        <p>
        パチンコ/パチスロ、スロットのベッティングボーナス
        ※ベッティングボーナスは、本人確認（KYCレベル2）完了後15日以内にキャッシュウォレットで$15以上のターンオーバーを行う必要があります。
        </p>
    `,
    fixedDetailsEn: `
        <p>
        Betting bonus on Pachinko/Pachislot,Slots.<br/>
        ※Betting bonus needs over $15 turnover<br/>
        of cash wallet wtihin 15 days after<br/>
        after completing Identity verification(KYC Level 2).        
        </p>
    `
  },
  {
    configName:
      '[{"lang": "en-US", "name": "New Registere betting bonus-Slot"},{"lang": "ja-JP", "name": "新規登録者ベットボーナス-スロット"}]',
    configCode: "dc7ad9266b",
    rewardType: "betslots",
    defaultUrl: "/slots",
    awardMoney: "15.00",
    awardSpin: "30",
    awardCurrency: "USD",
    betType: "slot",
    fixedDetailsJp: `
        <p>
        パチンコ/パチスロ、スロットのベッティングボーナス
        ※ベッティングボーナスは、本人確認（KYCレベル2）完了後15日以内にキャッシュウォレットで$15以上のターンオーバーを行う必要があります。
        </p>
    `,
    fixedDetailsEn: `
      <p>
        Betting bonus on Pachinko/Pachislot,Slots.<br/>
        ※Betting bonus needs over $15 turnover<br/>
        of cash wallet wtihin 15 days after<br/>
        after completing Identity verification(KYC Level 2).        
      </p>
    `
  },
  {
    configName:
      '[{"lang": "en-US", "name": "New Registere betting bonus-Sport"},{"lang": "ja-JP", "name": "新規登録者ベットボーナス-スポーツベット"}]',
    configCode: "dc7ad9266b",
    rewardType: "betsports",
    defaultUrl: "/sports-bet",
    betType: "sport",
    fixedDetailsJp: `
        <p>
         
        パチンコ/パチスロ、スロット、スポーツ、ライブカジノでのベット<br/>
        ※ベットによるボーナスを受け取るにはご請求先住所確認後、15日以内に15ドル以上のキャッシュでのベットが必要です。<br/>
        </p>
    `,
    fixedDetailsEn: `
        <p>
          Betting bonus on Pachinko/Pachislot, Slots, Sports and Live Casino.<br/>
          ※Betting bonus need over $15 turnover of cash wallet wtihin 15 days after complete Billing Address.<br/>
        </p>
    `
  },
  {
    configName:
      '[{"lang": "en-US", "name": "New Registere betting bonus-Live casino"},{"lang": "ja-JP", "name": "新規登録者ベットボーナス-ライブカジノ"}]',
    configCode: "dc7ad9266b",
    rewardType: "betlive",
    defaultUrl: "/live-casino",
    betType: "live",
    fixedDetailsJp: `
        <p>
        パチンコ/パチスロ、スロット、スポーツ、ライブカジノでのベット<br/>
        ※ベットによるボーナスを受け取るにはご請求先住所確認後、15日以内に15ドル以上のキャッシュでのベットが必要です。<br/>
        </p>
    `,
    fixedDetailsEn: `
        <p>
        Betting bonus on Pachinko/Pachislot, Slots, Sports and Live Casino.<br/>
        ※Betting bonus need over $15 turnover of cash wallet wtihin 15 days after complete Billing Address.<br/>
        </p>
    `
  },
  //VIP
  {
    configName: '[{"lang": "en-US", "name": "Level up quest"},{"lang": "ja-JP", "name": "レベルアップクエスト"}]',
    configCode: "3d038a238f",
    activityCoe: "vKnllkGX9L",
    awardCurrency: "USD",
    rewardType: "level_up",
    defaultUrl: "/account/Loyalty-club",
    fixedDetailsJp: `
        <p>
        初回レベルアップ時に限りボーナスを獲得できるクエストを進呈いたします、各レベル1回限りのクエストとなります。
        </p>
    `,
    fixedDetailsEn: `
        <p>
        The first time upgrades to the new level can receive bonus quest, each user account is restricted at each level only once
        </p>
    `
  },
  {
    configName: '[{"lang": "en-US", "name": "Birthday Bonus"},{"lang": "ja-JP", "name": "誕生日ボーナス"}]',
    configCode: "8e02040d6f;d670ec41cd;4f773cd629;a9ec11b59a;e193b7c366;0700edf718",
    rewardType: "brithday",
    activityCoe: "CE6N0ujnyp",
    defaultUrl: "/account/Loyalty-club",
    awardCurrency: "USD",
    fixedDetailsJp: `
        <p>
        現在のロイヤルティレベルに基づいて、誕生日ボーナスを進呈いたします。
        </p>
    `,
    fixedDetailsEn: `
        <p>
        Based on the current level, birthday bonus can be obtained on the birthday!
        </p>
    `
  },
  {
    configName: '[{"lang": "en-US", "name": "Weekly Cashback"},{"lang": "ja-JP", "name": "キャッシュバック"}]',
    configCode: "5818050749",
    rewardType: "cashback",
    activityCoe: "9LyNPZ3QEy",
    defaultUrl: "/account/Loyalty-club",
    awardCurrency: "USD",
    fixedDetailsJp: `
        <p>
          合計損失額から毎週キャッシュバックを進呈いたします。(レベルによりキャッシュバックの上限額が異なります)
        </p>
    `,
    fixedDetailsEn: `
        <p>
          Calculate the total weekly loss and cashback to you! (Different upper limits for different levels)
        </p>
    `
  },
  //SpecialBonus
  {
    configName: '[{"lang": "en-US", "name": "Joybet"},{"lang": "ja-JP", "name": " ジョイベット"}]',
    rewardType: "guess",
    defaultUrl: "/guess-game",
    fixedDetailsJp: `
        <p>
        不定期にさまざまな種類の予想イベントが開催され、2つの選択肢から1つを選んでいただきます。<br />
        選んだ選択肢に基づいて、追加のベットを行うことができます！最終的には賞金プールの金額を山分けします！<br />
        ベット額が多ければ多いほど、より多くの賞金を獲得するチャンスがあります！    
        </p>
    `,
    fixedDetailsEn: `
        <p>
        Various types of betting events are held irregularly, offering two options to choose from.<br />
        You can increase your bet based on the option you selected! The final prize pool will be shared!<br />
        The more you bet, the more chances you have to win a bigger prize!
        </p>
    `
  },
  {
    configName: '[{"lang": "en-US", "name": "Lucky Draw"},{"lang": "ja-JP", "name": "ラッキードロー"}]',
    rewardType: "lucky_draw",
    defaultUrl: "/promo/lucky-draw-win-a-car",
    fixedDetailsJp: `
        <p>
        獲得条件を満たした後、こちらのページでチケットを手動で獲得できます。
        </p>
    `,
    fixedDetailsEn: `
        <p>
          After meeting the collection conditions, you can collect the tickets manually.
        </p>
    `
  },
  //Tournaments
  {
    rewardType: "tour_pachinko",
    fixedDetailsJp: `
        <p>
        毎日ユーザーはベット額に応じてポイントを獲得できます✨<br />
        毎週、最も多くのポイントを獲得した上位50名のユーザーには各順位ごとに賞金が贈られます🎁<br />
        </p>
    `,
    fixedDetailsEn: `
        <p>
        Every day users earn points according to their bet amount ✨<br />
        Top 50 players with the most points in weekly tournaments<br />
        Users will receive prize money for each ranking 🎁
        </p>
    `
  },
  {
    rewardType: "tour_slot",
    fixedDetailsJp: `
        <p>
        ランキングは獲得したポイント（マルチプライヤー）に基づいて算出されます。<br />
        毎日、最も高いポイント（マルチプライヤー）を獲得した上位100名様に無料スピンを進呈いたします🎁
        </p>
    `,
    fixedDetailsEn: `
        <p>
        Ranking calculated based on highest winning multiplier<br />
        Reward will be awarded to the top 100 players with the highest winning multiplier        
        </p>
    `
  }
]
