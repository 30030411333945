import { useState, useEffect, useMemo, Fragment, useRef, memo } from 'react'
import { useClickAway, useDeepCompareEffect, useUpdateEffect } from 'ahooks'
import { useUserStore } from '@/stores/user'
import { useAppStore } from '@/stores/app'
import { useTranslation } from '@/lib/locales/client'
import { Switch, Popover, Empty } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { getExchangeRate } from '@/api/wallet'
import { useParams, useRouter } from 'next/navigation'
import { getCurrencySymbol, decimalFormat, isNullObject, cn, getBonusName, formatTime } from '@/lib/utils'
import { CSSTransition } from 'react-transition-group'
import styles from './_common_css/accountMoney.module.scss'
import { useShallow } from 'zustand/react/shallow'
/**com */
import FreeSpin from '@/components/base/notification/freeSpin'

const HeaderAccountMoney = () => {
  const { t } = useTranslation()
  const router = useRouter()
  const { lng } = useParams()
  const { isBigScreen, language } = useAppStore(
    useShallow((state) => ({
      isBigScreen: state.isBigScreen,
      language: state.language
    }))
  )
  const {
    currencyDisplay,
    hideZero,
    currencyRate,
    wallets,
    bonusAndTask,
    userInfo,
    currency,
    freeSpinWallet,
    moneyTypeSelectIndex,
    bonusCouponItem,
    freeSpinList,
    getBonusTaskList,
    SET_CURRENCY,
    SET_FREESPIN_WALLET,
    SET_ACTIVITY_CURRENCY,
    getExchangeRateFn,
    SET_BONUS_COUPON_ITEM,
    SET_CURRENCY_DISPLAY,
    getCurrencyRate,
    SET_HIDE_ZERO,
    SET_MONEY_TYPE_SELECT_INDEX
  } = useUserStore(
    useShallow((state) => ({
      currencyDisplay: state.currencyDisplay,
      hideZero: state.hideZero,
      currencyRate: state.currencyRate,
      wallets: state.wallets,
      bonusAndTask: state.bonusAndTask,
      userInfo: state.userInfo,
      currency: state.currency,
      freeSpinWallet: state.freeSpinWallet,
      moneyTypeSelectIndex: state.moneyTypeSelectIndex,
      bonusCouponItem: state.bonusCouponItem,
      freeSpinList: state.freeSpinList,
      getBonusTaskList: state.getBonusTaskList,
      SET_CURRENCY: state.SET_CURRENCY,
      SET_FREESPIN_WALLET: state.SET_FREESPIN_WALLET,
      SET_ACTIVITY_CURRENCY: state.SET_ACTIVITY_CURRENCY,
      getExchangeRateFn: state.getExchangeRateFn,
      SET_BONUS_COUPON_ITEM: state.SET_BONUS_COUPON_ITEM,
      SET_CURRENCY_DISPLAY: state.SET_CURRENCY_DISPLAY,
      getCurrencyRate: state.getCurrencyRate,
      SET_HIDE_ZERO: state.SET_HIDE_ZERO,
      SET_MONEY_TYPE_SELECT_INDEX: state.SET_MONEY_TYPE_SELECT_INDEX
    }))
  )
  const { currency: currencyType } = currency || {}
  // const [hideZero, setHideZero] = useState(false)
  const [currencySwicth, setCurrencySwicth] = useState('')
  const [isShowOptions, setIsShowOptions] = useState(false)
  const [showCurrencyDisplay, setShowCurrencyDisplay] = useState(false)
  const [showChooseFiat, setShowChooseFiat] = useState(false)
  const [bonusCouponSummary, setBonusCouponSummary] = useState([])

  // 特殊处理 切换货币
  useEffect(() => {
    // setHideZero(hideZero)
    setShowCurrencyDisplay(currencyDisplay !== '')
  }, [])

  useUpdateEffect(() => {
    setShowCurrencyDisplay(Boolean(currencyDisplay))
  }, [currencyDisplay])

  useEffect(() => {
    setCurrencySwicth(currencyDisplay)
  }, [currencyDisplay])

  // moneyTypeSelectIndex 当前切换的tab
  // 钱包这里要注意，依赖过多，添加wallets 是为了充值推送
  const walletCurrency = useMemo(() => {
    if (moneyTypeSelectIndex === 2 && !isNullObject(freeSpinWallet)) {
      return freeSpinWallet
    } else if (moneyTypeSelectIndex === 1 && bonusCouponItem.currency && bonusCouponSummary.length > 0) {
      return bonusCouponItem
    } else {
      return currency
    }
  }, [moneyTypeSelectIndex, currencyType, wallets])

  // click balance
  const openWallet = () => {
    if (!isShowOptions) {
      getBonusTaskList()
    }
    setIsShowOptions(!isShowOptions)
  }

  useDeepCompareEffect(() => {
    const bouns = bonusAndTask.filter(
      (item) =>
        (item.bonusStatus === 3 || item.bonusStatus === 5 || item.bonusStatus === 10) &&
        Number(item.amount) > 0 &&
        item.bonusType !== 5
    )
    setBonusCouponSummary(bouns)
  }, [bonusAndTask])

  //监听获取 当前币种兑换日元
  useEffect(() => {
    if (currencyType) {
      getCurrencyFn()
    }
  }, [currencyType])

  function chooseFreeSpin(val) {
    SET_CURRENCY(val)
    SET_FREESPIN_WALLET(val)
  }

  function getCurrencyFn() {
    // const { currency } = currency || {}
    if (currency?.currency === 'JPY') return
    getExchangeRate({
      tradeType: '01',
      targetCurrency: 'JPY',
      sourceCurrency: currency?.currency,
      isFindByCache: 0
    }).then((res: any) => {
      const { data } = res.data || {}
      if (res.success && data && data.length > 0) {
        // activityCurrency = data[0].realRate
        SET_ACTIVITY_CURRENCY(data[0].realRate)
      }
    })
  }

  // 显示余额
  const getDisplayCurrency = (amount, currency) => {
    // console.log('更新余额', amount, currency)
    // console.log('更新  currency', currency, currencyDisplay)
    if (
      currencyDisplay &&
      currency !== 'JPY' &&
      currency !== 'USD' &&
      getRateCurrency(amount, currency) !== false
    ) {
      return (
        getCurrencySymbol(currencyDisplay) + decimalFormat(getRateCurrency(amount, currency), currencyDisplay)
      )
    } else {
      return getCurrencySymbol(currency) + decimalFormat(amount, currency)
    }
  }

  const openDisplay = (flag) => {
    if (flag) {
      setShowChooseFiat(true)
    } else {
      SET_CURRENCY_DISPLAY('')
    }
  }

  const goBack = (evt) => {
    evt.stopPropagation()
    evt.nativeEvent.stopImmediatePropagation()
    setShowCurrencyDisplay(false)
    setShowChooseFiat(false)
  }

  const chooseFiat = (evt) => {
    // 阻止事件传递
    evt.stopPropagation()
    evt.nativeEvent.stopImmediatePropagation()
    if (currencySwicth && currencySwicth.length > 0) {
      SET_CURRENCY_DISPLAY(currencySwicth)
      setIsShowOptions(false)
      setShowChooseFiat(false)
      getCurrencyRate()
    } else {
      goBack(evt)
    }
  }
  const switchHidezero = (flag) => {
    SET_HIDE_ZERO(flag)
  }
  const changeFiatCurrency = (flag) => {
    setCurrencySwicth(flag)
  }

  const getRateCurrency = (amount, currency) => {
    // console.log('比例 数值', currencyRate)
    if (!currencyRate || !currency) return false
    const item = currencyRate.filter((el) => currency === el.sourceCurrency)
    if (item[0]) {
      return item[0].realRate * amount
    } else {
      return false
    }
  }

  const getImg = (currency) => {
    try {
      return `/static/images/header/wallets/currencies/${currency}.png`
    } catch (error) {
      return ''
    }
  }

  // 选择钱包 切换不同货币
  const walletClick = (item) => {
    SET_CURRENCY(item)
    setIsShowOptions(false)
    // 更改顶部币种时读取汇率在首页底部展示;切换币种为JPY时1:1展示；
    getExchangeRateFn({
      tradeType: '01',
      targetCurrency: item.currency === 'JPY' ? 'JPY' : 'USD',
      sourceCurrency: item.currency,
      isFindByCache: 0
    })
  }

  const bonusCouponClick = (item) => {
    SET_BONUS_COUPON_ITEM(item)
    // 原nexttick
    // useLayoutEffect(() => {
    setIsShowOptions(false)
    // }, [])
  }

  // 余额弹出在点外部时 关闭
  const headerAmountRef = useRef<HTMLDivElement>(null)
  useClickAway(() => {
    setIsShowOptions(false)
  }, [headerAmountRef])

  return (
    <>
      <div className={cn(styles['header-money'], !isBigScreen && styles['h5'])}>
        {/* 余额展示框 */}
        <div className={cn('header-money-input')} onClick={openWallet}>
          <div className={'currency-warp'}>
            {/* currency icon */}
            <img
              className={cn(walletCurrency?.currency, 'currency')}
              src={getImg(walletCurrency?.currency) || ''}
            />
          </div>
          {/* amount text */}
          <div className={cn('money', 'text-sm font-medium text-[#111]')}>
            {getDisplayCurrency(walletCurrency?.amount, walletCurrency?.currency)}
          </div>
          <a className={'money-btn'}>{isShowOptions}</a>
        </div>
        {/* 面板数据 */}
        <CSSTransition timeout={220} in={isShowOptions} unmountOnExit classNames={'fade'}>
          <>
            <div className={'money-box-warp'} ref={headerAmountRef}>
              {!showChooseFiat ? (
                <div>
                  <div className={'maney-box-content'}>
                    {/* tabs 3个状态切换 */}
                    <ul className={'head-tab'}>
                      <li
                        className={cn(moneyTypeSelectIndex === 0 && 'active')}
                        onClick={() => SET_MONEY_TYPE_SELECT_INDEX(0)}
                      >
                        <i></i>
                        <span>{t('home.heard.wallet')}</span>
                      </li>
                      <li
                        className={cn(moneyTypeSelectIndex === 1 && 'active')}
                        onClick={() => SET_MONEY_TYPE_SELECT_INDEX(1)}
                      >
                        <i></i>
                        <span>{t('home.heard.coupon')}</span>
                      </li>
                      <li
                        className={cn(moneyTypeSelectIndex === 2 && 'active')}
                        onClick={() => SET_MONEY_TYPE_SELECT_INDEX(2)}
                      >
                        <i></i>
                        <span>{t('home.heard.freeSqin')}</span>
                      </li>
                    </ul>
                  </div>
                  {/* 余额面板 货币数据展示 */}
                  {moneyTypeSelectIndex === 0 && (
                    <div className={'real-money'}>
                      <div className={cn('money-box', wallets.length > 12 && 'scroll')}>
                        {wallets.map((item, index) => (
                          <Fragment key={index}>
                            {!(hideZero && Number(item.amount) === 0) && (
                              <div key={index} className={'money-item'} onClick={() => walletClick(item)}>
                                <div className={'currency-warp'}>
                                  <img
                                    className={cn('currency', item.currency)}
                                    src={getImg(item.currency)}
                                  />
                                  <span>{item.currency}</span>
                                </div>
                                <div className={'money'}>
                                  {getDisplayCurrency(item.amount, item.currency)}
                                </div>
                              </div>
                            )}
                          </Fragment>
                        ))}
                      </div>
                    </div>
                  )}
                  {/* 余额面板 优惠券 */}
                  {moneyTypeSelectIndex === 1 && (
                    <>
                      {bonusCouponSummary.length ? (
                        <div className={'coupon-money'}>
                          {bonusCouponSummary.map((item, index) => (
                            <div
                              key={index}
                              className={cn(
                                'voucher',
                                bonusCouponItem && bonusCouponItem.requestId === item.requestId && 'select',
                                bonusCouponSummary.length === 1 && 'oneItem'
                              )}
                              onClick={() => bonusCouponClick(item)}
                            >
                              <div className={'coupon-left'}>
                                <h4 className={'title'}>{getBonusName(item, language)}</h4>
                                <div>
                                  <img
                                    className={'currency'}
                                    src={getImg(item.fetchCurrency || item.bonusCurrency)}
                                  />
                                  <span className={'symbol'}>{item.fetchCurrency || item.bonusCurrency}</span>
                                  <span className={'num'}>
                                    {getDisplayCurrency(
                                      item.amount,
                                      item.fetchCurrency || item.bonusCurrency
                                    )}
                                  </span>
                                </div>
                              </div>
                              <div className={'coupon-right'}>
                                {Number(item.betAmount) >= 0 && (
                                  <div>
                                    <span>
                                      {Math.min(
                                        (Number(item.betAmount) / Number(item.requiredBetAmount)) * 100,
                                        100
                                      ).toFixed(2)}
                                      %
                                    </span>

                                    {item.requestId === bonusCouponItem.requestId && (
                                      <img src="/static/images/header/wallets/yes1.png" />
                                    )}
                                  </div>
                                )}
                                <p>
                                  {t('home.heard.expire')} {formatTime(item.usedExpiredDate)}
                                </p>
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className="my-4" />
                      )}
                    </>
                  )}
                  {/* 余额面板 免费旋转 */}
                  {moneyTypeSelectIndex === 2 && (
                    <>
                      {(!userInfo.freeSpinWalletList || !userInfo.freeSpinWalletList.length) &&
                      !freeSpinList.length ? (
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className="my-4" />
                      ) : (
                        <div className={'freespin-warp'}>
                          <Popover
                            overlayClassName={'header-sp'}
                            placement="bottomRight"
                            trigger="click"
                            content={
                              <>
                                {userInfo.freeSpinWalletList.map((item) => (
                                  <div key={item.currency} onClick={() => chooseFreeSpin(item)}>
                                    <img
                                      className={'currency_icon'}
                                      src={`/static/images/header/wallets/currencies/${item.currency}.png`}
                                    />
                                    <span className={'currency'}>{item.currency}</span>
                                    <span className={'money'}>
                                      {decimalFormat(item.amount, item.currency)}
                                    </span>
                                  </div>
                                ))}
                              </>
                            }
                          >
                            {!isNullObject(freeSpinWallet) && (
                              <div className={'sp_box'}>
                                <img src={'/static/images/header/wallets/sp.png'} />
                                <span className={'txt'}>{t('bonus.wallet')}</span>

                                <img
                                  className={'money_icon'}
                                  src={`/static/images/header/wallets/currencies/${freeSpinWallet.currency}.png`}
                                />
                                <span className={'money'}>
                                  {decimalFormat(freeSpinWallet.amount, freeSpinWallet.currency)}
                                </span>
                                <DownOutlined className={'icon'} />
                              </div>
                            )}
                          </Popover>
                          <FreeSpin className={'scrollbar'} />
                        </div>
                      )}
                    </>
                  )}
                  {/* two buttons */}
                  {moneyTypeSelectIndex === 0 && (
                    <div className="deposit-warp">
                      <a
                        onClick={() => {
                          router.push(`/${lng}/account/deposit`)
                          setIsShowOptions(false)
                        }}
                      >
                        {t('deposit.deposit')}
                      </a>
                      <a
                        onClick={() => {
                          router.push(`/${lng}/account/buyCrypto`)
                          setIsShowOptions(false)
                        }}
                      >
                        {t('deposit.buyCrypto')}
                      </a>
                    </div>
                  )}
                  {/* 切换货币开关 */}
                  {moneyTypeSelectIndex !== 2 && (
                    <div className={'display-switch'}>
                      <div>
                        {t('home.heard.display')}
                        {currencyDisplay && <span>({currencyDisplay})</span>}
                      </div>
                      <Switch
                        checked={Boolean(showCurrencyDisplay)}
                        onChange={(checked, evt) => {
                          // evt.stopPropagation()
                          evt.nativeEvent.stopImmediatePropagation()
                          openDisplay(checked)
                        }}
                      />
                    </div>
                  )}
                  {/* 过滤掉余额为0的数据 */}
                  {moneyTypeSelectIndex === 0 && (
                    <div className={'hide-switch'}>
                      <p>{t('home.heard.hide')}</p>
                      <Switch checked={hideZero} onChange={switchHidezero} />
                    </div>
                  )}
                </div>
              ) : (
                <div className={'choose-fiat-warp'}>
                  <div className={'choose-fiat-head'}>
                    <img src="/static/images/header/wallets/down-2.png" onClick={goBack} />
                    <p>{t('home.heard.display')}</p>
                  </div>
                  <ul>
                    {['JPY', 'USD'].map((item, index) => (
                      <li key={index} onClick={() => changeFiatCurrency(item)}>
                        {item === currencySwicth ? (
                          <img src="/static/images/header/wallets/yes.png" />
                        ) : (
                          <img src="/static/images/header/wallets/no.png" />
                        )}

                        <span>{item}</span>
                        <img src={getImg(item)} />
                      </li>
                    ))}
                  </ul>
                  <a onClick={chooseFiat}>{t('home.heard.btn')}</a>
                  <p style={{ lineHeight: 1.5 }}>{t('home.heard.text')}</p>
                </div>
              )}
            </div>
          </>
        </CSSTransition>
      </div>
    </>
  )
}

export default memo(HeaderAccountMoney)
