'use client'
import { useState, useEffect, FC, useMemo } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { useUserStore } from '@/stores/user'
import { useTranslation } from '@/lib/locales/client'
import { cn, getCurrencySymbol } from '@/lib/utils'
import { formatAmount } from '@/lib/filter'
import React from 'react'

type IProps = {
  item: {
    channelName: string
    channelCode: string
    bestFastFlag: boolean
    channelInfo: any
  }
  currencyData?: string
}

const depositData: FC<IProps> = (props) => {
  const { t } = useTranslation()
  const storeCurrency = useUserStore((state) => state.currency?.currency)
  const [currency, setCurrency] = useState('')
  const [minDepositAmount, setMinDepositAmount] = useState(0)
  const [maxDepositAmount, setMaxDepositAmount] = useState(0)

  useEffect(() => {
    const info = props.item.channelInfo
    if (info) {
      const otherCurrency = storeCurrency !== 'USD' ? 'USD' : 'JPY'
      let useCurrency = props.currencyData || ''
      if (!useCurrency) {
        if (info[storeCurrency]?.maxAmount) {
          useCurrency = storeCurrency
        } else if (info[otherCurrency]?.maxAmount) {
          useCurrency = otherCurrency
        } else {
          useCurrency = 'JPY'
        }
      }
      if (info[useCurrency]?.minAmount !== undefined) {
        setMinDepositAmount(info[useCurrency].minAmount)
        setMaxDepositAmount(info[useCurrency].maxAmount)
        setCurrency(useCurrency)
      }
    }
  }, [props.item.channelInfo, storeCurrency, props.currencyData])

  return (
    <div className='item-box'>
      <p className="bank-name">
        <span>{props.item.channelName}</span>
        {props.item.bestFastFlag && <span className="fast">{t('deposit.fast')}</span>}
      </p>
      {props.item.channelCode === 'coinPayment' || props.item.channelCode === 'onramper' ? (
        <p className="fee">
          {
            {
              coinPayment: t('withdraw.fee'),
              onramper: t('withdraw.vary')
            }[props.item.channelCode]
          }
        </p>
      ) : (
        minDepositAmount &&
        maxDepositAmount && (
          <p className="limit">
            {t('deposit.limit')}
            <br />
            <span className="amount">
              {props.item.channelCode === 'jetonPaypay'
                ? t('withdraw.fee1')
                : `${getCurrencySymbol(currency)}${formatAmount(minDepositAmount, 0, true)}
          - ${getCurrencySymbol(currency)}${formatAmount(maxDepositAmount, 0, true)}`}
            </span>
          </p>
        )
      )}
    </div>
  )
}

export default depositData
