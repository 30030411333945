export const promo = {
  freeSpin: '無料スピン',
  bonus: 'ボーナス',
  claim: '受け取る',
  deposit: '入金',
  crypto: '仮想通貨',
  currency: '通貨',
  extra: '追加ギフト',
  endTime: '終了時間',
  viewMore: 'もっと見る',
  view: 'もっと見る',
  wager: '必要賭け金額',
  rewardExpire: '報酬の有効期限',
  betAmount: 'ベット額',
  evenExpire: 'イベント有効期限',
  expirationDate: '有効期限',
  provider: 'プロバイダー',
  currentVIP: '現在のVIPレベル',
  nextLevelXp: '次のレベルまでのXP',
  nextLevelReward: '次のレベルの報酬',
  jackpot: '最大ジャックポット',
  reward: '報酬',
  bonusClaimedTotal: '合計獲得ボーナス',
  bonusTotal: '合計ボーナス',
  questTotal: '合計クエスト',
  extraTotal: '合計その他',
  spinTotal: '合計無料スピン',
  details: '詳細',
  stateTab: ['プロモーション', '進行中'],
  claimRewards: { name: '報酬受け取り', tagCode: 'available' },
  promotionTab: [
    { name: "ウェルカムパッケージ", tagCode: "welcome" },
    { name: "クエストボーナス", tagCode: "questBonus" },
    { name: "トーナメント", tagCode: "tournament" },
    { name: "VIPボーナス", tagCode: "vip" },
    { name: "スペシャルボーナス", tagCode: "special" }
  ],
  progressTab: ['ボーナス', 'クエスト', 'その他', '無料スピン'],
  cancel: 'キャンセル',
  play: '今すぐプレイ',
  spin: '無料スピン',
  newTask: '新しいタスクがあります',
  completeTask: 'タスクを完了すると、',
  claimTask: 'タスクを受け取る',
  approved: '審査通過',
  creditedSuc: '入金成功',
  bet: 'ベット',
  claimReward: '報酬を受け取る',
  taskInfo: {
    kyc1: 'KYC 1を完了',
    kyc2: 'KYC 2を完了',
    kyc3: 'KYC 3を完了',
    deposit1: 'チャージ金額無制限',
    deposit2: 'チャージ金額無制限',
    deposit3: 'チャージ金額無制限',
    betpachinko: 'パチンコ/パチスロをプレイする',
    betslots: 'スロットをプレイする',
    betlive: 'ライブカジノをプレイする',
    betsports: 'スポーツベットをプレイする',
    sign: "リアルマネーベットゲーム（有効ベット額）"
  },
  closeRemind: 'タスクの通知を閉じた場合、このプロモーションで全てのタスクを確認できます。',
  notShowToday: '今日は再度表示しない',
  rank: 'ランキング',
  time: "回",
  sign:{
    needKyc: "携帯電話とメールアドレス認証を先に完了してください",
    title: "デイリーリワード説明",
    ruleTitle: "受け取りルール",
    rule: "毎月のサインイン回数が基準に達した場合、報酬を受け取ることができます。ボーナスのリリースは、利用規約および一般規則に従います。",
    popText: "のログイン報酬を獲得しました。おめでとうございます！",
    nextReward: "次の報酬",
  },
  detailsTitle:"イベント詳細"
} as const
