import { useState, useEffect, FC, memo } from 'react'
import { cn } from '@/lib/utils'
import { useTranslation } from '@/lib/locales/client'
import { useAppStore } from '@/stores/app'
import { useUserStore } from '@/stores/user'
import { useGameStore } from '@/stores/game'
import { cmsGameLabels } from '@/api/home'
import { Input, Skeleton } from 'antd'
import { useRouter, usePathname } from 'next/navigation'
import styles from '@/app/[lng]/(game)/_common_css/gameProvideSearch.module.scss'
import SlotsGameProvider from '@/app/[lng]/(game)/_components/slotsGameProvider'
import GameSeachList from '../_components/gameSeachList'
import CasinoBox from '@/components/game/casino'
import { useShallow } from 'zustand/react/shallow'

type IProps = {
  pageType?: string //  lobbyPage  游戏大厅    multiPage 多机台搜索  searchPage 搜索页面搜索
  inputText?: string // ""
  popClassName?: string
  isFavoriteDialog?: boolean
}

const GameProvideSearch: FC<IProps> = (props) => {
  const { pageType, inputText = '', isFavoriteDialog, popClassName } = props
  const { t } = useTranslation()
  const userInfo = useUserStore((state) => state.userInfo)
  const { isBigScreen, language } = useAppStore(
    useShallow((state) => ({
      isBigScreen: state.isBigScreen,
      language: state.language
    }))
  )
  const getGameBetLimit = useGameStore((state) => state.getGameBetLimit)
  const [searchProviderList, setSearchProviderList] = useState([])
  const [searchGameName, setSearchGameName] = useState('')
  const [frontDataList, setFrontDataList] = useState([])
  const [spinning, setSpinning] = useState(true)
  const [recentList, setRecentList] = useState([])
  const fastSearch = ['CR', 'バカラ', '海', '神', '北斗', 'Book', 'Big', '100', 'Sweet']
  const [multiType, setMultiType] = useState('')
  const multiTab = [
    { label: t('common.account.all'), value: '' },
    { label: t('common.account.favorite'), value: 'favorite' },
    { label: t('common.account.pachinko'), value: 'pachinko' },
    { label: t('common.account.slots'), value: 'slot' },
    { label: t('common.account.liveCasino'), value: 'live' }
  ]

  if (language !== 'ja-JP') {
    fastSearch[1] = 'Baccarat'
  }

  const handleFinish = (val) => {
    setSearchProviderList(val.checkedList)
  }

  const getGameLabels = () => {
    return cmsGameLabels({ moduleCode: 'labelClassification', username: userInfo.loginName })
      .then((res: any) => {
        if (res.code === 200 && res.success && res.data.length > 0) {
          res.data.forEach((el) => {
            el.detailsList.forEach((ll) => {
              // 接口历史遗留问题，转换一遍
              ll.providerName = ll.providerCode
              ll.providerCode = ll.lobbyCode
              ll.platformCode = ll.lobbyCode
            })
          })
          setFrontDataList(res.data || [])
        }
      })
      .finally(() => {
        setSpinning(false)
      })
  }

  const delRecentAll = () => {
    setRecentList([])
    localStorage.setItem('searchGameList', '')
  }
  const delRecent = (index) => {
    const list = [...recentList]
    list.splice(index, 1)
    setRecentList(list)
    localStorage.setItem('searchGameList', JSON.stringify(list))
  }
  const recentChange = (name) => {
    if (!name) return
    const data = [...recentList]
    const index = data.indexOf(name)
    if (index > -1) {
      data.splice(index, 1)
    }
    data.unshift(name)
    if (data.length > 30) {
      data.length = 30
    }
    setRecentList(data)
    localStorage.setItem('searchGameList', JSON.stringify(data))
  }

  useEffect(() => {
    getGameBetLimit()
    if (pageType === 'searchPage') {
      getGameLabels()
      if (localStorage.getItem('searchGameList')) {
        const list = JSON.parse(localStorage.getItem('searchGameList'))
        if (list && list.length > 0) {
          setRecentList(list)
        }
      }
    } else if (pageType === 'multiPage') {
    }
  }, [pageType])

  useEffect(() => {
    if (isFavoriteDialog) {
      setMultiType('favorite')
    }
  }, [isFavoriteDialog])

  return (
    <div className={cn(styles['game-provide-search'], popClassName)}>
      {pageType === 'multiPage' && (
        <div className={'fast-search'}>
          {multiTab.map((item, index) => (
            <span
              key={item.value}
              className={multiTab[index].value === multiType ? 'active' : null}
              onClick={() => setMultiType(item.value)}
            >
              {item.label}
            </span>
          ))}
        </div>
      )}
      <div className={'input-warp'}>
        <Input
          value={searchGameName}
          onChange={(e) => setSearchGameName(e.target.value)}
          placeholder={inputText || t('home.game.input')}
          prefix={
            <>
              <SlotsGameProvider finish={handleFinish} gameType={pageType === 'multiPage' ? multiType : ''} />
              <img className={'search-icon'} src="/static/images/game/search-icon.svg" />
            </>
          }
          suffix={
            searchGameName && (
              <img
                onClick={() => {
                  setSearchGameName('')
                }}
                className={'search-icon'}
                src="/static/images/home/clean.png"
              />
            )
          }
        ></Input>
      </div>
      {pageType === 'searchPage' && (
        <>
          <div className={'fast-search'}>
            {fastSearch.map((item) => (
              <span key={item} onClick={() => setSearchGameName(item)}>
                {item}
              </span>
            ))}
          </div>
          {recentList.length > 0 && !searchGameName && (
            <div className="recent-search">
              <div className="recent-title">
                <p>{t('home.seach.recent')}</p>
                <a onClick={delRecentAll}></a>
              </div>
              <div className="recent-search-warp">
                {recentList.map((item, index) => (
                  <a
                    key={index}
                    onClick={() => {
                      setSearchGameName(item)
                    }}
                  >
                    <span>{item}</span>
                    <i
                      onClick={(e) => {
                        e.stopPropagation()
                        delRecent(index)
                      }}
                    ></i>
                  </a>
                ))}
              </div>
            </div>
          )}
          {!isBigScreen && (
            <Skeleton loading={spinning} title={false} paragraph={{ rows: 8 }} className={'search-def'}>
              {!searchGameName && searchProviderList.length === 0 && (
                <div className={'search-def'}>
                  {frontDataList.length > 0 && (
                    <CasinoBox data={frontDataList} title={t('home.betList.casino')} />
                  )}
                </div>
              )}
            </Skeleton>
          )}
        </>
      )}
      {(searchGameName || searchProviderList.length > 0 || pageType !== 'searchPage') && (
        <GameSeachList
          key={multiType}
          pageType={pageType}
          gameType={pageType === 'multiPage' ? multiType : null}
          searchGameName={searchGameName}
          searchProviderList={searchProviderList}
          recentChange={recentChange}
        />
      )}
    </div>
  )
}

export default memo(GameProvideSearch)
