import styles from './_common_css/noData.module.scss'
const NoDataPart = ({children}) => {
  return (
    <>
      <div className={styles['no-data']}>
        <div className={styles['text']}>{children}</div>
      </div>
    </>
  )
}

export default NoDataPart
