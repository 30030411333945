export const landpage = {
  title: "Challenge yourself to make a deposit with attractive Cryptocurrency!",
  descrtion:
    "Here we will provide you with a convenient method of depositing with Cryptocurrency that no need KYC. Check out the benefits of Cryptocurrency, how to open an exchange account, and more, and try making a deposit with Cryptocurrency!",
  moduleTitle1: "Instant deposit",
  moduleTitle2: "High Security for Peace of Mind in Every Trade",
  moduleTitle3: "Exclusive offers unique to Cryptocurrencies",
  moduleTitle4: "Deposit with Cryptocurrency now!",
  moduleText1:
    "Deposits made in Cryptocurrency will be reflected in your account in just a few seconds. We provide an environment where you can play as soon as you want without the stress of waiting for payment confirmation.",
  moduleText2:
    "Transactions with Cryptocurrencies are highly anonymous and secure as there is no need to enter personal information such as credit card or account information.",
  moduleText3:
    "In addition to regular deposit promotions, Queen Casino also runs special exclusive offers promotions that only apply to Cryptocurrency deposits.",
  moduleText4:
    "There are many attractions to depositing with Cryptocurrency, such as no KYC required, instant deposit, high security, and abundant promotions! Please try making a deposit using Cryptocurrency!",
  text1: "Here are the representative Cryptocurrency exchanges in Japan！",
  text2:
    "(When using Cryptocurrency, we recommend that you first go through these Japanese Cryptocurrency exchanges and then go through an overseas Cryptocurrency exchange to maintain your anonymity.)",
  btn: "Deposit with Cryptocurrency"
}
