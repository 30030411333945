export const notice = {
  tab1: "Marketing Info",
  tab2: "Notification",
  detailBtn: "View Details",
  noShowToday: "Do Not Show Today",
  empty: "No information",
  oneClickRead: "Click to read",
  oneClickDelete: "Click to delete",
  all: "All",
  message: "Website Message",
  notification: "System Announcement",
  announcement: "Important Notice",
  updataNotification: "Update Notification",
  messageType: "Message type",
  reset: "Reset",
  confirm: "Confirm",
  isEnd: "Data has been finalized ",
  deleteOne: "Are you sure to delete this message?",
  deleteAll: "Are you sure to delete all messages?",
  notify: "Notification",
  deletedSuccessfully: "Deleted successfully",
  showAll: "show all"
} as const
