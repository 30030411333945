export const password = {
  text001: 'パスワードは6文字以上で、大文字/小文字/数字/記号の全てを含む必要があります。',
  text002:
    'パスワード設定する際に、名前、メールアドレス、生年月日など推測されやすい文字列を使用しないでください。 パスワードは大文字と小文字は異なるものとして認識されるので、大文字、小文字、数字、記号を混在させた文字列でパスワードを設定してください。',
  oldPwd: '現在のパスワード',
  oldPwdPla: '現在のパスワード',
  newPwd: '新しいパスワード',
  newPwdPla: '新しいパスワード',
  newPwdConfirm: '新しいパスワードを再入力',
  newPwdConfirmPla: '新しいパスワードを再入力',
  btn001: '編集',
  btn002: '更新',
  btn003: 'キャンセル',
  errText001: '必須項目',
  errText002: 'パスワードが一致していませ',
  modifySuccess: 'パスワードが変更されました',
  inputPwd: 'パスワードを入力してください',
  inputComPwd: 'パスワードをもう一度入力してください'
}
