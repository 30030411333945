'use client'
import React, { useEffect, useRef, useState } from 'react'
import {
  getTournamentList,
  getTournamentCustomerJoinRecord,
  getTournamentCustomerClaimPrize
} from '@/api/activity'
import RewardSwiper from '../rewardSwiper'
import RewardCard from '../rewardCard'
import { DefaultList } from '../defaultPromo'
import { useUserStore } from '@/stores/user'
import { useTranslation } from '@/lib/locales/client'
import { useShallow } from 'zustand/react/shallow'
import { useAppStore } from '@/stores/app'

const RewardTournaments = () => {
  const getBonusTaskList = useUserStore((state) => state.getBonusTaskList)
  const {  SET_GLOBAL_MSG } = useAppStore(
    useShallow((state) => ({SET_GLOBAL_MSG: state.SET_GLOBAL_MSG }))
  )
  const [spinning, setSpinning] = useState(true)
  const { t } = useTranslation()
  const [tournamentList, setTournamentList] = useState([])
  const dataList = useRef([])
  const queryTournamentList = () => {
    getTournamentList({}).then(({ data }: any) => {
      if (data.length > 0) {
        data.forEach((el: any) => {
          const rewardType = el.gameType[0] === 'pachinko' ? 'tour_pachinko' : 'tour_slot'
          const item = DefaultList.filter((ff) => ff.rewardType === rewardType)[0]
          dataList.current.push({
            ...item,
            rewardName: el.tournamentName,
            rewardType,
            defaultUrl: '/tournament-detail/' + el.tournamentId,
            awardMoney: el.totalPrize,
            awardCurrency: 'USD',
            awardSpin: el.totalFreeSpin,
            endTime: el.roundEndDate,
            expireTime: el.activityDateEnd,
            tournamentId: el.tournamentId
          })
          queryTournamentCustomerJoinRecord(el.tournamentId, el.round)
        })
      } else {
        setSpinning(false)
      }
    })
  }


  //通常只会有两场，所以不用切换再请求
  const queryTournamentCustomerJoinRecord = (id, round) => {
    getTournamentCustomerJoinRecord({ id, round })
      .then((res) => {
        const data = res.data
        dataList.current.forEach((el) => {
          if (el.tournamentId === data.tournamentId) {
            if (data.lastPrizeRecordStatus === 0) {
              el.awardMoney = data.lastPrizeAmount
              el.awardSpin = data.lastFreeSpins
              el.lastPrizeRecordId = data.lastPrizeRecordId
              el.rank = data.rank
            } else if ((data.freeSpins > 0 || Number(data.prize) > 0) && data.rank) {
              el.awardMoney = data.prize
              el.awardSpin = data.freeSpins
              el.rank = data.rank
            }
          }
        })
        setTournamentList(dataList.current)
      })
      .finally(() => {
        setSpinning(false)
      })
  }
  const queryTournamentCustomerClaimPrize = (item: any) => {
    getTournamentCustomerClaimPrize({
      lastPrizeRecordId: item.lastPrizeRecordId
    }).then((res) => {
      if (res.success) {
        queryTournamentList()
        getBonusTaskList()
        SET_GLOBAL_MSG({ flag: true, type: 'success', content: t('activity.tournament.receiveSuccess') })
      }
    })
  }
  useEffect(() => {
    queryTournamentList()
  }, [])

  return (
    <div className="reward-tournaments">
      <RewardSwiper
        swipData={tournamentList}
        spinning={spinning}
        cardType="tournaments"
        render={(item: any, key: string) => (
          <RewardCard cardInfo={item} claim={() => queryTournamentCustomerClaimPrize(item)} key={key} />
        )}
      ></RewardSwiper>
    </div>
  )
}

export default RewardTournaments
