import { useAppStore } from '@/stores/app'

const getFontSize = (isBigScreen: boolean) => {
  if (typeof window === 'undefined' || !window.document || !window.document.createElement) return
  const docEl = document.documentElement as HTMLElement
  if (isBigScreen) {
    const width = docEl.clientWidth < 1920 ? 1920 : docEl.clientWidth
    const fontSize = width / 100
    return fontSize
  } else if (window.innerWidth > 999) {
    return 22
  } else {
    const width = docEl.clientWidth
    const fontSize = width / (375 / 19.2)
    return fontSize
  }
}
/**
 * 设计稿原尺寸转换成rem
 * @param px
 * @param isShowUnit
 * @returns
 */
export const px2rem = (px: number, isShowUnit: boolean) => {
  const { isBigScreen } = useAppStore.getState()
  if (isBigScreen) {
    return `${px / (1920 / 100)}${isShowUnit ? 'rem' : ''}`
  }
  return `${px / 19.2}${isShowUnit ? 'rem' : ''}`
}

export const px2Scalepx = (px: number) => {
  const { isBigScreen } = useAppStore.getState()
  const remValue = px2rem(px, false)

  return parseFloat(remValue) * getFontSize(isBigScreen)
}

export const useRem = () => {
  const { isBigScreen } = useAppStore.getState()
  const docEl = document.documentElement as HTMLElement

  const init = () => {
    const fontSize = getFontSize(isBigScreen)
    docEl.style.fontSize = fontSize + 'px'
    const body = document.body
    body.setAttribute('doc-el-font-size', fontSize + '') // 会随分辨率更新字体大小
  }
  //首次加载是计算
  init()

  window.addEventListener('orientationchange', init)
}
