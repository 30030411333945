import { useState, useEffect, useMemo, memo } from 'react'
import { cn } from '@/lib/utils'
import meta from '@/app/[lng]/meta'
import { useTranslation } from '@/lib/locales/client'
import { useAppStore } from '@/stores/app'
import { useUserStore } from '@/stores/user'
import { useAuthStore } from '@/stores/authControl'
import { useRouter, usePathname, useParams } from 'next/navigation'
import { Layout, Space } from 'antd'
import { cmsTemplateApi } from '@/api/base'
import { useNotice } from '@/hooks/useNotice'
import { webpHandle } from '@/hooks/aboutWebp'
import styles from '../_common_css/header.module.scss'
import { useShallow } from 'zustand/react/shallow'
// com
import HeaderAccountMoney from '../../common/accountMoney'
const { Header } = Layout

const H5Header = () => {
  const { t } = useTranslation()
  const { language, globalPublickKey, setIsShowSideNav } = useAppStore(
    useShallow((state) => ({
      language: state.language,
      globalPublickKey: state.globalPublickKey,
      setIsShowSideNav: state.setIsShowSideNav
    }))
  )
  const { openLogin, openRegister } = useAuthStore(
    useShallow((state) => ({
      openLogin: state.openLogin,
      openRegister: state.openRegister
    }))
  )
  const { setWebToken, isLogin } = useUserStore(
    useShallow((state) => ({
      setWebToken: state.setWebToken,
      isLogin: state.isLogin
    }))
  )
  const router = useRouter()
  const { lng } = useParams()
  const pathName = usePathname()
  const rawPath = pathName.replace(/^\/(en|ja)($|\/)/g, '/')
  const { noticeList } = useNotice()
  const [headerLogo, setHeaderLogo] = useState('')

  // 特殊路由下不隐藏头部
  const exSomeURL =
    rawPath.startsWith('/account/buyCrypto') ||
    rawPath.startsWith('/account/deposit') ||
    rawPath.startsWith('/account/withdraw')

  const hideHeader = useMemo(() => {
    if (Object.keys(meta).length) {
      // 特殊处理个人中心 用户登录后隐藏顶部
      if (rawPath.startsWith('/account') && !exSomeURL && isLogin) {
        return !meta[rawPath]?.hideHeader
      } else {
        return meta[rawPath]?.hideHeader
      }
    }
  }, [pathName, isLogin])

  // 未读数量
  const unReadNoticeLength = useMemo(() => {
    const arr = noticeList.filter((item) => !item.isRead || item.isRead == 0)
    return arr.length > 99 ? 99 : arr.length // 大于99均展示99
  }, [])

  /*  fuc */
  const handleClick = (type) => {
    localStorage.removeItem('userStore')
    setWebToken('')
    setIsShowSideNav(false)
    if (type === 'login') {
      openLogin()
    } else {
      openRegister(true)
    }
  }

  const onGetCMSData = async () => {
    const params = {
      moduleCodeList: ['headerLogo']
    }
    const cmsData: Resp = await cmsTemplateApi(params)
    if (cmsData?.success) {
      const logo = cmsData.data.filter((item) => item.moduleCode === 'headerLogo')[0]?.mediaLibrary[0]
        ?.fullFilePath
      setHeaderLogo(logo || '')
    }
  }

  useEffect(() => {
    if (globalPublickKey) onGetCMSData()
  }, [globalPublickKey])

  return (
    <>
      {!hideHeader && (
        <Header className={cn(styles['header_box'], '!h-[56px] !bg-white !p-0')}>
          <div
            className={cn(
              'h5_header_left_box',
              rawPath !== '/' && 'bg',
              language,
              'bg-[url("/static/images/home/heard-bg.png")] bg-[length:100%_100%] bg-no-repeat'
            )}
          >
            {/* logo */}
            <div className={'logo-box'} onClick={() => router.push(`/${lng}`)}>
              {headerLogo && <img className={'logo-activity'} src={webpHandle(headerLogo)} alt="" />}
            </div>

            {!isLogin ? (
              <div className={'h5-header-btns'}>
                <a onClick={() => handleClick('login')}>
                  <span>{t('user.login')}</span>
                </a>
                <a onClick={() => handleClick('register')}>
                  <span>{t('user.signup')}</span>
                </a>
              </div>
            ) : (
              <div className={'amount-message'}>
                {rawPath !== '/account' && (
                  <Space>
                    <HeaderAccountMoney />
                  </Space>
                )}
                {/* 消息中心 */}
                <div className={'top-message'}>
                  {Boolean(unReadNoticeLength) && <i className={'count'}>{unReadNoticeLength}</i>}
                  <img
                    className={'message'}
                    src="/static/images/header/ld.png"
                    alt=""
                    onClick={() => router.push(`/${lng}/account/notice`)}
                  />
                </div>
              </div>
            )}
          </div>
        </Header>
      )}
    </>
  )
}

export default memo(H5Header)
