'use client'
import { useUserStore } from '@/stores/user'
import React, { useEffect, useState } from 'react'
import { DefaultList } from '../defaultPromo.js'
import { getBonusList, applyBonus } from '@/api/activity'
import RewardSwiper from '../rewardSwiper'
import RewardCard from '../rewardCard'
import { rewardLevel } from '@/api/account'
import { useShallow } from 'zustand/react/shallow'
import { useTranslation } from '@/lib/locales/client'
import { useAppStore } from '@/stores/app'
const RewardVip = () => {
  const { currency, getBonusTaskList } = useUserStore(
    useShallow((state) => ({
      currency: state.currency,
      getBonusTaskList: state.getBonusTaskList
    }))
  )
  const { SET_GLOBAL_MSG } = useAppStore(useShallow((state) => ({ SET_GLOBAL_MSG: state.SET_GLOBAL_MSG })))
  const { t } = useTranslation()
  const [spinning, setSpinning] = useState(true)
  const [vipList, setVipList] = useState([])
  const defaultData = [...DefaultList]
  const getData = async () => {
    const [levelData, bonusList] = await Promise.all([
      rewardLevel({}),
      getBonusList({
        configCode: '5818050749;3d038a238f;8e02040d6f;d670ec41cd;4f773cd629;a9ec11b59a;e193b7c366;0700edf718', // 输值返;晋级礼金;生日礼金
        status: 2, // 0等待,1一审,2批准x,3已领取-1已取消,5=使用中,6=已释放,7=已输光,8=已失效,9=已放弃,10=待释放 2,10 高亮 /3,5 Receive/ 6789不显示
        expiredDateBegin: new Date().toISOString(),
        pageSize: 200,
        pageNo: 1
      })
    ])
    setSpinning(false)
    let filterData = []
    defaultData.forEach((el, index) => {
      const list = levelData.data?.filter((item: any) => el.activityCoe === item.activityCoe)[0]
      if (!list) return
      if (
        (list.currentLevelPrizeAmount && Number(list.currentLevelPrizeAmount) > 0) ||
        (list.currentLevel === 130 && el.activityCoe === 'vKnllkGX9L')
      ) {
        //Type 1-免费转;2-奖券;3-比例
        el[['awardSpin', 'awardMoney', 'awardProportion'][list.currentLevelPrizeType - 1]] =
          list.currentLevelPrizeAmount
        if (list.nextLevelPrizeType === 1) {
          el.nextLevelReward = t('promo.spin') + ' ' + list.nextLevelPrizeAmount
        } else if (list.nextLevelPrizeType === 2) {
          el.nextLevelReward = list.nextLevelPrizeAmount
          el.nextLevelPrizeType = list.nextLevelPrizeType
        } else if (list.nextLevelPrizeType === 3) {
          el.nextLevelReward = list.nextLevelPrizeAmount + ' %'
        }
        //判断是否可领取
        const bonusItem = bonusList.data.data.filter((item) => el.configCode.indexOf(item.configCode) > -1)[0]
        el.available = !!bonusItem
        if (bonusItem) {
          el.fetchCurrency = bonusItem.fetchCurrency || currency.currency || 'JPY'
          el.requestId = bonusItem.requestId
        }
        filterData.push(el)
      }
    })
    setVipList(filterData)
  }
  const claimBonus = (item) => {
    if (!item.available) return
    const params = {
      activityCode: 'bbp3xDV3PS',
      extraDataMap: {
        fetch_currency: item.fetchCurrency,
        req_id: item.requestId
      }
    }
    applyBonus(params).then((res) => {
      if (res.success) {
        SET_GLOBAL_MSG({ flag: true, type: 'success', content: t('activity.vip.claimSuccess') })
        getData()
        getBonusTaskList()
      }
    })
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <div className="reward-vip">
      <RewardSwiper
        swipData={vipList}
        spinning={spinning}
        cardType="vip"
        render={(item: any) => (
          <RewardCard cardInfo={item} claim={claimBonus(item)} cardType="vip" key={item.activityCoe} />
        )}
      ></RewardSwiper>
    </div>
  )
}

export default RewardVip
