import { useState, useEffect, useMemo, useRef } from 'react'
import { useSetState } from 'ahooks'
import { useUserStore } from '@/stores/user'
import { useAppStore } from '@/stores/app'
import { useParams, useRouter } from 'next/navigation'
import { useTranslation } from '@/lib/locales/client'
import { NoticeListProps, NoticeImgMap, NoticeTabProps } from '@/types/notice'
import { Pagination } from '@/types/common'
// import { useLangHelp } from '@/hooks/useLangHelp'
import { batchUpdateStationMsgRecordFrontApi, getSysContent } from '@/api/notice'
import { addDays, subDays } from 'date-fns'
import { useShallow } from 'zustand/react/shallow'
// import { message as AntdMessage } from '@/components/base/antdNotice'

const getPreviewMsgContent = (msgContent: string, i18) => {
  if (!msgContent) return
  const text = msgContent.replace(/<(img).*?>/g, '') // 去除所有html表情
  if (text.length > 0) {
    return text
  } else {
    return i18('notice.detailBtn')
  }
}

const getPreviewMsgContentText = (msgContent: string, i18) => {
  // const { t } = useTranslation()
  if (!msgContent) return
  const text = msgContent // 去除所有html表情
    .replace(/<(style|script|iframe)[^>]*?>[\s\S]+?<\/\1\s*>/gi, '')
    .replace(/<[^>]+?>/g, '')
    .replace(/\s+/g, ' ')
    .replace(/ /g, ' ')
    .replace(/>/g, ' ')
    .trim()
  if (text.length > 0) {
    return text
  } else {
    return i18('notice.detailBtn')
  }
}

/**
 * 设置消息已读
 * @param ids
 */
const readySysContent = (msgs: Array<any>) => {
  const ids = msgs
    .filter((item) => {
      return item.isRead === 0
    })
    .map((item) => {
      return item.id
    })

  if (ids && ids.length > 0) {
    batchUpdateStationMsgRecordFrontApi({ idArr: ids, isRead: 1 }).then((res: any) => {
      // _this.$success(_this.$t("bonus.success"))
    })
  }
}

export const useNotice = () => {
  const { t } = useTranslation()
  const isLogin = useUserStore((state) => state.isLogin)
  const { isBigScreen, SET_GLOBAL_MSG } = useAppStore(
    useShallow((state) => ({
      isBigScreen: state.isBigScreen,
      SET_GLOBAL_MSG: state.SET_GLOBAL_MSG
    }))
  )
  const router = useRouter()
  const { lng } = useParams()
  const [activeMarket, setActiveMarket] = useState(null)
  const [activeTab, setActiveTab] = useState<number>(1)
  const [deleteIsVisible, setDeleteIsVisible] = useState<boolean>(false)
  const [drawerIsVisible, setDrawerIsVisible] = useState<boolean>(false)
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false)
  const [curDeleteData, setCurDeleteData] = useState<Array<any>>([])
  const [curReadData, setCurReadData] = useState<Array<any>>([])
  const [msgTypes, setMsgTypes] = useState<Array<any>>([])
  const [noticeDetailDialogVisible, setNoticeDetailDialogVisible] = useState<boolean>(false)
  const [curMsgContent, setCurMsgContent] = useState<string>('')
  const [curMsgTitle, setCurMsgTitle] = useState<string>('')
  const [curMsgDate, setCurMsgDate] = useState<string>('')
  const [curMsgPicLink, setCurMsgPicLink] = useState<string>('')
  const [curMsgJumpLink, setCurMsgJumpLink] = useState<string>('')
  const [curTabIndex, setCurTabIndex] = useState<number>(0)
  const [noticePageCurrent, setNoticePageCurrent] = useState<number>(1)
  const [marketPageCurrent, setMarketPageCurrent] = useState<number>(1)
  const [deleteOnlyOne, setDeleteOnlyOne] = useState<boolean>(false) // 删除单条数据
  const [marketVisible, setMarketVisible] = useState<boolean>(false)
  const [hasMore, setHasMore] = useState(true) // 是否还有更多数据
  const pagination = useRef<Pagination>({
    total: 0,
    current: 1,
    pageSize: 10
  })
  const tabData = [
    {
      text: t('misc.notice.message'),
      param: ['STATION_MESSAGE']
    },
    {
      text: t('misc.notice.notification'),
      param: ['SYSTEM_NOTIFICATION']
    },
    {
      text: t('misc.notice.announcement'),
      param: ['POP_UP_ANNOUNCEMENT']
    }
  ]

  const tabDataPC = [
    {
      text: t('misc.notice.all'),
      param: ['STATION_MESSAGE', 'SYSTEM_NOTIFICATION', 'POP_UP_ANNOUNCEMENT']
    },
    ...tabData
  ]

  const tabs = [{ label: t('misc.notice.tab1'), isHide: true }, { label: t('common.account.notice') }]

  // 消息类型,SYSTEM_NOTIFICATION-系统通知,STATION_MESSAGE-站内信,MARQUEE-跑马灯

  const getImg = (msgType: 'SYSTEM_NOTIFICATION' | 'STATION_MESSAGE' | undefined) => {
    const imgMap: NoticeImgMap = {
      STATION_MESSAGE: '/static/images/myAccount/common/msg-type-2.png',
      SYSTEM_NOTIFICATION: '/static/images/myAccount/common/msg-type-1.png',
      POP_UP_ANNOUNCEMENT: '/static/images/myAccount/common/msg-type-4.png',
      MARKET_MESSAGE: '/static/images/myAccount/common/msg-type-3.png'
    }
    if (msgType) {
      return imgMap[msgType]
    }
  }

  const [noticeList, setNoticeList] = useState<Array<any>>([])
  // const [marketList, setMarketList] = useState<Array<any>>([])

  //   消息类型,SYSTEM_NOTIFICATION-系统通知,STATION_MESSAGE-站内信,MARQUEE-跑马灯
  const getNoticeDate = (queries = {}, config = { hideLoading: false }, type = '1') => {
    if (!isBigScreen && pagination.current.current >= pagination.current.total && type === 'more') {
      setHasMore(false)
      return
    }

    if (!isBigScreen && pagination.current.current < pagination.current.total && type === 'more') {
      pagination.current.current = pagination.current.current + 1
    }

    const data = {
      pageSize: pagination.current.pageSize,
      pageNo: pagination.current.current,
      batchId: -1, //-1 批量人工发送，-1系统自动发送
      isDeleted: 0,
      createdDateBegin: subDays(new Date(), 7).toISOString(),
      createdDateEnd: new Date().toISOString()
    }
    if (!isLogin) return
    getSysContent(data, config).then(({ success, data }: any) => {
      if (success) {
        let noticeListInit = []
        noticeListInit = data.data.map((item: any) => {
          return {
            ...item,
            img: getImg(item.msgType),
            onClick: () => router.push(`/${lng}/account/notice-detail?id=${item.recordId}`),
            preMsgContent: getPreviewMsgContent(item.msgContent, t),
            preMsgContentText: getPreviewMsgContentText(item.msgContent, t)
          }
        })
        if (type === 'more') {
          setNoticeList(isBigScreen ? noticeListInit : [...noticeList, ...noticeListInit])
        } else {
          setNoticeList(noticeListInit)
        }
        pagination.current.total = data.totalPage
      } else {
        setNoticeList([])
        pagination.current.total = 0
      }
    })
  }

  // const getMarketDate = (queries = {}, config = { hideLoading: false }, type = '1') => {
  //   if (!isBigScreen && pagination.current.current >= pagination.current.total && type === 'more') {
  //     return
  //   }

  //   if (!isBigScreen && pagination.current.current < pagination.current.total && type === 'more') {
  //     pagination.current.current = pagination.current.current + 1
  //   }

  //   const data = {
  //     size: pagination.current.pageSize,
  //     current: pagination.current.current,
  //     queries: {
  //       ...queries,
  //       msgTypes: [3],
  //       sendClients: ['MAIN_WEBSITE']
  //     },
  //     asc: [],
  //     desc: [],
  //     orders: []
  //   }
  //   if (!isLogin) return
  //   getSysContent(data, config).then(({ success, data }: any) => {
  //     if (success) {
  //       let marketListInit = []
  //       marketListInit = data.records.map((item: any) => {
  //         return {
  //           ...item,
  //           img: getImg(item.msgType),
  //           onClick() {
  //             setActiveMarket(item)
  //             setMarketVisible(true)
  //             console.log('点击营销信息')
  //           },
  //           preMsgContent: getPreviewMsgContent(item.msgContent, t),
  //           preMsgContentText: getPreviewMsgContentText(item.msgContent, t)
  //         }
  //       })

  //       setMarketList(isBigScreen ? marketListInit : marketList.concat(marketListInit))
  //       pagination.current.total = data.totalPage
  //     } else {
  //       setMarketList([])
  //       pagination.current.total = 0
  //     }
  //   })
  // }

  const changePage = (cPagination: any) => {
    pagination.current.current = cPagination.current
    getNoticeDate({}, { hideLoading: true })
    // if (activeTab === 1) {
    //   getNoticeDate({}, { hideLoading: true })
    // } else {
    //   getMarketDate({}, { hideLoading: true })
    // }
  }

  //点击站内信显示详情 并 调用接口 设置为已读
  const clickNews = (item: NoticeListProps) => {
    setCurMsgContent(item && item.content)
    setNoticeDetailDialogVisible(true)
    setCurMsgTitle(item && item.subject)
    setCurMsgDate(item && item.createdDate)
    setCurMsgPicLink(item && item.picLink)
    setCurMsgJumpLink(item && item.jumpLink)

    const arr = []
    arr.push(item)
    readySysContent(arr)

    // if (activeTab === 1) {
    const tempNotice = [...noticeList]
    tempNotice.forEach((list) => {
      if (list.id === item.id) {
        list.isRead = 1
      }
    })
    setNoticeList(tempNotice)
    // } else {
    //   const tempMarketList = [...marketList]
    //   tempMarketList.forEach((list) => {
    //     if (list.recordId === item.recordId) {
    //       list.readStatus = 'HAVE_READ'
    //     }
    //   })
    //   setMarketList(tempMarketList)
    // }
  }

  useEffect(() => {
    getNoticeDate({}, { hideLoading: false })
  }, [])

  // 一键删除
  const onDeleteAll = () => {
    if (!noticeList.length) return
    setDeleteIsVisible(true)
    setCurDeleteData([])
    setDeleteOnlyOne(false)
  }

  // 删除单条数据
  const onDelete = (data: any) => {
    setDeleteIsVisible(true)
    setCurDeleteData([data])
    setDeleteOnlyOne(true)
  }

  const showDrawer = () => {
    setDrawerIsVisible(true)
  }

  const onCloseDrawer = () => {
    setDrawerIsVisible(false)
  }

  const onDeleteFn = () => {
    setDeleteLoading(true)
    if (deleteOnlyOne) {
      onDeleteSysContent(curDeleteData)
    } else {
      onDeleteSysContent(noticeList)
    }
  }

  const onCloseDialog = () => {
    setDeleteIsVisible(false)
  }

  // 删除消息
  const onDeleteSysContent = (msgs: Array<any>) => {
    const ids = msgs.map((item) => {
      return item.id
    })

    if (ids && ids.length > 0) {
      batchUpdateStationMsgRecordFrontApi({
        idArr: ids,
        isDeleted: 1,
        isRead: 1
      }).then((res: any) => {
        SET_GLOBAL_MSG({ flag: true, type: 'success', content: t('misc.notice.deletedSuccessfully') })
        setDeleteLoading(false)
        setDeleteIsVisible(false)

        // if (activeTab === 1) {
        setNoticeList([])
        setNoticePageCurrent(1)
        pagination.current.current = 1 // --- 待处理
        getNoticeDate({}, { hideLoading: true })
        // } else {
        //   setMarketList([])
        //   setMarketPageCurrent(1)
        //   pagination.current.current = 1
        //   getMarketDate({}, { hideLoading: true })
        // }
      })
    }
  }
  function contentImgClick() {
    // -- 待处理
    // router.push({
    //   path: curMsgJumpLink,
    // })
  }

  // 一键已读
  const onReadAll = () => {
    if (!noticeList.length) return
    setCurReadData(noticeList)
    const params = {
      allRead: true
    }

    batchUpdateStationMsgRecordFrontApi(params).then((res: any) => {})
    // if (activeTab === 1) {
    const tempNotice = [...noticeList]
    tempNotice.forEach((list) => {
      list.isRead = 1
    })
    setNoticeList(tempNotice)
    // } else {
    //   const tempMarketList = [...marketList]
    //   tempMarketList.forEach((list) => {
    //     list.readStatus = 'HAVE_READ'
    //   })
    //   setMarketList(tempMarketList)
    // }
  }

  // H5 删除单条
  const onDeleteH5 = (data: any) => {
    setCurDeleteData([])
    setDeleteOnlyOne(true)
    onDeleteSysContent([data])
  }

  // 筛选消息类型
  const onGetMsgTypesData = (val: any, i: number) => {
    if (val && val.length) {
      setMsgTypes(val)
    }
    if (i || i === 0) {
      setCurTabIndex(i)
    }
    setNoticeList([])
    setNoticePageCurrent(1)
    getNoticeDate({}, { hideLoading: true })
    setDrawerIsVisible(false)
  }

  // H5 站内信 重置
  const onGetAllMsgTypesData = () => {
    setMsgTypes(['STATION_MESSAGE', 'SYSTEM_NOTIFICATION', 'POP_UP_ANNOUNCEMENT'])
    setNoticeList([])
    setNoticePageCurrent(1)
    getNoticeDate({}, { hideLoading: true })
    setDrawerIsVisible(false)
  }

  // H5 选择需要筛选的站内信类型
  const onChooseTabH5 = (val: any, i: number) => {
    setMsgTypes(val)
    setCurTabIndex(i)
  }

  return {
    getNoticeDate,
    pagination,
    changePage,
    noticeList,
    marketVisible,
    activeTab,
    clickNews,
    activeMarket,
    tabs,
    onDelete,
    deleteIsVisible,
    showDrawer,
    drawerIsVisible,
    onCloseDrawer,
    deleteLoading,
    onDeleteFn,
    onCloseDialog,
    curDeleteData,
    onDeleteAll,
    curReadData,
    onReadAll,
    onGetMsgTypesData,
    msgTypes,
    onChooseTabH5,
    noticeDetailDialogVisible,
    curMsgContent,
    tabData,
    tabDataPC,
    curTabIndex,
    onDeleteH5,
    noticePageCurrent,
    marketPageCurrent,
    onGetAllMsgTypesData,
    curMsgTitle,
    curMsgDate,
    curMsgPicLink,
    curMsgJumpLink,
    setNoticeDetailDialogVisible,
    setActiveTab,
    setMarketVisible,
    contentImgClick,
    hasMore
  }
}
