// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { json } from "stream/consumers"
import request from "./request"
import requestOld from './request-old'

export const get = (url: string, params?: any) => {
  if(verifyUrl(url)){
    return requestOld.get(url, { params })
  }else{
    return request.get(url, { params })
  }
}

export const post = (url: string, params?: any, options?: any) => {
  if(verifyUrl(url)){
    return requestOld.post(url, params, options)
  }else{
    return request.post(url, params, options)
  }
}

// 第三方接口请求（需要完整第三方接口URL，目前使用为首页-河马体育）
export const fetchData = async (fullUrl, params = {}, option = {}) => {
  const options = {
    method: 'POST',
    mode: "cors",
    ...option,
    headers: {
      "Content-Type": "application/json",
      ...(option.headers || {})
    },
  }
  if(params && Object.keys(params).length) options.body = JSON.stringify(params)
  const response = await fetch(fullUrl, options)
  return response.json()
}

//验证字符串
function verifyUrl(url){
  if(url.includes('/phoenix/') || url.includes('/rex/')){
    return true
  }else{
    return false
  }
}
