export const bets = {
  // export const BETS = {
  title: 'Settled Bets',
  titleno: 'Unsettled Bets',
  id: 'SEARCH BY EXACT ID',
  ticketID: 'Ticket ID',
  sport: 'GAME TYPE',
  sportlow: 'Game Type',
  selection: 'SELECTION',
  selectionlow: 'Selection',
  status: 'STATUS',
  statuslow: 'Status',
  from: 'FROM',
  to: 'TO',
  confirm: 'Submit',
  cancel: 'CLEAR',
  noData: 'You do not have any unsettled bets at the moment.',
  voids: 'Void',
  win: 'Win',
  lose: 'Lose',
  refund: 'Refund',
  halfWin: 'Half Win',
  halfLose: 'Half Lose',
  single: 'Single',
  combo: 'Combo',
  system: 'System',
  pa: 'Pending',
  set: 'Settled',
  bonus: 'Bonus',
  canceled: 'Canceled',
  title1: 'TICKET ID',
  title2: 'DATE',
  title3: 'GAME NAME',
  title4: 'ODDS',
  title5: 'STAKE',
  title6: 'WALLET TYPE',
  title7: 'AMOUNT',
  title8: 'TYPE',
  title9: 'PAYOUT',
  title10: 'VALID TURNOVER',
  pend: 'Refund',
  reject: 'Reject'
}
