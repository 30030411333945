export const user = {
  badge: 'バッジ',
  details: 'もっと見る',
  comingSoon: '近日公開予定',
  vipProgress: 'VIPの進捗状況',
  fastDeposit: 'クイック入金',
  tips: '新規登録するにはパズルを完成させます',
  termsChecked: '利用規約をご確認ください',
  setup: 'クイック設定',
  once: 'ユーザーネームの変更は一度までです。',
  save: 'ユーザーネームとパスワードを保存してください。',
  uLogin: 'ログイン',
  login: 'ログイン',
  signup: '新規登録',
  uRegister: '新規登録',
  uRegister2: '新規登録',
  uLogin2: '新規登録',
  play: '今すぐプレイ',
  confirm: '確認する',
  headUser: {
    lastLogin: '最終ログイン',
    credit: 'キャッシュ',
    bonus: 'ボーナス',
    toDeposit: ' 入金 ',
    withdraw: ' 出金 ',
    gameLoading: 'ゲーム進行中...',
    wager: '次のレベルに必要な賭け金',
    registrationTime: '登録時間',
    EditAvatar: 'アバター編集',
    EditAvatarTip: 'プロフィール画像に使用するアバターをお選びください',
    coming: 'リベートボーナス近日開始'
  },
  resetPwd: {
    resetSuccess: 'パスワードのリセットが成功しました!',
    resetSuccessTips: 'アカウントの安全性を確保するため、新しいパスワードを設定するとログアウトされます。',
    resetSuccessUnderstand: '確認しました',
    email: 'メール',
    title: 'パスワードリセット',
    tips: 'パスワードをお忘れの場合、下記よりリセットをしてください。',
    sendTip: 'Please enter your QueenCasino E-Mail address and we will send you a new password shortly.',
    sendedTips:
      'パスワードリセットのリクエストがされました。パスワードをリセットするためのリンクを記載したメールをお送りします。'
  },
  authDialog: {
    quick: 'ワンクリック',
    dLogin: 'ログイン',
    dReg: '新規登録',
    dThird: 'または ',
    regPhone: '携帯電話',
    regEmail: 'メール',
    phonePlace: '携帯電話番号',
    EmailPlace: 'メール',
    refCodePlace: '紹介コード',
    authPassword: 'パスワード',
    logPW: 'パスワード',
    logCode: '認証コード',
    userNamePlac: 'ユーザー名または携帯番号またはEメールアドレス',
    codePlace: '携帯番号',
    authCodePlace: '6桁の確認コードを受け取る',
    termsTips: `私は18歳以上であるということを保証し、<a class="le_fh" href="/static/termsAndConditions"  target="_blank">「利用規約」</a>に記載されている事項について同意します。`,
    termsTips1:
      "I agree to the <span  style='color:#2196F3;cursor: pointer;'>Terms of Service</span> & Confirm I am at least 18 years old.",
    dLeftTips1: "WELCOME TO <span style='color: #F44336'>Queen Casino</span>",
    dLeftTips2: 'START YOUR GAME JOURNEY NOW',
    sendTips: '確認コードが届きません',
    rememberAcc: '次回から自動でログイン',
    forgotPS: 'パスワードをお忘れですか?',
    sendSuc: '確認コードが正常に送信されました',
    regSucText: '登録に成功し',
    logSucText: 'ログインに成功しました',
    thirdLoginFailedText: 'サードパーティのログインに失敗しました',
    toRegist1: 'まだアカウントをお持ちでないですか？',
    toRegist2: '新規アカウントの作成',
    toRegist3: '既にアカウントをお持ちの方?',
    binded: 'ログインにお困りの際は、',
    contact: 'ライブチャットへお越しください',
    send: 'コードを送信',
    loginWith: '以下のアカウントでログインする'
  },
  sliderCaptcha: {
    slideReg: 'スライドパズル',
    slideLog: 'スライドパズル',
    suc: '正常に承認されました',
    use2: 'ログイン ボタンをクリックして続行します',
    use3: '確認 ボタンをクリックします',
    fail: '検証に失敗した',
    tip1: 'スライダをドラッグして画像を調整します',
    timeOut: 'タイムアウト',
    resetTip: '更新してもう一度実行してください',
    verifySuccess: '検証成功',
    filterTxt: 'スライドしてパズルを完成させます'
  },
  regSuc: {
    newWelcome: 'クイーンカジノへようこそ！',
    newUNPlac: '新しいユーザー名を作成する',
    nwPWPlac: '新しいパスワードを作成し',
    editOnce: '今後の変更はできません',
    unTips: '文字と数字',
    pwTips: 'パスワードは6〜32文字でなければならず、文字、数字、記号を含む必要があります',
    confirm: '確認する'
  },
  codeTips: {
    codeTip1: '携帯番号を確認する',
    codeTip2: 'ネットワーク接続に問題が発生している可能性があります',
    codeTip3: '有効なメールアドレスをご入力ください',
    codeTip4: '迷惑フォルダをご確認ください'
  },
  loginSafe: {
    safeVerify: 'セキュリティ検証'
  },
  saveAccount: {
    save: 'このページのスクリーンショットを撮って保存して下さい',
    useName: 'ユーザー名: ',
    password: 'パスワード: '
  },
  logOthes: 'お客様のアカウントは、他の端末でログアウト操作されました',
  lostPop: {
    phone_register_8: '2FA を紛失しました',
    phone_register_11: '2FA が適切に機能しているかどうかを確認してください。',
    phone_register_12: '2FA をリセットしてください。',
    phone_register_13: 'ご質問がございましたら、お気軽にお問い合わせください',
    phone_register_30: '連絡先',
    phone_register_31: '私たち',
    phone_register_32: '確認コードを入力してください',
    phone_register_33: '確認コードを入力する必要があります',
    phone_register_34: '確認コードの形式が間違っています',
    v2fTitle: '2FA 認証',
    lockUserMsgs1:
      '「新しいデバイスまたは新しい IP からサインインしているため、アカウントの安全性を確保するために、カスタマー サービスに連絡してアカウントのロックを解除し、メールを確認することをお勧めします。」',
    lockUserMsgs2:
      'アカウントのセキュリティのため、メールのロックを解除してリンクするためにカスタマー サービスに連絡してください',
    haveSendCodePhone: 'にメッセージを送信しました',
    haveSendCodeEmail: 'にメールを送信しました',
    userName: 'ユーザー名',
    nameRepeat: 'ユーザー名はすでに存在します',
    emailRepeat: '電子メールはすでに存在します'
  }
} as const
