// H5端左侧栏
'use client'
import { useState, useEffect, useMemo, memo, useRef } from 'react'
import { cn } from '@/lib/utils'
import { useDeepCompareEffect } from 'ahooks'
import { cmsTemplateApi } from '@/api/base'
import { Input, Flex } from 'antd'
import meta from '@/app/[lng]/meta'
import { useTranslation } from '@/lib/locales/client'
import { useAppStore } from '@/stores/app'
import { useUserStore } from '@/stores/user'
import { useAuthStore } from '@/stores/authControl'
import { CSSTransition } from 'react-transition-group'
import { useRouter, usePathname, useParams } from 'next/navigation'
import { useRedirect } from '@/lib/useRedireact'
import { track } from '@/hooks/useTrack'
import styles from '../_common_css/sidebar.module.scss'
import { useShallow } from 'zustand/react/shallow'
// com *
import QueryLang from '@/components/base/queryLang'
import LotteryBox from '@/app/[lng]/(promotion)/promo/lottery-tickets/_components/LotteryBox'

const NavPage = () => {
  const { t } = useTranslation()
  const {
    isShowSideNav,
    globalPublickKey,
    lotteryTicketConfig,
    setIsShowSideNav,
    setIsShowAddToHome,
    setIsShowNotification
  } = useAppStore(
    useShallow((state) => ({
      isShowSideNav: state.isShowSideNav,
      globalPublickKey: state.globalPublickKey,
      lotteryTicketConfig: state.lotteryTicketConfig,
      setIsShowSideNav: state.setIsShowSideNav,
      setIsShowAddToHome: state.setIsShowAddToHome,
      setIsShowNotification: state.setIsShowNotification
    }))
  )
  // accountTransferRecord 待处理
  const { userLogout, isLogin } = useUserStore(
    useShallow((state) => ({
      userLogout: state.userLogout,
      isLogin: state.isLogin
    }))
  )
  const openLogin = useAuthStore((state) => state.openLogin)
  const router = useRouter()
  const { lng } = useParams()
  const [queryImage, setQueryImage] = useState('')
  const [menuTopEntrance, setMenuTopEntrance] = useState([])
  const [menuListAll, setMenuListAll] = useState([])
  const secondMenuList = useRef([])
  const [menuList7, setMenuList7] = useState([])
  const [menuList8, setMenuList8] = useState([])
  const [bettingTopEntrance, setBettingTopEntrance] = useState<any>()
  const pathName = usePathname()
  const rawPath = pathName.replace(/^\/(en|ja)($|\/)/g, '/')
  const redirectPath = useRedirect()
  const hideSidebar = useMemo(() => {
    if (Object.keys(meta).length) return meta[rawPath]?.hideSidebar
  }, [pathName])

  // 原项目 直接合并1-6的菜单，这里单独处理下，减少重复代码，待观察
  // const allList = [menuList1, menuList2, menuList3, menuList4, menuList5, menuList6]
  const updateMenuList = (menuData) => {
    let allMenuList = [...Array(6)].fill(null) // 初始化6个空数组
    for (let i = 0; i < allMenuList.length; i++) {
      allMenuList[i] = menuData.filter(
        (item) => item.templateComment && item.templateComment.includes(`menuList${i + 1}`)
      )
    }
    return allMenuList
  }

  // 下载线上配置的图片
  useDeepCompareEffect(() => {
    if (menuList7 && menuList7.length) {
      setQueryImage(menuList7[0]?.mediaLibrary[0]?.fullFilePath || '')
    }
  }, [menuList7])

  // const isCompleteVerify = useMemo(() => {
  //   return (
  //     !!userInfo.firstNameJapanese &&
  //     !!userInfo.lastNameJapanese &&
  //     !!userInfo.mobile &&
  //     !!userInfo.email &&
  //     kycAuditStatus === 2
  //   )
  // }, [])

  // const progress = useMemo(() => {
  //   let num = vipInfo.isMaxLevel ? 100 : vipInfo.progress || 0
  //   num = num > 100 ? 100 : num < 0 ? 0 : num
  //   return Math.abs(num)
  // }, [])

  // const isActiveIcon = (item) => {
  //   return item.name === language
  // }
  // 由标签直接带入
  // const getPopupContainer = () => {
  //   return document.querySelector('.nav_page_wrap')
  // }
  const onOpenAddToHome = () => {
    setIsShowSideNav(false)
    setIsShowAddToHome(true)
  }
  const logoutUser = () => {
    setIsShowSideNav(false)
    try {
      userLogout()
    } catch (err) {
      console.log('logout err', err)
    }
    router.push(`/${lng}`)
  }
  // 搜索子菜单文本 匹配菜单下是否有二级菜单......
  const getSubMenuCode = (str) => {
    if (str && str.includes('【')) {
      const matchData = str.match(/【([^】]+)】/)
      return matchData?.[1] || ''
    } else {
      return ''
    }
  }

  const openSearch = () => {
    setIsShowSideNav(false)
    // window.history.pushState(null, '', `${lng}/search-game`)
    router.push(`/${lng}/searchGame`, { scroll: false })
  }

  const toPage = (path) => {
    if (path) {
      setIsShowSideNav(false)
      if (path === '/account/deposit') track('RechargeClick')
      redirectPath(path)
    }
  }

  useEffect(() => {
    if (globalPublickKey) queryNavigation()
  }, [globalPublickKey])

  useEffect(() => {
    if (isShowSideNav) {
      document.body.classList.add('nav-page-no-scroll')
    } else {
      document.body.classList.remove('nav-page-no-scroll')
    }
  }, [isShowSideNav])

  // 读取侧边栏数据
  const queryNavigation = async () => {
    const params = {
      moduleCodeList: ['headerNavigation', 'menuTopEntrance', 'bettingTopEntrance']
    }
    const siderMenuData: Resp = await cmsTemplateApi(params)
    if (siderMenuData?.success) {
      // headerNavigation 菜单数据
      const filterModuleData = siderMenuData.data.filter((item) => item.moduleCode === 'headerNavigation')
      // 重新组织菜单结构 返回一个带2级菜单结构的数组
      const tempMenuData = filterModuleData.map((item) => {
        const subCodeData = getSubMenuCode(item.templateComment)
        return {
          ...item,
          subMenuCode: subCodeData,
          subMenuList: [],
          isShowSubMenu: false
        }
      })
      // menuTopEntrance 菜单数据
      const MenuTopFilter = siderMenuData.data
        .filter((item, index) => item.moduleCode === 'menuTopEntrance')
        .filter((item, index) => index < 2)
      setMenuTopEntrance(MenuTopFilter)
      // bettingTopEntrance 菜单数据
      const betTop = siderMenuData.data.filter((item, index) => item.moduleCode === 'bettingTopEntrance')?.[0]
      setBettingTopEntrance(betTop)
      // 获取有二级菜单的子数据，只包括menu code的文字数组
      const subMenuCodeList = tempMenuData.map((item) => item.subMenuCode).filter((item) => item)
      // 根据分类出来的子菜单名称 去获取子菜单的接口
      const params = {
        moduleCodeList: subMenuCodeList
      }
      const getSubMenuData: Resp = await cmsTemplateApi(params)
      if (getSubMenuData?.success) {
        // 将重新组装的二级菜单数据 回写到菜单数组
        const newMenuData = tempMenuData.map((item) => ({
          ...item,
          subMenuList:
            item.subMenuCode && getSubMenuData.data.filter((unit) => item.subMenuCode === unit.moduleCode)
        }))
        // 保存二级菜单数据，用于判断是否展示二级菜单
        secondMenuList.current = newMenuData
        // 更新全列表代码
        const allMenuList = updateMenuList(newMenuData)
        setMenuListAll(allMenuList)
        // 特殊处理 menu7和8 显示语言和退出
        setMenuList7(
          newMenuData.filter((item) => item.templateComment && item.templateComment.includes('menuList7'))
        )
        setMenuList8(
          newMenuData.filter((item) => item.templateComment && item.templateComment.includes('menuList8'))
        )
      }
    }
  }

  // 点击子菜单
  const clickHandle = (menu) => {
    // console.log('点击菜单', menu)
    if (menu.templateAction === '/livechat') {
      onOpenChat()
      setIsShowSideNav(false)
      return
    }
    if (menu.templateAction === '/Add To Home Screen') {
      onOpenAddToHome()
      setIsShowSideNav(false)
      return
    }
    // 展开子菜单
    if (menu.subMenuCode && menu?.subMenuList.length > 0) {
      // 重新组件父级数组
      const togShowSubMenuList = secondMenuList.current.map((item) => {
        return {
          ...item,
          isShowSubMenu: item.templateComment === menu.templateComment && !menu.isShowSubMenu
        }
      })
      // 更新全列表代码
      const allMenuList = updateMenuList(togShowSubMenuList)
      // setMenuListAll(allMenuList)
      setMenuListAll([...allMenuList])
      // setMenuListAll([...menuListAll, ...allMenuList])
      // 特殊处理 menu7和8 显示语言和退出
      return
    }

    const path = menu.templateAction
    if (path === 'showNotification') {
      setIsShowSideNav(false)
      setIsShowNotification(true)
      return
    }

    if (!path) return
    if (!isLogin) {
      const pathList = ['/account', '/sports-bet']
      if (pathList.includes(path)) {
        openLogin()
        setIsShowSideNav(false)
        return
      }
    }

    redirectPath(path)
    setIsShowSideNav(false)
  }

  const onOpenChat = () => {
    window.Intercom('show')
  }

  return (
    <>
      {!hideSidebar && (
        <CSSTransition timeout={220} in={isShowSideNav} unmountOnExit classNames={'fade'}>
          <div className={styles['menu-container']}>
            <div className={'nav_page_wrap'}>
              <div className={cn('menu-list-container', !isLogin && 'no-login')}>
                <div className={'close-box'}>
                  <img
                    className={'close_btn'}
                    src="/static/images/home/memberDetail/close.svg"
                    alt=""
                    onClick={() => setIsShowSideNav(false)}
                  />
                </div>
                {bettingTopEntrance && (
                  <div
                    onClick={() => toPage(bettingTopEntrance.templateAction)}
                    className={'betting-top-entrance'}
                  >
                    <img src={bettingTopEntrance?.mediaLibrary[0].fullFilePath} alt="" />
                  </div>
                )}
                {menuTopEntrance.length > 0 && (
                  <div className={'menu-list-top-entrance'}>
                    {menuTopEntrance.map((entrance) => (
                      <div
                        key={entrance.templateId}
                        style={{
                          backgroundImage: entrance.mediaLibrary[0]
                            ? `url(${entrance.mediaLibrary[0].fullFilePath})`
                            : ''
                        }}
                        onClick={() => toPage(entrance.templateAction)}
                        className={'menu-entrance-item'}
                      ></div>
                    ))}
                  </div>
                )}
                {/* 集奖券 */}
                {lotteryTicketConfig?.mainShow && (
                  <div className="mb-[8px]">
                    <LotteryBox boxType="home" />
                  </div>
                )}
                {/* 搜索栏 */}
                <div className={'navpage-seach'} onClick={openSearch}>
                  <div className={'mask'}></div>
                  {/* 原项目直接用组件做样式 待处理 处理成只是跳转就好了 */}
                  <div className={'input-warp'}>
                    <Input
                      placeholder={t('home.game.input')}
                      prefix={<img className={'search-icon'} src="/static/images/game/search-icon.svg" />}
                    ></Input>
                  </div>
                </div>
                {menuListAll
                  .filter((item) => item.length > 0)
                  .map((menuList, index) => (
                    <div key={index} className={'menu-list-entrance'}>
                      {menuList.map((menu) => (
                        <div
                          className={cn(
                            'menu-list-entrance-item',
                            menu.mediaLibrary[0] && 'has-icon',
                            menu.subMenuCode && menu.subMenuList && menu.subMenuList.length > 0 && 'has-list'
                          )}
                          key={menu.templateId}
                        >
                          <>
                            <div
                              onClick={() => clickHandle(menu)}
                              className={'menu-list-entrance-item-content'}
                            >
                              {menu.mediaLibrary[0] && (
                                <img src={menu.mediaLibrary[0].fullFilePath} className={'img'} />
                              )}
                              <span>{menu.title}</span>
                              {menu.mediaLibrary[1] && (
                                <img
                                  src={menu.mediaLibrary[1].fullFilePath}
                                  className={'img'}
                                  style={{ marginLeft: '5px' }}
                                />
                              )}
                              {/* 有子菜单 */}
                              {menu.subMenuCode && menu?.subMenuList.length > 0 && (
                                <img
                                  className={'menu-arrow'}
                                  src={`/static/images/theme-red/i-${menu.isShowSubMenu ? 'minus' : 'plus'}.png`}
                                />
                              )}
                            </div>
                            {menu.subMenuCode && menu?.subMenuList.length > 0 && (
                              <CSSTransition
                                timeout={300}
                                in={menu.subMenuCode && menu?.subMenuList.length > 0}
                                unmountOnExit
                                classNames={'fade'}
                              >
                                <Flex vertical>
                                  <div className={'menu-list'}>
                                    {menu?.subMenuList
                                      .filter((item) => menu.isShowSubMenu)
                                      .map((subMenu) => (
                                        <div
                                          key={subMenu.templateId}
                                          onClick={() => clickHandle(subMenu)}
                                          className="mt-[2px] flex h-[44px] items-center rounded-[4px] bg-white pl-[44px] text-sm font-normal text-black/65"
                                        >
                                          {subMenu.title}
                                          {(subMenu.title === 'Baccarat' || subMenu.title === 'バカラ') && (
                                            <img
                                              className="ml-[8px] w-[63px]"
                                              src="/static/images/theme-red/meau_jackpot.png"
                                              alt=""
                                            />
                                          )}
                                        </div>
                                      ))}
                                  </div>
                                </Flex>
                              </CSSTransition>
                            )}
                          </>
                          {/* )} */}
                        </div>
                      ))}
                    </div>
                  ))}
                {/* change language */}
                {menuList7.length > 0 && (
                  <div>
                    <QueryLang queryImage={queryImage} zIndex={2003} curWidth={'100%'} />
                  </div>
                )}
                {isLogin && menuList8.length > 0 && (
                  <div onClick={logoutUser} className={'log-out'}>
                    <img src={menuList8[0]?.mediaLibrary[0]?.fullFilePath} alt="" className={'icon'} />
                    <span>{t('common.account.logOut')}</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </CSSTransition>
      )}
    </>
  )
}

export default memo(NavPage)
