'use client'
import { useRef } from 'react'
import { useTranslation } from '@/lib/locales/client'
import { Flex } from 'antd'
import { useParams, useRouter } from 'next/navigation'
import { useAuthStore } from '@/stores/authControl'
/* com */
import BaseDialog from '@/components/base/baseDialog'
import Login from '../_components/login'
import Register from '../_components/register'
import SupportThird from '../_components/supportThird'
import { useShallow } from 'zustand/react/shallow'
// import styles from './index.module.scss'

export default function AuthMobileLayout() {
  const { t } = useTranslation()
  const { closeModal, logRegVisible, logRegType, openRegister, openLogin } = useAuthStore(
    useShallow((state) => ({
      closeModal: state.closeModal,
      logRegVisible: state.logRegVisible,
      logRegType: state.logRegType,
      openRegister: state.openRegister,
      openLogin: state.openLogin
    }))
  )
  const router = useRouter()
  const { lng } = useParams()
  const lineTimer = useRef(null)

  const handlClose = () => {
    closeModal()
    clearInterval(lineTimer.current)
    lineTimer.current = null
    router.push(`/${lng}`)
  }

  return (
    <>
      <BaseDialog visible={logRegVisible} width={'100%'} isFullScreen>
        <div className="flex h-full w-full select-none overflow-hidden rounded-none text-white">
          <div
            className="relative w-full bg-[#fafafa]"
            style={{
              background: 'linear-gradient(180deg, #0B625C 21.32%, #EEF6F6 43.71%)'
            }}
          >
            <div
              className="relative w-full pb-[260px] pl-[18px] pt-[80px]"
              style={{
                background: "url('/static/images/user/sportbg_h5.png') no-repeat center",
                backgroundSize: '100% 360px',
                backgroundPosition: 'center'
              }}
            >
              <div className="absolute top-[16px]">
                <span className="text-base font-semibold text-white/85 opacity-100">
                  {logRegType === 'login' ? t('user.login') : t('user.authDialog.dReg')}
                </span>
              </div>
            </div>
            {/* content */}
            <div className="px-[16px]">
              {logRegType === 'login' ? (
                <>
                  <div className="-mt-[120px]">
                    <Login />
                  </div>
                  {/* switch to register */}
                  <Flex justify="space-around" className="-mt-[12px]">
                    <p className="text-sm font-normal leading-[22px] text-black/65">
                      {t('user.authDialog.toRegist1')}
                      <span className="ml-1 cursor-pointer text-[#3F75FF]" onClick={() => openRegister(true)}>
                        {t('user.authDialog.toRegist2')}
                      </span>
                    </p>
                  </Flex>
                </>
              ) : (
                <>
                  <div className="-mt-[120px]">
                    <Register />
                  </div>
                  {/* switch to login */}
                  <Flex justify="space-around" className="-mt-[12px]">
                    <p className="text-sm font-normal leading-[22px] text-black/65">
                      {t('user.authDialog.toRegist3')}
                      <span className="ml-1 cursor-pointer text-[#3F75FF]" onClick={() => openLogin()}>
                        {t('user.login')}
                      </span>
                    </p>
                  </Flex>
                </>
              )}
              {/* third login */}
              <div className="absolute bottom-[20px] left-0 w-full">
                <SupportThird />
              </div>
            </div>
          </div>
          {/* x close icon */}
          <img
            src="/static/images/saveAccount/x_close.svg"
            alt=""
            onClick={handlClose}
            className="z-9 absolute right-[12px] top-[12px] w-[24px] cursor-pointer text-base text-white"
          />
        </div>
      </BaseDialog>
    </>
  )
}
