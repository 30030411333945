import dayjs from 'dayjs'
import { useState, useEffect, memo } from 'react'
import { getGameList } from '@/api/game'
import { openGame } from '@/app/[lng]/(game)/_components/startGame'
import { useUserStore } from '@/stores/user'
import { applyFreeSpin } from '@/api/activity'
import { useAppStore } from '@/stores/app'
import { cn, formatGameImg } from '@/lib/utils'
import styles from '../_common_css/freeSpin.module.scss'
import { Skeleton } from 'antd'
import { useTranslation } from '@/lib/locales/client'
import { useShallow } from 'zustand/react/shallow'

type IProps = {
  className?: string
}

const FreeSpin: React.FC<IProps> = (props) => {
  const { t } = useTranslation()
  const { commonConfig, setIsShowNotification } = useAppStore(
    useShallow((state) => ({
      commonConfig: state.commonConfig,
      setIsShowNotification: state.setIsShowNotification
    }))
  )
  const { freeSpinList, getBonusTaskList } = useUserStore()
  const [listData, setListData] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    ;(async function getFreeSpinData() {
      const gameIds = freeSpinList.map((item) => item.gameId)
      if (gameIds.length === 0) {
        setLoading(false)
        return
      }
      try {
        setLoading(true)
        const result = await getGameList({
          gameIds,
          pageSize: 999,
          hideLoading: true
        })
        if (result?.success) {
          const arrData = result.data?.data || []
          // 获取符合条件的优惠券数据
          const filterSpinList = freeSpinList.reduce((prev, item) => {
            let couponItem = arrData.find(
              (unit) => item.gameId === unit.gameId && item.platformCode === unit.providerCode
            )
            prev.push({ ...item, ...couponItem })
            return prev
          }, [])
          const finalList =
            filterSpinList.length &&
            filterSpinList.sort(
              (a, b) => new Date(b.useExpiredDate).getTime() - new Date(a.useExpiredDate).getTime()
            )
          setListData(finalList)
        }
      } catch (err) {
        console.log('err', err)
      } finally {
        setLoading(false)
      }
    })()
  }, [])

  function playGame(val) {
    if (val.bizCheckStatus === 4) {
      const params = {
        activityCode: val.activityCode,
        id: val.id
      }
      applyFreeSpin(params).then((res) => {
        if (res && res.data) {
          setIsShowNotification(false)
          openGame(0, val, val.providerCurrency)
        }
        getBonusTaskList()
      })
    } else {
      setIsShowNotification(false)
      openGame(0, val, val.providerCurrency)
    }
  }

  return (
    <>
      <div className={cn(styles['free_spin'], props.className)}>
        <Skeleton loading={loading} title={false} paragraph={{ rows: 4 }} active>
          {listData.map((item, index) => (
            <div key={index} className={'free_spin_list'}>
              <div className={'left'}>
                <img className={'box'} src={formatGameImg(item, commonConfig)} />
                <div className={'info'}>
                  <p>{item.gameName}</p>
                  <p className={'time'}>{dayjs(item.useExpiredDate).format('YYYY/MM/DD HH:mm:ss')}</p>
                </div>
              </div>
              <div className={'right'}>
                <span className={'round'}>{item.roundNum}</span>
                {t('bonus.times')}
                <span className={'btn'} onClick={() => playGame(item)}>
                  {t('common.game.play')}
                </span>
              </div>
            </div>
          ))}
        </Skeleton>
      </div>
    </>
  )
}

export default memo(FreeSpin)
