import { Lng, useTranslation } from '@/lib/locales/i18n'
import type { Metadata } from 'next'
import { getBasicURL } from '@/lib/utils'
export interface Meta extends Metadata {
  title: string
  description?: string
  keyword?: string
  locale: string
  remark?: string
  openGraph?: Object
  isPlaceholder?: boolean
  hideHeader?: boolean
  hideFooter?: boolean
  hideSidebar?: boolean
  hideTabbar?: boolean
}

export const pathMetaData = {
  '/': {
    title: 'Home',
    locale: 'home.title',
    description: 'home.description',
    isPlaceholder: false,
    hideHeader: false,
    hideFooter: false
  },

  // promo*
  '/promo': {
    title: 'promotions',
    locale: 'promo.play',
    isPlaceholder: true,
    hideHeader: false,
    hideFooter: false
  },

  '/promo/guess-game': {
    title: 'GuessGame',
    locale: 'home.game.quitGame',
    isPlaceholder: true
  },
  '/promo/tickets': {
    title: 'tickets'
  },

  '/wallet': {
    title: 'Wallet',
    locale: 'home.game.tips2',
    isPlaceholder: true,
    hideHeader: false,
    hideFooter: false
    // Navbar: NavPage
  },

  '/searchGame': {
    title: 'search game',
    locale: 'home.game.tips2',
    isPlaceholder: true,
    hideHeader: true,
    hideFooter: true,
    hideSidebar: true,
    hideTabbar: true
  },

  '/reset-password': {
    title: 'reset password',
    locale: 'home.game.tips2',
    isPlaceholder: true,
    hideHeader: true,
    hideFooter: true,
    hideSidebar: true,
    hideTabbar: true
  },

  //account *
  '/account': {
    title: 'Account',
    locale: 'personal.title',
    isPlaceholder: true,
    hideHeader: false,
    hideFooter: false
  },
  '/account/globalSetting': {
    title: 'globalSetting',
    locale: 'home.game.tips2',
    isPlaceholder: true,
    hideFooter: true
  },
  '/account/deposit': {
    hideFooter: true
  },
  '/account/buyCrypto': {
    hideFooter: true
  },
  '/account/withdraw': {
    hideFooter: true
  },
  '/account/notice': {
    title: 'notice',
    locale: 'home.game.tips2',
    isPlaceholder: true,
    hideFooter: true
  },
  '/account/Loyalty-club': {
    title: 'Loyalty club'
  },
  '/promo/lottery-tickets': {
    title: 'Lottery Tickets',
    hideHeader: false,
  },
  '/account/personal': {
    title: 'user info'
  },
  '/account/verification': {
    title: 'kyc',
    hideFooter: false
  },

  // games*

  '/hippo-sports': {
    title: 'load',
    hideFooter: true,
    hideHeader: true,
    hideSidebar: true,
    hideTabbar: true
  },
  '/fb': {
    title: 'load',
    hideFooter: true,
    hideHeader: true,
    hideSidebar: true,
    hideTabbar: true
  },
  '/slotGame': {
    title: 'load',
    hideFooter: true,
    hideHeader: true,
    hideSidebar: true,
    hideTabbar: true
  },
  '/sports-bet': {
    title: 'load',
    hideFooter: true,
    hideHeader: true,
    hideSidebar: true,
    hideTabbar: true
  },
  '/pachinko': {
    title: 'pachinko'
  },
  '/tournament': {
    title: 'tournament'
  }
}

const meta: {
  [key: string]: Meta
} = pathMetaData as any

export const GenerateMetadata = async (
  lng: Lng,
  path: keyof typeof pathMetaData // 页面传入的url 地址，一般不带语言前缀
) => {
  const metadata = { ...meta[path] }
  const { t } = await useTranslation(lng)
  // 获取 接口seo数据
  const params = { themeCode: 'REDJPCARTOON', url: path }
  let seoData: SEOInfo
  try {
    const result = await fetch(`${getBasicURL()}/api/seoMeta`, {
      method: 'POST',
      headers: {
        lng: lng // 语言参数
      },
      body: JSON.stringify(params)
    })
    const data: Resp = await result.json()

    if (data.success) {
      seoData = data.data
    }
  } catch (err) {
    console.log('get meta err', err)
  }

  metadata.title = `${seoData?.title || t(metadata.locale)}`
  metadata.description = `${seoData?.remark || t(metadata.description)}`
  metadata.keywords = seoData?.keywords || `keyword`
  // other
  metadata.robots = 'index, follow'
  metadata.icons = {
    icon: `${process.env.NEXT_PUBLIC_SITE_URL}/img/icons/apple-touch-icon-152x152.png`,
    apple: `${process.env.NEXT_PUBLIC_SITE_URL}/img/icons/apple-touch-icon-152x152.png`
  }
  metadata.openGraph = {
    type: 'website',
    url: process.env.NEXT_PUBLIC_SITE_URL,
    title: `${seoData?.title || t(metadata.locale)}`,
    description: `${seoData?.remark || t(metadata.description)}`,
    site_name: 'クイーンカジノ',
    images: [
      {
        url: `${process.env.NEXT_PUBLIC_SITE_URL}/img/icons/apple-touch-icon-180-180.png`,
        width: 200,
        height: 200,
        alt: 'a22'
      }
    ]
  }
  // metadata.twitter = {
  //   card: 'summary_large_image',
  //   site: process.env.NEXT_PUBLIC_SITE_URL,
  //   title: `${t(metadata.locale)} | ${t(COMMON_KEYS.PACKAGE_NAME)}`,
  //   description: `${t(META.DESC)}`,
  //   images: `${process.env.NEXT_PUBLIC_SITE_URL}/logo.png`,
  // };

  // metadata.appleWebApp = {
  //   capable: true,
  //   title: t(COMMON_KEYS.PACKAGE_NAME),
  //   startupImage: `${process.env.NEXT_PUBLIC_SITE_URL}/logo.png`,
  //   statusBarStyle: 'black-translucent',
  // };
  // metadata.verification = {
  //   google: 'oKUAZe57ikDxiRUtYzSrO-nv_EMdG_Nju0BoSoevbhM',
  // };

  return metadata
}

export default meta
