export const promo = {
  // export const PROMO = {
  freeSpin: 'FreeSpin',
  bonus: 'Bonus',
  claim: 'Claim',
  deposit: 'Deposit',
  crypto: 'Crypto',
  currency: 'Currency',
  extra: 'Extra Gift',
  endTime: 'End time',
  viewMore: 'View more',
  view: 'View',
  wager: 'Wager required',
  rewardExpire: 'Reward expire',
  betAmount: 'Bet Amount',
  evenExpire: 'Even expire',
  expirationDate: 'Expiration Date ',
  provider: 'Provider',
  currentVIP: 'Current VIP',
  nextLevelXp: 'XP to Next Level',
  nextLevelReward: 'Next Level Reward',
  jackpot: 'Maximam Jackpot',
  reward: 'Reward',
  bonusClaimedTotal: 'Total Bonus Claimed',
  bonusTotal: 'Total Bonus',
  questTotal: 'Total Quest',
  extraTotal: 'Total Extra',
  spinTotal: 'Total Free Spin',
  details: 'Details',
  stateTab: ['Promotions', 'Progress'],
  claimRewards: { name: 'Claim Rewards', tagCode: 'available' },
  promotionTab: [
    { name: "Welcome Packages", tagCode: "welcome" },
    { name: "Quest Bonus", tagCode: "questBonus" },
    { name: "Tournaments", tagCode: "tournament" },
    { name: "VIP Bonus", tagCode: "vip" },
    { name: "Special Bonus", tagCode: "special" }
  ],
  progressTab: ['Bonus', 'Quest', 'Extra', 'Free Spin'],
  cancel: 'Cancel',
  play: 'Play now',
  spin: 'FreeSpin',
  newTask: 'You have a new task',
  completeTask: 'Complete the task to receive',
  claimTask: 'Claim Task',
  approved: 'Approved',
  creditedSuc: 'Successfully Credited',
  bet: 'Bet',
  claimReward: 'Claim Reward',
  taskInfo: {
    kyc1: 'Complete KYC 1',
    kyc2: 'Complete KYC 2',
    kyc3: 'Complete KYC 3',
    deposit1: 'Unlimited recharge amount',
    deposit2: 'Unlimited recharge amount',
    deposit3: 'Unlimited recharge amount',
    betpachinko: 'Play Pachinko/Pachinslot',
    betslots: 'Play Slot ',
    betlive: 'Play Live Casino',
    betsports: 'Play Sports Games',
    sign: "Real Money Betting Game (Effective Bet Amount)"
  },
  closeRemind: 'Close task reminders; you can view all tasks in this promotional offer.',
  notShowToday: 'Don’t remind me again today',
  rank: 'rank',
  time: "Time",
  sign:{
    needKyc: "Please complete the mobile and email verification",
    title: "Daily Reward Instructions",
    ruleTitle: "Claim Rules",
    rule: "You can claim the reward by meeting the required number of sign-ins each month. Bonus release follows the terms of service and general rules.",
    popText: "Congratulations on completing sign-in and receiving your reward.",
    nextReward: "Next Reward",
  }
} as const
