import { memo, FC, useEffect } from 'react'
import { cn } from '@/lib/utils'
import { useTranslation } from '@/lib/locales/client'
import { useAppStore } from '@/stores/app'
import { useGameStore } from '@/stores/game'
import { Skeleton, Pagination } from 'antd'
import GameItemNew from './gameItemNew'
import { useGameData } from './useGameData'
import { useDebounceFn } from 'ahooks'
import styles from '../_common_css/gameSeachList.module.scss'
import { useUpdateEffect } from 'ahooks'

type IProps = {
  searchGameName?: string
  searchProviderList?: any
  seachGameCategories?: string
  filterData?: any
  gameType?: string
  pageType?: string
  recentChange?: (name: string) => void
  setALLNum?: (num: number) => void
}

const GameSeachList: FC<IProps> = (props) => {
  const {
    filterGameList,
    totalCount,
    useSearchType,
    getGameData,
    isLoding,
    setIsLoding,
    setFilterGameList,
    setTotalCount
  } = useGameData(props.pageType)
  const { t } = useTranslation()
  const isBigScreen = useAppStore((state) => state.isBigScreen)
  const allGameLen = useGameStore((state) => state.allGameLen)

  const seachFn = () => {
    setIsLoding(true)
    delete useSearchType.current.gameCategories
    delete useSearchType.current.maxRtp
    delete useSearchType.current.minRtp
    delete useSearchType.current.providerCodes
    delete useSearchType.current.orderByClause
    useSearchType.current.comboName = props.searchGameName
    useSearchType.current.pageNo = 1

    const filterData = props.filterData || {}
    if (JSON.stringify(filterData) !== '{}') {
      if (props.gameType === 'slot') {
        if (filterData.rptValue === 0) {
          useSearchType.current.maxRtp = 0.89
        } else if (filterData.rptValue === 50) {
          useSearchType.current.minRtp = 0.8901
          useSearchType.current.maxRtp = 0.9499
        } else if (filterData.rptValue === 100) {
          useSearchType.current.minRtp = 0.95
        }
        if (filterData.sortAz === 1) {
          useSearchType.current.orderByClause = 'gameName ASC'
        } else  if (filterData.sortAz === 2) {
          useSearchType.current.orderByClause = 'gameName DESC'
        }
      }
      if (filterData.providerChooseList?.length) {
        useSearchType.current.providerCodes = filterData.providerChooseList
      }
      if (filterData.propertiesChooseList?.length) {
        useSearchType.current.gameCategories = filterData.propertiesChooseList
      }
    } else if (props.seachGameCategories) {
      useSearchType.current.gameCategories = [props.seachGameCategories]
    }
    if (props.searchProviderList?.length) {
      useSearchType.current.providerCodes = props.searchProviderList
    }
    if (
      props.pageType === 'searchPage' &&
      props.searchGameName === '' &&
      props.searchProviderList.length === 0
    ) {
      // 搜索页面 如果没有数据不会直接搜索
      setFilterGameList([])
      setTotalCount(0)
    } else {
      getGameData(props.gameType)
    }
  }

  const { run } = useDebounceFn(seachFn, { wait: 600 })

  useUpdateEffect(() => {
    // 输入框防抖
    if (props.searchGameName === '') {
      seachFn()
    } else {
      run()
    }
  }, [
    props.searchGameName,
    props.searchProviderList,
    props.seachGameCategories,
    props.filterData,
    props.gameType
  ])

  useEffect(() => {
    if (!props.seachGameCategories) {
      props.setALLNum && props.setALLNum(totalCount)
    }
  }, [totalCount])

  useEffect(() => {
    if (filterGameList && filterGameList.length > 0 && props.recentChange) {
      //如果在搜索页面，有搜索结果，加入最近搜索列表
      props.recentChange(props.searchGameName)
    }
  }, [filterGameList])

  useEffect(() => {
    seachFn()
  }, [])

  return (
    <div className={cn(props.pageType === 'searchPage' && styles.seachPage, styles.root)}>
      {filterGameList.length > 0 && props.pageType === 'searchPage' && (
        <p
          className="game_page_seach_title"
          dangerouslySetInnerHTML={{
            __html: t('home.game.resultHtml', { target: totalCount, total: allGameLen })
          }}
        ></p>
      )}

      <section className="game_page_seach">
        <Skeleton loading={isLoding} title={false} paragraph={{ rows: 8 }} active>
          <div className="seach-game-warp">
            {filterGameList.map((item, index) => (
              <GameItemNew
                isSearchResult={true}
                key={item.gameId + item.platformCode + index}
                gameData={item}
              ></GameItemNew>
            ))}
            {filterGameList.length === 0 && (props.searchGameName.length > 0 || props.gameType) && (
              <img src="/static/images/game/nodata.png" className="nodata" />
            )}
          </div>
        </Skeleton>
        {!(isLoding && useSearchType.current.pageNo === 1) && (
          <Pagination
            showQuickJumper={false}
            showSizeChanger={false}
            hideOnSinglePage={true}
            showLessItems
            total={totalCount}
            onChange={(num) => {
              getGameData(props.gameType, num)
            }}
            pageSize={isBigScreen ? (props.pageType === 'lobbyPage' ? 18 : 12) : 9}
          />
        )}
      </section>
    </div>
  )
}

export default memo(GameSeachList)
