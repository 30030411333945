'use client'
// import { styled } from 'styled-components'
import { memo } from 'react'
import { useTranslation } from '@/lib/locales/client'
import { useAppStore } from '@/stores/app'
import { useParams, useRouter } from 'next/navigation'
import { px2Scalepx } from '@/hooks/useRem'
import { Flex } from 'antd'
import { cn } from '@/lib/utils'
import styles from './limitLoginDialog.module.scss'
import VerifyBaseDialog from '@/components/common/VerifyBaseDialog'
import { useShallow } from 'zustand/react/shallow'

const LimitLoginDialog = () => {
  const { t } = useTranslation()
  const router = useRouter()
  const { lng } = useParams()
  const isBigScreen = useAppStore((state) => state.isBigScreen)
  const { showLimitLogin, language, updateLimitLoginDialog } = useAppStore(
    useShallow((state) => ({
      showLimitLogin: state.showLimitLogin,
      language: state.language,
      updateLimitLoginDialog: state.updateLimitLoginDialog
    }))
  )

  const goPrivacy = () => {
    close()
    router.push(`/${lng}/static/privacy`)
  }

  const onOpenChat = () => {
    close()
    window.Intercom('show')
  }

  const close = () => {
    updateLimitLoginDialog(false)
  }

  return (
    <>
      {showLimitLogin && (
        <VerifyBaseDialog
          visible
          zIndex={9999}
          width={px2Scalepx(343)}
          closeFn={close}
          cusClass={cn(styles['limit-login-dialog'])}
          type="alert"
        >
          <h3>{t('common.limitLogin.title')}</h3>
          {language !== 'ja-JP' ? (
            <p>
              As per gaming license norms, we are unable to accept players from your region! Contact{' '}
              <a href="mailto:support@queen-casino.com">support@queen-casino.com</a> for further assistance!
            </p>
          ) : (
            <p>
              ゲームライセンスの規定により、お客様の滞在地域からのプレイヤーを受け入れることはできません。ご不明な点などございましたら、{' '}
              <a href="mailto:support@queen-casino.com">support@queen-casino.com</a>{' '}
              までお問い合わせください。
            </p>
          )}

          <Flex justify="center" className="mx-0 my-[16px]">
            {/* <Flex className="img-warp"> */}
            <a onClick={onOpenChat} className="mr-[34px] cursor-pointer">
              <img className="h-[24px] w-[24px]" src="/static/images/common/limit_cs.png" />
            </a>
            <a href="mailto:support@queen-casino.com">
              <img className="h-[24px] w-[24px]" src="/static/images/common/limit_email.png" />
            </a>
          </Flex>
          <a onClick={goPrivacy}>{t('common.limitLogin.bottom')}</a>
        </VerifyBaseDialog>
      )}
    </>
  )
}

export default memo(LimitLoginDialog)

