'use client'
import { useState, useMemo, memo, FC, useEffect } from 'react'
import { useTranslation } from '@/lib/locales/client'
import { useAppStore } from '@/stores/app'
import VerifyBaseDialog from '@/components/common/VerifyBaseDialog'
import { useParams, useRouter } from 'next/navigation'
import { useShallow } from 'zustand/react/shallow'
import styles from './_common_css/needKycDialog.module.scss'
import { px2Scalepx } from '@/hooks/useRem'

type IProps = {
  level?: string
  close?: () => void
}

const needKycDialog: FC<IProps> = (props) => {
  const { level = 'L2' } = props
  const { t } = useTranslation()
  const router = useRouter()
  const { lng } = useParams()

  const { isBigScreen, changeFastDialog } = useAppStore(
    useShallow((state) => ({
      isBigScreen: state.isBigScreen,
      changeFastDialog: state.changeFastDialog
    }))
  )
  const [closeTime, setCloseTime] = useState(5)

  let timer
  useEffect(() => {
    timer = setInterval(() => {
      setCloseTime((prevCount) => {
        const newCount = prevCount - 1
        return newCount
      })
    }, 1000)
  }, [])

  useEffect(() => {
    if (closeTime === 1) {
      goVerificton()
    }
  }, [closeTime])

  const goVerificton = () => {
    closeFn()
    changeFastDialog(false)
    router.push(`/${lng}/account/verification`)
  }

  const closeFn = () => {
    props.close()
    clearInterval(timer)
  }

  return (
    <VerifyBaseDialog
      visible={true}
      zIndex={6100}
      width={px2Scalepx(343)}
      closeFn={closeFn}
      cusClass={styles['need-kyc-dialog']}
    >
      <h3>{level === 'L1' ? t('deposit.needKyc1') : t('deposit.needKyc')}</h3>
      <p dangerouslySetInnerHTML={{ __html: t('deposit.needKyc5s', { num: closeTime }) }}></p>
      <a onClick={goVerificton}>{t('deposit.needKycBtn')}</a>
    </VerifyBaseDialog>
  )
}

export default memo(needKycDialog)
