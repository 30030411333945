'use client'
import { memo, useEffect, useMemo, useRef, useState } from 'react'
import { useUserStore } from '@/stores/user'
import { useShallow } from 'zustand/react/shallow'
import React from 'react'
import { DefaultList } from '../defaultPromo'
import RewardSwiper from '../rewardSwiper'
import RewardCard from '../rewardCard'

const ReardWelcome = () => {
  const { depositActivityInfo, kycAuditStatus, userInfo } = useUserStore(
    useShallow((state) => ({
      bonusAndTask: state.bonusAndTask,
      doneBonusAndTask: state.doneBonusAndTask,
      depositActivityInfo: state.depositActivityInfo,
      kycAuditStatus: state.kycAuditStatus,
      userInfo: state.userInfo
    }))
  )
  const [spinning, setSpinning] = useState(false)

  //2024 1030改版 只显示充值活动
  const taskList = useMemo(() => {
    let ids = []
    let depositTime = Number(depositActivityInfo?.extraResult?.depositTime || 0)
    for (let i = 1; i <= depositTime; i++) {
      ids.push('depositTime' + i)
    }
    const list = DefaultList.filter(
      (item) => ['deposit1', 'deposit2', 'deposit3'].indexOf(item.rewardType) > -1 && ids.indexOf(item.configCode) === -1 
    )
    return list
  }, [depositActivityInfo])

  useEffect(() => {
    if (depositActivityInfo[0] && kycAuditStatus[1] !== -1) {
      setSpinning(false)
    }
  }, [depositActivityInfo, kycAuditStatus])
  return (
    <div className="reward-welcome">
      <RewardSwiper
        swipData={taskList}
        spinning={spinning}
        cardType="welcome"
        render={(item: any, key: string) => <RewardCard cardInfo={item} cardType="welcome" key={key} />}
      ></RewardSwiper>
    </div>
  )
}

export default memo(ReardWelcome)
