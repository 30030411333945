'use client'
import { useAppStore } from '@/stores/app'
import { useUserStore } from '@/stores/user'
import { cn, getUrlParameter } from '@/lib/utils'
import React, { memo, useEffect, useMemo, useState } from 'react'
import { taskCheckDisplay } from '@/api/activity'
import RewardSwiper from '../rewardSwiper'
import RewardCard from '../rewardCard'
import BonusReceive from '../bonusReceive'
import { useShallow } from 'zustand/react/shallow'
const RewardMission = (props: any) => {
  const { rewardTagCode } = props
  const { language } = useAppStore()

  const { getMissionTagsList, getBonusTaskList } = useUserStore(
    useShallow((state) => ({
      getMissionTagsList: state.getMissionTagsList,
      getBonusTaskList: state.getBonusTaskList
    }))
  )
  const [spinning, setSpinning] = useState(false)
  const [rewardList, setRewardList] = useState([])
  const [receiveDialog, setReceiveDialog] = useState(false)
  const [dialogData, setDialogData] = useState({})

  const getTaskList = (flag) => {
    setSpinning(true)
    taskCheckDisplay({ paidType: 'PRE_PAID', taskTag: rewardTagCode })
      .then(({ data }: any) => {
        setRewardList([])
        if (data && data.length > 0) {
          data.forEach((el: any) => {
            if (el.rewardType !== 'FREE_SPINS') {
              el.awardMoney = el.bonus
                ? el.bonus
                : el.rewardCalSource === 'FIX'
                  ? el.rewardAmount
                  : 'x ' + el.rewardAmount + '%'
            }
            el.awardSpin = el.freeSpins
            el.awardCurrency = 'USD'
            el.rewardName = getTaskText(el).taskName
            el.fixedDetails = getTaskText(el).taskDescription
            el.expireTime = el.taskDateEnd
            el.endTime = el.taskDateBegin
            el.defaultUrl = getUrl(el.taskType)
          })
          setRewardList(data.sort((a: any, b: any) => b.available - a.available))
        }
        if (flag) {
          getMissionTagsList()
        }
      })
      .finally(() => {
        setSpinning(false)
      })
  }

  const getUrl = (taskType: string) => {
    if (taskType === 'DEPOSIT') {
      return '/account/deposit'
    } else if (taskType === 'REGISTER') {
      return '/account/verification'
    } else if (taskType === 'BET') {
      return '/pachinko'
    } else if (taskType === 'FIRST_DEPOSIT') {
      return '/account/deposit'
    }
  }

  const clickReceive = (item: any) => {
    setDialogData({
      fetchAmount: item.awardMoney,
      ...item
    })
    setReceiveDialog(true)
  }

  const handleReceive = () => {
    getTaskList(true)
    getBonusTaskList()
    setReceiveDialog(false)
  }

  const getTaskText = (item: any) => {
    return item.languageRespList.find((el: any) => el.language === language) || {}
  }

  useEffect(() => {
    rewardTagCode && getTaskList(true)
  }, [rewardTagCode])

  return (
    <div className="reward-tournaments">
      <RewardSwiper
        swipData={rewardList}
        spinning={spinning}
        cardType="mission"
        render={(item: any, key: string) => (
          <RewardCard cardInfo={item} cardType="mission" key={key} claim={() => clickReceive(item)} />
        )}
      ></RewardSwiper>

      {receiveDialog && (
        <BonusReceive
          dialogData={dialogData}
          close={() => setReceiveDialog(false)}
          receiveBonus={handleReceive}
        />
      )}
    </div>
  )
}

export default memo(RewardMission)
