
export const getRewardType = (item) => {
  const code = item.configCode
  if(!code) return
  if (code === "b98a6b84f2") {
    return "kyc1"
  } else if (code === "ae69eb687e") {
    return "kyc2"
  } else if (code === "4183d52d76") {
    return "kyc3"
  } else if (code === "dc7ad9266b") {
    if (item.remark.indexOf("pachinko") > -1 || item.remark.indexOf("pachislot") > -1) {
      return "betpachinko"
    } else if (item.remark.indexOf("slot") > -1) {
      return "betslots"
    } else if (item.remark.indexOf("live") > -1) {
      return "betlive"
    } else if (item.remark.indexOf("sport") > -1) {
      return "betsports"
    }
  } else if ("977965d986;953c1ccb1e;a937d4f82d;0819ad6355".indexOf(code) > -1) {
    return "deposit1"
  } else if ("3544adb00b;205c1e0345".indexOf(code) > -1) {
    return "deposit2"
  } else if ("a5e92f03a7;2d482a5e84".indexOf(code) > -1) {
    return "deposit3"
  } else if (code === "7cb9c15616") {
    return "tour_pachinko"
  } else if (code === "fdb3472851 ") {
    return "lucky_draw"
  } else if (code === "3d038a238f") {
    return "level_up"
  } else if ("8e02040d6f;d670ec41cd;4f773cd629;a9ec11b59a;e193b7c366;0700edf718".indexOf(code) > -1) {
    return "brithday"
  } else if (code === "5818050749" || code === "3afa837966") {
    return "cashback"
  }
  return "common"
}
