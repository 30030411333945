export const benefit = {
  title:
    'キャッシュでのベット額に応じてリアルタイムにVIPに昇格！ お客様のためにカスタマイズされたサービスをぜひご体験ください！',
  title2: 'ロイヤルティクラブではお客様にさまざまな特典をご用意しております！',
  btnLeft: 'ゲームをプレイ',
  title3: 'ロイヤルティクラブは完全招待制です。 担当部署の厳正な審査を通過されたお客様のみが招待されます。',
  title4: 'オンラインカジノ最高クラスの\nVIPメンバーシップ',
  btnRight1: '詳細をみる',
  btnRight2: 'ゲームをプレイ',
  conTitle: '特典詳細',
  conTitle2:
    'プレイヤーとして最高ランクに到達すると、15,000ドルから 500,000ドルのボーナスを手にすることができます。',
  conTitle3:
    'またその他にもワンランク上の体験をお約束するサービスをお楽しみいただくことができます。ぜひ、エキサイティング、かつ最高の体験をしてみませんか？',
  fit1: 'キャッシュバック',
  benText2: '特別イベントご招待',
  benText3: 'キャッシュバック',
  benText4: 'ロイヤルティクラブ限定ボーナス',
  benText5: '専用コンシェルジュサービス',
  benText6: 'カスタマイズされた世界旅行',
  benText7: '世界中で行われる特別なイベントを体験',
  benText8: 'ベット不要ボーナス',
  benText9: '出金処理の最優先対応',
  benText10: 'デイリーボーナス',
  benText11: 'レベルアップクエスト',
  benText12: '誕生日ボーナス',
  benText13: '専用コンシェルジュサービス',
  benText14: 'レジェンドクラブカスタマイズツアー',
  benText15: 'ラグジュアリーホテル滞在',
  benText16: 'ボーナス',
  benContent:
    '0.05%～0.3%のレーキバックをお受け取りください<br/>LV21～LV100：週に1度<br/>LV101以上：お好きなタイミング',
  benContent1:
    'ブロンズレベル以上のみなさまに、レベルアップがさらにしやすくなるレベルアップクエストをご用意しております。',
  benContent2: 'みなさまに、レベルに応じた誕生日ボーナスをご用意しております。',
  benContent3:
    'プラチナレベル以上のみなさまは、お客様のニーズに細かくお応えし、丁寧なサポートを提供する専用コンシェルジュサービスをご提供いたしております。',
  benContent4: 'レジェンドクラブのみなさまには、カスタマイズされたラグジュアリーな旅行もお楽しみいただいております。こちらは完全招待制となっております。',
  benContent5: 'ロイヤルティクラブプログラムに参加し、最高級ホテル滞在で極上のサービスをご体験ください。',
  benContent6: 'ロイヤルティクラブでは、キャッシュバック、誕生日特典、レベルアップクエストなどさまざまなボーナスをご用意しております。',
  benContent7: 'レジェンドレベルのみなさまには出金処理を最優先で対応させていただきます。',
  Iron: 'アイアン',
  Bronze: 'ブロンズ',
  Silver: 'シルバー',
  Gold: 'ゴールド',
  Platinum: 'プラチナ',
  Emerald: 'エメラルド',
  Diamond: 'ダイヤモンド',
  Legend: 'レジェンド',
  queenCasino: 'クイーンカジノ',
  NextLevel: '次のレベル',
  loyaltyClub: 'ロイヤルティクラブ',
  invitationOnly: '完全招待制',
  benefitsInfo: {
    levelUpFreeSpins: 'レベルアップ無料スピン'
  }
} as const
