'use client'
import React, { useState, useEffect, memo, useRef } from 'react'
import styles from './index.module.scss'
import { useTranslation } from '@/lib/locales/client'
import { useShallow } from 'zustand/react/shallow'
import { useAppStore } from '@/stores/app'
import { useUserStore } from '@/stores/user'
import { BonusCurrencyAmout } from '@/api/account'
import { decimalFormat, getCurrencySymbol } from '@/lib/utils'

type IProps = {
  isShowPC?: boolean
  onPCPop?: (flag: boolean) => void
}

export default function Credit(props: IProps) {
  const [isShowOptions, setIsShowOptions] = useState(false)
  //结构完成了，功能交互未完成 币种接口未调先弄别的
  const headerInputRef = useRef(null)
  const { changeFastDialog } = useAppStore(
    useShallow((state) => ({
      changeFastDialog: state.changeFastDialog
    }))
  )
  const { currency, getRakebackInfo, wallets } = useUserStore(
    useShallow((state) => ({
      currency: state.currency,
      getRakebackInfo: state.getRakebackInfo,
      wallets: state.wallets
    }))
  )

  const currencyBoxRef = useRef(null)

  const { t } = useTranslation()
  const getImg = (currency) => {
    try {
      return `/static/images/header/wallets/currencies/${currency}.png`
    } catch (error) {
      return ''
    }
  }
  const [currentCurrency, setCurrentCurrency] = useState<any>({}) // 当前币种
  const [currentBounsAmount, setCurrentBounsAmount] = useState([])
  useEffect(() => {
    setCurrentCurrency(currency)
  }, [currency])

  const changeCurrency = (t, i) => {
    currentBounsAmount.forEach((el) => {
      if (el.currency === t.currency) {
        t.currentBounsAmount = el.currentAmount
      }
    })
    setCurrentCurrency(t)
    setIsShowOptions(false)
  }

  function GetBonusCurrencyAmout() {
    const params = {
      status: 3,
      bonusType: '4;5;6'
    }
    BonusCurrencyAmout(params).then((res) => {
      if (res.success) {
        res.data.forEach((el) => {
          if (el.currency === currency.currency) {
            setCurrentCurrency((prevState) => ({ ...prevState, currentBounsAmount: el.currentAmount }))
          }
        })
        setCurrentBounsAmount(res.data)
      }
    })
  }

  useEffect(() => {
    GetBonusCurrencyAmout()
    getRakebackInfo()
  }, [])

  return (
    <div className={styles['credit-bbox']}>
      <div className="credit-box">
        <div className="top-box">
          <span className="label">{t('user.headUser.credit')}</span>
          <span className="unit">{getCurrencySymbol(currentCurrency.currency)}</span>
          <span className="num">{decimalFormat(currentCurrency.amount, currentCurrency.currency)}</span>
        </div>
        <div className="bottom-box">
          <div
            className="change-btn pc:cursor-pointer"
            ref={headerInputRef}
            onClick={() => setIsShowOptions(!isShowOptions)}
          >
            <img className="icon" src={getImg(currentCurrency.currency)} />
            <span className="currency">
              {currentCurrency.currency == 'JPY' ? 'JPY￥' : currentCurrency.currency}
            </span>
            <img
              className={`${!isShowOptions ? 'arrowTransformReturn' : 'arrowTransform'}`}
              src={'/static/images/myAccount/h5/down-2.png'}
            />
          </div>
          <div
            className="desposit pc:cursor-pointer"
            onClick={() => {
              props.isShowPC && props.onPCPop(false)
              changeFastDialog(true)
            }}
          >
            <img src={'/static/images/myAccount/h5/layer.svg'} alt="" />
            <span>{t('user.fastDeposit')}</span>
          </div>
        </div>

        {isShowOptions && (
          <div className="currency-box" ref={currencyBoxRef}>
            <ul>
              {wallets.map((item, idx) => (
                <li key={idx} onClick={() => changeCurrency(item, idx)}>
                  {item.currency === currentCurrency.currency ? (
                    <img src="/static/images/header/wallets/yes.png" alt="" />
                  ) : (
                    <img src="/static/images/header/wallets/no.png" alt="" />
                  )}
                  <div className="check-btn"></div>
                  <span>{item.currency}</span>
                  <img src={`/static/images/header/wallets/currencies/${item.currency}.png`} alt="" />
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  )
}
