'use client'
import { useAppStore } from '@/stores/app'
import { useSearchParams } from 'next/navigation'
import styles from './index.module.scss'
import { useUserStore } from '@/stores/user'
import { cn, getUrlParameter } from '@/lib/utils'
import React, { useEffect, useMemo, useState, useRef, memo } from 'react'
import RewardWelcome from '../rewardWelcome'
import RewardQuestBonus from '../rewardQuestBonus'
import RewardVip from '../rewardVip'
import RewardCoupons from '../rewardCoupons'
import RewardTournaments from '../rewardTournaments'
import RewardSpecialBonus from '../rewardSpecialBonus'
import RewardMission from '../rewardMission'
import { useTranslation } from '@/lib/locales/client'
import { useShallow } from 'zustand/react/shallow'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useDeepCompareEffect, usePrevious } from 'ahooks'
const RewardCenter = () => {
  const searchParams = useSearchParams()
  const { isBigScreen, SET_REWARD_SHOW_SWIPER, rewardshowSwiper } = useAppStore(
    useShallow((state) => ({
      isBigScreen: state.isBigScreen,
      SET_REWARD_SHOW_SWIPER: state.SET_REWARD_SHOW_SWIPER,
      rewardshowSwiper: state.rewardshowSwiper
    }))
  )
  const {
    bonusAndTask,
    freeSpinList,
    availableBonusTask,
    availableMission,
    missionTagsList,
    kycAuditStatus,
    signDetail,
    depositActivityInfo
  } = useUserStore(
    useShallow((state) => ({
      bonusAndTask: state.bonusAndTask,
      freeSpinList: state.freeSpinList,
      availableBonusTask: state.availableBonusTask,
      availableMission: state.availableMission,
      missionTagsList: state.missionTagsList,
      kycAuditStatus: state.kycAuditStatus,
      signDetail: state.signDetail,
      depositActivityInfo: state.depositActivityInfo
    }))
  )
  const { t } = useTranslation()
  const swiperRef = useRef(null)
  const [rewardStateList, setRewardStateList] = useState(
    t('promo.stateTab', { returnObjects: true }) as string[]
  )
  const [rewardStateIndex, setRewardStateIndex] = useState(0)
  const [rewardTagCode, setRewardTagCode] = useState('')
  const [progressIndex, setProgressIndex] = useState(0)
  const onSwiper = (swiper: any) => {
    swiperRef.current = swiper
  }

  const kycAuditStatusCalc = useMemo(() => {
    return kycAuditStatus > -1
  }, [kycAuditStatus])

  const tagClick = (item: any, index: number) => {
    setRewardTagCode(item.tagCode)
    swiperRef.current && swiperRef.current.slideTo(index, 1000, false)
  }

  const changeState = (index: number) => {
    setRewardStateIndex(index)
  }

  const couponsList = useMemo(() => {
    let arr = [0, 0, 0, 0]
    bonusAndTask.forEach((item) => {
      if ([3, 5, 10].includes(item.bonusStatus)) {
        if (item.bonusType === 4) {
          arr[0]++
        } else if (item.bonusType === 5) {
          arr[1]++
        } else if (item.bonusType === 0) {
          arr[2]++
        }
      }
    })
    arr[3] = freeSpinList.length
    return arr
  }, [freeSpinList, bonusAndTask])

  const availableTask = useMemo(() => {
    const list = [...bonusAndTask.filter((item) => item.status === 2), ...freeSpinList]
    return list.length
  }, [bonusAndTask, freeSpinList])

  const rewardTagsList = useMemo(() => {
    const list = [...missionTagsList, ...(t('promo.promotionTab', { returnObjects: true }) as object[])]
    if (availableTask > 0) {
      list.unshift(t('promo.claimRewards', { returnObjects: true }))
    }
    const newList = list.filter(
      (el) =>
        !(!signDetail && el.tagCode === 'questBonus') &&
        !((depositActivityInfo?.extraResult?.depositTime as number) > 2 && el.tagCode === 'welcome')
    )
    return newList
  }, [availableTask, depositActivityInfo, signDetail])

  const lastMissionTagsList = usePrevious(missionTagsList)

  useDeepCompareEffect(() => {
    if (availableBonusTask >= 0 && availableMission >= 0 && !rewardTagCode) {
      //首次加载
      let tabIndex = 0
      if (searchParams.get('tagCode')) {
        tabIndex = rewardTagsList.findIndex((el) => el.tagCode === searchParams.get('tagCode'))
      } 
      tagClick(rewardTagsList[tabIndex], 0)
    } else if (rewardTagCode !== '' && missionTagsList.length < lastMissionTagsList.length) {
      //如果领取任务后，标签减少，重新选择标签
      tagClick(rewardTagsList[0], 0)
    }
    // const tagCodes = rewardTagsList.map((item) => item.tagCode)
    // if (!rewardTagCode || !tagCodes.includes(rewardTagCode) || tagCodes[0] != rewardTagCode) {
    //   tagClick(rewardTagsList[0], 0)
    // }
  }, [availableBonusTask, availableMission, missionTagsList])
  

  const progressTab = t('promo.progressTab', { returnObjects: true }) as string[]
  const getImgUrl = () => {
    return `/static/images/promo/list${rewardshowSwiper ? '_a' : ''}.svg`
  }

  const cardType = useMemo(() => {
    return ['bonus', 'quest', 'extra', 'freeSpin'][progressIndex]
  }, [progressIndex])

  return (
    <section className={styles['reward-center']}>
      <div className="reward-center-heard">
        <div>
          {rewardStateList.map((item: any, index: number) => (
            <div
              key={index}
              className={cn('tag', index === rewardStateIndex ? 'active' : '')}
              onClick={() => changeState(index)}
            >
              {item}
            </div>
          ))}
        </div>
        {!isBigScreen && (
          <a onClick={() => SET_REWARD_SHOW_SWIPER(!rewardshowSwiper)}>
            <img src={getImgUrl()} />
          </a>
        )}
      </div>
      {rewardStateIndex === 0 && (
        <>
          {kycAuditStatusCalc && (
            <Swiper
              className="reward-tags reward-tags1"
              slidesPerView="auto"
              centeredSlidesBounds={true}
              centeredSlides={true}
              onSwiper={onSwiper}
              key={rewardTagsList.length}
            >
              {rewardTagsList.map((item: any, index: number) => (
                <SwiperSlide
                  className={cn('tag', item.tagCode === rewardTagCode ? 'active' : '')}
                  key={item.tagCode}
                  onClick={() => tagClick(item, index)}
                >
                  <p>{item.name}</p>
                  {index === 0 && availableTask > 0 && rewardStateIndex === 0 && <span>{availableTask}</span>}
                  {index !== 0 && item.availablePrizeCount > 0 && rewardStateIndex === 0 && (
                    <span>{item.availablePrizeCount}</span>
                  )}
                </SwiperSlide>
              ))}
            </Swiper>
          )}

          {rewardTagCode === 'available' ? (
            <RewardCoupons cardType={'available'} />
          ) : rewardTagCode === 'welcome' ? (
            <RewardWelcome />
          ) : rewardTagCode === 'questBonus' ? (
            <RewardQuestBonus />
          ) : rewardTagCode === 'tournament' ? (
            <RewardTournaments />
          ) : rewardTagCode === 'vip' ? (
            <RewardVip />
          ) : rewardTagCode === 'special' ? (
            <RewardSpecialBonus />
          ) : (
            <RewardMission rewardTagCode={rewardTagCode} />
          )}
        </>
      )}

      {rewardStateIndex === 1 && (
        <>
          <Swiper
            className="reward-tags"
            slidesPerView="auto"
            centeredSlidesBounds={true}
            onSwiper={onSwiper}
          >
            {progressTab.map((item: any, index: number) => (
              <SwiperSlide
                className={cn('tag', index === progressIndex ? 'active' : '')}
                key={item}
                onClick={() => setProgressIndex(index)}
              >
                <p>{item}</p>
                {couponsList[index] > 0 && <span>{couponsList[index]}</span>}
              </SwiperSlide>
            ))}
          </Swiper>
          <RewardCoupons cardType={cardType} />
        </>
      )}
    </section>
  )
}
export default memo(RewardCenter)
