import { memo } from 'react'
import { cn } from '@/lib/utils'
import { Flex } from 'antd'
import styles from './index.module.scss'
// com*
import SupportThird from '../../_components/supportThird'

const LeftModel = () => {
  return (
    <>
      <Flex
        vertical
        justify="space-between"
        align="center"
        className={cn(
          'relative w-[368px] px-[16px] py-[32px] text-lg font-medium text-white',
          styles['left-auth']
        )}
        style={{
          background: `url('/static/images/user/sportbg_pc.jpg') no-repeat center`,
          backgroundSize: '100% 100%'
        }}
      >
        <div className="swiper-box"></div>
        <SupportThird />
      </Flex>
    </>
  )
}

export default memo(LeftModel)
