export const misc = {
  accountDisabled: {
    title: 'アカウント停止中',
    text001: 'You have excluded yourself.',
    text002: 'ありがとうございました。'
  },
  faq: {
    title: 'よくある質問',
    quickSearch: 'クイック検索',
    selectPlaceholder: '選択してください',
    rules: 'ボーナスとクエストルール'
  },
  landpage: {
    title: '魅力的な仮想通貨での入金にチャレンジ！',
    descrtion:
      'こちらではご本人様確認の必要がない便利な仮想通貨でのご入金方法をご案内しています。仮想通貨のメリット、取引所口座開設方法などを確認して仮想通貨での入金にチャレンジしてみましょう！',
    moduleTitle1: '即時入金',
    moduleTitle2: '高度なセキュリティ',
    moduleTitle3: '仮想通貨ならではの特別限定オファー',
    moduleTitle4: '今すぐ仮想通貨で入金しよう！',
    moduleText1:
      '仮想通貨での入金はわずか数秒でお客様のアカウントへ反映されます。入金確認までのストレスなく遊びたい時にすぐ遊べる環境をご提供しております。',
    moduleText2:
      '仮想通貨での取引はクレジットカードや口座情報などの個人情報を記入する必要がないため匿名性が高く、またセキュリティ面でも安心です。',
    moduleText3:
      'クイーンカジノでは通常の入金プロモーションのほかに、仮想通貨でのご入金だけに適用される特別な限定プロモーションも随時行っております。さらにお得になる仮想通貨でのご入金をお試しください。',
    moduleText4:
      'KYC不要（ご本人様確認）、即時入金、高度なセキュリティ、豊富なプロモーションなど仮想通貨での入金には魅力がいっぱい！ぜひ、仮想通貨での入金にチャレンジしてみましょう！',
    text1: '日本国内の代表的な仮想通貨取引所はこちらです！',
    text2:
      '（仮想通貨をご利用の場合、匿名性を保つためこれら日本の仮想通貨取引所から一度海外の仮想通貨取引所を介することをおすすめします。）',
    btn: '仮想通貨で入金する'
  },
  namePromptDialog: {
    title: '銀行キャッシュカード所有者の名前をご入力ください。',
    countdownText: '今一度ご確認ください',
    note01: '入力した名前が銀行口座名義人と一致しない場合、ご入金することができません。',
    note02: '銀行キャッシュカードに印刷されている名義人名をご確認の上ご入力ください。'
  },
  notice: {
    tab1: 'マーケティング情報',
    tab2: '通知',
    detailBtn: '詳細を見る',
    noShowToday: '今日はもう表示しない',
    empty: '情報なし',
    oneClickRead: 'ワンクリックで読む',
    oneClickDelete: 'ワンクリックで削除',
    all: '全部',
    message: 'サイト内メッセージ',
    notification: 'システムからのお知らせ',
    announcement: '重要なお知らせ',
    updataNotification: 'アップデート通知',
    messageType: 'メッセージのタイプ',
    reset: 'リセット',
    confirm: '確認',
    isEnd: 'データがいっぱい',
    deleteOne: 'このメッセージを本当に削除しますか？',
    deleteAll: '全てのメッセージを本当に削除しますか？',
    notify: 'つうち',
    deletedSuccessfully: '削除成功',
    showAll: 'すべて表示'
  },
  password: {
    text001: 'パスワードは6文字以上で、大文字/小文字/数字/記号の全てを含む必要があります。',
    text002:
      'パスワード設定する際に、名前、メールアドレス、生年月日など推測されやすい文字列を使用しないでください。 パスワードは大文字と小文字は異なるものとして認識されるので、大文字、小文字、数字、記号を混在させた文字列でパスワードを設定してください。',
    oldPwd: '現在のパスワード',
    oldPwdPla: '現在のパスワード',
    newPwd: '新しいパスワード',
    newPwdPla: '新しいパスワード',
    newPwdConfirm: '新しいパスワードを再入力',
    newPwdConfirmPla: '新しいパスワードを再入力',
    btn001: '編集',
    btn002: '更新',
    btn003: 'キャンセル',
    errText001: '必須項目',
    errText002: 'パスワードが一致していませ',
    modifySuccess: 'パスワードが変更されました',
    inputPwd: 'パスワードを入力してください',
    inputComPwd: 'パスワードをもう一度入力してください'
  },
  preferences: {
    title: 'アカウントの設定',
    text001: 'サイトの表示に関する設定を行えます。',
    text002: 'タイムゾーン',
    text003: '言語',
    text004: 'スポーツベット ビュー形式',
    text005: 'オッズ形式',
    btn001: '編集',
    btn002: '更新',
    btn003: 'キャンセル',
    language: {
      en: 'English',
      js: '日本語'
    },
    sportsViewsFormat: {
      text001: 'アジアビュー',
      text002: 'アメリカビュー',
      text003: 'ヨーロッパビュー'
    },
    oddsFormat: {
      text001: 'デシマルオッズ',
      text002: 'ホンコンオッズ',
      text003: 'マレーオッズ',
      text004: 'インドネシアオッズ',
      text005: 'アメリカンオッズ'
    },
    modifySuccess: 'データが正常に保存されました'
  },
  router: {
    home: 'HOME'
  },
  test: {
    promo: 'Promo',
    slot: 'Slot',
    ori: 'Ori'
  }
} as const
