export const benefit = {
  // export const BENEFIT = {
  title: 'Be promoted to VIP in real time by betting with cash! Experience our customized service for you!',
  title2: 'QueenCasino Loyalty Club offers players with exclusive benefits!!',
  btnLeft: 'Play Game',
  title3:
    'The Loyalty Club is by invitation only.  Only customers who have passed a rigorous screening process by the relevant department will be invited.',
  title4: 'Queens Casino The Loyalty Club  By Invitation Only',
  btnRight1: 'Learn More',
  btnRight2: 'Play Game',
  conTitle: 'Privilege Details',
  conTitle2:
    "As a player, if you manage to reach the pinnacle of achievement, you'll be generously rewarded with a bonus ranging from $17,500 to $500,000.",
  conTitle3:
    "Additionally, you'll gain access to an enhanced level of service and an unparalleled gaming experience that is truly one-of-a-kind. Get ready to embark on an exciting journey with us and indulge in endless enjoyment!",
  fit1: 'キャッシュバック',
  benText2: 'Exclusive event invitations',
  benText3: 'Cash Back',
  benText4: 'Exclusive Bonus',
  benText5: 'Dedicated support service',
  benText6: 'Customized Global Travel',
  benText7: 'Experience exclusive events around the world',
  benText8: 'Wager-free rewards',
  benText9: 'Top Priority Withdrawal Processing',
  benText10: 'Birthday Bonus',
  benText11: 'Level-Up Quests',
  benText12: 'Birthday Bonus',
  benText13: 'Exclusive-Customer Service',
  benText14: 'Club Customized Tours (Invite-Only)',
  benText15: 'Luxury Hotel Suites',
  benText16: 'Bonuses',
  benContent: 'Receive rakeback of 0.05% to 0.3%<br/>LV21~LV100: Once a week<br/> LV101 and above: Any time.',
  benContent1: 'For members at Bronze level and above, we offer special Level-Up Quests to make leveling up even easier.',
  benContent2: 'We provide birthday bonus tailored to your level to celebrate your special day.',
  benContent3:
    'Platinum-level members and above enjoy access to an exclusive customer service, offering personalized support to meet your specific needs with care',
  benContent4: 'Members of the Legend Club can enjoy customized luxury tours. These exclusive trips are available by invitation only.',
  benContent5:
    'Indulge in the royal treatment by joining our Legend Club program and staying at our top-rated partner hotels.',
  benContent6: 'The Loyalty Club offers various bonuses, including cashback, birthday rewards, and Level-Up Quests, exclusively for members.',
  benContent7: 'For our Legend-level members, we prioritize withdrawal processing to ensure the fastest service.',
  Iron: 'Iron',
  Bronze: 'Bronze',
  Silver: 'Silver',
  Gold: 'Gold',
  Platinum: 'Platinum',
  Emerald: 'Emerald',
  Diamond: 'Diamond',
  Legend: 'Legend',
  queenCasino: 'Queen Casino',
  NextLevel: 'Next Level',
  loyaltyClub: 'The Legend Club',
  invitationOnly: 'By Invitation Only',
  benefitsInfo: {
    levelUpFreeSpins: 'Level Up Free Spins'
  }
} as const
