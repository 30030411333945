'use client'
import { useState, memo } from 'react'
import { cn } from '@/lib/utils'
import { useTranslation } from '@/lib/locales/client'
import Mobile from './_components/mobile'
import Email from './_components/email'
import { useAppStore } from '@/stores/app'
import { useRouter } from 'next/navigation'
import styles from './_common_css/index.module.scss'
import { useShallow } from 'zustand/react/shallow'

const Forget = () => {
  const { t } = useTranslation()
  const { isBigScreen, setForgetDialog } = useAppStore(
    useShallow((state) => ({
      isBigScreen: state.isBigScreen,
      setForgetDialog: state.setForgetDialog
    }))
  )
  const router = useRouter()
  const [currentComponent, setCurrentComponent] = useState('mobile')

  const comToggle = () => {
    switch (currentComponent) {
      case 'mobile':
        return <Mobile />
      case 'email':
        return <Email />
      default:
        return null
    }
  }

  const list = [
    {
      key: t('user.authDialog.regPhone'),
      component: 'mobile'
    },
    {
      key: t('user.authDialog.regEmail'),
      component: 'email'
    }
  ]

  return (
    <>
      <section className={cn(styles['forget-pwd-page'], styles[!isBigScreen && 'mobile'])}>
        <div className={'header'}>
          <img src="/static/images/common/logo.png" alt="" />
          <a
            onClick={(evt) => {
              evt.preventDefault()
              isBigScreen ? setForgetDialog(false) : router.push('/')
            }}
          >
            <img src="/static/images/common/close.png" alt="clone" className={'close-icon'} />
          </a>
          {/* components toggle */}
          <div>
            {list.map((item, index) => (
              <span
                key={index}
                className={cn(item.component === currentComponent && 'active')}
                onClick={() => setCurrentComponent(item.component)}
              >
                {item.key}
              </span>
            ))}
          </div>
        </div>
        {/* mobile and email toggle!  */}
        {comToggle()}
      </section>
    </>
  )
}

export default memo(Forget)
