import { useState, useEffect, memo } from 'react'
import { onHandleAlt } from '@/lib/utils'
import { Carousel } from 'antd'
import { cmsTemplateApi } from '@/api/base'
import styles from './index.module.scss'
// com *
// import { usePromotionData } from '@/hooks/usePromotionData'

// usePromotionData 有循环计数， 要注意
const ProfileSwiper = () => {
  // const { handleURL, swiperList } = usePromotionData()
  const [swiperList, setSwiperList] = useState([])

  const goTemplateAction = (item) => {
    if (!item.templateAction) return
    window.open(item.templateAction)
  }

  const handleURL = (item: any) => {
    const mediaLibrary = item.mediaLibrary?.filter((item: any) => {
      return item.resourceType === 2
    })
    const img = mediaLibrary[0]?.fullFilePath
    return img
  }

  const getData = async () => {
    const params = {
      moduleCodeList: ['promotions'],
      promotionCard: true // 没找到相关 暂时保留
    }
    const cmsData: Resp = await cmsTemplateApi(params)
    if (cmsData?.success) {
      const filterList = cmsData.data.filter(
        (item) =>
          ['211560617462222849', '182530890478530562', '181059130465853442', '220181567304904706'].indexOf(
            item.templateId
          ) > -1
      )
      setSwiperList(filterList)
    }
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <>
      {Boolean(swiperList.length) && (
        <div className={styles['banner-box']}>
          <Carousel autoplay>
            {swiperList.map((item, index) => (
              <div className="banner-info" key={index} onClick={() => goTemplateAction(item)}>
                <img src={handleURL(item)} alt={onHandleAlt(item)} />
              </div>
            ))}
          </Carousel>
        </div>
      )}
    </>
  )
}

export default memo(ProfileSwiper)
