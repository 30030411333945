import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'
import {
  gameFavorQuery,
  queryGamesMemberDetail,
  queryWebGameProvider,
  getGameList,
  getKamikazeInfo,
  queryGameBetLimit
} from '@/api/game'
import { useAppStore } from '@/stores/app'
import { useUserStore } from '@/stores/user'
import { queryJackpotOrder, cmsGameLabels } from '@/api/home'
import { IGameStroe } from '@/types/IStore'
// import { useShallow } from 'zustand/react/shallow'

export const useGameStore = create<IGameStroe>()(
  persist(
    immer((set, get) => ({
      allGameLen: 0,
      isOpenGame: false,
      gameData: [],
      gameSearchDialogShow: false,
      favoriteGameList: [], // 收藏游戏
      platformCurrencys: [],
      homeCurrentTab: 'all', // 首页标签页当前点击项
      footerWinData: {}, // 标签页底部排行榜
      isHiddenFooterBigWin: false,
      pachinko_islot: [],
      kamikazeInfo: [],
      liveGameBetLimit: [],
      gameTipFun: null,
      gameTipShow: false,
      _hasHydrated: false,
      // actions
      getCMSLabelFuc: async (params = {}, moduleCode) => {
        const { userInfo } = useUserStore.getState()
        // const userInfo = useUserStore((state) => state.userInfo)
        if (!moduleCode && !params.moduleCode) {
          console.error('getCMSLabelFuc no params!!!')
          return
        }
        const res: any = await cmsGameLabels(
          Object.assign(
            {
              moduleCode,
              username: userInfo.loginName,
              // username: useUserStore.getState().userInfo.loginName,
              gameSource: 1 // 游戏来源: 0-配置(默认);1-按规则匹配;"
            },
            params
          )
        )
        if (res.success) {
          res.data.forEach((el) => {
            el.detailsList.forEach((i) => {
              /**
               * 因i.providerCode = i.lobbyCode中providerCode与以前进游戏有关，不可删除；
               * providerName: 搜索页数据来源/element/material/game_page，无providerCode字段；
               * providerName: 首页Casino模块中数据来源/cms/open/gameLabels/gameLabelsList，需使用providerCode字段；
               * 进游戏仅需使用gameId与providerCode，不受字段影响，故赋值在providerName中进行使用。
               */
              i.providerName = i.providerCode
              i.platformCode = i.lobbyCode
              i.providerCode = i.lobbyCode
              const gameProperty = res.data[i.gameId]
              if (gameProperty) {
                i.gameProperties = { gameProperty }
              }
            })
          })
          return Promise.resolve(res.data || [])
        } else {
          return Promise.reject(res)
        }
      },
      SET_GAME_DATA: (data) => {
        // const { updateUserInfo, getBonusTaskList } = useUserStore(
        //   useShallow((state) => ({
        //     updateUserInfo: state.updateUserInfo,
        //     getBonusTaskList: state.getBonusTaskList
        //   }))
        // )
        const { updateUserInfo, getBonusTaskList } = useUserStore.getState()
        if (get().gameData.length > 1 && data.length === 0) {
          updateUserInfo()
          getBonusTaskList()
          // useUserStore.getState().updateUserInfo()
          // useUserStore.getState().getBonusTaskList()
        }
        set({ gameData: data })
        set({ isOpenGame: data.length > 0 })
      },
      SET_GAME_LEN: (data = '') => {
        set({ allGameLen: data })
      },
      SET_HOME_CUR_TAB: (data) => {
        set({ homeCurrentTab: data })
      },
      SET_HIDDEN_BIG_WIN: (data) => {
        set({ isHiddenFooterBigWin: data })
      },
      setGameSearchDialogShow: (data) => {
        set({ gameSearchDialogShow: data })
      },
      getHighestBigWin: (type = 'all') => {
        queryJackpotOrder({
          pageNo: 1,
          pageSize: 5,
          assembledDataType: 1,
          gameType: type
        }).then((res) => {
          // pachinko/pachislot 单独处理请求2次取合集中最高
          if (type === 'pachinko' || type === 'pachislot') {
            res.data?.data.forEach((item) => {
              // push数据
              set((state) => {
                state.pachinko_islot.push(item) // 直接使用 push
                return { pachinko_islot: state.pachinko_islot } // 返回新的状态
              })
            })
            set({
              footerWinData: get().pachinko_islot.sort((a, b) => b.winLossAmount - a.winLossAmount)[0] || []
            })
          } else if (type === 'all') {
            set({
              footerWinData:
                res.data?.data.sort((a, b) => b.defaultWinLossAmount - a.defaultWinLossAmount)[0] || []
            })
          } else {
            set({ footerWinData: res.data?.data[0] })
          }
          if (get().footerWinData) {
            set({ isHiddenFooterBigWin: true })
          }
        })
      },
      bigWinFooter: async () => {
        let type = ''
        if (typeof get().homeCurrentTab === 'string') {
          type = get().homeCurrentTab
          get().getHighestBigWin(type)
        } else {
          const arr = ['pachinko', 'pachislot']
          arr.forEach((i) => {
            get().getHighestBigWin(i)
          })
        }
      },
      getFavoriteGameList: () => {
        gameFavorQuery({ pageSize: 500 }).then((res: any) => {
          if (res.success && res.data) {
            set({ favoriteGameList: res.data.data })
          }
        })
      },
      getKamikazeStore: (cb) => {
        getKamikazeInfo({
          providerCurrency: 'USD',
          providerCode: 'KAMIKAZE',
          pageSize: 999
        }).then((res: any) => {
          if (res.success && res.data) {
            set({ kamikazeInfo: res.data })
            if (typeof cb === 'function') cb()
          }
        })
      },
      queryWebGameProvider: () => {
        queryWebGameProvider({}).then((res: any) => {
          // console.log('查询币种失效', res)
          if (res.success && res.data.length > 0) {
            set({ platformCurrencys: res.data })
          }
        })
      },
      getGameListFun: async (rd, isPNG) => {
        // const { SET_MEMEBER_DETAIL, language, memberDetail } = useAppStore(
        //   useShallow((state) => ({
        //     SET_MEMEBER_DETAIL: state.SET_MEMEBER_DETAIL,
        //     language: state.language,
        //     memberDetail: state.memberDetail
        //   }))
        // )
        const { SET_MEMEBER_DETAIL, language, memberDetail } = useAppStore.getState()
        const setGameData = async (type, arr) => {
          if (arr.length > 0) {
            await getGameList({
              [type]: Array.from(new Set(arr)),
              pageSize: 99,
              language: language
            }).then((res2: any) => {
              if (res2.success && res2.data) {
                const { data } = res2.data || {}
                const gameFmt = rd.games.map((el) => {
                  const obj =
                    data.find(
                      (code) =>
                        (type === 'gameIds' &&
                          code.gameId === el.gameId &&
                          code.platformCode === el.providerCode) ||
                        (type !== 'gameIds' &&
                          code.gameName === el.gameName &&
                          code.platformCode === el.providerCode)
                    ) || {}
                  return { ...el, ...obj }
                })
                // useAppStore.getState().
                let result = { ...memberDetail.data, ...rd, games: gameFmt }
                // 爆奖游戏商为PNG时会出现多个gameId, rd.gameId为投注ID，此处需要替换为游戏ID，否则影响游戏正常进厅；
                if (type === 'gameNames' && isPNG) {
                  const item = data.filter((item) => item.gameName === rd.gameName)
                  result = Object.assign(result, item[0])
                }
                SET_MEMEBER_DETAIL({
                  // useAppStore.getState().SET_MEMEBER_DETAIL({
                  type: 'detail',
                  data: result
                })
                window.localStorage.setItem('GameMemberDetail', JSON.stringify(result || {}))
                return Promise.resolve({ ...rd, games: gameFmt })
              } else {
                return Promise.reject({})
              }
            })
          }
        }
        const gameIds = []
        const gameNames = []
        rd.games.map((item) => {
          if (item.gameId && item.providerCode !== 'PNG') {
            gameIds.push(item.gameId)
          } else if (item.providerCode === 'PNG') {
            gameNames.push(item.gameName)
          }
        })
        setGameData('gameIds', Array.from(new Set(gameIds)))
        setGameData('gameNames', Array.from(new Set(gameNames)))
      },
      getMemberDetail: (cb) => {
        const data = useAppStore.getState().memberDetail?.data || {}
        const params = {
          merchantCode: data.merchantCode,
          providerCode: data.providerCode,
          billNo: data.billNo
        }
        queryGamesMemberDetail(params).then((res: any) => {
          if (res.success && res.data) {
            let rd = res.data || {}
            /**
             * isPNG: PNG游戏存在多个gameId, 榜单接口返回的是投注ID导致无法进游戏；
             * 开发逻辑：判断为PNG游戏商时，替换game_page接口中的gameId在爆奖弹窗详情中使用；
             */
            const isPNG = data.providerCode === 'PNG' || data.providerName === 'PNG'
            get()
              .getGameListFun(rd, isPNG)
              .then(() => {
                if (typeof cb === 'function') cb()
              })
              .catch((e) => {
                const { SET_MEMEBER_DETAIL } = useAppStore.getState()
                if (rd) {
                  rd = { ...params, ...rd }
                  SET_MEMEBER_DETAIL({
                    // useAppStore.getState().SET_MEMEBER_DETAIL({
                    type: 'detail',
                    data: rd
                  })
                  // getGameListFun接口读取异常时先储存一次。
                  localStorage.setItem('GameMemberDetail', JSON.stringify(rd || {}))
                  cb()
                }
              })
          }
        })
      },
      getMaxGameLen: () => {
        if (typeof window != undefined) {
          const u = global?.window && global?.window.navigator.userAgent
          const ios = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
          const iPad = u.indexOf('iPad') > -1
          const iPhone = u.indexOf('iPhone') > -1 || u.indexOf('Mac') > -1
          if (ios || iPad || iPhone) {
            return 2
          } else {
            return 4
          }
        }
      },
      getGameBetLimit() {
        if (get().liveGameBetLimit.length > 0) return
        queryGameBetLimit({ gameCatalog: 1, merchantCode: '1b0c73df8b' }).then((res: any) => {
          if (res.success) {
            set({ liveGameBetLimit: res.data })
          }
        })
      },
      setGameTipFun(fn) {
        set({ gameTipFun: fn })
      },
      setGameTipShow(flag) {
        set({ gameTipShow: flag })
      },
      setHasHydrated: (state) => {
        set({
          _hasHydrated: state
        })
      }
    })),
    {
      name: 'gameStore', // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
      // 指定需要存储的字段
      partialize: (state) => ({
        favoriteGameList: state.favoriteGameList,
        platformCurrencys: state.platformCurrencys
      }),
      merge: (persistedState, currentState) => ({ ...currentState, ...(persistedState as IGameStroe) }),
      onRehydrateStorage: (state) => {
        return () => state.setHasHydrated(true)
      }
    }
  )
)
