'use client'
import { useState, useEffect, useMemo, FC, memo } from 'react'
import { cn } from '@/lib/utils'
import { useDeepCompareEffect, useUpdateEffect } from 'ahooks'
import { useTranslation } from '@/lib/locales/client'
import { Lng } from '@/lib/locales/i18n'
import { Tabs, Flex } from 'antd'
import { useAppStore } from '@/stores/app'
import { useUserStore } from '@/stores/user'
import { CSSTransition } from 'react-transition-group'
import dayjs from 'dayjs'
import { webpHandle } from '@/hooks/aboutWebp'
import { cmsTemplateApi } from '@/api/base'
import dynamic from 'next/dynamic'
import styles from './index.module.scss'
import { useShallow } from 'zustand/react/shallow'
/** api */
import { transferOutAll } from '@/api/activity'
// import { cmsGameLabels } from '@/api/home'
/* com */
import Banner from '../_components/banner'
import HomeIcons from '../_components/common/misc/homeIcons'
import HomeIslot from '../_components/common/misc/homeIslot'

const MobileHome: FC<{ lng?: Lng; device?: string }> = ({ lng, device }) => {
  const { t } = useTranslation()
  const { globalPublickKey, changeFastDialog } = useAppStore(
    useShallow((state) => ({
      globalPublickKey: state.globalPublickKey,
      changeFastDialog: state.changeFastDialog
    }))
  )
  const { isLogin, depositActivityInfo, userInfo } = useUserStore(
    useShallow((state) => ({
      isLogin: state.isLogin,
      depositActivityInfo: state.depositActivityInfo,
      userInfo: state.userInfo
    }))
  )
  const [homeBanner, setHomeBanner] = useState([])
  const [activityFlag, setActivityFlag] = useState(false)
  const [depositTime, setDepositTime] = useState(0)
  const [icons, setIcons] = useState([])
  const [prizeGuideBigBg, setPrizeGuideBigBg] = useState('')
  const [gameVideos, setGameVideos] = useState([])

  // 首页标签页内模块
  // const [activeTab, setActiveTab] = useState<any>(0)
  // const [countDown, setCountDown] = useState(3)

  // 内容模块
  const gameModules: any = useMemo(
    () => [
      {
        label: t('common.account.top'),
        Component: dynamic(() => import('./modules/top'), {
          ssr: false
        })
      },
      {
        label: t('common.account.slots'),
        Component: dynamic(() => import('./modules/slots'), { ssr: false })
      },
      {
        label: t('common.account.liveCasino'),
        Component: dynamic(() => import('./modules/liveCasino'), { ssr: false })
      },
      {
        label: t('common.account.shootingGame'),
        Component: dynamic(() => import('./modules/shootingGame'), { ssr: false })
      }
    ],
    []
  )

  // individual banner
  const onGetBannerData = async () => {
    const params = {
      moduleCodeList: ['homeBanner']
    }
    const cmsData: Resp = await cmsTemplateApi(params)
    if (cmsData?.success) {
      setHomeBanner(cmsData.data.filter((item) => item.moduleCode === 'homeBanner'))
    }
  }
  // load cms data
  const onGetCMSData = async () => {
    const params = {
      moduleCodeList: ['homeGameVideos', 'limitedTimeBgNew']
    }
    const cmsData: Resp = await cmsTemplateApi(params)
    if (cmsData?.success) {
      setGameVideos(cmsData.data.filter((item) => item.moduleCode === 'homeGameVideos'))
      const bgArr = cmsData.data.filter((item) => item.moduleCode === 'limitedTimeBgNew')[0] || []
      bgArr.mediaLibrary?.forEach((el) => {
        if (el.resourceType === 1 || el.resourceType === 2) {
          setPrizeGuideBigBg(webpHandle(el.fullWebpFilePath, el.fullFilePath))
        }
      })
    }
  }

  const init = async () => {
    await Promise.all([onGetBannerData(), onGetCMSData()])
  }

  // 待观察 最先调用request-old的接口的地方
  useEffect(() => {
    init()
  }, [])

  useUpdateEffect(() => {
    if (globalPublickKey) init()
  }, [globalPublickKey])

  useEffect(() => {
    if (isLogin) {
      transferOutAll({ loginName: userInfo?.loginName })
    }
  }, [isLogin])

  useDeepCompareEffect(() => {
    if (Object.keys(depositActivityInfo).length) {
      setActivityFlag(depositActivityInfo?.flag || false)
      if (depositActivityInfo.extraResult) {
        setDepositTime(Number(depositActivityInfo?.extraResult?.depositTime) || 0)
      }
    }
  }, [depositActivityInfo])

  return (
    <>
      <div
        className={cn(styles['home-red-page'], 'pb-[20px]')}
        style={{
          background: 'url(/static/images/home/bg_h5.webp) 0 -66px repeat-y',
          backgroundSize: '375px 1455px'
        }}
      >
        {/* 首页轮播图 */}
        <div className={cn(isLogin && 'login')}>
          {/* 根据活动需求 隐藏优惠图 */}
          {isLogin && activityFlag && depositTime < 3 && (
            <CSSTransition timeout={220} in={prizeGuideBigBg} unmountOnExit classNames={'fade'}>
              <div
                onClick={() => changeFastDialog(true)}
                className={'home-top-promo'}
                style={{
                  background: `url(${prizeGuideBigBg}) round`
                }}
              >
                <Flex vertical align={'flex-end'} className="mr-[16px]">
                  <div className="welcom-txt">
                    <p className="text-ellipsis">{t('home.deposit.welcome', { name: userInfo.loginName })}</p>
                  </div>
                  <Flex justify="space-between" align="center" className="rewards-box pr-[8px]">
                    <strong>
                      +{depositActivityInfo?.extraResult?.bonusRate || 0}
                      <i>%</i>
                    </strong>
                    <div>
                      <p>REWARDS</p>
                      <span>End {dayjs(depositActivityInfo?.activityEndDate).format('YYYY-MM-DD')}</span>
                    </div>
                    <img className="w-[20px] -rotate-90" src="/static/images/header/arrow.png" alt="right" />
                  </Flex>
                </Flex>
              </div>
            </CSSTransition>
          )}
          {/* 首页轮播图 */}
          <Banner key={homeBanner.length} homeBanner={homeBanner} />
          {/* 首页标签页 横向菜单*/}
          <div className={'home-tabs-box-warp'}>
            <Tabs
              className={'home-tabs-box'}
              defaultActiveKey="0"
              items={gameModules.map((item, index) => {
                return {
                  key: index,
                  label: item.label,
                  children: <item.Component />
                }
              })}
            />
          </div>
        </div>
        <HomeIcons icons={icons} />
      </div>
    </>
  )
}

export default memo(MobileHome)
