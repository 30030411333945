'use client'
import { useTranslation } from '@/lib/locales/client'
import { useAppStore } from '@/stores/app'
import { Modal } from 'antd'
import { JSX, memo, useMemo, useState } from 'react'
import { px2Scalepx } from '@/hooks/useRem'
import { useShallow } from 'zustand/react/shallow'
import DepositList from '../depositList'
import VirtualDeposit from '../virtualDeposit'
import HibanqDeposit from '../hibanqDeposit'
import styles from '@/app/[lng]/account/(pages)/(deposits)/deposit/index.module.scss'
import styles2 from '@/app/[lng]/account/(pages)/(deposits)/index.module.scss'
import './index.scss'

const FastDeposit = (props: any) => {
  const { buyVirtualCurrency } = props
  const { t } = useTranslation()
  const { changeFastDialog } = useAppStore(
    useShallow((state) => ({ changeFastDialog: state.changeFastDialog }))
  )

  const [depositInfo, setDepositInfo] = useState<any>({})
  const [currentComponent, setCurrentComponent] = useState('')
  const handleCallBack = (val) => {
    setDepositInfo(val)
    setCurrentComponent(val.component)
  }

  return (
    <Modal
      className={'fast-deposit-dialog'}
      wrapClassName="ters"
      open={true}
      centered={true}
      footer={null}
      width={px2Scalepx(906)}
      keyboard={false}
      title={t('deposit.fastg')}
      onCancel={() => {
        changeFastDialog(false)
      }}
    >
      <div className={styles2['deposits-common']}>
        <div className={styles['deposit-page']} >
          <div className="contain">
            <DepositList chooseType={(val) => handleCallBack(val)} isFastDeposit={true}></DepositList>
            {currentComponent === 'virtual' ? (
              <VirtualDeposit depositInfo={depositInfo} isFastDeposit={true} />
            ) : (
              currentComponent === 'hibanq' && (
                <HibanqDeposit depositInfo={depositInfo} key={depositInfo.channelCode} isFastDeposit={true} />
              )
            )}
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default memo(FastDeposit)
