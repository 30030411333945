'use client'
import styles from './index.module.scss'
import React, { memo } from 'react'
import { px2Scalepx } from '@/hooks/useRem'
import { useAppStore } from '@/stores/app'
import VerifyBaseDialog from '@/components/common/VerifyBaseDialog'
import { useTranslation } from '@/lib/locales/client'

const CancelBonus = (props: any) => {
  const { close, cancelBonus } = props
  const isBigScreen = useAppStore((state) => state.isBigScreen)
  const { t } = useTranslation()
  return (
    <VerifyBaseDialog
      cusClass={styles['lee-cancel-dialog']}
      visible={true}
      zIndex={5000}
      width={px2Scalepx(400)}
      closeFn={close()}
    >
      <p className="title">{t('bonus.cancel')}</p>
      <p className="txt">{t('bonus.cancelTast1')}</p>

      <div className="btn-group">
        <span className="left" onClick={close()}>
          {t('bonus.canceled')}
        </span>
        <span className="right" onClick={cancelBonus()}>
          {t('bonus.confirm')}
        </span>
      </div>
    </VerifyBaseDialog>
  )
}

export default memo(CancelBonus)
