'use client'
import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'
// import { useShallow } from 'zustand/react/shallow'
import { notFound } from 'next/navigation'
import { formatUpdateApi } from '@/api/account'
import { getScrollWidth } from '@/lib/utils'
import { useUserStore } from '@/stores/user'
// import { useRouter } from 'next/navigation'
import {
  cmsTemplateApi,
  cmsTemplateDetailApi,
  cmsTemplatePageApi,
  getCommonConfig,
  getPublicKey,
  commonCheckIp
} from '@/api/base'
import { IAppStroe } from '../types/IStore'
import { getActLotConfig, getReceiveLotteryTickets, queryCustomerLotNum } from '@/api/activity'

// 创建状态存储
export const useAppStore = create<IAppStroe>()(
  persist(
    immer((set, get) => ({
      mainTheme: 'red',
      language: 'en-US',
      // isBigScreen: global?.window && window.innerWidth > 999, //// 是不是大尺寸，即大于999px，即判定为PC版本
      isBigScreen: false, // 默认小屏，入口会加读取当前屏宽度的方法
      currentThem: '',
      globalLoading: false, //全局loading-flag
      isShowSideNav: false, // 是否展示H5侧边栏菜单
      isShowNotification: false, // 是否展示小铃铛侧边栏菜单
      isShowAvatar: false, // 是否展示头像更改弹框
      isShowAddToHome: false,
      isShowNoticeSysDialog: false, // 系统通知
      isShowHomeBonusDialog: false, // 全局待领取奖励弹窗
      countryCurInfo: {
        alpha2: 'JP',
        code: '81',
        codeToInt: 81,
        country: 'Japan',
        countryFullName: 'Japan',
        countryCode: null
      }, //国家信息
      // 全局消息弹窗
      globalMsg: {
        flag: false, // 消息开关
        type: 'success',
        content: '', // 消息内容
        count: 3 // 倒计时
      },
      /* 全局加密-publicKey */
      globalPublickKey: '',
      // abandon
      dialogType: {
        forget: false
      },
      forgetDialog: false,
      unreadMsgCount: 0,
      serviceCloseHintShow: false,
      isShowFastDeposit: false,
      isOpenGameAudio: true, // 是否开启游戏音效
      previewDialogData: {
        visible: false,
        gameData: {
          guessGameName: '',
          trailerUrl: ''
        }
      },
      introduceDialog: false, //网站介绍弹框
      introduceShowTimes: 0,
      isShowSameAlert: false, //【QC-PC/H5】任务中心互斥弹窗 REX-6714
      mutualTaskList: [],
      commonConfig: <any>{
        productId: 'jP8InKJj7vjrjKbQMQm5IRthP2HebJEX',
        appid: '57db4f6e07644286aa880e3d0377cc83',
        appidForPc: '406f817d68df42368af89d2540993fa0',
        authorization: 'kratos-client:123456',
        merchantCode: '1b0c73df8b',
        webkey:
          'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCnj02qwBE8YQWX/jpUZtyrB5QD7ieBLlYbf8EbNE6z9/EZcV7/J/AnaClrFHYhlnqb3Tc2LSNU20ls9TKxateNV5lFxlr4Opr7tDOBXme9L3x7YliG4Mxfn8zG+MHIGx6L/O/nEw876xH9SCAMlijC9Cz2sgkkOpQ6rsdUZAohOwIDAQAB'
      }, // 商户配置信息
      limitLogin: false,
      showLimitLogin: false,
      userIp: '',
      accessType: 0,
      isNewVer: true, // 首页是否为新版
      memberDialog: false, // 会员信息相关弹窗
      memberDetail: {
        type: 'detail',
        data: {}
      }, // 游戏会员详情
      rewardshowSwiper: false, //任务中心排列方式
      lotteryTicketConfig: {},
      routerPush: '', //支持了工具函数里面调用roter跳转
      _hasHydrated: false,
      // actions
      SET_TASK_SAME_ALERT: (isShowAlert, mutualTaskList, taskId) => {
        set({ isShowSameAlert: isShowAlert })
        if (mutualTaskList) {
          if (taskId) {
            set({
              mutualTaskList: mutualTaskList.sort((a, b) => {
                if (a.taskId === taskId) {
                  return 1
                } else {
                  return 0
                }
              })
            })
          } else {
            set({ mutualTaskList: mutualTaskList })
          }
        }
      },
      queryCommonConfig: async () => {
        // const { currencyDisplay, getCurrencyRate } = useUserStore(
        //   useShallow((state) => ({
        //     currencyDisplay: state.currencyDisplay,
        //     getCurrencyRate: state.getCurrencyRate
        //   }))
        // )
        const { currencyDisplay, getCurrencyRate } = useUserStore.getState()
        const res = await getCommonConfig({})
        // console.log('全局配置', res)
        if (res.data) {
          set({ commonConfig: { ...get().commonConfig, ...res.data } })
          if (!get().commonConfig.appid || !get().commonConfig.appidForPc) {
            set({
              commonConfig: {
                appid: '57db4f6e07644286aa880e3d0377cc83',
                appidForPc: '406f817d68df42368af89d2540993fa0'
              }
            })
          }
          if (currencyDisplay !== '') {
            getCurrencyRate()
          }
        }
      },

      /* 请求public-key */
      requestPublicKey: async () => {
        const result: any = await getPublicKey()
        // console.log('public key', result)
        // 配置公共key信息
        if (result?.successful) {
          set({ globalPublickKey: result.data || '' })
          return result
        }
      },
      acccesslimit: async () => {
        const res: any = await commonCheckIp()
        if (
          res.success &&
          res.data.accessLimit &&
          res.data.region !== 'localhost' &&
          global?.window &&
          window.navigator.userAgent.indexOf('prerender') === -1
        ) {
          // accessType 1   限制登录注册  accessType 2   限制访问（403）
          set({ limitLogin: true, showLimitLogin: true, userIp: res.data.ip })
          if (res.data.accessType === 2) {
            set({ accessType: 2 })
            // _this.$router.push('/403')
            notFound()
          }
        } else {
          set({ accessType: 0, limitLogin: false })
        }
      },
      changeFastDialog: (isShow) => set({ isShowFastDeposit: isShow }),
      // abandon
      setDialogType: (v) => {
        set({ dialogType: Object.assign(get().dialogType, v) })
      },
      setForgetDialog: (flag) => set({ forgetDialog: flag }),
      changeLanguage: async (lang: 'en-US' | 'ja-JP') => {
        if (get().language === lang) return
        //set({ language: lang })
        // 后台存储语言，用户登录时才保存
        const { isLogin, userInfo } = useUserStore.getState()
        if (!isLogin) return
        // 只作保存，跳转由前台完成，失败由拦截器通知
        formatUpdateApi({
          lang,
          modifyType: 10,
          id: userInfo?.id,
          lastUpdatedBy: userInfo?.loginName
        })
      },
      setIsShowNotification: (data) => {
        const targetNode = document.getElementsByTagName('body')[0]
        const antApp = document.getElementsByClassName('ant-app')[0] as HTMLAnchorElement
        if (data) {
          global?.window && window.document.body.classList.add('ant-scrolling-effect')
          targetNode.style.setProperty('overflow', 'hidden')
          // targetNode.style.setProperty('width', `calc(100% - ${getScrollWidth()}px)`)
          antApp && antApp.style.setProperty('position', 'relative')
        } else {
          global?.window && window.document.body.classList.remove('ant-scrolling-effect')
          targetNode.style.removeProperty('overflow')
          targetNode.style.removeProperty('width')
        }
        set({ isShowNotification: data })
      },
      updatePreviewDialog: (data) => set({ previewDialogData: data }),
      setIsBigScreen: (data) => set({ isBigScreen: data }),
      setGolabLoading: (data) => {
        // console.log('打开loading 数据', data)
        set({ globalLoading: data })
      },
      setIsShowSideNav: (data) => set({ isShowSideNav: data }),
      setIsShowAvatar: (data) => set({ isShowAvatar: data }),
      setIsShowAddToHome: (data) => set({ isShowAddToHome: data }),
      setIsShowNoticeSysDialog: (data) => set({ isShowNoticeSysDialog: data }),
      setShowHomeBonusDialog: (data) => set({ isShowHomeBonusDialog: data }),
      /* 国家信息 */
      SET_COUNTRY_INFO: (data) => set({ countryCurInfo: data }),
      /* 自定义全局信息 */
      SET_GLOBAL_MSG: (data) => set({ globalMsg: data }),
      SET_LANGUAGE: (data) => set({ language: data }),
      SET_iSOPEN_GAME_AUDIO: (data) => set({ isOpenGameAudio: data }),
      updateUnreadMsgCount: (data) => set({ unreadMsgCount: data }),
      updateServiceCloseHintShow: (data) => set({ serviceCloseHintShow: data }),
      updateIntroduceDialog: (data) => set({ introduceDialog: data }),
      updateLimitLoginDialog: (data) => set({ showLimitLogin: data }),
      SET_MEMEBER_DIALOG: (data) => set({ memberDialog: data }),
      SET_MEMEBER_DETAIL: (data) => set({ memberDetail: data || {} }),
      SET_IS_NEW_VERSION: (data) => set({ isNewVer: data }),
      SET_REWARD_SHOW_SWIPER: (data) => set({ rewardshowSwiper: data }),
      SET_PUBLIC_KEY: (data) => set({ globalPublickKey: data }),
      SET_ROUTER_PUSH: (data) => set({ routerPush: data }),
      SET_LOTTERY_TICKET_CONFIG: (data) => set({ lotteryTicketConfig: data }),
      getLotteryTicketConfig: async () => {
        //获取本期奖券配置
        getActLotConfig({}).then(({ success, data }: any) => {
          if (success && data) {
            // console.log('集奖券数据', data)
            const { actCode, actEndDate } = data
            if (actCode < 20241008) return
            const { isLogin } = useUserStore.getState()
            get().SET_LOTTERY_TICKET_CONFIG(data)
            if (isLogin && new Date(actEndDate) > new Date()) {
              //登陆后请求用户奖券信息
              queryCustomerLotNum({ actCode }).then((res: any) => {
                if (res.code === 200 && res.success && res.data.length) {
                  //首期运营只会配置一种奖券，所以写死取第一个
                  const obj = res.data[0]
                  if (obj?.num > 0 && obj.limitNum > obj.usedNum) {
                    //如果有待领取 自动领取
                    getReceiveLotteryTickets({
                      actCode,
                      sourceType: obj.sourceType
                    }).then((res: any) => {
                      //领取成功或者失败后刷新奖券信息
                      queryCustomerLotNum({ actCode }).then((info: any) => {
                        get().SET_LOTTERY_TICKET_CONFIG({ ...get().lotteryTicketConfig, ...info })
                      })
                    })
                  } else {
                    get().SET_LOTTERY_TICKET_CONFIG({ ...get().lotteryTicketConfig, ...obj })
                  }
                }
              })
            }
          }
        })
      },
      setHasHydrated: (state) => {
        set({
          _hasHydrated: state
        })
      }
    })),
    {
      name: 'appStore', // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
      // 指定存储的字段
      partialize: (state) => ({
        language: state.language,
        isBigScreen: state.isBigScreen,
        currentThem: state.currentThem,
        globalPublickKey: state.globalPublickKey,
        isOpenGameAudio: state.isOpenGameAudio,
        commonConfig: state.commonConfig,
        memberDetail: state.memberDetail,
        rewardshowSwiper: state.rewardshowSwiper,
        introduceShowTimes: state.introduceShowTimes
      }),
      merge: (persistedState, currentState) => ({ ...currentState, ...(persistedState as IAppStroe) }),
      onRehydrateStorage: (state) => {
        return () => state.setHasHydrated(true)
      }
      // skipHydration: true
    }
  )
)
