import { useState, useEffect, memo } from 'react'
import { useTranslation } from '@/lib/locales/client'
import { useAppStore } from '@/stores/app'
import { useShallow } from 'zustand/react/shallow'
import styles from './index.module.scss'
import ConfigBase from '@/config/base'
import Swiper from '../swiper'
import { cmsTemplateApi } from '@/api/base'

const UnLoginHeader = () => {
  const { t } = useTranslation()
  // const { isBigScreen, mainTheme, globalPublickKey } = useAppStore(
  //   useShallow((state) => ({
  //     isBigScreen: state.isBigScreen,
  //     mainTheme: state.mainTheme,
  //     globalPublickKey: state.globalPublickKey,
  //   }))
  // )

  // const [menuTopEntrance, setMenuTopEntrance] = useState([])
  // const queryNavigation = async () => {
  //   const params = {
  //     moduleCodeList: ['headerNavigation', 'menuTopEntrance'],
  //   }
  //   const cmsData:Resp = await cmsTemplateApi(params)
  //   if(cmsData?.success) {
  //     const menuData = cmsData.data
  //     .filter((item, index) => item.moduleCode === 'menuTopEntrance')
  //     .filter((item, index) => index < 2)
  //   setMenuTopEntrance(menuData)
  //   }
  // }
  // useEffect(() => {
  //   if (!isBigScreen && globalPublickKey) {
  //     queryNavigation()
  //   }
  // }, [])

  return (
    <div className={styles['account-unlogin']}>
      <div className="account-top">
        <div className="top">
          <div className="user-profile"></div>
          <div className="name-box">
            <p className="name">{t('common.account.welcome')}</p>
            <p className="time">{t('common.account.tips')}</p>
          </div>
        </div>
      </div>
      <Swiper />
    </div>
  )
}

export default memo(UnLoginHeader)
