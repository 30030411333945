'use client'
import { cn } from '@/lib/utils'
import styles from './index.module.scss'
import { memo, useMemo } from 'react'
import { useTranslation } from '@/lib/locales/client'

const RewardIcon = (props: any) => {
  const { t } = useTranslation()
  const iconColor = (type: string) => {
    if (type === 'bonus') {
      return '#FC005B'
    } else if (type === 'freeSpin') {
      return '#00BEE8'
    } else if (type === 'quest') {
      return '#FF7A00'
    } else {
      return '#8000FF'
    }
  }
  const types: string[] = useMemo(() => props.types?.split(';') || [], [props.types])
  const iconText = (type: string) => {
    if (type === 'bonus') {
      return t('bonus.bonus')
    } else if (type === 'freeSpin') {
      return t('promo.freeSpin')
    } else if (type === 'quest') {
      return t('bonus.bonusPlaza')
    } else if (type === 'reward') {
      return t('promo.reward')
    } else {
      return t('bonus.extra')
    }
  }
  return (
    <>
      {types.map((item: string, index: number) => (
        <div className={cn(styles.reward_icon, styles['reward-icon' + index])} key={index}>
          {index === 0 && (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 22" fill="none">
              <path d="M60 0V15.1425L29.9265 22L0 15.1425V0H60Z" fill={iconColor(item)} />
            </svg>
          )}
          <svg xmlns="http://www.w3.org/2000/svg" width="60" height="27" viewBox="0 0 60 27" fill="none">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0 0V20L29.9265 26.8575L60 20V0L29.9265 6.85748L0 0Z"
              fill={iconColor(item)}
            />
          </svg>
          <p>{iconText(item)}</p>
        </div>
      ))}
    </>
  )
}

export default memo(RewardIcon)
