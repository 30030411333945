import { useAppStore } from '@/stores/app'
/* 活动配置相关 */
import { post } from './config/config'

/* 专题页配置接口 */
export const getSpecialData = (params: any) => {
  return post('/api/phoenix/cms/open/topic/query', params)
}

/* *************vip--start******************** */
/* 获取所有等级列表 */
export const queryAllLeveList = () => post('/api/v1/player/level/query_process')
/* 等级详情 */
export const queryDetailLogined = () => post('/api/v1/player/level/query_process')
export const queryDetail = () => post('/api/phoenix/user/open/levelAndBenefits/detail')

/* *************vip--end******************** */

/* **************** 好友推荐start ******************** */
/* 专题页配置接口 */
export const getUserRecInfo = () => {
  return post('/api/phoenix/user/referral/info')
}
/* 专题页配置接口 */
export const getRecFriendList = (params: any) => {
  return post('/api/phoenix/user/referral/friends', params)
}
/* **************** 好友推荐end ******************** */

/* **************** new好友推荐start ******************** */
export const getRecFriendBonusConfigList = (params: any) => {
  return post('/api/phoenix/prom/open/act/referral/queryBonusConfig', params)
}
export const getRecFriendReferredList = (params: any) => {
  return post('/api/phoenix/prom/act/referral/queryContributionRecordPage', params)
}
export const getRecFriendReferredBonusList = (params: any) => {
  return post('/api/phoenix/prom/act/referral/queryReferralBonusRecordPage', params)
}
export const getRecFriendSpinsList = (params: any) => {
  return post('/api/phoenix/prom/act/referral/queryReferralSpinsPage', params)
}
export const getRecFriendTotalData = (params: any) => {
  return post('/api/phoenix/prom/act/referral/queryTotalBonus', params)
}
export const getRecFriendClaimBonus = (params: any) => {
  return post('/api/phoenix/prom/act/referral/receiveReferralBonus', params)
}
/* **************** new好友推荐end ******************** */

/* **************** 锦标赛start ******************** */
// 锦标赛列表页接口 :(不需登录)
export const getTournamentList = (params: any) => {
  return post('/api/phoenix/prom/open/tournament/list', params, { hideLoading: true })
}

// 锦标赛详情页接口(不需登录)
export const getTournamentDetail = (params: any) => {
  return post('/api/phoenix/prom/open/tournament/detail', params, { hideLoading: true })
}

// 用户锦标赛列表页接口
export const getTournamentRank = () => {
  return post('/api/phoenix/prom/tournament/customer/rank')
}
// 用户锦标赛详情页接口
export const getTournamentAllJoinRecord = (params: any) => {
  return post("/api/phoenix/prom/tournament/customer/alljoinRecord", params, { hideLoading: true })
}


// 用户锦标赛详情页接口
export const getTournamentCustomerJoinRecord = (params: any) => {
  return post('/api/phoenix/prom/tournament/customer/joinRecord', params, { hideLoading: true })
}

// 用户上回奖励领取接口
export const getTournamentCustomerClaimPrize = (params: any) => {
  return post('/api/phoenix/prom/tournament/claimPrize', params)
}

/* **************** 锦标赛end ******************** */

export const getActLotCarouselData = (params: any) => {
  return post('/api/phoenix/prom/open/act/lot/carousel/data', params)
}
export const getActLotConfig = (params: any) => {
  return post('/api/phoenix/prom/open/act/lot/config', params, { hideLoading: true })
}
export const getActLotPrize = (params: any) => {
  return post('/api/phoenix/prom/open/act/lot/prize', params, { hideLoading: false, ...params })
}
//奖券来源配置
export const getSourceConfig = (params: any) => {
  return post('/api/phoenix/prom/open/act/lot/source/config', params)
}
export const queryCustomerLotNum = (params: any) => {
  return post('/api/phoenix/prom/act/lot/queryCustomerLotNum', params)
}
export const queryCustomerLotNumTotal = (params: any) => {
  return post('/api/phoenix/prom/act/lot/account/totalNum', params)
}
export const getReceiveLotteryTickets = (params: any) => {
  return post('/api/phoenix/prom/act/lot/receiveLotteryTickets', params)
}
export const getActLotConfigList = (params: any) => {
  return post('/api/phoenix/prom/open/act/lot/config/list', params, { hideLoading: false, ...params })
}
export const getActLotPage = (params: any) => {
  return post('/api/phoenix/prom/act/lot/page', params, { hideLoading: true })
}
export const getActLotHistory = (params: any) => {
  return post('/api/phoenix/prom/open/act/lot/history', params, { hideLoading: true })
}

export const getAccountPrizeList = (params: any) => {
  return post('/api/phoenix/prom/act/lot/account/prize', params, { hideLoading: true })
}
export const receiveAccountBonus = (params: any) => {
  return post('/api/phoenix/prom/act/lot/receive/bonus', params, { hideLoading: true })
}

export const queryGuessGameList = (params: any) => {
  return post('/api/phoenix/prom/open/act/guessgame/config/queryGuessGameList', params, { hideLoading: true })
}
export const queryGuessGameOptions = (params: any) => {
  return post('/api/phoenix/prom/open/act/guessgame/option/queryOptions', params, { hideLoading: true })
}
export const queryGuessGameEstimateReward = (params: any) => {
  return post('/api/phoenix/prom/open/guessgame/customer/estimateReward', params, { hideLoading: true })
}
export const queryGuessGameBet = (params: any) => {
  const { isBigScreen, commonConfig } = useAppStore.getState()
  params.aid = isBigScreen ? commonConfig.appidForPc : commonConfig.appid
  return post('/api/phoenix/prom/guessgame/customer/bet', params, { hideLoading: true })
}

//历史赢家
export const queryCustomerGuessTop = (params: any) => {
  return post('/api/phoenix/prom/open/guess/game/queryCustomerGuessTop', params, { hideLoading: true })
}

// 高奖池
export const queryGuessGameTop = (params: any) => {
  return post('/api/phoenix/prom/open/guess/game/queryGuessGameTop', params, { hideLoading: true })
}

//全站竞猜
export const queryGuessGameBetRecordPage = (params: any) => {
  return post('/api/phoenix/prom/open/act/guessgame/bet/queryBetRecordPage', params, { hideLoading: true })
}
//开奖记录
export const queryOpenRecordPage = (params: any) => {
  return post('/api/phoenix/prom/open/act/guessgame/config/queryOpenRecordPage', params, {
    hideLoading: true
  })
}

//竞猜标签
export const queryGuessGameTagList = (params: any) => {
  return post('/api/phoenix/prom/open/act/guessgame/tag/queryGuessGameTagList', params, { hideLoading: true })
}

export const queryCustomerBonusTop = (params: any) => {
  return post('/api/phoenix/prom/guess/game/queryCustomerBonusTop', params, { hideLoading: true })
}
export const getPrizeRecordPage = (params: any) => {
  return post('/api/phoenix/prom/guessgame/customer/getPrizeRecordPage', params, { hideLoading: true })
}
export const claimGuessGamePrize = (params: any) => {
  return post('/api/phoenix/prom/guessgame/customer/claimPrize', params, { hideLoading: false })
}
export const cancelGuessGamePrize = (params: any) => {
  return post('api/phoenix/prom/guessgame/customer/cancelPrize', params, { hideLoading: false })
}
export const queryGuessBetPage = (params: any) => {
  return post('/api/phoenix/prom/guess/game/queryGuessBetPage', params, { hideLoading: false })
}
export const queryPopoutGuessGameList = (params: any) => {
  return post('/api/phoenix/prom/open/act/guessgame/config/queryPopoutGuessGameList', params, {
    hideLoading: false
  })
}
// REX-5900 首页推送活动
export const getHomeTask = (params: any) => {
  return post('/throne-api/activity/queryActivityConstant', params, { hideLoading: true })
}

// REX-5900 查询指定任务配置
export const getDesignationTasks = (params: any) => {
  return post('/api/phoenix/prom/open/task/designationTasks', params, { hideLoading: true })
}

// REX-5900 查询主活动有哪些附加活动
export const getAdditionalConfig = (params: any) => {
  return post('/api/phoenix/prom/open/act/queryAdditionalConfig', params, { hideLoading: true })
}

//查询用户完成事件的时间
export const getCustomerDate = (params: any) => {
  return post('/api/phoenix/prom/act/queryCustomerDate', params, { hideLoading: true })
}

//查询Bonus 列表
export const getBonusList = (params: any) => {
  return post('/api/v1/bonus/request/new/query', params, { hideLoading: true })
}

//申请活动
export const applyBonus = (params: any) => {
  return post('/throne-api/activity/apply', params)
}

//获取签到详情
export const signDetail = (params: any) => {
  return post('/throne-api/activity_detail_sign/customer_sign_detail_v2', params, { hideLoading: true })
}

//签到
export const signApplyAign = (params: any) => {
  return post('/throne-api/activity_detail_sign/apply_sign_v2', params, { hideLoading: true })
}

//签到领奖
export const signRewardFetch = (params: any) => {
  return post('/throne-api/activity_detail_sign/fetch_sign_reward', params)
}

//获取签到查询优惠提案列表
export const signQueryRequests = (params: any) => {
  return post('/api/v1/bonus/request/query_requests', params)
}

//获取签到会员奖品列表
export const signQueryMyPrize = (params: any) => {
  return post('/throne-api/activity/queryMyPrize', params)
}

//优惠配置详情
export const getBonusDetail = (params: any) => {
  return post('/api/ext/v1/bonus/coupon/bonus_config', params, { hideLoading: true })
}

//查询会员的KYC等级及其他信息
export const getPlayerCommonInfo = (params: any) => {
  return post('/api/v1/player/get_player_common_info', params, { hideLoading: true })
}

//放弃优惠券
export const giveUPBonus = (params: any) => {
  return post('/api/v1/bonus/give_up', params)
}

//释放优惠券
export const releaseBonus = (params: any) => {
  return post('/api/v1/bonus/release', params)
}

// islot 转钱包
export const transferOutAll = (params: any) => {
  return post(`/api/v1/game/transfer/transfer_out_all`, params)
}

//分页查询玩家个人优惠券列表
export const bonusCouponPage = (params: any) => {
  return post('/api/ext/v1/bonus/coupon/page', params)
}

//查询免费旋转
export const getFreeSpinList = (params: any) => {
  return post('/throne-api/activity/free_spin/list', params, { hideLoading: true })
}

//领取免费旋转
export const applyFreeSpin = (params: any) => {
  return post('/throne-api/customer_free_spin/apply_free_spin', params)
}

//预洗码查询接口
export const rakebackRequest = (params: any) => {
  return post('/api/v2/rakeback_request/pre/v2', params)
}

//预洗码查询接口
export const rakebackReceive = (params: any) => {
  return post('/api/v2/rakeback_request/receive/v2', params)
}

//活动时间内所有的任务标签
export const taskQueryTaskTags = (params: any) => {
  return post('/api/phoenix/prom/open/task/queryTaskTags', params)
}

//显示用户可用任务
export const taskCheckDisplay = (params: any) => {
  return post('/api/phoenix/prom/task/checkDisplay', params, { hideLoading: true })
}

//用户申请优惠
export const taskApply = (params: any) => {
  return post('/api/phoenix/prom/task/apply', params)
}

//查询Statisitics
export const sumFullLifecycle = (params: any)=>{
  return post('/report/player_order_stat/sum_full_lifecycle', params, { hideLoading: true })
}

//保存任务 白名单-黑名单
export const taskWhitelistSave = (params: any) => {
  return post('/api/phoenix/prom/act/task/whitelist/save', params, { hideLoading: true })
}
