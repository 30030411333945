'use client'
import { useState, useEffect, useMemo, memo, FC } from 'react'
import { cn, decimalFormat } from '@/lib/utils'
import { Flex, Empty, Drawer, Modal } from 'antd'
import { useTranslation } from '@/lib/locales/client'
import { useAppStore } from '@/stores/app'
import { useUserStore } from '@/stores/user'
import { useRouter, usePathname, useParams } from 'next/navigation'
import styles from './_common_css/mobileAccount.module.scss'
import './_common_css/mobileAccountDrawer.scss'
import { CSSTransition } from 'react-transition-group'
import { useShallow } from 'zustand/react/shallow'
import ConfigBase from '@/config/base'
import { useCookies } from 'react-cookie'
import Config from '@/config/sidebar'
import Link from 'next/link'
// com*
import { editAvatar } from '@/api/account'
import Credit from './credit'
import UnLoginHeader from './unLoginHeader'
import Swiper from './swiper'
import VipProgress from './vipProgress'
// import Statistics from '../__abandon/statistics'
// import BadgeSystem from './badgeSystem'
import dayjs from 'dayjs'
import { useRedirect } from '@/lib/useRedireact'

type IProps = {
  device: 'mobile' | 'desktop'
  isShowPC?: boolean
  onPCPop?: (flag: boolean) => void
}

const MainPage: FC<IProps> = (props) => {
  const { isShowPC = false, onPCPop } = props
  const { t } = useTranslation()
  const { language, commonConfig, setGolabLoading, SET_GLOBAL_MSG, isBigScreen } = useAppStore(
    useShallow((state) => ({
      language: state.language,
      commonConfig: state.commonConfig,
      setGolabLoading: state.setGolabLoading,
      SET_GLOBAL_MSG: state.SET_GLOBAL_MSG,
      isBigScreen: state.isBigScreen
    }))
  )
  const {
    isLogin,
    userLogout,
    vipInfo,
    userInfo,
    updateUserInfo,
    getExchangeRateFn,
    statisticsInfo,
    kycAuditStatus
  } = useUserStore(
    useShallow((state) => ({
      isLogin: state.isLogin,
      userLogout: state.userLogout,
      vipInfo: state.vipInfo,
      userInfo: state.userInfo,
      updateUserInfo: state.updateUserInfo,
      getExchangeRateFn: state.getExchangeRateFn,
      statisticsInfo: state.statisticsInfo,
      kycAuditStatus: state.kycAuditStatus
    }))
  )
  // const { openLogin, openRegister } = useAuthStore(
  //   useShallow((state) => ({
  //     openLogin: state.openLogin,
  //     openRegister: state.openRegister
  //   }))
  // )
  const router = useRouter()
  const pathName = usePathname()
  const rawPath = pathName.replace(/^\/(en|ja)($|\/)/g, '/')
  const { lng } = useParams()
  const [drawerIsVisible, setDrawerIsVisible] = useState(false)
  const [currentIndex, setCurrentIndex] = useState(null)
  const lastLoginDate = useMemo(() => {
    return userInfo.lastLoginDate ? dayjs(userInfo.lastLoginDate).format('YYYY-MM-DD hh:mm:ss') : ''
  }, [userInfo.lastLoginDate])
  const redirect = useRedirect()
  const [cookies, setCookie] = useCookies(['authLog'])

  const goAccountVerification = (val) => {
    router.push(`/${lng}${val}`)
  }
  const isCompleteVerify = useMemo(() => {
    return (
      !!userInfo.firstNameJapanese &&
      !!userInfo.lastNameJapanese &&
      !!userInfo.mobile &&
      !!userInfo.email &&
      kycAuditStatus === 4
    )
  }, [])

  const logoutUser = async () => {
    try {
      setGolabLoading(true)

      await Promise.all([
        userLogout(),
        getExchangeRateFn({
          tradeType: '01',
          targetCurrency: 'USD',
          sourceCurrency: 'BTC',
          isFindByCache: 0
        })
      ])
      // console.log('登出这里?', cookies.authLog)
      // setCookie('authLog', '', { path: '/' })
      router.replace(`/${lng}`)
      // redirect(`/`)
    } catch (err) {
      console.log('err', err)
    } finally {
      isShowPC && onPCPop(false)
      setGolabLoading(false)
    }
  }

  const NewAvatarList = useMemo(() => {
    const filterFlag = (min, max) => {
      if (vipInfo.currentLevel > max) return true
      return vipInfo.currentLevel >= min && vipInfo.currentLevel <= max
    }
    let avatarList = [
      { flag: true, en: 'default', id: 0 },
      { flag: true, en: 'default', id: 1 },
      { flag: true, en: 'default', id: 2 },
      { jp: 'アイアン', flag: filterFlag(1, 20), en: 'Iron', id: 3 },
      { jp: 'ブロンズ', flag: filterFlag(21, 40), en: 'Bronze', id: 4 },
      { jp: 'シルバー', flag: filterFlag(41, 60), en: 'Silver', id: 5 },
      { jp: 'ゴールド', flag: filterFlag(61, 80), en: 'Gold', id: 6 },
      { jp: 'プラチナ', flag: filterFlag(81, 99), en: 'Platinum', id: 7 },
      { jp: 'プラチナ', flag: filterFlag(81, 99), en: 'Platinum', id: 8 },
      { jp: 'エメラルド', flag: filterFlag(101, 120), en: 'Emerald', id: 9 },
      { jp: 'エメラルド', flag: filterFlag(101, 120), en: 'Emerald', id: 10 },
      { jp: 'ダイヤモンド', flag: filterFlag(121, 129), en: 'Diamond', id: 11 },
      { jp: 'レジェンド', flag: filterFlag(130, 130), en: 'Legend', id: 12 }
    ]
    return avatarList
  }, [vipInfo])

  useEffect(() => {
    setCurrentIndex(userInfo.avatar)
  }, [userInfo.avatar])

  const changeBtn = (unit, index) => {
    if (!unit.flag) {
      return
    }
    setCurrentIndex(index)
  }
  function handleConfirm() {
    let params = {
      merchantCode: commonConfig.merchantCode,
      avatar: currentIndex
    }
    editAvatar(params).then((res) => {
      if (res.success) {
        SET_GLOBAL_MSG({ flag: true, type: 'success', content: t('common.validtor.changeSuc') })
        setDrawerIsVisible(false)
        updateUserInfo()
      }
    })
  }

  return (
    <>
      {props.device === 'desktop' ? (
        <Flex vertical>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className="h-[588px] qc-flex-center" />
        </Flex>
      ) : (
        <div className="bg-white">
          <CSSTransition timeout={220} in={rawPath !== '/account/multimode'} unmountOnExit>
            {(rawPath === `/account` || isShowPC) && (
              // h5端
              <div className={styles['mobile-account-page']} id="mobile-account-popup">
                <div className="relative bg-[url('/static/images/myAccount/h5/header.png')] bg-[length:100%_230px] bg-no-repeat">
                  {isLogin ? (
                    <>
                      <div className="title-box-top">
                        <span>{t('common.account.myProfile')}</span>
                        <img
                          src="/static/images/myAccount/h5/close.svg"
                          alt=""
                          className="cursor-pointer"
                          onClick={() => {
                            if (isShowPC) {
                              isShowPC && onPCPop(false)
                            } else {
                              window && window.history.go(-1)
                            }
                          }}
                        />
                      </div>
                      <div className="account-header-box">
                        <div className="avatar-box">
                          <div
                            className="user-profile pc:cursor-pointer"
                            onClick={() => setDrawerIsVisible(true)}
                          >
                            {userInfo.avatar ? (
                              <img
                                src={`/static/images/myAccount/avatar/avata-${userInfo.avatar}.png`}
                                alt=""
                              />
                            ) : (
                              <img src="/static/images/myAccount/avatar/avata-0.png" alt="" />
                            )}
                            <div className="name-box">
                              <div>
                                <span className="name">{userInfo.loginName}</span>
                                <span className="vip">VIP {vipInfo.currentLevel || 0}</span>
                              </div>
                              <p className="time">
                                {t('user.headUser.lastLogin')}: {lastLoginDate}
                              </p>
                            </div>
                          </div>

                          <Link
                            className="set-box pc:cursor-pointer"
                            href={isBigScreen ? `/${lng}/account/personal` : `/${lng}/account/globalSetting`}
                            onClick={() => isShowPC && onPCPop(false)}
                          >
                            <img src="/static/images/myAccount/h5/i-settings.svg" alt="" />
                          </Link>
                        </div>
                        <VipProgress vipInfo={vipInfo} isShowPC={isShowPC} onPCPop={onPCPop} />
                        {/* BadgeSystem */}
                        <div className="badge">
                          <div className="title-box">
                            <div className="left">
                              <span className="title">{t('user.badge')}</span>
                              <span className="small-title">{t('user.comingSoon')}</span>
                            </div>
                            <span className="details pc:cursor-pointer">{t('user.details')}</span>
                          </div>
                          <div className="badge-list"></div>
                        </div>
                        {/* Credit */}
                        <Credit isShowPC={isShowPC} onPCPop={onPCPop} />
                        {/* Statistics */}
                        <Link
                          href={
                            isBigScreen
                              ? `/${lng}/account/statements`
                              : `/${lng}/account/globalSetting/?history=1`
                          }
                          className="statistics"
                          onClick={() => isShowPC && onPCPop(false)}
                        >
                          <div className="title-box">
                            <div className="title">
                              <span>{t('home.betList.statistics')}</span>{' '}
                              <span className="tips">{t('home.betList.tips')}</span>
                            </div>
                            <span className="details">{t('user.details')}</span>
                          </div>
                          <div className="info">
                            <div className="item">
                              <span className="label"> {t('home.betList.totalWins')}</span>
                              <span className="value">
                                ${decimalFormat(statisticsInfo.totalDefaultWinLossAmount, 'USD')}
                              </span>
                            </div>
                            <div className="item">
                              <span className="label">{t('home.betList.totalBets')}</span>
                              <span className="value">
                                ${decimalFormat(statisticsInfo.totalDefaultValidBetAmount, 'USD')}
                              </span>
                            </div>
                            <div className="item full-span">
                              <span className="label">{t('home.betList.totalWagered')}</span>
                              <span className="value">
                                ${decimalFormat(statisticsInfo.totalDefaultBetAmount, 'USD')}
                              </span>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </>
                  ) : (
                    <UnLoginHeader />
                  )}
                  {/* setting options*/}
                  <div className="modal-content">
                    {!isShowPC && (
                      <>
                        {/* 设置菜单 pc下隐藏 */}
                        {(isLogin ? Config.accountSliderH5login : Config.accountSliderH5Nologin).map(
                          (item, key) => (
                            <dl key={key} onClick={() => goAccountVerification(item.path)}>
                              <dd>
                                <img
                                  src={`/static/images/myAccount/h5/i-${item.icon}.svg`}
                                  alt=""
                                  className={cn('icon', item.class)}
                                />
                                <span>{t(item.title)}</span>
                                {item.type && (
                                  <div className="verified-box">
                                    <span className={cn(isCompleteVerify && 'verified')}>{t(item.type)}</span>
                                    {!isCompleteVerify ? (
                                      <img src="/static/images/theme-red/meau_unverified.png" alt="" />
                                    ) : (
                                      <img src="/static/images/theme-red/meau_verified.png" alt="" />
                                    )}
                                  </div>
                                )}
                                {item.title2 && (
                                  <div
                                    className="video-btn"
                                    onClick={(e) => {
                                      e.stopPropagation()
                                      redirect(item.pathVideo)
                                    }}
                                  >
                                    <span>{t(item.title2)}</span>
                                  </div>
                                )}
                                <img
                                  className="menu-arrow"
                                  src="/static/images/common/arrow-thin.png"
                                  alt=""
                                />
                              </dd>
                            </dl>
                          )
                        )}
                      </>
                    )}
                    {/* user exit */}
                    {isLogin && (
                      <>
                        <div
                          className="logout-box pc:!mx-[16px] pc:cursor-pointer"
                          onClick={logoutUser}
                          style={{ marginTop: isShowPC ? 0 : '16px' }}
                        >
                          <img src="/static/images/myAccount/h5/i-logout.svg" />
                          <span className="logout">{t('common.account.logOut')}</span>
                        </div>
                        <Swiper />
                      </>
                    )}
                  </div>
                </div>

                <div></div>

                {isBigScreen ? (
                  <Modal
                    title={t('user.headUser.EditAvatar')}
                    centered
                    width={375}
                    open={drawerIsVisible}
                    onOk={() => setDrawerIsVisible(false)}
                    onCancel={() => setDrawerIsVisible(false)}
                    wrapClassName={styles['avatar_modal_box']}
                    zIndex={2005}
                    footer={null}
                  >
                    <div className={cn('avatar_drawer_box')}>
                      <div className={'title_img_div'}>
                        <p>{t('user.headUser.EditAvatarTip')}</p>
                        <ul>
                          {NewAvatarList.map((unit, idx) => (
                            <li key={idx} onClick={() => changeBtn(unit, idx)}>
                              <img
                                src={`/static/images/myAccount/avatar/avata-${idx}.png`}
                                alt=""
                                className={cn(!unit.flag && 'locked', +currentIndex === idx && 'active')}
                              />
                              {!unit.flag && (
                                <div className="locked-box">
                                  <img src="/static/images/myAccount/avatar/locked.png" alt="" />
                                  <p>{language === 'en-US' ? unit.en : unit.jp}</p>
                                </div>
                              )}
                            </li>
                          ))}
                        </ul>
                        <div className="btn">
                          <p onClick={handleConfirm}>{t('activity.account.confirm')}</p>
                        </div>
                      </div>
                    </div>
                  </Modal>
                ) : (
                  <Drawer
                    placement={'bottom'}
                    open={drawerIsVisible}
                    className={'avatar_drawer_box'}
                    getContainer={() => document.getElementById('mobile-account-popup')}
                    height={500}
                    closable={false}
                    zIndex={2005}
                  >
                    <div className={'title_img_div'}>
                      <h2 className="title" onClick={() => setDrawerIsVisible(false)}>
                        {t('user.headUser.EditAvatar')}
                      </h2>
                      <p>{t('user.headUser.EditAvatarTip')}</p>
                      <ul>
                        {NewAvatarList.map((unit, idx) => (
                          <li key={idx} onClick={() => changeBtn(unit, idx)}>
                            <img
                              src={`/static/images/myAccount/avatar/avata-${idx}.png`}
                              alt=""
                              className={cn(!unit.flag && 'locked', +currentIndex === idx && 'active')}
                            />
                            {!unit.flag && (
                              <div className="locked-box">
                                <img src="/static/images/myAccount/avatar/locked.png" alt="" />
                                <p>{language === 'en-US' ? unit.en : unit.jp}</p>
                              </div>
                            )}
                          </li>
                        ))}
                      </ul>
                      <div className="btn">
                        <p onClick={handleConfirm}>{t('activity.account.confirm')}</p>
                      </div>
                    </div>
                  </Drawer>
                )}

                {/* choose user avatar */}
              </div>
            )}
          </CSSTransition>
        </div>
      )}
    </>
  )
}

export default memo(MainPage)
