import { post } from './config/config'

export const getLobbyCodeList = () => post('/api/phoenix/game/open/getLobbyCodeList')

//获取游戏列表
export const getGameList = (params: any) => {
  // return post('/api/cms/v1/element/material/game_page', params, { hideLoading: params.hideLoading })
  return post('/cms/v1/element/material/game_page', params, { hideLoading: params.hideLoading })
}
//获取 游戏二级页 pachinko
export const getPachinkoList = (params: any) => {
  return post("/api/v1/game/info/all/machine_info_list", params, { hideLoading: true })
}
//获取游戏厂商下拉
export const getGameProvider = (params: any) => {
  return post('/cms/v1/element/material/game_provider_selection', params, { hideLoading: true })
  // return post("/cms/v1/element/material/game_provider_selection", params, { hideLoading: true })
}
//游戏进厅
export const launch_game = (params: any) => {
  return post('/api/v1/game/launch_game', params)
}
//游戏进厅 试玩
export const launch_game_demo = (params: any) => {
  return post('/api/v1/game/launch_demo_game', params)
}

//游戏进厅 试玩河马
export const launch_anonymous_game = (params: any) => {
  return post("/api/v1/game/launch_anonymous_game", params)
}

//转账钱包进厅前 厅方用户名注册
export const getTransferRegister = (params: any) => {
  return post('/api/v1/game/transfer/register', params)
}

//查询各厅支持的币种种类
export const queryWebGameProvider = (params: any) => {
  return post('/api/v1/game/provider/query_web_game_provider', params)
}

//判断用户当前是否在神风机台内
export const depositAutoTransInGame = (params: any) => {
  return post('/api/v1/game/info/deposit_auto_trans_in_game', params, { hideLoading: true })
}

//转入金额
export const transferAsyncTransferIn = (params: any) => {
  return post('/api/v1/game/transfer/async_transfer_in', params)
}

/* 收藏的游戏-新增 */
export const gameFavorAdd = (params: any) => {
  return post('/api/v1/game/favor/add', params)
}

/* 收藏的游戏-删除 */
export const gameFavorDelete = (params: any) => {
  return post('/api/v1/game/favor/delete', params)
}

/* 收藏的游戏-查询 */
export const gameFavorQuery = (params: any) => {
  return post('/api/v1/game/favor/query', params)
}

export const getRecentBetGameList = (params: any) => {
  return post('/api/phoenix/game/recentBetGameList', params)
}

/* 获取所有游戏供应商列表 */
export const getAllLobbyCode = (params: any) => {
  return post('/api/phoenix/game/open/getChildLobbyCodeList', params, { hideLoading: true })
}

//获取游戏余额
export const getGameBalance = (params: any) => {
  return post('/api/phoenix/game/game_balance', params, { hideLoading: true })
}

//获取AmigoInfo
export const getAmigoInfo = (params: any) => {
  return post('/api/v1/game/info/amigo/game_list_by_id', params, { hideLoading: true })
}

//获取Ipachin
export const getIpachinInfo = (params: any) => {
  return post('/api/v1/game/info/ipachin/game_list', params, { hideLoading: true })
}

//获取神风
export const getKamikazeInfo = (params: any) => {
  return post('/api/v1/game/info/kamikaze/machine_list', params, { hideLoading: true })
}

//获取EVOID
export const queryEvoGames = (params: any) => {
  return post('/api/v1/game/info/query_evo_games', params, { hideLoading: true })
}

// 榜单用户详情
export const queryGamesMemberDetail = (params: any) => {
  return post('/api/v1/game/order/user_order_detail', params, { hideLoading: true })
}

//获取 首页 pachinko分类
export const homeGetKamikazeList = (params: any) => {
  return post("/api/v1/game/info/all/first_page_machine_info_list", params, { hideLoading: true })
}

// 根据游戏大类查询比游戏分类标签
export const queryProperties = (params: any) => {
  return post("/cms/v1/element/material/game_properties", params, { hideLoading: true })
}

// 根据游戏大类查询最近游戏
export const queryRecentGame = (params: any) => {
  return post("/api/v1/recent/game/query", params, { hideLoading: true })
}
// 删除最近游戏
export const delRecentGame = (params: any) => {
  return post("/api/v1/recent/game/delete", params)
}
// 根据游戏大类查询限红
export const queryGameBetLimit = (params: any) => {
  return post("/v1/callback/betlimit/query_game_betLimit", params, { hideLoading: true })
}

// 根据游戏SEO
export const queryGameSeo = (params: any) => {
  return post("/cms/v1/seo/web/query", params, { hideLoading: true })
}
