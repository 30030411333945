export const notice = {
  tab1: "マーケティング情報",
  tab2: "通知",
  detailBtn: "詳細を見る",
  noShowToday: "今日はもう表示しない",
  empty: "情報なし",
  oneClickRead: "ワンクリックで読む",
  oneClickDelete: "ワンクリックで削除",
  all: "全部",
  message: "サイト内メッセージ",
  notification: "システムからのお知らせ",
  announcement: "重要なお知らせ",
  updataNotification: "アップデート通知",
  messageType: "メッセージのタイプ",
  reset: "リセット",
  confirm: "確認",
  isEnd: "データがいっぱい",
  deleteOne: "このメッセージを本当に削除しますか？",
  deleteAll: "全てのメッセージを本当に削除しますか？",
  notify: "通知",
  deletedSuccessfully: "削除成功",
  showAll: "すべて表示"
} as const
