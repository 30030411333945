'use client'
import { useState, useEffect, useMemo, useRef } from 'react'
import { cn } from '@/lib/utils'
import { useTranslation } from '@/lib/locales/client'
import { useAppStore } from '@/stores/app'
import { Progress } from 'antd'
import BaseDialog from '@/components/base/baseDialog'
import { Autoplay, Navigation, EffectCoverflow, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import { useGameStore } from '@/stores/game'
import styles from '@/components/base/_common_css/addToHomeDialog.module.scss'
import { px2Scalepx } from '@/hooks/useRem'

const AddToHomeDialog = () => {
  const { t } = useTranslation()
  const { isBigScreen, setIsShowAddToHome } = useAppStore()
  const { getMaxGameLen } = useGameStore()
  let circleTimer = null
  // 添加到屏幕数据配置
  const carouselItemsIos = useMemo(
    () => [
      {
        icon: 'ios_1',
        tips1: t('common.account.jsBet'),
        tips2: t('common.account.jsBet')
      },
      {
        icon: 'ios_2',
        tips1: t('addToHome.iosTitle2'),
        tips2: t('addToHome.iosContent2')
      },
      {
        icon: 'ios_3',
        tips1: t('addToHome.iosTitle3'),
        tips2: t('addToHome.iosContent3')
      },
      {
        icon: 'ios_4',
        tips1: t('addToHome.iosTitle4'),
        tips2: t('addToHome.iosContent4')
      },
      {
        icon: 'ios_5',
        tips1: t('addToHome.iosTitle5'),
        tips2: t('addToHome.iosContent5')
      }
    ],
    []
  )
  const carouselItemsAndroid = useMemo(
    () => [
      {
        icon: 'android_1',
        tips1: t('addToHome.androidTitle1'),
        tips2: t('addToHome.androidContent1')
      },
      {
        icon: 'android_2',
        tips1: t('addToHome.androidTitle2'),
        tips2: t('addToHome.androidContent2')
      },
      {
        icon: 'android_3',
        tips1: t('addToHome.androidTitle3'),
        tips2: t('addToHome.androidContent3')
      },
      {
        icon: 'android_4',
        tips1: t('addToHome.androidTitle4'),
        tips2: t('addToHome.androidContent4')
      },
      {
        icon: 'android_5',
        tips1: t('addToHome.androidTitle5'),
        tips2: t('addToHome.androidContent5')
      }
    ],
    []
  )
  const [showHowAddDialog, setShowHowAddDialog] = useState(false)
  const [navActiveIndex, setNavActiveIndex] = useState(0)
  const device = ['iOS', 'Android']
  const swiperRef = useRef(null)

  const [percentNum, setPercentNum] = useState(0)
 
  const [selectIndex, setSelectIndex] = useState(0)
  const [installPrompt, setInstallPrompt] = useState(null)
  const [endIndex, setEndIndex] = useState()
  const refreshKey = useRef(null)

  const onSwiper = (swiper) => {
    swiperRef.current = swiper
  }


  const autoPlay = () => {
    circleTimer = setInterval(() => {
      setPercentNum((perv)=>{
        const nextNum = perv + 0.5
        if (nextNum >= 100) {
          return 100
        }
        return nextNum
      })
    }, 10)
  }

  const handleNav = (i) => {
    setNavActiveIndex(i)
    /* init */
    setPercentNum(0)
    setSelectIndex(0)
    refreshKey.current = new Date().getTime()
  }
  const closeFn = () => {
    setIsShowAddToHome(false)
  }
  const slideNext = () => {
    swiperRef.current?.slideNext()
  }

  const swiperChange = (swipe) => {
    setEndIndex(swipe.realIndex + 1)
    setSelectIndex(swipe.realIndex)
    setPercentNum(0)
  }

  /* computed */
  const rendenList = useMemo(() => {
    return navActiveIndex === 0 ? carouselItemsIos : carouselItemsAndroid
  }, [navActiveIndex])

  const addToHome = () => {
    if (isBigScreen) {
      setIsShowAddToHome(false)
      installApp() // --- 待处理
    } else {
      getMaxGameLen() === 2 ? '' : setNavActiveIndex(1)
      setShowHowAddDialog(true)
      autoPlay()
    }
  }

  const handleNeverShow = () => {
    localStorage.setItem('neverShowDialog', String(true))
    setIsShowAddToHome(false)
  }

  // pc添加到桌面
  const installApp = () => {
    //pwa 待引入
    if(!installPrompt) return
    installPrompt.prompt()
    console.log(installPrompt, 'bababbabab')
    installPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('点击添加')
        handleNeverShow()
      } else {
        console.log('点击取消')
      }
    })
  }

  useEffect(() => {
    if (isBigScreen) {
      window.addEventListener('beforeinstallprompt', (event) => {
        console.log(event, '----')
        console.log(33333333, '------')
        event.preventDefault()
        setInstallPrompt(event)
        // fun()
      })
    } else {
      if (typeof navigator !== 'undefined') {
        // 是否已在pwa
        if (navigator.hasOwnProperty('standalone')) {
          return
        }
      }
      // fun()
    }

    // 注意 外层onUnmounted 放到这里
    return () => {
      clearInterval(circleTimer)
      window.removeEventListener('beforeinstallprompt', () => {})
    }
  }, [])

  return (
    <BaseDialog
      cusClass={styles['warp']}
      visible={true}
      handleClose={closeFn}
    >
      {!showHowAddDialog ? (
        <div className={styles['box']}>
          <div className={styles['center']}>
            <img
              onClick={closeFn}
              className={styles['delete_x']}
              src="/static/images/myAccount/verification/delete_x.png"
              alt=""
            />
            {!isBigScreen ? (
              <img src="/static/images/addToHome/popup_img_h5.png" alt="" className={styles['top']} />
            ) : (
              <img src="/static/images/addToHome/popup_img_pc.png" alt="" className={styles['top2']} />
            )}
          </div>
          <div className={styles['main']}>
            <h2>{t('addToHome.title')}</h2>
            <p>{t('addToHome.content')}</p>
            <p className={styles['add-btn']} onClick={addToHome}>
              {t('addToHome.btn1')}
            </p>
          </div>
        </div>
      ) : (
        <div className={styles['main-box']}>
          {/* 添加到桌面的教程strat */}
          <img
            onClick={closeFn}
            className={styles['delete_x']}
            src="/static/images/myAccount/verification/delete_x.png"
            alt=""
          />
          <div className={styles['nav']}>
            {device.map((item, index) => (
              <p
                key={index}
                onClick={() => handleNav(index)}
                className={styles[navActiveIndex === index && 'active']}
              >
                {item}
              </p>
            ))}
          </div>
          {/* circle-progress  */}
          <div className={styles['circle_progress']}>
            {rendenList.map((item, index) => (
              <div className={styles['step_item']} key={index}>
                <div className={styles['circle_basic']}>
                  <Progress
                    strokeColor="#fc005b"
                    type="circle"
                    format={() => index + 1}
                    percent={selectIndex === index ? percentNum : selectIndex > index ? 100 : 0}
                    strokeWidth={8}
                    width={24}
                  />
                </div>
                {index < rendenList.length - 1 && (
                  <div className={styles['line']}>
                    <div
                      className={styles['active']}
                      style={{
                        width: selectIndex === index ? percentNum + '%' : selectIndex > index ? '100%' : '0'
                      }}
                    ></div>
                  </div>
                )}
              </div>
            ))}
          </div>
          {/*  轮播主体 strat */}
          <div className={styles['container']} key={refreshKey.current}>
            <Swiper
              className={styles['_swiper']}
              modules={[Autoplay, Pagination, EffectCoverflow, Navigation]}
              onSwiper={onSwiper}
              loop
              onActiveIndexChange={swiperChange}
              pagination={{
                clickable: true,
                el: '.custom-pagination'
              }}
              autoplay={true}
            >
              {rendenList.map((item, index) => (
                <SwiperSlide key={index}>
                  <h2>{item.tips1}</h2>
                  <p>{item.tips2}</p>
                  <img src={`/static/images/addToHome/${item.icon}.png`} alt="" />
                  {/* <img src={`/static/images/addToHome/${item.icon}.png`} alt="" /> */}
                </SwiperSlide>
              ))}
            </Swiper>
            <div className={styles['bottom-btn']}>
              {endIndex === 5 ? (
                <p className={styles['last-btn']} onClick={closeFn}>
                  {t('addToHome.btnEnd')}
                </p>
              ) : (
                <div>
                  <p onClick={closeFn}>{t('addToHome.btnSkip')}</p>
                  <p onClick={slideNext}>{t('addToHome.next')}</p>
                </div>
              )}
            </div>
          </div>
          {/*轮播主体 end  */}
        </div>
      )}

      {/* 添加到桌面的教程end */}
    </BaseDialog>
  )
}

export default AddToHomeDialog
