'use client'
import { memo, useEffect, useMemo, useRef, useState } from 'react'
import { useAppStore } from '@/stores/app'
import { useRouter, useParams } from 'next/navigation'
import { cn, decimalFormat, formatGameImg, getBonusName } from '@/lib/utils'
import styles from './index.module.scss'
import dayjs from 'dayjs'
import { useUserStore } from '@/stores/user'
import { numberToKUnit } from '@/lib/filter'
import RewardIcon from '../rewardIcon'
import VerifyBaseDialog from '@/components/common/VerifyBaseDialog'
import { useTranslation } from '@/lib/locales/client'
import { useShallow } from 'zustand/react/shallow'

const RewardCard = (props: any) => {
  const { cardInfo = {}, cardType, useBtns, claim, signList, btns, children } = props
  const { language, commonConfig, setIsShowNotification } = useAppStore(
    useShallow((state) => ({
      language: state.language,
      commonConfig: state.commonConfig,
      setIsShowNotification: state.setIsShowNotification
    }))
  )
  const vipInfo = useUserStore((state) => state.vipInfo)
  const router = useRouter()
  const { lng } = useParams()
  const { t } = useTranslation()
  const [dialogHtml, setDialogHtml] = useState(null)
  const [endTimeStr, setEndTimeStr] = useState('')

  const getImg = (currency: string) => {
    try {
      return `/static/images/header/wallets/currencies/${currency}.png`
    } catch (error) {
      return ''
    }
  }
  const getIconType = (item: any) => {
    if (['bonus', 'quest', 'extra', 'freeSpin'].includes(cardType)) {
      return cardType
    }
    if ([0, 4, 5].includes(item.bonusType)) {
      return { 0: 'extra', 4: 'bonus', 5: 'quest' }[item.bonusType]
    }
    if (cardType === 'special') {
      return 'extra'
    }
    if (cardType === 'vip' && !item.awardProportion) {
      return 'reward'
    }
    if (item.extra) {
      if (item.percentage === '100%') {
        return 'reward;freeSpin'
      } else {
        return 'bonus;freeSpin'
      }
    }
    if (item.awardMoney && (item.awardSpin || item.roundNum)) {
      return 'bonus;freeSpin'
    } else if (item.awardSpin || item.roundNum) {
      return 'freeSpin'
    } else if (item.awardMoney) {
      return 'bonus'
    } else {
      return 'extra'
    }
  }
  const noExpire = (endTime: string) => {
    if (!endTime) return false
    return dayjs(endTime).isAfter(dayjs())
  }
  const getTime = () => {
    let activityDateEnd = dayjs(cardInfo.endTime)
    const now = dayjs()
    const days = activityDateEnd.diff(now, 'day')
    const hours = activityDateEnd.diff(now, 'hour') % 24
    const minutes = activityDateEnd.diff(now, 'minute') % 60
    const seconds = activityDateEnd.diff(now, 'second') % 60
    const d = cardType === 'sign' ? t('promo.sign.nextReward') + '<br />' : days + 'd '
    const str =
      d +
      (hours > 9 ? hours : `0${hours}`) +
      'h:' +
      (minutes > 9 ? minutes : `0${minutes}`) +
      'm:' +
      (seconds > 9 ? seconds : `0${seconds}`) +
      's'
    setEndTimeStr(str)
  }
  const getViewtext = () => {
    if (cardInfo.defaultUrl === '/account/deposit') {
      return t('promo.deposit')
    }
    if (cardType === 'welcome') {
      return t('promo.view')
    } else {
      return t('promo.viewMore')
    }
  }
  const goView = () => {
    if (cardType === 'sign') {
      setDialogHtml(cardInfo.fixedDetails)
    } else {
      router.push(`/${lng}${cardInfo.defaultUrl}`)
      setIsShowNotification(false)
    }
  }
  const isProgress = useMemo(() => {
    return ['bonus', 'quest', 'extra'].includes(cardType)
  }, [cardType])
  const endtimer = useRef(null)
  useEffect(() => {
    if (noExpire(cardInfo.endTime) && !endtimer.current) {
      getTime()
      endtimer.current = setInterval(() => {
        getTime()
      }, 1000)
    }
    return () => {
      endtimer && clearInterval(endtimer.current)
    }
  }, [cardInfo.endTime])

  const getImgUrl = () => {
    return `/static/images/promo/${cardInfo.rewardType}.png`
  }

  return (
    <>
      <div
        className={cn(
          styles['reward-item-box'],
          isProgress ? 'progress' : '',
          cardType === 'freeSpin' ? 'free_spin' : '',
          cardInfo.roundNum && cardType === 'available' ? 'small_free_spin' : ''
        )}
        style={{
          backgroundImage: cardInfo.taskCardPic ? `url(${cardInfo.taskCardPic})` : `url(${getImgUrl()})`
        }}
      >
        <RewardIcon types={getIconType(cardInfo)} />
        {cardType !== 'freeSpin' && cardInfo.rewardType !== 'freespin' && (
          <div className="heard">
            <p>{cardInfo.rewardName || getBonusName(cardInfo, language)}</p>
            {(cardInfo.fixedDetails || cardInfo.fixedDetailsJp) && (
              <img
                src={'/static/images/promo/question.svg'}
                onClick={() =>
                  setDialogHtml(
                    cardInfo.fixedDetails ||
                      cardInfo[language === 'ja-JP' ? 'fixedDetailsJp' : 'fixedDetailsEn']
                  )
                }
              />
            )}
          </div>
        )}
        {/* signList插槽 */}
        {cardType === 'sign' && signList}
        {cardInfo.guessGameName && (
          <div className={cn('guess-game-title', 'text-multi-ellipsis-l2')}>{cardInfo.guessGameName}</div>
        )}

        {(cardType === 'freeSpin' || cardInfo.roundNum) && (
          <div className="spin-warp">
            <img src={formatGameImg(cardInfo, commonConfig)} />
            <div>
              <p>{cardInfo.gameName}</p>
              <span>
                {t('promo.spin')} {cardInfo.roundNum}
              </span>
            </div>
          </div>
        )}

        <div className="info">
          {cardInfo.rank && (
            <div className="rank">
              {t('promo.rank')} {cardInfo.rank}
            </div>
          )}

          {cardInfo.guessGameName && <div className="guess-game-tips">{t('promo.jackpot')}</div>}

          {cardInfo.luckyDrawName && (
            <div className={cn('lucky-draw-name', 'text-multi-ellipsis-l2')}>{cardInfo.luckyDrawName}</div>
          )}

          {cardInfo.extra && cardInfo.percentage ? (
            <div className="grid-award">
              <h3>
                <span>{t('promo.bonus')}</span>
                <span>{cardInfo.percentage}</span>
              </h3>
              <div>
                <p>{t('promo.extra')}</p>
                <span>{cardInfo.extra}</span>
              </div>
            </div>
          ) : cardInfo.awardMoney || cardInfo.awardSpin || cardInfo.awardProportion ? (
            <div className="line-award">
              {Boolean(cardInfo.awardMoney && Number(cardInfo.awardMoney)) ? (
                <>
                  <img className="currency" src={getImg(cardInfo.awardCurrency)} />
                  <i>{decimalFormat(cardInfo.awardMoney, cardInfo.awardCurrency)}</i>
                </>
              ) : cardType === 'mission' ? (
                <i>{cardInfo.awardMoney}</i>
              ) : (
                <></>
              )}

              {Boolean(cardInfo.awardMoney && Number(cardInfo.awardMoney) && cardInfo.awardSpin) && (
                <span>+</span>
              )}

              {Boolean(cardInfo.awardSpin) && (
                <>
                  <span>{t('promo.freeSpin')}</span>
                  <i>{cardInfo.awardSpin}</i>
                </>
              )}
              {Boolean(cardInfo.awardProportion) && <i>{cardInfo.awardProportion} %</i>}
            </div>
          ) : (
            <></>
          )}

          <div className="detal-warp">
            {(cardType === 'freeSpin' || cardInfo.roundNum) && (
              <>
                <div>
                  <span>{t('promo.provider')}</span>
                  <p>{cardInfo.providerName}</p>
                </div>
                <div>
                  <span>{t('promo.expirationDate')}</span>
                  <p>{dayjs(cardInfo.useExpiredDate).format('YYYY-MM-DD HH:mm:ss')}</p>
                </div>
              </>
            )}

            {cardInfo.requiredMultiple && (
              <div>
                <span>{t('promo.wager')}</span>
                <p>{Number(cardInfo.requiredMultiple)}X</p>
              </div>
            )}

            {cardInfo.expireTime && (
              <div>
                <span>{t('promo.evenExpire')}</span>
                <p>{dayjs(cardInfo.expireTime).format('YYYY-MM-DD HH:mm:ss')}</p>
              </div>
            )}

            {cardInfo.defaultUrl === '/account/Loyalty-club' && vipInfo.currentLevelName && (
              <>
                <div>
                  <span>{t('promo.currentVIP')}</span>
                  <p>{vipInfo.currentLevelName}</p>
                </div>
                {vipInfo.nextLevel < 130 && (
                  <div>
                    <span>{t('promo.nextLevelXp')}</span>
                    <p>
                      {numberToKUnit(vipInfo.sumBetAmount) || 0.0} /
                      {+vipInfo.nextLevel === 130
                        ? numberToKUnit(10101386850, 2)
                        : numberToKUnit(vipInfo.nextLevelAmount, 2)}
                    </p>
                  </div>
                )}

                {vipInfo.nextLevel < 130 && (
                  <div>
                    <span>{t('promo.nextLevelReward')}</span>
                    <p>
                      {cardInfo.nextLevelPrizeType === 2 && <img className="currency" src={getImg('USD')} />}
                      <span>{decimalFormat(cardInfo.nextLevelReward, 'USD')}</span>
                    </p>
                  </div>
                )}
              </>
            )}
          </div>
          {cardInfo.rewardType === 'lucky_draw' && children}
        </div>
        <div className="btn-warp">
          {/* btns插槽 */}
          {useBtns ? (
            btns
          ) : (
            <>
              {cardInfo.lastPrizeRecordId || cardInfo.available ? (
                <a onClick={claim}>{t('promo.claim')}</a>
              ) : noExpire(cardInfo.endTime) ? (
                <a dangerouslySetInnerHTML={{ __html: endTimeStr }}></a>
              ) : cardType === 'sign' ? (
                <a onClick={() => router.push(`/${lng}/pachinko`)}>{t('promo.bet')}</a>
              ) : (
                <></>
              )}
              {((!cardInfo.lastPrizeRecordId && !cardInfo.available) || cardType === 'sign') && (
                <a onClick={() => goView()}>{getViewtext()}</a>
              )}
            </>
          )}
        </div>
      </div>
      <VerifyBaseDialog
        visible={!!dialogHtml}
        zIndex={5000}
        width={343}
        type={'common'}
        cusClass="rewardCard"
        closeFn={() => setDialogHtml(null)}
      >
        <div className={styles['rewardDialog']} dangerouslySetInnerHTML={{ __html: dialogHtml }}></div>
      </VerifyBaseDialog>
    </>
  )
}
export default memo(RewardCard)
