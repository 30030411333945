'use client'
import { useState, useEffect, FC, useMemo } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { useWalletStore } from '@/stores/wallet'
import { useUserStore } from '@/stores/user'
import { useAppStore } from '@/stores/app'
import { useTranslation } from '@/lib/locales/client'
import { cn, getCurrencySymbol } from '@/lib/utils'
import { bankTobankOrderOperate } from '@/api/base'
import dayjs from 'dayjs'

const BankToBank = () => {
  const { t } = useTranslation()
  const { bankToBankInfo, updateBankToBankInfo } = useWalletStore(
    useShallow((state) => ({
      bankToBankInfo: state.bankToBankInfo,
      updateBankToBankInfo: state.updateBankToBankInfo
    }))
  )
  const { isBigScreen, isShowFastDeposit, changeFastDialog, SET_GLOBAL_MSG } = useAppStore(
    useShallow((state) => ({
      isBigScreen: state.isBigScreen,
      isShowFastDeposit: state.isShowFastDeposit,
      changeFastDialog: state.changeFastDialog,
      SET_GLOBAL_MSG: state.SET_GLOBAL_MSG
    }))
  )

  const [time1, setTime1] = useState('')
  const [time2, setTime2] = useState('')

  const getTime = (endTime) => {
    let activityDateEnd = dayjs(endTime)
    const now = dayjs()
    const minutes = activityDateEnd.diff(now, 'minute') % 60
    const seconds = activityDateEnd.diff(now, 'second') % 60
    return (minutes > 9 ? minutes : `0${minutes}`) + ':' + (seconds > 9 ? seconds : `0${seconds}` + '')
  }

  const noExpire = (endTime) => {
    if (!endTime) return false
    return dayjs(endTime) > dayjs()
  }

  const handleCopy = (v) => {
    navigator.clipboard.writeText(v)
    SET_GLOBAL_MSG({ flag: true, type: 'success', content: t('deposit.copy') })
  }

  const endFn = () => {
    if (!noExpire(bankToBankInfo.canEndTime)) {
      updateBankToBankInfo({})
    }
  }

  const payAlready = () => {
    bankTobankOrderOperate({
      billNo: bankToBankInfo.billno,
      extSystemType: 'CMS',
      operateType: 1
    }).finally(() => {
      updateBankToBankInfo({})
    })
  }

  let expiraTimer

  useEffect(() => {
    const { willExpiraTime, canEndTime } = bankToBankInfo
    setTime1(getTime(willExpiraTime))
    setTime2(noExpire(canEndTime) ? getTime(canEndTime) : '')
    expiraTimer = setInterval(() => {
      if (noExpire(willExpiraTime)) {
        setTime1(getTime(willExpiraTime))
      } else {
        updateBankToBankInfo({})
        changeFastDialog(false)
      }
      setTime2(noExpire(canEndTime) ? getTime(canEndTime) : '')
    }, 1000)
    return () => {
      clearInterval(expiraTimer)
    }
  }, [])

  return (
    <div className="banktobank">
      {!isBigScreen && !isShowFastDeposit && (
        <div className="change-warp">
          <span
            onClick={() => {
              changeFastDialog(true)
            }}
          >
            {t('deposit.changePayment')}
          </span>
        </div>
      )}
      <div className="main-wrap">
        <header className="header">送金情報は下記</header>
        <div className="countdown">
          <div className="left">お振込み期限</div>
          <div className="right">{time1}</div>
        </div>
        <div className="bank-row-warp">
          <div className="bank-row">
            <div className="bank-row-tip">受取銀行 Bank</div>
            <div className="items-warp">
              <div className="bank-name">{bankToBankInfo.bankName}</div>
              <div
                className="copyBtn"
                onClick={() => {
                  handleCopy(bankToBankInfo.bankName)
                }}
              >
                コピー
              </div>
            </div>
          </div>
          <div className="bank-row">
            <div className="bank-row-tip">支店番号 Branch</div>
            <div className="items-warp">
              <div className="bank-name">{bankToBankInfo.bankBranchName}</div>
              <div
                className="copyBtn"
                onClick={() => {
                  handleCopy(bankToBankInfo.bankBranchName)
                }}
              >
                コピー
              </div>
            </div>
          </div>
          <div className="bank-row" v-if="bankToBankInfo?.branchCode">
            <div className="bank-row-tip">銀行支店コード Branch Code</div>
            <div className="items-warp">
              <div className="bank-name">{bankToBankInfo.branchCode}</div>
              <div
                className="copyBtn"
                onClick={() => {
                  handleCopy(bankToBankInfo.branchCode)
                }}
              >
                コピー
              </div>
            </div>
          </div>
          <div className="bank-row">
            <div className="bank-row-tip">受取口座お名前 Account Name</div>
            <div className="items-warp">
              <div className="bank-name">{bankToBankInfo.bankAccountName}</div>
              <div
                className="copyBtn"
                onClick={() => {
                  handleCopy(bankToBankInfo.bankAccountName)
                }}
              >
                コピー
              </div>
            </div>
          </div>
          <div className="bank-row">
            <div className="bank-row-tip">受取口座アカウント Account Number</div>
            <div className="items-warp">
              <div className="bank-name">{bankToBankInfo.bankAccountNo}</div>
              <div
                className="copyBtn"
                onClick={() => {
                  handleCopy(bankToBankInfo.bankAccountNo)
                }}
              >
                コピー
              </div>
            </div>
          </div>
          <div className="bank-row">
            <div className="bank-row-tip">振込金额 Amount</div>
            <div className="items-warp">
              <div className="bank-name" style={{ color: 'red', fontWeight: 'bold' }}>
                {parseInt(bankToBankInfo.amount)}
              </div>
              <div
                className="copyBtn"
                onClick={() => {
                  handleCopy(parseInt(bankToBankInfo.amount))
                }}
              >
                コピー
              </div>
            </div>
          </div>
          <div className="bank-row">
            <div className="bank-row-tip" style={{ color: 'red', width: '72%' }}>
              備考 *必须
              <span style={{ color: 'blue' }}>同じ振込コードを複数回利用すると入金が遅れます。</span>
            </div>
            <div className="items-warp">
              <div className="bank-name" style={{ color: 'red', fontWeight: 'bold' }}>
                {bankToBankInfo.postscript}[依賴人名]
              </div>
              <div
                className="copyBtn"
                onClick={() => {
                  handleCopy(bankToBankInfo.postscript)
                }}
              >
                コピー
              </div>
            </div>
          </div>
        </div>
        <div className="red-info">
          <ul>
            <li>※送金時は備考に「6桁コード」とフルネームを必ず入力してください。</li>
            <li>&lt;例&gt;【AB1234スズキユキ】</li>
            <li>※ご入金額は必ず表示された金額をお振込みください</li>
          </ul>
        </div>
      </div>
      <div className="bottom-warp">
        <a
          onClick={() => {
            endFn()
          }}
        >
          {t('deposit.cancel')} {time2}
        </a>
        <a
          onClick={() => {
            payAlready()
          }}
        >
          {t('deposit.complete')}
        </a>
      </div>
    </div>
  )
}

export default BankToBank
