'use client'
import { useMemo, useRef, ReactNode, FC, useEffect, memo, useState, useLayoutEffect } from 'react'
import { createCache, extractStyle, StyleProvider } from '@ant-design/cssinjs'
import { usePrevious, useUpdateEffect } from 'ahooks'
import { useServerInsertedHTML } from 'next/navigation'
import { ConfigProvider, App } from 'antd'
import { usePathname, useRouter } from 'next/navigation'
import { useUserStore } from '@/stores/user'
import { useGameStore } from '@/stores/game'
import { useTranslation } from '@/lib/locales/client'
import NotificPanel from '@/components/common/noticePromoPanel'
import jaJP from 'antd/lib/locale/ja_JP'
import enUS from 'antd/lib/locale/en_US'
import { useAppStore } from '@/stores/app'
import { useAuthStore } from '@/stores/authControl'
import { useShallow } from 'zustand/react/shallow'
import { antdTheme, color } from '@/assets/styles/theme/red'
import Config from '@/config/base'
import { getUrlParameter } from '@/lib/utils'
import dynamic from 'next/dynamic'
import Ws from '@/hooks/ws'
import 'dayjs/locale/en'
dayjs.locale('en')
import dayjs from 'dayjs'
import { useRem } from '@/hooks/useRem'
import { trackFristDeposit } from '@/hooks/useTrack'
import { Decrypt } from '@/hooks/encrypt'
// *api
import { getExchangeRate } from '@/api/wallet'
import { logApi } from '@/api/auth'
import { agentAccessRecord } from '@/api/agent'
// *com
import RegSuccess from '@/app/[lng]/(auth)/_components/registerSuccess'
import GlobalLoading from '@/components/base/globalLoading'
import UserDialog from '@/app/[lng]/(auth)'
import GlobalMesg from '@/components/base/globalMessage'
import TipsDialog from '@/app/[lng]/(game)/_components/tipsDialog'
import BaseDialog from '@/components/base/baseDialog'
import Forget from '@/components/account/forget'
import AddToHomeDialog from '@/components/base/addToHomeDialog'
import HomeBonusDialog from '@/components/base/homeBonusDialog'
import NoticeDialog from '@/components/base/noticeDialog'
import NeedKycDialog from '@/components/base/needKycDialog'
import LimitLoginDialog from '@/components/common/systemPage/model/limitLoginDialog'
import PromoNotice from '@/app/[lng]/(promotion)/promo/_components/promoNotice'
import FastDeposit from '@/app/[lng]/account/(pages)/(deposits)/_components/fastDeposit'
import Service from '@/components/layout/common/service'
import PreviewDialog from '@/app/[lng]/activity/betting/previewDialog'
// no ssr
const TotalWin = dynamic(() => import('@/components/layout/common/totalWin'), { ssr: false })
const FollowUs = dynamic(() => import('@/components/layout/common/followUs'), { ssr: false })
const VerifyBaseDialog = dynamic(() => import('@/components/common/VerifyBaseDialog'), {
  ssr: false
})

type IProps = {
  children: ReactNode
  lang: string
}

const ComponentsRegistry: FC<IProps> = ({ children, lang }) => {
  const { t } = useTranslation()
  const cache = useMemo(() => createCache(), [])
  const isServerInserted = useRef(false)
  const pathName = usePathname()
  const rawPath = pathName.replace(/^\/(en|ja)($|\/)/g, '/')
  const preRawPath = usePrevious(rawPath)
  const router = useRouter()
  const websocket = useRef(null)

  const {
    isShowNotification,
    isBigScreen,
    language,
    globalLoading,
    commonConfig,
    routerPush,
    forgetDialog,
    isShowAddToHome,
    memberDialog,
    memberDetail,
    setIsShowAddToHome,
    requestPublicKey,
    setIsBigScreen,
    acccesslimit,
    SET_LANGUAGE,
    queryCommonConfig,
    SET_MEMEBER_DETAIL,
    SET_MEMEBER_DIALOG,
    SET_ROUTER_PUSH,
    getLotteryTicketConfig,
    isShowFastDeposit,
    previewDialogData
  } = useAppStore(
    useShallow((state) => ({
      isShowNotification: state.isShowNotification,
      isBigScreen: state.isBigScreen,
      language: state.language,
      globalLoading: state.globalLoading,
      commonConfig: state.commonConfig,
      routerPush: state.routerPush,
      forgetDialog: state.forgetDialog,
      isShowAddToHome: state.isShowAddToHome,
      memberDialog: state.memberDialog,
      memberDetail: state.memberDetail,
      setIsBigScreen: state.setIsBigScreen,
      acccesslimit: state.acccesslimit,
      SET_LANGUAGE: state.SET_LANGUAGE,
      queryCommonConfig: state.queryCommonConfig,
      SET_MEMEBER_DETAIL: state.SET_MEMEBER_DETAIL,
      SET_MEMEBER_DIALOG: state.SET_MEMEBER_DIALOG,
      SET_ROUTER_PUSH: state.SET_ROUTER_PUSH,
      setIsShowAddToHome: state.setIsShowAddToHome,
      requestPublicKey: state.requestPublicKey,
      getLotteryTicketConfig: state.getLotteryTicketConfig,
      isShowFastDeposit: state.isShowFastDeposit,
      previewDialogData: state.previewDialogData
    }))
  )
  const {
    isLogin,
    currency,
    getBonusTaskList,
    getVipInfo,
    getSignDetail,
    getMissionTagsList,
    getDepositActivity,
    updateJpyToUsd,
    SET_CURRENCY_RATE,
    updatePageVisible,
    updateUserInfo,
    setWebToken,
    showKycDialogType,
    SET_SHOW_KYC_DIALOG,
    getSumFullLifecycle
  } = useUserStore(
    useShallow((state) => ({
      isLogin: state.isLogin,
      currency: state.currency,
      getBonusTaskList: state.getBonusTaskList,
      getVipInfo: state.getVipInfo,
      getSignDetail: state.getSignDetail,
      getMissionTagsList: state.getMissionTagsList,
      getDepositActivity: state.getDepositActivity,
      SET_CURRENCY_RATE: state.SET_CURRENCY_RATE,
      updateJpyToUsd: state.updateJpyToUsd,
      updatePageVisible: state.updatePageVisible,
      updateUserInfo: state.updateUserInfo,
      setWebToken: state.setWebToken,
      showKycDialogType: state.showKycDialogType,
      SET_SHOW_KYC_DIALOG: state.SET_SHOW_KYC_DIALOG,
      getSumFullLifecycle: state.getSumFullLifecycle
    }))
  )
  const {
    regSucInfo,
    logRegVisible,
    resetSuccess,
    openRegister,
    openLogin,
    SET_LOG_REG_VISIBLE,
    SET_RESET_SUCCESS
  } = useAuthStore(
    useShallow((state) => ({
      regSucInfo: state.regSucInfo,
      logRegVisible: state.logRegVisible,
      resetSuccess: state.resetSuccess,
      openRegister: state.openRegister,
      openLogin: state.openLogin,
      SET_LOG_REG_VISIBLE: state.SET_LOG_REG_VISIBLE,
      SET_RESET_SUCCESS: state.SET_RESET_SUCCESS
    }))
  )
  const { getFavoriteGameList, getMemberDetail, queryWebGameProvider, gameTipShow } = useGameStore(
    useShallow((state) => ({
      getFavoriteGameList: state.getFavoriteGameList,
      getMemberDetail: state.getMemberDetail,
      queryWebGameProvider: state.queryWebGameProvider,
      gameTipShow: state.gameTipShow
    }))
  )
  const [antdLocale, setAntdLocale] = useState<any>(lang === 'ja' ? jaJP : enUS)

  useServerInsertedHTML(() => {
    // 避免 css 重复插入
    if (isServerInserted.current) return
    isServerInserted.current = true
    return <style id="antd" dangerouslySetInnerHTML={{ __html: extractStyle(cache, true) }} />
  })

  useUpdateEffect(() => {
    if (routerPush) {
      console.log(routerPush, 666666)
      router.push(`/${lang}${routerPush}`)
      SET_ROUTER_PUSH(null)
    }
  }, [routerPush])

  // 多语言相关
  useEffect(() => {
    // console.log('语言变动', lang)
    SET_LANGUAGE(lang === 'en' ? 'en-US' : 'ja-JP') // store默认语言 默认没填
  }, [lang])

  //首次渲染不加载，避免子组件重新刷新
  useUpdateEffect(() => {
    setAntdLocale(lang === 'ja' ? jaJP : enUS)
  }, [lang])

  // 初始化
  useEffect(() => {
    // phonix接口 初始化, 首次加载时读取, 并缓存
    requestPublicKey()
    acccesslimit() // 访问限制 放这里会多次渲染， 考虑移动到其它页面
    window.addEventListener('resize', setIsBigScreenHandle, false)

    websocket.current = new Ws()
    websocket.current.init()
    queryWebGameProvider()
    updateBodyClass(rawPath, '')
    // updateBodyClass(_this.$route.name, "")
    // 初始化时 如果缓存的弹窗，将其关闭
    SET_LOG_REG_VISIBLE(false)
    setIsBigScreenHandle()
    // 销毁监听事件
    return () => {
      window.removeEventListener('resize', setIsBigScreenHandle)
    }
  }, [])

  // 初始化hooks
  const setIsBigScreenHandle = () => {
    setIsBigScreen(window.innerWidth > 999)
  }

  // 登录状态读取
  useEffect(() => {
    queryCommonConfig() //  判断登录状态 去获取商户信息
    updateUserInfo()
    getCurrencyFn(isLogin ? currency?.currency : 'BTC')
    getDepositActivity()
    isLogin && getMissionTagsList()
    isLogin && getSumFullLifecycle()
    websocket.current && websocket.current.send({ type: 'connection_refresh' })
  }, [isLogin])

  // 登录状态不取store默认值
  useUpdateEffect(() => {
    if (isLogin) {
      getBonusTaskList()
      getFavoriteGameList()
      trackFristDeposit()
      getVipInfo()
      getSignDetail()
      getMissionTagsList()
      getLotteryTicketConfig()
    }
  }, [isLogin])

  // 计算像素大小 待观察 处理
  // useUpdateEffect(() => {
  useEffect(() => {
    useRem()
  }, [isBigScreen])

  // 爆奖弹窗分享链接
  const shareDialogFnc = async () => {
    const { zmkm = '' } = getUrlParameter(window.location.search)
    if (!zmkm) return
    const obj = Decrypt(zmkm, Config.cryptoKey)
    SET_MEMEBER_DETAIL({
      type: 'detail',
      data: JSON.parse(obj)
    })
    getMemberDetail(() => {
      SET_MEMEBER_DIALOG(true)
    })
  }

  // 待观察 nextTick 验证码等相关功能
  useLayoutEffect(() => {
    let link = window.location.href
    // console.log('当前 连接?', rawPath, window.location.href)
    const { agent = '', invite = '', freelogin = '', freename = '' } = getUrlParameter(link)
    // console.log('拆解自动登录', agent, invite)
    //无料站自动登录
    if (freelogin && freename && !isLogin) {
      logApi({
        grantType: 'web_password',
        merchantCode: commonConfig.merchantCode,
        password: freelogin,
        loginName: freename,
        loginType: 3
      }).then((res) => {
        setWebToken(res.data)
        updateUserInfo(res.data.loginName)
      })
    }
    if ((agent || invite) && !link.includes('google') && !link.includes('line')) {
      const code = agent || invite
      if (code) {
        agentAccessRecord({ referralCode: code })
      }
      localStorage.setItem('agent-info-local', code)
    }
  }, [rawPath])

  function getCurrencyFn(sourceCurrency, targetCurrency = 'USD') {
    let currency = sourceCurrency
    if (currency !== 'JPY') {
      currency += ';JPY'
    }
    getExchangeRate({
      tradeType: '01',
      targetCurrency,
      sourceCurrency: currency,
      isFindByCache: 0
    }).then((res) => {
      const { data } = res.data || {}
      if (res.success && data && data.length > 0) {
        data.forEach((item) => {
          if (item.sourceCurrency === sourceCurrency && item.targetCurrency === 'USD') {
            SET_CURRENCY_RATE(item)
          }
          if (item.sourceCurrency === 'JPY' && item.targetCurrency === 'USD') {
            updateJpyToUsd(item.realRate)
          }
        })
      }
    })
  }

  const updateBodyClass = (addClass, removeClass) => {
    removeClass ? window.document.body.classList.remove(removeClass) : ''
    addClass ? window.document.body.classList.add(addClass) : ''
  }

  // 待观察路由 由于register 和 login是已组件的方式存在，所以路由可能不生效，待解决
  useEffect(() => {
    if (rawPath === '/register') {
      if (!isLogin) {
        openRegister(true)
      }
    } else if (pathName === `/login`) {
      // } else if (rawPath === '/login?need=1') {
      if (!isLogin) {
        // console.log('11aaa2')
        openLogin()
      }
    }
    updateBodyClass(rawPath, preRawPath) // 是否为代理连接
  }, [pathName])
  // }, [rawPath])

  // ---  待处理
  // const pageReload = () => {
  //   updatePageVisible(false)
  // }

  // useLayoutEffect(() => {
  //   updatePageVisible(true)
  // }, [])

  // provide("RELOAD", {
  //   pageReload
  // })

  return (
    // layer
    <StyleProvider cache={cache}>
      <ConfigProvider locale={antdLocale} theme={antdTheme}>
        <App>
          {globalLoading && <GlobalLoading />}
          {logRegVisible && <UserDialog />}
          {/* // let websocket  */}
          {children}
          <GlobalMesg />
          {regSucInfo.flag && <RegSuccess />}
          {/* IP封锁弹窗 */}
          <LimitLoginDialog />
          {/* forget dialog */}
          {isBigScreen && forgetDialog && (
            <BaseDialog visible={forgetDialog} width={'420px'}>
              <Forget />
            </BaseDialog>
          )}
          {/* 站内信弹窗 */}
          {isLogin && <NoticeDialog />}
          {/* PromoNotice */}
          {rawPath === '/' && isLogin && <PromoNotice />}
          {/* success dialog */}
          <VerifyBaseDialog
            visible={resetSuccess}
            zIndex={2000}
            width={343}
            closeFn={() => SET_RESET_SUCCESS(false)}
          >
            <div className="p-[16px]">
              <h3 className="text-center text-base font-bold text-black/85">
                {t('user.resetPwd.resetSuccess')}
              </h3>
              <p className="mt-[18px] text-center text-sm leading-[22px] text-black/65">
                {t('user.resetPwd.resetSuccessTips')}
              </p>
              <div
                className="mt-[24px] h-[44px] cursor-pointer rounded-[5px] bg-[#fc005b] text-center text-base font-bold leading-[44px] text-white"
                onClick={() => SET_RESET_SUCCESS(false)}
              >
                {t('user.resetPwd.resetSuccessUnderstand')}
              </div>
            </div>
          </VerifyBaseDialog>

          {gameTipShow && <TipsDialog />}
          {isShowAddToHome && <AddToHomeDialog />}
          {/* 处理爆浆 没登录时 也会弹优惠券信息， 待处理 */}
          {isLogin && <HomeBonusDialog />}

          {/* <LimitLoginDialog /> */}

          {/* 会员投注排行详情 */}
          {memberDialog && (
            <BaseDialog visible={memberDialog} width={'18rem'} handleClose={() => SET_MEMEBER_DIALOG(false)}>
              {memberDetail?.type === 'detail' ? <TotalWin /> : <FollowUs />}
            </BaseDialog>
          )}
          {showKycDialogType && (
            <NeedKycDialog
              level={showKycDialogType} // L1 L2
              close={() => {
                SET_SHOW_KYC_DIALOG('')
              }}
            />
          )}
          {previewDialogData.visible && <PreviewDialog />}

          {isShowFastDeposit && <FastDeposit />}
          <Service />

          {/* <div
      v-if="!userStore.isLogin"
      id="g_id_onload"
      data-context="signin"
      :data-client_id="googleClientId"
      data-callback="googleLoginEndpoint"
      data-close_on_tap_outside="false"
    ></div> */}

          {/* PC右侧 奖励面板 */}
          {isShowNotification && <NotificPanel />}
        </App>
      </ConfigProvider>
    </StyleProvider>
  )
}

export default memo(ComponentsRegistry)
