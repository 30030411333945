import { useState, useEffect, FC, useRef, memo } from 'react'
import { cn } from '@/lib/utils'
import { cmsTemplateApi } from '@/api/base'
import { useAppStore } from '@/stores/app'
import { useUserStore } from '@/stores/user'
import { usePathname } from 'next/navigation'
import styles from '../_common_css/customBtn.module.scss'
import { useShallow } from 'zustand/react/shallow'

const CustomBtn: FC = () => {
  const { isBigScreen, language, updateServiceCloseHintShow } = useAppStore(
    useShallow((state) => ({
      isBigScreen: state.isBigScreen,
      language: state.language,
      updateServiceCloseHintShow: state.updateServiceCloseHintShow
    }))
  )
  const { _hasHydrated, userInfo, isLogin, depositActivityInfo } = useUserStore(
    useShallow((state) => ({
      _hasHydrated: state._hasHydrated,
      isLogin: state.isLogin,
      userInfo: state.userInfo,
      depositActivityInfo: state.depositActivityInfo
    }))
  )
  const [isShow, setIsShow] = useState(false)
  const [isShowIcon, setIsShowIcon] = useState(false)
  const [customerServiceData, setCustomerServiceData] = useState(null)
  const pathName = usePathname()
  const timer = useRef(null)

  const onClose = () => {
    setIsShow(false)
    localStorage.setItem('CUSTOMER_SERVICE_HIDE', 'true')
    onInitFn()

    if (!isBigScreen) {
      updateServiceCloseHintShow(true)
    }
  }

  const isNextDate = () => {
    const key = 'CUSTOMER_SERVICE'
    const nowDate = new Date().toJSON().slice(0, 10)
    const lastDate = localStorage.getItem(key)
    // 只要不是同一日期，就视为下一个自然日
    if (nowDate === lastDate) {
      return false
    }
    localStorage.setItem(key, nowDate)
    localStorage.removeItem('CUSTOMER_SERVICE_HIDE')
    return true
  }

  const onInitFn = () => {
    if (!isNextDate() && !localStorage.getItem('CUSTOMER_SERVICE_HIDE')) {
      setIsShow(true)
    } else {
      setIsShow(false)
    }
  }

  const initIntercom = () => {
    if (process.env.NODE_ENV === 'development' || pathName.includes('fat.com')) return
    if (!window.Intercom) {
      clearTimeout(timer.current)
      timer.current = setTimeout(() => {
        initIntercom()
      }, 5000)
      return
    }
    const zhLan = ['zh_CN', 'zh_TW']
    const paramsMessenger = {
      app_id: 'u21ip4va',
      email: userInfo?.email,
      name: userInfo?.loginName,
      user_id: userInfo?.customerId,
      language_override: zhLan.includes(language) ? language.replace('_', '-') : language.slice(0, 2),
      custom_launcher_selector: '#my_custom_link',
      level: isLogin ? userInfo?.playerLevel : '',
      finishedfirstdepc: isLogin ? !!depositActivityInfo?.extraResult?.depositTime : ''
    }
    window.Intercom('boot', paramsMessenger)
    window.Intercom('update')
  }

  useEffect(() => {
    if (!_hasHydrated) return
    initIntercom()
  }, [isLogin, _hasHydrated])

  useEffect(() => {
    onInitFn()
  }, [customerServiceData])

  useEffect(() => {
    async function getCMSData() {
      const params = {
        moduleCodeList: ['customerService']
      }
      const cmsData: Resp = await cmsTemplateApi(params)
      if (cmsData?.success) {
        setCustomerServiceData(cmsData.data.filter((item) => item.moduleCode === 'customerService')[0])
      }
    }
    getCMSData()

    setTimeout(() => {
      setIsShowIcon(true)
    }, 5000)
  }, [])

  return (
    isShow &&
    customerServiceData?.mediaLibrary?.length && (
      <div id="my_custom_link">
        <div className={cn(styles[!isBigScreen && 'mobile'], styles['service_wrap'])}>
          <img className={styles['service_img']} src={customerServiceData.mediaLibrary[0].fullFilePath} />
          {isShowIcon && (
            <p className={cn(styles[!isBigScreen && 'mobile'], styles['btn_close'])} onClick={onClose}></p>
          )}
        </div>
      </div>
    )
  )
}

export default memo(CustomBtn)
