'use client'
import { ReactNode, memo } from 'react'
import { Layout, FloatButton } from 'antd'
import { cn } from '@/lib/utils'
import styles from './_common_css/common.module.scss'
// *com
import Header from './components/header'
import Footer from './components/footer'

const DesktopLayout = ({ children }: { children: ReactNode }) => {
  return (
    <Layout className={cn(styles['user-info'], 'pc-contrainer')}>
      <Header />
      {children}
      <Footer />
      <FloatButton.BackTop className="!mb-[22px]" />
    </Layout>
  )
}

export default memo(DesktopLayout)
