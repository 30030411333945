//此文件因为会被app 引用，所以会在i18n异步导入多语言文件之前加载，所以直接写对应语言的key 即可，然后在template 模板里使用$i18n.t 格式化即可，如$i18n.t(topMenu.title).非app 引入的文件 可以正常在ts文件里使用$i18n.t
const bettingList: any = [
  {
    icon: 'settledbets',
    title: 'common.account.jsBet',
    path: '/account/settledbets'
  },
  {
    icon: 'unsettledbets',
    title: 'common.account.wjsBet',
    path: '/account/unsettledbets'
  }
]

// 侧边栏配置文件
export default {
  // pc 左侧栏
  accountSliderPC: [
    {
      title: '',
      key: 'firstLayer',
      children: [
        // Loyalty Club
        {
          icon: 'vip',
          title: 'common.account.vip',
          path: '/account/Loyalty-club',
          key: 'vip'
        },
        // Favorite
        {
          icon: 'favorite',
          title: 'common.account.favorite',
          path: '/account/favorite',
          key: 'favorite'
        },
        // Multi Window Mode
        {
          icon: 'multimode',
          title: 'common.account.multimode',
          path: '/account/multimode',
          key: 'multimode'
        },
        // Message Center
        {
          icon: 'favorite',
          title: 'common.account.notice',
          path: '/account/notice',
          key: 'notice'
        }
      ]
    },
    {
      title: 'common.account.accountTitle',
      key: 'account',
      children: [
        {
          icon: 'account',
          title: 'common.account.accountVerify',
          path: '/account/verification',
          key: 'verification'
        },
        {
          icon: 'wallet',
          title: 'common.account.wallet2',
          path: '/account/wallet',
          key: 'wallet'
        },
        {
          icon: 'point-icon-color',
          title: 'common.account.voucher',
          path: '/account/voucher',
          key: 'voucher'
        },
        {
          icon: 'password',
          title: 'common.account.setPwd',
          path: '/account/password',
          key: 'password'
        },
        {
          icon: 'preferences',
          title: 'common.account.selfSet',
          path: '/account/exclusion',
          key: 'exclusion'
        }
      ]
    },
    {
      title: 'common.account.vaultTitle',
      key: 'vault',
      children: [
        {
          icon: 'deposit',
          title: 'common.account.despoit',
          path: '/account/deposit',
          key: 'deposit'
        },
        {
          icon: 'withdraw',
          title: 'common.account.withdraw',
          path: '/account/withdraw',
          key: 'withdraw'
        }
      ]
    },
    {
      title: 'common.account.statisticsTitle',
      key: 'statistics',
      children: [
        {
          icon: 'history',
          title: 'common.account.statements',
          path: '/account/statements',
          key: 'statements'
        },
        {
          icon: 'settledbets',
          title: 'common.account.jsBet',
          path: '/account/settledbets',
          key: 'settledbets'
        },
        {
          icon: 'unsettledbets',
          title: 'common.account.wjsBet',
          path: '/account/unsettledbets',
          key: 'unsettledbets'
        },
        {
          icon: 'bonus',
          title: 'common.account.bounsHistory',
          path: '/account/bonus',
          key: 'bonus'
        }
      ]
    }
  ],

  accountSliderH5: [
    {
      title: 'common.account.funks',
      key: 'funds',
      children: [
        {
          class: 'despoit-btn',
          icon: 'deposit',
          title: 'common.account.despoit',
          path: '/account/deposit'
        },
        {
          class: 'withdraw-btn',
          icon: 'withdraw',
          title: 'common.account.withdraw',
          path: '/account/withdraw'
        },
        {
          class: 'vip-btn',
          icon: 'vip',
          title: 'common.account.vip',
          path: '/account/Loyalty-club',
          hiddenH5: true
        }
      ]
    },
    {
      title: 'common.account.jl',
      key: 'history',
      children: [
        {
          icon: 'bonus',
          title: 'common.account.point',
          path: '/account/bonus'
        },
        {
          icon: 'voucher',
          title: 'common.account.voucher',
          path: '/account/voucher'
        },
        {
          icon: 'history',
          title: 'common.account.statements',
          path: '/account/statements'
        },
        {
          icon: 'wallet',
          title: 'common.account.wallet2',
          path: '/account/wallet'
        }
      ]
    },
    {
      title: 'common.account.betStatus',
      key: 'betting',
      children: bettingList
    },
    {
      title: 'common.account.myAccount',
      key: 'account',
      children: [
        {
          icon: 'personal',
          title: 'common.account.accountInfo',
          path: '/account/personal'
        },

        {
          icon: 'msg_center',
          title: 'common.account.notice',
          path: '/account/notice'
        },

        {
          icon: 'recFriend',
          title: 'common.account.recFriend',
          path: '/account/recFriend'
        },
        {
          icon: 'password',
          title: 'common.account.setPwd',
          path: '/account/password'
        },
        {
          icon: 'account',
          title: 'common.account.accountVerify',
          path: '/account/verification'
        },
        {
          icon: 'preferences',
          title: 'common.account.selfSet',
          path: '/account/exclusion'
        },
        {
          icon: 'vip',
          title: 'common.account.vip',
          path: '/account/Loyalty-club',
          hiddenPc: true
        }
      ]
    }
  ],
  //   {
  //     class: 'despoit-btn',
  //     icon: 'deposit',
  //     title: 'common.account.despoit',
  //     path: '/account/deposit'
  //   },
  //   {
  //     icon: 'bonus',
  //     title: 'common.notificTab.reward',
  //     path: '/reward'
  //   },
  //   {
  //     class: 'withdraw-btn',
  //     icon: 'withdraw',
  //     title: 'common.account.withdraw',
  //     path: '/account/withdraw'
  //   },
  //   {
  //     icon: 'vip',
  //     title: 'common.account.vip',
  //     path: '/account/Loyalty-club'
  //   },
  //   {
  //     icon: 'wallet',
  //     title: 'common.account.wallet2',
  //     path: '/account/wallet'
  //   },
  //   {
  //     icon: 'voucher',
  //     title: 'activity.ticket.freeSpins',
  //     path: '/reward'
  //   },
  //   {
  //     icon: 'history',
  //     title: 'common.account.history',
  //     path: '/account/globalSetting/?history=1'
  //   }

  //   // {
  //   //   icon: "preferences",
  //   //   title: "common.account.recFriend",
  //   //   path: "/account/recFriend"
  //   // },
  // ],
  accountSliderH5login: [
    {
      class: 'is-login',
      icon: 'global',
      title: 'common.account.globalSettings',
      path: '/account/globalSetting',
      needLogin: true
    },
    {
      class: 'is-login',
      type: 'common.account.Approved',
      icon: 'Verifications',
      title: 'common.account.accountVerify',
      path: '/account/verification',
      needLogin: true
    }
  ],
  accountSliderH5Nologin: [
    {
      class: 'samll',
      icon: 'playPachinko',
      title: 'common.account.playPachinko',
      title2: 'common.account.PachinkoVideo',
      path: '/pachinko',
      pathVideo: '/videoList'
    },
    {
      class: 'samll',
      icon: 'guide',
      title: 'common.tabber.guide',
      path: '/help/guide'
    },
    {
      class: 'samll',
      icon: 'responsible',
      title: 'common.game.responsible',
      path: '/responsible'
    }
  ]
  // title: [
  //   {
  //     icon: 'checkin',
  //     title: '签到活动',
  //     path: '/reward?tagCode=questBonus'
  //   },
  //   {
  //     icon: 'favorites',
  //     title: '我的收藏',
  //     path: '/favorite'
  //   },
  //   {
  //     icon: 'redemption',
  //     title: '空投兑换码',
  //     path: '/account/voucher'
  //   },
  //   {
  //     icon: 'multimachinemode',
  //     title: '多机模式',
  //     path: '/account/multimode'
  //   },
  //   {
  //     icon: 'message',
  //     title: '站内信',
  //     path: '/account/notice'
  //   }
  // ]
}
