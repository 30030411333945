'use client'
import { useState, FC, useEffect, memo } from 'react'
import { Modal } from 'antd'
import { cn } from '@/lib/utils'
import styles from './_common_css/baseDialog.module.scss'

type IProps = {
  width?: any
  visible: boolean
  cusClass?: string
  isFullScreen?: boolean // false
  needCenter?: boolean // true
  zIndex?: number
  children?: any
  handleClose?: (flag?: boolean) => void
}

const BaseDialog: FC<IProps> = (props) => {
  const [fullScreenClass, setFullScreenClass] = useState('')

  useEffect(() => {
    setFullScreenClass(props.isFullScreen ? 'full-modal' : 'not-full')
  }, [props.isFullScreen])

  return (
    <Modal
      className={cn(styles['input-box'], 'base-dialog', props.cusClass)}
      open={props.visible}
      centered={props.needCenter || true}
      wrapClassName={styles[fullScreenClass]}
      closable={false}
      footer={null}
      keyboard={false}
      style={{ padding: 0 }}
      width={props.width}
      onCancel={() => props.handleClose}
      zIndex={props.zIndex || 2000}
      getContainer={false} // 处理包裹的表单不会报无初始化
      forceRender
    >
      {props.children}
    </Modal>
    // wrapClassName 指mask
  )
}

export default memo(BaseDialog)
