/*
 * @Author: VIOLA viola@acclub.io
 * @Date: 2023-05-26 14:50:31
 * @LastEditors: VIOLA viola@acclub.io
 * @LastEditTime: 2023-05-29 16:21:20
 * @FilePath: /packet-web/src/api/base.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { post, get } from './config/config'
import Config from '@/config/base'

/* 国家集合信息 */
export const getCountryInfo = () => get('/api/phoenix/user/open/common/countryCode')

// 查询用户IP
export const requestUserIp = () => get('/api/phoenix/user/open/common/requestIp')

// 查询常规模板 (列表)
export const cmsTemplateApi = (params: QueryCMSModules) => {
  params.themeCode = Config.themeCode
  return post('/api/phoenix/cms/open/cmsTemplate/list', params, { hiddeErr: true, hideLoading: true })
}

// 查询模板 (分页)
export const cmsTemplatePageApi = (params: any) => {
  params.queries.themeCode = 'REDJPCARTOON'
  return post('/api/phoenix/cms/open/cmsTemplate/page', params, { hiddeErr: true, hideLoading: true })
}

// 查询模板 (详情)
export const cmsTemplateDetailApi = (params: any) => {
  params.themeCode = 'REDJPCARTOON'
  return post('/api/phoenix/cms/open/cmsTemplate/detail', params, { hiddeErr: true, hideLoading: true })
}

// 查询优惠详情
export const cmsPromoDetail = (params: any) => {
  return post('/api/phoenix/cms/open/cmsTemplate/detail', params, { hiddeErr: true, hideLoading: true })
}

/* public-key-获取 */
export const getPublicKey = () => post('/api/phoenix/public-key')

/**
 * 获取埋点列表
 * @param params
 * @returns
 */
export const checkReportStatus = (params?: any) => {
  return post('/api/v1/player/checkReportStatus', params, { hiddeErr: true, hideLoading: true })
}

/**
 * 埋点消费
 * @param params
 * @returns
 */
export const updateReportStatus = (params: any) => {
  return post('/api/v1/player/updateReportStatus', params, { hiddeErr: true, hideLoading: true })
}

export const successfulDepositQuery = (params: any) => {
  return post('/api/v1/deposit/successful_deposit_query', params, { hiddeErr: true, hideLoading: true })
}

export const successfulDepositQueryUpdate = (params: any) => {
  return post('/api/v1/deposit/successful_deposit_time_update ', params, {
    hiddeErr: true,
    hideLoading: true
  })
}

export const bankTobankOrderOperate = (params: any) => {
  return post('/api/v1/deposit/orderOperate ', params, { hiddeErr: true })
}

// 查询指定路由的网页元素
export const cmsElementsInfo = (params: any) => {
  params.themeCode = Config.themeCode
  return post('/api/phoenix/cms/open/elements/info', params, { hiddeErr: true, hideLoading: true })
}

//查询视频列表
export const videoQuery = (params: any) => {
  return post('/api/phoenix/cms/open/video/query', params, { hiddeErr: true })
}
//查询视频标签 下拉框使用
export const videoTagList = () => {
  return post('/api/phoenix/cms/open/video/tagList', {}, { hiddeErr: true, hideLoading: true })
}
//点击播放
export const videoPlayClick = (params: any) => {
  return post('/api/phoenix/cms/open/video/play', params, { hiddeErr: true, hideLoading: true })
}
//更多视频
export const videoMore = (params: any) => {
  return post('/api/phoenix/cms/open/video/more', params, { hiddeErr: true })
}

//视频详情
export const videoDetail = (params: any) => {
  return post('/api/phoenix/cms/open/video/detail', params, { hiddeErr: true })
}

//商户配置信息获取
export const getCommonConfig = (params: any) => {
  return post('/api/v1/common/config', params, { hiddeErr: true })
}

//用户信息获取
export const getPlayerConfig = (params: any) => {
  return post('api/v1/player/get_player_config', params, { hiddeErr: true, hideLoading: true })
}

/**
 * 检查ip是否有限制
 */
export const checkIp = () => {
  return post(`/api/v1/common/checkIp`)
}

/**
 * @description 生成滑块验证码
 */
export const generateSlider = (params: any) => {
  return post(`/api/v1/common/generate_slider`, params) // 测试loading
}

/**
 * @description 验证滑块验证码
 */
export const queryValidateSlider = (params: any) => {
  return post(`/api/v1/common/validate_slider`, params)
}

/**
 * 检查ip是否有限制
 */
export const commonCheckIp = () => {
  return post(`/api/v1/common/checkIp`)
}

/**
 * 服务端 基本API调用
 */

export function getBaseUrl() {
  if (process.env.NEXT_PUBLIC_SITE_URL && process.env.NODE_ENV === 'production') {
    return `${process.env.NEXT_PUBLIC_SITE_URL}`
  }

  if (typeof window !== 'undefined') {
    return `http://localhost:${process.env.NEXT_PUBLIC_PORT ?? 3001}`
    return `${process.env.NEXT_PUBLIC_SITE_URL}`
  }

  if (typeof window !== 'undefined') {
    return `http://localhost:${process.env.NEXT_PUBLIC_PORT ?? 3001}`
  }

  // assume localhost
  return `http://localhost:${process.env.NEXT_PUBLIC_PORT ?? 3001}`
}
