export const user = {
  badge: 'Badge',
  details: 'Details',
  comingSoon: 'coming soon',
  vipProgress: 'Your VIP Progress',
  fastDeposit: 'Fast Deposit',
  tips: 'Complete the puzzle to SIGN UP',
  termsChecked: 'Please check the terms of service',
  setup: 'Quick Setup',
  once: 'User names can be changed only once.',
  save: 'Please save your username and password.',
  uLogin: 'LOGIN',
  login: 'Log In',
  signup: 'Sign Up',
  uRegister: 'SIGN UP',
  uRegister2: 'Sign up',
  uLogin2: 'SIGN IN',
  play: 'Play now',
  confirm: 'Confirm',
  headUser: {
    lastLogin: 'Last login',
    credit: 'Credit',
    bonus: 'Bonus',
    toDeposit: 'Deposit',
    withdraw: 'Withdraw',
    gameLoading: 'game in progress...',
    wager: 'Next level wager target',
    registrationTime: 'Registration time',
    EditAvatar: 'Edit avatar',
    EditAvatarTip: 'Select an avatar as a profile picture',
    coming: 'Rakeback coming Soon'
  },
  resetPwd: {
    resetSuccess: 'Password Reset Successful!',
    resetSuccessTips:
      "To make sure your account is secure, you'll be logged out once you set the new password.",
    resetSuccessUnderstand: 'I See',
    email: 'EMAIL',
    title: 'Reset password',
    tips: 'If you have forgotten or lost your password, please use the form below to reset it.',
    sendTip: 'Please enter your QueenCasino E-Mail address and we will send you a new password shortly.',
    sendedTips:
      'Your password reset request was accepted. You will receive an email with instructions on how to reset your password.'
  },
  authDialog: {
    quick: 'One-Click',
    dLogin: 'Login',
    dReg: 'Sign Up',
    dThird: 'Or',
    regPhone: 'Mobile',
    regEmail: 'Email',
    phonePlace: 'Phone Number',
    EmailPlace: 'Email',
    refCodePlace: 'Referral Code (Optional)',
    authPassword: 'Password',
    logPW: 'Password',
    logCode: 'Code',
    userNamePlac: 'Username or Email',
    codePlace: 'Phone',
    authCodePlace: '6-Digit Code',
    termsTips: `I certify that I am at least 18 years old and agree to the terms and conditions outlined in the "<a class="le_fh" href="/static/termsAndConditions"  target="_blank">Terms & Conditions</a>".`,
    termsTips1:
      "I agree to the <span  style='color:#2196F3;cursor: pointer;'>Terms of Service</span> & Confirm I am at least 18 years old.",
    dLeftTips1: "WELCOME TO <span style='color: #F44336'>Queen Casino</span>",
    dLeftTips2: 'START YOUR GAME JOURNEY NOW',
    sendTips: "Didn't receive the code?",
    rememberAcc: 'Remember me',
    forgotPS: 'Forgot password?',
    sendSuc: 'Successfully sent verification code.',
    regSucText: 'Register Successfull',
    logSucText: 'Login Successfull',
    thirdLoginFailedText: 'Third party login failed',
    toRegist1: 'New to QueenCasino?',
    toRegist2: 'Create account',
    toRegist3: 'Already have an account?',
    binded: 'If not binded please, ',
    contact: 'Contact Live Support',
    send: 'SEND',
    loginWith: 'Log in directly with'
  },
  sliderCaptcha: {
    slideReg: 'Slide completed and certified register',
    slideLog: 'Slide completed and certified login',
    suc: 'Successfully verified',
    use2: "Click on 'Login' button to perform Login",
    use3: "Click on 'Confirm' to complete Password Change",
    tip1: 'Drag the slider to merge the image correctly',
    timeOut: 'Time-Out',
    resetTip: "Please click 'Refresh' to try again",
    verifySuccess: 'Successfully verified',
    fail: 'Fail to authenticate',
    filterTxt: 'Slide to complete the puzzle'
  },
  regSuc: {
    newWelcome: 'Welcome to Queen Casino',
    newUNPlac: 'Create New Username',
    nwPWPlac: 'Create New Password',
    editOnce: 'Can be changed only once',
    unTips: 'letters & numbers',
    pwTips: 'Use 6-32 characters with a mix of letters, numbers & symbols',
    confirm: 'Confirm'
  },
  codeTips: {
    codeTip1: 'Please check your mobile number is correct',
    codeTip2: 'Delivery delayed due to connection constraint',
    codeTip3: ' Please check your Email is valid',
    codeTip4: 'Please check the spam folder of your inbox'
  },
  loginSafe: {
    safeVerify: 'Security Verification'
  },
  saveAccount: {
    save: 'Take a screenshot of this page to save.',
    useName: 'Username: ',
    password: 'Password: '
  },
  logOthes: 'Your account has been logged into from another device.',
  lostPop: {
    phone_register_8: 'Lost 2FA',
    phone_register_11: 'Please confirm if your 2FA is functioning properly.',
    phone_register_12: 'Please reset your 2FA.',
    phone_register_13: 'If you have any quesitons, please',
    phone_register_30: 'contact',
    phone_register_31: 'us',
    phone_register_32: 'Please enter verification code',
    phone_register_33: 'Verification code must be filled',
    phone_register_34: 'Verification code format is wrong',
    v2fTitle: '2FA Authentication',
    lockUserMsgs1:
      'Because you signed in from a new device or new IP, to ensure your account safety, we suggest connecting with customer service to unlock your account and verify the email.',
    lockUserMsgs2: 'For account security, please contact customer service to unlock and link your email',
    haveSendCodePhone: 'We have sent a message to',
    haveSendCodeEmail: 'We have sent an email to',
    userName: 'UserName',
    nameRepeat: 'Username Already Exists',
    emailRepeat: 'Email already exists'
  }
} as const
