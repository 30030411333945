'use client'
import { ReactNode, memo } from 'react'
import { Layout, FloatButton } from 'antd'
// *com
import Header from './components/header'
import Footer from './components/footer'
import Tabbar from './components/tabbar'
import Sidebar from './components/sidebar'

const MobileLayout = ({ children }: { children: ReactNode }) => {
  return (
    <Layout>
      <Header />
      {children}
      <Footer />
      {/* 底部边栏和侧边栏 */}
      <Tabbar />
      <Sidebar />
      <FloatButton.BackTop className="!mb-[22px]" />
    </Layout>
  )
}

export default memo(MobileLayout)
