export const deposit = {
  selectCurrency: 'ご希望の通貨を選択してください',
  gk: '数秒でクリプト獲得',
  price: '通貨価額（為替レート）',
  preview: 'プレビュー',
  select: '簡単選択',
  reminder: 'お知らせ',
  firstDeposit: '初回入金',
  finshInfo: '情報を完了',
  getAmount: 'ボーナスを受け取る',
  amountText: '金額',
  deposit: '入金',
  depositType: '入金方法を選択してください',
  tip1: '入金ボーナスを受け取るには事前の申請が必要です。',
  tip2: '入金ボーナスを受け取りたい場合は、ご入金の前にボーナスページから申請する必要があります。',
  tip3: 'おトクなボーナスは入金ボーナス以外にもたくさんありますので、ぜひチェックしてみてください。',
  tip4: '※意図しないボーナスを獲得してしまった場合は、ご遊戯せずにカスタマーサポートまでご連絡ください。',
  tip5: 'ご遊戯後のボーナス削除は出来なくなっております。',
  ok: ' ボーナスを確認',
  banqtitle: 'BANK',
  banqtips: '{bank}を利用することで、お客様のオンラインバンクやATMからご入金が可能です。',
  time: '銀行振込対応可能時間帯',
  min: '最低入金額：',
  max: '最大入金額：',
  amount: '金額',
  confirm: '入金する',
  disctitle: 'クレジットカード',
  disctips: '海外で発行したクレジットカードはご利用できません。',
  cardNnm: 'クレジットカード番号',
  month: '月',
  year: '年',
  lastName: '名',
  firstName: '姓',
  city: '市区町村／番地',
  status: '都道府県',
  postalCode: '郵便番号',
  country: '国',
  phoneNumber: '電話番号',
  email: 'メールアドレス',
  ecopaytitle: 'エコペイズ',
  ecopaytips: 'エコペイズ アカウントが必要です。',
  ecopayurl: 'エコペイズ ウェブサイト',
  iwallettitle: 'i-Wallet',
  iwallettips: 'i-Wallet アカウントが必要です。',
  orientaltitle: '有効なオリエンタルウォレットアカウントが必要です。You can create one at',
  orientalurl: 'OrientalWallet Website',
  notEnough: '支払い限度額は{{currency}}{{min}} - {{currency}}{{max}}です',
  maxEnough: '金額が超過しております',
  day: '平日・土曜・日曜・祝日',
  hour: '24時間即時',
  japan: '(日本時間)',
  err: '必須項目',
  kycTips1: 'KYCが完了すると、ご利用いただける入金方法が増えます。',
  kycTips2: 'また、出金をスピーディーに処理するために、KYCは早めに完了してください。',
  userName: '口座名義(カタ カナ)',
  userNameText: '預金者名を入力してください',
  copy: '正常にコピー',
  certain: '本人確認には、以下の書類2点が必要になります。',
  verify: '下記の書類をアップロードしてください。',
  id: '写真付き身分証明書 1点（マイナンバーカード / 運転免許証 / パスポート / 写真付き住民基本台帳カード）',
  bill: '発行日から3ヶ月以内の住所確認書類 1点（住民票 / 水道・電気・ガス代・電信通話の請求書でご住所、発行日がわかるもの / 郵送されたクレジットカードご利用明細書）',
  // valid: "(Only valid for documents issued within 3 months)",
  file: 'ファイル形式：',
  types: '.gif, .jpeg, .jpg, .png, .tif, .tiff',
  maximum: '(最大ファイルサイズ 5 MB)',
  formats: 'また、出金の際にはご入金方法により、別の確認書類が必要になる場合がございます。',
  black: '- クレジットカード画像',
  pdf: '- 各ウォレット口座のスクリーンショット など',
  dialogTitle: '個人情報を正しくご入力ください ',
  dialogText1: '本名と携帯電話番号を入力して下さい。',
  dialogFirstName: '名',
  dialogLastName: '姓',
  mobile: '携帯番号',
  verification: '認証コード',
  open: '認証コードが受け取れない?',
  upload: '本人確認書類をアップロードしてください。',
  idCard: '身分証明書',
  address: '住所確認書類',
  what: 'ID確認に必要な書類は何ですか？',
  card: 'カード名義人は、ユーザー名と同一であること',
  btn: '確認',
  trouble: 'ご不明な点がございましたら、弊社オンラインカスタマーサービスまでお',
  team: '問い合わせ下さい。',
  help: '',
  send: '送信',
  enterFirstName: '名を入力してください',
  enterLastName: '姓を入力してください',
  enterPhone: '電話番号を入力してください',
  enterEmail: '「メールアドレスを入力してください',
  enterCode: '確認コードを入力してください',
  tips: "{{currency}}({{protocol}})を入金していることを確認し。 情報が不正確であったり、間違っている場合、送金した資産を失う可能性があります。 また、不正確な情報、メモ/タグの記入がない場合、返金手続手数料として <span style='white-space: nowrap;'>200 USDT</span> が差し引かれます。",
  choose: 'チャネル',
  realName: '本名を入力してください',
  fail: '入金に失敗しました',
  warn1: 'Onramper Payでクリプトを購入すると、自動的に口座に入金されます',
  warn2: '支払う',
  warn3: '取得',
  warn4: '残高 ≈ {{amount}} USD',
  warn5:
    '免責事項：QueenCasinoを離れ、第三者プラットフォームに移動します。仮想通貨を購入する前に本人確認手続きを行う必要があります。',
  warn6: '{{currency}}の支払い限度額は{{min}} - {{max}}です',
  tag: 'タグ/メモ：',
  xrp: '注意：XRP(リップル)を入金していることを確認してください。情報が不正確であったり、間違っている場合、送金した資産を失う可能性があります。 また、不正確な情報、メモ/タグの記入がない場合、返金手続手数料として 200 USDT が差し引かれます。',
  limit: '最小/最大: ',
  fee: '手数料',
  maxText: '最大: ',
  totalAmount: '合計金額(手数料を含む):',
  more: 'もっと',
  prohibit: 'アカウント例外：当サイトオンラインサービスにご連絡ください',
  wrong: '間違った形式',
  depositer: '入金成功',
  depositerErr: '入金の送信に失敗しました。ページを更新して再試行してください。',
  userID: 'ID',
  password: 'パスワード',
  cardNumber: 'カード番号',
  cvv: 'CVV',
  date: '有効期限 (MM/YYYY)',
  countrys: '国',
  province: '都道府県',
  citys: '街',
  name: '銀行名',
  first: '最初に金額を入力してください',
  receive: '受け取れる額を確認する',
  choosePrize: '(ボーナスを選択する)',
  details: '詳細を見る',
  nochoose: '未選択',
  bonus: 'ボーナス',
  finally: '合計残高',
  click: 'クリックして受理',
  have: '{amount}ドルの新しいオファーあり',
  fast: '最速の',
  condition: 'クレジットカードを有効化',
  times: '入金回数',
  moon: 'ご登録からの日数',
  title: '時間は申請から注文完了までの10件の平均値として計算されます。',
  txt: '選択済み',
  realLastName: '本姓を入力してください',
  realFirstName: '本名を入力してください',
  dates: '生年月日を選択してください',
  cou: '国を選択してください',
  cit: '市区町村を入力してください',
  addrress: '詳細な住所を入力してください',
  codeText: '郵便番号を入力してください',
  last: '最後の入金',
  fastg: 'クイック入金',
  tips3: '入金後、ポップアップウィンドウを閉じてお待ちください',
  find: 'CVV/CVNをどう見つけるか？',
  also: 'CVV/CVNまたはCVC/CIDとも呼ばれるセキュリティーコードは以下場所に記載されています：',
  tf: 'VISA/Mastercard/JCBの裏面（下3桁）',
  tb: 'またはAMEXカードの表面（4桁）',
  font: '裏面の数字3桁',
  fontTxt: 'Visa / Mastercard / JCB',
  back: '表面の数字4桁',
  backTxt: 'American Express (AMEX)',
  maintain: 'メンテナンス',
  needKyc1: "まずはレベル L1 認証を完了させてください。",
  needKyc: 'まずはレベル L1 および  2 認証を完了させてください。',
  needKyc5s: '<span>{{num}}</span> 秒以内に認証ページに進みます。',
  needKycBtn: 'すぐに確認する',
  vegaTip: 'ベガウォレットのユーザーID·パスワードを入力します',
  vegaLink: '使い方(必読)',
  bankTips: '入金成功率を向上させる方法？',
  cardErr: 'カード番号が間違っています。確認して再度入力してください。',
  cardNoSup: '現在、この種類のクレジットカードはお取り扱いできません。',
  vegaWalletTutorialTitle: 'Vega Wallet 入金方法',
  vegaWalletTutorialTitleHtml: 'Vega Wallet 入金方法',
  purchase: '購入',
  goBack: '戻る',
  registration: 'ベガウォレット登録',
  goDeposit: '今すぐ入金',
  chooseCurrency: '通貨を選択',
  confirmAgain: 'もう一度ご確認ください',
  bankTipTitle:
    "銀行振込での入金時<span  style='color:#d9004e;cursor: pointer;'><br/> 「備考欄」</span> ご入力をお忘れなく。",
  bankTipMain:
    "銀行振込での入金を選択された場合、送金時には備考欄に必ず送金情報画面、備考フィールドに表示されている 「6桁のコード+振込人名」をご入力ください。<span  style='color:#d9004e;cursor: pointer;'>例＞123456スズキユキ</span> こちらご入力されていませんと正しく入金処理を行うことができませんのでご注意ください。",
  changePayment: '支払方法を変更する',
  cancel: 'キャンセル',
  complete: '支払完了',
  selecting: '選択する',
  iSee: '了解しました',
  buyCrypto: '仮想通貨購入',
  cryptoSelect: 'お支払い方法を選択',
  exchangeAmount: '交換金額',
  actualAmount: '実際に受け取った金額',
  seachBank: '銀行を検索する'
} as const
