'use client'
import { Skeleton } from 'antd'
import { useAppStore } from '@/stores/app'
import { cn } from '@/lib/utils'
import styles from './index.module.scss'
import { Swiper, SwiperSlide } from 'swiper/react'

import { memo, useMemo, useRef, useState } from 'react'
import { useTranslation } from '@/lib/locales/client'
import { useShallow } from 'zustand/react/shallow'
import { Autoplay, EffectCards, Pagination } from 'swiper/modules'

const RewardSwiper = (props: any) => {
  const { cardType, swipData, spinning, render } = props
  const { t } = useTranslation()
  const { isBigScreen, rewardshowSwiper } = useAppStore(
    useShallow((state) => ({
      isBigScreen: state.isBigScreen,
      rewardshowSwiper: state.rewardshowSwiper
    }))
  )
  const showSwiper = useMemo(() => {
    return !isBigScreen && rewardshowSwiper
  }, [isBigScreen, rewardshowSwiper])
  const swiperRef = useRef(null)
  const isProgress = useMemo(() => {
    return ['bonus', 'quest', 'extra'].includes(cardType)
  }, [cardType])

  const onSwiper = (swiper) => {
    swiperRef.current = swiper
  }

  const maskHight = useMemo(() => {
    if (swipData.length < 2) {
      return 0
    } else if (swipData.length === 2) {
      return isProgress || cardType === 'freeSpin' ? '2.08rem' : '1.56rem'
    } else {
      return isProgress || cardType === 'freeSpin' ? '3.52rem' : '2.78rem'
    }
  }, [isProgress, cardType, swipData])
  return (
    <div className={styles['reward-swiperbox-warp']}>
      <Skeleton loading={spinning} title={false} paragraph={{ rows: 8 }} active>
        {swipData.length > 0 && showSwiper ? (
          <div
            className={cn('reward-swiperbox', isProgress || cardType == 'freeSpin' ? 'height' : '')}
            style={{ paddingBottom: maskHight }}
          >
            <Swiper
              modules={[EffectCards, Pagination, Autoplay]}
              direction="vertical" // 设置Swiper为垂直方向
              effect="cards" // 设置卡片效果
              grabCursor={true} // 启用抓手光标
              // slidesPerView={1.1} // 每次显示1.5个卡片，看到部分上下的卡片
              centeredSlides={true} // 居中当前卡片
              cardsEffect={{
                rotate: false,
                perSlideOffset: 20,
                slideShadows: false
              }}
              autoplay={{
                delay: 20000,
                disableOnInteraction: false
              }}
              // loop={swipData.length > 1}
              onSwiper={onSwiper}
              pagination={{
                el: '.reward-pagination',
                type: 'fraction'
              }}
            >
              {swipData.map((item: any, index: number) => (
                <SwiperSlide key={index} className={cn('reward-swiper-item', item.rewardType)}>
                  {render && render(item, String(index) + cardType)}
                </SwiperSlide>
              ))}
            </Swiper>
            <div
              className="mask"
              onClick={() => swiperRef.current?.slideNext()}
              style={{ height: maskHight }}
            ></div>
            {swipData.length > 1 && (
              <div className="reward-pagination-warp" onClick={() => swiperRef.current?.slideNext()}>
                <div className="reward-pagination"></div>
              </div>
            )}
          </div>
        ) : swipData.length > 0 && !showSwiper ? (
          <div className="reward-del-box">
            {swipData.map((item: any, index: number) => (
              <div key={index} className={item.rewardType}>
                {render(item, String(index) + cardType)}
              </div>
            ))}
          </div>
        ) : (
          <div className="reward-nodata">
            <img src="/static/images/promo/nodata.png" />
            <p>{t('common.game.nodata')}</p>
          </div>
        )}
      </Skeleton>
    </div>
  )
}

export default memo(RewardSwiper)
