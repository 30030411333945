export const virtualArr = [
  'USDT',
  'USDC',
  'BTC',
  'ETH',
  'DOGE',
  'LTC',
  'TRX',
  'DAI',
  'XRP',
  'ADA',
  'SHIB',
  'SOL'
]

// 底部支持关注平台
export const FollowSocialMedia = [
  // {
  //   name: 'Discord',
  //   path: 'https://lihi.cc/3jTD6'
  // },
  {
    name: 'Telegram',
    path: 'https://t.me/queencasino777'
  },
  {
    name: 'Twitter',
    path: 'https://twitter.com/queencasino_0'
  },
  {
    name: 'Youtube',
    path: 'https://www.youtube.com/channel/UCuc0gyUhzB40vVyItDbplrQ'
  },
  {
    name: 'LINE',
    path: 'https://uyu4ktjm.autosns.app/line'
  },
  {
    name: 'Instagram',
    path: 'https://www.instagram.com/queencasino.official/'
  }
]
export const FollowBlog = [
  {
    url: 'https://blog.queen-casino.com/',
    img: '/static/images/footer/icon_0.svg',
    text: 'common.footer.blog'
  },
  {
    url: '/videoList',
    img: '/static/images/footer/icon_1.svg',
    text: 'common.footer.streams'
  },
  {
    url: 'https://queen-casino.info/',
    img: '/static/images/footer/icon_2.svg',
    text: 'common.footer.news'
  },
  {
    url: 'https://www.queen-casino.com/media/',
    img: '/static/images/footer/icon_3.svg',
    text: 'common.footer.media'
  }
]

export const Licenses = [
  {
    icon: 'gamcare',
    path: 'https://www.gamcare.org.uk/'
  },
  {
    icon: 'ga',
    path: 'https://www.gamblersanonymous.org.uk/'
  },
  {
    icon: '18'
  },
  {
    icon: 'gpwa',
    path: 'https://www.gpwa.org/affiliate-program/og-affiliate'
  }
]

export const hidePath = [
  '/account/notice',
  '/account/deposit',
  '/account/buyCrypto',
  '/account/withdraw',
  '/promo',
  '/reward',
  '/account/voucher'
]
