// 选择语言
'use client'
import { useMemo, FC, memo } from 'react'
import { cn } from '@/lib/utils'
import { Popover, Button, Space } from 'antd'
import { useAppStore } from '@/stores/app'
// import { useCookies } from 'react-cookie'
import { languages, Lng } from '@/lib/locales/i18n'
import { localeItems, COOKIE_LANG } from '@/lib/locales/settings'
import styles from './_common_css/queryLang.module.scss'
import { useRouter, usePathname, useParams } from 'next/navigation'
import { useShallow } from 'zustand/react/shallow'

type IProps = {
  queryImage?: string // 可配置线上图片
  curWidth?: string // 配置宽度
  zIndex?: number // 调整index
}

const QueryLang: FC<IProps> = (props) => {
  const { queryImage = '', curWidth = '110px', zIndex = 2001 } = props
  const { isBigScreen, changeLanguage, setGolabLoading } = useAppStore(
    useShallow((state) => ({
      isBigScreen: state.isBigScreen,
      setGolabLoading: state.setGolabLoading,
      changeLanguage: state.changeLanguage
    }))
  )
  const router = useRouter()
  const pathName = usePathname()
  const rawPath = pathName.replace(/^\/(en|ja)($|\/)/g, '/')
  // const [cookies, setCookie, removeCookie] = useCookies([COOKIE_LANG])
  // const [cookies, setCookie] = setCookie([COOKIE_LANG])
  const { lng }: { lng: Lng } = useParams()

  // 线上配置的图片
  const imgaeData = useMemo(() => {
    return queryImage || '/static/images/footer/language.svg'
  }, [queryImage])

  // 语言字段转换
  const langTitle = useMemo(() => {
    return lng === 'en' ? 'English' : '日本語'
  }, [lng])

  // 判断 cookie
  // useEffect(() => {
  //   console.log('读取cookie 配置', cookies)
  // }, [cookies])

  // // 切换语言, 1修改url地址语言，2修改缓存的语言
  const changeLanguageHandle = async (item: { nickname: any; name: any }, key: any) => {
    try {
      setGolabLoading(true)
      // console.log('key和item', languages)
      await changeLanguage(item.name)
      // setCookie(COOKIE_LANG, key, { path: '/' })
      //router.replace(`/${key}${rawPath}`)
      window.location.href = `/${key}/${rawPath}`
    } catch (err) {
      console.log('change', err)
    } finally {
      setGolabLoading(false)
    }
  }

  return (
    <>
      <Space id="change-language" className={cn(styles['popup-langs'])} style={{ width: curWidth }}>
        <Popover
          trigger="click"
          className={`queryPop ${isBigScreen ? '' : 'mobile'}`}
          placement={!isBigScreen ? 'topLeft' : 'top'}
          getPopupContainer={() => document.getElementById('change-language')}
          zIndex={zIndex}
          content={
            <>
              <ul className={`langs-box ${isBigScreen ? 'pc_langs' : ''}`}>
                {localeItems.map((item, key) => (
                  <li
                    key={key}
                    className={cn(lng === item.key && 'active')}
                    onClick={() => changeLanguageHandle(item, item.arr[0])}
                  >
                    {item.title}
                  </li>
                ))}
              </ul>
            </>
          }
        >
          <Button className="bg-none" style={{ backgroundImage: 'url(' + imgaeData + ')' }}>
            {langTitle}
          </Button>
        </Popover>
      </Space>
    </>
  )
}

export default memo(QueryLang)
