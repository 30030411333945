import { useState, useMemo, memo, FC } from 'react'
import { cn, formatGameImg } from '@/lib/utils'
import { useTranslation } from '@/lib/locales/client'
import { useAppStore } from '@/stores/app'
import { useUserStore } from '@/stores/user'
import { useGameStore } from '@/stores/game'
import { usePathname } from 'next/navigation'
import { CSSTransition } from 'react-transition-group'
import { openGame, favoriteGameFun } from '@/app/[lng]/(game)/_components/startGame'
import { numThFilter, formatAmount, numberToKMUnit } from '@/lib/filter'
import styles from '../_common_css/gameItemNew.module.scss'
import { useShallow } from 'zustand/react/shallow'

type IProps = {
  gameData?: {
    property: string
    gameName: string
    amigoGameType: string
    gameType: string
    gameProperties: any
    gameId: string
    platformCode: string
    smallMediaLibrary: any
    rtp: any
    totalWinAmount: any
    totalBetAmount: any
    lobbyCode: string
    providerName: string
    isDemoGame: boolean
  }
  isSearchResult?: boolean
  type?: string //非游戏类型
}

const GameItemNew: FC<IProps> = (props) => {
  const { t } = useTranslation()
  const { isBigScreen, commonConfig, language } = useAppStore(
    useShallow((state) => ({
      isBigScreen: state.isBigScreen,
      commonConfig: state.commonConfig,
      language: state.language
    }))
  )
  const { favoriteGameList, liveGameBetLimit, getFavoriteGameList } = useGameStore(
    useShallow((state) => ({
      favoriteGameList: state.favoriteGameList,
      liveGameBetLimit: state.liveGameBetLimit,
      getFavoriteGameList: state.getFavoriteGameList
    }))
  )
  const { isLogin, jpyToUsd, storeCurrency } = useUserStore(
    useShallow((state) => ({
      isLogin: state.isLogin,
      jpyToUsd: state.jpyToUsd,
      storeCurrency: state.currency
    }))
  )
  const pathName = usePathname()

  const [showDetail, setShowDetail] = useState(false) // 是否查看详情

  const isAmigoSlot = useMemo(() => {
    return (
      props.gameData.gameType === 'pachislot' ||
      props.gameData.amigoGameType === 'slot' ||
      props.gameData.lobbyCode === 'KAMIKAZE'
    )
  }, [])

  const isPachinko = useMemo(() => {
    return props.gameData.gameType === 'pachinko' || isAmigoSlot
  }, [])

  const gameProperty = useMemo(() => {
    if (isPachinko) {
      return props.gameData.property || props.gameData.gameProperties?.gameProperty
    } else {
      return null
    }
  }, [])

  const liveLimitText = useMemo(() => {
    if (props.gameData.gameType !== 'live') return ''
    const rate = storeCurrency.currency === 'JPY' ? jpyToUsd : 1
    const tex = storeCurrency.currency === 'JPY' ? '¥' : '$'
    const games = liveGameBetLimit.filter(
      (el) => el.gameId === props.gameData.gameId && el.providerCode === props.gameData.platformCode
    )
    if (games[0] && Number(games[0].maxBetAmount) && Number(games[0].minBetAmount)) {
      const min = tex + numberToKMUnit(games[0].minBetAmount / rate)
      const max = tex + numberToKMUnit(games[0].maxBetAmount / rate)
      return min + '-' + max
    }
    return ''
  }, [])

  const getJaName = (name = '-') => {
    if (language === 'ja-JP') {
      if (name === 'N0.4') {
        return '4号機'
      } else if (name === 'N0.5') {
        return '5号機'
      } else if (name === 'N0.6') {
        return '6号機'
      } else if (name === 'TAIWANver') {
        return 'TAIWANver'
      }
    }
    return name
  }


  return (
    <>
      <div className={cn(styles.root)}>
        <div
          className={cn(`game-type-${props.gameData.gameType}`, isBigScreen ? 'pc' : 'h5', 'game_item_new')}
          onClick={() => openGame(0, props.gameData)}
        >
          {props.gameData && (
            <div
              className={'game-img'}
              style={{
                backgroundImage: `url(${formatGameImg(props.gameData, commonConfig)}`
              }}
            ></div>
          )}

          {isLogin && (
            <div
              className={cn(
                favoriteGameList.some(
                  (e) => e.gameId === props.gameData.gameId && e.providerCode === props.gameData.platformCode
                ) && 'favorited',
                'fav-icon'
              )}
              onClick={(event) => {
                event.stopPropagation()
                favoriteGameFun(props.gameData)
              }}
            ></div>
          )}

          {/* H5点击查询详情 */}
          {!showDetail && props.gameData.gameType !== 'live' && (
            <div
              className={'detail-icon'}
              onClick={(event) => {
                event.stopPropagation()
                setShowDetail(true)
              }}
            ></div>
          )}

          {/* 数据来源：/cms/open/gameLabels/gameLabelsList, 搜索模块数据来源：/cms/v1/element/material/game_page */}
          <div className={'game-name-wrap'}>
            <div className={'game-name-wrap-txts'}>
              <p className={cn('game-name', 'text-multi-ellipsis-l2')}>{props.gameData.gameName}</p>
              <span className='text-ellipsis'>{props.gameData.providerName}</span>
              {props.gameData.gameType === 'live' && <div className="game-limit">{liveLimitText}</div>}
            </div>
          </div>
          <div className={'after'}></div>

          {/* H5黑色蒙层详情 */}
          <CSSTransition timeout={300} in={showDetail} classNames={'fade'} unmountOnExit>
            <div className={'mask-detail'}>
              <h3 className={cn((isAmigoSlot || isPachinko) && 'one-line', 'text-multi-ellipsis-l2')}>
                {props.gameData.gameName}
              </h3>
              {props.gameData.totalBetAmount &&
              props.gameData.totalBetAmount != 0 &&
              props.type !== 'pachinkoWin' ? (
                <>
                  <span>{t('common.game.dailyBet')}</span>
                  <strong>${numThFilter(props.gameData.totalBetAmount * 4 || 0, 0)}</strong>
                </>
              ) : (
                props.gameData.totalWinAmount &&
                props.gameData.totalWinAmount != 0 &&
                props.type === 'pachinkoWin' && (
                  <>
                    <span>{t('common.game.winBet')}</span>
                    <strong>${numThFilter(props.gameData.totalWinAmount * 4 || 0, 0)}</strong>
                  </>
                )
              )}

              {gameProperty && 
                (isAmigoSlot ? (
                  <>
                    {props.gameData.lobbyCode !== 'KAMIKAZE' &&
                      props.gameData.platformCode !== 'KAMIKAZE' && (
                        <div>
                          {t('common.game.ceiling')}
                          <span className={cn(props.isSearchResult && 'red', 'num')}>
                            {gameProperty.ceiling || '-'}
                          </span>
                        </div>
                      )}
                    <div>
                      {t('common.game.token')}
                      <span className={'num'}>${formatAmount(gameProperty.onePiece, 2)}</span>
                    </div>
                    <div>
                      {t('common.game.type')}
                      <span className={'num'}>{getJaName(gameProperty.type)}</span>
                    </div>
                  </>
                ) : (
                  isPachinko && (
                    <>
                      {props.gameData.lobbyCode !== 'KAMIKAZE' &&
                        props.gameData.platformCode !== 'KAMIKAZE' && (
                          <div>
                            {t('common.game.probability')}
                            <span className={cn(props.isSearchResult && 'red', 'num')}>
                              {gameProperty.ceiling || '-'}
                            </span>
                          </div>
                        )}
                      <div>
                        {t('common.game.ball')}
                        <span className={'num'}>${formatAmount(gameProperty.oneBead, 3)}</span>
                      </div>
                      <div>
                        {t('common.game.roll')}
                        <span className={'num'}> {formatAmount(gameProperty.oneTurn, 3)}</span>
                      </div>
                    </>
                  )
                ))}

              {!isPachinko && (
                <div>
                  RTP <i>{(props.gameData.rtp * 100).toFixed(2)}%</i>
                </div>
              )}

              {!props.isSearchResult && (
                <div className={'bottom-box'}>
                  <button onClick={() => openGame(0, props.gameData)}>
                    {isLogin ? t('common.game.play') : t('common.game.goPlay')}
                  </button>
                  {props.gameData.isDemoGame && !isLogin && (
                    <button onClick={() => openGame(1, props.gameData)}>{t('common.game.funPlay')}</button>
                  )}
                </div>
              )}
              <img
                onClick={(event) => {
                  event.stopPropagation()
                  setShowDetail(false)
                }}
                className={'close-icon'}
                src="/static/images/game/close.svg"
              />
            </div>
          </CSSTransition>

          {props.gameData.smallMediaLibrary && (
            <img
              src={
                props.gameData.smallMediaLibrary?.fullWebpFilePath ||
                props.gameData.smallMediaLibrary?.fullFilePath
              }
              className={'top_icon'}
            />
          )}
        </div>
      </div>
    </>
  )
}

export default memo(GameItemNew)
