import { FC, memo, useState, useEffect } from 'react'
// import type { PaginationProps } from 'antd'
import { Pagination, Flex } from 'antd'

/**
 * author: hone
 * 描述: 首页更多标签 传入3个标签名会自动渲染
 * @param containElm: 传入数组，输入需要进行渲染的元素
 * @param count: 分页起始页数, total是总页数
 * @param calldec: 回调增加(callInc)和减少(callDec) 的方法
 * @returns
 */

type IProps = {
  containElm: ('toggle' | 'more' | 'pages')[]
  count?: number
  total?: number
  // callDec?: () => void
  // callInc?: () => void
  callbackCount?: () => void
}

const MorePagination: FC<IProps> = (props) => {
  const { containElm: element, count = 1, total = 10 } = props

  // const [current, setCurrent] = useState(count);
  //   // useEffect(() => {
  //   //   props.callbackCount(count)
  //   // }, [count])
  //   const callback = () => {
  //     props.callbackCount(count)
  //   }

  // const onChange: PaginationProps['onChange'] = (page) => {
  //   console.log(page);
  //   setCurrent(page);
  //   props.callbackCount(page)
  // };

  // .toggle {
  //   width: 28px;
  //   height: 28px;
  //   padding: 8px 12px 8px 12px;
  //   border: 0px 0px 1px 0px;
  //   // gap: 8px;
  //   // opacity: 0px;
  //   background: #fff;
  //   box-shadow: 0px 1px 0px 0px #0000001A;
  // }
  return (
    <Flex className={'picker-more'} justify="flex-end">
      {/* 收缩 toggle */}
      {/* 待开发，估计是筛选项 */}
      {/* {element.includes('toggle') && <div className="toggle" onClick={() => console.log('点了收缩')}></div>} */}
      {/* 更多 more */}
      {element.includes('more') && (
        <div
          className="h-[28px] w-[60px] cursor-pointer bg-white px-[8px] py-[12px] text-[12px] font-normal leading-[12px] shadow-[0_1px_0_0_#00000001A]"
          onClick={() => console.log('点了更多')}
        >
          {'もっと'}
        </div>
      )}
      {/* 分页 pages */}
      {element.includes('pages') && (
        <Pagination simple defaultCurrent={1} total={total} onChange={props.callbackCount} />
      )}
    </Flex>
  )
}

export default memo(MorePagination)
