export const activity = {
  voucher: {
    offer: "Voucher",
    tips: 'Redemption codes can be obtained from various social media platforms or special events, please follow the each rules. Please redeem the coupon within the validity period. If you have any questions, please',
    record: 'Redemption Record',
    enter: 'Please enter the offer code',
    receive: 'Receive',
    code: 'Voucher code'
  },
  promo: {
    readMore: 'Read more',
    goBack: 'Go Back',
    signUp: 'Sign up now!',
    status0: 'Coming Soon',
    status1: 'Ongoing',
    status2: 'End',
    startDateText: 'Start at {{0}}',
    endDateText: 'End at {{0}}',
    SUPERTournament: 'SUPER Tournament',
    supplier: 'Supplier'
  },
  account: {
    btn: 'Transfer account',
    title1: 'For Your Account Security',
    title2: 'Please Enter Your Password',
    password: 'Password',
    tips: 'Please use 6-20 characters of alphabets, numerals and symbol',
    confirm: 'Confirm',
    cancel: 'Cancel',
    title3:
      'Dear {{name}}, We are committed to providing you with the best user experience. To enhance service quality and offer a better gaming experience, Queen Casino will undergo a comprehensive system upgrade and open applications for transferring account data to the new Queen Casino Beta.You can now access https://queen-casino.vip to discover the brand new Queen Casino Beta:',
    tips2:
      "We wholeheartedly invite you to dive in and explore our platform right away, and share your invaluable insights with us. We also encourage you to promptly transfer your account details and balance to the new site. We'll also keep enhancing and upgrading our offerings! Once you confirm your data transfer application, all the account data and information on (queen-casino.com) will be swiftly transferred to the new version within 24 hours. Thank you for your attention!",
    transfer: 'Transfer Now',
    later: 'Do it Later',
    item1: 'Exclusive online access to real-life Pachinko machines',
    item2: 'Immerse yourself in over 7,000 slot machine games, live casinos and so on.',
    item3: 'Lightning-fast deposit and withdrawal response speeds upgraded.',
    item4:
      'Putting CLIENTS at the center, we craft an unmatched, comfortable, and seamless user experience for you'
  },
  freeSpin: {
    tips: 'TIPS：This reward will expire on <br />{{expiredTime}}'
  },
  vip: {
    title: 'Loyalty Club',
    level: 'Level',
    wager: 'Total Wager  ',
    levelUpBonus: 'Level up quest',
    annuallyBonus: 'Anniversary Bonus',
    realRebate: 'Real Time Rebate',
    monthlyBack: 'Monthly Cashback',
    luckySpin: 'Lucky Spin',
    vipManager: 'VIP Manager',
    luxuriousTrip: 'Luxurious Travel',
    register: 'Register',
    logFirst: 'Please log in first',
    levelEd: 'This level is completed',
    lockedLevel: 'This level is not unlocked',
    Received: 'Received',
    claim: 'Claim',
    benefitsUnlocked: '{{0}}Benefits have been unlocked',
    claimText1: 'Personal information has been completed',
    claimText2: 'Contact information has been certified',
    claimText3: 'KYC has been certified',
    claimTextSuccess: 'Bonus has been deposited',
    claimSuccess: 'Claim Success',
    claimLevelOne: 'Receive rewards after level-up',
    maxLevel: 'Max Level',
    myBenefits: 'My Benefits',
    wagerTitle: 'Wager',
    legend: 'Legend Club',
    reachedMaxLevel:'Reached the highest level'
  },
  recFriend: {
    title: 'Referred Friends',
    recWay: 'Referral Method',
    recLink: 'Referral Link:',
    recCode: 'Referral Code:',
    recQrCode: 'Referral QR Code:',
    recNum: 'Referrer',
    recMember: 'Refer friend:',
    recEficacy: 'Valid friends:',
    recRank: 'Serial Number',
    myFriend: 'My Friends',
    regTime: 'Registration Time',
    isEficacy: 'Valid Friends',
    copySuc: 'Successfully Copied',
    noFriends:
      "You haven't referred any friends yet. Please ask your friends to fill in your referral code to become your refferred friends.",
    more: 'More',
    friendsList: 'Friend List',
    yes: 'yes',
    not: 'no',
    copy: 'COPY',
    save: 'SAVE',
    referralMethod: 'Refer method',
    referrer: 'Refer results',
    totalFriendsReferred: 'Total Friends Referred',
    totalFriendsReferred2: 'Total Friends Referred',
    totalReferralReward: 'Total Referral Reward',
    totalFriendsFreeSpin: 'Total Friends FreeSpin',
    rewardsAvailable: 'Rewards available',
    details: 'Details',
    receive: 'Receive',
    rules: 'Refer rules',
    rulesTitle1: 'VIP level',
    rulesTitle2: 'Deposit (Grand total)',
    rulesTitle3: 'Valid turnover (Grand total)',
    rulesTitle4: 'Rewards available',
    rules1Title: 'Refer friend',
    rules1Li1:
      '1.Must register via referral link/QRcode/referral code in order to be recognized as a referee of that referral.',
    rules1Li2: '2.Complete KYC, get {{spins}} times freespin.',
    rules1Li3: '2.Deposits totaling ${{ftd}} or more, get {{spins}} times freespin.',
    rules1Li4: '2.Complete KYC and deposit total amount of ${{ftd}} or more, get {{spins}} times freespin.',
    rules2Title: 'Referrer',
    rules2Li1_1: '1.Refer friend completes KYC,get {{spins}} times freespin.',
    rules2Li1_2: '1.Refer friend deposits ${{ftd}} or more, get {{spins}} times freespin.',
    rules2Li1_3: '1.Refer friend completes KYC and deposits ${{ftd}} or more, get {{spins}} times freespin.',
    rules2Li2:
      '2.Refer friend completes the specified wagering amount, the referrer will receive the corresponding bonus (as described in the table).',
    receiveTitle: 'Friend recommendation activity',
    receiveIntroduce: '{{0}}USD bonus has been sent to your Bonus wallet!',
    search1: 'Valid friends',
    search2: 'Registration date',
    search3: '',
    tableNo: 'Number',
    tableAccount: 'Account Name',
    tableRegTime: 'Registration Time',
    tableIsEficacy: 'Valid Friends',
    tableCumulativeRewardContribution: 'Total reward contribution',
    tableCumulativeBetAmount: 'Total valid bet amount',
    tableAmount: 'Reward Amount',
    tableBonusType: 'Reward Type',
    tableRequiredTurnover: 'Wagering Requirement',
    tableDispatchTime: 'Dispatch time',
    tableGameName: 'Game Name',
    tableFreeSpins: 'Free Spin Times',
    tableSpinOnePrice: 'Bet Value',
    total: 'Total',
    tableBonusType1: 'Referrer reward',
    tableBonusType2: 'Refer friend reward',
    effective: 'Y',
    invalid: 'N'
  },
  tournament: {
    finishIn: 'Finish in',
    days: 'Day',
    hours: 'Hour',
    min: 'Min',
    sec: 'Sec',
    daysMin: 'D',
    hoursMin: 'H',
    minMin: 'M',
    secMin: 'S',
    tournaments: 'Tournaments',
    prize: 'Prize',
    all: 'All',
    running: 'End in',
    announced: 'Start in',
    ended: 'Closed at',
    startIn: 'Start in',
    closedAt: 'Closed at',
    rank: 'Rank',
    player: 'Player',
    points: 'Points',
    now: 'Now',
    outOfRank: 'Out of Rank',
    joinNow: 'Join Now',
    joined: 'Joined',
    login: 'Login',
    getPoints: 'Join now',
    bonusHistory: 'Bonus History',
    lastBonus: 'Last Bonus',
    totalBonus: 'Total Bonus',
    receive: 'Receive',
    received: 'Received',
    send: 'Send',
    rounds: 'Spins',
    round: 'Spins',
    topGames: 'Top Games',
    ranking: 'Ranking',
    me: 'Me',
    rules: 'Rules',
    unsent: 'Unsent',
    understand: 'Understand',
    receiveFailed: 'Receive Failed',
    receiveSuccess: 'Receive Success',
    receiveFailedTips:
      'Sorry, you are not eligible to receive it. Please contact customer service for details.',
    totalPrize: 'Prize Pool',
    totalFreeSpin: 'Free Spins',
    detail: 'Detail',
    betListTitle: 'Super Tournament',
    magnification: 'Winning Multiplier',
    updateMagnification: 'Join now',
    FreeSpins: "Free Spins",
  },
  ticket: {
    rt: "Queen Casino Beginner's Guide",
    siteRt: 'Site Policies',
    detailTitle: 'Tickets & Prize Details',
    detailSelectDate: 'Select Period',
    detailSelectStatus: 'Winning Status',
    detailIndex: 'No.',
    search: 'Search ticket number',
    searchTxt: 'Please enter your ticket number',
    detailNum: 'Ticket No.',
    claimDate: 'Claim Date',
    prize: 'Prize',
    totalSize: '{{0}} items',
    totalPage: 'Total {{0}} pages',
    goToPage: 'Go to {{page0}} page',
    reviewPast: 'Review Of Past',
    bigWinNum: 'Super Award',
    midWinNum: 'Weekly Award',
    level0: 'Special Prize',
    level1: 'First Prize',
    level2: 'Second Prize',
    level3: 'Third Prize',
    level4: 'Fourth Prize',
    weeklyRewards: 'Weekly Award',
    superRewards: 'Luxury Prize',
    marqueeText: 'Congratulations {{0}} for winning the {{1}} : {{2}}',
    openDateText: ` Open at {
        0
      } day {
        1
      } hour {
        2
      } minute {
        3
      } second`,
    openedText: 'Opened',
    openingText: 'Opening',
    lotSourceEnum1: 'Register and first deposit',
    lotSourceEnum2: 'Total Deposit ',
    lotSourceEnum3: 'Pachinko bet',
    lotSourceEnum4: 'Slot bet',
    lotSourceEnum5: 'Sport bet',
    lotSourceEnum6: 'Live bet',
    lotSourceEnum7: 'Backstage gift',
    lotNumText1: `{
        0
      }PCS`,
    lotNumText2: `\${
        0
      }/PCS`,
    lotNumText3: `\${
        0
      }/{
        1
      } PCS`,
    claimText: `Pcs`,
    dr: 'Get',
    claim: 'Claim',
    myTickets: 'My Tickets',
    ticketLimit: 'Reached the upper limit',
    loginNow: 'Login',
    remainingTickets: 'Remain Tickets',
    claimSuccess: 'Claim Success',
    claimSuccessChatServer: 'Successfully claimed, please contact customer service to get your prize',
    betTotal: 'The higher the bet amount of all users,',
    betTotalText: 'chance to get {{amount}} prize!',
    claimStatus: 'Status',
    claimStatus0: 'Deleted',
    claimStatus1: 'Pending approval',
    claimStatus2: 'Approval Rejected',
    claimStatus3: 'Be claimed',
    claimStatus4: 'Received',
    claimStatus5: 'Expired',
    claimStatus6: 'shipped',
    status0: 'Lose',
    status1: 'Win',
    statusAll: 'All',
    noData: 'No Data',
    winText: '{{0}} win',
    winPeopleText: '{{0}} people ',
    rounds: 'Spins',
    activeRule: 'Event Rules',
    myPrice: 'My Prize',
    priceName: 'Prize Name',
    priceDate: 'Expiration Date',
    operate: 'Operate',
    times: '{{0}} times',
    bonus: 'Bonus',
    freeSpins: 'Free spin',
    cashback: 'Cashback',

    gameRule: `
      <div class="line">
      [Event object
      ] All QC members
      </div>
      <div class="line">
      [Ticket Collection
      ] After meeting the collection conditions, you can collect the tickets manually on this page, and the system randomly assigns the ticket number, and each member can collect a maximum of {maxClaim
      } tickets for each period.
      </div>
      <div class="line">
      [Raffle rules
      ] A total of {totalNum
      } raffle tickets for each period of activity, the first to lead until the end, each raffle ticket can participate in the weekly awards and the Super Prize at the same time, a raffle ticket has a number of chances to win!
      </div>
      `
  },
  betting: {
    cannotBetTips: 'Preferential wallets cannot bet',
    inputPlaceHolder: 'Please enter the number of tickets you want to buy',
    historyBtn: 'History of Ranking',
    liText1: 'Actual valid bet amount',
    liText2: 'Estimated return',
    flagText: 'Match Prediction',
    dateText: '{{0}} d {{1}} h {{2}} min {{3}} sec',
    historyTab1: 'All prediction',
    historyTab2: 'High Prize Pool',
    historyTab3: 'History of Winners',
    historyTabSub1: 'Credit',
    historyTabSub2: 'Bonus',
    historyTabSub3: 'Q-PON',
    comingSoon: 'Coming Soon',
    historyTableHeader1: 'Joybet',
    historyTableHeader2: 'Member Account',
    historyTableHeader3: 'Amount',
    historyTableHeader4: 'Estimated Return',
    historyTableHeader5: 'Content',
    indexRank: 'Ranking',
    poolValue: 'Jackpot Amount',
    poolValueIn: 'Injection Amount',
    gameTimes: 'Number of Participation',
    winValue: 'Amount of the Prize',
    bet: 'Bet',
    betBtn0: 'Bet',
    more: 'More',
    all: 'All',
    historyHigth: 'High Prize Pool',
    historyWin: 'History of Winners',
    bettingTitle: 'Lottery Record',
    bettingTitleNew: 'Featured Matches',
    bettingTitleMore: 'More',
    maxPrize: 'Maximam Jackpot',
    startDate: 'Start Date',
    endDate: 'End Date',
    betBtn: 'Bet Now',
    myScore: 'My Result',
    totalBetNum: 'Total of {{0}} prediction Available to Collect',
    totalInfo: 'Total Data <span> {{0}}</span>',
    claim: 'Claim',
    poolType: 'Jackpot Type',
    betTime: 'Prediction Time',
    betProportion: 'Prediction Proportion',
    status: 'Status',
    status1: 'Win',
    status2: 'No won',
    status3: 'Cancel',
    status4: 'In Progress',
    return: 'Estimated Return',
    betRuleTitle: 'Rule Description',
    knowIt: 'I Got it',
    totalNum: "Total Data <span> {{0}}</span>",
    filterPlaceholder: 'Please enter the guessing topic or session number',
    selectWallet: 'Select Wallet',
    selectStatus: 'Select Status',
    selectClaimStatus: 'Select Claim Status',
    betTimeLabel: 'Prediction Time',
    reset: 'Reset',
    confirm: 'Confirm',
    betHistory: 'Prediction Record',
    history: 'Historical Record',
    betPoolValue: 'Jackpot Amount',
    betPoolnum: 'Number of the People Who prediction',
    betActiveDate: 'Activity Time',
    gameStaits: 'Result for the Winner',
    rolloverRequest: 'Flow Requirements',
    wallet: 'Wallet',
    betResult: 'Prediction Result',
    betMoney: 'Guessing Amount',
    betText: 'Prediction',
    effectiveBet: 'Actual Valid Prediction Amount',
    estimatedBonus: 'Estimated Bonus',
    betSuccess: 'Bet Success',
    betTimes: '{{0}} times',
    noSelect: 'Please check the guessing content',
    noInput: 'Please enter the guessing amount',
    noStart: 'Guessing not started',
    claimSuccess: 'Claim Success',
    status_not_start: 'Not Started',
    status_betting: 'Guessing',
    status_wait_result: 'Pending',
    status_wait_settle: 'Pending Settlement',
    status_settled: 'Settled',
    status_canceled: 'Canceled',
    slotsBigWin: 'Slot Highest Bet',
    gameNo: 'Session Number',
    winPeopleNum: 'History of Winners',
    openDate: 'Prize Announcement Date',
    rolloverRequestText: '{{0}} times',
    gameStatus0: 'Waiting for the Announcement',
    gameStatus1: 'Announced Prize Winner',
    gameStatus2: 'Canceled',
    commissionAmount: 'Commission',
    claimStatus: 'Waiting for Collection',
    claimStatus1: 'Pending',
    claimStatus2: 'Received',
    claimStatus3: 'Expired',
    claimStatus4: 'Canceled',
    claimStatus5: 'Rejected',
    LuxuryCruiseTravel: 'Luxury Cruise Travel',
    claimText: 'Collection',
    ended: 'Ended',
    cancel: 'Cancel',
    cancelSuccess: 'Cancel Success'
  },
  lottery: {
    luckyDraw: "Lucky Draw",
    history: "Tickets History",
    more: "More",
    detail: "Detail",
    yourWagered: "Your Wagered",
    totalWagered: "Total wagered",
    totalTicket: "Total ticket",
    eventChannel: "Event Channel",
    myTickets : "My Tickets",
    currentPeriod: "Current Period",
    lastPeriod: "Last Period",
    popDetail: "Each drawing will take place on the official channel. Please check the event details for more information!",
    select: "Please select the query period"
  },
  accountDisabled : {
    title: "Account suspended",
    text001: "You have excluded yourself.",
    text002: "Thank you."
  }
} as const
