'use client'
import { useState, useEffect, useMemo, FC, memo, useRef } from 'react'
import { cn } from '@/lib/utils'
// import { CMSLabels } from '@/types/common'
import { useTranslation } from '@/lib/locales/client'
import { Lng } from '@/lib/locales/i18n'
import { useAppStore } from '@/stores/app'
import { useUserStore } from '@/stores/user'
import { cmsTemplateApi } from '@/api/base'
// import { CSSTransition } from 'react-transition-group'
import { Flex, Space, Skeleton } from 'antd'
import dayjs from 'dayjs'
import { webpHandle } from '@/hooks/aboutWebp'
import dynamic from 'next/dynamic'
import styles from './index.module.scss'
import { useRouter, useParams } from 'next/navigation'
import { useShallow } from 'zustand/react/shallow'
/** api */
import { transferOutAll } from '@/api/activity'
import { cmsGameLabels } from '@/api/home'
/* com */
// import Banner from '../_components/banner'
import HomeIcons from '../_components/common/misc/homeIcons'
import HomeIslot from '../_components/common/misc/homeIslot'
import More from '@/components/common/QCMore'

const DesktopHome: FC<{ lng?: Lng; device?: string }> = ({ lng, device }) => {
  const { t } = useTranslation()
  const { globalPublickKey, changeFastDialog } = useAppStore(
    useShallow((state) => ({
      globalPublickKey: state.globalPublickKey,
      changeFastDialog: state.changeFastDialog
    }))
  )
  const { isLogin, depositActivityInfo, userInfo } = useUserStore(
    useShallow((state) => ({
      isLogin: state.isLogin,
      depositActivityInfo: state.depositActivityInfo,
      userInfo: state.userInfo
    }))
  )
  const router = useRouter()
  // const { lng } = useParams()
  const [homeBanner, setHomeBanner] = useState([])
  const [homeSportbet, sethomeSportbet] = useState([])
  const [homeLobby, setHomeLobby] = useState([])
  const [homeMileStone, setHomeMileStone] = useState([])
  const [frontDataListHot, setFrontDataListHot] = useState([])
  const [frontDataListNew, setFrontDataListNew] = useState([])
  const [icons, setIcons] = useState([])
  const [gameVideos, setGameVideos] = useState([])
  const [prizeGuideBigBg, setPrizeGuideBigBg] = useState('')
  const [prizeGuideSmallBg, setPrizeGuideSmallBg] = useState('')
  const [homeTestimonials, setHomeTestimonials] = useState([])

  const hotGamesData = useRef<CMSLabels>()

  // const [searchString, setSearchString] = useState('')

  // 首页标签页内模块
  const Banner = dynamic(() => import('../_components/banner'), {
    loading: () => <Skeleton active />
  })
  const RecentBigWin = dynamic(() => import('./modules/recentWinners'), {
    loading: () => <Skeleton active />
  })
  const HotGames = dynamic(() => import('./modules/hotGames'), {
    loading: () => <Skeleton active />
  })
  const HorseRacing = dynamic(() => import('@/app/[lng]/(home)/_components/horseRacing/index'), {
    loading: () => <Skeleton active />
  })

  //单独加载banner 让banner更快显示
  const onGetBannerData = async () => {
    const params = {
      moduleCodeList: ['homeBanner', 'homeSportbet']
    }
    const cmsData: Resp = await cmsTemplateApi(params)
    if (cmsData?.success) {
      setHomeBanner(cmsData.data.filter((item) => item.moduleCode === 'homeBanner'))
      sethomeSportbet(cmsData.data.filter((item) => item.moduleCode === 'homeSportbet'))
    }
  }

  const onGetCMSData = async () => {
    const params = {
      moduleCodeList: [
        'homeLobby',
        'homePromotion',
        'homeSpokesman',
        'floatbutton',
        'homeGameVideos',
        'limitedTimeBgNew',
        'homeTestimonials',
        'mileStone'
      ]
    }
    const cmsData: Resp = await cmsTemplateApi(params)
    if (cmsData?.success) {
      setHomeLobby(cmsData.data.filter((item) => item.moduleCode === 'homeLobby'))
      setIcons(cmsData.data.filter((item) => item.moduleCode === 'floatbutton'))
      setGameVideos(cmsData.data.filter((item) => item.moduleCode === 'homeGameVideos'))
      setHomeTestimonials(cmsData.data.filter((item) => item.moduleCode === 'homeTestimonials'))
      const bgArr = cmsData.data.filter((item) => item.moduleCode === 'limitedTimeBgNew')[0] || []
      bgArr.mediaLibrary?.forEach((el) => {
        if (el.resourceType === 1) {
          setPrizeGuideBigBg(webpHandle(el.fullWebpFilePath, el.fullFilePath))
        } else if (el.resourceType === 2) {
          setPrizeGuideSmallBg(webpHandle(el.fullWebpFilePath, el.fullFilePath))
        }
      })
      setHomeMileStone(
        cmsData.data
          .filter((item) => item.moduleCode === 'mileStone')
          .map((item) => {
            return {
              ...item,
              startDate: item.startDate ? item.startDate : item.createDate // 没填取创建时间
            }
          })
          .sort((a, b) => {
            return new Date(b.startDate).getTime() - new Date(a.startDate).getTime()
          })
      )
    }
  }

  // 游戏数据，
  async function getGameLabels() {
    // const userName = userInfo?.loginName
    const { loginName = '' } = userInfo
    const formatData = (data) => {
      /**
       * 因ll.providerCode = ll.lobbyCode中providerCode与以前进游戏有关，不可删除；
       * providerName: 搜索页数据来源/element/material/game_page，无providerCode字段；
       * providerName: 首页Casino模块中数据来源/cms/open/gameLabels/gameLabelsList，需使用providerCode字段；
       * 进游戏仅需使用gameId与providerCode，不受字段影响，故赋值在providerName中进行使用。
       */
      // 以上看不懂，改写该方法为map的方式
      const gamesData = data.map((item) => ({
        ...item,
        detailsList: item.detailsList.map((unit) => ({
          ...unit,
          providerName: unit.providerCode,
          providerCode: unit.lobbyCode,
          platformCode: unit.lobbyCode
        }))
      }))
      return gamesData
    }
    // hotGame cms data
    try {
      const result = await cmsGameLabels({ moduleCode: 'labelClassification', loginName })
      if (result?.success && result.data.length) {
        setFrontDataListHot(formatData(result.data))
      }
    } catch (err) {
      console.log('数值err ', err)
    }

    // new Release cms data
    // await cmsGameLabels({ moduleCode: 'labelClassification2', loginName }).then((res) => {
    //   if (res?.success && res.data.length) {
    //     setFrontDataListNew(formatData(res.data))
    //   }
    // })
  }

  const init = async () => {
    await onGetBannerData()
    // await getGameLabels()
    // await Promise.all([onGetCMSData(), getGameLabels()])
  }

  // 待观察 最先调用request-old的接口的地方
  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    if (isLogin) {
      transferOutAll({ loginName: userInfo?.loginName })
    }
  }, [isLogin])

  useEffect(() => {
    if (globalPublickKey) init()
  }, [globalPublickKey])

  // useEffect(() => {
  //   actionData('').then((res) => {
  //     console.log('下发的接口', res)
  //   }) // 读取接口默认数据
  // }, [])

  return (
    <>
      {/* content background-image */}
      {/* <div
        style={{
          background: `url(/static/images/myAccount/bg_pc.jpg) no-repeat`,
          backgroundSize: '100% 100%'
        }}
      > */}
      {/* mx-auto  */}
      <div className="relative w-[1000px]">
        <Flex gap="small" justify={'space-between'} className="mb-[16px] mt-[16.8px]">
          {/* bannar */}
          <div className="h-[264px] w-[662px] bg-gray-200">
            <Banner key={homeBanner.length} homeBanner={homeBanner} />
          </div>
          {/* video */}
          <Flex vertical className="bg-red h-[264px] w-[326px] bg-gray-200">
            <div>video</div>
            <div>边栏</div>
          </Flex>
        </Flex>

        {/* 2nd */}
        <Flex gap="small" vertical className="mb-[16px]">
          <Flex justify="start" align="center" className="h-[28px] w-full">
            <p className="text-[16px] font-bold leading-[16px]">{t('home.betList.bigWin')}</p>
          </Flex>
          {/* content */}
          <Flex gap={12} className="h-[168px] w-full">
            <RecentBigWin type={'all'} />
            {/* {[...Array(9)].map((item, index) => (
              <div key={index} className="h-[168px] w-[100px] bg-gray-200">
                big wins 滚动组件
              </div>
            ))} */}
          </Flex>
        </Flex>

        {/* 3st */}
        <Flex gap="small" justify={'space-between'} className="mb-[16px]">
          {/* lucky Draw */}
          <Flex vertical className="w-[325px] bg-gray-200">
            <Flex justify="space-between" align="center" className="mb-[8px] h-[28px] w-full">
              <p>lucky Draw</p>
              <More containElm={['more']} />
            </Flex>
            {/* content */}
            <div>业务组件1</div>
          </Flex>
          {/* Super Tournament */}
          <Flex vertical className="w-[663px] bg-gray-200">
            <Flex justify="space-between" align="center" className="mb-[8px] h-[28px] w-full">
              <p>Super Tournament</p>
              <More containElm={['pages']} />
            </Flex>
            {/* content */}
            <Flex gap={12} className="h-[140px]">
              <a
                className="w-[325px] qc-flex-center"
                href="#"
                onClick={() => router.push(`/${lng}/tournament`)}
              >
                锦标赛入口
              </a>
              <a
                className="w-[325px] qc-flex-center"
                href="#"
                onClick={() => router.push(`/${lng}/tournament`)}
              >
                锦标赛入口
              </a>
            </Flex>
          </Flex>
        </Flex>

        <Flex gap="small" vertical className="mb-[16px]">
          <Flex gap={12} className="h-[374px] w-full">
            <HorseRacing data={homeSportbet} />
          </Flex>
        </Flex>

        {/* 4th */}
        <Flex gap="small" vertical className="mb-[16px]">
          {/* header */}
          <Flex justify="space-between" align="center" className="mb-[8px] h-[28px] w-full bg-gray-200">
            <p>title Recent Big Wins</p>
            <More containElm={['more']} />
          </Flex>
          {/* title desc */}
          <Space className="my-[8px]">
            <div>次に開催されるレース(15)</div>
            <div>明日(2647)</div>
            <div>次に開催されるレース(15)</div>
            <div>icon speak</div>
            <div>No.1. Isa Chil Ukiuki (1)-Monbetsu R9</div>
            <div>color diffent 51.24</div>
            <div>No.1. Isa Chil Ukiuki (1)-Monbetsu R9</div>
          </Space>

          {/* 业务组件1 */}
          <Flex className="bg-gray-200">
            <div className="w-[325px]">R7 名古屋 1</div>
            <div className="w-[325px]">R7 名古屋 2</div>
            <div className="w-[325px]">R7 名古屋 3</div>
          </Flex>
        </Flex>

        {/* 5th */}
        <Flex gap="small" vertical className="mb-[16px] bg-gray-200">
          <Flex justify="space-between" align="center" className="mb-[8px] h-[28px] w-full">
            <p>Hot Games</p>
            <More containElm={['more', 'pages']} />
          </Flex>
          {/* content */}
          <div>业务组件1</div>
          <div>业务组件2</div>
        </Flex>

        {/* 6th */}
        <Flex gap="small" vertical className="mb-[16px] bg-gray-200">
          <Flex justify="space-between" align="center" className="mb-[8px] h-[28px] w-full">
            <p>KuiPachi</p>
            <More containElm={['more', 'pages']} />
          </Flex>
          {/* content */}
          <div>业务组件1</div>
          <div>业务组件2</div>
        </Flex>

        {/* 7th */}
        <Flex gap="small" justify={'space-between'} className="mb-[16px] bg-gray-200">
          <Flex gap="small" vertical className="w-[494px]">
            <Flex justify="space-between" align="center" className="mb-[8px] h-[28px] w-full">
              <p>Milestones</p>
              <More containElm={['more']} />
            </Flex>
            <div>业务组件1</div>
          </Flex>

          <Flex gap="small" vertical className="w-[494px] bg-gray-200">
            <Flex justify="space-between" align="center" className="mb-[8px] h-[28px] w-full">
              <p>Testimonials</p>
              <More containElm={['more']} />
            </Flex>
            <div>业务组件1</div>
          </Flex>
        </Flex>

        {/*  8th */}
        <Flex gap="small" vertical className="mb-[16px] bg-gray-200">
          <Flex justify="start" align="center" className="mb-[8px] h-[28px] w-full">
            <p>Why Us</p>
          </Flex>
          <div className="h-[300px] w-full">业务组件 アジアNo.1オンラインカジノ 展示更多</div>
        </Flex>
      </div>
      {/* </div> */}
    </>
  )
}

export default memo(DesktopHome)
