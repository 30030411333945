/* 登录-注册弹窗控制 */
import { track } from '@/hooks/useTrack'
import { IAuthStore } from '../types/IStore'
import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'
// import { useRouter } from 'next/navigation'

// 创建状态存储
export const useAuthStore = create<IAuthStore>()(
  persist(
    immer((set, get) => ({
      resetSuccess: false,
      logRegVisible: false,
      logRegType: 'login',
      //注册成功
      regSucInfo: {
        flag: false,
        showPwEdit: true
      },
      redirectLink: '',
      fastRegisterVisible: false,
      fastRegisterData: {},
      _hasHydrated: false,
      //actions
      authModalControl: (data) => {
        set({ logRegVisible: data.flag, logRegType: data.type })
      },
      openLogin: () => {
        get().authModalControl({ flag: true, type: 'login' })
        track('LoginClick')
      },
      openRegister: (needAppFlyer) => {
        get().authModalControl({ flag: true, type: 'register' })
        track('RegisterClick')
      },
      closeModal: () => {
        get().authModalControl({ flag: false, type: 'login' })
      },
      updateFastRegisterData: (visible, data) => {
        set({ fastRegisterData: data, fastRegisterVisible: visible })
      },

      // 待处理,
      redirectTO: () => {
        // 待处理, router是个hooks, 需要返回由页面去处理
        // const router = useRouter()
        if (!get().redirectLink) return
        // router.push(get().redirectLink)
      },
      SET_REG_SUC_INFO: (data) => set({ regSucInfo: data }),
      SET_REDIRECT_TO: (path) => set({ redirectLink: path }),
      SET_RESET_SUCCESS: (data) => set({ resetSuccess: data }),
      SET_LOG_REG_VISIBLE: (visible) => set({ logRegVisible: visible }),
      setHasHydrated: (state) => {
        set({
          _hasHydrated: state
        })
      }
    })),
    {
      name: 'authControl', // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
      merge: (persistedState, currentState) => ({ ...currentState, ...(persistedState as IAuthStore) }),
      onRehydrateStorage: (state) => {
        return () => state.setHasHydrated(true)
      }
    }
  )
)
