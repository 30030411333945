'use client'
import { useState, useEffect, FC, useMemo, useRef } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { useUserStore } from '@/stores/user'
import { useTranslation } from '@/lib/locales/client'
import { cn, getCurrencySymbol, decimalFormat } from '@/lib/utils'
import { useUpdateEffect } from 'ahooks'
import { usePathname } from 'next/navigation'
import useOnClickOutside from 'use-onclickoutside'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import styles from './index.module.scss'

type IProps = {
  currencyList?: any[]
  currencyData?: string
  showBalance?: string
  finish?: (obj: any) => void
}

const HibanqDeposit: FC<IProps> = (props) => {
  const { t } = useTranslation()
  const pathname = usePathname()
  const { storeCurrency, storeWallets } = useUserStore(
    useShallow((state) => ({
      storeCurrency: state.currency?.currency,
      storeWallets: state.wallets
    }))
  )
  const [isShowOptions, setIsShowOptions] = useState(false)

  const wallets = useMemo(() => {
    if (props.currencyList && props.currencyList.length) {
      return props.currencyList.map((el) => {
        return { currency: el }
      })
    }
    if (props.showBalance === 'JPY') {
      return storeWallets.filter((e) => e.currency === 'JPY')
    } else if (props.showBalance === 'USD') {
      return storeWallets.filter((e) => e.currency === 'USD')
    } else if (props.showBalance === 'fiat') {
      return storeWallets.filter((e) => e.currency === 'JPY' || e.currency === 'USD')
    } else if (props.showBalance === 'virtual') {
      return storeWallets.filter((e) => e.currency !== 'JPY' && e.currency !== 'USD')
    } else {
      return storeWallets
    }
  }, [storeWallets, props.showBalance])

  const balanceNum = useMemo(() => {
    const item = storeWallets.filter((e) => e.currency === props.currencyData)[0]
    return item ? decimalFormat(item.cashAmount || 0, item.currency) : '0.00'
  }, [storeWallets])

  const getImg = (currency) => {
    try {
      return `/static/images/header/wallets/currencies/${currency}.png`
    } catch (error) {
      return ''
    }
  }
  const menuRef = useRef(null)
  useOnClickOutside(menuRef, () => setIsShowOptions(false))

  function chooseCurrency(item) {
    setIsShowOptions(false)
    props.finish(item.currency || item)
  }

  return (
    <div className={cn(styles['select-currency-warp'],'select-currency-warp')}>
      {(pathname.indexOf('/account/deposit') > -1 || pathname.indexOf('/account/withdraw') > -1) && (
        <p className={'title-select'}>{t('deposit.chooseCurrency')}</p>
      )}
      <div className="select-currency" ref={menuRef}>
        <div
          className={cn('select-currency-heard', isShowOptions && 'open')}
          onClick={() => {
            setIsShowOptions(!isShowOptions)
          }}
        >
          <div className="leftbox">
            <img className="currency" src={getImg(props.currencyData)} />
            <span>{props.currencyData}</span>
          </div>
          {props.showBalance ? (
            <p className="money">{getCurrencySymbol(props.currencyData) + ' ' + balanceNum}</p>
          ) : (
            <p className="text-ellipsis">{t('deposit.selectCurrency')}</p>
          )}
        </div>
        <CSSTransition timeout={200} in={isShowOptions} unmountOnExit classNames={'fade'}>
          <div className="list-box scrollbar">
            {wallets.map((item, index) => (
              <div
                className="list-item"
                key={index}
                onClick={() => {
                  chooseCurrency(item)
                }}
              >
                <div className="currency-box">
                  <img className="currency" src={getImg(item.currency)} />
                  <span>{item.currency}</span>
                </div> 
                <p className="num-box">
                  {getCurrencySymbol(item.currency) +
                    ' ' +
                    decimalFormat(item.cashAmount || 0, item.currency)}
                </p>
              </div>
            ))}
          </div>
        </CSSTransition>
      </div>
    </div>
  )
}

export default HibanqDeposit
