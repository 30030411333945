export const landpage = {
  title: "魅力的な仮想通貨での入金にチャレンジ！",
  descrtion:
    "こちらではご本人様確認の必要がない便利な仮想通貨でのご入金方法をご案内しています。仮想通貨のメリット、取引所口座開設方法などを確認して仮想通貨での入金にチャレンジしてみましょう！",
  moduleTitle1: "即時入金",
  moduleTitle2: "高度なセキュリティ",
  moduleTitle3: "仮想通貨ならではの特別限定オファー",
  moduleTitle4: "今すぐ仮想通貨で入金しよう！",
  moduleText1:
    "仮想通貨での入金はわずか数秒でお客様のアカウントへ反映されます。入金確認までのストレスなく遊びたい時にすぐ遊べる環境をご提供しております。",
  moduleText2:
    "仮想通貨での取引はクレジットカードや口座情報などの個人情報を記入する必要がないため匿名性が高く、またセキュリティ面でも安心です。",
  moduleText3:
    "クイーンカジノでは通常の入金プロモーションのほかに、仮想通貨でのご入金だけに適用される特別な限定プロモーションも随時行っております。さらにお得になる仮想通貨でのご入金をお試しください。",
  moduleText4:
    "KYC不要（ご本人様確認）、即時入金、高度なセキュリティ、豊富なプロモーションなど仮想通貨での入金には魅力がいっぱい！ぜひ、仮想通貨での入金にチャレンジしてみましょう！",
  text1: "日本国内の代表的な仮想通貨取引所はこちらです！",
  text2:
    "（仮想通貨をご利用の場合、匿名性を保つためこれら日本の仮想通貨取引所から一度海外の仮想通貨取引所を介することをおすすめします。）",
  btn: "仮想通貨で入金する"
}
